<template>


  <div>
    <div class="form-group calibration-group calibration-switch _panel"><button  @click="current_page = 1" type="button" class="btn route-setting_page-btn bottom" :class="current_page===1 ? 'active' : ''">{{ lang.main_calibration.Fuel_Level_Analog_1 }}</button><button  @click="current_page = 2" type="button" class="btn route-setting_page-btn bottom"   :class="current_page===2 ? 'active' : ''">{{ lang.main_calibration.Fuel_Level_Analog_2 }}</button></div>
  <div v-if="current_page === 1">
<h4>{{ lang.main_calibration.Fuel_Level_Analog_1 }}</h4>

    <template v-if="params_key1 != null">


    <div class="col-md-4">

      <div class="form-group calibration-group" v-for="(value, key) in row_add_1">
        <label>{{ lang.main_calibration[key] }}<span class="span-requier"> *</span>:</label>
        <input :type="value.type" class="form-control calibration-number" v-model="params[params_key1][key]">
      </div>


    </div>
    <div class="col-md-8">
      <div class="form-group calibration-group">
          <span>{{ lang.main_component.calibration }}</span>
        <button class=" btn btn-primary btn-sm calibration-btn" @click="addRowKe1()"><i class="fa fa-plus"></i></button>


        <button type="button" class="download-btn btn-primary btn-sm calibration-btn" @click="downloadExcel(params_key1 , 1)"> <i class="fa fa-file-excel-o" style="margin-right: 10px"></i>{{ lang.main_component.save }}</button>

        <input type="file"  @change="onFileChange1"  class="custom-file-input">
      </div>





      <div class="form-group calibration-group"  :key="index" v-for="(item, index)  in params_data1">

        <input type="text" class="form-control calibration-row1" v-model="item['val_key']">
        <span class="calibration-row-text"> mV = </span>
        <input type="text" class="form-control calibration-row2" v-model="item['val_item']">
        <span class="calibration-row-text"> L </span>
        <button class=" btn btn-primary btn-sm calibration-btn" @click="removeRowKe1(index)"><i class="fa fa-minus"></i></button>
      </div>
    </div>
      <div class="col-md-12">

        <div class="form-group ">
          <label>{{ lang.main_calibration.tankInfo }}<span class="span-requier"> *</span>:</label>
          <input type="text" class="form-control " v-model="params[params_key1]['tankInfo']">
        </div>

        <div class="form-group fuel-group">
          <button type="button" class="btn btn-success" @click="SaveCalibrationAnalog">{{ lang.main_component.save }}</button>

        </div>
      </div>
</template>
  </div>
  <div  v-if="current_page === 2">
    <h4>{{ lang.main_calibration.Fuel_Level_Analog_2 }}</h4>

    <template v-if="params_key2 != null">


      <div class="col-md-4">

        <div class="form-group calibration-group" v-for="(value, key) in row_add_2">
          <label>{{ lang.main_calibration[key] }}<span class="span-requier"> *</span>:</label>
          <input :type="value.type" class="form-control calibration-number" v-model="params[params_key2][key]">
        </div>


      </div>
      <div class="col-md-8">
        <div class="form-group calibration-group">
          <span>{{ lang.main_component.calibration }}</span>
          <button class=" btn btn-primary btn-sm calibration-btn" @click="addRowKe2()"><i class="fa fa-plus"></i></button>

          <button type="button" class="download-btn btn-primary btn-sm calibration-btn" @click="downloadExcel(params_key2 , 2)"> <i class="fa fa-file-excel-o" style="margin-right: 10px"></i>{{ lang.main_component.save }}</button>

          <input type="file"  @change="onFileChange2"  class="custom-file-input">

        </div>
        <div class="form-group calibration-group"  :key="index" v-for="(item, index)  in params_data2">

          <input type="text" class="form-control calibration-row1" v-model="item['val_key']">
          <span class="calibration-row-text"> mV = </span>
          <input type="text" class="form-control calibration-row2" v-model="item['val_item']">
          <span class="calibration-row-text"> L </span>
          <button class=" btn btn-primary btn-sm calibration-btn" @click="removeRowKe2(index)"><i class="fa fa-minus"></i></button>
        </div>
      </div>

      <div class="col-md-12">


        <div class="form-group ">
          <label>{{ lang.main_calibration.tankInfo }}<span class="span-requier"> *</span>:</label>
          <input type="text" class="form-control " v-model="params[params_key2]['tankInfo']">
        </div>


        <div class="form-group fuel-group">
          <button type="button" class="btn btn-success" @click="SaveCalibrationAnalog">{{ lang.main_component.save }}</button>

        </div>
      </div>
    </template>
  </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import XLSX from 'xlsx';



export default {
  name: "CalibrationSettingsComponent",
  props:{
    object_number: String,
    object_id: Number | String,
    calibration: Array | Object
  },
  data() {
    return {
      count_helper:0,
      json_fields:{},
      excel_arr:[],
      current_page:1,
         loading_page:false,
      base_url: base_url,
      params:{},
      data_today:null,
      params_key1: null,
      params_key2: null,
      params_data1 :[],
      params_data2 :[],
      row_add_1:{
        tankName: {type:'text'},
        tankL: {type:'number'},
        tankB: {type:'number'},
        tankH: {type:'number'},
        tankVmax: {type:'number'},
        tankmV: {type:'number'},
        llsName: {type:'text'},
        llsSerial: {type:'text'},
        llsLorign: {type:'number'},
        llsLobr: {type:'number'},
        min: {type:'number'},
        max: {type:'number'},
      },
      row_add_2:{
        tankName: {type:'text'},
        tankL: {type:'number'},
        tankB: {type:'number'},
        tankH: {type:'number'},
        tankVmax: {type:'number'},
        tankmV: {type:'number'},
        llsName: {type:'text'},
        llsSerial: {type:'text'},
        llsLorign: {type:'number'},
        llsLobr: {type:'number'},
        min: {type:'number'},
        max: {type:'number'},
      }
    }
    },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      current_object:'CURRENT_OBJECT',
    }),
  },
  mounted() {

    this.data_today = this.$moment().format('MM/DD/YYYY');
    this.params = this.calibration;
    if(typeof this.params === 'object' && this.params !== null) {
      this.params_key1 = null
      this.params_key2 = null
      let index = 0;
      Object.entries(this.params).forEach(([key, val]) => {
        if(index === 0){
          this.params_key1 = key
        }else{
          this.params_key2 = key
        }

        index++;
      });
      let check =this.params[this.params_key1]['data']
      if(typeof check === 'object' && check !== null) {
        let ent = 0;
        Object.entries(check).forEach(([key, val]) => {
          this.params_data1[ent] = {'val_key':key ,'val_item':val }
          ent++;
        });
      }
      let check2 =this.params[this.params_key2]['data']
      if(typeof check2 === 'object' && check2 !== null) {
        let ent2 = 0;
        Object.entries(check2).forEach(([key, val]) => {
          this.params_data2[ent2] = {'val_key':key ,'val_item':val }
          ent2++;
        });
      }


    }

  },
  methods: {
    onFileChange1(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {type: 'binary', cellDates: true, dateNF: 'yyyy/mm/dd;@'});
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {header: 1});

          let fields = [
            ['tankName','G7'],
            ['tankL','G8'],
            ['tankB','G9'],
            ['tankH','G10'],
            ['tankVmax','G11'],
            ['tankmV','G12'],
            ['llsName','G14'],
            ['llsSerial','G15'],
            ['llsLorign','G16'],
            ['llsLobr','G17'],
            ['mvMin','G18'],
            ['mvMax','G19'],
            ['tankInfo','G20'],
          ];
 let chek = data[7]
  if( chek.length> 0) {
    this.params[this.params_key1].tankName = data[6][6];
    this.params[this.params_key1].tankL = data[7][6];
    this.params[this.params_key1].tankB = data[8][6];
    this.params[this.params_key1].tankH = data[9][6];
    this.params[this.params_key1].tankVmax = data[10][6];
    this.params[this.params_key1].tankmV = data[11][6];
    this.params[this.params_key1].llsName = data[13][6];
    this.params[this.params_key1].llsSerial = data[14][6];
    this.params[this.params_key1].llsLorign = data[15][6];
    this.params[this.params_key1].llsLobr = data[16][6];
    this.params[this.params_key1].min = data[17][6];
    this.params[this.params_key1].max = data[18][6];
    this.params[this.params_key1].tankInfo = data[19][6];
  }


          for(let i =7; i <data.length; i++ ){
            if(data[i][1] !== 'undefined' && data[i][1] !== '' && data[i][1] !== null && data[i][1]*1 > 0){
              this.params_data1.push({'val_key':data[i][2] ,'val_item':data[i][3] })
            }

         }



        }

        reader.readAsBinaryString(this.file);
      }
    },
    onFileChange2(event) {
      this.file = event.target.files ? event.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, {type: 'binary', cellDates: true, dateNF: 'yyyy/mm/dd;@'});
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {header: 1});

          let fields = [
            ['tankName','G7'],
            ['tankL','G8'],
            ['tankB','G9'],
            ['tankH','G10'],
            ['tankVmax','G11'],
            ['tankmV','G12'],
            ['llsName','G14'],
            ['llsSerial','G15'],
            ['llsLorign','G16'],
            ['llsLobr','G17'],
            ['mvMin','G18'],
            ['mvMax','G19'],
            ['tankInfo','G20'],
          ];
          let chek = data[7]
          if( chek.length> 0) {
            this.params[this.params_key2].tankName = data[6][6];
            this.params[this.params_key2].tankL = data[7][6];
            this.params[this.params_key2].tankB = data[8][6];
            this.params[this.params_key2].tankH = data[9][6];
            this.params[this.params_key2].tankVmax = data[10][6];
            this.params[this.params_key2].tankmV = data[11][6];
            this.params[this.params_key2].llsName = data[13][6];
            this.params[this.params_key2].llsSerial = data[14][6];
            this.params[this.params_key2].llsLorign = data[15][6];
            this.params[this.params_key2].llsLobr = data[16][6];
            this.params[this.params_key2].min = data[17][6];
            this.params[this.params_key2].max = data[18][6];
            this.params[this.params_key2].tankInfo = data[19][6];
          }


          for(let i =7; i <data.length; i++ ){
            if(data[i][1] !== 'undefined' && data[i][1] !== '' && data[i][1] !== null && data[i][1]*1 > 0){
              this.params_data2.push({'val_key':data[i][2] ,'val_item':data[i][3] })
            }

          }



        }

        reader.readAsBinaryString(this.file);
      }
    },
    downloadExcel(params_key, add_num){
      let cali = [];

      let counter = 13;
      if(add_num === 1) {
        if (this.params_data1.lenght > counter) {
          counter = this.params_data1.lenght
        }
        for (let i = 0; i < this.params_data1.length; i++) {
          cali.push([this.params_data1[i] ? this.params_data1[i]['val_key'] : " ",  this.params_data1[i] ? this.params_data1[i]['val_item'] : " "]);
        }
      }else{
        if (this.params_data2.lenght > counter) {
          counter = this.params_data2.lenght
        }
        for (let i = 0; i < this.params_data2.length; i++) {
          cali.push([this.params_data2[i] ? this.params_data2[i]['val_key'] : " ",  this.params_data2[i] ? this.params_data2[i]['val_item'] : " "]);
        }
      }



      let tankName = this.params[params_key].tankName;
      let tankL =this.params[params_key].tankL;
      let tankB = this.params[params_key].tankB;
      let tankH =this.params[params_key].tankH;
      let tankVmax = this.params[params_key].tankVmax;
      let tankmV = this.params[params_key].tankmV;
      let llsName = this.params[params_key].llsName;
      let llsSerial =this.params[params_key].llsSerial;
      let llsLorign = this.params[params_key].llsLorign;
      let tankInfo = this.params[params_key].tankInfo;
      let llsLobr =this.params[params_key].llsLobr;
      let mvMin =this.params[params_key].min;
      let mvMax = this.params[params_key].max;


      let xlsData = [];
      let erow = [
        undefined,
        undefined,
        undefined,
        undefined
      ];
      let i;
      for(i=1;i<7;i++){
        xlsData.push(erow);
      }
      xlsData.push([
        undefined,
        {v:'#', s:'', m: false},
        {v:'mV', s:'', m: false},
        {v:'L', s:'', m: false},
        undefined,
        {v:'BAK', s:'', m: false},
        {v:tankName, s:'', m: false}
      ]);


      let bakrows = [
        ['L',tankL,'mm'],
        ['B',tankB,'mm'],
        ['H',tankH,'mm'],
        ['Vmax',tankVmax,'Ltr'],
        ['mV',tankmV,'mV'],
        ['','',''],
        ['DUT',llsName,''],
        ['Serial',llsSerial,''],
        ['L orig',llsLorign,'mm'],
        ['L obr',llsLobr,'mm'],
        ['mV min',mvMin,'mV'],
        ['mv max',mvMax,'mV'],
        ['tankInfo',tankInfo,'']
      ];


      for(i=0;i<cali.length;i++){
        xlsData.push([
          undefined,
          {v:i+1, s:'', m: false},
          {v:cali[i][0], s:'', m: false},
          {v:cali[i][1], s:'', m: false},
          undefined,
          {v:bakrows[i]?bakrows[i][0]:'', s:'', m: false},
          {v:bakrows[i]?bakrows[i][1]:'', s:'', m: false},
          {v:bakrows[i]?bakrows[i][2]:'', s:'', m: false}
        ]);
      }

      if (i<bakrows.length){
        for(var r=i;r<bakrows.length;r++){
          xlsData.push([
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            {v:bakrows[r][0], s:'', m: false},
            {v:bakrows[r][1], s:'', m: false},
            {v:bakrows[r][2], s:'', m: false},
            {v:bakrows[r][3], s:'', m: false}
          ]);
        }
      }



      const ws  = XLSX.utils.json_to_sheet(xlsData,{skipHeader: true })

      const wb = XLSX.utils.book_new()

      let todayDate = new Date().toJSON().slice(0,19);

      XLSX.utils.book_append_sheet(wb, ws, "calibration")
      XLSX.writeFile(wb,"calibration " + todayDate + ".xlsx")
    },
 addRowKe2(){
      this.params_data2.push({'val_key':0 ,'val_item':0 })
    },
    removeRowKe2(index){
      this.params_data2.splice(index, 1);
    },
   addRowKe1(){
     this.params_data1.push({'val_key':0 ,'val_item':0 })
   },
    removeRowKe1(index){
      this.params_data1.splice(index, 1);
    },
    SaveCalibrationAnalog(){

      let _this = this
      let formData = new FormData()
      formData.append("ObjectId",  this.current_object)

      formData.append("calibration[id"+_this.params_key1+"][id]",  _this.params_key1)

      Object.keys( _this.row_add_1).forEach(function(key) {
        formData.append("calibration[id"+_this.params_key1+"]["+key+"]",  _this.params[_this.params_key1][key])
      });

      formData.append("calibration[id"+_this.params_key1+"][tankInfo]",  _this.params[_this.params_key1]['tankInfo'])



       let elem = 0;
      _this.params_data1.map(function(item) {

        formData.append("calibration[id"+_this.params_key1+"][data]["+elem+"][mV]",  item['val_key'])
        formData.append("calibration[id"+_this.params_key1+"][data]["+elem+"][L]",  item['val_item'])
        elem++;
      });


      formData.append("calibration[id"+_this.params_key2+"][id]",  _this.params_key2)

      Object.keys( _this.row_add_2).forEach(function(key) {
        formData.append("calibration[id"+_this.params_key2+"]["+key+"]",  _this.params[_this.params_key2][key])
      });
      formData.append("calibration[id"+_this.params_key2+"][tankInfo]",  _this.params[_this.params_key2]['tankInfo'])

      let elem2 = 0;
      _this.params_data2.map(function(item) {

        formData.append("calibration[id"+_this.params_key2+"][data]["+elem2+"][mV]",  item['val_key'])
        formData.append("calibration[id"+_this.params_key2+"][data]["+elem2+"][L]",  item['val_item'])
        elem2++;
      });

      axios.post(base_url + '/index/setObjectParams/', formData).then(response => {
        console.log(response)
        if(response.data.error === 0){
          _this.$iosAlert('Success changed params.');
          _this.loading_page =true
        }else{
          _this.$iosAlert('Something went wrong. Response not success.');
          _this.loading_page =true
        }
      }).catch(error => {
        console.log(error)
        _this.loading_page =true
      })



    },

  }
}
</script>

<style scoped>
.vodometerSettings .save, .vodometerSettings  .cancel {
  display: none;
}

.vodometerSettings td, .vodometerSettings th {
  border: 1px solid #aaa;
  padding: 6px;
}
.vodometerSettings .gps { text-align: right; }
.vodometerSettings .odometer { text-align: right; }
.vodometerSettings .correction input { width: 70px; text-align: right; }


.weightSettings .list {
  border: 1px solid #ddd;
  height: 300px;
  overflow-y: scroll;
  width: 49%;
  float:left;
}
.weightSettings .valls {
  border: 0;
  width: 49%;
  float:right;padding-left: 10px;
}
.weightSettings .list .item input {
  width: 70px;
}
.weightSettings .list .item {
  border-bottom: 1px solid #ddd;
  margin: 3px;
  padding: 3px;
  padding-top:0;
}
.weightSettings .list .item:hover {
  background-color: #fafafa;
}
.weightSettings .add {
  float: left;
}
.weightSettings .add, .weightSettings .save {
  display: none;
}
.weightSettings .save,.weightSettings .refresh {
  float: right;
}

.form-group.calibration-group {
  margin-bottom: 5px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.form-group.calibration-group .calibration-number {
  width: 45%;
  height: 28px;
  padding: 2px 12px;
}
.form-group.calibration-group label {
font-size: 12px;
  width: 45%;
  padding-right: 10px;
  text-align: right;
}
.span-requier{
  color: red;
}
.calibration-btn{
  padding: 2px 6px;
  margin: 0 10px;
}

.form-group.calibration-group .calibration-row1, .form-group.calibration-group .calibration-row2{
  width: 20%;
  height: 28px;
  padding: 2px 12px;
}
.calibration-row-text{
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: bold;
}
.route-setting_page-btn{
  margin: 0 20px;
}

.custom-file-input {
  color: transparent;
  width: 150px;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: 'Load';
  display: inline-block;
  background-color: #1C71DA;
  border: 1px solid #1C71DA;
  border-radius: 3px;
  padding: 2px 12px;

  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  font-weight: 400;
  font-size: 10pt;
  margin: 0 10px;
  color: #ffffff;
}

.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background:  #1C71DA;
}
</style>