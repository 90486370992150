import { render, staticRenderFns } from "./CalibrationSettingsComponent.vue?vue&type=template&id=a4a61440&scoped=true&"
import script from "./CalibrationSettingsComponent.vue?vue&type=script&lang=js&"
export * from "./CalibrationSettingsComponent.vue?vue&type=script&lang=js&"
import style0 from "./CalibrationSettingsComponent.vue?vue&type=style&index=0&id=a4a61440&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4a61440",
  null
  
)

export default component.exports