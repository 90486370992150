<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 264.553 264.553" style="enable-background:new 0 0 264.553 264.553;" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M254.885,26.243l-23.44,7.08V19.526c0-4.142-3.357-7.5-7.5-7.5H85.721c-4.143,0-7.5,3.358-7.5,7.5v13.087     C66.445,35.9,57.777,46.716,57.777,59.526s8.667,23.627,20.443,26.913v13.087c0,4.142,3.357,7.5,7.5,7.5h15.167v16.5     c0,4.142,3.357,7.5,7.5,7.5h15.166v52.226c0,6.893-5.608,12.5-12.5,12.5h-17.39c-3.623-23.623-24.084-41.774-48.705-41.774     c-13.549,0-23.998,0-37.458,0c-4.142,0-7.5,3.358-7.5,7.5v83.549c0,4.142,3.358,7.5,7.5,7.5c13.441,0,23.918,0,37.458,0     c24.621,0,45.082-18.151,48.705-41.774h17.39c15.163,0,27.5-12.336,27.5-27.5v-52.226h15.167c4.143,0,7.5-3.358,7.5-7.5v-16.5     h62.724c4.143,0,7.5-3.358,7.5-7.5V85.73l23.44,7.08c4.825,1.456,9.669-2.167,9.669-7.18V33.423     C264.554,28.392,259.691,24.791,254.885,26.243z M78.22,70.07c-3.292-2.349-5.443-6.2-5.443-10.544s2.15-8.195,5.443-10.544     V70.07z M28.114,237.527H15.001v-68.549h13.113V237.527z M43.114,237.526v-68.549c22.823,0,36.119,16.853,36.119,34.274     C79.233,220.706,65.93,237.526,43.114,237.526z M146.221,116.026h-30.333v-9h30.333V116.026z M216.444,92.026     c-7.368,0-116.485,0-123.224,0c0-7.214,0-57.294,0-65h123.224C216.444,34.403,216.444,84.893,216.444,92.026z M249.554,75.53     l-18.109-5.47V48.992l18.109-5.47V75.53z"/>
      <circle cx="114.194" cy="71.052" r="8.474"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgCameraIcon"
}
</script>

<style scoped>

</style>
