<template>
  <section class="panel events-panel" :class="!full_event? 'hidden-top':''">
    <template v-if="current_resources && current_resources[120] && current_resources[120].enabled ===true">
    <header class="panel-heading panel-heading-transparent events-head">
      <div class="button-cover">
        <div class="button r" id="button-1">
          <input type="checkbox" class="checkbox" v-model="event_type">
          <div class="knobs"></div>
          <div class="layer"></div>
        </div>
      </div>
      <h2 class="panel-title" v-if="!create">

        <span class="label label-primary label-sm text-normal va-middle mr-sm " style="margin-right: 10px">{{records}}</span><translate-component :text="lang.events_component.title" />
        <span style="padding-left: 5px">({{ start_event}}-{{ end_event}})</span>
        <span class="events-loading" v-if="events_loading">
         <scale-loader :height="loading_size" ></scale-loader>
      </span>

      </h2>
      <h2 class="panel-title" v-if="create && !edit">
        <translate-component :text="lang.events_component.create_event" />
      </h2>
      <h2 class="panel-title" v-if="edit">
        <translate-component :text="lang.events_component.edit_event" />{{params.Name}}
      </h2>



      <div class="panel-actions">
      <span class="arrow-control" v-if="full_event" @click="changeFull()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
          <path d="M352 352c-8.188 0-16.38-3.125-22.62-9.375L192 205.3l-137.4 137.4c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25l160-160c12.5-12.5 32.75-12.5 45.25 0l160 160c12.5 12.5 12.5 32.75 0 45.25C368.4 348.9 360.2 352 352 352z"/></svg>
      </span>
        <span class="arrow-control" v-if="!full_event" @click="changeFull()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
        <path d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>
      </span>
      </div>
    </header>
    <div class="box-events-panel first-box " :class="event_type == 0 ? 'full-height': '' " v-if="full_event">


    <div class="panel-body events-list-body"  style="display: block;" >
      <div class="create-event-box" v-if="create">
        <div class="alert alert-danger" v-if="error_log">
          <button @click="closeError()" type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
          <p v-for="(text,key,index) in error_text" :key="index"><strong>{{text}}</strong></p>
        </div>
        <div class="alert alert-success" v-if="success_log">
          <strong v-if="edit">
            <translate-component :text="lang.events_component. edit_text" />
          </strong>
          <strong v-else>
            <translate-component :text="lang.events_component.success_text" />
          </strong>

        </div>
        <div class="row" v-if=" create_page ===1 ">

        <div class="col-md-6 form-group">
          <label  ><translate-component :text="lang.events_component.type" /></label>
            <select class="form-control mb-md" v-model="params.Type" @change="onChange()">
              <template v-for="val in option_val">
                <option :value="val">{{lang.events_component['type_'+val]}}</option>
              </template>

            </select>
        </div>
        <div class="col-md-6 form-group">
          <label >
            <translate-component :text="lang.events_component.name" />
          </label>
          <input type="text" class="form-control" v-model="params.Name">
        </div>
        </div>
         <div class="row" v-if="(create_page ===2 && stop_page === 5)">
    <template v-if="params.Type === 31 || params.Type === 15">

    <div class="col-md-6">
 <geozone-list-component  :small_tema="small_tema" @handlebox="handlePolyCheckbox2($event)" />
    </div>
      <div class="col-md-6">
        <div>
          <div class="form-group" v-if="params.Type === 31">
            <label><translate-component :text="lang.events_component.check_for" /></label>
            <select class="form-control" v-model="params_options.Option">
              <option value="1"><translate-component :text="lang.events_component.opt_1" /></option>
              <option value="2"><translate-component :text="lang.events_component.opt_2" /></option>
              <option value="3"><translate-component :text="lang.events_component.opt_3" /></option>
            </select>

          </div>
        </div>
        <div class="form-group" style="margin-top: 20px">
          <label>
            <translate-component :text="lang.events_component.add_zone" />
          </label>
        </div>
        <div>
        <div style="display: none">{{zone_counter}}</div>
        <ul class="widget-todo-list">
          <li v-for="(zone,key,index) in zones" :key="key">
            <div class="checkbox-custom checkbox-default">
              <span class="todo-label" ><span>{{zone[2]}}</span></span>
            </div>
            <div class="todo-actions">
              <a class="todo-remove"  @click="removeZone(key)">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </li>
        </ul>
        </div>
      </div>
    </template>
           <template v-if="params.Type === 5">

             <div class="col-md-12">
               <label><translate-component :text="lang.events_component.range_text" /></label>
               <select class="form-control" v-model="params_options.Option" @change="onChangeRange()">
                 <option :value="item"  v-for="(item,index) in choose_options">{{lang.events_component['choose_'+index]}}</option>
               </select>
             </div>
             <div class="col-md-12 ranger-box" >
               <div class="route-setting_input-helper">
                 <span class="min">{{range_mes.min}} {{range_mes.mes}}</span>
                 <span class="max">{{range_mes.max}} {{range_mes.mes}}</span>
               </div>
               <vue-range-slider :min="range_mes.min" :max="range_mes.max"
                                 :tooltip-merge="false"
                                 v-model="range"
               ></vue-range-slider>
             </div>

           </template>
           <template v-if="params.Type === 8 || this.params.Type === 9">
             <div class="col-md-12">
               <label><translate-component :text="lang.events_component.inspection_text" /></label>
             </div>
             <div class="col-md-12 form-group inspection-box">
               <span class="inspection-text"><translate-component :text="lang.events_component.remind" /></span>
               <input type="number" class="tsHH hhmmhh form-control" v-model="reminderDays">
               <span class="inspection-text"><translate-component :text="lang.events_component.days_before" /></span>
             </div>
           </template>
           <template v-if="params.Type === 10">
             <div class="col-md-12">
               <label><translate-component :text="lang.events_component. maint_text" /></label>
             </div>
             <div class="col-md-12 form-group inspection-box">
               <span class="inspection-text"><translate-component :text="lang.events_component.maint_remain" /></span>
               <input type="number" class="maintext-text form-control" v-model="maintenanceLimit">
               <span class="inspection-text">km</span>
             </div>
             <div class="col-md-12 form-group inspection-box">
               <span class="inspection-text"><translate-component :text="lang.events_component.maint_start" /></span>
               <input type="number" class="maintext-text form-control" v-model="maintenanceStart">
               <span class="inspection-text">km</span>
             </div>
           </template>

  </div>

        <div class="row" v-if="(create_page ===3 &&  stop_page === 5) || (create_page ===2 &&  stop_page === 4)">


            <div class="col-md-6">
              <div class="form-group" style="margin-top: 20px">
                <label>
                  <translate-component :text="lang.events_component.select_vehicle" />
                </label>
              </div>
              <div>
                <div style="display: none">{{zone_counter}}</div>
                <template v-if="objects && objects.length >0 ">
                <ul class="widget-todo-list">
                  <li v-for="(obj,index) in objects" :key="obj.ObjectId">
                    <div class="checkbox-custom checkbox-default" @click="addVehicle(obj,index)">

                        <div class="object-text">
                          <div class="object-text__title">{{obj.Name }}</div>
                          <div class="object-text__subitle">{{ obj.Number }}</div>
                          <div class="object-text__addtext" v-if=" obj.DriverName"><span>{{obj.DriverName }}</span></div>
                        </div>

                    </div>

                  </li>
                </ul>
                </template>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group" style="margin-top: 20px">
                <label>
                  <translate-component :text="lang.events_component.add_vehicle" />
                </label>
              </div>
              <div>
                <div style="display: none">{{zone_counter}}</div>
                <ul class="widget-todo-list">
                  <li v-for="(vehicle,key,index) in vehicles" :key="key">
                    <div class="checkbox-custom checkbox-default">
                      <span class="todo-label" ><span class="event-vehicle-name">{{vehicle.Name }}</span><span class="event-vehicle-number">{{vehicle.Number}}</span></span>
                    </div>
                    <div class="todo-actions">
                      <a class="todo-remove"  @click="removeVehicle(key)">
                        <i class="fa fa-times"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>


        </div>

        <div class="row" v-if=" (create_page === 4 &&  stop_page === 5) || (create_page ===3 &&  stop_page === 4) ">

            <div class="col-md-12 form-group">
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="params.AlertOnSite">
                  <translate-component :text="lang.events_component.use_alert" />
                </label>
              </div>
            </div>

            <div class="col-md-6 form-group">
              <div class="checkbox">
              <label>
                <input type="checkbox" v-model="params.SendToEmail">
                <translate-component :text="lang.events_component.use_email" />
              </label>
              </div>
              <input type="email" class="form-control" v-model="params.Email" :placeholder="lang.events_component.placeholder_email">
            </div>


          <template v-if="current_resources && current_resources[125] && current_resources[125].enabled ===true">
            <div class="col-md-6 form-group" v-if="params.Type !== 10">
              <div class="checkbox">
              <label>
                <input type="checkbox" v-model="params.sendSms">
                <translate-component :text="lang.events_component.use_sms" />
              </label>
              </div>
              <input type="text"  class="form-control" v-model="params.GsmNumber" :placeholder="lang.events_component.placeholder_sms">
            </div>
          </template>

          <template v-if="params.Type === 31 || params.Type === 15">
            <div class="col-md-12 form-group">
              <label>
                <translate-component :text="lang.events_component.enter_days" />
              </label>
            </div>

            <div class="col-md-12 form-group">
              <div class="line-group">
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="days" value="0">
                  <translate-component :text="lang.main_component.mon"/>
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="days" value="1">
                  <translate-component :text="lang.main_component.tue" />
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="days" value="2">
                  <translate-component :text="lang.main_component.wed" />
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="days" value="3">
                  <translate-component :text="lang.main_component.thu" />
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="days" value="4">
                  <translate-component :text="lang.main_component.fry" />
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="days" value="5">
                  <translate-component :text="lang.main_component.sat" />
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="days" value="6">
                  <translate-component :text="lang.main_component.sun" />
                </label>
              </div>
              </div>
            </div>
            <div class="col-md-12 form-group">
              <label><translate-component :text="lang.events_component.enter_time" /></label>
              <div class="divDaysTimeSettings">
              <input class="hhmm tsHH hhmmhh form-control" type="number" maxlength="2"  v-model="time_add.start1"><span class="time-text">:</span>
              <input class="hhmm tsHH hhmmhh form-control" type="number" maxlength="2"   v-model="time_add.start2"><span class="time-text">-</span>
              <input class="hhmm teHH hhmmhh form-control" type="number" maxlength="2"  v-model="time_add.end1"><span class="time-text">:</span>
              <input class="hhmm teMM hhmmhh form-control" type="number" maxlength="2" v-model="time_add.end2">
              </div>
            </div>
          </template>
            <div class="col-md-6 form-group">
              <label>
                <translate-component :text="lang.events_component['enter_'+params.Type]" />
              </label>
              <input type="text" class="form-control" v-model="params_options.Message1">
            </div>
            <div class="col-md-6 form-group" v-if="params.Type !== 15 && params.Type !== 8  && params.Type !== 9  && params.Type !== 10">
              <label>
                <translate-component :text="lang.events_component['out_'+params.Type]" />
              </label>
              <input type="text" class="form-control" v-model="params_options.Message0">
            </div>


            <div class="col-md-6 form-group">
              <label><translate-component :text="lang.events_component.out_offset" /></label>
              <input type="number" class="form-control" v-model="params.UtcOffset">
            </div>
            <div class="col-md-6 form-group" v-if="params.Type !== 15 && params.Type !== 8 && params.Type !== 9  && params.Type !== 10">
              <label><translate-component :text="lang.events_component.react_delay" /></label>
              <input type="number" class="form-control" v-model="params_options.Delay">
            </div>
          <template v-if="params.Type === 31 || params.Type === 15">
            <div class="col-md-6 form-group">
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="params.OffsetAdjust" >
                  <translate-component :text="lang.events_component.adjust" />
                </label>
              </div>
            </div>
          </template>

        </div>


        <div class="row" v-if="(create_page ===5 && stop_page === 5) || (create_page ===4 &&  stop_page === 4)">

          <div class="col-md-12 form-group">
            <label><translate-component :text="lang.events_component.event_now" /></label>
          </div>
          <div class="col-md-12">
            <div class="checkbox">
              <label>
                <input type="checkbox" v-model="params.Enabled">
                <translate-component :text="lang.events_component.event_after" />
              </label>
            </div>
          </div>
        </div>

      </div>
      <template v-else>
        <div style="display: none">{{event_counter}}</div>

        <div class="table-responsive" >
          <perfect-scrollbar >
            <table class="table table-striped table-hover mb-none table-event" v-if="events.length > 0">
              <thead class="event-table-header">
              <tr>
                <th><translate-component :text="lang.events_component.name"/></th>
                <th><translate-component :text="lang.events_component.type"/></th>
                <th><translate-component :text="lang.events_component.utc_off"/></th>
                <th><translate-component :text="lang.events_component.user"/></th>
                <th class="check-log"><translate-component :text="lang.events_component.e_mail"/></th>
                <th class="check-log"><translate-component :text="lang.events_component.add_alert"/></th>
                <th class="check-log"><translate-component :text="lang.events_component.enable"/></th>
                <th><translate-component :text="lang.events_component.create_at"/></th>
                <th><translate-component :text="lang.events_component.update_at"/></th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(event,index) in events">
                <tr class="event-table-list" :key="event.id" v-if="option_val.includes(event.cell[1])">
                  <td class="event-table-name">{{event.cell[0]}}</td>
                  <td class="event-table-type">{{lang.events_component['type_'+event.cell[1]]}}</td>
                  <td class="event-table-clock"><span class="fa fa-clock-o event-table-icon"></span>{{event.cell[2]}}</td>
                  <td class="event-table-user"><span class="fa fa-user event-table-icon"></span>{{event.cell[5]}}</td>
                  <td class="check-log">
                  <span class="check-box" :class="event.cell[6] ===1?'active': ''">
                    <svg-chek-icon  v-if="event.cell[6] ===1 "/>
                      <svg-minus-icon v-else/>
                    <span class="event-table-check"><translate-component :text="lang.events_component.e_mail"/></span>
                  </span>

                  </td>
                  <td class="check-log">
                    <span class="check-box" :class="event.cell[7] ===1?'active': ''">
                    <svg-chek-icon  v-if="event.cell[7] ===1 "/>
                      <svg-minus-icon v-else/>
                        <span class="event-table-check"><translate-component :text="lang.events_component.add_alert"/></span>
                  </span>
                  </td>
                  <td class="check-log">
                    <span class="check-box" :class="event.cell[8] ===1?'active': ''">
                    <svg-chek-icon  v-if="event.cell[8] ===1 "/>
                      <svg-minus-icon v-else/>
                        <span class="event-table-check"><translate-component :text="lang.events_component.enable"/></span>
                  </span>
                  </td>
                  <td class="event-table-date">{{event.cell[3]}}</td>
                  <td class="event-table-update">{{event.cell[4]}}</td>
                  <th class="event-table-btns">
                    <template v-if="current_resources && current_resources[122] && current_resources[122].enabled ===true">
                    <button type="button" class="btn btn-xs btn-success event-btn" @click="eventEdit(event.id,index)">{{lang.events_component.edit}}</button>
                    </template>
                    <template v-if="current_resources && current_resources[123] && current_resources[123].enabled ===true">
                    <button type="button" class="btn btn-xs btn-default event-btn" @click="eventDelete(event.id,index)">{{lang.events_component.delete}}</button>
                    </template>
                  </th>
                </tr>
              </template>
              </tbody>
            </table>
          </perfect-scrollbar>
        </div>

      </template>
    </div>

      <div class="panel-footer" v-if="create">

        <div class="btn-group-scroll big-group-scroll ">
          <div class="btn  button-scroll button-scroll-total" @click="cancelEvent()"><span class="fa fa-times"></span>
            <span class="span-title"><translate-component :text="lang.main_component.cancel"/></span>

          </div>
          <button type="button" class="btn  button-scroll " :class="create_page===1 ? 'disabled':''"  @click="ScrollPage(-1)">
            <span class="button-scroll-icon button-scroll-icon-left fa fa-chevron-left"></span>
            <span class="span-title hidelog"><translate-component :text="lang.main_component.previous"/></span>
          </button>
          <button type="button" class="btn  button-scroll button-scroll-right" :class="stop_page=== create_page ? 'disabled':''" @click="ScrollPage(1)" >
            <span class="span-title hidelog"><translate-component :text="lang.main_component.next"/></span>
          <span
              class="button-scroll-icon button-scroll-icon-right fa fa-chevron-right"></span>

          </button>
          <div class="btn  button-scroll button-scroll-total" @click="saveEvent()" v-if="stop_page=== create_page">
            <span class="fa fa-plus"></span>
            <span class="span-title"><translate-component :text="lang.main_component.save_at"/></span>
          </div>

        </div>
      </div>


      <div class="panel-footer" v-else>

        <div class="btn-group-scroll big-group-scroll ">
          <template v-if="current_resources && current_resources[121] && current_resources[121].enabled ===true">
          <div class="btn  button-scroll button-scroll-total" @click="createEvent()">
            <span class="fa fa-edit"></span>
            <translate-component :text="lang.events_component.create"/>

          </div>
          </template>
          <button type="button" class="btn  button-scroll " @click="ScrollList(-1)">
            <span class="button-scroll-icon button-scroll-icon-left fa fa-chevron-left"></span>
          </button>
          <div class="btn  button-scroll button-scroll-center hidelog">
            <translate-component :text="lang.geo_zone_component.page"/>
            <span class="page-count"><input type="number" class="form-control"

                                            :value="this.page" disabled> </span></div>
          <button type="button" class="btn  button-scroll button-scroll-right" @click="ScrollList(1)"><span
              class="button-scroll-icon button-scroll-icon-right fa fa-chevron-right"></span></button>
          <div class="btn  button-scroll button-scroll-total" @click="ScrollList('max')"><translate-component :text="lang.events_component.total_page"/>
            <span style="padding-left: 5px">{{ this.totalpages }}</span></div>
        </div>

      </div>

    </div >
    <div class="box-events-panel second-box"  :class="event_type === true ? 'full-second-height': '' " v-if="full_event">

    <header class="panel-heading panel-heading-transparent events-head">
      <div class="button-cover">
        <div class="button r" id="button-2">
          <input type="checkbox" class="checkbox" v-model="event_type">
          <div class="knobs"></div>
          <div class="layer"></div>
        </div>
      </div>
      <h2 class="panel-title">
        <span class="label label-primary label-sm text-normal va-middle mr-sm " style="margin-right: 10px">{{log_records}}</span>
        <translate-component :text="lang.events_component.event_log" />
        <span style="padding-left: 5px">({{ log_start_event}}-{{ log_end_event}})</span>
        <span class="events-loading" v-if="logs_loading">
         <scale-loader :height="loading_size" ></scale-loader>
      </span>
      </h2>
    </header>

    <div class="panel-body events-list-body" style="display: block;" >
      <div class="table-responsive" >
        <perfect-scrollbar>
          <div style="display: none">{{log_counter}}</div>
          <table class="table table-striped table-hover mb-none table-event" v-if="logs.length > 0">
            <thead class="event-log-head">
            <tr>
              <th></th>
              <th><translate-component :text="lang.events_component.vehicle"/></th>
              <th><translate-component :text="lang.events_component.type"/></th>
              <th><translate-component :text="lang.events_component.name"/></th>
              <th><translate-component :text="lang.events_component.data_time"/></th>
              <th class="check-log"><translate-component :text="lang.events_component.status"/></th>
              <th><translate-component :text="lang.events_component.accept_by"/></th>
              <th><translate-component :text="lang.events_component.accept_at"/></th>
              <th class="log-mes"><translate-component :text="lang.events_component.message"/></th>
            </tr>
            </thead>
            <tbody>


            <template v-for="(log,index) in logs">
              <tr :key="log.id" class="event-log-list">
                <td class="event-log-btn"><button type="button" class="btn btn-xs btn-success event-btn" :class="log.cell[5] ===1?'hidelog': ''" @click="logAccept(log.id,index)">{{lang.events_component.accept}}</button></td>
                <td class="event-log-vehicle"><span class="fa fa-car event-table-icon"></span>{{ checkName(log.cell[1])}}</td>
                <td class="event-log-type">{{lang.events_component['type_'+log.cell[2]]}}</td>
                <td class="event-log-name">{{log.cell[3]}}</td>
                <td class="event-log-date"><span class="fa fa-clock-o event-table-icon"></span>{{log.cell[4]}}</td>
                <td class="check-log event-log-status">  <span class="check-box" :class="log.cell[5] ===1?'active': ''">
                    <svg-chek-icon  v-if="log.cell[5] ===1 "/>
                      <svg-minus-icon v-else/>
                  </span><span class="event-log-status-text"><translate-component :text="lang.events_component.status"/></span></td>
                <td class="event-log-by" :class="log.cell[5] ==1?'': 'hidelog'"><span class="fa fa-user event-table-icon"></span><span class="event-log-status-text"><translate-component :text="lang.events_component.accept_by"/></span>{{log.cell[6]}}</td>
                <td class="event-log-at" :class="log.cell[5]==1?'': 'hidelog'">{{log.cell[7]}}</td>
                <td class="log-mes">{{log.cell[8]}}</td>
              </tr>
            </template>
            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="panel-footer" >
      <div class="btn-group-scroll ">

        <button type="button" class="btn  button-scroll " @click="ScrollList2(-1)">
          <span class="button-scroll-icon button-scroll-icon-left fa fa-chevron-left"></span>
        </button>
        <div class="btn  button-scroll button-scroll-center hidelog">
          <translate-component :text="lang.geo_zone_component.page"/>
          <span class="page-count"><input type="number" class="form-control"
                                          :value="this.log_page" disabled> </span></div>
        <button type="button" class="btn  button-scroll button-scroll-right" @click="ScrollList2(1)"> <span class="button-scroll-icon button-scroll-icon-right fa fa-chevron-right"></span></button>
        <div class="btn  button-scroll button-scroll-total" @click="ScrollList2('max')"><translate-component :text="lang.events_component.total_page"/> <span style="padding-left: 5px">{{ this.log_totalpages }}</span></div>
      </div>
    </div>

    </div>

    </template>
  </section>
</template>

<script>
//IMPORTANT
//vue-range-component REMOVE FUNCTION handleKeyup  WORK!!!!!!!!!!!
import {mapGetters} from "vuex";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader";
import SvgChekIcon from "@/components/ui/icons/svgChekIcon";
import SvgMinusIcon from "@/components/ui/icons/svgMinusIcon";
import helpers from "@/mixins/helpers";
import GeozoneListComponent from "@/components/geozone/GeozoneListComponent";
import VueRangeSlider from 'vue-range-component'

export default {
  name: "EventsComponent",
  components: {GeozoneListComponent, SvgMinusIcon, SvgChekIcon, TranslateComponent, ScaleLoader,VueRangeSlider,},
  mixins: [helpers],
  data() {
    return {
      event_type:0,
      maintenanceLimit:1500,
      maintenanceStart:0,
      tooltipMerge:false,
      enableCross:false,
      time_add:{
        start1:0,
        start2:0,
        end1:23,
        end2:59,
      },
      range_mes:{
        min:0,
        max:250,
        mes:' km/h',
      },
      choose_options:[1,10,11,30,12,13,14,15,16,17,18,19,40],
      log_counter:0,
      event_counter:0,
      success_log:false,
      error_log:false,
      error_text:{},
      objects:{},
      vehicles:{},
      zone_counter:0,
      zones:{},
      full_event:true,
      small_tema:true,
      create_page:1,
      stop_page:5,
      create:false,
      edit:false,
      base_url:base_url,
      logs_loading: true,
      events_loading: true,
      loading_size: "18px",
      events: [],
      records:0,
      rows:100,
      page:1,
      sord:'desc',
      _search: false,
      totalpages:1,
      log_records:0,
      log_rows:100,
      log_page:1,
      log_sord:'desc',
      log_search: false,
      log_totalpages:1,
      logs:[],
      start_event:1,
      end_event:0,
      log_start_event:1,
      log_end_event:0,
      option_val:[31,5,1,4,15,2,8,9,10],
      days:[0,1,2,3,4,5,6],
      range:[0,90],
      params:{
        EventId: 0,
        Name: '',
        Type: 31,
        UtcOffset: 2,
        Enabled: 1,
        SendToEmail: 0,
        sendSms: 0,
        AlertOnSite: 1,
        Email:'',
        GsmNumber:'',
        OffsetAdjust: 1,
      },
    params_options:{
      _objects:[],
      Message1:'',
      Message0:'',
      sendThisTextOnly:0,
      _zones:[],
      Delay:0,
      Option:1,
    },
      reminderDays:0,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      show_events_list:'SHOW_EVENTS_LIST',
    add_objects: 'OBJECTS',
      current_resources:'CURRENT_RESOURCES',
    }),
  },
  mounted() {
    let _this = this
    this.$store.commit('SET_ACTIVE_ROUTE', 'events');
    this.$store.commit('SET_SHOW_OBJECT_CONTENT', true);
    this.getAllEvents()
    this.getAllLogs()
    setTimeout(function () {

      _this.objects = [..._this.add_objects]

    }, 500);
  },
  watch: {
    show_events_list(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getAllEvents()
        this.getAllLogs()
      }
    },
  },
  methods: {
    checkName(ObjectId){
      if (ObjectId === "MA-9779") {
        return 'demo_1'
      }
      if (ObjectId=== "RE-28") {
        return'demo_2'
      }
      if (ObjectId === "KF-150") {
        return 'demo_3'
      }
      return  ObjectId
    },
    onChangeRange(){
      //choose_options:[1,10,11,30,12,13,14,15,16,17,18,19,40],
      if(this.params_options.Option === 1){
        this.range_mes = {min:0,max:250,mes:' km/h' }
        this.range=[0,90]
      }
      if(this.params_options.Option === 10){
        this.range_mes = {min:0,max:35,mes:' V' }
        this.range=[0,12]
      }
      if(this.params_options.Option === 11){
        this.range_mes = {min:-100,max:100,mes:' C' }
        this.range=[0,10]
      }
      if(this.params_options.Option === 11){
        this.range_mes = {min:-80,max:100,mes:' %' }
        this.range=[0,100]
      }
      if(this.params_options.Option === 12 || this.params_options.Option === 13 || this.params_options.Option === 14 || this.params_options.Option === 15){
        this.range_mes = {min:0,max:1,mes:' ' }
        this.range=[0,1]
      }
      if(this.params_options.Option === 62 || this.params_options.Option === 17 || this.params_options.Option === 18 || this.params_options.Option === 19){
        this.range_mes = {min:0,max:50000,mes:' mV' }
        this.range=[0,5000]
      }
      if(this.params_options.Option === 40){
        this.range_mes = {min:0,max:1,mes:' ' }
        this.range=[0,1]
      }
    },
    onChange() {
      if(this.params.Type != 31 && this.params.Type != 5 && this.params.Type != 15 &&  this.params.Type != 8 &&  this.params.Type !== 9 &&  this.params.Type !== 10){
        this.stop_page = 4
      }
    },
    eventEdit(event_id,index){
      let formData = new FormData()
      formData.append('EventId', event_id)
       let $this = this
      axios.post(base_url + '/eventmanager/get/', formData).then(response => {
        if(response.data['error'] == 0){
          let datas = response.data

          $this.params= {
            EventId: datas.EventId,
                Name: datas.Name,
                Type: datas.Type,
                UtcOffset:datas.UtcOffset,
                Enabled: datas.Enabled,
                SendToEmail:datas.SendToEmail,
                AlertOnSite: datas.AlertOnSite,
                Email:datas.Email,
                OffsetAdjust: datas.OffsetAdjust,
          }
          if(datas.sendSms){
            $this.params.sendSms= datas.sendSms
          }else{
            $this.params.sendSms= 0
          }
          if(datas.sendSms){
            $this.params.GsmNumber = datas.GsmNumber
          }else{
            $this.params.GsmNumber = ''
          }
          $this.days =[]
          let d, dne;
          for(d = 1;d<8;d++) {
            dne = Math.pow(2, d - 1);
            if ((dne & datas.days) != 0) {
              $this.days.push(d-1)
            }
          }
          let ts = $this.secondsToTime(datas.timeStart);
          let te = $this.secondsToTime(datas.timeStart+datas.timeDuration);
          if (te.h>23) {
            te.h = Math.abs(24-te.h);
          }

          $this.time_add ={
                start1:$this.pad(ts.h, 2),
                start2:$this.pad(te.h, 2),
                end1:$this.pad(ts.m, 2),
                end2:$this.pad(te.m, 2),
          }
          if(datas.Options){

            for (const [key, value] of Object.entries( datas.Options)) {
              if(key === '_info' || key === '_zones_info'){
              }
              else if(key === '_zones' ){
                let i = 0
                value.map(item=>{
                  $this.params_options['_zones'].push(item)
                  $this.zones[item] =[]
                  $this.zones[item] = [item,datas.Options['_zones_info'][i]['Address'],datas.Options['_zones_info'][i]['Name']]
                      i++
                })
              }
              else if(key === '_objects' ){
                if(Array.isArray(value) ){
                  let k = 0
                  value.map(item=>{
                    $this.params_options['_objects'].push(item)
                    $this.vehicles[item] = $this.objects.find(x => x.ObjectId == item);
                    k++
                  })
                }else{
                  for (const [key, value2] of Object.entries( value)) {
                      $this.params_options['_objects'].push(value2)
                      $this.vehicles[value2] = $this.objects.find(x => x.ObjectId == value2);
                    }
                }

              }
              else{
                $this.params_options[key] = value
              }

            }
            $this.onChangeRange()
          }
          $this.onChange()
          $this.edit = true
          $this.create = true


        }

      }).catch(error => {
        console.log(error)
      })
     },
    pad(number, length) {

      return new Array((length || 2) + 1 - String(number).length).join(0) + number;
    },
    secondsToTime(secs) {
      secs = Math.round(secs)
      let hours = Math.floor(secs / (60 * 60))

      let divisor_for_minutes = secs % (60 * 60)
      let minutes = Math.floor(divisor_for_minutes / 60)

      let divisor_for_seconds = divisor_for_minutes % 60
      let seconds = Math.ceil(divisor_for_seconds)

      if (hours < 10)
        hours = '0' + hours

      if (minutes < 10)
        minutes = '0' + minutes

      if (seconds < 10)
        seconds = '0' + seconds

      let obj = {
        'h': hours,
        'm': minutes,
        's': seconds
      }
      return obj
    },

    logAccept(log_id, index) {
      let $this = this
      let message = this.lang.events_component.log_text
      this.$iosConfirm({
        text: message
      }).then(function () {
        let formData = new FormData();
        formData.append('Alerts[]', log_id)
        axios.post(base_url + '/eventmanager/accept/', formData).then(response => {
          if (response.data['error'] == 0) {
            $this.getAllLogs()
            $this.log_counter++
          }

        }).catch(error => {
          console.log(error)
        })

      })
    },
    eventDelete(event_id, index) {
      let $this = this
      let message = this.lang.events_component.delete_text
      this.$iosConfirm({
        text: message
      }).then(function () {

        let formData = new FormData();
        formData.append('EventId', event_id)
        axios.post(base_url + '/eventmanager/delete/', formData).then(response => {
          if (response.data['error'] == 0) {
            $this.events.splice(index, 1)
            $this.event_counter++

          }

        }).catch(error => {
          console.log(error)
        })

      })


    },
    closeError(){
      this.error_log = false
    },
    saveEvent(){
      this.error_log = false
      this.validateData()
      if(!this.error_log ){
     this.sendEvent();
      }
    },
    validateData(){
      this.error_text={}
      if(!this.params.Name || this.params.Name == ''  ){
        this.error_log = true
        this.error_text['Name'] =this.lang.events_component.val_1
      }

        if (this.params_options['_objects'].length === 0) {
          this.error_log = true
          this.error_text['_objects'] = this.lang.events_component.val_2
        }

      if(this.params.Type == 31 && this.params.Type == 15) {
        if(this.params_options['_zones'].length === 0){
          this.error_log = true
          this.error_text['_zones'] =this.lang.events_component.val_3
        }
      }
      if(this.params.sendSms === 1 && this.params.GsmNumber.length === 0){
        this.error_log = true
        this.error_text['GsmNumber'] =this.lang.events_component.val_4
      }
      if(this.params.SendToEmail === 1 && this.params.Email.length === 0){
        this.error_log = true
        this.error_text['Email'] =this.lang.events_component.val_5
      }
    },
    sendEvent(){
      let $this = this
      let formData = new FormData();
      Object.keys($this.params).forEach(function (key) {
        formData.append(key, $this.params[key])
      });
      Object.keys($this.params_options).forEach(function (key) {
        let item = $this.params_options[key]
        if(Array.isArray(item)){
          for(let i =0; i<item.length;i++){
            formData.append('Options['+key+']['+i+']', item[i])
          }
        }else{
          formData.append('Options['+key+']', item)
        }
      });
      let count_days = 0
      this.days.map(item=>{
        count_days+= Math.pow(2,Number(item));
      })
      formData.append('days', count_days)

      let ts1 = this.checkDateTime(this.time_add.start1)
      let ts3 = this.checkDateTime(this.time_add.end1)

      let ts2 = this.checkDateMinutes(this.time_add.start2)
      let ts4 = this.checkDateMinutes(this.time_add.end2)

     let timeStart = ts1 * 3600 + ts3 * 60;
     let timeEnd = ts2 * 3600 + ts4 * 60;

      formData.append('timeStart', timeStart)
      formData.append('timeEnd', timeEnd)
      let timeDuration =0
      if (timeEnd > timeStart) {
        timeDuration = timeEnd - timeStart;
      } else {
        timeDuration= 86400-timeStart+ timeEnd;
      }
      formData.append('timeDuration', timeDuration)
      if(this.params.Type === 5){
          formData.append('Options[min]', $this.range[0])
          formData.append('Options[max]', $this.range[1])

      }
      if(this.params.Type === 8 || this.params.Type === 9){
        formData.append('Options[reminderDays]', $this.reminderDays)
      }
      if(this.params.Type === 10){
        formData.append('Options[maintenanceLimit]', $this.maintenanceLimit)
        formData.append('Options[maintenanceStart]', $this.maintenanceStart)
      }
      axios.post(base_url + '/eventmanager/save/', formData).then(response => {

        if(response.data['error'] == 0){
          $this.error_log = false
          $this.success_log = true

          setTimeout(function () {
            $this.success_log = false
            $this.getAllEvents()
            $this.cancelEvent()

          }, 1500);
        }

      }).catch(error => {
        console.log(error)
      })

    },
    checkDateTime(num){
      if(num <0){
        return 0
      }
      if(num>23){
        return 23
      }
      return num
    },
    checkDateMinutes(num){
      if(num <0){
        return 0
      }
      if(num>59){
        return 59
      }
      return num
    },
    changeFull(){
      this.full_event = !this.full_event
    },
    handlePolyCheckbox2(zone){
      let id = zone.id
      if (!this.params_options['_zones'].includes(id)) {
        this.params_options['_zones'].push(zone.id)
      }
      this.zones[zone.id] = zone.cell
      this.zone_counter++
    },
    removeZone(id) {
      this.$delete(this.zones, id)

      for(let i = 0; i < this.params_options['_zones'].length; i++) {
        this.params_options['_zones'].splice(i, 1)
        break
      }
      this.zone_counter++
    },
    removeVehicle(id) {
      this.$delete(this.vehicles, id)
      for(let i = 0; i < this.params_options['_objects'].length; i++) {
        this.params_options['_objects'].splice(i, 1)
        break
      }

      this.zone_counter++
    },
    addVehicle(obj,index){
      let id = obj.ObjectId
      this.vehicles[id] = obj;
      if ( !this.params_options['_objects'].includes(id)) {
        this.params_options['_objects'].push(id)
      }
      this.zone_counter++
    },
    cancelEvent(){
      this.success_log = false
      this.edit = false
      this.create = false
      this.error_log = false
      this.error_text={}
      this.create_page=1
      this.vehicles = {}
      this.zones = {}
      this.params={
        EventId: 0,
            Name: '',
            Type: 31,
            UtcOffset: 2,
            Enabled: 1,
            SendToEmail: 0,
            sendSms: 0,
            AlertOnSite: 1,
            Email:'',
            GsmNumber:'',
            OffsetAdjust: 1,
      }
      this.params_options ={
            _objects:[],
            Message1:'',
            Message0:'',
            sendThisTextOnly:0,
            _zones:[],
            Delay:0,
            Option:1,
      }
      this.time_add ={
            start1:0,
            start2:0,
            end1:23,
            end2:59,
      }
      this.days = [0,1,2,3,4,5,6]
    },
    ScrollPage(num){
      if(num<0){
        if(this.create_page>1){
          this.create_page =this.create_page+num
        }
      }else{
        if( this.create_page< this.stop_page){
          this.create_page =this.create_page+num
        }
      }
    },
    createEvent(){

      this.create = !this.create
    },
    ScrollList(num){
      if(num=== 'max'){
        this.page =this.totalpages
      }else{
        this.page =  this.page+num
      }

      this.getAllEvents()
    },
    ScrollList2(num){
      if(num=== 'max'){
        this.log_page =this.log_totalpages
      }else {
        this.log_page = this.log_page + num
      }
      this.getAllLogs()

    },
    getAllEvents(){
      let $this = this
      let formData = new FormData();
      let date_now  = new Date().getTime();
      formData.append('rows', this.rows)
      formData.append('page',this.page)
      formData.append('sord',this.sord)
      formData.append('_search',this._search)
      formData.append('Only',-1)
      formData.append('nd',date_now)
      if(this.checkObjectLength(this.show_events_list)){
        Object.keys(this.show_events_list).forEach(function(key) {
          formData.append('SelectedObjects[]',key)
        });
      }
      axios.post(base_url + '/eventmanager/getevents/', formData).then(response => {
        if(response.data && response.data.rows){
          $this.events = response.data.rows
          $this.page =response.data.page
          $this.records =response.data.records;
          $this.totalpages =response.data.totalpages;
          if($this.page >1) {
            $this.start_event = (($this.page-1) * 100)+1;
          }else{
            $this.start_event =1
          }
              $this.end_event= $this.start_event+$this.events.length-1;
      }

        $this.events_loading =false

      }).catch(error => {
        console.log(error)
      })
    },
    getAllLogs(){
      let $this = this
      let formData2 = new FormData();
      let date_now  = new Date().getTime();
      formData2.append('rows', this.log_rows)
      formData2.append('page',this.log_page)
      formData2.append('sord',this.log_sord)
      formData2.append('_search',this.log_search)
      formData2.append('Only',-1)
      formData2.append('nd',date_now)
      if(this.checkObjectLength(this.show_events_list)){
        Object.keys(this.show_events_list).forEach(function(key) {
          formData2.append('SelectedObjects[]',key)
        });
      }
      axios.post(base_url + '/eventmanager/geteventslog/', formData2).then(response => {
        if(response.data && response.data.rows) {
          $this.logs = response.data.rows
          $this.log_page = response.data.page
          $this.log_records = response.data.records;
          $this.log_totalpages = response.data.totalpages;

          if ($this.log_page > 1) {
            $this.log_start_event =  (($this.log_page-1) * 100)+1;
          }else{
            $this.log_start_event =1;
          }
          $this.log_end_event = $this.log_start_event + $this.logs.length - 1;
        }
        $this.logs_loading =false

      }).catch(error => {
        console.log(error)
      })
    },

  }
}
</script>

<style scoped>
.button-cover
{
  display: none;
}
.arrow-control{
  width: 20px;
  height: 20px;
  position: absolute;
  top: 5px;
  right: 10px;
}
.arrow-control svg{
  fill:#1C71DA;
}
.panel-body.events-list-body{
  height: 100%;
  overflow-y: auto;
  margin: 0;
  max-height: 35vh;
}
.panel.events-panel{
  background-color: #fff;
  position: fixed;
  z-index: 1000000;
  right: 0;
  top: 60px ;
  bottom: 0;
  left: auto;
  width: calc(100% - 470px);
  display: flex;
  flex-direction: column;
  transition: width 300ms ease;
  margin-bottom: 0;
}
.panel.events-panel.hidden-top{
  bottom: auto;
  width: 300px;
  left: 500px;
}
.box-events-panel{
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.panel-heading.events-head {
  background: #e5f3f9;
  border-bottom: 1px solid #e5f3f9;
  padding: 10px 20px;
  min-height: 65px;
  display: flex;
  align-items: center;
  color: #777;
}
.btn-report-close {
  background-color: transparent;
}
html .panel .panel-heading-transparent .panel-actions {
  position: absolute;
  right: 15px;
}
.ps {
  height: 30vh;
}
.big-group-scroll,.btn-group-scroll {
  width: 100%;
  justify-content: start;
}
.big-group-scroll .btn, .btn-group-scroll .btn{
 margin-right: 10px;
}
.event-btn {
  margin-right: 5px;
}
.panel-footer {
  border-radius: 0 0 5px 5px;

}
.check-box{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.check-box svg {
  width: 18px;
  fill: #cfcfcf;
}
.check-box.active svg {
  fill: #47a447;
}
.log-mes{
  min-width: 200px;
  max-width: 200px;
}
.check-log{
  text-align: center;
  width: 110px;
}
.table.table-event th,.table-event td {
 padding: 6px !important;
}
.create-event-box{
  height: 100%;
}
ul.widget-todo-list li {
  padding: 2px 15px 2px 20px;

}
ul.widget-todo-list li .todo-actions {
  position: absolute;
  top: 2px;
  right: 20px;
  bottom: 2px;
}
ul.widget-todo-list li .todo-actions .todo-remove {
  font-size: 12px;
}
.event-vehicle-number{
  padding-left: 5px;
  font-weight: bold;
  color: #1C71DA;
}
.object-text{
  display: flex;
}
.object-text__subitle{
  padding-left: 5px;
  padding-right: 5px;
  color:#1C71DA;
  font-weight: bold;
}
.checkbox-custom{
  cursor: pointer;
}
.line-group{
  display: flex;
  align-items: center;
}
.line-group .checkbox{
  padding-left: 5px;
  padding-right: 5px;
}
.span-title{
  padding-left: 5px;
  padding-right: 5px;
}
.hhmmhh.form-control{
width: 60px;
}
.maintext-text.form-control{
  width: 100px;
}
.divDaysTimeSettings{
  display: flex;
}
.time-text{
  padding-left: 5px;
  padding-right: 5px;
}
.checkbox {
  margin-top: 0;
 margin-bottom: 0;
}
.route-setting_input-helper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.route-setting_input-helper .min, .route-setting_input-helper .max {
  border-radius: 3px;
  background-color: #ddd;
  padding: 2px 5px;
}
.route-setting_input-box span {
  font-weight: 600;
  font-size: 14px;
}
.ranger-box{
  margin-top: 30px !important;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.inspection-text{
  margin-right: 10px;
  margin-left: 10px;
}
.inspection-box{
  display: flex;
  flex-wrap: wrap;
}
.arrow-control {
  top: 0px;
}
.event-table-check{
  display: none;
}
.event-table-icon{
  display: none;
}
.event-log-status-text{
  display: none;
}
@media only screen and (max-width: 1500px) {
  .panel-heading.events-head {
    max-width: 100%;
    padding: 5px 15px;
    min-height: 55px;
  }
  .panel.events-panel {
    right: 0;
    top: 42px;
    bottom: 0;
    left: auto;
    width: calc(100% - 420px);
    margin-bottom: 0;
  }

  .event-btn{
    margin-bottom: 3px;
  }
}
@media only screen and (max-width: 600px) {
  .panel.events-panel {
    right: 0;
    top: 60px;
    bottom: 0;
    left: auto;
    width: 100vw;
    height: 100vh;
    margin-bottom: 0;
  }
  .panel.events-panel.hidden-top {
    bottom: auto;
    width: 200px;
    left: 60px;
    height: auto;
    top: 65px;
  }
  .panel-heading.events-head {
    max-width: 100%;
    padding: 5px 10px;
    min-height: 40px;
  }
  .arrow-control {
    width: 15px;
    height: 15px;
  }
  html .panel .panel-heading-transparent .panel-actions{
    position: absolute;
    right: 0px;
    top: 10px;
  }
.event-table-header{
  display: none;
}
  .event-table-check{
    display: inline-block;
  }
  .event-table-icon{
    display: block;
    margin-right: 10px;
    font-size: 16px;
    color: #1C71DA;
  }
  .table-responsive{

  }

  .event-table-list{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
    margin: 2px 5px;
    padding: 4px 10px;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 5px 7px -1px rgb(51 51 51 / 22%);
    background-color: #fff;
    margin-bottom: 10px;
    position: relative;
  }
  .event-table-list td{
    border: none;
  }
  .event-table-name{
    order: 1;
    width: 60%;
    flex-wrap: wrap;
    display: flex;
    justify-content:start;
    font-weight: bold;
    font-size: 18px;
  }
  .event-table-user{
    order: 2;
    width: 40%;
    flex-wrap: wrap;
    display: flex;
    justify-content:end;
    align-items: center;
  }
  .event-table-type{
    order: 3;
    width: 100%;
  }
  .check-log{
    order: 4;
    width: 30%;
    background-color: #e5f3f9;
    border: 1px solid rgb(153, 207, 234);;
    border-radius: 5px;
  }
  .check-box{
  }
  .event-table-date{
    display: none;
  }
  .event-table-update{
    display: none;
  }
  .event-table-clock{
    order: 7;
    width: 30%;
    display: flex;
    justify-content:start;
  }
  .event-table-btns{
    order: 8;
    width: 68%;
    border-top: none;
    display: flex;
    justify-content:end;
  }
  .table-responsive {

    border:none !important;
  }
  .event-log-status-text{
    display: inline-block;
  }
  .event-log-head{
    display: none;
  }
 .event-log-vehicle{
   order: 1;
   width: 50%;
   font-weight: bold;
   color: #1C71DA;
   display: flex;
   justify-content:start;
   align-items: center;
 }
 .event-log-name{
   order: 2;
   width: 50%;
   display: flex;
   justify-content:end;
   align-items: center;
 }
 .event-log-type{
   order: 3;
   width: 100%;
   display: flex;
   justify-content:start;
   align-items: center;
 }
 .log-mes{
   order: 4;
   width: 100%;
   text-align: left;
   font-weight: bold;
 }

.event-log-date{
  order: 5;
  width: 60%;
  display: flex;
  justify-content:start;
  align-items: center;
}
  .event-log-btn{
    order: 6;
    width: 40%;
    display: flex;
    justify-content:end;
  }
  .event-log-by{
    order: 7;
    width: 60%;
    display: flex;
    justify-content:start;
    align-items: center;
  }
  .event-log-at{
    order: 8;
    width: 40%;
    display: flex;
    justify-content:end;
  }
.check-log.event-log-status{
  order: 6;
  width: 40%;
  display: none;
}

 .event-log-list{
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   height: auto;
   margin: 2px 5px;
   padding: 4px 10px;
   overflow: hidden;
   border-radius: 5px;
   box-shadow: 0 5px 7px -1px rgb(51 51 51 / 22%);
   background-color: #fff;
   margin-bottom: 10px;
   position: relative;
 }
  .event-log-list td{
    border: none;
  }
.event-log-status-text{
  padding-right: 10px;
  color: #1C71DA;
}

.hidelog{
  display: none;
}
  .box-events-panel {
    height: 42%;
  }
  .big-group-scroll, .btn-group-scroll {
    justify-content: center;
  }
  .big-group-scroll .btn, .btn-group-scroll .btn {
    margin-right: 15px;
    min-width: 60px;
  }
  .full-height .panel-body.events-list-body{

    max-height: unset;
  }
  .full-height .ps {
    height: calc(100vh - 160px);
    max-height: unset;
  }
  .box-events-panel.second-box.full-second-height{
    min-height: calc(100vh - 60px);
    position: absolute;
    top: 0;
    z-index: 1000;
  }
  .full-second-height .ps {
    height: calc(100vh - 160px);
    max-height: unset;
  }
  .full-second-height .panel-body.events-list-body {
    max-height: unset;
  }
  .toggle-button-cover
  {
    display: table-cell;
    position: relative;
    width: 200px;
    height: 140px;
    box-sizing: border-box;
  }

  .button-cover
  {
    height: 36px;
    width: 74px;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    border-radius: 4px;
    left: auto;
    right: 40px;
    top: 3px;
    position: absolute;
    display: block;
  }

  .button-cover:before
  {
    counter-increment: button-counter;
    content: counter(button-counter);
    position: absolute;
    right: 0;
    bottom: 0;
    color: #d7e3e3;
    font-size: 12px;
    line-height: 1;
    padding: 5px;
  }

  .knobs, .layer
  {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .button
  {
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
  }

  .button.r, .button.r .layer
  {
    border-radius: 100px;
  }

  .button.b2
  {
    border-radius: 2px;
  }

  .checkbox
  {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }

  .knobs
  {
    z-index: 2;
  }

  .layer
  {
    width: 100%;
    background-color: #ffffff;
    transition: 0.3s ease all;
    z-index: 1;
  }

  /* Button 1 */
  #button-1 .knobs:before
  {
    content: 'LIST';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 2px;
    background-color: #03A9F4;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  }

  #button-1 .checkbox:checked + .knobs:before
  {
    content: 'LOGS';
    left: 42px;
    background-color: #f44336;
  }

  #button-1 .checkbox:checked ~ .layer
  {
    background-color: #fcebeb;
  }

  #button-1 .knobs, #button-1 .knobs:before, #button-1 .layer
  {
    transition: 0.3s ease all;
  }
  /* Button 2 */
  #button-2 .knobs:before
  {
    content: 'LIST';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 30px;
    height: 30px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 2px;
    background-color: #03A9F4;
    border-radius: 50%;
    transition: 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15) all;
  }

  #button-2 .checkbox:checked + .knobs:before
  {
    content: 'LOGS';
    left: 42px;
    background-color: #f44336;
  }

  #button-2 .checkbox:checked ~ .layer
  {
    background-color: #fcebeb;
  }

  #button-2 .knobs, #button-2 .knobs:before, #button-2 .layer
  {
    transition: 0.3s ease all;
  }


  .box-events-panel.first-box.full-height{
    min-height: calc(100vh - 100px);
  }

  .panel-footer {

    padding-bottom: 60px;
  }
}



</style>