<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" viewBox="0 0 380.16 380.16" enable-background="new 0 0 380.16 380.16">
    <g>
      <path d="m223.109,63.757c-21.282-21.282-49.578-33.003-79.676-33.003-30.098,0-58.394,11.721-79.676,33.003s-33.003,49.579-33.003,79.677c0,30.098 11.721,58.394 33.003,79.676 21.282,21.282 49.579,33.003 79.676,33.003 30.098,0 58.394-11.721 79.676-33.003 21.282-21.282 33.003-49.579 33.003-79.676 5.68434e-14-30.099-11.721-58.395-33.003-79.677zm-10.606,148.746c-18.449,18.449-42.979,28.61-69.07,28.61-26.091,0-50.621-10.16-69.07-28.61-38.085-38.085-38.085-100.055 0-138.14 18.449-18.449 42.979-28.609 69.07-28.609 26.091,0 50.621,10.16 69.07,28.609 38.085,38.085 38.085,100.055 0,138.14z"/>
      <path d="m189.043,68.306c-3.539-2.152-8.153-1.028-10.305,2.511-2.152,3.539-1.028,8.153 2.511,10.305 4.955,3.013 9.567,6.641 13.71,10.784 1.464,1.465 3.384,2.197 5.303,2.197s3.839-0.732 5.303-2.197c2.929-2.929 2.929-7.677 0-10.606-4.987-4.988-10.546-9.36-16.522-12.994z"/>
      <path d="m86.603,94.103c1.919,0 3.839-0.732 5.303-2.197 16.39-16.39 39.87-24.042 62.804-20.47 4.095,0.638 7.927-2.164 8.565-6.257 0.637-4.093-2.164-7.927-6.256-8.565-27.651-4.305-55.956,4.923-75.719,24.686-2.929,2.929-2.929,7.677 0,10.606 1.464,1.465 3.384,2.197 5.303,2.197z"/>
      <path d="m104.932,205.322c-4.693-2.926-9.075-6.412-13.026-10.363-2.929-2.929-7.678-2.929-10.606,0-2.929,2.929-2.929,7.677 0,10.606 4.756,4.756 10.037,8.957 15.697,12.485 1.234,0.77 2.606,1.136 3.96,1.136 2.503,0 4.95-1.253 6.372-3.533 2.191-3.513 1.118-8.139-2.397-10.331z"/>
      <path d="m194.96,194.959c-15.89,15.89-37.818,23.484-60.165,20.833-4.113-0.49-7.844,2.451-8.331,6.564-0.488,4.113 2.451,7.843 6.564,8.331 3.53,0.419 7.047,0.625 10.544,0.625 23.193,0 45.343-9.097 61.994-25.747 2.929-2.929 2.929-7.678 0-10.607-2.928-2.927-7.677-2.927-10.606,0.001z"/>
      <path d="m371.055,327.024l-90.768-90.768c-2.929-2.929-7.678-2.929-10.606,0l-3.631,3.631-8.907-8.907c43.357-56.232 39.279-137.498-12.24-189.017-55.95-55.951-146.989-55.951-202.939,0-55.951,55.95-55.951,146.989 0,202.939 27.975,27.975 64.723,41.963 101.47,41.963 30.925,0 61.85-9.909 87.547-29.723l8.907,8.907-3.631,3.631c-1.407,1.406-2.197,3.314-2.197,5.303s0.79,3.897 2.197,5.303l90.768,90.768c6.07,6.07 14.042,9.104 22.016,9.104 7.972,0 15.946-3.035 22.016-9.104 12.138-12.138 12.138-31.89-0.002-44.03zm-318.486-92.728c-24.271-24.271-37.637-56.54-37.637-90.863s13.367-66.593 37.637-90.863c24.271-24.27 56.54-37.637 90.863-37.637s66.594,13.367 90.864,37.637c24.271,24.271 37.637,56.54 37.637,90.863s-13.366,66.593-37.637,90.863c-24.271,24.27-56.54,37.637-90.863,37.637s-66.593-13.366-90.864-37.637zm189.827,13.05c0.843-0.803 1.679-1.616 2.506-2.444s1.641-1.664 2.444-2.506l8.097,8.097-4.95,4.95-8.097-8.097zm118.053,113.103c-6.292,6.291-16.527,6.29-22.818,0l-85.465-85.465 22.818-22.818 85.465,85.465c6.291,6.291 6.291,16.527-5.68434e-14,22.818z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "svgSearchIcon"
}
</script>

<style scoped>

</style>