<template>
  <div  >
    <div class="panel-heading geo-zone" :class="small_tema?'small_tema':''">

      <div class="input-group input-search">
        <span v-if="small_tema"><translate-component :text="lang.events_component.select_zone"/></span>
        <input type="text" class="form-control" :placeholder="lang.geo_zone_component.search"
               :value="zone_list_options.simple_search" @input="handlerSearchInput($event)">
        <span class="input-group-btn">
<button class="btn btn-default" type="submit"><i class="fa fa-search"></i>
</button>
</span>
      </div>
      <div class="input-group-arrow"><span class="fa fa-times" @click="getShow()" v-if="!small_tema"></span></div>
    </div>
    <div class="panel-body"  :class="small_tema?'small_tema':''">
      <div class="content">
        <template v-if="zone_list.length >0">
          <perfect-scrollbar  :suppressScrollX="onScroll" :class="small_tema?'small_tema':''">
            <ul class="geo-zone-scroll-list big "  v-if="!small_tema">
              <li class="geozone-list" v-for="(zone,index) in zone_list" :key="zone.id">

                <span class="index-label">
                  <div class="checkbox-custom checkbox-default"><span style="display: none">{{ zone_count }}</span>
<input type="checkbox" @click="handlePolyCheckbox2(zone)" :checked="zone.show" :id="'checkbox'+zone.id">
<label :for="'checkbox'+zone.id"></label>
</div>
                </span>

                <span class="title">{{ zone.cell[2] }}</span>
                <div class="zone-group__box " v-if=" zone.cell[4] &&  zone.cell[4].length > 0 ">
                  {{ zone.cell[4] }}
                </div>


                <div class="object-star__box zone-star "
                     :class="zone.cell[4] &&  zone.cell[4].length > 0 ?'' : 'geozone-bnt-left'"><i
                    class=" fas fa-star"
                    :class="(zone.cell[10] && zone.cell[10] ===true)? 'star-gold': ''"
                    @click="addFavorit(index,zone.id)"></i>
                </div>

                <button type="button" class="mb-xs mt-xs mr-xs btn btn-default  geozone-bnt"
                        @click="handlerPolyLocation2(zone)"><i
                    class="fa fa-info"></i></button>


                <template v-if="current_resources && current_resources[15] && current_resources[15].enabled ===true">
                <button type="button" class="mb-xs mt-xs mr-xs btn btn-default geozone-bnt"
                        @click="deletGeoZone2(zone.id,index)"><i class="fa fa-times"></i></button>
                </template>
              </li>
            </ul>

            <ul class="geo-zone-scroll-list big small_tema" v-if="small_tema">
              <li class="geozone-list" v-for="(zone,index) in zone_list" :key="zone.id">

                <span class="title" @click="handlePolyCheckbox2(zone)" style="cursor: pointer">{{ zone.cell[2] }}</span>
                <div class="zone-group__box " v-if=" zone.cell[4] &&  zone.cell[4].length > 0 ">
                  {{ zone.cell[4] }}
                </div>

                <div class="object-star__box zone-star "
                     :class="zone.cell[4] &&  zone.cell[4].length > 0 ?'' : 'geozone-bnt-left'"><i
                    class=" fas fa-star"
                    :class="(zone.cell[10] && zone.cell[10] ===true)? 'star-gold': ''"></i>
                </div>

                <button type="button" class="mb-xs mt-xs mr-xs btn btn-default  geozone-bnt"
                        @click="handlerPolyLocation2(zone)"><i
                    class="fa fa-info"></i></button>

              </li>
            </ul>
          </perfect-scrollbar>
        </template>
        <template v-else>
          <ul class="geo-zone-scroll-list">
            <li class="geozone-list">
              <span class="title"><translate-component :text="lang.geo_zone_component.empty_title"/> </span>
            </li>
          </ul>
        </template>
      </div>
    </div>
    <div class="panel-footer geo-zone " :class="small_tema?'small_tema':''">

      <div class="btn-group-scroll ">

        <button type="button" class="btn  button-scroll button-scroll-left " @click="sortByFavorite()">
          <span class="fas fa-star " :class="zone_list_options.is_favorite ? 'star-gold': ''"></span>
        </button>
        <button type="button" class="btn  button-scroll " @click="ScrollList(-1)">
          <span class="button-scroll-icon button-scroll-icon-left fa fa-chevron-left"></span>
        </button>
        <div class="btn  button-scroll button-scroll-center">
          <translate-component :text="lang.geo_zone_component.page"/>
          <span class="page-count"><input type="number" class="form-control"
                                          @blur="handlerScrollInput($event)"
                                          v-on:keyup.enter="handlerScrollInput($event)"
                                          :value="this.zone_list_options.current_page"> </span></div>
        <button type="button" class="btn  button-scroll button-scroll-right" @click="ScrollList(1)"><span
            class="button-scroll-icon button-scroll-icon-right fa fa-chevron-right"></span></button>
        <div class="btn  button-scroll button-scroll-total">{{ this.zone_list_options.totalpages }}</div>
      </div>


    </div>
  </div>
</template>

<script>
import axios from "axios";
import {mapGetters} from "vuex";
import TranslateComponent from "@/components/dashboard/TranslateComponent";

export default {
  name: "GeozoneListComponent",
  props: {
    small_tema:{
      default:false,
    },

  },
  components: {TranslateComponent},
  data(){
    return {
      onScroll:true,
      zone_count: 0,
      base_url: base_url,
      zone_list_options: {
        rows: 100,
        page: 1,
        sidx: 'ZoneId',
        sord: 'desc',
        totalpages: 0,
        current_page: 1,
        simple_search: '',
        total: 0,
        is_favorite: false,
        zone_count: 0,
      },
      zone_list: [],
      show_list: false,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      show_geo_zone: 'SHOW_GEO_ZONE',
      polygon_zones: 'POLYGON_ZONES',
      show_weather: 'SHOW_WEATHER',
      create_path: 'CREATE_PATH',
      objects: 'OBJECTS',
      start_center: 'START_CENTER',
      map_zoom: 'MAP_ZOOM',
      show_all_zones: 'SHOW_ALL_ZONES',
      current_resources:'CURRENT_RESOURCES',
    }),

  },
  mounted() {
    this.getGeoZoneList();
    if(this.small_tema === true){
      this.onScroll =false
    }

  },
  methods:{
    getShow(){
      this.$emit('closelog');
    },
    handlerSearchInput(event) {
      this.zone_list_options.simple_search = event.target.value
      if (this.zone_list_options.simple_search.length > 2) {
        this.getGeoZoneList(true);

      }
      if (this.zone_list_options.simple_search.length === 0) {
        this.getGeoZoneList();
      }

    },
    getGeoZoneList(search = false, is_favorite = false) {
      let $this = this;
      let formData = new FormData();
      formData.append('rows', $this.zone_list_options.rows);
      formData.append('page', $this.zone_list_options.page);
      formData.append('sidx', $this.zone_list_options.sidx);
      formData.append('sord', $this.zone_list_options.sord);
      if (search === true) {
        formData.append('SimpleSearch', $this.zone_list_options.simple_search);
      }
      if (is_favorite === true) {
        formData.append('is_favorite', true);
      }

      axios.post(base_url + '/geozones/getlist/', formData
      ).then(response => {

        if (response.data.rows) {
          $this.zone_list_options.total = response.data.records
          $this.zone_list_options.totalpages = response.data.totalpages
          $this.zone_list = response.data.rows
        }

      }).catch(error => {
        console.log(error)
      })
    },
    sortByFavorite() {

      this.zone_list_options.is_favorite = !this.zone_list_options.is_favorite
      if (this.zone_list_options.is_favorite === true) {
        this.getGeoZoneList(false, true);
      } else {
        this.getGeoZoneList();
      }
    },
    ScrollList(num) {
      let check = 0;
      let add = parseInt(this.zone_list_options.page)
      if (num === -1) {


        if (add > 1) {
          this.zone_list_options.page = add + num
          this.zone_list_options.current_page = this.zone_list_options.page
          check++
        } else {

        }
      } else {
        if (add === this.zone_list_options.totalpages) {

        } else {
          this.zone_list_options.page = add + num
          this.zone_list_options.current_page = this.zone_list_options.page
          check++
        }

      }
      if (check > 0) {
        this.getGeoZoneList()
      }

    },
    handlerScrollInput(event) {


      //this.zone_list_options.current_page = event.target.value
      let num = event.target.value
      if (num > 0 && num <= this.zone_list_options.totalpages) {
        this.zone_list_options.page = num
        this.zone_list_options.current_page = num

      } else {
        if (num > this.zone_list_options.totalpages) {
          this.zone_list_options.page = this.zone_list_options.totalpages
          this.zone_list_options.current_page = this.zone_list_options.totalpages
        }
        if (num <= 0) {
          this.zone_list_options.page = 1
          this.zone_list_options.current_page = 1
        }
      }
      this.getGeoZoneList()

    },
    deletGeoZone2(zone_id, index) {

      let message = this.lang.geo_zone_component.confirm_delete_zone;
      let $this = this
      this.$iosConfirm({
        text: message
      }).then(function () {

        $this.deleteZone(zone_id, index)

      })

    },
    deleteZone(zone_id, index) {

      let formData = new FormData();
      formData.append('ZoneData[Ids][]', zone_id);

      axios.post(base_url + '/geozones/deletezone/', formData
      ).then(response => {
        //console.log(response.data)
        if (response.data) {

          this.zone_list.splice(index, 1)
        }

      }).catch(error => {
        console.log(error)
      })

    },
    addFavorit(index, zone_id) {

      let star = true;
      if (this.zone_list[index]) {
        if (this.zone_list[index].cell[10] && this.zone_list[index].cell[10] === true) {
          star = false;
        }
      }


      axios.get(base_url + '/geozones/set-favorite?zone_id=' + zone_id + '&is_favorite=' + star
      ).then(response => {
        //console.log(response.data)
        if (response.data.success === true) {
          // console.log(this.zone_list[index])
          this.zone_list[index].cell[10] = star;
          ///this.$set(this.zone_list, [index].cell[10], star)
          this.zone_count++;
        }

      }).catch(error => {
        console.log(error)
      })


    },
    handlerPolyLocation2(old_zone) {
      if (!this.polygon_zones[old_zone.id]) {

        let $this = this;
        let formData = new FormData();
        let zone_id = old_zone.id
        formData.append('ZoneData[Ids][]', zone_id);
        axios.post(base_url + '/geozones/getzoneinfo/', formData
        ).then(response => {

          if (response.data) {

            let zone = response.data[zone_id]
            let item = {}
            item.monitoring = {}
            item.info = zone
            let status = zone_id
            item.status = status;
            item.show = true;
            item.name = zone['Name'];
            item.color = '#FF7043'
            this.$store.commit('SET_MAP_ZOOM', 12);

            //console.log(item)

            if (zone.data['Type'] === 1) {
              if (zone.data['r'] == 0) {
                item.type = 'marker'
              } else {
                item.type = 'circle'
              }
              item.latlng = [];
              item.latlng.push(zone.data['y']);
              item.latlng.push(zone.data['x']);
              item.mRadius = zone.data['r'] * 1000
              item.center = [];
              item.center.push(zone.data['y']);
              item.center.push(zone.data['x']);


            }
            if (zone.data['Type'] === 2) {
              item.type = 'polygon'
              item.latlngs = [];
              zone.data.forEach(element => {
                let it = [];
                it.push(element['Y'])
                it.push(element['X'])
                item.latlngs.push(it)
              });
              item.center = [];
              item.center.push(zone['y']);
              item.center.push(zone['x']);

            }
            if (zone.data['Type'] === 0) {
              item.type = 'rectangle'
              item.latlngs = [];
              let it1 = [];
              it1.push(element['Y1'])
              it1.push(element['X1'])
              item.latlngs.push(it1)

              let it2 = [];
              it2.push(element['Y1'])
              it2.push(element['X2'])
              item.latlngs.push(2)

              let it3 = [];
              it3.push(element['Y2'])
              it3.push(element['X2'])
              item.latlngs.push(it3)


              let it4 = [];
              it4.push(element['Y2'])
              it4.push(element['X1'])
              item.latlngs.push(it4)

              item.center = [];
              item.center.push(zone['y']);
              item.center.push(zone['x']);

            }

            $this.$store.commit('SET_ZONE_INFO', item);

          }
        }).catch(error => {
          console.log(error)
        })

      } else {
        this.$store.commit('SET_ZONE_INFO', this.polygon_zones[old_zone.id]);
      }


      let $this = this;
      this.$nextTick(() => {
        $this.$store.commit('SET_SHOW_ZONE_INFO', true);
      })
    },
    handlePolyCheckbox2(zone) {
      if(zone.checked && zone.checked=== true){
        zone.checked = false
      }else{
        zone.checked = true
      }
      this.$emit('handlebox', zone);
    },
  }
}
</script>

<style scoped>
.panel-body {
  padding-left: 0;
  padding-right: 0;
}

.geozone-list {
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 0.5px solid #e5e5e5;
  border-radius: 3px;
  padding: 4px 10px;
  min-height: 40px;
  padding-right: 20px;
}


.geozone-list:focus, .geozone-list:hover {
  background-color: #e5f3f9;
}

.geozone-bnt {
  color: #777;
  border: none;
  box-shadow: none;
  opacity: 0;
  transition: opacity 100ms linear;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 16px;
  min-height: 25px;
  min-width: 25px;
  max-height: 25px;
}

.geozone-list:focus .geozone-bnt, .geozone-list:hover .geozone-bnt {
  opacity: 1;
  cursor: pointer;
  padding: 4px 10px;
  height: 40px;

}


.geozone-list .geozone-bnt.monitoring {
  opacity: 1 !important;
}

.geozone-bnt:hover, .geozone-bnt:focus {
  background-color: rgba(0, 0, 0, 0.24);

}

.geozone-bnt-left {
  margin-left: auto;
}

.img-circle {
  width: 24px;
  height: auto;
}

ul.simple-user-list li .title {
  color: #777;
  display: block;
  line-height: 1.334;
  font-size: 16px;
}

.index-label {
  margin-right: 10px;
  color: #1C71DA;
  font-size: 16px;
  font-weight: bold;

}

.poly-icon svg {
  width: 26px;
}

.geo-zone-scroll-list {
  display: block;
  padding-left: 0;
}

.geo-zone-scroll-list.big {
  max-height: 350px;

}
.geo-zone-scroll-list.big.small_tema {
  max-height: 220px;

}
.geo-zone-scroll-list.small_tema li{
  padding: 0 10px;
  min-height: 30px;
}


.geo-zone .input-search:hover, .geo-zone .input-search:focus {
  border-color: #1C71DA;
}

.panel {
  min-width: 340px;
}

.input-group-arrow {
  margin: 0 10px;
}

.geozone-list a {
  text-decoration: none;
  cursor: pointer;
  padding-left: 10px;
}

.zone-star {
  margin-right: 10px

}



.timeline .tm-items > li {
  margin: 10px 0;
}

.timeline .tm-items > li .tm-box {
  padding: 3px 3px;
  margin-right: 5px;
}

.zone-group__box {
  margin-left: auto;
  border-radius: 3px;
  background: #26A69A;
  color: #fff;
  padding: 2px 6px;
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
  margin-right: 10px;
}
.panel-title {
  font-size: 20px;
  max-width: 75%;
  color: #777;
  letter-spacing: 0.3px;
}
.panel.zone_edit  .panel-body{
  max-height: 65vh;
  overflow-y: auto;
}
.panel-heading.small_tema {
  padding: 6px;
  background: transparent;
}
.panel-footer.geo-zone.small_tema {
  background-color: transparent;
  margin-top: 0;
  padding: 0;
  border-bottom: none;
}
ul.widget-todo-list {
  overflow-y: auto;
}
@media only screen and (max-width: 1500px) {

  .panel-heading {
    padding: 10px 5px;
  }

  .panel {
    min-width: 280px;
    max-width: 280px;
  }

}
@media only screen and (max-width: 600px) {
  .panel {
    min-width: 100vw;
    max-width: 100vw;
    width: 100vw;
  }
  ul.simple-user-list li {
    margin: 5px 0 10px;
  }

  .geozone-bnt {
    opacity: 0.8;
  }

  .panel-footer.geo-zone {

  }
  .btn-group-scroll {
    width: 100%;
  }

  .mr-xs {
    margin-right: 8px !important;
  }

}
</style>