import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueLocalStorage from 'vue-localstorage'
import VueCookies from 'vue-cookies'
import 'vue-slider-component/theme/default.css'
import VueAxios from 'vue-axios'
axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
};
axios.defaults.withCredentials = true;
import iosAlertView from 'vue-ios-alertview';
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import excel from 'vue-excel-export'

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'

import 'vue-search-select/dist/VueSearchSelect.css'
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css"
import 'vue-range-component/dist/vue-range-slider.css'
import SmartTable from 'vuejs-smart-table'
import moment from 'moment-timezone'

moment.suppressDeprecationWarnings = true;

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(VueCookies)
Vue.$cookies.config("7D", "", "localhost")
Vue.use(PerfectScrollbar)
Vue.use(iosAlertView)
Vue.use(VueLocalStorage)
Vue.use(SmartTable)
Vue.use(excel)
Vue.use(require('vue-moment'))
Vue.use(VueLocalStorage, {
  name: 'ls',
  bind: true
})

Vue.config.productionTip = false

 new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
