<template>
  <div class="full-map">
  <v-map  ref="map" :zoom=7 :center="initialLocation">
    <v-icondefault></v-icondefault>
    <v-tilelayer url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"></v-tilelayer>
  </v-map>
  </div>
</template>

<script>
import * as Vue2Leaflet from 'vue2-leaflet'
function rand(n) {
  let max = n + 0.1
  let min = n - 0.1
  return Math.random() * (max - min) + min;
}
export default {
  components: {
    'v-map': Vue2Leaflet.LMap,
    'v-tilelayer': Vue2Leaflet.LTileLayer,
    'v-icondefault': Vue2Leaflet.LIconDefault,
    'v-marker': Vue2Leaflet.LMarker,
    'v-popup': Vue2Leaflet.LPopup,

  },
  methods: {

  },
  data () {

    return {

      clusterOptions: {},
      initialLocation: [56.946000,24.10589]
    }
  },
  mounted() {

  }
}
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
html, body {
  height: 100%;
  margin: 0;
}
.full-map {
  max-height: 100vh !important;
  overflow-y: auto;
  bottom: 0;
}
.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  max-height: 100vh !important;
}
</style>
