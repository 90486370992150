<template>
<div class="wrapper-vehicle">
  <section class="panel vehicle-settings">
    <header class="panel-heading vehicle-head ">
      <div class="panel-actions">
        <a href="#" class="fa fa-times" @click="closeSetting()"></a>
      </div>
      <h2 class="panel-title">
        <span class="va-middle"><translate-component :text="lang.main_component.vehicle_settings" /></span>
        <span class="vehicle-number" >{{object[0].Number}}</span>
      </h2>
    </header>
    <div class="panel-body vehicle-body">
      <ul class="route-setting_list">
        <li >
          <popper trigger="hover">
            <div class="popper">
              <translate-component :text="lang.main_component.info"/>
            </div>
            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                    :class="page===1 ? 'active' : ''" @click="changePage(1)">
            <svg-info-icon/>
              <span class="vehicle-btn-title"><translate-component :text="lang.main_component.info"/></span>
            </button>
          </popper>
        </li>
        <li>
          <popper trigger="hover">
            <div class="popper">
              <translate-component :text="lang.main_component.icons"/>
            </div>
            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                    :class="page===2 ? 'active' : ''" @click="changePage(2)">
              <svg-image-icon/>
              <span class="vehicle-btn-title"><translate-component :text="lang.main_component.icons"/></span>
            </button>
          </popper>
        </li>
        <li >
          <popper trigger="hover">
            <div class="popper">
              <translate-component :text="lang.main_component.addit"/>
            </div>
            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                    :class="page===3 ? 'active' : ''" @click="changePage(3)">
              <svg-roue-view-icon/>
              <span class="vehicle-btn-title"><translate-component :text="lang.main_component.addit"/></span>
            </button>
          </popper>
        </li>
        <li >
          <popper trigger="hover">
            <div class="popper">
              <translate-component :text="lang.main_component.note"/>
            </div>
            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                    :class="page===4 ? 'active' : ''" @click="changePage(4)">
          <svg-table-icon/>
              <span class="vehicle-btn-title"><translate-component :text="lang.main_component.note"/></span>
            </button>
          </popper>
        </li>
        <li >
          <popper trigger="hover">
            <div class="popper">
              <translate-component :text="lang.main_component.calibration"/>
            </div>
            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                    :class="page===5 ? 'active' : ''" @click="changePage(5)">
             <svg-server-icon/>
              <span class="vehicle-btn-title"><translate-component :text="lang.main_component.calibration"/></span>
            </button>
          </popper>
        </li>
        <li >
          <popper trigger="hover">
            <div class="popper">
              <translate-component :text="lang.main_component.fuel_title"/>
            </div>
            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                    :class="page===6 ? 'active' : ''" @click="changePage(6)">
            <svg-fuel-icon/>
              <span class="vehicle-btn-title"><translate-component :text="lang.main_component.fuel_title"/></span>
            </button>
          </popper>
        </li>
      </ul>
      <div class="content vehicle-content">
        <template v-if="page===1">
          <div class="row">

            <div class="col-md-6">

              <div class="form-group">
                <label><translate-component :text="lang.main_component.Number"/></label>
                <input type="text" class="form-control" v-model="info_params.Number">
              </div>
              <div class="form-group">
                <label><translate-component :text="lang.main_component.Mark"/></label>
                <input type="text"  class="form-control"v-model="info_params.Marka">
              </div>
              <div class="form-group">
                <label><translate-component :text="lang.main_component.Model"/></label>
                <input type="text" class="form-control" v-model="info_params.Modelj">
              </div>
              <div class="form-group">
                <label><translate-component :text="lang.events_component.type"/></label>
                <select id="object_type"   class="form-control" v-model="info_params.Vid">
                  <option value="1" ><translate-component :text="lang.main_component.Car"/></option>
                  <option value="2"><translate-component :text="lang.main_component.Truck"/></option>
                  <option value="3"><translate-component :text="lang.main_component.Bus"/></option>
                  <option value="4"><translate-component :text="lang.main_component.Ship"/></option>
                  <option value="5"><translate-component :text="lang.main_component.Special"/></option>
                </select>
              </div>

              </div>

            <div class="col-md-6">

              <div class="form-group">
                <label><translate-component :text="lang.main_component.TankCapacity"/></label>
                <input type="number" class="form-control" v-model="info_params.TankCapacity">
              </div>
              <div class="form-group">
                <label><translate-component :text="lang.main_component.FulNormal"/></label>
                <input type="number" class="form-control" v-model="info_params.FuelNormal">
              </div>
              <div class="form-group">
                <label><translate-component :text="lang.main_component.FulNormal2"/></label>
                <input type="number" class="form-control" v-model="info_params.FuelNormal2">
              </div>
              <div class="form-group">
                <label><translate-component :text="lang.main_component.Comment"/></label>
                <textarea rows="3" class="form-control" v-model="info_params.Comment"></textarea>
              </div>

            </div>

          </div>

        </template>
        <template v-if="page===2">
          <ul class="simple-user-list vehicle-list">
            <template v-for="n in 88">
              <li>
                <figure class="image rounded">
                  <img
                      @click="sendSettings(n)"
                      class="vehicle-img"
                      :src="'/tracking/images/icons/object_types/4type('+ n+').svg' "
                      alt="icon" />
                </figure>

              </li>
            </template>
          </ul>
        </template>
        <template v-if="page===3">
          <div class="row">
            <div class="col-md-6 form-group">
              <label><translate-component :text="lang.main_component.inspection_date"/></label>
              <input type="date" class="form-control" v-model="info_params.inspectionDate">
            </div>
            <div class="col-md-6 form-group">
              <label><translate-component :text="lang.main_component.insurance_date"/></label>
              <input type="date" class="form-control" v-model="info_params.insuranceDate">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 form-group">
              <label><translate-component :text="lang.main_component.work_date_start"/></label>
              <input type="time" class="form-control" v-model="info_params.workStart">
            </div>
            <div class="col-md-6 form-group">
              <label><translate-component :text="lang.main_component.work_date_end"/></label>
              <input type="time" class="form-control" v-model="info_params.workEnd">
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 form-group">
              <label><translate-component :text="lang.main_component.dis_coefficient"/></label>
              <input type="number" class="form-control" v-model="reports_params.distanceCoef">
            </div>
            <div class="col-md-6 form-group">
              <label><translate-component :text="lang.main_component.dis_source"/></label>
              <select class="form-control" v-model="reports_params.distanceSource" >
                <option value="0">GPS</option>
                <option value="1">CAN</option>
                <option value="2">Odometer</option>
              </select>

            </div>

          </div>
        </template>
        <template v-if="page===4">
          <div class="row">
            <div class="col-md-12 form-group">
              <label><translate-component :text="lang.main_component.note"/></label>
              <textarea class="form-control" rows="3" id="textareaAutosize" data-plugin-textarea-autosize=""  v-model="info_params.notes"></textarea>
            </div>
          </div>
        </template>
        <template v-if="page===5">
          <calibration-settings-component :object_number = "object[0].Number"   :object_id = "current_object" :calibration ="calibration" />

        </template>
        <template v-if="page===6">
          <fuel-settings-component :object_number = "object[0].Number"   :object_id = "current_object"/>
        </template>
      </div>

    </div>
    <div class="panel-footer">
      <button  class="btn route-setting_page-btn bottom" @click="saveOptions()"><translate-component :text="lang.geo_zone_component.save_edit"/></button>
    </div>
  </section>
</div>
</template>

<script>
import Popper from 'vue-popperjs';
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import {mapGetters} from "vuex";
import axios from "axios";
import SvgPathIcon from "@/components/ui/icons/svgPathIcon";
import SvgRoueViewIcon from "@/components/ui/icons/svgRoueViewIcon";
import SvgTableIcon from "@/components/ui/icons/svgTableIcon";
import SvgImageIcon from "@/components/ui/icons/svgImageIcon";
import SvgInfoIcon from "@/components/ui/icons/svgInfoIcon";
import SvgFuelIcon from "@/components/ui/icons/svgFuelIcon";
import SvgServerIcon from "@/components/ui/icons/svgServerIcon";
import CalibrationSettingsComponent from "@/components/settings/CalibrationSettingsComponent";
import FuelSettingsComponent from "@/components/settings/FuelSettingsComponent";
export default {
  name: "VehicleComponent",
  components: {
    FuelSettingsComponent,
    CalibrationSettingsComponent,
    SvgServerIcon,
    SvgFuelIcon,
    SvgInfoIcon, SvgImageIcon, SvgTableIcon, SvgRoueViewIcon, SvgPathIcon, TranslateComponent, 'popper': Popper,},
  data() {
    return {
      calibration:[],
      object:[
        {Number:'',}
      ],
      page: 1,
      base_url: base_url,
      info_params: {
        Number: '',
        Comment: '',
        Marka: '',
        Modelj: '',
        Vid: 1,
        TankCapacity: 0,
        FuelNormal: 0,
        FuelNormal2: 0,
        inspectionDate:'',
        insuranceDate:'',
        workStart:'',
        workEnd:'',
        notes:'',
      },
      reports_params: {
        averagePoints:"0",
        distanceCoef:"1.0",
        distanceSource:"0",
        minDrainL:"0.0",
        minFillingL:"0.0",
        minsAfterIgnitionOn:"0.0",
        minsBeforeIgnitionOff:"0.0",
      }
    }
  },
  computed: {
    ...mapGetters({
    lang: 'LANG',
    vehicle_settings: 'VEHICLE_SETTINGS',
      current_object:'CURRENT_OBJECT',
      objects: 'OBJECTS',
    }),
  },
  mounted() {
    this.object = this.objects.filter(obj => {
      return obj.ObjectId === this.current_object
    })
    this.getAllParams()

  },
  methods:{
    saveOptions(){
      let formData = new FormData()
      formData.append("ObjectId",  this.current_object)
      let $this = this
      Object.keys( this.info_params).forEach(function(key) {
        if(key == 'TankCapacity' ){
          if($this.info_params[key]  != undefined && $this.info_params[key]  != null) {
            let kk = parseFloat($this.info_params[key] * 1).toFixed(0)
            formData.append("params[" + key + "]", kk)
          }
        }else{
          formData.append("params["+key+"]",  $this.info_params[key])
        }

      });
      Object.keys( this.reports_params).forEach(function(key) {
        formData.append("reportOptions["+key+"]",  $this.reports_params[key])
      });
      axios.post(base_url + '/index/setObjectParams/', formData).then(response => {
        $this.closeSetting()
      })

    },
    getAllParams(){
      let formData = new FormData()
      formData.append("ObjectId",  this.current_object)
      axios.post(base_url + '/index/getObjectParams/', formData).then(response => {
        if(response.data['error'] == 0) {
          this.info_params = response.data['params']
          this.reports_params = response.data['reportOptions']

            this.calibration =response.data['calibration']


        }
      })
    },

    changePage(num){
      this.page = num
    },
    closeSetting(){
      this.$store.commit('SET_VEHICLE_SETTINGS',false)
    },
    sendSettings(icon_number){

      let formData = new FormData()

      formData.append("objectId",  this.current_object)
      formData.append("icon", icon_number)

      axios.post(base_url + '/objects/seticon', formData).then(response => {
        if(response.data['success'] === true) {
          let newObj =[...this.objects]
          let item =newObj.find(item => item.ObjectId === this.current_object);
          item.icon =icon_number
          this.$store.commit('SET_OBJECT',  newObj);

        }
      })
    }
  }
}
</script>

<style scoped>
.vehicle-content{
  margin-top: 30px;
  max-height:  50vh;;
  overflow-y: auto;
  overflow-x: hidden;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.wrapper-vehicle{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000000;
  background-color: rgba(10,106,161,0.2) ;
}
.panel.vehicle-settings{
  margin: auto;
  margin-top: 20vh;
  margin-left: 500px;
  width: 800px;
}
.vehicle-img{
  width: 38px;
  height: 38px;
  cursor: pointer;

}
.vehicle-img:hover, .vehicle-img:focus{
  transform: scale(1.4);
}
.vehicle-list{
  display: flex;
  flex-wrap: wrap;
}



.route-setting_list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.route-setting_list li {
  width: 25%;
  padding: 0;
  margin: 0;
  padding: 5px;
}

.route-setting_page-btn {
  white-space: normal;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  min-width: 0;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  border: none;
  padding: 4px 5px;
  font-weight: 600;
}

.route-setting_page-btn.active {
  color: #1C71DA;
  background: rgba(0, 136, 204, 0.3);
}

.route-setting_page-btn svg {
  width: 25px;
  margin-right: 5px;
}
.route-setting_page-btn svg {
  fill: #1C71DA;
}

.route-setting_page-btn:hover, .route-setting_page-btn:focus {
  background-color: #dcdcdc;
}

.route-setting_page-btn img {
  width: 25px;
}



.vehicle-btn-title{
  padding-left: 5px;
}
.vehicle-body{
  min-height: 440px;
}
.panel-heading.vehicle-head{
  background: #e5f3f9;
  border-bottom: 1px solid #e5f3f9;
  padding: 10px 20px;
}
.vehicle-head .panel-actions {
  top: 10px;
}
.vehicle-number{
  padding-left: 5px;
  color: #1c71da;
  font-weight: bold;
}



@media only screen and (max-width: 1500px) {
  .panel.vehicle-settings{
    margin-left: 440px;
    width: calc(100vw - 500px);
    margin-top: 40px;
  }
  .vehicle-body {
    min-height: 440px;
    height: 68vh;
    overflow-y: auto;
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 600px) {
  .panel.vehicle-settings{
    margin-left: 0;
    margin-top: 60px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .vehicle-head .panel-actions {
    position: absolute;
    top: 10px;
  }
  .vehicle-head.panel-heading {
    padding: 10px;
  }
  .vehicle-btn-title{
    display: none;
  }
  .vehicle-body {
    min-height: unset;
    height: calc(100vh - 220px);
    overflow-y: auto;
  }
  .vehicle-conten {
    margin-top: 10px;
    max-height: unset;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .panel-footer {
    border-radius: 0 0 5px 5px;
    margin-top: -5px;
    height: 140px;
  }
  .form-group input[type="date"]{
    padding: 2px 12px !important;
    line-height: 30px;
  }
}
</style>