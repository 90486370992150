<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 458.379 458.379" style="enable-background:new 0 0 458.379 458.379;" xml:space="preserve">
<g>
	<g>
		<g>
			<circle cx="53.792" cy="53.311" r="53.311"/>
      <circle cx="404.908" cy="53.311" r="53.311"/>
      <circle cx="229.35" cy="53.311" r="53.311"/>
      <circle cx="53.472" cy="229.19" r="53.312"/>
      <circle cx="404.587" cy="229.19" r="53.312"/>
      <circle cx="229.03" cy="229.19" r="53.312"/>
      <circle cx="53.472" cy="405.068" r="53.311"/>
      <circle cx="404.587" cy="405.068" r="53.311"/>
      <circle cx="229.03" cy="405.068" r="53.311"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgOpenPanelIcon"
}
</script>

<style scoped>

</style>
