<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M498.872,0H13.128C5.878,0,0,5.879,0,13.128v485.744C0,506.121,5.878,512,13.128,512h485.744    c7.251,0,13.128-5.879,13.128-13.128V13.128C512,5.879,506.123,0,498.872,0z M485.744,485.744H26.256V26.256h459.487V485.744z"/>
	</g>
</g>
    <g>
	<g>
		<path d="M446.359,52.513H65.641c-7.251,0-13.128,5.879-13.128,13.128v380.718c0,7.249,5.878,13.128,13.128,13.128h380.718    c7.251,0,13.128-5.879,13.128-13.128V65.641C459.487,58.392,453.61,52.513,446.359,52.513z M433.231,433.231H78.769V78.769    h354.462V433.231z"/>
	</g>
</g>
    <g>
	<g>
		<path d="M371.637,213.99H298.01v-73.627c0-15.865-12.908-28.773-28.773-28.773h-27.789c-15.864,0-28.772,12.908-28.772,28.773    v73.627h-72.314c-15.865,0-28.773,12.908-28.773,28.773v27.787c0,15.865,12.908,28.773,28.773,28.773h72.314v72.314    c0,15.865,12.908,28.773,28.773,28.773h27.788c15.865,0,28.773-12.908,28.773-28.773v-72.314h73.627    c15.865,0,28.773-12.908,28.773-28.773v-27.787C400.41,226.897,387.503,213.99,371.637,213.99z M374.155,270.55h-0.001    c0,1.388-1.129,2.517-2.517,2.517h-86.755c-7.251,0-13.128,5.879-13.128,13.128v85.442c0,1.388-1.129,2.517-2.517,2.517h-27.789    c-1.388,0-2.517-1.129-2.517-2.517v-85.442c0-7.249-5.878-13.128-13.128-13.128h-85.442c-1.388,0-2.517-1.129-2.517-2.517v-27.787    c0-1.388,1.129-2.517,2.517-2.517h85.444c7.251,0,13.128-5.879,13.128-13.128v-86.755c0-1.388,1.129-2.517,2.517-2.517h27.789    c1.388,0,2.517,1.129,2.517,2.517v86.755c0,7.249,5.878,13.128,13.128,13.128h86.755c1.388,0,2.517,1.129,2.517,2.517V270.55z"/>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgMonitoringCarIcon"
}
</script>

<style scoped>

</style>
