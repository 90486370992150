<template>
  <section class="panel reports-panel">
    <header class="panel-heading panel-heading-transparent reports-head">
      <div class="panel-actions">
        <button  class="btn fa fa-times btn-report-close" @click="closeReports()"></button>
      </div>
      <h2 class="panel-title">
      <span class="label label-primary label-sm text-normal va-middle mr-sm " style="margin-right: 10px">{{reports.length}}</span><translate-component :text="lang.reports_component.title" />
      <span class="reports-loading" v-if="reports_loading">
         <scale-loader :height="loading_size" ></scale-loader>
      </span>
      </h2>
    </header>
    <div class="panel-body reports-list-body" style="display: block;" v-if="!complete_reports">
      <div class="alert alert-default reports-info" @click="openGenerateReports()">
         <span  class="fa reports-icon" ><svg-reports-icon/> </span><strong><translate-component :text="lang.reports_component.info_vehicle" /></strong>
      </div>
      <div class="table-responsive"  v-if="reports.length>0">
        <perfect-scrollbar>
        <table class="table table-striped table-hover mb-none">
          <thead>
          <tr>
            <th><span style="display: none">{{ help_couter}}</span>#</th>
            <th><translate-component :text="lang.reports_component.name" /></th>
            <th><translate-component :text="lang.reports_component.create_at" /></th>
            <th><translate-component :text="lang.reports_component.status" /></th>
            <th><translate-component :text="lang.reports_component.actions" /></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(report, index) in reports ">
          <tr :key="index">
            <td>{{index+1}}</td>
            <td>
              <span v-if="report.report_type === 'routes2'">
               <translate-component :text="lang.reports_component.routes2" />
              </span>
               <span v-if="report.report_type === 'telemetry'">
               <translate-component :text="lang.reports_component.telemetry" />
              </span>
              <span v-if="report.report_type === 'routesnew'">
               <translate-component :text="lang.reports_component.vid" />
              </span>
            </td>
            <td>
              <span class="reports-time">
             {{report.date_created}}
              </span>
            </td>
            <td>
              <span class="text-bold text-primary" v-if="report.is_finished ==0 " >{{lang.reports_component.render}}</span>
              <span class="text-bold text-success" v-if="report.raw_report && report.is_finished ==1 ">{{lang.reports_component.ready}}</span>
              <span class="text-bold text-danger" v-if="!report.raw_report && report.is_finished ==1 " >{{lang.reports_component.error}}</span>
            </td>
            <td>
              <div class="reports-btn-group">

                <span class="data-minute" v-if="report.is_finished ==0 "><span style="display: none">{{time_counter}}</span>{{ renders_time[report.uuid] }}</span>
                <button type="button" class="btn btn-xs btn-info btn-report-read"  @click="reportsOpen(report,index)" style="margin-right: 10px" v-if="report.raw_report  && report.is_finished ==1 ">{{lang.reports_component.open}}</button>

                <button type="button" class="btn btn-xs btn-default" @click="reportsDelete(report,index)">{{lang.reports_component.delete}}</button>
              </div>

            </td>
          </tr>
          </template>

          </tbody>
        </table>
        </perfect-scrollbar>
      </div>
    </div>
    <div class="panel-body" style="display: block;" v-if="complete_reports">
      <div class="alert alert-success reports-info">

       <strong v-if="!delete_reports"><translate-component :text="lang.reports_component.info_success" /></strong>
        <strong v-if="delete_reports"><translate-component :text="lang.reports_component.info_delete" /></strong>
      </div>
      <div class="table-responsive"  v-if="reports.length>0">
        <perfect-scrollbar>
          <table class="table table-striped table-hover mb-none">
            <thead>
            <tr>
              <th><span style="display: none">{{ help_couter}}</span>#</th>
              <th><translate-component :text="lang.reports_component.name" /></th>
              <th><translate-component :text="lang.reports_component.create_at" /></th>
              <th><translate-component :text="lang.reports_component.status" /></th>
              <th><translate-component :text="lang.reports_component.actions" /></th>
            </tr>
            </thead>
            <tbody>
            <template v-for="(report, index) in reports ">
              <tr :key="index" >
                <td>{{index+1}}</td>
                <td>
              <span v-if="report.report_type === 'routes2'">
               <translate-component :text="lang.reports_component.routes2" />
              </span>
                  <span v-if="report.report_type === 'telemetry'">
               <translate-component :text="lang.reports_component.telemetry" />
              </span>
                  <span v-if="report.report_type === 'routesnew'">
               <translate-component :text="lang.reports_component.vid" />
              </span>
                </td>
                <td>
              <span class="reports-time">
             {{report.date_created}}
              </span>
                </td>
                <td>
                  <span class="text-bold text-primary" v-if="report.is_finished ==0 " >{{lang.reports_component.render}}</span>
                  <span class="text-bold text-success" v-if="report.raw_report && report.is_finished ==1 ">{{lang.reports_component.ready}}</span>
                  <span class="text-bold text-danger" v-if="!report.raw_report && report.is_finished ==1 " >{{lang.reports_component.error}}</span>
                </td>
                <td>
                  <div class="reports-btn-group">

                    <span class="data-minute" v-if="report.is_finished ==0 "><span style="display: none">{{time_counter}}</span>{{ renders_time[report.uuid] }}</span>
                    <button type="button" class="btn btn-xs btn-info btn-report-read"  @click="reportsOpen(report,index)" style="margin-right: 10px" v-if="report.raw_report  && report.is_finished ==1 ">{{lang.reports_component.open}}</button>

                    <button type="button" class="btn btn-xs btn-default" @click="reportsDelete(report,index)">{{lang.reports_component.delete}}</button>
                  </div>

                </td>
              </tr>
            </template>

            </tbody>
          </table>
        </perfect-scrollbar>
      </div>
    </div>
    <section class="panel reports-page" v-if="reports_show">
      <header class="panel-heading panel-heading-transparent reports-head">
        <div class="report-page-btns">
          <button  class="btn btn-xs btn-default "   @click="reports_show = false">
            <translate-component :text="lang.reports_component.back"  v-if="screen_size !=2"/>
            <i class="report-ico-btn fa fa-arrow-left" aria-hidden="true" v-if="screen_size ===2"></i>
          </button>
          <button  class="btn btn-xs btn-success excel-btn" @click="exportXls('routes2')"  >
            <translate-component :text="lang.reports_component.export_xls"  v-if="screen_size !=2" />
            <i class="report-ico-btn fa fa-file-excel-o" aria-hidden="true" v-if="screen_size ===2"></i>
          </button>
          <template v-if=" reports_open_type === 'routes2'">
          <button  class="btn btn-xs btn-success excel-btn2" @click="exportXls('routes2',1)">
            <translate-component :text="lang.reports_component.export_xls_alt" v-if="screen_size !=2" />
            <i class="report-ico-btn fa fa-file-excel-o" aria-hidden="true" v-if="screen_size ===2"></i>
          </button>
          </template>


          <template >
          <button  class="btn btn-xs btn-warning pdf-btn" @click="generatePdf()">
            <translate-component :text="lang.reports_component. export_pdf" v-if="screen_size !=2" />
            <i class="report-ico-btn fa fa-file-pdf-o" aria-hidden="true" v-if="screen_size ===2"></i>
          </button>
          </template>

        </div>
        <div class="panel-actions ">
          <button  class="btn fa fa-times btn-report-close" @click="reports_show = false"></button>
        </div>
      </header>
      <div class="panel-body" >
        <div class="print-panel" v-html="reports_texts">

        </div>
      </div>

    </section>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import SvgReportsIcon from "@/components/ui/icons/svgReportsIcon";
import axios from "axios";
import moment from "moment";
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'

export default {
  name: "ReportDailyComponent",
  components: {SvgReportsIcon, TranslateComponent, ScaleLoader},
  data() {
    return {
      screen_size: 0,
      htmlToPdfOptions2:{
        margin:  [5, 5, 5, 5],
        jsPDF: { format:'a2',orientation: 'landscape'},
        pagebreak: {avoid: 'tr'},
        html2canvas: {
          useCORS: true
        },
      },
      htmlToPdfOptions:{
        margin:  [5, 5, 5, 5],
        jsPDF: { format:'a4',orientation: 'landscape'},
        pagebreak: {avoid: 'tr'},
        html2canvas: {
          useCORS: true
        },

      },
      reports_size:"100%",
      delete_reports:false,
      complete_reports:false,
      reports_open_type:'',
      reports_url:"https://reports.sizzapp.com",
      old_url: "https://reports.trackpro.lv",
      loading_size: "18px",
      reports_loading:false,
      time_counter:0,
      currentTime: null,
      help_couter:0,
      old_cookie:null,
      reports_data:"",
      reports:[],
      reports_show:false,
      reports_texts:'',
      base_url:base_url,
      renders_objects:{},
      renders_time:{},
    }
    },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      show_reports_settings:'SHOW_REPORTS_SETTINGS',
      reports_cookie:'REPORTS_COOKIE',
      show_reports_list:'SHOW_REPORTS_LIST',
      client_id:'CLIENT_ID',
      reports_time:"REPORTS_TIME",
      reports_select:"REPORTS_SELECT",
      reports_settings_params:"REPORTS_SETTINGS_PARAMS",
      lang_short:"LANG_SHORT",
      start_render_report:"START_RENDER_REPORT",
      active_route: "ACTIVE_ROUTE",
      report_type:'REPORTS_TYPE',
      select_type:'SELECT_TYPE',

    }),
  },
  mounted() {
    let width_main = window.innerWidth
    if (width_main <= 600) {
      this.screen_size = 2
    }

    this.getAllReports()
      this.currentTime = moment().set('minute', 0).set('second', 0).format("mm:ss");
      setInterval(() => this.updateCurrentTime(), 1 * 1000);
      if(this.start_render_report === true){
        this.generateNewReport()
      }
  },

  watch: {
    start_render_report(newVal, oldVal) {
      if (newVal === true) {
        this.generateNewReport()
      }
    },
  },
  methods: {
    openGenerateReports(){

      if(this.select_type != "reports"){
        let check =  this.active_route
        this.$store.commit('SET_SELECT_TYPE', "reports");
        this.$store.commit('SET_ADD_CALENDAR', true);
        this.$store.commit('SET_SELECT_HISTORY', false);
        this.$store.commit('SET_SHOW_REPORTS', true);
        if(this.active_route !== 'routing'){
          this.$router.push("/");
        }
        this.$store.commit('SET_SHOW_OBJECT_CONTENT', true);

      }else{
        this.$store.commit('SET_SELECT_TYPE', "history");
        this.$store.commit('SET_ADD_CALENDAR', false);
        this.$store.commit('SET_SHOW_REPORTS', false);

      }
      if(this.screen_size === 2){
        this.$store.commit('SET_SHOW_REPORTS_LIST', false);
      }

    },
    generatePdf(){
      var header = '<style>thead{font-weight:bold;}table{border: 1px solid #ccc !important;}td{border: 1px solid #aaa !important;}.aaa img{display: none !important;}</style>'
      var footer = ''

      var table = $('#print_content').clone()

      table.find('td').css('text-align', 'right')

      //for RSDS rigas satiksme odometer value - "convert" input to spans for print to pdf
      var odo = table.find('.odo')
      var oi
      for (oi = 0; oi < odo.length; oi++) {
        var odoInput = $(odo[oi]).find('.odoInput input')
        var odoSpan = $(odo[oi]).find('.odoSpan span')
        var val = odoInput.val()
        if (!val.length) {
          odoSpan.html('&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
        } else {
          odoSpan.html(val)
        }
        $(odo[oi]).find('.odoInput').hide()
        $(odo[oi]).find('.odoSpan').show()
      }

      var reqHtml = header + table.html() + footer

      //show back inputs
      for (oi = 0; oi < odo.length; oi++) {
        $(odo[oi]).find('.odoInput').show()
        $(odo[oi]).find('.odoSpan').hide()
      }

      var form = document.createElement('form')
      form.method = 'post'
      form.action = 'https://gps.trackpro.lv/htmltopdf.php' + (pdf_vertical ? '?vertical=true' : '')
      var input = document.createElement('input')
      input.type = 'hidden'
      input.name = 'html'
      input.value = reqHtml
      form.appendChild(input)
      document.body.appendChild(form)
      form.submit()
    },
    generateNewReport() {
      let objects = {...this.reports_select}
      if (Object.keys(objects).length > 0 ) {

      this.complete_reports = false
      this.delete_reports = false

      this.reports_loading = true
      let $this = this
      this.$store.commit('SET_START_RENDER_REPORT', false);
      let formData = new FormData();
      let add_data = {...this.reports_time}
      let start = add_data.start_time
      let end = add_data.end_time
      let object_list = ''
      let reports_settings_params = {...this.reports_settings_params}
      Object.keys(objects).map(function (key, index) {
        if (object_list === '') {
          object_list += '' + key
        } else {
          object_list += ',' + key
        }

      })

      let params_arr = ['type', 'reportType', 'ids', 'dt1', 'dt2',
        'minStopTime', 'maxStopDistance', 'skipMinDistance',
        'byIgnition', 'bDaily', 'dateFormat', 'showDrivers', 'bOnlyFullDays',
        'tzoffset', 'lang', 'server_ip', 'server_name', 'is_local', 'clientId',
        'ndt1', 'ndt2',
      ]

      let reports_type = 'routes2'
      if (this.report_type === 'full') {
        reports_type = 'telemetry'
      }
        if (this.report_type === 'vid') {
          reports_type = 'routesnew'
        }


      let utcStart_old = new moment(start, "YYYY-MM-DD").unix();
      let utcStart = new moment(start, "YYYY-MM-DD").utc('UTC').unix();
      let diffInMinutes = utcStart - utcStart_old;
      let diff = diffInMinutes / 3600

      let params_val = [reports_type, reports_type, object_list, start, end,
        reports_settings_params.reports_minStopTime, reports_settings_params.reports_maxStopDistance, reports_settings_params.reports_skipMinDistance,
        reports_settings_params.reports_chkByIgnition, reports_settings_params.reports_chkDaily, reports_settings_params.reports_dateFormat, reports_settings_params.reports_showDrivers, reports_settings_params.reports_bOnlyFullDays,
        diff, this.lang_short, '172.30.217.15', 'gps.trackpro.lv', false, this.client_id,
        add_data.ndt1, add_data.ndt2
      ]
      let i = 0;
      let params = {};
      params['params'] = {}
      params_val.map(item => {
        formData.append("params[" + params_arr[i] + "]", params_val[i])
        params['params'][params_arr[i]] = params_val[i]
        i++;
      })
      params['params']['ObjectIds'] = [];
      Object.keys(objects).map(function (key, index) {
        formData.append("params[ObjectIds][]", key)
        params['params']['ObjectIds'].push(key)

      })
      params['params']['TelemetryOptions'] = {};
      params['params']['TelemetryOptions']['Columns'] = [];
      params['params']['TelemetryOptions']['Sources'] = {};
      Object.entries(reports_settings_params).forEach(([key, value]) => {
        if (key.startsWith("reports_telemetry_chk") && reports_settings_params[key] == 1) {
          let str_end = key.substring(21)
          formData.append("params[TelemetryOptions][Columns][]", str_end)
          params['params']['TelemetryOptions']['Columns'].push(str_end)
        }
        if (key.startsWith("reports_telemetry_source")) {
          let str2_end = key.substring(25)
          formData.append("params[TelemetryOptions][Sources][" + str2_end + "]", reports_settings_params[key])
          params['params']['TelemetryOptions']['Sources'][str2_end] = reports_settings_params[key]
        }
      });

      let add_cookie = null
      axios.post(base_url + '/reports/getsesid/', formData
      ).then(res => {

        if (res.data != null) {
          add_cookie = res.data
          formData.append("params[sessid]", res.data)

          axios.post(base_url + '/reports/getsesid/', formData
          ).then(res => {
            if (res.data != null) {
              add_cookie = res.data
              formData.append("params[sessid]", res.data)
              params['params']['sessid'] = res.data
              params['params']['sessidOld'] = add_cookie


              if (this.report_type === 'daily') {
                $.post(this.reports_url + '/reports/routes2/', params, function (data) {

                  let result = data
                  if (typeof result === 'string' || result instanceof String) {
                    let message = result;
                    if (result == 'limit') {
                      message = $this.lang.reports_component.generate_limit
                    }
                    $this.$iosAlert(message);
                  }
                  if (typeof result === 'object' && !Array.isArray(result) && result !== null) {

                  }
                  $this.complete_reports = true
                  setTimeout(() => {
                    $this.getAllReports()
                    $this.reports_loading = false
                  }, 1000)
                })

                setTimeout(() => {
                  $this.complete_reports = false
                }, 2000)

              } else {


                $.post(this.reports_url + '/reports/'+reports_type+'/', params, function (data) {

                  let result = data
                  if (typeof result === 'string' || result instanceof String) {
                    let message = result;
                    if (result == 'limit') {
                      message = $this.lang.reports_component.generate_limit
                    }
                    $this.$iosAlert(message);
                  }
                  if (typeof result === 'object' && !Array.isArray(result) && result !== null) {

                  }
                  $this.complete_reports = true
                  setTimeout(() => {
                    $this.getAllReports()
                    $this.reports_loading = false
                  }, 1000)

                  setTimeout(() => {
                    $this.complete_reports = false
                  }, 2000)
                })
              }


            } else {
              $this.reports_loading = false
              let message = $this.lang.reports_component.generate_error
              $this.$iosAlert(message);
            }
          })
          //  commit('SET_REPORTS_COOKIE', res.data);
        } else {
          $this.reports_loading = false
          let message = $this.lang.reports_component.generate_error
          $this.$iosAlert(message);
        }
      }).catch(error => {
        console.log(error)
        $this.reports_loading = false
      })
    }
    },
    updateCurrentTime() {
      this.time_counter++;
      let $this = this
    Object.keys($this.renders_time).forEach(key => {
      $this.renders_time[key] = moment().set('minute', 0).set('second', $this.renders_objects[key] + $this.time_counter).format("mm:ss");
    });
     if(this.time_counter % 10 == 0 && Object.keys($this.renders_time).length > 0){
       $this.getAllReports()
     }
    },
    closeReports(){
      this.$store.commit('SET_SHOW_REPORTS_LIST',false);
    },
    getAllReports(){
      let $this = this
      let params ={}
      params['params'] ={}
      params['params']['clientId'] = this.client_id
      params['params']['server_ip'] = '172.30.217.15'
      $.post(this.reports_url+ '/reports/get/',params,  function (data) {
        if(data['reports']) {
          let add_now = new Date().getTime();
          let check_reports = []
          data['reports'].map(item =>{
              if(item.report_type === 'routes2' || item.report_type === 'telemetry' || item.report_type === 'routesnew') {
                if (item.is_finished == 0) {

                  let add = new Date(item.date_requested).getTime()
                  let diff = add_now - add
                  $this.renders_objects[item.uuid] = diff
                  $this.renders_time[item.uuid] = moment().set('minute', 0).set('second', diff).format("mm:ss");


                }
                if(item.date_created != null && item.date_created != '' ) {
                  item.date_created = $this.localizeTime(item.date_created, 4)
                }
                if(item.date_requested != null && item.date_created != '' ) {
                  item.date_requested = $this.localizeTime(item.date_requested, 4)
                }
                check_reports.push(item)
              }
          })
          $this.reports = check_reports;

        }
      })

      let formData = new FormData();
      formData.append("params[clientId]", this.client_id)
      formData.append("params[server_ip]",'172.30.217.15')

    },
    reportsDelete(report,index){
      this.complete_reports =false
      let $this = this
      let params ={}
      params['params'] ={}
      params['params']['uuid'] = report.uuid
      params['params']['server_ip'] = '172.30.217.15'


      $.post(this.reports_url+'/reports/delete/',params,  function (data) {
        if(data){
          $this.reports.splice(index, 1)

          if( $this.renders_objects[report.uuid]) {
            delete $this.renders_objects[report.uuid]
          }
          if( $this.renders_time[report.uuid]){
            delete $this.renders_time[report.uuid]
          }
          $this.delete_reports=true
          $this.complete_reports =true

          setTimeout(function () {
            $this.complete_reports =false
            $this.delete_reports=false

          }, 3000);

          $this.help_couter++

        }
      })
    },
    reportsOpen(report,index){

      this.complete_reports =false
      this.delete_reports=false
      this.reports_open_type = report.report_type
      if(report.date_created != null){
        this.reports_data = report.date_created.slice(9, -1);
      }else {
        this.reports_data = report.date_requested.slice(9, -1);
      }
      let $this = this
      let params ={}
      params['params'] ={}
      params['params']['uuid'] = report.uuid
      $.post(this.reports_url + '/reports/open/',params,  function (data) {
        if(data['reports']) {
          $this.reports_texts = data['reports'][0]['report_text'];
          $this.reports_show =true
          let params2 ={}
          let formData = new FormData();

          formData.append("params[rawReport]", data['reports'][0]['raw_report'])
          formData.append("params[type]", report['report_type'])
          formData.append("params[server_ip]", '172.30.217.15')

          axios.post(base_url + '/reports/setrawreport/', formData
          ).then(res => {

          }).catch(error => {
            console.log(error)
          })


        }
      })
    },

    exportXls(type, ext) {

      let odometer = false
      let odometers = []
      if (this.reports_show === true) {
        $('.odoInput input').each(function () {
          odometers.push($(this).val())
        })
        odometer = odometers.join('|')
      }
      let add =''
      if(odometers.length >0  ){
        add =odometer
      }
      let params ={}
      params['reportType'] = this.reports_open_type

      if (!ext) {
        document.location.href=base_url + '/reports/export2xls/reportType/' + this.reports_open_type + add
      } else {
        params['ext'] = ext
        document.location.href=base_url + '/reports/export2xls/reportType/' + this.reports_open_type + '/ext/' + ext + add

      }
    },

    localizeTime(t, format) {

      if (t != null) {
        let part1 = t.split('-')
        let day = part1[2].split(' ')[0]
        let time = t.split(' ')[1].split(':')

        //year, month, day, h, m, s
        let dateObj = new Date(Date.UTC(part1[0], part1[1] - 1, day, time[0], time[1], time[2]))

        let monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        let curr_date = dateObj.getDate()
        let curr_month = dateObj.getMonth()
        curr_month = curr_month + 1
        let curr_year = dateObj.getFullYear()
        let curr_min = dateObj.getMinutes()
        let curr_hr = dateObj.getHours()
        let curr_sc = dateObj.getSeconds()
        if (curr_month.toString().length == 1)
          curr_month = '0' + curr_month
        if (curr_date.toString().length == 1)
          curr_date = '0' + curr_date
        if (curr_hr.toString().length == 1)
          curr_hr = '0' + curr_hr
        if (curr_min.toString().length == 1)
          curr_min = '0' + curr_min

        if (format == 1)//dd-mm-yyyy
        {
          return curr_date + '-' + curr_month + '-' + curr_year
        } else if (format == 2)//yyyy-mm-dd
        {
          return curr_year + '-' + curr_month + '-' + curr_date
        } else if (format == 3)//dd/mm/yyyy
        {
          return curr_date + '/' + curr_month + '/' + curr_year
        } else if (format == 4)// MM/dd/yyyy HH:mm:ss
        {
          if (curr_sc < 10) curr_sc = '0' + curr_sc
          return curr_date + '.' + curr_month + '.' + curr_year + ' ' + curr_hr + ':' + curr_min + ':' + curr_sc
        }

      }
    }
  }
}
</script>

<style scoped>

.print-panel{
  margin-right: auto;
  margin-left: auto;
  margin-top: 40px;
}
.reports-panel{
  margin-left: 20px;
  background: #ffffff;
  min-width: 400px;
}
html .panel .panel-heading-transparent .panel-actions {
  position: absolute;
  right: 15px;
}
.panel-heading.reports-head{
  background: #e5f3f9;
  border-bottom: 1px solid #e5f3f9;
  padding: 10px 20px;
  min-height: 65px;
  display: flex;
  align-items: center;
  color: #777;
}
.reports-head .panel-title{
  color: #777;
  letter-spacing: 0.3px;
}
.btn-report-close{
  background-color: transparent;
}
.ps {
  height: 65vh;
}
.reports-icon{
  width: 20px;
  margin-right: 10px;
  color: #1C71DA;
}
.reports-icon svg, .reports-icon svg path{
  fill:#1C71DA;
}
.btn-report-read{
  background-color: #1C71DA;
  border-color: #1C71DA;
}
.label-reports-total{
  padding: 1px 3px;
  background-color: #1C71DA;
}
.table-hover tr{
  cursor: pointer;
}
.reports-page{
  position: fixed;
  top: 60px;
  bottom: 0px;
  left: 80px;
  right: 0px;
  z-index: 1000000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow-y: auto;
  background-color: #ffffff;
  height: auto;
}
.reports-page  .panel-heading{
  width: auto;
  background: #ffffff;
  min-width: auto;
  position: fixed;
  top: 40px;
  left: 50px;
  right: 50px;
}


.report-page-btns{
  display: flex;

}
.report-page-btns button{
  margin-right: 10px;
}
.data-minute{
  margin-right: 20px;
}
.reports-loading{
  display: inline-block;
  padding-left: 10px;
}
.reports-info{
  display: flex;
}

@media only screen and (max-width: 1500px) {
  .panel-heading.reports-head {
    max-width: 100%;
    padding: 5px 15px;
    min-height: 55px;
  }
  .ps {
    height: 60vh;
  }
  .reports-page{
    position: fixed;
    top: 0;
    bottom: 20px;
    left: 50px;
    right: 0;
    z-index: 100000;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow-y: auto;
    background-color: #ffffff;
    overflow-x: hidden;
  }
  .reports-page .panel-heading{
    top: 40px;
    left: 0;
    right: 0;
  }

}
thead, tr, td, th {
  page-break-inside: avoid !important;
}
thead div, td div, th div{
  page-break-inside: avoid;
}
tr {
  page-break-inside: avoid;
}
.reports-list-body{
  max-height: 75vh;
}
@media only screen and (max-width: 1500px) {
  .reports-list-body{
    max-height: 90vh;
    height: calc(100vh - 200px);
  }
  .reports-head .panel-title{
  font-size: 18px;
  }
}
.table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 6px;
}
@media only screen and (max-width: 600px) {

  .reports-panel {
    min-width: 100%;
    margin: 0;
    width: 100%;
    position: fixed;
    z-index: 10000;
    height: auto;
    bottom: 0;
    top: 60px;
  }

  .panel-heading.reports-head {
    max-width: 100%;
    padding: 10px 5px;
    min-height: unset;
  }
  html .panel .panel-heading-transparent .panel-actions{
    position: absolute;
    right: 10px;
    top: 4px;
  }

  .reports-page {
    position: fixed;
    top: 60px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000;
    box-shadow: none;
    background-color: #ffffff;
    overflow-x: scroll !important;
    overflow-y: scroll !important;
    -webkit-overflow-scrolling: touch;
  }
  .reports-page .panel-heading {
    top: 60px;
    left: 0;
    background-color: #ffffff;
    right: 0;
    height: 60px !important;
    border-radius: 0;
  }

  .btn-report-close{
    background-color: transparent;

  }
  .report-ico-btn{
    font-size: 18px;
    padding: 5px 5px;
  }

  .excel-btn{
    background: #69F0AE;
    border-color: transparent;
    color: #333333;

  }
  .excel-btn:hover,  .excel-btn:focus{
    background:#45ec9b;


  }
  .excel-btn2{
    background: #9FA8DA;
    border-color: transparent;
    color: #333333;
  }
  .excel-btn2:hover ,.excel-btn2:focus{
    background: #838ecf;

  }
  .pdf-btn{
    background: #FFE082;
    border-color: transparent;
    color: #333333;
  }
  .pdf-btn:hover, .pdf-btn:focus{
    background: #ffd65b;

  }
  .btn-report-read{
    margin-bottom:5px
  }
  }
</style>