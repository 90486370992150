<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
<path style="fill:#CEE8FA;" d="M330.081,181.919c-40.913-40.913-107.248-40.913-148.161,0s-40.913,107.248,0,148.161  s107.248,40.913,148.161,0L256,256L330.081,181.919z"/>
    <path style="fill:#2D527C;" d="M509.507,248.033c-2.023-2.914-46.086-65.747-113.359-112.425l37.318-37.318  c5.455-5.455,5.455-14.3,0-19.756c-5.456-5.453-14.298-5.453-19.756,0l-41.722,41.722C332.714,97.505,293.707,85.94,256,85.94  c-58.135,0-120.528,28.211-180.435,81.585c-44.524,39.668-71.927,78.861-73.072,80.509c-3.325,4.79-3.325,11.141,0,15.931  c1.144,1.65,28.548,40.84,73.072,80.509c13.343,11.887,26.81,22.519,40.326,31.878l-37.357,37.357c-5.455,5.455-5.455,14.3,0,19.756  c2.728,2.727,6.303,4.091,9.877,4.091c3.575,0,7.149-1.365,9.877-4.091l41.778-41.778c39.209,22.631,78.454,34.373,115.931,34.373  c57.733,0,119.715-27.845,179.242-80.526c5.777-5.113,6.315-13.941,1.203-19.718c-5.11-5.776-13.939-6.315-19.717-1.203  c-37.894,33.535-96.538,73.511-160.729,73.511c-33.837,0-66.209-10.946-95.444-26.922l21.964-21.964  c21.469,16.943,47.466,25.444,73.48,25.444c30.406,0,60.81-11.573,83.958-34.721c5.455-5.455,5.455-14.3,0-19.756l-64.203-64.203  l64.203-64.203l36.077-36.077c65.446,44.092,110.047,107.566,110.52,108.247c4.399,6.339,13.104,7.906,19.442,3.509  C512.334,263.075,513.905,254.371,509.507,248.033z M31.363,255.991C55.202,224.928,148.009,113.877,256,113.877  c30.691,0,62.766,9.059,95.477,26.891l-21.997,21.995c-46.562-36.748-114.485-33.673-157.439,9.278  c-42.95,42.951-46.023,110.877-9.277,157.438l-26.742,26.742C84.278,321.329,45.572,274.473,31.363,255.991z M309.569,329.322  c-31.776,23.245-75.348,23.223-107.121-0.015L256,275.754L309.569,329.322z M246.123,246.121l-63.442,63.442  c-25.964-35.495-22.927-85.725,9.116-117.768c17.701-17.701,40.951-26.552,64.203-26.552c18.84,0,37.681,5.81,53.567,17.432  L246.123,246.121z"/>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgEyeHide"
}
</script>

<style scoped>

</style>
