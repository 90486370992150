<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
       id="Layer_1" x="0px" y="0px" viewBox="0 0 511.787 511.787"
       style="enable-background:new 0 0 511.787 511.787;" xml:space="preserve">
<g transform="translate(1 1)">
	<polygon style="fill:#99cfea;" points="408.067,500.333 100.867,500.333 7,225.56 254.467,8.813 501.933,227.267  "/>
  <path style="fill:#1C71DA;"
        d="M408.067,508.867h-307.2c-3.413,0-6.827-2.56-7.68-5.973L-0.68,228.12   c-0.853-3.413,0-6.827,2.56-9.387L249.347,2.84c3.413-2.56,7.68-2.56,11.093,0l247.467,218.453c2.56,2.56,3.413,5.973,2.56,9.387   L416.6,503.747C414.893,506.307,411.48,508.867,408.067,508.867z M106.84,491.8h294.4l90.453-261.973L254.467,19.907L17.24,228.12   L106.84,491.8z"/>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgPolygonIcon"
}
</script>

<style scoped>

</style>
