export default {

    methods: {

        ScrollList(num){
            let old_page =  this.params.page;
            if(num=== 'max'){
                this.params.page =this.params.totalpages
            }else{
                if(this.params.page <= this.params.totalpages) {
                    this.params.page = this.params.page + num

                    if(this.params.page === 0 ){
                        this.params.page =1
                    }
                    if(this.params.page > this.params.totalpages ){
                        this.params.page =this.params.totalpages
                    }
                }
            }
            if(old_page != this.params.page ){
                this.getAllTasks()
            }

        },
        handlerScrollInput(event) {
            let old_page =  this.params.page;

            let num = event.target.value
            if (num > 0 && num <= this.params.totalpages) {
                this.params.page = num

            } else {
                if (num > this.params.totalpages) {
                    this.params.page = this.params.totalpages
                }
                if (num <= 0) {
                    this.params.page = 1
                }
            }

            if(old_page != this.params.page ){
                this.getAllTasks()
            }

        },
        getSearchTable() {
            this.getAllTasks();
        },
        controlRow(){
            this.getAllTasks()
        },
        reloadTable(){
            this.getAllTasks()
        },
        HideSearch(){
            this.search_hide =! this.search_hide;
        },
    }
}
