<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    headers_units: {
      type: Array
    },
    chart_max: {
      type: Array
    },
    chart_min: {
      type: Array
    },
    chartdata: {
      type: Array
    },
    add_labels: {
      type: Array
    },
    label_name: {
      type: Array
    }
    ,
    backgroundColor: {
        type: Array,
    },

    title: {
      type: String
    },
    chart_type_num:{
      type:Number
    }
  },
  data(){
    return{
      alpha: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      dataset: [],
      yAxes: [],
    }
  },
  mounted() {

    let $this = this;
    $this.dataset = [];
    $this.yAxes =[]
    let k = 0;
    if (this.chart_type_num === 1) {
      $this.dataset.push(
        {
          label: $this.label_name[1],
          data: $this.chartdata[1],
          backgroundColor: $this.backgroundColor[1].hex,
          pointBackgroundColor: $this.pointBackgroundColor
        }
      )

    } else {

      this.add_labels.map(item => {

        if (k > 1) {

          if (Array.isArray($this.chartdata[k]) && $this.chartdata[k].length > 0) {
            $this.dataset.push(
              {
                label: $this.label_name[k],
                data: $this.chartdata[k],
                backgroundColor: $this.backgroundColor[k].hex,
                pointBackgroundColor: $this.pointBackgroundColor
              }
            )
            if ($this.chart_max[k] !== "undefined" && $this.chart_max[k] != null) {
              let add = $this.headers_units[k]
              $this.yAxes.push({
                display: true,
                id: $this.alpha[k - 2],
                type: 'linear',
                position: 'left',
                ticks: {
                  min: $this.chart_max[k],
                  max: $this.chart_min[k],
                  maxTicksLimit: 10,
                  stepSize: $this.chart_max[k] / 10,
                  check_val: $this.headers_units[k],
                  fontColor: $this.backgroundColor[k].hex,
                  callback: function (value, index, values) {
                    return value + '' + add;
                  }
                }
              })

            }
          }



        }
        k++;
      })

    }

    if (this.chart_type_num === 1) {
      this.renderChart(
        {
          labels: this.add_labels,
          datasets:
          this.dataset

        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.title
          },
          scales: {
            xAxes: [{
              ticks: {
                maxTicksLimit: 10,
                maxRotation: 0,
                minRotation: 0
              },
              maxBarThickness: 100,
              borderWidth: 0,
            }]
          },


        }
      );
    }else{
      this.renderChart(
        {
          labels: this.add_labels,
          datasets:
          this.dataset

        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.title
          },
          scales: {
            yAxes: this.yAxes,
            xAxes: [{
              ticks: {
                maxTicksLimit: 10,
                maxRotation: 0,
                minRotation: 0
              },
              maxBarThickness: 100,
              borderWidth: 0,
            }]
          }
        },

      );
    }
  }
};
</script>
