<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 487.9 487.9" style="enable-background:new 0 0 487.9 487.9;" xml:space="preserve">
<g>
	<g>
		<path d="M99.599,324.3c48.2,57.5,106.1,102.9,172.2,134.9c25.2,12,58.8,26,96.3,28.5c2.3,0.1,4.6,0.2,6.8,0.2    c25.1,0,45.3-8.7,61.8-26.6c0.1-0.1,0.3-0.3,0.4-0.5c5.9-7.1,12.5-13.4,19.5-20.2c4.7-4.6,9.6-9.3,14.2-14.2    c21.5-22.4,21.4-50.9-0.2-72.5l-60.8-60.7c-10.3-10.7-22.6-16.4-35.5-16.4s-25.3,5.6-35.9,16.2l-36.3,36.3    c-3.4-1.9-6.8-3.7-10.1-5.3c-4-2-7.8-3.9-11.2-6c-33-20.9-62.9-48.3-91.5-83.4c-14.5-18.4-24.2-33.7-31-49.5    c9.5-8.6,18.4-17.6,27-26.4c3.1-3.1,6.2-6.3,9.3-9.4c10.9-10.9,16.7-23.5,16.7-36.3s-5.8-25.4-16.7-36.3l-30.1-30.1    c-3.5-3.5-6.9-7-10.3-10.5c-6.7-6.9-13.6-13.9-20.5-20.4c-10.2-10.3-22.5-15.7-35.4-15.7c-12.8,0-25.1,5.4-35.9,15.7l-37.7,37.8    c-13.7,13.7-21.5,30.4-23.2,49.7c-2,24.1,2.5,49.8,14,80.9C33.299,232,59.999,276.6,99.599,324.3z M25.899,105.2    c1.2-13.5,6.4-24.8,16.1-34.4l37.6-37.6c5.9-5.7,12.4-8.7,18.8-8.7c6.2,0,12.5,2.9,18.3,8.8c6.8,6.3,13.2,12.9,20,19.9    c3.5,3.5,6.9,7.1,10.5,10.7l30.1,30.1c6.3,6.3,9.5,12.7,9.5,19c0,6.3-3.2,12.7-9.5,19c-3.2,3.2-6.3,6.4-9.4,9.5    c-9.4,9.5-18.2,18.5-27.9,27.1c-0.2,0.2-0.3,0.3-0.5,0.5c-8.3,8.3-7.1,16.3-5.1,22.3c0.1,0.3,0.2,0.5,0.3,0.8    c7.7,18.7,18.6,36.5,35.4,57.7c30.4,37.4,62.3,66.4,97.5,88.8c4.4,2.8,8.9,5.1,13.4,7.3c4,2,7.8,3.9,11.2,6    c0.4,0.2,0.7,0.4,1.1,0.6c3.3,1.7,6.5,2.5,9.7,2.5c8,0,13.2-5.2,14.9-6.9l37.8-37.8c11.7-11.7,25.1-12,36.6,0l60.9,60.9    c12,12,11.9,25.6-0.3,38.3c-4.3,4.5-8.7,8.9-13.5,13.4c-7.1,6.9-14.5,13.9-21.1,21.9c-11.7,12.6-25.5,18.4-43.5,18.4    c-1.8,0-3.5-0.1-5.3-0.2c-33.2-2.2-64.1-15.1-87.2-26.1c-62.9-30.5-118.1-73.7-164-128.5c-37.7-45.5-63.1-87.7-79.9-133    C28.299,148,24.299,125.6,25.899,105.2z"/>
    <path d="M278.799,132.9h76.2V209c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-76.1h76.1c6.8,0,12.3-5.5,12.3-12.3    s-5.5-12.3-12.3-12.3h-76.1V32.2c0-6.8-5.5-12.3-12.3-12.3s-12.3,5.6-12.3,12.3v76.1h-76.1c-6.8,0-12.3,5.5-12.3,12.3    S272.099,132.9,278.799,132.9z"/>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgPhoneIcon"
}
</script>

<style scoped>

</style>
