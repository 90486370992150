<template>
  <section class="panel panel-featured-left panel-featured-primary">
    <div class="panel-body">
      <div class="widget-summary widget-summary-xs">
        <div class="widget-summary-col widget-summary-col-icon">
          <div class="summary-icon bg-primary">
            <i class="fa fa-life-ring"></i>
          </div>
        </div>
        <div class="widget-summary-col">
          <div class="summary">
            <h4 class="title">Support Questions</h4>
            <div class="info">
              <strong class="amount">1281</strong>
              <span class="text-primary">(14 unread)</span>
            </div>
          </div>
          <div class="summary-footer">
            <a class="text-muted text-uppercase">(view all)</a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ClusterDescriptionComponent"
}
</script>

<style scoped>

</style>
