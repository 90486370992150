<template>
  <section class="panel global-settings-panel" >
    <template v-if="current_resources && current_resources[320] && current_resources[320].enabled ===true">
    <header class="panel-heading vehicle-head  task-head" :class="search_hide ? 'scroll-head-hide': ''">

      <h2 class="panel-title">

        <span class="vehicle-number" ><span class="svg-box-set"><svg-tasks-icon/></span><translate-component :text="lang.task_component.title2"/></span>
        <button type="button"  class="mobile-search-scroll" @click="HideSearch()">
          <span class="search-scroll-icon" v-if="!search_hide">
            <search-hide-icon/>
          </span>
          <span class="search-scroll-icon" v-if="search_hide">
         <search-sho-icon/>
          </span>
        </button>
      </h2>



      <div class="form-group search-group search-status-group">
        <label><translate-component :text="lang.task_component.search"/></label>
        <div class="input-group">
       <input type="text" class="form-control" v-model="params.search"/>
        <div class="input-group-btn">
        <button class="btn btn-default" type="button" @click="getSearchTable()">
          <i class="glyphicon glyphicon-search"></i>
        </button>
        </div>
        </div>
      </div>
      <div class="form-group search-group">
        <label><translate-component :text="lang.task_component.task_type"/></label>
        <select class="form-control" v-model="params.filterType" @change="reloadTable()" >
          <option value="-1" >{{lang.reports_settings_component.all}}</option>
          <option :value="index" v-for="(type,index) in arrType" v-if="index > 0">{{type}}</option>
        </select>

      </div>
      <div class="form-group search-group search-status-group">
        <label><translate-component :text="lang.events_component.status"/></label>

        <select class="form-control" v-model="params.filterStatus" @change="reloadTable()" >
          <option value="-1" >{{lang.reports_settings_component.all}}</option>
          <option :value="index" v-for="(stat,index) in arrStatus" >{{stat}}</option>
        </select>

      </div>
    </header>

    <div v-if="!redy_loading" class="global-settings-table">
      <perfect-scrollbar  :class="search_hide ? 'scroll-head-hide': ''">
        <table class="table table-striped table-hover mb-none table-event" v-if="tasks_list.length > 0">
          <thead class="event-table-header">
          <tr>
            <th></th>
            <th>{{lang.events_component.vehicle}}</th>
            <th>{{lang.events_component.type}}</th>
            <th>{{lang.events_component.status}}</th>
            <th>{{lang.task_component.Changed}}</th>
            <th>{{lang.task_component.Remaining}}</th>
            <th>{{lang.task_component.Executive}}</th>
            <th>{{lang.task_component.Phone}}</th>
            <th>{{lang.task_component.Email}}</th>
            <th>{{lang.main_component.Comment}}</th>
            <th>{{lang.task_component.Username}}</th>
            <th>{{lang.task_component.actions}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(task,index) in tasks_list">
            <tr class="event-table-list" :key="task.taskId" >
              <td class="event-table-name">{{task.nr}}</td>

              <td >{{task.objectName}}</td>
              <td >{{arrType[task.type]}}</td>
              <td class="status-task" :class="'status-'+task.status">{{arrStatus[task.status] }}</td>
              <td >{{task.statusTime }}</td>
              <td >{{task.remaining}} {{arrRemaining[task.type]}}</td>
              <td >{{task.executive }}</td>
              <td >{{task.executiveGsm }}</td>
              <td >{{task.executiveEmail }}</td>
              <td >{{task.comment}}</td>
              <td >{{task.userName}}</td>
              <td class="btn-row">
                <button type="button" class="btn-row-box btn btn-xs btn-sm btn-success" @click="getAcceptTask(task.taskId)">{{lang.task_component.accept}}</button>
                <button type="button" class="btn-row-box btn btn-xs btn-sm btn-warning" @click="getUpdateTask(task.taskId)">{{lang.events_component.edit}}</button>
                <button type="button" class="btn-row-box btn btn-xs btn-sm btn-danger" @click="getDeleteTask(task.taskId)">{{lang.task_component.delete}}</button>
              </td>
            </tr>
          </template>
          </tbody>
        </table>

      </perfect-scrollbar>
      <div class="panel-footer" >

        <div class="btn-group-scroll big-group-scroll ">
          <div class="btn  button-scroll button-scroll-total" @click="createEvent()"><span class="fa fa-edit"></span><translate-component :text="lang.events_component.create"/>

          </div>
          <button type="button" class="btn  button-scroll " @click="ScrollList(-1)">
            <span class="button-scroll-icon button-scroll-icon-left fa fa-chevron-left"></span>
          </button>
          <div class="btn  button-scroll button-scroll-center hidelog">
            <translate-component :text="lang.geo_zone_component.page"/>
            <span class="page-count"> <input type="number" class="form-control"
                                            @blur="handlerScrollInput($event)"
                                            v-on:keyup.enter="handlerScrollInput($event)"
                                            :value="params.page" > </span></div>
          <button type="button" class="btn  button-scroll button-scroll-right" @click="ScrollList(1)"><span
              class="button-scroll-icon button-scroll-icon-right fa fa-chevron-right"></span></button>
          <div class="btn  button-scroll button-scroll-total" @click="ScrollList('max')"><translate-component :text="lang.events_component.total_page"/>
            <span style="padding-left: 5px">{{ this.params.totalpages }}</span></div>

          <div class="btn  button-scroll button-scroll-center button-scroll-total" >
            <translate-component :text="lang.task_component.limit"/>
            <span class="page-count"> <select class="form-control" v-model="params.limit" @change="controlRow()" >
                <option>10</option>
              <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>200</option>
            </select></span></div>

        </div>



      </div>

    </div>
    <div v-else>
      <div class="loading-box" >
        <scale-loader :height="loading_size"></scale-loader>
      </div>
    </div>

    <div  class="modal fade tasks-modal" role="dialog" style="display: block;opacity: 1" v-if="create_modal">
      <div class="modal-dialog tasks-modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close"  @click="closeCreateModal()">&times;</button>
            <h4 class="modal-title"> <template v-if="current_type === 1">{{lang.task_component.create_task}}</template><template v-if="current_type === 2">{{lang.task_component.edit_task}}</template></h4>

          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group" v-if="current_type === 1">
                <label><translate-component :text="lang.task_component.select_vehicle"/> <span class="require-field"> *</span></label>
                <select class="form-control" v-model="create_params.objectId"  :disabled="current_type === 2">
                  <option :value="object.ObjectId" v-for="(object,index) in objects" >{{object.Number}}</option>
                </select>
              </div>
                <div class="form-group" v-if="current_type === 2">
                  <label><translate-component :text="lang.task_component.select_vehicle"/> : <b>{{create_params.vehicle_name}}</b></label><br />
                  <label style="padding-right: 10px">{{lang.task_component.data_of}} {{arrType[create_params.type]}}</label>
                  <input type="checkbox" v-model="create_params.change" >  <label style="padding-right: 10px">  {{lang.task_component.change}}</label>
                  <input type="checkbox" v-model="create_params.reset" >  <label>  {{lang.task_component.reset}}</label><br />
              </div>
            </div>
            <div class="row">
            <div class="form-group">
              <label><translate-component :text="lang.task_component.task_type"/><span class="require-field"> *</span></label>
              <select class="form-control" v-model="create_params.type" @change="chekType()" :disabled="current_type === 2">
                <option :value="index" v-for="(type,index) in arrType" v-if="index > 0">{{type}}</option>
              </select>

            </div>
            </div>

            <div class="row">
              <div class="form-group" v-if="create_params.type!= 3 && create_params.type != 4">
              <label>{{lang.task_component.data_of}} {{arrType[create_params.type]}}<span class="require-field"> *</span></label>
                <input type="date" class="form-control"  v-model="create_params.add_date"  :disabled="current_type === 2 && create_params.change == 0"/>
              </div>

                <div class="form-group" v-if="create_params.type == 3 || create_params.type == 4">
              <label><translate-component :text="lang.task_component.do_maintenance_after"/> <span v-if="create_params.type == 3 ">( {{lang.task_component.km}} )</span><span v-if="create_params.type == 4 ">( {{lang.task_component.motohours}} )</span><span class="require-field"> *</span></label>
              <input type="number" class="form-control"   v-model="create_params.value" :disabled="current_type === 2 && create_params.change == 0"/>
            </div>
            </div>

            <div class="row">
              <div class="form-group">
                <label><translate-component :text="lang.task_component.Executive"/> <span class="require-field"> *</span>: </label><br />

                <input type="text" class="form-control" v-model="create_params.executive" ><br />

                <input type="checkbox" v-model="create_params.sendSms" id="sendSms">  <label>{{lang.task_component.send}} SMS:</label><br />
                <input type="text"  v-model="create_params.executiveGsm"  class="form-control" v-if="create_params.sendSms == 1" >
                <input type="checkbox" v-model="create_params.sendEmail"  id="sendEmail">  <label>{{lang.task_component.send}} Email:</label><br/>
                <input type="text" v-model="create_params.executiveEmail"  class="form-control"  v-if="create_params.sendEmail == 1">

              </div>

              <div class="form-group" v-if="(create_params.sendSms ==1 || create_params.sendEmail ==1)">
                <label>{{lang.task_component.send_notification_before}} <span v-if="create_params.type != 4 && create_params.type != 3  ">( {{lang.task_component.days}} )</span><span v-if="create_params.type == 4 || create_params.type == 3 ">( {{lang.task_component.km}} )</span><span class="require-field"> *</span> </label>

                <input type="number" class="form-control"  v-model="create_params.sendBefore"  />
              </div>


            </div>

            <div class="row">
              <div class="form-group">
                <label><translate-component :text="lang.main_component.Comment"/></label>
                <textarea class="form-control" v-model ="create_params.comment"></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <template v-if="current_type === 1"> <button type="button" class="btn btn-sucess" @click="CreateTask()">{{lang.main_component.save}}</button></template>
            <template v-if="current_type === 2"><button type="button" class="btn btn-sucess" @click="UpdateTask()">{{lang.main_component.save}}</button></template>
            <button type="button" class="btn btn-default" @click="closeCreateModal()">{{lang.task_component.close_btn}}</button>
          </div>
        </div>

      </div>
    </div>


</template>
  </section>

</template>

<script>
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import {mapGetters} from "vuex";
import SvgTasksIcon from "@/components/ui/icons/svgTasksIcon";
import axios from "axios";
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
import SearchHideIcon from "@/components/ui/icons/search/SearchHideIcon";
import SearchShoIcon from "@/components/ui/icons/search/SearchShoIcon";
import managerHelper from "@/mixins/managerHelper";



export default {
  name: "TasksComponent",
  components: {SearchShoIcon, SearchHideIcon, SvgTasksIcon, TranslateComponent, ScaleLoader},
  mixins:[managerHelper],
  computed: {
    ...mapGetters({
      lang: 'LANG',
      objects: 'OBJECTS',
      current_resources:'CURRENT_RESOURCES',
    }),
  },
    data() {
      return {
        search_hide:false,
        search_status:-1,
        search_type:-1,
        create_modal:false,
        tasks_list:[],
        current_type:1,
        params:{
          _search:false,
          limit:50,
          order:'taskId',
          direction:'desc',
          nd: Date.now(),
          totalpages:1,
          page:1,
          filterStatus: -1,
          filterType:-1,
          search:'',
        },
        create_params:{
          vehicle_name:null,
          taskId:null,
          objectId:null,
          type:1,
          sendSms : 0,
          sendEmail : 0,
          sendBefore: 5,
          executive: null,
          executiveGsm: '+371',
          executiveEmail : null,
          comment: '',
          add_date: this.$moment().format('YYYY-MM-DD'),
          reset:0,
          change:0,
          value: 10000,
        },
        arrType:[],
        arrStatus:[],
        arrRemaining:[],
        loading_size: "30px",
        show_icon:true,
        redy_icons:false,
        redy_loading:false,

      }
    },
    mounted() {
      let _this =this;


      this.arrType = [
          '',
        this.lang.task_component.inspection,
        this.lang.task_component.insurance_octa,
        this.lang.task_component.maintenance_by_km,
        this.lang.task_component.maintenance_by_motohours,
        this.lang.task_component.kasko,
        this.lang.task_component.tahograf_check,
        this.lang.task_component.license_map,
        this.lang.task_component.eka,
        this.lang.task_component.ADR_Check,
        this.lang.task_component.Other,
      ];
      this.arrStatus = [this.lang.task_component.performance_not_required,
        this.lang.task_component.performance_is_required,
        this.lang.task_component.completed];

      this.arrRemaining = [
          '',
        this.lang.task_component.days,
        this.lang.task_component.days,
        this.lang.task_component.km,
        this.lang.task_component.hrs,
        this.lang.task_component.days,
        this.lang.task_component.days,
        this.lang.task_component.days,
        this.lang.task_component.days,
        '',
        '',
      ];

      this.getAllTasks()
    },
    methods: {
      chekType(){
        if(this.create_params.type == 3 || this.create_params.type == 4 ){
          this.create_params.sendBefore =1000
        }else{
          this.create_params.sendBefore =5
        }
      },
      validateonRules(){
        let chek_valid = 0;
        let check_message ='';
        let _this = this
        if (_this.create_params.objectId == null || _this.create_params.objectId == '') {
          check_message = check_message+'\n '+this.lang.task_component.empty_object+','
          chek_valid++
        }

        if ((_this.create_params.type == 3 || _this.create_params.type == 4) && (_this.create_params.value.length == 0)) {
          check_message = check_message+'\n '+this.lang.task_component.empty_sendBefore+','
          chek_valid++
        }
        if ((_this.create_params.type != 3 && _this.create_params.type != 4) && (_this.create_params.add_date.length == 0)) {
          check_message = check_message+'\n '+this.lang.task_component.empty_add_date+','
          chek_valid++
        }
        if (_this.create_params.objectId == null || _this.create_params.objectId == '') {
          check_message = check_message+'\n '+this.lang.task_component.empty_object+','
          chek_valid++
        }


        if (_this.create_params.executive == null || _this.create_params.executive == '') {
          check_message = check_message+'\n '+this.lang.task_component.empty_field+','
          chek_valid++
        }

        let  patt = /\+[0-9]{6,24}/;
        if (!patt.test(_this.create_params.executiveGsm) && _this.create_params.sendSms == 1) {
          check_message = check_message+'\n '+this.lang.task_component.valid_phone+','
          chek_valid++
        }

        let filter = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/;
        if (_this.create_params.sendEmail == 1 && (_this.create_params.executiveEmail == null || _this.create_params.executiveEmail == '' || !filter.test(_this.create_params.executiveEmail)) ) {
          check_message = check_message+'\n '+this.lang.task_component.valid_email+','
          chek_valid++
        }
        if(chek_valid > 0){
          _this.$iosAlert(check_message)
        }
        return  chek_valid
      },
    CreateTask(){
      let chek_valid = 0;

      chek_valid = this.validateonRules();

      if(chek_valid === 0){
        this.saveChangeTask(1)
      }

    },
      UpdateTask(){

        let chek_valid = 0;
        chek_valid = this.validateonRules();
        if(chek_valid === 0){
          this.saveChangeTask(2)
        }
      },
    saveChangeTask(save_type){
      let _this = this
     let path_url = '/task/create';
      let formData = new FormData()
      if(save_type === 2){
        formData.append("taskId",  _this.create_params.taskId);
        formData.append("reset",  _this.create_params.reset);
        path_url = '/task/update';
      }
      formData.append("objectId",  _this.create_params.objectId);
      formData.append("type",  _this.create_params.type);
      formData.append("sendSms",  _this.create_params.sendSms);
      formData.append("sendEmail",  _this.create_params.sendEmail);

      formData.append("sendBefore",  _this.create_params.sendBefore);

      formData.append("executive",  _this.create_params.executive);
      formData.append("executiveGsm",  _this.create_params.executiveGsm);
      formData.append("executiveEmail",  _this.create_params.executiveEmail);
      formData.append("comment",  _this.create_params.comment);

      let form_date = this.$moment(_this.create_params.add_date).format('DD.MM.YYYY');
      formData.append("date",  form_date);
      if(_this.create_params.type == 3 || _this.create_params.type == 4 ) {
        formData.append("value", _this.create_params.value);
      }

      axios.post(base_url + path_url, formData).then(response => {


        if(response.data.success == true){
          if(save_type === 2){
            _this.$iosAlert(_this.lang.task_component.success_create);
          }else{
            _this.$iosAlert(_this.lang.task_component.success_update);
          }
          _this.closeCreateModal();
          _this.getAllTasks();

        }else{
          _this.$iosAlert(response.data.error);
        }

      }).catch(error => {
        console.log(error)
      })
    },
      getUpdateTask(taskId){
        let _this =this;
        let task  = this.tasks_list.find(bird => bird.taskId === taskId);
        if(task != null){

          let formData = new FormData()
          formData.append("taskId", taskId);
          axios.post(base_url + '/task/get', formData).then(response => {

            if(response.data.success == true ){

              _this.create_params = {
                vehicle_name: task.objectName,
                taskId:taskId,
                objectId:task.objectId,
                type:response.data.type,
                sendSms : task.sendSms ? 1 : 0 ,
                sendEmail : task.sendEmail ? 1 : 0 ,
                sendBefore: response.data.sendBefore,
                value: response.data.value,
                executive:response.data.executive,
                executiveGsm: response.data.executiveGsm,
                executiveEmail : response.data.executiveEmail,
                comment: response.data.comment ? response.data.comment : '',
                add_date:response.data.date,
                reset:0,
                change:0,
              }
              if(_this.create_params.add_date != null && _this.create_params.add_date.length > 0){
                _this.create_params.add_date = _this.$moment(response.data.date,'DD.MM.YYYY').format('YYYY-MM-DD')
              }
              _this.current_type = 2;
              _this.create_modal = true;

            }else{
              _this.$iosAlert(response.data.error);
            }


          }).catch(error => {
            console.log(error)
          })

        }

     },
    createEvent(){
      this.current_type = 1;
      this.create_modal = true;
    },
    closeCreateModal(){
      this.create_modal = false;
      this.create_params = {
        vehicle_name:null,
            taskId:null,
            objectId:null,
            type:1,
            sendSms : 0,
            sendEmail : 0,
            sendBefore: 10000,
            executive: null,
            executiveGsm: '+371',
            executiveEmail : null,
            comment:'',
            add_date: this.$moment().format('YYYY-MM-DD'),
            reset:0,
            change:0,
            value: 10000,
      }
    },
    getAllTasks(){
      let _this =this;
      _this.redy_loading = true;

      let formData = new FormData()
      formData.append("limit",  _this.params.limit);
      formData.append("page",  _this.params.page);
      formData.append("_search",  _this.params._search);
      formData.append("order",  _this.params.order);
      formData.append("direction",  _this.params.direction);
      formData.append("nd",  _this.params.nd);
      formData.append("filterStatus",  _this.params.filterStatus);
      formData.append("filterType",  _this.params.filterType);
      formData.append("search",  _this.params.search);


      axios.post(base_url + '/task/list', formData).then(response => {
        _this.tasks_list = [];
          if(response.data && response.data.data){
            _this.tasks_list =  response.data.data;

                _this.params.totalpages  =  Math.ceil(response.data.total/_this.params.limit);
          }

          _this.redy_loading = false;
      }).catch(error => {
        console.log(error)
      })
    },
    getAcceptTask(taskId){
      let _this =this;
      let message = this.lang.task_component.confirm_message
      this.$iosConfirm({
        text: message
      }).then(function () {
      let formData = new FormData();
      formData.append("taskId",  taskId);
      axios.post(base_url + '/task/accept', formData).then(response => {

       if(response.data){
         if(response.data.success == false){
           _this.$iosAlert(response.data.error);

         }else{

           _this.$iosAlert(_this.lang.task_component.accept_message)
           _this.getAllTasks()
         }
       }

      }).catch(error => {
        console.log(error)
      })
      })
    },
    getDeleteTask(taskId){
      let _this =this;
      let message = this.lang.task_component.confirm_delete
      this.$iosConfirm({
        text: message
      }).then(function () {
        let formData = new FormData();
        formData.append("taskId",  taskId);
        axios.post(base_url + '/task/delete', formData).then(response => {


          if(response.data){
            if(response.data.success == false){
              _this.$iosAlert(response.data.error);

            }else{
             // _this.$iosAlert(_this.lang.task_component.delete_message)
              _this.getAllTasks()
            }
          }

        }).catch(error => {
          console.log(error)
        })
      })
    }
  },
}

</script>

<style scoped>
.ps {
  max-height: calc(100vh - 160px);
}


.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 4px;

}


.panel-footer {
  border-radius: 0 0 5px 5px;

}
.modal.tasks-modal{
  background: rgba(26,35,126,0.2);
}
.modal-dialog.tasks-modal-dialog{
  margin-top: 20%;
}

.modal-dialog.tasks-modal-dialog .modal-content {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.require-field{
  font-weight: bold;
  color: #ff3d00;
}


/*searcg_group*/





@media only screen and (max-width: 1600px){
  .panel.global-settings-panel{
    width: calc(100vw - 50px );
    height: calc(100vh - 42px );

  }
  .vehicle-head.task-head{
  flex-wrap: wrap;
  }
  .panel-title{
    width: 100%;
    padding-bottom: 10px;
  }
  .ps {
    max-height: calc(100vh - 200px);
  }
}
@media only screen and (max-width: 1200px){
  .ps {
    max-height: calc(100vh - 240px);
  }
}
@media only screen and (max-width: 767px){
  .modal-dialog.tasks-modal-dialog {
    margin-top: 35%;
  }
  .panel.global-settings-panel{
    width: 100%;
    height: calc(100vh - 42px );
    top: 60px;
    position: fixed;
  }
  .ps {
    height:  calc(100vh - 260px );
    max-height:  calc(100vh - 260px );
  }
  .global-settings-table {
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 600px){
  .search-group{
    margin: 0 5px;
  }
  .search-group.search-status-group label {
    width: 100px;
  }
  .search-group label {
    width: 100px;
    text-align: start;
  }
  .input-group .form-control{
    width: 124px !important;
  }
  .mobile-search-scroll{
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    box-shadow: none;
    border-color: #1c71da;
    border-radius: 50%;
  }
  .search-scroll-icon{
    color: #1c71da;
  }
  .search-scroll-icon svg{
    fill: #1c71da;
  }
  .search-group{
    margin-bottom: 10px !important;
  }

  .vehicle-head.task-head {
    flex-wrap: wrap;
    height: 60px;
    overflow: hidden;
    transition: height 0.6s linear;
  }
  .vehicle-head.task-head.scroll-head-hide{
    height:auto;
  }
  .ps {
    height:  calc(100vh - 220px );
    max-height:  calc(100vh - 220px );
  }
  .ps.scroll-head-hide {
    height:  calc(100vh - 350px );
    max-height:  calc(100vh - 350px );
  }
  .panel-footer {
    padding: 10px 0;
    margin-top: 0;
  }
  .button-scroll.button-scroll-center.hidelog{
    display: none;
  }
  .button-scroll-center.button-scroll-total span:nth-child(1){
    display: none;
  }
  tr.event-table-list{
    position: relative;
  }
  tr.event-table-list td{
    padding-bottom: 30px !important;
  }
  tr.event-table-list td.btn-row{
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
padding-bottom: 4px !important;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .modal-dialog.tasks-modal-dialog {
    margin-top: 55%;
  }
  .modal-header {
    padding: 10px;
  }
  .modal-footer {
    padding: 10px;
  }
  .modal-body {
    position: relative;
    padding: 10px;
    padding-right: 20px;
    max-height: 70vh;
    overflow-y: auto;
  }
}
</style>