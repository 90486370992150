
export default {
  methods: {
    getInitinalDate() {

      let start = this.$moment().startOf('day').toDate();
      let end = this.$moment().startOf('day').add(23, 'hours').add(59, 'minutes').toDate();

      if(this.active_route==="routing" && this.select_type==="history" && this.history_time.hasOwnProperty(this.settings_active) && this.history_time[this.settings_active].start_val) {

        start =this.history_time[this.settings_active].start_val;
        end =  this.history_time[this.settings_active].end_val;
      }
      if(this.active_route==="fuel2" && this.select_fuel_data.hasOwnProperty(this.settings_fuel_active) && this.select_fuel_data[this.settings_fuel_active].start_val){

        start =new Date(this.select_fuel_data[this.settings_fuel_active].start_val);
        end =  new Date(this.select_fuel_data[this.settings_fuel_active].end_val);
      }
      return [start, end]

    },
  }
}
