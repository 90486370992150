<template>
  <svg width="32px" height="32px" viewBox="-5 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path id="Path_19" data-name="Path 19" d="M1002,44a10,10,0,0,0-10,10c0,5.523,10,20,10,20s10-14.477,10-20A10,10,0,0,0,1002,44Zm0,13a3,3,0,1,1,3-3A3,3,0,0,1,1002,57Z" transform="translate(-991 -43)" :fill="'#'.add_color" :stroke="color_palette[add_color]" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
  </svg>

</template>

<script>
export default {
  name: "markerIconComponent",
  props: {
    add_index:{
      type:Number,
      default: 0,
    },
    add_color:{
      type:String,
      default: "2979ff",
    },
  },
  data:{
    color_palette:{
      "2979ff":"#0D47A1",
      "4FC3F7":"#039BE5",
      "42D692":"#24a76b",
      "9575CD":"#5E35B1",
      "323232":"#000000",
      "795548":"#4E342E",
      "AFB42B":"#827717",
      "B2FF59":"#64DD17",
      "FA573C":"#cf2205",
      "F57C00":"#E65100",
      "F4511E":"#BF360C",
      "FFA726":"#F57C00",
      "FFB399":"#ff9a99",
      "FFFF99":"#d4d400",
    }
  }
}
</script>

<style scoped>

</style>