<template>
  <img  src="/tracking/images/sizzapp_white.svg" alt="">
</template>

<script>
export default {
  name: "svgLogoWhite"
}
</script>

<style scoped>

</style>