<template>
<span class="role menu" ><span class="data-date">{{currentDay}} </span><span class="data-minute">{{currentTime}}</span></span>
</template>

<script>
import moment from 'moment'
export default {
  name: "ClockComponent",

data() {
  return {
    date_now: '',
    currentTime: null,
    currentDay: ''
  }
},
mounted() {

  this.currentDay = moment().format('DD.MM.YYYY');
  this.currentTime = moment().format("HH:mm:ss");

  setInterval(() => this.updateCurrentTime(), 1 * 1000);
},
  methods: {
    updateCurrentTime() {
      this.currentTime = moment().format("HH:mm:ss");
    }
  },
}
</script>

<style scoped>

</style>
