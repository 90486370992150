<template>
  <section class="panel geoparams-box" :class="hide_content ? 'hide-box' : ''">
    <div class="panel-body geoparams-info-box">
      <div class="content">
        <div class="widget-content-expanded">

          <span  class="btn btn-default btn-renove-info" @click="removeInfo()"><span class="fa fa-times" ></span></span>
          <ul class="simple-todo-list geoparams__list">
            <li class="completed"><span class="geoparams__number" v-if="">{{info.Number}}</span></li>
            <li class="completed">
              <span class="geoparams__list-svg"><svg-rout-sitevare-icon/></span>

              <span><translate-component :text="lang.geoparams_siteber_component.total_distance"/>: </span><span>{{info.gps_drive_distance}} Km</span></li>
            <li class="completed">
              <span class="geoparams__list-svg "><svg-time-circle-icon/></span>
                <span><translate-component :text="lang.geoparams_siteber_component.total_time"/>: </span>
              <span>{{info.gps_total_drive_time}}</span>
            </li>

            <li class="completed" v-if="info.gps_total_stop_time !==0">
              <span class="geoparams__list-svg "><svg-parking-square-icon/></span>
                <span><translate-component :text="lang.geoparams_siteber_component.stop_length"/>: </span>
                <span>{{info.gps_total_stop_time}}</span>
            </li>

            <li class="completed"><span class="geoparams__list-svg geoparams__list-top-speed"><svg-speed-object-icon/></span><span><translate-component :text="lang.geoparams_siteber_component.topspeed"/>: </span><span>{{info.gps_top_speed}} km/h</span></li>
            <li class="completed"><span class="geoparams__list-svg geoparams__list-avarage-speed"><svg-speed-object-icon/></span><span><translate-component :text="lang.geoparams_siteber_component.avgspeed"/>: </span><span>{{info.gps_avg_speed}} km/h</span></li>

          </ul>
        </div>
      </div>
    </div>
    <span  class="hide-content-btn"   @click="hideContent()" ><svg-move-down-table-icon/></span>
    <perfect-scrollbar>
    <div class="panel-body geoparams-info-main">

      <div class="content">

        <span style="display: none">{{help_counter}}</span>
        <ul class="simple-user-list geoparams-box__list" v-if="params.stops  && params.routes">
          <template  v-for="(item,index) in params.stops">
          <li class="geoparams-box__item"  :class="item.checkbox ?'selected' :''" @click="showPoint(item,index)">
              <img :src="base_url + '/images/stop-sign.png'" alt="icon" class="geoparams-box__img">
            <div class="geoparams-box__item-column">
            <div class="geoparams-box__item-date">{{item.stop_start_time}}</div>

              <div class="geoparams-box__item-title" v-if="item.address">
                {{item.address}}
              </div>
              <div class="geoparams-box__item-title" v-else>
                <translate-component :text="lang.geoparams_siteber_component.loading"/>
              </div>

            </div>
            <div class="geoparams-box__item-interval">{{item.stop_time}} <span> </span></div>
            <div class="geoparams-box__checkbox">
             </div>
          </li>
            <template v-if="index < params.routes.length">
              <li class="geoparams-box__item" :class="params.routes[index].checkbox ?'selected' :''"  @click="showPoint(params.routes[index],index)">
                <div class="geoparams-box__index">{{index+1}}</div>
                  <img :src="base_url + '/images/route-sign.png'" alt="icon" class="geoparams-box__img route-img">
                <div class="geoparams-box__item-column">
                  <div class="geoparams-box__item-date">{{params.routes[index].route_start_time}}</div>
                    <div class="geoparams-box__item-title">{{params.routes[index].route_dist}} <span> Km</span></div>
                </div>
                <div class="geoparams-box__item-interval">{{getRouteTime(params.routes[index].route_time_sec)}} <span> </span></div>
                <div class="geoparams-box__checkbox">
                  <button type="button" class="btn btn-default btn-excel" @click.stop="getExcelRoute(params.routes[index])"><svg-excel-icon/></button>
                </div>

              </li>
            </template>
          </template>

        </ul>
      </div>
    </div>
    </perfect-scrollbar>

  </section>
</template>

<script>
import {mapGetters} from "vuex";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import zoomIcon from "@/mixins/zoomIcon";
import SvgSpeedObjectIcon from "@/components/ui/icons/svgSpeedObjectIcon";
import SvgParkingSquareIcon from "@/components/ui/icons/svgParkingSquareIcon";
import SvgTimeCircleIcon from "@/components/ui/icons/svgTimeCircleIcon";
import SvgRoutSitevareIcon from "@/components/ui/icons/svgRoutSitevareIcon";
import SvgExcelIcon from "@/components/ui/icons/svgExcelIcon";
import moment from "moment";
import SvgMoveDownTableIcon from "@/components/ui/icons/svgMoveDownTableIcon";
import axios from "axios";
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);
typeof moment.duration.fn.format === "function";
// true
typeof moment.duration.format === "function";
// true
export default {
  name: "GeoparamsSitebarComponent",
  components: {
    SvgMoveDownTableIcon,
    SvgExcelIcon,
    SvgRoutSitevareIcon, SvgTimeCircleIcon, SvgParkingSquareIcon, SvgSpeedObjectIcon, TranslateComponent},
  mixins: [zoomIcon],
  data() {
    return {
      route_counter:0,
      reload_map:1000,
      hide_content:false,
      help_counter:0,
   base_url:base_url,
      params:{},
      info:{},
    }
  },
  mounted() {
    this.params = this.history_data[this.settings_active]
    let total_time = 0
    this.params.routes.map(item=>{
      total_time = total_time+item['route_time_sec']
    })
    let drive_time = moment.duration(parseInt(total_time), "seconds").format("HH:mm")
    if(drive_time.indexOf(':') > -1){

    }else{
      drive_time = '00:'+drive_time
    }
    this.params['gps_total_drive_time'] = drive_time
    this.infoCardCreate()

    if(this.settings_active === '5404'){
      this.info['Number'] = 'demo_2'
    }
    if(this.settings_active === '16481'){
      this.info['Number'] = 'demo_1'
    }
    if(this.settings_active === '6786'){
      this.info['Number'] = 'demo_3'
    }
    let item   = [];
    let formData = new FormData();

    let i =0
    this.params.stops.map(marker =>{
      formData.append('params['+ i +'][x]', marker.X);
      formData.append('params['+ i +'][y]', marker.Y);
      //item.push({x: marker.X, y: marker.Y})
      i++
    })
    this.showRevgeoInfo(formData)

  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      history_table_show: 'HISTORY_TABLE_SHOW',
      settings_active: 'SETTINGS_ACTIVE',
      history_data:'HISTORY_DATA',
      map_zoom: 'MAP_ZOOM',
    }),
  },
  methods:{
    getRouteTime(route_time_sec){
      let drive_time = moment.duration(parseInt(route_time_sec), "seconds").format("HH:mm")
      if(drive_time.indexOf(':') > -1){

      }else{
        drive_time = '00:'+drive_time
      }
      return  drive_time
     },
    infoCardCreate(){

      this.info={
        'Comment':this.params['Comment'],
        'Number':this.params['Number'],
        'can_total_distance':this.params['can_total_distance'],
        'exectime':this.params['exectime'],
        'gps_avg_speed':this.params['gps_avg_speed'],
        'gps_drive_distance':this.params['gps_drive_distance'],
        'gps_top_speed':this.params['gps_top_speed'],
        'gps_total_distance':this.params['gps_total_distance'],
        'gps_total_drive_time':this.params['gps_total_drive_time'],
        'gps_total_drive_time_sec':this.params['gps_total_drive_time_sec'],
        'gps_total_stop_time':this.params['gps_total_stop_time'],
        'gps_total_time':this.params['gps_total_time']
      }
      if(this.settings_active === '5404'){
        this.info['Number'] = 'demo_2'
      }
      if(this.settings_active === '16481'){
        this.info['Number'] = 'demo_1'
      }
      if(this.settings_active === '6786'){
        this.info['Number'] = 'demo_3'
      }
    },
    showRevgeoInfo(item) {

      axios.post(base_url + '/index/revgeocoding',  item
      ).then(response => {

        if(response.data.length >0) {
          let k = 0
          this.params.stops.map(marker => {
            marker['address'] = response.data[k]
            k++
          })
          this.help_counter++;
        }
      }).catch(error => {
        console.log(error)
      })

    },
    hideContent(){
      this.hide_content = !this.hide_content
      this.reload_map++
      let $this = this
      setTimeout(function () {
        $this.$store.commit('SET_RELOAD_MAP', this.reload_map);
      }, 200);

    },
    removeInfo(){

      this.infoCardCreate()

      this.params.routes.map(item=>{
        item.checkbox = false
      })
      this.params.stops.map(item=>{
        item.checkbox = false
      })
      this.help_counter++
      this.$store.commit('SET_UPDATE_POLYLINE', {'type':1,'counter':Math.random()});
      this.route_counter =0
    },
    getExcelRoute(item){
      let utcStart_old = new moment(parseInt(item.route_start_time_sec+'000')).unix();
      let utcStart = new moment(parseInt(item.route_start_time_sec+'000')).utc('UTC').unix();
      let diffInSeconds = utcStart-utcStart_old;

      let change_add = parseInt(item.route_time_sec)-diffInSeconds
      let start = moment(parseInt(item.route_start_time_sec+'000')).add(-diffInSeconds, 'seconds').format("DD.MM.YYYY HH:mm:ss ")
      let end = moment(parseInt(item.route_start_time_sec+'000')).add(change_add, 'seconds').format("DD.MM.YYYY HH:mm:ss ")

      let datas =[start,end];
      this.$store.commit('SET_ROUTE_EXCEL', datas);

    },
    showPoint(item,index){
      let $this = this
      if(item.route_dist) {
        if(this.params.routes[index].checkbox &&  this.params.routes[index].checkbox ===1){
          this.params.routes[index].checkbox =0;
          if(this.route_counter !== 0) {
            this.route_counter = this.route_counter - 1
          }
          if(this.route_counter === 0){
            this.infoCardCreate()
          }
        }else {
        this.route_counter++
          this.params.routes[index].checkbox =1;
          setTimeout(function () {
              $this.$store.commit('SET_UPDATE_POLYLINE', {'type':3,'counter': $this.params.routes[index]['route_start_time_sec']});
          }, 500);
        }
        if(this.route_counter >  0){
          let work_routes =  []
          let max_speed = 0
          let total = 0
          let count = 0
          let route_dist = 0
          let total_drive_time =0
          let total_drive_time_sec=0
          let avarage = 0
          this.params.routes.map(item=>{
            if(item.checkbox &&  item.checkbox ===1){
              work_routes = work_routes.concat(item['r']);
              route_dist =route_dist + item['route_dist']
              total_drive_time_sec =total_drive_time_sec + item['route_time_sec']
            }
          })

          work_routes.map(item=>{
           if(parseInt(item['speed']) > max_speed){
             max_speed = parseInt(item['speed'])
           }
            total += parseInt(item['speed']);
            count++;
          })
          avarage = Math.round(total / count);
          let drive_time = moment.duration(parseInt(total_drive_time_sec), "seconds").format("HH:mm")
          if(drive_time.indexOf(':') > -1){

          }else{
            drive_time = '00:'+drive_time
          }

          this.info['gps_total_stop_time'] = 0
          this.info['gps_drive_distance'] = Math.round(route_dist * 100) / 100
          this.info['gps_total_drive_time_sec']=total_drive_time_sec
          this.info['gps_avg_speed']= avarage
          this.info['gps_top_speed']= max_speed
          this.info['gps_total_drive_time']=drive_time
        }
      }else{
        if(this.params.stops[index].checkbox &&  this.params.stops[index].checkbox ===1){
          this.params.stops[index].checkbox =0;
        }else{
          this.params.stops[index].checkbox =1;
        }
        this.zoomIcon(item,'stop_'+item.id)
      }

      this.help_counter++
    }
  }
}
</script>

<style scoped>
.geoparams__list-svg {
  width: 20px;
  height: 15px;
 position: relative;
  display: inline-block;
}
.geoparams__list-svg svg{
  width: 15px;
  height: 15px;
  fill: #BDBDBD;
  color: #BDBDBD;
  position: absolute;
  top: 2px;
}
.geoparams__list-svg.geoparams__list-top-speed svg{
  fill: #FF8A65 !important;
  color:#FF8A65 !important;
}
.geoparams__list-svg.geoparams__list-avarage-speed svg{
  fill: #81C784;
  color:#81C784;
}
.geoparams-box{
  position: relative;
  width: 300px;
  min-width: 300px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  right: 0;
  transition: right 0.2ms ease;
}
.geoparams-box.hide-box{
  position: absolute;
  right: -280px;
}

ul.simple-todo-list li.completed:before {
  color: #1C71DA;
  display: none;
}
ul.simple-todo-list li.completed {
  color: #1C71DA;
  font-weight: 600;
  line-height: 20px;
  padding: 0 0 0 5px;
}
ul.simple-todo-list li.completed span:nth-child(2){
  color: #777777;
  padding-right: 5px;
}
.geoparams-box__img{
  width: 25px;
  height: auto;
}

.geoparams-box__item{
  display: flex;
  align-items: center;
  padding: 6px 20px 6px 6px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 5px 7px -1px rgb(51 51 51 / 13%);
  cursor: pointer;
  transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
  background-color: #fff;
  margin-bottom: 10px;
  position: relative;
}

.geoparams-box__item:hover ,.geoparams-box__item:focus{
   transform: scale(1.1);
   box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);

 }
.geoparams-box__item.selected{
  background: rgb(176, 222, 234)
}

.geoparams-info-box{
  min-height: 140px;
  background: #f6f6f6;
  border-bottom: 1px solid #DADADA;
  position: relative;
  height: auto;
}
.btn-renove-info{
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 100000;
  background: transparent;
  border: none;
}
.geoparams-info-main{
  height: 100%;
}
.ps {
  height: calc(100% - 230px);
  max-height: unset !important;
}
.geoparams-box__item-column{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.geoparams-box__item-interval{
  top: 5px;
  right: 20px;
  color: #1C71DA;
  position: absolute;
}
.geoparams-box__img.route-img {
  margin-top: 18px;
}
.geoparams-box__item-title{
  font-weight: 600;
  color: #6b6b6b;
  font-size: 11px;
}
.geoparams-box__item-date{
  color: #1C71DA;
}
.geoparams-box__checkbox{
  margin-left: auto;
  margin-right: 30px;
  height: 100%;
  padding-top: 20px;
}
.geoparams__number{
  padding-right: 10px;
  font-size: 20px;
  color: #1C71DA;
}
.geoparams-box__index{
  color: #676767;
  position: absolute;
  top: 5px;
  left: 16px;
  font-size: 13px;
  opacity: 0.8;
  border: 1px solid #1C71DA;
  border-radius: 50%;
  width: 22px;
  font-weight: bold;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a5e1ff;
}
.btn-excel{
  padding: 2px 5px;
  line-height: 20px;
  display: flex;
  justify-content: center;
}
.btn-excel svg{
  width: 20px;
  height: 20px;
}
.hide-content-btn{
  position: absolute;
  top: 40vh;
  z-index: 100000;
  transform: rotate(-90deg);
  cursor: pointer;
}
.hide-content-btn svg{
  fill: #777;
}
.hide-content-btn:focus svg,.hide-content-btn:hover svg{
  fill:  #1C71DA;
}
.geoparams-info-main{
  padding-left: 20px;
}
.geoparams-info-main {
  height: 100vh;
  background-color: transparent;
  box-shadow: none;
}
ul.simple-user-list {
  padding-bottom: 20px;
}
@media only screen and (max-width: 1500px) {


  .geoparams-box__index{
    color: #676767;
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 12px;
    opacity: 0.8;
    border: 1px solid #1C71DA;
    border-radius: 50%;
    width: 20px;
    font-weight: 500;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #a5e1ff;
  }

  .geoparams__number{
    font-size: 16px;
    width: 180px;
    display: inline-block;
  }
  .history-table__conrtent {
    width: 240px;
  }
  .geoparams-box {
    width: 240px;
    min-width: 240px;
  }
  .geoparams-box.hide-box {
    right: -220px;
  }
  .geoparams-box__item {
    padding: 4px 10px 4px 4px;
    border-radius: 3px;
    margin-bottom: 5px;
  }
  .geoparams-box__checkbox{
    margin-right: 10px;
  }
  .btn-renove-info {
    top: 0px;
    right: 0px;
  }
  .geoparams-box__img {
    width: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .geoparams-box {
    width: 240px;
    min-width: 240px;
    z-index: 100000;
  }
  .geoparams-info-box{
    width: 220px;
    margin-left: 20px;
  }
  .geoparams__number {
    font-size: 14px;

  }
  ul.simple-todo-list li.completed {
    font-size: 11px;
  }
  .geoparams-box {
    z-index: 100000;
    position: absolute;
    background: #ecedf0;
    max-height: calc(100vh - 60px);
    bottom: 0;
    top: 0px;
  }

  .geoparams-box.hide-box {

    background: transparent;
  }

  .ps {
    height: calc(100vh - 220px);

  }
}
</style>
