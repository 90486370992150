<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 58 58" style="enable-background:new 0 0 58 58;" xml:space="preserve">
<g>
	<path d="M51,34.36V32h1c0.553,0,1-0.447,1-1s-0.447-1-1-1h-1h-1H36h-1h-1c-0.553,0-1,0.447-1,1s0.447,1,1,1h1v2.36   c0,3.534,1.845,6.748,4.827,8.64C36.845,44.892,35,48.105,35,51.64V54h-1c-0.553,0-1,0.447-1,1s0.447,1,1,1h1h14h2h1   c0.553,0,1-0.447,1-1s-0.447-1-1-1h-1v-2.36c0-3.534-1.845-6.748-4.827-8.64C49.155,41.108,51,37.895,51,34.36z M49,51.64V54H37   v-2.36c0-3.319,2.033-6.298,5.197-7.64h1.605C46.967,45.342,49,48.32,49,51.64z M43.803,42h-1.605C39.033,40.658,37,37.68,37,34.36   V32h12v2.36C49,37.68,46.967,40.658,43.803,42z"/>
  <path d="M37,25c0-0.342-0.175-0.66-0.463-0.844l-11-7c-0.31-0.195-0.698-0.208-1.019-0.033C24.199,17.299,24,17.635,24,18v14   c0,0.365,0.199,0.701,0.519,0.877C24.669,32.959,24.835,33,25,33c0.187,0,0.373-0.053,0.537-0.156l11-7   C36.825,25.66,37,25.342,37,25z M26,30.179V19.821L34.138,25L26,30.179z"/>
  <path d="M57,2H47H11H1C0.447,2,0,2.447,0,3v11v11v11v11c0,0.553,0.447,1,1,1h10h18c0.553,0,1-0.447,1-1s-0.447-1-1-1H12V36V25V14V4   h34v10v11c0,0.553,0.447,1,1,1h9v8c0,0.553,0.447,1,1,1s1-0.447,1-1v-9V14V3C58,2.447,57.553,2,57,2z M2,26h8v9H2V26z M10,24H2v-9   h8V24z M2,46v-9h8v9H2z M10,13H2V4h8V13z M56,4v9h-8V4H56z M48,24v-9h8v9H48z"/>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgPlayerIcon"
}
</script>

<style scoped>

</style>
