<template>
  <div
    class="vdpr-datepicker__calendar-dialog"
    :class="{
      'vdpr-datepicker__calendar-dialog--inline': this.inline,
    }"
  >
    <template v-if="show_short">
      <div class="helper-button-box" @click="show_helper = !show_helper">
<svg-time-circle-icon></svg-time-circle-icon>
      </div>
      <div class="vdpr-datepicker__calendar-button-helper small-helper-data" v-if="helpers.length && show_helper">
        <button
          v-for="btn in helpers"
          :key="'btn' + btn.name"
          :class="[
          'vdpr-datepicker__button',
          'vdpr-datepicker__button--block',
          'vdpr-datepicker__button-default',
        ]"
          @click="
          () => {
            onHelperClick(btn.from, btn.to);
          }
        "
        >{{ btn.name }}</button>
      </div>
    </template>
    <template v-else>
      <div class="vdpr-datepicker__calendar-button-helper"  v-if="helpers.length">
        <button
          v-for="btn in helpers"
          :key="'btn' + btn.name"
          :class="[
          'vdpr-datepicker__button',
          'vdpr-datepicker__button--block',
          'vdpr-datepicker__button-default',
        ]"
          @click="
          () => {
            onHelperClick(btn.from, btn.to);
          }
        "
        >{{ btn.name }}</button>
      </div>
    </template>

    <calendar
      :show_short="show_short"
      :language="language"
      oldSelect="not"
      :selectedStartDate="selectedStartDate"
      :selectedEndDate="selectedEndDate"
      :disabledDates="disabledDates"
      :isMondayFirst="isMondayFirst"
      @select-date="selectDate"
      @select-disabled-date="selectDisabledDate"
      @on-prev-calendar="onPrevCalendar"
      @on-next-calendar="onNextCalendar"
    />

    <div class="vdpr-datepicker__calendar-actions" :class="show_short ? 'small-input-data' : ''">

      <div class="vdpr-datepicker__calendar-input-wrapper">
        <span >{{show_short ? '':  labelStarts }}</span>
        <calendar-input-date
          :format="dateInput.format"
          :inputClass="dateInput.inputClass"
          :timestamp="unixSelectedStartDate"
          :language="language"
          @on-change="onStartInputDateChange"
        />
      </div>
      <div class="vdpr-datepicker__calendar-input-wrapper vdpr-datepicker__calendar-input-wrapper--end" >
        <calendar-input-time
          v-show="isVisibleTimeInput"
          :step="timeInput.step"
          :readonly="timeInput.readonly"
          :inputClass="timeInput.inputClass"
          :timestamp="unixSelectedStartDate"
          @on-change="onTimeStartInputChange"
        />
      </div>
      <div class="vdpr-datepicker__calendar-input-wrapper">
        <span>{{show_short ? '': labelEnds }}</span>
        <calendar-input-date
          :format="dateInput.format"
          :inputClass="dateInput.inputClass"
          :timestamp="unixSelectedEndDate"
          :language="language"
          @on-change="onEndDateInputDateChange"
        />
      </div>


      <div class="vdpr-datepicker__calendar-input-wrapper vdpr-datepicker__calendar-input-wrapper--end" >
        <calendar-input-time
          v-show="isVisibleTimeInput"
          :step="timeInput.step"
          :readonly="timeInput.readonly"
          :inputClass="timeInput.inputClass"
          :timestamp="unixSelectedEndDate"
          @on-change="onTimeEndInputChange"
        />
      </div>

    </div>
    <div class="vdpr-datepicker__submit-button" :class="show_short ? 'small-input-button' : ''">
    <button
      v-if="selectedStartDate && selectedEndDate"
      :class="[
          'vdpr-datepicker__button',
          'vdpr-datepicker__button--block',
          'vdpr-datepicker__button-submit',
        ]"
      @click="onClickButtonApply"
    >{{ new_applyButtonLabel }}</button>
    <button
      :class="[
          'vdpr-datepicker__button',
          'vdpr-datepicker__button--block',
          'vdpr-datepicker__button-reset',
        ]"
      @click="onClickButtonReset"
    >{{new_resetButtonLabel }}</button>
    </div>
  </div>
</template>

<script>
import PropsValidator from '../Utils/PropsValidator';
import DateUtil from '../Utils/DateUtil';
import Calendar from './Calendar.vue';
import SwitchButton from './SwitchButton.vue';
import CalendarInputDate from './CalendarInputDate.vue';
import CalendarInputTime from './CalendarInputTime.vue';
import {mapGetters} from "vuex";
import moment from 'moment'
import SvgCloseIcon from "@/components/ui/icons/svgCloseIcon";
import SvgTimeCircleIcon from "@/components/ui/icons/svgTimeCircleIcon";
import LoginComponent from "@/components/login/LoginComponent";

const  calendar_lang =  {
  ru: {
    starts: 'Начало',
        ends: 'Конец',
        all_days:'Все дни',
        apply:'Применить',
        reset:'Сброс',
        today: 'Сегодня',
        yesterday: 'Вчера',
        this_week: 'На этой неделе',
        last_week: 'Прошлая неделя',
        this_Month: 'Этот месяц',
        last_Month: 'Прошлый месяц',
        this_year: 'В этом году',
        last_year: 'Прошлый год',
  },
  en: {
    starts: 'Starts',
        ends: 'Ends',
        all_days:'All Days',
        apply:'Apply',
        reset:'Reset',
        today: 'Today',
        yesterday: 'Yesterday',
        this_week: 'This Week',
        last_week: 'Last Week',
        this_Month: 'This Month',
        last_Month: 'Last Month',
        this_year: 'This Year',
        last_year: 'Last Year',
  },
  pl: {
    starts: 'Zaczyna się',
    ends: 'Kończy się',
    all_days:'Wszystkie dni',
    apply:'Zastosować',
    reset:'Resetowanie',
    today: 'Dzisiaj',
    yesterday: 'Wczoraj',
    this_week: 'W tym tygodniu',
    last_week: 'Zeszły tydzień',
    this_Month: 'Ten miesiąc',
    last_Month: 'W zeszłym miesiącu',
    this_year: 'W tym roku',
    last_year: 'Ostatni rok',
  },
  lt: {
    starts: 'Prasideda',
    ends: 'Baigiasi',
    all_days:'Visos dienos',
    apply:'Taikyti',
    reset:'Atstatyti',
    today: 'Šiandien',
    yesterday: 'Vakar',
    this_week: 'Šią savaitę',
    last_week: 'Praeitą savaitę',
    this_Month: 'Šį mėnesį',
    last_Month: 'Praeitą mėnesį',
    this_year: 'Šiais metais',
    last_year: 'Praeitais metais',
  },
  lv: {
    starts: 'Sākas',
    ends: 'Beidzas',
    all_days:'Visas dienas',
    apply:'Apstiprināt',
    reset:'Atiestatīt',
    today: 'Šodien',
    yesterday: 'Vakar',
    this_week: 'Šonedēļ',
    last_week: 'Pagājušajā nedēļā',
    this_Month: 'Šis mēnesis',
    last_Month: 'Pagājušajā mēnesī',
    this_year: 'Šogad',
    last_year: 'Pagājušais gads',
  },
  it: {
    starts: 'Inizia',
    ends: 'Finisce',
    all_days:'Tutti i giorni',
    apply:'Applicare',
    reset:'Ripristina',
    today: 'Oggi',
    yesterday: 'Ieri',
    this_week: 'Questa settimana',
    last_week: 'La settimana scorsa',
    this_Month: 'Questo mese',
    last_Month: 'Lo scorso mese',
    this_year: "Quest'anno",
    last_year: "L'anno scorso",
  },
}
export default {
  components: {
    SvgTimeCircleIcon,
    SvgCloseIcon,
    Calendar,
    CalendarInputDate,
    CalendarInputTime,
    SwitchButton,
    moment
  },
  props: {
    show_short:{
      type: Boolean,
      default:false,
    },
    select_type:{
      type:String,
      default:''
    },
    inline: {
      type: Boolean,
      default: false,
    },
    initialDates: {
      type: Array,
      /*validator: PropsValidator.isValidInitialDate,
      default() {
        return [];
      },*/
    },
    language: {
      type: String,
      default: 'en',
    },
    disabledDates: {
      type: Object,
      validator: PropsValidator.isValidDisabledDates,
      default() {
        return {};
      },
    },
    showHelperButtons: {
      type: Boolean,
      default: true,
    },
    helperButtons: {
      type: Array,
      validator: PropsValidator.isValidHelperButtons,
      default() {
        return [];
      },
    },
    timeInput: {
      type: Object,
      default() {
        return {
          inputClass: null,
          readonly: false,
          step: 60, // in minutes
        };
      },
    },
    dateInput: {
      type: Object,
      default() {
        return {
          labelStarts: calendar_lang['en'].starts,
          labelEnds: calendar_lang['en'].ends,
          inputClass: null,
          format: 'DD/MM/YYYY',
          readonly: false,
        };
      },
    },
    switchButtonLabel: {
      type: String,
      default: 'All Days',
    },
    switchButtonInitial: {
      type: Boolean,
      default: false,
    },
    applyButtonLabel: {
      type: String,
      default: 'Apply',
    },
    resetButtonLabel: {
      type: String,
      default: 'Reset',
    },
    isMondayFirst: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const dateUtil = new DateUtil('lv');
    const [from, to] = this.initialDates;
    let isAllDay = false;

    return {
      show_helper:false,
      add_language: 'en',
      selectedStartDate: from,
      selectedEndDate: to ,
      isAllDay,
      new_switchButtonLabel:'',
      new_applyButtonLabel:'',
      new_resetButtonLabel:'',
      labelStarts:'',
      labelEnds:'',
      start_counter :0,
    };

  },
 mounted() {

    this.labelStarts =calendar_lang[this.language].starts
        this.labelEnds = calendar_lang[this.language].ends
   this.new_switchButtonLabel = calendar_lang[this.language].all_days
   this.new_applyButtonLabel = calendar_lang[this.language].apply
   this.new_resetButtonLabel = calendar_lang[this.language].reset

  },
  computed: {
      ...mapGetters({
        settings_active: 'SETTINGS_ACTIVE',
        history_time: 'HISTORY_TIME',
        fuel_time:'SELECT_FUEL_DATA',
        settings_fuel_active:'SETTINGS_FUEL_ACTIVE',
        reports_select:"REPORTS_SELECT",
        lang: 'LANG',

      }),
    dateUtil() {
      return new DateUtil('lv');
    },
    helpers() {
      if (!this.showHelperButtons) return [];

      if (this.helperButtons.length) return this.helperButtons;

      return this.getDefaultHelpers();
    },
    unixSelectedStartDate() {
      if (!this.selectedStartDate) {
        return 0;
      }
      return this.dateUtil.toUnix(this.selectedStartDate);
    },
    unixSelectedEndDate() {
      if (!this.selectedEndDate) {
        return 0;
      }
      this.selectedEndDate = this.dateUtil.endOf(this.selectedEndDate, 'd');
      return this.dateUtil.toUnix(this.selectedEndDate);

    },
    isVisibleTimeInput() {
      return !this.isAllDay;
    },
    isVisibleButtonApply() {
      return !this.inline;
    },
  },
  methods: {
    isStartDate(){
      let old_date =this.dateUtil.subtract(now, 1, 'M');
      return old_date
    },
    onCheckChange(check) {
      this.isAllDay = check;
      if (!this.selectedStartDate || !this.selectedEndDate) return;

      this.selectedStartDate = this.dateUtil.startOf(
        this.selectedStartDate,
        'd',
      );
      if (check) {
        this.selectedEndDate = this.dateUtil.endOf(this.selectedEndDate, 'd');
      } else {
        this.selectedEndDate = this.dateUtil.startOf(this.selectedEndDate, 'd');
      }
    },
    onStartInputDateChange(value) {
      this.applyOrSwapApply(value, this.selectedEndDate);
      this.emitOnApplyIfInline();
    },
    onEndDateInputDateChange(value) {
      this.applyOrSwapApply(this.selectedStartDate, value);
      this.emitOnApplyIfInline();
    },
    onTimeStartInputChange(value) {
      this.applyOrSwapApply(value, this.selectedEndDate);
      this.emitOnApplyIfInline();
    },
    onTimeEndInputChange(value) {
      this.applyOrSwapApply(this.selectedStartDate, value);
      this.emitOnApplyIfInline();
    },
    onHelperClick(fromDate, toDate) {

      this.show_helper= false;
      this.isAllDay = false;
      this.applyOrSwapApply(fromDate, toDate);
      this.$emit('select-date', this.selectedStartDate, this.selectedEndDate)
      this.emitOnApplyIfInline();
    },
    onClickButtonApply() {
      this.$emit("on-apply", this.selectedStartDate, this.selectedEndDate)

      if(this.select_type === 'reports'){
        let add_objects = {...this.reports_select}
        if (Object.keys(add_objects).length === 0) {
          let message = this.lang.reports_component.select_any_object;
          this.$iosAlert(message);
        } else {
        let start = moment(this.selectedStartDate).format("DD.MM.YYYY HH:mm:ss")
        let end = moment(this.selectedEndDate).format("DD.MM.YYYY HH:mm:ss")

        let  newDate = new moment(this.selectedStartDate, "DD.MM.YYYY HH:mm:ss").utc('UTC').unix();
        let ndt1 = Math.round(newDate)
        let  newDate2 = new moment(this.selectedEndDate, "DD.MM.YYYY HH:mm:ss").utc('UTC').unix();
        let ndt2 = Math.round(newDate2)
        let data = {'start_time':start, 'end_time':end ,'ndt1' :ndt1 ,'ndt2': ndt2}
        this.$store.commit('SET_REPORTS_TIME', data)
        this.$store.dispatch('getReportsDailyData')
        }
      }
      if(this.select_type === 'history'){
        let start = moment(this.selectedStartDate).format("DD.MM.YYYY HH:mm:ss")
        let end = moment(this.selectedEndDate).format("DD.MM.YYYY HH:mm:ss")

         let data = {'start':start, 'end':end , 'start_val':this.selectedStartDate,'end_val':this.selectedEndDate}
         let old_data = {...this.history_time}

        old_data[this.settings_active] = data

        this.$store.commit('SET_HISTORY_TIME', old_data)
        this.$store.commit('SET_HISTORY_TABLE_SHOW', false)
      }
      if(this.select_type === 'fuel' || this.select_type === 'fuelOld') {

        if (this.settings_fuel_active === '' || this.settings_fuel_active == null) {
          let message = this.lang.reports_component.select_any_object;
          this.$iosAlert(message);
        } else {

          this.$store.commit('SET_CALENDAR_DATES', [this.selectedStartDate, this.selectedEndDate])

          let newDate = new moment(this.selectedStartDate, "DD.MM.YYYY HH:mm:ss").utc('UTC').unix();
          let start = Math.round(newDate)

          let newDate2 = new moment(this.selectedEndDate, "DD.MM.YYYY HH:mm:ss").utc('UTC').unix();
          let end = Math.round(newDate2)
          if (this.select_type === 'fuelOld') {
            end = Math.round(newDate2)
          }
          let diff = Math.round((end - start) / 86400)

          let data = {
            'start': start,
            'end': end,
            'diff': diff,
            'start_val': this.selectedStartDate,
            'end_val': this.selectedEndDate
          }
          let old_data = {...this.fuel_time}
          old_data[this.settings_fuel_active] = data
          this.$store.commit('SET_SELECT_FUEL_DATA', old_data)
          if (this.select_type === 'fuel') {
            this.$store.dispatch('getFuel2Data')
          }
          if (this.select_type === 'fuelOld') {
            this.$store.dispatch('getFuelData')

          }
        }
      }
    },
    onClickButtonReset() {
      this.selectedStartDate = null;
      this.selectedEndDate = null;
      this.isAllDay = false;

      this.$emit('on-reset');
    },
    selectDate(date) {

        let startDate = this.selectedStartDate;
        let endDate = this.selectedEndDate;
        if (
            this.dateUtil.isValidDate(startDate)
            && this.dateUtil.isValidDate(endDate)
            && this.dateUtil.isSameDate(startDate, endDate)
        ) {
          endDate = date;
        } else {
          startDate = date;
          endDate = date;
        }
 if(this.start_counter=== 0){
   startDate = date;
  //endDate =null;
   this.start_counter++
 }
      this.applyOrSwapApply(startDate, endDate);

      if (this.isAllDay) {
        this.selectedStartDate = this.dateUtil.startOf(
          this.selectedStartDate,
          'd',
        );
        this.selectedEndDate = this.dateUtil.endOf(this.selectedEndDate, 'd');
      }

      this.$emit('select-date', this.selectedStartDate, this.selectedEndDate);
      this.emitOnApplyIfInline();
    },
    selectDisabledDate(date) {
      this.$emit('select-disabled-date', date);
    },
    applyOrSwapApply(startDate, endDate) {
      if (this.dateUtil.isAfter(startDate, endDate)) {
        [this.selectedStartDate, this.selectedEndDate] = [endDate, startDate];
      } else {
        this.selectedStartDate = startDate;
        this.selectedEndDate = endDate;
      }
    },
    emitOnApplyIfInline() {
      if (this.inline) {
        this.$emit('on-apply', this.selectedStartDate, this.selectedEndDate);
      }
    },
    getDefaultHelpers() {
      const now = new Date();
      const yesterday = this.dateUtil.subtract(now, 1, 'd');
      const lastWeek = this.dateUtil.subtract(now, 7, 'd');
      const lastMonth = this.dateUtil.subtract(now, 1, 'M');
      const lastYear = this.dateUtil.subtract(now, 1, 'y');
      const todayFrom = this.dateUtil.startOf(now, 'd');
      const todayTo = this.dateUtil.endOf(now, 'd');
      const yesterdayFrom = this.dateUtil.startOf(yesterday, 'd');
      const yesterdayTo = this.dateUtil.endOf(yesterday, 'd');
      const thisWeekFrom = this.dateUtil.startOf(now, 'week');
      const thisWeekTo = this.dateUtil.endOf(now, 'week');
      const lastWeekFrom = this.dateUtil.startOf(lastWeek, 'week');
      const lastWeekTo = this.dateUtil.endOf(lastWeek, 'week');
      const thisMonthFrom = this.dateUtil.startOf(now, 'month');
      const thisMonthTo = this.dateUtil.endOf(now, 'month');
      const lastMonthFrom = this.dateUtil.startOf(lastMonth, 'month');
      const lastMonthTo = this.dateUtil.endOf(lastMonth, 'month');
      const thisYearFrom = this.dateUtil.startOf(now, 'year');
      const thisYearTo = this.dateUtil.endOf(now, 'year');
      const lastYearFrom = this.dateUtil.startOf(lastYear, 'year');
      const lastYearTo = this.dateUtil.endOf(lastYear, 'year');

      return [
        {
          name: calendar_lang[this.language].today,
          from: todayFrom,
          to: todayTo,
        },
        {
          name: calendar_lang[this.language].yesterday,
          from: yesterdayFrom,
          to: yesterdayTo,
        },
        {
          name: calendar_lang[this.language].this_week,
          from: thisWeekFrom,
          to: thisWeekTo,
        },
        {
          name: calendar_lang[this.language].last_week,
          from: lastWeekFrom,
          to: lastWeekTo,
        },
        {
          name: calendar_lang[this.language].this_Month,
          from: thisMonthFrom,
          to: thisMonthTo,
        },
        {
          name: calendar_lang[this.language].last_Month,
          from: lastMonthFrom,
          to: lastMonthTo,
        },
        {
          name: calendar_lang[this.language].this_year,
          from: thisYearFrom,
          to: thisYearTo,
        },
        {
          name: calendar_lang[this.language].last_year,
          from: lastYearFrom,
          to: lastYearTo,
        },
      ];
    },
    onPrevCalendar() {
      this.$emit('on-prev-calendar');
    },
    onNextCalendar() {
      this.$emit('on-next-calendar');
    },
  },
};
</script>

<style lang="scss">
@import "../Styles/CalendarDialog.scss";
.vdpr-datepicker__button-submit{
  max-width: 220px;
}
</style>
