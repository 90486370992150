import { render, staticRenderFns } from "./svgReportsIcon.vue?vue&type=template&id=7b3450bc&scoped=true&"
import script from "./svgReportsIcon.vue?vue&type=script&lang=js&"
export * from "./svgReportsIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b3450bc",
  null
  
)

export default component.exports