<template>
  <div class="panel-body group-box">
    <section class="panel panel-group" v-if="show_groups">
      <div id="accordion">
        <div class="panel panel-accordion panel-accordion-first">
          <div class="panel-heading">
            <h4 class="panel-title">
              <a class="accordion-toggle" data-toggle="collapse" data-parent="#accordion" href="#collapse1One" aria-expanded="true">
                <i class="fa fa-check"></i> <span class="label label-primary label-sm text-normal va-middle mr-sm" v-if="group_list.length>0" >{{group_list.length}}</span> <translate-component :text=" lang.group_list_component.group_list"></translate-component>

              </a>
            </h4>
          </div>
          <div id="collapse1One" class="accordion-body collapse in" aria-expanded="true" style="">
            <div class="panel-body">
              <ul class="widget-todo-list ui-sortable" v-if="group_list.length>0">
                <li v-for="(group,index) in group_list">
                  <div class="checkbox-custom checkbox-default">
                    <input type="checkbox" checked="" id="todoListItem1" class="todo-check">
                    <label class="todo-label line-through" for="todoListItem1"><span>{{group.groupName}}</span></label>
                  </div>
                  <div class="todo-actions">
                    <a class="todo-remove" href="#">
                      <i class="fa fa-times"></i>
                    </a>
                  </div>
                </li>

              </ul>
              <hr class="solid mt-sm mb-lg">
              <form method="get" class="form-horizontal form-bordered">
                <div class="form-group">
                  <div class="col-sm-12">
                    <div class="input-group mb-md">
                      <input type="text" class="form-control">
                      <div class="input-group-btn">
                        <button type="button" class="btn btn-primary" tabindex="-1">Add</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import TranslateComponent from "@/components/dashboard/TranslateComponent";

export default {
  name: "GroupListComponent",
  components: {TranslateComponent},
  props: {
    show_groups: {
      default: false,
      type: Boolean
    },
  },
    data() {
      return {
        group_counter:0,
      }
      },
    computed: {
      ...mapGetters({
        lang: 'LANG',
        group_list: 'GROUP_LIST'
      }),
  }
}
</script>

<style scoped>

</style>
