<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="daycloud"
       x="0px" y="0px" viewBox="0 0 224.8438 223.291" enable-background="new 0 0 224.8438 223.291" xml:space="preserve"
       width="25" height="25">
<g>
	<path fill="#D0E8FF"
        d="M64.7175,126.6084C52.2502,132.1729,44,144.1211,44,157.291c0,0.9863,0.0469,1.9648,0.1328,2.9297   c0.1211,1.3398-0.4414,2.6484-1.4922,3.4863c-1.043,0.8379-2.4453,1.0938-3.7305,0.6777   c-2.2305-0.7266-4.5547-1.0938-6.9102-1.0938c-13.2344,0-24,11.6641-24,26s10.7656,26,24,26h88c15.5898-1.0117,28-15.0723,28-32   c0-8.7944-3.2756-16.9668-9.2124-23.0576c-5.9175,1.9575-12.2219,3.0557-18.7876,3.0557   C95.1807,163.2891,73.835,148.1426,64.7175,126.6084z M71.8516,157.0059l-20.4727,20.4746   c-0.7812,0.7812-1.8047,1.1719-2.8281,1.1719s-2.0469-0.3906-2.8281-1.1719c-1.5625-1.5625-1.5625-4.0938,0-5.6562l20.4727-20.4746   c1.5625-1.5625,4.0938-1.5625,5.6562,0S73.4141,155.4434,71.8516,157.0059z M123.9375,175.3184v28.9551c0,2.209-1.7891,4-4,4   s-4-1.791-4-4v-28.9551c0-2.209,1.7891-4,4-4S123.9375,173.1094,123.9375,175.3184z"/>
  <path fill="#5CB0FF"
        d="M120,51.2891c-28.6719,0-52,23.3262-52,52s23.3281,52,52,52s52-23.3262,52-52S148.6719,51.2891,120,51.2891   z"/>
  <path fill="#1C71DA"
        d="M66.1953,151.3496l-20.4727,20.4746c-1.5625,1.5625-1.5625,4.0938,0,5.6562   c0.7812,0.7812,1.8047,1.1719,2.8281,1.1719s2.0469-0.3906,2.8281-1.1719l20.4727-20.4746c1.5625-1.5625,1.5625-4.0938,0-5.6562   S67.7578,149.7871,66.1953,151.3496z"/>
  <path fill="#1C71DA"
        d="M169.6914,57.5098c1.0234,0,2.0469-0.3906,2.8281-1.1719l20.4727-20.4727   c1.5625-1.5625,1.5625-4.0938,0-5.6562s-4.0938-1.5625-5.6562,0l-20.4727,20.4727c-1.5625,1.5625-1.5625,4.0938,0,5.6562   C167.6445,57.1191,168.668,57.5098,169.6914,57.5098z"/>
  <path fill="#1C71DA"
        d="M172.5195,151.3496c-1.5625-1.5625-4.0938-1.5625-5.6562,0s-1.5625,4.0938,0,5.6562l20.4727,20.4746   c0.7812,0.7812,1.8047,1.1719,2.8281,1.1719s2.0469-0.3906,2.8281-1.1719c1.5625-1.5625,1.5625-4.0938,0-5.6562L172.5195,151.3496z   "/>
  <path fill="#1C71DA"
        d="M66.1953,56.3379c0.7812,0.7812,1.8047,1.1719,2.8281,1.1719s2.0469-0.3906,2.8281-1.1719   c1.5625-1.5625,1.5625-4.0938,0-5.6562L51.3789,30.209c-1.5625-1.5625-4.0938-1.5625-5.6562,0s-1.5625,4.0938,0,5.6562   L66.1953,56.3379z"/>
  <path fill="#1C71DA"
        d="M220.8438,99.4922h-28.6211c-2.2109,0-4,1.791-4,4s1.7891,4,4,4h28.6211c2.2109,0,4-1.791,4-4   S223.0547,99.4922,220.8438,99.4922z"/>
  <path fill="#1C71DA"
        d="M119.9375,36.9551c2.2109,0,4-1.791,4-4V4c0-2.209-1.7891-4-4-4s-4,1.791-4,4v28.9551   C115.9375,35.1641,117.7266,36.9551,119.9375,36.9551z"/>
  <path fill="#1C71DA"
        d="M19.2891,107.4844h28.9531c2.2109,0,4-1.791,4-4s-1.7891-4-4-4H19.2891c-2.2109,0-4,1.791-4,4   S17.0781,107.4844,19.2891,107.4844z"/>
  <path fill="#1C71DA"
        d="M115.9375,175.3184v28.9551c0,2.209,1.7891,4,4,4s4-1.791,4-4v-28.9551c0-2.209-1.7891-4-4-4   S115.9375,173.1094,115.9375,175.3184z"/>
  <path fill="#1C71DA"
        d="M180,103.2891c0-33.084-26.9141-60-60-60s-60,26.916-60,60c0,5.4126,0.7859,10.6338,2.1357,15.625   c-15.1873,6.4556-25.4045,20.7393-26.0967,36.6445c-1.3359-0.1777-2.6836-0.2676-4.0391-0.2676c-17.6445,0-32,15.252-32,34   s14.3555,34,32,34l88.2578-0.0078C140.3008,221.9922,156,204.4258,156,183.291c0-9.8164-3.3252-19.022-9.3943-26.3037   C166.3616,147.1582,180,126.8115,180,103.2891z M148,183.291c0,16.9277-12.4102,30.9883-28,32H32c-13.2344,0-24-11.6641-24-26   s10.7656-26,24-26c2.3555,0,4.6797,0.3672,6.9102,1.0938c1.2852,0.416,2.6875,0.1602,3.7305-0.6777   c1.0508-0.8379,1.6133-2.1465,1.4922-3.4863C44.0469,159.2559,44,158.2773,44,157.291c0-13.1699,8.2502-25.1182,20.7175-30.6826   C73.835,148.1426,95.1807,163.2891,120,163.2891c6.5657,0,12.8701-1.0981,18.7876-3.0557   C144.7244,166.3242,148,174.4966,148,183.291z M120,155.2891c-28.6719,0-52-23.3262-52-52s23.3281-52,52-52s52,23.3262,52,52   S148.6719,155.2891,120,155.2891z"/>
</g>
    <path fill="#FF5D5D"
          d="M188.3854,74.1212c-1.0234,0-2.0469-0.3906-2.8281-1.1714c-1.5625-1.5625-1.5625-4.0952,0-5.6572  l14.1426-14.1421c1.5605-1.5615,4.0938-1.5615,5.6562,0c1.5625,1.5625,1.5625,4.0952,0,5.6572l-14.1426,14.1421  C190.4332,73.7306,189.4088,74.1212,188.3854,74.1212z"/>
    <path fill="#FF5D5D"
          d="M202.528,74.1212c-1.0234,0-2.0469-0.3906-2.8281-1.1714l-14.1426-14.1421  c-1.5625-1.562-1.5625-4.0947,0-5.6572c1.5605-1.5615,4.0938-1.5615,5.6562,0l14.1426,14.1421c1.5625,1.562,1.5625,4.0947,0,5.6572  C204.5758,73.7306,203.5514,74.1212,202.528,74.1212z"/>
    <path fill="#00D40B"
          d="M14.3854,66.1212c-7.7197,0-14-6.2803-14-14s6.2803-14,14-14s14,6.2803,14,14  S22.1051,66.1212,14.3854,66.1212z M14.3854,46.1212c-3.3086,0-6,2.6914-6,6s2.6914,6,6,6s6-2.6914,6-6  S17.694,46.1212,14.3854,46.1212z"/>
    <path fill="#FFC504"
          d="M203.6989,216.7487c-1.0605,0-2.0781-0.4214-2.8281-1.1719l-11.3135-11.314  c-1.5625-1.562-1.5625-4.0942,0-5.6567l11.3135-11.3135c1.5605-1.5615,4.0957-1.5615,5.6562,0l11.3135,11.3135  c1.5625,1.5625,1.5625,4.0947,0,5.6567l-11.3135,11.314C205.777,216.3273,204.7594,216.7487,203.6989,216.7487z M198.0426,201.4347  l5.6562,5.6572l5.6562-5.6572l-5.6562-5.6567L198.0426,201.4347z"/>
</svg>
</template>

<script>
export default {
  name: "svgWeatherIcon"
}
</script>

<style scoped>

</style>
