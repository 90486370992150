<template>
  <div class="fuel-box-wrapper">
    <template v-if="current_resources && current_resources[170] && current_resources[170].enabled ===true">
      <div class="fuel-calendar-wrapper">
        <button type="button" class="btn btn-default btn-close-calendar"
                slot="reference"
                @click="closeShowCalendar()"  v-if="showCalendar">

                    <i class="fa fa-times"></i>
        </button>

          <button type="button" class="history_object_calendar btn btn-default calendar-button  bottom" :class="showCalendar ?'active':'disactive'"
                  slot="reference"
                  @click="closeShowCalendar()"  v-if="!showCalendar">

                     <span class="far calendar-icon">
              <svg-calendar-time-icon></svg-calendar-time-icon>

          </span>
          </button>
          <template v-if="showCalendar ">
          <date-picker
            :select_type="select_type"
            :language="lang_short"
            apply-button-label="use"
            :show-helper-buttons="true"
            :switch-button-initial="true"
            :is-monday-first="true"
            :initial-dates="getInitinalDate()"
            :inline="inline_style"
            :show_short="show_short"
            :disabledDates="disabledDates"
          />
          </template>
      </div>
      <button type="button" class="btn fuel-chart-show-btn" @click="showChart()" v-if="table_show"><svg-chart-bar/></button>
      <button type="button" class="btn fuel-chart-show-btn" @click="showSettingsMap()" v-if="table_show && !setting_show" ><svg-setting-mono/></button>
    </template>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DatePicker from "@/components/vue3calendar/calendar/DatePicker";
import SvgCalendarTimeIcon from "@/components/ui/icons/svgCalendarTimeIcon";
import calendarStart from "@/mixins/calendarStart";
import SvgChartBar from "@/components/ui/icons/svgChartBar";
import SvgSettingMono from "@/components/ui/icons/svgSettingMono";
export default {
  name: "FuelComponent",
  components: {
    SvgSettingMono,
    SvgChartBar,
    SvgCalendarTimeIcon,
    DatePicker,
  },
  mixins:[calendarStart],

  data() {
    return {
      disabledDates:{
        from: new Date(Date.now() + 8640000)
      },
      fuel_type:'fuel_2',
      ps_style:'height: 300px;',
      start_row:10,
      index: 0,
      select_type:'fuel',
      inline_style: true,
      show_short: true,
      base_url:base_url,
      last_zoom:'',
      last_start_center:'',
      resize_count:0,
    }
  },
  computed: {
    ...mapGetters({
      lang_short: 'LANG_SHORT',
      table_show:'HISTORY_TABLE_SHOW',
      showCalendar:'SHOW_CALENDAR_PANEL',
      chart_data: 'FUEL_CHART_DATA',
      calendar_date:'CALENDAR_DATES',
      setting_show:'SETTINGS_SHOW',
      show_graph:'SHOW_GRAPH',
      settings_fuel_active: 'SETTINGS_FUEL_ACTIVE',
      active_route: "ACTIVE_ROUTE",
      select_fuel_data: 'SELECT_FUEL_DATA',
      current_resources:'CURRENT_RESOURCES',
    })
  },
  mounted() {
    this.$store.commit('SET_ACTIVE_ROUTE', 'fuel2');
    if(this.chart_data[this.settings_fuel_active] ){
      this.$store.commit('SET_HISTORY_TABLE_SHOW', true);
     // this.$store.commit('SET_SHOW_GRAPH',  true)
    //  this.$store.commit('SET_SHOW_REPORTS_SETTINGS', true);
    }else
    {
      this.$store.commit('SET_SHOW_REPORTS_SETTINGS', false);
      this.$store.commit('SET_SHOW_GRAPH', false)

    }
    this.$store.commit('SET_SHOW_OBJECT_CONTENT', true);
   // let dates = this.getInitinalDate()
   // this.$store.commit('SET_CALENDAR_DATES', dates)
    let intElemOffsetHeight = document.getElementById('main_wrapper').offsetHeight

     intElemOffsetHeight = intElemOffsetHeight-100
    this.start_row = intElemOffsetHeight/10
   let ps_add = this.start_row*5
    this.ps_style='height: '+ps_add+'px;'

    let $this = this
    this.$store.dispatch('getObjectGroups');
    this.$nextTick(function () {
      $this.resizedMap()
    })
  },
  methods:{
    showSettingsMap(){
      let add =true
    if( this.setting_show === true){
      add =false
    }
      this.$store.commit('SET_SETTINGS_SHOW',  add)
    },
    showChart(){

      let add =false
      if( this.show_graph === false){
        add =true
      }
      let $this = this

      this.$store.commit('SET_SHOW_GRAPH',  add)

      setTimeout(function () {
        $this.resizedMap()
      },300)
    },
    resizedMap(){
      this.resize_count++
      this.$store.commit('SET_RELOAD_MAP',  this.resize_count)
    },
    resizedTableEvent(e){
      //console.log(e)
    },

    closeShowCalendar(){
      let change = false
      if(this.showCalendar === false){
        change = true
      }
        this.$store.commit('SET_SHOW_CALENDAR_PANEL', change);
    }
  },
  beforeDestroy: function () {
    this.$store.commit('SET_SHOW_ALL_OBJECTS', true)
  //  this.$store.commit('SET_MAP_ZOOM', this.last_zoom)
   // this.$store.commit('SET_START_CENTER', this.last_start_center)
  },
}
</script>

<style scoped>

</style>
