<template>
<span>{{text}}</span>
</template>

<script>
export default {
  name: "TranslateComponent",
  props:{
    text: {
      default: ''
    }
  }
}
</script>
