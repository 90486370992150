<template>
<div style="display: none;">
<slot v-if="ready"></slot>
</div>
</template>

<script>
import L from 'leaflet'
import 'leaflet-polylinedecorator'
import { findRealParent, propsBinder } from 'vue2-leaflet'
const props = {
  pixelSize: {
    type:   Number,
    default: 10
  },
  paths: {
    type: Array,
    default: () => []
  },
  visible: {
    type: Boolean,
    custom: true,
    default: true
  },
  arrow_colorpicker:{
    type: String,
    default: '#F5F5F5'
  }
};
export default {
  name: 'LeafletPolylinedecorator',
  props,
  data() {
    return {
      ready: false,
     patterns:[
        { offset: 12, repeat: 100,
          symbol: L.Symbol.arrowHead(
            {pixelSize: this.pixelSize,
            pathOptions: {color: this.arrow_colorpicker, weight: 2}
            })
        },

      ]
    }
  },
  mounted() {
    const polyline = L.polyline(this.paths);
    const options = { patterns: this.patterns };
    this.mapObject = L.polylineDecorator(polyline, options);
    L.DomEvent.on(this.mapObject, this.$listeners);
    propsBinder(this, this.mapObject, props);
    this.ready = true;
    this.parentContainer = findRealParent(this.$parent);
    this.parentContainer.addLayer(this, !this.visible);
  },
  beforeDestroy() {
    this.parentContainer.removeLayer(this);
  },
  methods: {
    setVisible(newVal, oldVal) {
      if (newVal == oldVal) return;
      if (newVal) {
        this.parentContainer.addLayer(this);
      } else {
        this.parentContainer.removeLayer(this);
      }
    },
    addLatLng(value) {
      this.mapObject.addLatLng(value);
    }
  }
};
</script>
