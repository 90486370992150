<template>
  <div>
    <div class="col-md-6">

      <div class="form-group fuel-group">
        <label>{{ lang.main_component.fuelcontrolSettings }}</label>
        <select class="form-control fuel-number" v-model="fuel_params.SensorType">
            <option value="0">{{ lang.main_component.SensorType_0 }}</option>
          <option value="1">{{ lang.main_component.SensorType_1 }}</option>
          <option value="2">{{ lang.main_component.SensorType_2 }}</option>
          <option value="3">{{ lang.main_component.SensorType_3 }}</option>
          <option value="4">{{ lang.main_component.SensorType_4 }}</option>
          <option value="50">{{ lang.main_component.SensorType_5 }}</option>
        </select>
      </div>


      <div class="form-group fuel-group" v-for="item in row_add1">
        <label>{{ lang.main_component[item] }}</label>
        <input type="number" class="form-control fuel-number" v-model="fuel_params[item]">
      </div>

    </div>
    <div class="col-md-6">
      <div class="form-group fuel-group" >
       <p style="height: 20px;"></p>
        <div class="form-check">

          <input class="form-check-input" type="checkbox" v-model="fuel_params.fuelCache" id="chek_fuelCache">
          <label  class="form-check-label" for="chek_fuelCache" ><span style="padding-left: 10px">{{ lang.main_component.fuelCache }}</span> </label>
        </div>
      </div>
      <div class="form-group fuel-group" v-for="item in row_add2">

        <label>{{ lang.main_component[item] }}</label>
        <input type="number" class="form-control fuel-number" v-model="fuel_params[item]">
      </div>


    </div>
    <div class="col-md-12">
      <div class="form-group fuel-group">
        <button type="button" class="btn btn-success" @click="SaveFuelSettings()">{{ lang.main_component.save }}</button>

      </div>
    </div>

  </div>
</template>


<script>
import {mapGetters} from "vuex";
import axios from "axios";
import TranslateComponent from "@/components/dashboard/TranslateComponent";

export default {
  name: "FuelSettingsComponent",
  components: {TranslateComponent},
  props:{
    object_number: String,
    object_id: Number | String,
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      current_object:'CURRENT_OBJECT',
    }),
  },
  data() {
    return {
      loading_page:false,
      base_url: base_url,
      row_add1:[
        'ff_min_speed',
        'ff_min_stop_duration',
        'ff_drop_first_minutes',
        'ff_drop_last_minutes',
        'ff_min_fuel_value',
        'ff_min',
        'ff_min_group_minutes',

      ],

      row_add2:[
        'fd_min_speed',
        'fd_min_stop_duration',
        'fd_drop_first_minutes',
        'fd_drop_last_minutes',
        'fd_min_fuel_value',
        'fd_min',
        'fd_min_group_minutes',
      ],
      fuel_params:{
        SensorType: 0,
        fuelCache: 0,
        fd_drop_first_minutes: 0,
        fd_drop_last_minutes: 0,
        fd_min: 0,
        fd_min_fuel_value: 0,
        fd_min_group_minutes: 0,
        fd_min_speed: 0,
        fd_min_stop_duration: 0,
        ff_drop_first_minutes: 0,
        ff_drop_last_minutes: 0,
        ff_min: 0,
        ff_min_fuel_value: 0,
        ff_min_group_minutes: 0,
        ff_min_speed: 0,
        ff_min_stop_duration: 0,
      },
    }
  },
  mounted() {
    this.refreshFuelConfig()
  },
  methods:{
    SaveFuelSettings(){

      let _this = this
      let formData = new FormData()

      Object.entries(_this.fuel_params).forEach(([key, val]) => {

        formData.append("config["+_this.object_id+"]["+key+"]", val)
      });



      _this.loading_page =false


      axios.post(base_url + '/fuel/setconfig/',formData).then(response => {

        let datas = response.data
        if(datas.success === true){
          _this.loading_page =true
        }else{
          $this.$iosAlert('Something went wrong. Response not success.');
          _this.loading_page =true
        }

      }).catch(error => {
        console.log(error)
        _this.loading_page =true
      })


    },
    refreshFuelConfig() {

      let _this = this
      let formData = new FormData()
      _this.loading_page =false
      formData.append("ObjectIds[]", _this.object_id)
      axios.post(base_url + '/fuel/getconfig/',formData).then(response => {

       let datas = response.data
          if(datas.success === true){

            _this.fuel_params = datas.data[_this.object_id]
            _this.loading_page =true
        }else{
            $this.$iosAlert('Something went wrong. Response not success.');
            _this.loading_page =true
          }

      }).catch(error => {
        console.log(error)
        _this.loading_page =true
      })

    }
  }
}
</script>

<style scoped>

.form-group.fuel-group {
  margin-bottom: 10px;
}

.form-group.fuel-group .fuel-number {
  width: 80%;
  height: 28px;
  padding: 2px 12px;
}

</style>