<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" >
    <path d="M9.5 9.5L9.5 13.5179C9.74942 13.482 10.0057 13.4999 10.25 13.5725V12.75C10.25 11.7835 11.0335 11 12 11C12.9665 11 13.75 11.7835 13.75 12.75V13.5725C13.9943 13.4999 14.2506 13.482 14.5 13.5179L14.5 9.5L9.5 9.5Z" />
    <path d="M8 9.5L8 14.5H3.75C3.55109 14.5 3.36032 14.421 3.21967 14.2803C3.07902 14.1397 3 13.9489 3 13.75V9.5L8 9.5Z" />
    <path d="M9.5 8L14.5 8L14.5 3H9.5L9.5 8Z" />
    <path d="M16 9.5L16 14.5L20.25 14.5C20.6642 14.5 21 14.1642 21 13.75L21 9.5L16 9.5Z" />
    <path d="M21 8L16 8L16 3L17.75 3C19.5449 3 21 4.45508 21 6.25V8Z" />
    <path d="M8 8L3 8V6.25C3 4.45507 4.45508 3 6.25 3H8L8 8Z" />
    <path d="M3.75 19.5C3.33579 19.5 3 19.8358 3 20.25C3 20.6642 3.33579 21 3.75 21L20.25 21C20.6642 21 21 20.6642 21 20.25C21 19.8358 20.6642 19.5 20.25 19.5L3.75 19.5Z" />
    <path d="M9.18945 14.7517C9.46464 14.4421 9.93869 14.4143 10.2483 14.6894L11.25 15.5799V12.75C11.25 12.3358 11.5858 12 12 12C12.4142 12 12.75 12.3358 12.75 12.75V15.5799L13.7517 14.6894C14.0613 14.4143 14.5354 14.4421 14.8106 14.7517C15.0858 15.0613 15.0579 15.5354 14.7483 15.8106L12.4983 17.8106C12.2141 18.0631 11.7859 18.0631 11.5017 17.8106L9.25174 15.8106C8.94215 15.5354 8.91426 15.0613 9.18945 14.7517Z" />
  </svg>
</template>

<script>
export default {
  name: "svgMoveDownTableIcon"
}
</script>

<style scoped>

</style>
