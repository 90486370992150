<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 496 496" style="enable-background:new 0 0 496 496;" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M248,0C111.248,0,0,111.256,0,248v8h128.424c0.96,14.488,4.352,28.52,10.448,41.808l-126.6,126.6     C4.36,432.336,0,442.864,0,454.056C0,477.184,18.816,496,41.936,496c11.208,0,21.744-4.36,29.656-12.288L198.184,357.12     C213.912,364.344,230.64,368,248,368c63.472,0,115.448-49.56,119.592-112H496v-8C496,111.256,384.752,0,248,0z M248,352     c-16.736,0-32.8-3.928-47.752-11.672l-5.192-2.696L60.272,472.408c-4.888,4.896-11.4,7.592-18.336,7.592     C27.632,480,16,468.36,16,454.056c0-6.928,2.696-13.44,7.592-18.344l134.776-134.776l-2.696-5.192     c-6.496-12.544-10.136-25.896-11.2-39.744h32C180.464,291.944,211,320,248,320s67.536-28.056,71.528-64h32.064     C347.48,309.608,302.64,352,248,352z M278.864,240h-61.728c3.576-13.76,15.992-24,30.864-24S275.288,226.24,278.864,240z      M271.36,206.104l86.496-67.96l-67.96,86.496C285.552,216.88,279.12,210.448,271.36,206.104z M303.36,256     c-3.904,27.096-27.208,48-55.36,48s-51.456-20.904-55.36-48H303.36z M298.168,240L378.8,137.384     c4.488-5.72,3.992-13.904-1.152-19.04c-5.144-5.144-13.32-5.648-19.048-1.136l-105.696,83.04     c-1.608-0.16-3.248-0.248-4.904-0.248c-23.736,0-43.448,17.336-47.28,40H16.136C20.368,115.768,122.752,16,248,16     s227.632,99.768,231.864,224H298.168z"/>
      <rect x="240" y="32" width="16" height="16"/>
      <rect x="160.403" y="47.845" transform="matrix(-0.3824 -0.924 0.924 -0.3824 181.1985 232.8046)" width="16" height="16"/>
      <rect x="92.915" y="92.92" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 100.9109 243.6386)" width="16" height="16"/>
      <rect x="47.825" y="160.406" transform="matrix(-0.9238 -0.3828 0.3828 -0.9238 42.9309 345.3544)" width="16" height="16"/>
      <rect x="432.144" y="160.363" transform="matrix(0.3828 0.9238 -0.9238 0.3828 427.1898 -302.7053)" width="16" height="16"/>
      <rect x="387.047" y="92.948" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 603.0065 451.669)" width="16" height="16"/>
      <rect x="319.602" y="47.827" transform="matrix(-0.924 -0.3824 0.3824 -0.924 608.9575 232.6857)" width="16" height="16"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgServerIcon"
}
</script>
