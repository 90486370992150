<script>
import {Line} from 'vue-chartjs'

export default {
  extends: Line,
  name: 'lineChart',
  props: {

    headers_units: {
      type: Array
    },
    chart_max: {
      type: Array
    },
    chart_min: {
      type: Array
    },
    chartdata: {
      type: Array
    },
    add_labels: {
      type: Array
    },
    label_name: {
      type: Array
    }
    ,
    pointBackgroundColor: {
      type: String,
    },
    borderColor: {
      type: Array
    },
    title: {
      type: String
    },
    chart_type_num: {
      type: Number
    }
  },
  data() {
    return {
      alpha: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      dataset: [],
      yAxes: [],
    }
  },
  mounted() {


    let $this = this
    $this.yAxes =[]
    $this.dataset = []
    let k = 0;
    if (this.chart_type_num === 1) {
      $this.dataset.push(
        {
          label: $this.label_name[1],
          data: $this.chartdata[1],
          backgroundColor: "transparent",
          borderColor: $this.borderColor[1].hex,
          pointBackgroundColor: $this.pointBackgroundColor,
          pointRadius: 0,
        }
      )

    } else {

      this.add_labels.map(item => {

        if (k > 1) {
          if (Array.isArray($this.chartdata[k]) && $this.chartdata[k].length > 0) {
            if($this.chart_max[k] !== "undefined" && $this.chart_max[k] != null) {
              $this.dataset.push(
                {
                  label: $this.label_name[k],
                  yAxisID: this.alpha[k - 2],
                  data: $this.chartdata[k],
                  backgroundColor: "transparent",
                  borderColor: $this.borderColor[k].hex,
                  pointRadius: 0,
                  pointBackgroundColor: $this.pointBackgroundColor
                }
              )
            }
            if($this.chart_max[k] !== "undefined" && $this.chart_max[k] != null) {
              let add = $this.headers_units[k]
              $this.yAxes.push({
                display: true,
                id: $this.alpha[k - 2],
                type: 'linear',
                position: 'left',
                ticks: {
                  min: $this.chart_max[k],
                  max: $this.chart_min[k],
                  maxTicksLimit: 10,
                  stepSize: $this.chart_max[k]/10,
                  check_val:$this.headers_units[k],
                  fontColor: $this.borderColor[k].hex,
                  callback: function (value, index, values) {
                    return value +''+ add;
                  }
                }
              })

            }
          }

        }
        k++;
      })
    }
    if(this.chart_type_num !== 1) {
      this.renderChart(
        {
          labels: this.add_labels,
          datasets: this.dataset
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.title
          },
          scales: {
            yAxes: this.yAxes,
            xAxes: [{
              ticks: {
                maxTicksLimit: 10,
                maxRotation: 0,
                minRotation: 0
              },
              maxBarThickness: 100,
              borderWidth: 0,
            }]
          }
        }
      );
    }else{
      this.renderChart(
        {
          labels: this.add_labels,
          datasets: this.dataset
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: this.title
          },
          scales: {
            xAxes: [{
              ticks: {
                maxTicksLimit: 10,
                maxRotation: 0,
                minRotation: 0
              },
              maxBarThickness: 100,
              borderWidth: 0,
            }]
          },
        }
      );
    }
  }
}
</script>

<style>
</style>
