<template>
  <section class="panel global-settings-panel" >
    <template v-if="current_resources && current_resources[190] && current_resources[190].enabled ===true">
    <header class="panel-heading vehicle-head  task-head" :class="search_hide ? 'scroll-head-hide': ''">

      <h2 class="panel-title">

        <span class="vehicle-number" ><span class="svg-box-set"><svg-group-icon/></span><translate-component :text="lang.group_component.title2"/></span>

      </h2>


    </header>

    <div v-if="!redy_loading" class="global-settings-table">

      <div class="event-table-list-group ">
        <div class="event-table-list-box">
          <div class="group-column-box">
            <div class="group-column-head"><h4>{{lang.group_list_component.group_list}}</h4></div>
            <div class="group-column-form">
            <perfect-scrollbar  class="group-scroll-p">
            <template v-for="(group, index) in group_list" v-if="group_list.length>0">
              <div class="radio">
                <label><input type="radio" v-model="group_active" :value="group.groupId" @change="GroupAddedHandle()">{{group.groupName}} <span class="label label-primary group-private-badge" v-if="group.private === true">{{lang.group_component.private}}</span></label>
              </div>
            </template>
            <template v-else>
              <div class="radio">
                <label>{{lang.group_list_component.group_empty}}</label>
              </div>
            </template>
            </perfect-scrollbar>
            </div>
            <div class="btn-row-box2"  v-if="role <1">
              <button type="button" class="btn btn-success btn-xs"  @click="CreateGroup()" >{{lang.group_list_component.group_create_btn}}</button>
              <button type="button" class="btn btn-success btn-xs"  @click="EditGroup()" >{{lang.events_component.edit}}</button>
              <button type="button" class="btn btn-success btn-xs"  @click="DeleteGroup()" >{{lang.events_component.delete}}</button>
            </div>
          </div>
        </div>
        <div class="event-table-list-box">
          <div class="group-column-box">
            <div class="group-column-head"><h4>{{lang.group_component.object_group}}<span class="selectgroup-row-title">{{current_group}}</span></h4></div>
            <div class="group-column-form">
              <perfect-scrollbar  class="group-scroll-p">
            <template v-for="(item, index) in current_group_list">
              <div class="checkbox">
              <label><input type="checkbox"  v-model="item.selected" :value="item.ObjectId">{{item.clientName == 'TomWare-Latvia' ? 'Demo customer' : item.clientName}} : {{item.number}}</label>
              </div>
            </template>
              </perfect-scrollbar>
            </div>
            <div class="btn-row-box2"  v-if="role <1">
            <button type="button" class="btn btn-success btn-xs"  @click="DeleteSelectedObjects()" >{{lang.user_component.delete_selected}}</button>
            <button type="button" class="btn btn-success btn-xs" @click="DeleteAllObjects()" >{{lang.user_component.delete_all}}</button>
            </div>
        </div>
        </div>
        <div class="event-table-list-box">
          <div class="group-column-box">
            <div class="group-column-head"><h4>{{lang.group_component.available_object}}</h4></div>
            <div class="group-column-form">
              <perfect-scrollbar  class="group-scroll-p">
            <template v-for="(object, index) in objects">
              <div class="checkbox">
                <label><input type="checkbox"  v-model="remove_group" :value="object.ObjectId">{{object.Name}} : {{object.Number}}</label>
              </div>
            </template>

              </perfect-scrollbar>
            </div>
            <div class="btn-row-box2" v-if="role <1">
              <button type="button" class="btn btn-success btn-xs"  @click="AddSelectedObjects()" >{{lang.user_component.add_selected}}</button>
              <button type="button" class="btn btn-success btn-xs" @click="AddAllObjects()">{{lang.user_component.add_all}}</button>

            </div>
        </div>
        </div>
      </div>

    </div>
    <div v-else>
      <div class="loading-box" >
        <scale-loader :height="loading_size"></scale-loader>
      </div>
    </div>
    <div  class="modal fade group-modal" role="dialog" style="display: block;opacity: 1" v-if="create_modal">
      <div class="modal-dialog tasks-modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close"  @click="CreateGroupClose()">&times;</button>
            <h4 class="modal-title">
              <template v-if="current_type === 1">{{lang.group_component.create_group}}</template><template v-if="current_type === 2">{{lang.group_component.edit_group}}</template>
            </h4>


          </div>

          <div class="modal-body">
            <div class="row">
              <div class="form-group">
                <label><translate-component :text="lang.group_component.group_name"/><span class="require-field"> *</span></label>
                <input class="form-control" v-model ="new_group.groupName">
              </div>


              <div class="form-group">
                <div class="checkbox">
                  <label><input type="checkbox" v-model="new_group.private">{{lang.group_component.private}}</label>
                </div>
              </div>

            </div>

          </div>

          <div class="modal-footer" >
            <template v-if="current_type === 1"> <button type="button" class="btn btn-sucess" @click="beforeSaveGroup()">{{lang.main_component.save}}</button></template>
            <template v-if="current_type === 2"><button type="button" class="btn btn-sucess" @click="beforeUpdateGroup()">{{lang.main_component.save}}</button></template>
            <button type="button" class="btn btn-default" @click="CreateGroupClose()">{{lang.task_component.close_btn}}</button>
          </div>
        </div>
      </div>
    </div>
    </template>
  </section>
</template>

<script>
import SvgGroupIcon from "@/components/ui/icons/svgGroupIcon";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import {mapGetters} from "vuex";
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader";
export default {
  name: "GroupComponent",
  components: {TranslateComponent, SvgGroupIcon, ScaleLoader},
  computed: {
    ...mapGetters({
      lang: 'LANG',
      objects: 'OBJECTS',
      current_resources:'CURRENT_RESOURCES',
    }),
  },
  data() {
    return {
      valid_message:'',
      check_valid:0,
      role:1,
      current_type:1,
      search_hide:false,
      loading_size: "30px",
      show_icon:true,
      redy_icons:false,
      redy_loading:false,
      group_list:[],
      remove_group:[],
      current_group:'',
      current_group_list:[],
      group_active:null,
      selected_group:[],
      create_modal:false,
      new_group:{
        groupName:'',
        private:false,
        groupId:null,
      },
    }
    },
  mounted() {
    this.getMyRole()
    this.getAllTasks()
  },
  methods: {
    getMyRole(){
      let _this =this;
      axios.post(base_url + '/problem/clienthistory').then(response => {

        if (response.data.success === true) {
          if(response.data.role != null) {
            _this.role = response.data.role * 1
          }
        }
      }).catch(error => {
        console.log(error)
      })

    },
    CreateGroupClose(){
      this.new_group = {
        groupName:'',
        private:false,
        groupId:null,
      }
      this.create_modal = false;
    },
    EditGroup(){
      if(this.group_active != null && this.group_active != 0) {
        this.current_type = 2;

        let id = this.group_active;
        let item = this.group_list.find(x => x.groupId === id);
        this.new_group = {
          groupName: item.groupName,
          private: item.private,
          groupId: item.groupId,
        }
        this.create_modal = true;
      }else{
        let message =  this.lang.group_component.select_group
        this.$iosAlert(message);
      }
    },
    beforeUpdateGroup(){
      if(this.new_group.groupName.length === 0){

        let message =  this.lang.group_component.valid_name
        this.$iosAlert(message);
      }else{
        this.saveGroup();
      }
    },
    beforeSaveGroup(){
      if(this.new_group.groupName.length === 0){

       let message =  this.lang.group_component.valid_name
        this.$iosAlert(message);
      }else{
        this.saveGroup();
      }

    },

    saveGroup(){
      let _this =this;
      let url = '/objectgroup/create'
      let formData = new FormData()
      formData.append("groupName", _this.new_group.groupName);
      formData.append("private", _this.new_group.private);
      if(  this.current_type === 2){
        formData.append("groupId", _this.new_group.groupId);
         url = '/objectgroup/update'
      }
      axios.post(base_url +  url, formData).then(response => {

        if( response.data && response.data.success === true){

          if( _this.current_type === 1){
            let element = {'groupId': response.data.groupId,'groupName': _this.new_group.groupName,'private': _this.new_group.private,'icon': ''}
          _this.$set(_this.group_list, _this.group_list.length,   element);
          }else{
            let id = _this.group_active;
            let element = {'groupId': _this.new_group.groupId,'groupName': _this.new_group.groupName,'private': _this.new_group.private,'icon': ''}
            let itemIndex = _this.group_list.findIndex(x => x.groupId === id);
            _this.$set(_this.group_list, itemIndex,   element);
          }
          _this.CreateGroupClose()

        }else{
          _this.$iosAlert(response.data.error);
        }


      }).catch(error => {
        console.log(error)
      })
    },
    CreateGroup(){
      this.current_type = 1;
      this.create_modal = true;
    },
    GroupAddedHandle(){
      let _this =this;

      let item =this.group_list.find(x => x.groupId === _this.group_active);
      this.current_group  = item.groupName
      if(this.group_active != null && this.group_active != '' ){
        this.getGroupList(this.group_active)
      }
    },
    getGroupList(group_active){
      let _this =this;
      let formData = new FormData()
      formData.append("groupId", group_active);
      axios.post(base_url + '/objectgroup/objectlist', formData).then(response => {
        _this.current_group_list = [];
        if( response.data && response.data.success === true && response.data.data){
          let cur = response.data.data;
          cur.forEach(v => {v.selected =  false})
          _this.current_group_list =  cur;

        }
        _this.redy_loading = false;
      }).catch(error => {
        console.log(error)
      })

    },
    DeleteGroup(){
      let _this =this;
      let message = this.lang.group_component.confirm_delete+' '+this.current_group
      this.$iosConfirm({
        text: message
      }).then(function () {
      let formData = new FormData()
      formData.append("groupId", _this.group_active);
      axios.post(base_url + '/objectgroup/delete', formData).then(response => {
        if( response.data && response.data.success === true ) {
          let itemIndex =_this.group_list.findIndex(x => x.groupId === _this.group_active);
          _this.$delete(_this.group_list, itemIndex)
          _this.group_active = null;

        }else{
          _this.$iosAlert(response.data.error);
        }
      }).catch(error => {
        console.log(error)
      })
      })
    },
    getAllTasks(){
      let _this =this;
      _this.redy_loading = true;

      let formData = new FormData()
      formData.append("manage", true);

      axios.post(base_url + '/objectgroup/list', formData).then(response => {

        if( response.data && response.data.success === true && response.data.data){
          _this.group_list =  response.data.data;

        }

        _this.redy_loading = false;
      }).catch(error => {
        console.log(error)
      })
    },
    DeleteSelectedObjects(){
      this.check_valid =0;
      let add_objs = [...this.current_group_list]
      for (let i = 0; i < add_objs.length; i++) {
        if(add_objs[i].selected === true){
          let id = add_objs[i].objectId
          this.delete0bject(id)

        }

      }
      this.messageAlert();
    },
    AddSelectedObjects(){
      let remove_group  = [...this.remove_group]
      this.check_valid =0;
      for (let i = 0; i <  remove_group.length; i++) {
        let id =  remove_group[i]
        let itemIndex =this.current_group_list.findIndex(x => x.objectId === id);
        let item = this.objects.find(x => x.ObjectId === id);
        if(itemIndex == null || itemIndex == -1 || itemIndex == 0  ){
          this.saveAddObject(id,item)
        }

      }
      this.messageAlert();
      this.remove_group =[];
    },
    messageAlert(){
      let _this =this;
      setTimeout(function(){
        if(_this.check_valid > 0 ){
          _this.$iosAlert( _this.valid_message);
        }

      }, 1000)
    },
    AddAllObjects(){
      this.check_valid =0;
      let add_objs = [...this.objects]
      for (let i = 0; i < add_objs.length; i++) {
        let id = add_objs[i].ObjectId
        let item = add_objs[i]
        let itemIndex =this.current_group_list.findIndex(x => x.ObjectId === id);
        if(itemIndex == null || itemIndex == -1 || itemIndex == 0  ){
          this.saveAddObject(id,item)
        }
      }
      this.messageAlert();
      this.remove_group =[];
    },
    DeleteAllObjects(){
      this.check_valid =0;
      let add_objs = [...this.current_group_list]
      for (let i = 0; i < add_objs.length; i++) {
        let id = add_objs[i].objectId
        this.delete0bject(id)
      }
      this.messageAlert();
    },
    delete0bject(id){
      let _this =this;
      let formData = new FormData()
      formData.append("groupId", _this.group_active);
      formData.append("objectId",id);
      axios.post(base_url + '/objectgroup/removeobject', formData).then(response => {
        if( response.data.success === true){
          let cur_index = _this.current_group_list.findIndex(x => x.objectId === id);
            _this.$delete(_this.current_group_list, cur_index)

        }else{
          _this.check_valid++;
          _this.valid_message =response.data.error;
        }

      }).catch(error => {
        console.log(error)
      })
    },
    saveAddObject(id,item){

      let _this =this;
      let formData = new FormData()
      formData.append("groupId", _this.group_active);
      formData.append("objectId",id);
      axios.post(base_url + '/objectgroup/addobject', formData).then(response => {
        if( response.data.success === true){
          let last_index = _this.current_group_list.length;
          let elem = {
            clientName:item.Name,
            number:item.Number,
            objectId:item.ObjectId,
            selected:false,
            vehicleNumber:item.Number,
          }
          _this.$set(  _this.current_group_list, last_index, elem)


        }else{
          _this.check_valid++;
          _this.valid_message =response.data.error;
        }

      }).catch(error => {
        console.log(error)
      })
    },

  }


}
</script>

<style scoped>


.event-table-list-group{
  max-width: 1100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.event-table-list-box{
  width: 33%;
}
.group-column-box{
  border-radius: 10px;
  padding: 10px 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.group-column-form{
  height: 400px;
}
.group-scroll-p.ps{

}
.btn-row-box2 .btn {
  min-width: 90px;
  margin-right: 10px;
  margin-bottom: 10px;
}


.btn-row-box2{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

}
.group-scroll-p.ps {
  height: 380px;

}
.modal-dialog.tasks-modal-dialog{
  margin-top: 20%;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.selectgroup-row-title{
  padding-left: 10px;
}
@media only screen and (max-width: 1600px){
  .panel.global-settings-panel{
    width: calc(100vw - 50px );
    height: calc(100vh - 42px );

  }
  .vehicle-head.task-head{
    flex-wrap: wrap;
  }
  .panel-title{
    width: 100%;
    padding-bottom: 10px;
  }

}@media only screen and (max-width: 950px){
  .event-table-list-box {
    width: 100%;
    padding-bottom: 10px;
  }
  .panel.global-settings-panel {
    overflow-y: auto;
  }
  .group-column-form {
    height: 300px;
  }
  .group-scroll-p.ps {
    height: 280px;
  }
  .event-table-list-group{
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767px){
  .panel.global-settings-panel{
    width: 100%;
    height: calc(100vh - 42px );
    top: 60px;
    position: fixed;
  }
  .global-settings-table{
     margin-right: 0;
  }
  .modal-dialog.tasks-modal-dialog {
    margin-top: 45%;
  }
}




</style>