<template>
  <section class="object-info-box" :class="show_content? '' : 'hide-object-wrapper'">
    <div class="card object-info">
      <div class="widgets-panel__header">
        <div class="widgets-panel__tracker-title settin-title-box">
          
          <translate-component :text="lang.reports_settings_component.Advanced_settings"></translate-component>
          <button type="button" class="btn object-info-close" @click="closeModal()"><span class=" fa fa-times"></span>
          </button>


        </div>
        <div class="street-view widgets-panel__street-view">
          <div class="street-view__streetview" :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">

          </div>


          <div class="street-view__click-stub">
            <span aria-hidden="true" class="fa touch-icon">

          </span>

          </div>
        </div>
      </div>
        <div class="card-body" v-if="reports_settings_options.reports_minStopTime">
          <div class="route-setting_box">
            <ul class="route-setting_list">
              <li>
                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.reports_settings_component.main_settings"/>
                  </div>
                  <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                          :class="page===1 ? 'active' : ''" @click="changePage(1)">
                    <svg-path-icon/>
                  </button>
                </popper>
              </li>
              <li v-if="report_type === 'full'">
                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.reports_settings_component.full_report_settings"/>
                  </div>
                  <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                          :class="page===2 ? 'active' : ''" @click="changePage(2)">
                    <svg-roue-view-icon/>
                  </button>
                </popper>
              </li>
            </ul>
            <div style="display: none;">{{ help_counter }}</div>
            <perfect-scrollbar :options="{'suppressScrollX' :suppressScrollX,'suppressScrollY' : false}" >
            <transition-group name="testanim" tag="div">
              <div class="route-setting_item" key="1" v-if="page===1">
                <div class="left-pane-padding">



                  <div class="route-setting_input-box">
                    <translate-component :text="lang.reports_settings_component.min_stop_time"/>
                    :<br/>
                    <select id="minStopTime" name="minStopTime"
                            @change="getSelect($event,'reports_minStopTime','reports_minStopTime')"
                            :value="reports_settings_options.reports_minStopTime">
                      <option value="0">
                        <translate-component :text="lang.reports_settings_component.all"/>
                      </option>
                      <template v-for="(step) in metres_arr">
                        <option :value="step">
                          <translate-component :text="lang.reports_settings_component.greater"/>
                          {{ step }}
                          <translate-component :text="lang.reports_settings_component.minutes"/>
                        </option>
                      </template>
                    </select>
                  </div>


                  <div class="route-setting_input-box">
                    <translate-component :text="lang.reports_settings_component.allowable"/>
                    :<br/>
                    <select id="maxStopDistance" name="maxStopDistance"
                            @change="getSelect($event,'reports_maxStopDistance','reports_maxStopDistance')"
                            :value="reports_settings_options.reports_maxStopDistance">
                      <template v-for="(step) in allowable_arr">
                        <option :value="step">
                          {{ step }}
                          <translate-component :text="lang.reports_settings_component.meters"/>
                        </option>
                      </template>
                    </select>
                  </div>



                  <div class="route-setting_input-box">
                    <translate-component :text="lang.reports_settings_component.skip"/>
                    :<br/>
                    <select id="skipMinDistance" name="skipMinDistance"
                            @change="getSelect($event,'reports_skipMinDistance','reports_skipMinDistance')"
                            :value="reports_settings_options.reports_skipMinDistance">
                      <template v-for="(step) in skip_arr">
                        <option :value="step">
                          {{ step }}
                          <translate-component :text="lang.reports_settings_component.meters"/>
                        </option>
                      </template>
                    </select>
                  </div>


                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" class="switch_1" id="chkByIgnition"
                             @change="getSelect($event,'reports_chkByIgnition','reports_chkByIgnition')"
                             :checked="reports_settings_options.reports_chkByIgnition == 1 ? 1 :0">
                    </div>
                    <span> <translate-component :text="lang.reports_settings_component.ignition"/></span>

                  </div>

                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" class="switch_1" id="chkDaily"
                             @change="getSelect($event,'reports_chkDaily','reports_chkDaily')"
                             :checked="reports_settings_options.reports_chkDaily == 1 ? 1 :0">
                    </div>
                    <span> <translate-component :text="lang.reports_settings_component.daily"/></span>

                  </div>


                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" class="switch_1" id="bOnlyFullDays"
                             @change="getSelect($event,'reports_bOnlyFullDays','reports_bOnlyFullDays')"
                             :checked="reports_settings_options.reports_bOnlyFullDays == 1 ? 1 :0">
                    </div>
                    <span> <translate-component :text="lang.reports_settings_component.full_days"/></span>

                  </div>

                </div>
              </div>

              <div class="route-setting_item" key="2" v-if="page===2 && report_type === 'full'">
                <div class="left-pane-padding">
                  <div class="route-setting_input-box">
                    <template v-for="(value, key, index) in default_telemetry_settings ">
                      <div class="form-check report-check">
                        <input class="form-check-input" type="checkbox" :checked="reports_settings_options[key] == 1 ? 1 :0"
                               @change="getSelect3($event,'reports_telemetry_chk'+index,'reports_telemetry_chk'+index,'default_telemetry_settings')"
                               :id="help_lang[index]">
                        <label class="form-check-label" :for="help_lang[index]">
                          <translate-component :text="lang.reports_settings_component[help_lang[index]]"/>
                        </label>
                      </div>
                    </template>
                    <div class="form-check report-check">
                      <input class="form-check-input" type="checkbox" :checked="reports_settings_options.reports_dateFormat  == 1 ? 1 :0"
                             @change="getSelect3($event,'reports_dateFormat','reports_dateFormat','default_full_settings')"
                             id="dateFormat">
                      <label class="form-check-label" for="dateFormat">
                        <translate-component :text="lang.reports_settings_component.date_format"/>
                      </label>
                    </div>
                    <div class="form-check report-check">
                      <input class="form-check-input" type="checkbox" :checked="reports_settings_options.reports_showDrivers  == 1 ? 1 :0"
                             @change="getSelect3($event,'reports_showDrivers','reports_showDrivers','default_full_settings')"
                             id="showDrivers">
                      <label class="form-check-label" for="showDrivers">
                        <translate-component :text="lang.reports_settings_component.Show_driver_names"/>
                      </label>
                    </div>
                  </div>
                  <div class="route-setting_input-box">
                    <translate-component :text="lang.reports_settings_component.Distance"/>
                    :<br/>
                    <select id="fuel_level" name="reports_telemetry_source_Distance"
                            @change="getSelect3($event,'reports_telemetry_source_Distance','reports_telemetry_source_Distance','default_full_settings')"
                            :value="reports_settings_options.reports_telemetry_source_Distance">
                      <option value="0">CAN</option>
                      <option value="1">GPS</option>
                    </select>
                  </div>
                  <div class="route-setting_input-box">
                    <translate-component :text="lang.reports_settings_component.FuelCounter"/>
                    :<br/>
                    <select id="reports_telemetry_source_FuelCounter" name="reports_telemetry_source_FuelCounter"
                            @change="getSelect3($event,'reports_telemetry_source_FuelCounter','reports_telemetry_source_FuelCounter','default_full_settings')"
                            :value="reports_settings_options.reports_telemetry_source_FuelCounter">
                      <option value="0">CAN</option>
                      <option value="1">Counter</option>
                      <option value="2">Normal</option>
                    </select>
                  </div>

                  <div class="route-setting_input-box">
                    <translate-component :text="lang.reports_settings_component.fuel_level"/>
                    :<br/>
                    <select id="reports_telemetry_source_FuelLevel" name="reports_telemetry_source_FuelLevel"
                            @change="getSelect3($event,'reports_telemetry_source_FuelLevel','reports_telemetry_source_FuelLevel','default_full_settings')"
                            :value="reports_settings_options.reports_telemetry_source_FuelLevel">
                      <option value="0">CAN</option>
                      <option value="1">LLS</option>
                      <option value="2">Standard</option>
                    </select>
                  </div>


                  <div class="route-setting_input-box">
                    <translate-component :text="lang.reports_settings_component.engine_work_time"/>
                    :<br/>
                    <select id="reports_telemetry_source_EngineTime" name="reports_telemetry_source_EngineTime"
                            @change="getSelect3($event,'reports_telemetry_source_EngineTime','reports_telemetry_source_EngineTime','default_full_settings')"
                            :value="reports_settings_options.reports_telemetry_source_EngineTime">
                      <option value="0">CAN</option>
                      <option value="1">Ignition</option>
                      <option value="2">Motion</option>
                    </select>
                  </div>

                  <div class="route-setting_input-box">
                    <translate-component :text="lang.reports_settings_component.speed"/>
                    :<br/>
                    <select id="reports_telemetry_source_Speed" name="reports_telemetry_source_Speed"
                            @change="getSelect3($event,'reports_telemetry_source_Speed','reports_telemetry_source_Speed','default_full_settings')"
                            :value="reports_settings_options.reports_telemetry_source_Speed">
                      <option value="0">CAN</option>
                      <option value="1">GPS</option>
                    </select>
                  </div>

                </div>
              </div>


            </transition-group>
            </perfect-scrollbar>

          </div>
        </div>
    </div>
  </section>
</template>


<script>
import Popper from 'vue-popperjs';
import SvgSettingMono from "@/components/ui/icons/svgSettingMono";
import SvgSettingsIcon from "@/components/ui/icons/svgSettingsIcon";
import SvgMapIcon from "@/components/ui/icons/svgMapIcon";
import SvgRoueViewIcon from "@/components/ui/icons/svgRoueViewIcon";
import SvgPlayerIcon from "@/components/ui/icons/svgPlayerIcon";
import SvgPathIcon from "@/components/ui/icons/svgPathIcon";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import VueRangeSlider from "vue-range-component";
import {mapGetters} from "vuex";
import axios from "axios";

export default {
  name: "ReportsSettingsComponent",
  components: {
    SvgSettingMono,
    SvgSettingsIcon,
    SvgMapIcon,
    SvgRoueViewIcon,
    SvgPlayerIcon,
    SvgPathIcon,
    TranslateComponent,
    VueRangeSlider,
    'popper': Popper,
  },
  data() {
    return {
      suppressScrollX:true,
      page: 1,
      show_content: true,
      help_counter: 0,
      backgroundImageUrl: '/tracking/assets/images/geo-zone/tallinn_600.jpg',
      metres_arr: [2, 5, 10, 15, 20, 25, 30],
      allowable_arr: [0, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000],
      skip_arr: [0, 5, 10, 20, 30, 40, 50, 100],
      default_settings: {
        reports_chkByIgnition: 1,
        reports_chkDaily: 1,
        reports_bOnlyFullDays: 1,
        reports_chkFuelByDayOnlyWithIgnition: 1,
        reports_selFuelByDayMovementSource: 0,
        reports_selFuelByDayMovementSpeed: 2,
        reports_minWorkInterval: '08:00',
        reports_maxWorkInterval: '20:00',
        reports_minStopTime: 5,
        reports_maxStopDistance: 200,
        reports_skipMinDistance: 5,
        reports_routecheck_Type: 0,
        reports_routecheckm_byParking: 1,
        reports_routecheckw_byParking: 1,
        reports_distancegps_flipSides: 0,
        reports_overspeed_overspeedLimit: 90,
      },
      reports_settings_options: {},
      default_telemetry_settings: {
        reports_telemetry_chk0:true,
        reports_telemetry_chk1:true,
        reports_telemetry_chk2:true,
        reports_telemetry_chk3:false,
        reports_telemetry_chk4:false,
        reports_telemetry_chk5:false,
        reports_telemetry_chk6:true,
        reports_telemetry_chk7:true,
        reports_telemetry_chk8:false,
        reports_telemetry_chk9:false,
        reports_telemetry_chk10:false,
        reports_telemetry_chk11:false,
        reports_telemetry_chk12:false,
        reports_telemetry_chk13:false,
        reports_telemetry_chk14:true,
        reports_telemetry_chk15:true,
        reports_telemetry_chk16:true,
        reports_telemetry_chk17:false,
        reports_telemetry_chk18:true,
        reports_telemetry_chk19:false,
        reports_telemetry_chk20:false,
        reports_telemetry_chk21:false,
        reports_telemetry_chk22:false,
        reports_telemetry_chk23:false,
        reports_telemetry_chk24:false,
        reports_telemetry_chk25:false,
        reports_telemetry_chk26:false,
        reports_telemetry_chk27:true,
        reports_telemetry_chk28:true,
      },
      default_full_settings: {
        reports_dateFormat:false,
        reports_showDrivers:false,
        reports_telemetry_source_Distance:0,
        reports_telemetry_source_FuelCounter:0,
        reports_telemetry_source_FuelLevel:0,
        reports_telemetry_source_EngineTime:0,
        reports_telemetry_source_Speed:0,
        reports_overspeed_overspeedLimit:90,
        reports_parking_parkingLimit:5,
      },
      help_lang:[ "DateTimeFrom",
        "DateTimeTo",
        "Distance",
        "FuelCounter",
        "FuelUsedByDistance",
        "FuelUsedByTime",
        "FuelLevelStart",
        "FuelLevelEnd",
        "EngineWorkTime",
        "EngineWorkTimeWithMotion",
        "SpeedAvg",
        "EngineRevolutionsAvg",
        "NormalFuelUsedByDistance",
        "NormalFuelUsedByTime",
        "TimeBegin",
        "TimeEnd",
        "FuelUp",
        "FuelDown",
        "FuelConsumed",
        "EngineWorkTimeWithoutMotion",
        "OverspeedDuration",
        "ParkingDuration",
        "FuelCounterIdle",
        "SpeedRangeDuration",
        "SpeedRangeDistance",
        "TimeDuration",
        "HeaterTime",
        "FuelConsumedByDistance",
        "CanOdometer"]
    }
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      reports_settings_params:'REPORTS_SETTINGS_PARAMS',
      reports_settings_check:'REPORTS_SETTINGS_CHECK',
      report_type:'REPORTS_TYPE',
    }),

  },
  mounted() {
    let $this = this
    this.reports_settings_options  ={...$this.reports_settings_params }
  },
  methods:{
    hideContent() {
      this.show_content = !this.show_content
    },
    closeModal() {
      this.$store.commit('SET_SHOW_REPORTS_SETTINGS', false);
    },
    changePage(num) {
      this.page = num
    },
    addDefault(){
      
    },
    getSelect3(event, option_type, save_type,object_name){
      let val_add = ''
      if (!isNaN(event) || Array.isArray(event)) {
        val_add = event
      } else {
        val_add = event.target.value
      }
      if (event.target) {
        if (event.target.className === 'form-check-input') {
          if (event.target.checked === true) {
            val_add = 1

          } else {
            val_add = 0

          }
        }
      }
      if(object_name === 'default_telemetry_settings'){
        let old_value =  this.default_telemetry_settings[option_type]

        if ( val_add != old_value) {
          this.default_telemetry_settings[option_type] = val_add
          this.sendSettings2(val_add, save_type)

        }

      }

      if(object_name === 'default_full_settings'){
        let old_value =  this.default_full_settings[option_type]

        if ( val_add != old_value) {
          this.default_full_settings[option_type] = val_add
          this.sendSettings2(val_add, save_type)

        }

      }

      this.reports_settings_options[option_type] = val_add
      this.$store.commit('SET_REPORTS_SETTINGS_PARAMS', this.reports_settings_options);
     },
    getSelect(event, option_type, save_type = null) {
      let val_add = ''
      if (!isNaN(event) || Array.isArray(event)) {
        val_add = event
      } else {
        val_add = event.target.value
      }
      let old_value = this.reports_settings_options[option_type]
      if (event.target) {
        if (event.target.className === 'switch_1') {
          if (event.target.checked === true) {
            val_add = 1

          } else {
            val_add = 0

          }
        }
      }

      let new_value = val_add

        if (new_value != old_value) {
          this.sendSettings(new_value, save_type)

          this.$store.commit('SET_REPORTS_SETTINGS_PARAMS', this.reports_settings_options);
        }

    },
    sendSettings(value, params){
      let $this = this
      let formData = new FormData();
      formData.append("UserParameters["+params+"]", value)

      axios.post(base_url + '/index/setuserparameter', formData).then(res => {


      }).catch(error => {
        console.log(error)
      })
    },
    sendSettings2(value, params){

      let $this = this
      let formData = new FormData();
      formData.append("ParameterName", params)
      formData.append("ParameterValue", value)
      setTimeout(() => {
        axios.post(base_url + '/index/setuserparameter', formData).then(res => {
        }).catch(error => {
          console.log(error)
        })
      }, 300)




    },
  }
}
</script>

<style scoped>
.report-check label{
  padding-left: 10px;
  display:inline;
}
.report-check label span{
 font-size: 13px;
}
.object-info-more-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
}

.object-info-more-item {
  font-size: 12px;
  line-height: 18px;
}

.object-info-more-key {
  margin-right: 10px;
}

.card.object-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  max-width: 350px;
  height: auto;
  max-height: calc(100% - 170px);
  position: fixed;
  background-color: #fff;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.widgets-panel__header {
  position: relative;
  height: 120px;
  padding: 0;
  margin: 0;
}

.widgets-panel__tracker-title {
  color: #fff !important;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 16px;
  width: 100%;
  z-index: 2;
  font-size: 20px;
  font-weight: bolder;
  text-shadow: 1px 1px #000;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);

}

.settin-title-box span {
  padding-left: 10px;
}

.street-view__streetview, .street-view__streetview img {
  border-radius: 4px 4px 0px 0px;
  -webkit-filter: blur(2px);
  filter: blur(2px);
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.street-view__click-stub {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  cursor: pointer;
  font-size: 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.widgets-panel .widgets-panel__street-view {
  height: 100px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.widgets-panel__street-view, .widgets-panel__tracker-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.object-info-close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 20px;
  color: #ccc;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  align-items: center;
}

.object-info-close:hover, .object-info-close:focus {
  color: #ffff
}

.list-group-icon {
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.list-group-icon svg {
  fill: #4cabdb;
}

.list-group-item {
  display: flex;
  align-items: center;
}

.info-list-item {
  font-weight: 600;
  line-height: 18px;
  color: #34495e;
}

.info-list-title {
  line-height: 18px;

}

.info-list-item_label {
  border: 1px solid #4cabdb;
  padding: 2px 10px;
  border-radius: 15px;
  margin-left: 5px;
}

.card-body.bject-info {
  padding: 20px 20px;
  padding-top: 0;
}

.list-group-item {
  padding: 8px 15px;
}

.history-setting-section {
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  padding: 0;
  background: #fff;
  width: 100%;
  max-width: 800px;
  height: auto;
  max-height: 90vh;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}

.testanim-enter-active, .testanim-leave-active {
  transition: all .5s;
}

.testanim-enter, .testanim-leave-to {
  transform: translateX(1rem);
  opacity: 0;
}

.testanim-leave-active {
  position: absolute;
}

.testanim-move {
  transition: all .5s;
}

.route-setting_box {
  width: 100%;
  height: 100%;
}

.route-setting_list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.route-setting_list li {
  width: 25%;
  padding: 0;
  margin: 0;
  padding: 5px;
}

.route-setting_page-btn {
  white-space: normal;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  min-width: 0;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  border: none;
  padding: 4px 5px;
  font-weight: 600;
}

.route-setting_page-btn.active {
  color: #1C71DA;
  background: rgba(0, 136, 204, 0.3);
}

.route-setting_page-btn svg {
  width: 25px;
  margin-right: 5px;
}

.route-setting_page-btn svg {
  fill: #1C71DA;
}


.route-setting_page-btn:hover, .route-setting_page-btn:focus {
  background-color: #dcdcdc;
}

.route-setting_page-btn img {
  width: 25px;
}

.object-list-title {
  margin-top: 0;
  margin-bottom: 0;
}

.route-setting_item {
  padding: 10px 10px;
  max-height: 50vh;
  min-height: 50vh;
  padding-top: 40px;
  padding-right: 20px;
}

select {
  width: 100%;
}

.route-setting_input-box {
  margin-bottom: 15px;
}

.route-setting_input-box span {
  font-weight: 600;
  font-size: 14px;
}

.switch_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100px;
  min-width: 100px;
  height: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/* Switch 1 Specific Styles Start */

.box_1 {
  background: transparent;
}

input[type="checkbox"].switch_1 {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 2.5em;
  height: 1.0em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #0ebeff;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.0em;
  height: 1.0em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
  box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
  -webkit-transform: scale(.7);
  transform: scale(.7);
  left: 0;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1em);
}

.route-setting_input-box.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group span {
  padding-top: 15px;
}

.route-setting_input-helper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.route-setting_input-helper .min, .route-setting_input-helper .max {
  border-radius: 3px;
  background-color: #ddd;
  padding: 2px 5px;
}

.color-chacker {
  width: 25px;
  height: 25px;
  border-radius: 3px;
  margin-left: 10px;
  display: block;
  cursor: pointer;
  border: 1px solid #cecece;
}

.street-view__click-stub .hide-button {
  top: 40px;
}

.object-info-box.hide-object-wrapper {
  max-width: 100px;
  height: 100px;
  width: 100px;

}

.hide-object-wrapper .card.object-info {
  min-width: 100px;
  max-width: 100px;
}

.hide-object-wrapper .object-info-close {
  display: none;
}

.hide-object-wrapper .widgets-panel__header {
  height: 100px;
}

.hide-object-wrapper .widgets-panel__tracker-title {
  font-size: 14px;
  display: none;
}

.hide-object-wrapper .street-view__click-stub .hide-button {
  top: 10px;
}

.hide-object-wrapper .street-view__click-stub {
  width: 1px;
}

.hide-object-wrapper .street-view__streetview, .street-view__streetview img {
  display: none;
}

.hide-object-wrapper .card.object-info {
  min-width: 1px;
  max-width: 1px;
  max-height: 1px;
}
.ps {
  height: 50vh;
}
@media only screen and (max-width: 1500px) {
  .route-setting_input-box span {
    font-weight: 600;
    font-size: 12px;
  }

  .switch_box {
    display: flex;
    max-width: 80px;
    min-width: 80px;
    height: auto;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .card.object-info {
    min-width: 280px;
    max-width: 300px;
  }

  .widgets-panel__header {
    height: 110px;
  }

  .street-view__streetview, .street-view__streetview img {
    height: 110px;
  }

  .list-group-item {
    padding: 5px 8px;
  }

  .list-group-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }

  .route-setting_list {
    padding-top: 10px;
  }

  .route-setting_item {
    max-height: 50vh;
    min-height: 50vh;
  }

  .route-setting_input-box select {
    border: 1px solid #E5E7E9;
    border-radius: 6px;
    height: 40px;
    padding: 8px;
    outline: none;
  }

  .route-setting_input-box {
    margin-bottom: 10px;
  }

  input[type="checkbox"].switch_1 {
    width: 2.2em;
    height: .8em;
  }

  input[type="checkbox"].switch_1:after {
    width: 0.8em;
    height: 0.8em;
  }

  input[type="checkbox"].switch_1:checked:after {
    left: calc(100% - 0.8em);
  }

}

.settin-title-box {
  font-size: 16px;
}

.remove-default-btn {
  position: absolute;
  right: 5px;
  bottom: 10px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding: 3px;
  font-size: 12px;
  font-weight: 600;
  background-color: #f5f5f5;
  box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%);
}

.remove-default-btn:hover, .remove-default-btn:focus {

  background-color: #ffffff;
}
@media only screen and (max-width: 600px) {
  .ps {

    height: 60vh;


  }
  .object-info-box {
    right: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
    max-width: 100vw;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 100000;
    top: 60px;
    left: 0px;
  }
  .card.object-info{
    min-width: 100vw;
    max-width: 100vw;
    height: 100%;
    max-height: calc(100% - 60px);
  }
.route-setting_item{
  max-height: 60vh;
  height: 60vh;
  padding-top: 20px;
  padding-right: 60px;
}
}
</style>
