export default {

  methods: {
    zoomIcon(obj,marker_ref= false) {

       if(marker_ref) {
           this.$store.commit('SET_UPDATE_POLYLINE', {'type': 4, 'counter': Math.random(), 'marker_ref': marker_ref});
       }else {
           let change_center = [];
           change_center.push(parseFloat(obj.Y));
           change_center.push(parseFloat(obj.X));
           let $this = this
           $this.$store.commit('SET_MAP_ZOOM', 16);
           $this.$store.commit('SET_START_CENTER', change_center);
           setTimeout(() => {
               $this.$store.commit('SET_MAP_ZOOM', 17);
               $this.$store.commit('SET_START_CENTER', change_center);
           }, 400)
       }
    },
  }
}
