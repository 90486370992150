<template>
  <section class="panel geoparams-box" :class="hide_content ? 'hide-box' : ''">
    <div class="panel-body geoparams-info-box">
      <div class="content">
        <div class="widget-content-expanded">
          <span  class="btn btn-default btn-renove-info" @click="removeInfo()"><span class="fa reload-table" ><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="redo-alt" class="svg-inline--fa fa-redo-alt fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M256.455 8c66.269.119 126.437 26.233 170.859 68.685l35.715-35.715C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.75c-30.864-28.899-70.801-44.907-113.23-45.273-92.398-.798-170.283 73.977-169.484 169.442C88.764 348.009 162.184 424 256 424c41.127 0 79.997-14.678 110.629-41.556 4.743-4.161 11.906-3.908 16.368.553l39.662 39.662c4.872 4.872 4.631 12.815-.482 17.433C378.202 479.813 319.926 504 256 504 119.034 504 8.001 392.967 8 256.002 7.999 119.193 119.646 7.755 256.455 8z"/></svg></span></span>
          <ul class="simple-todo-list geoparams__list">
            <li class="completed"><span class="geoparams__number" v-if="">{{info.Number}}</span></li>
            <li class="completed">
              <span class="geoparams__list-svg"><svg-rout-sitevare-icon/></span>

              <span><translate-component :text="lang.geoparams_siteber_component.total_distance"/>: </span><span>{{info.total_distance}} Km</span></li>

            <li class="completed"><span class="geoparams__list-svg geoparams__list-top-speed"></span><span><translate-component :text="lang.fuel_table_components.consumption"/> (<translate-component :text="lang.fuel_table_components.consumption_litr"/>): </span><span>{{info.total_consumption}}</span></li>
            <li class="completed"><span class="geoparams__list-svg geoparams__list-avarage-speed"></span><span><translate-component :text="lang.fuel_table_components.consumption"/> (<translate-component :text="lang.fuel_table_components.consumption_hour"/>): </span><span>{{getCountaAvarage(info.total_consumptionTime,info.total_consumptionTime_count)}}</span></li>
            <li class="completed"><span class="geoparams__list-svg geoparams__list-avarage-speed"></span><span><translate-component :text="lang.fuel_table_components.consumption"/> (<translate-component :text="lang.fuel_table_components.consumption_km"/>): </span><span>{{getCountaAvarage(info.total_consumptionDist,info.total_consumptionDist_count)}}</span></li>
            <li class="completed"><span class="geoparams__list-svg geoparams__list-avarage-speed"><svg-termometr-full-icon/></span><span><translate-component :text="lang.fuel_table_components.fill"></translate-component>:</span><span> +{{info.total_filling}} (<translate-component :text="lang.fuel_table_components.consumption_litr"/>)</span><span class=" last-list-item geoparams__list-avarage-speed" v-if="info.total_filling_count > 0">{{info.total_filling_count}}</span></li>
            <li class="completed"><span class="geoparams__list-svg geoparams__list-top-speed"><svg-termometr-empy-icon/></span><span> <translate-component :text="lang.fuel_table_components.drain"></translate-component>:</span><span> -{{info.total_drain}} (<translate-component :text="lang.fuel_table_components.consumption_litr"/>)</span><span class=" last-list-item geoparams__list-top-speed" v-if="info.total_drain_count > 0">{{info.total_drain_count}}</span></li>
          </ul>
        </div>
      </div>
    </div>
    <span  class="hide-content-btn"   @click="hideContent()" ><svg-move-down-table-icon/></span>
        <perfect-scrollbar>
          <div class="panel-body geoparams-info-main">
            <div class="content">
              <span style="display: none">{{help_counter}}</span>
              <ul class="simple-user-list geoparams-box__list" >
                <template v-if="loading === table_index">
                  <template v-for="(index) in table_index" >
                    <li class="geoparams-box__item"  :class="[(showAll === index ? 'selected': '' ), (table_data[index].fill >0  && table_data[index].drain <= 0  ? 'green' : '') , (table_data[index].fill <= 0  && table_data[index].drain > 0  ? 'red' : '') ,(table_data[index].fill > 0  && table_data[index].drain > 0  ? 'purpure' : '')]" @click="ShowChanges(index)"  >
                  <div class="geoparams-counter" v-if="(table_data[index].fillCount+table_data[index].drainCount)>0">{{ table_data[index].fillCount+table_data[index].drainCount }}</div>
                  <div class="geoparams-boxes" >
                    <span class="geoparams-name">  <translate-component :text="lang.fuel_table_components.date"></translate-component></span>
                    <span class="geoparams-item geoparams-add-time">
                    <span style="display: none">{{ help_counter }}</span>{{ table_day[index].dt1.split(' ')[0] }}
                    </span>
                  </div>
                      <template v-if="showAll ===index">
                  <div class="geoparams-boxes">
                    <span class="geoparams-name">  <translate-component :text="lang.fuel_table_components.fill"></translate-component></span>
                    <span class="geoparams-item" >
                    <template v-if="table_data[index].fill >0">
                <span class="green">+<span
                    class="Xfillvalue">{{ table_data[index].fill.toFixed(1) }}</span></span>
                      <span class="grey small">(x<span class="Xfillcount">{{ table_data[index].fillCount }}</span>)  </span>
                    </template>
                      <template v-if="table_data[index].fill < 0">
                <span class="red">-<span
                    class="Xfillvalue">{{ table_data[index].fill.toFixed(1) }}</span></span><span
                             class="grey small">(x<span
                             class="Xfillcount">{{ table_data[index].fillCount }}</span>)  </span>
                    </template>
                    </span>
                  </div>
                  <div class="geoparams-boxes">
                    <span class="geoparams-name">  <translate-component :text="lang.fuel_table_components.drain"></translate-component></span>
                    <template v-if="table_data[index].drain >0">
                      <span class="red">-{{ parseFloat(table_data[index].drain.toFixed(1)) }}</span>
                      <span class="grey small">(x<span class="Xdraincount">{{ table_data[index].drainCount }}</span>)</span>
                    </template>
                  </div>
                  <div class="geoparams-boxes"> <span class="geoparams-name"> <translate-component :text="lang.fuel_table_components.consumption"></translate-component></span><span class="geoparams-item">{{ table_data[index].consumption.toFixed(1) }}</span></div>
                  <div class="geoparams-boxes"><span class="geoparams-name">  <translate-component :text="lang.fuel_table_components.consumption_hour"></translate-component></span><span class="geoparams-item">{{ table_data[index].consumptionTime.toFixed(1) }}</span></div>
                  <div class="geoparams-boxes"><span class="geoparams-name"> <translate-component :text="lang.fuel_table_components.consumption_km"></translate-component></span><span class="geoparams-item">{{ table_data[index].consumptionDist.toFixed(1) }}</span></div>
                  <div class="geoparams-boxes"><span class="geoparams-name"> <translate-component :text="lang.fuel_table_components.distance"></translate-component></span><span class="geoparams-item">{{ table_data[index].distance.toFixed(1) }}</span></div>
                  <div style="display: none">{{ table_data[index].engineWorkTime.toFixed(1) }}</div>
                  <div style="display: none">{{ table_data[index].engineWorkTimeInMovement.toFixed(1) }}</div>

                      <template v-if="table_data[index].changes && table_data[index].changes.length > 0">
                        <template v-for="(item,int) in table_data[index].changes">
                          <template v-if="item[4] > 0 || item[4] < 0">
                    <div class="geoparams-box__item not-tranzit"  :class="item[4] > 0 ? 'changeEnabler-filling' : 'changeEnabler-drain'" :key="int+'ch'+index"  @click.stop="changeMarker(item)">

                      <div class="geoparams-box-time" :class="item[4] > 0 ? 'changeEnabler-filling' : 'changeEnabler-drain'">
                        <span class="geoparams-time-icon"><span class="fa fa-clock-o"></span></span><span class="table-fuel-time" >{{ getFuelTime(item) }}</span>
                      </div>
                      <div class="geoparams-item geoparams-fuel">

                        <span class="geoparams-fuel-icon"><i class="fa" ><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gas-pump" class="svg-inline--fa fa-gas-pump fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z"/></svg></i></span>
                        <span class="geoparams-fuel-text green" v-if="item[4] > 0">
                        {{ item[4] > 0 ? '+' : '' }}
                        <span  >
                          {{ item[4] > 0 ? item[4].toFixed(1) : '' }}
                        </span>
                      </span>
                        <span class="geoparams-fuel-text red" v-if="item[4] < 0">
                        {{ item[4] < 0 ? '-' : '' }}
                        <span >
                          {{ item[4] < 0 ? item[4].toFixed(1) : '' }}
                        </span>
                      </span>
                      </div>

                      <div  class="table-fuel-address">
                        <span v-if="item[5]">{{item[5]}}</span>
                      </div>


                    </div>
                  </template>
                </template>
               </template>
                      </template>
                </li>

                </template>
                </template>
              </ul>

            </div>
          </div>
       </perfect-scrollbar>
  </section>
</template>

<script>


import {mapGetters} from "vuex";
import axios from "axios";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import helpers from "@/mixins/helpers";
import SvgCameraIcon from "@/components/ui/icons/svgCameraIcon";
import zoomIcon from "@/mixins/zoomIcon";
import moment from "moment-timezone";
import SvgMoveDownTableIcon from "@/components/ui/icons/svgMoveDownTableIcon";
import SvgRoutSitevareIcon from "@/components/ui/icons/svgRoutSitevareIcon";
import SvgTermometrFullIcon from "@/components/ui/icons/svgTermometrFullIcon";
import SvgTermometrEmpyIcon from "@/components/ui/icons/svgTermometrEmpyIcon";
export default {
  name: "FuelTableComponent",
  components: {
    SvgTermometrEmpyIcon,
    SvgTermometrFullIcon, SvgRoutSitevareIcon, SvgMoveDownTableIcon, SvgCameraIcon, TranslateComponent},
  mixins: [helpers, zoomIcon],
  props:{
    ps_style:{
      type:String,
      default: 'height: 300px;'
    },
    fuel_type:{
      type:String,
      default:'fuel_2'
    }
  },
  data() {
    return {
      info:{
        Number:'',
        total_distance:0,
        total_consumption:0,
        total_consumptionTime:0,
        total_consumptionDist:0,
        total_consumptionTime_count:0,
        total_consumptionDist_count:0,
        total_filling:0,
        total_filling_count:0,
        total_drain:0,
        total_drain_count:0,
      },
      route_counter:0,
      reload_map:1000,
      hide_content:false,
      helper_counter:0,
      fuel_markers:[],
      video_time:0,
      show_video:false,
      new_address:'',
      showAll:'',
      loading: 0,
      help_counter: 0,
      table_data: {},
      table_day: {},
      table_index: 0,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      select_fuel: 'SELECT_FUEL',
      select_fuel_data: 'SELECT_FUEL_DATA',
      base_url:'BASE_ADD',
      lang_short:'LANG_SHORT',
      chart_data: 'FUEL_CHART_DATA',
      fuel_fill_markers:'FUEL_FILL_MARKERS',
      settings_fuel_active: 'SETTINGS_FUEL_ACTIVE',
      fuel_time_change: 'FUEL_TIME_CHANGE',
    }),
  },
  mounted() {


    this.table_index = this.select_fuel_data[this.settings_fuel_active].diff
    this.fuel_markers =[]
    this.$store.commit('SET_FUEL_FILL_MARKERS', this.fuel_markers);
    if(this.fuel_type === 'fuel_2'){
      for (let i = 0; i <= this.table_index; i++) {

        this.getFuelData(i)
      }
    }else{
      this.table_index = this.select_fuel_data.diff+1
      this.table_data = this.chart_data.days
      this.chart_data.days.map(item=>{
        if(item.changePoints.length > 0){
          item.changePoints.map(mar =>{
            let obj = this.chart_data.data[mar.idx]
            let add_time = moment(obj.timestamp*1000).tz('utc').format('HH:mm')
            this.fuel_markers.push({'lat':obj.lat, 'lng': obj.lng ,'fill': obj.fuelLevel, 'date':item.date, 'time': add_time})
          })
        }
      })


      this.help_counter++
      this.loading = this.table_index

      this.$store.commit('SET_FUEL_FILL_MARKERS', this.fuel_markers);
    }


  },
  methods: {
    getCountaAvarage(info_add,info_total){

      if(info_add > 0 && info_total> 0){
        return Math.round((info_add/info_total) * 100) / 100
      }else{
        return 0
      }

     },
    changeMarker(obj){
      let time = this.getFuelTime(obj)
      this.zoomIcon(obj,'fuel_'+time)
    },
    hideContent(){
      this.hide_content = !this.hide_content
      this.reload_map++
      let $this = this
      setTimeout(function () {
        $this.$store.commit('SET_RELOAD_MAP', this.reload_map);
      }, 200);

    },
    showVideo(add_time){
      this.video_time =parseInt(add_time)-(3600*2)-60
      this.$emit('showTableVideo',  this.video_time)
    },
     ShowChanges(index){
       let $this = this
       if(this.fuel_type === 'fuel_2') {
         let start = this.table_day[1].ndt1
         let end = this.table_day[this.table_index].ndt2
         let data = {'start': start, 'end': end}
         if (this.showAll === index) {
           this.showAll = null
           this.helper_counter++
         } else {
           this.showAll = index
           start = this.table_day[index].ndt1
           end = this.table_day[index].ndt2
           data = {'start': start, 'end': end}
           if (this.table_data[index].changes && this.table_data[index].changes.length > 0) {
             this.table_data[index].changes.map(item => {
               if (!item[5]) {
                 item[5] = 'loading...'
                 $.get( 'https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + item[2] + '&lon=' + item[3] + '&accept-language=' + $this.lang_short,function() {
                 }).done(function(data) {
                   item[5] = data.display_name
                   this.help_counter++
                 })
                     .fail(function() {
                       alert( "error" );
                     })

               }
             })
           }
         }
         this.helper_counter++
         setTimeout(() => {
           $this.help_counter++
         }, 500);

         let add = {...this.fuel_time_change}
         add[this.settings_fuel_active] = data
         this.$store.commit('SET_FUEL_TIME_CHANGE', add)
       }
       if(this.fuel_type === 'fuel_old') {
         if (this.showAll === index) {
           this.showAll = ''
         } else {
           this.showAll = index
         }
         let start =  this.table_data[index].chart[0]
         let len = this.table_data[index].chart.length-1
         let end = this.table_data[index].chart[len]
         let data = {'start': start, 'end': end}
         let $this = this
         setTimeout(() => {
           $this.help_counter++;
           $this.$store.commit('SET_FUEL_TIME_CHANGE', data)
         }, 200);


         }
    },
    getFuelTime(item) {
      let t = new Date(item[0] * 1000 + item[1]);
      return this.pad(t.getUTCHours(), 2) + ':' + this.pad(t.getUTCMinutes(), 2);
    },
    pad(number, length) {
      return new Array((length || 2) + 1 - String(number).length).join(0) + number;
    },
    DataFullTable() {

    },
    getFuelData(num) {
      if (this.select_fuel !== 0) {
        let $this = this
        let active_fuel = this.settings_fuel_active
        let formData = new FormData();
        formData.append('id', this.select_fuel.ObjectId)
        formData.append('dt1', this.select_fuel_data[active_fuel].start)
        formData.append('dt2', this.select_fuel_data[active_fuel].end)
        formData.append('index', num)
        axios.post(base_url + '/fuel/getfuelday', formData).then(response => {
          if (response.data['success'] === true) {
            this.table_data[num + 1] = response.data['data']
            this.table_day[num + 1] = response.data['day']
            let add = response.data['data'].changes
            if (add.length > 0) {
              let add_date = this.table_day[num + 1].dt1.split(' ')[0]
              add.map(item => {
                let add_time = this.getFuelTime(item)
                item[4] = parseFloat(item[4])
                this.fuel_markers.push({
                  'lat': item[2],
                  'lng': item[3],
                  'fill': item[4],
                  'date': add_date,
                  'time': add_time
                })
              })

            }
            this.help_counter++
            this.loading++
            let add_markers = {...this.fuel_fill_markers}
            add_markers = this.fuel_markers

            this.$store.commit('SET_FUEL_FILL_MARKERS', add_markers);

            this.info['Number'] = this.select_fuel['Number']

            if(this.select_fuel['ObjectId'] === '5404'){
              this.info['Number'] = 'demo_2'
            }
            if(this.select_fuel['ObjectId'] === '16481'){
              this.info['Number'] = 'demo_1'
            }
            if(this.select_fuel['ObjectId'] === '6786'){
              this.info['Number'] = 'demo_3'
            }
            this.info['total_distance'] = Math.round((this.info['total_distance'] + response.data['data']['distance']) * 100) / 100
            this.info['total_consumption'] = Math.round((this.info['total_consumption'] + response.data['data']['consumption']) * 100) / 100

            this.info['total_consumptionTime'] =this.info['total_consumptionTime'] + response.data['data']['consumptionTime']

            this.info['total_consumptionDist'] = this.info['total_consumptionDist'] +response.data['data']['consumptionDist']
            if(response.data['data']['consumptionTime'] > 0) {
              this.info['total_consumptionTime_count']++
            }
            if( response.data['data']['consumptionDist'] > 0) {
              this.info['total_consumptionDist_count']++
            }

            if (response.data['data']['fill'] > 0) {
              this.info['total_filling'] = Math.round((this.info['total_filling'] + response.data['data']['fill']) * 100) / 100
              this.info['total_filling_count']++
            }
            if (response.data['data']['fill'] < 0) {
              this.info['total_drain'] = Math.round((this.info['total_drain'] + response.data['data']['fill']) * 100) / 100
              this.info['total_drain_count']++
            }
            if (response.data['data']['drain'] > 0) {
              this.info['total_drain'] = Math.round((this.info['total_drain'] + response.data['data']['drain']) * 100) / 100
              this.info['total_drain_count']++
            }
          }
        }).catch(error => {
          console.log(error)
        })

      }
    },
    removeInfo(){
      this.$store.commit('SET_UPDATE_POLYLINE', {'type':2,'counter':Math.random()});

    }
  }
}
</script>

<style scoped>
.geoparams__list-svg {
  width: 20px;
  height: 15px;
  position: relative;
  display: inline-block;
}
.completed{
  position: relative;
}
.geoparams__list-svg svg{
  width: 15px;
  height: 15px;
  fill: #BDBDBD;
  color: #BDBDBD;
  position: absolute;
  top: 2px;
}
.geoparams__list-svg.geoparams__list-top-speed svg{
  fill: #FF8A65 !important;
  color:#FF8A65 !important;
}
.geoparams__list-svg.geoparams__list-avarage-speed svg{
  fill: #81C784;
  color:#81C784;
}
.last-list-item{
  border: 1px solid #cecece;
  border-radius: 15px;
  min-width: 20px;
  height: 16px;
  top: 0;
  width: auto;
  padding-right: 5px;
  padding-left: 5px;
  margin-left: 10px;
}
.last-list-item.geoparams__list-avarage-speed{
  color:#81C784;
  border-color: #81C784;
}
.last-list-item.geoparams__list-top-speed{
  color:#FF8A65;
  border-color: #FF8A65;
}

.geoparams-box{
  position: relative;
  width: 300px;
  min-width: 300px;
  flex-shrink: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  right: 0;
  transition: right 0.2ms ease;
}
.geoparams-box.hide-box{
  position: absolute;
  right: -280px;
}

ul.simple-todo-list li.completed:before {
  color: #1C71DA;
  display: none;
}
ul.simple-todo-list li.completed {
  color: #1C71DA;
  font-weight: 600;
  line-height: 20px;
  padding: 0 0 0 5px;
}
ul.simple-todo-list li.completed span:nth-child(2){
  color: #777777;
  padding-right: 0 !important;
}
.geoparams-box__img{
  width: 25px;
  height: auto;
}

.geoparams-box__item{
  display: flex;
  align-items: center;
  padding: 6px 20px 6px 16px;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 5px 7px -1px rgb(51 51 51 / 13%);
  cursor: pointer;
  transition: transform 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98), box-shadow 0.25s cubic-bezier(0.7, 0.98, 0.86, 0.98);
  background-color: #fff;
  margin-bottom: 10px;
  position: relative;
  flex-wrap: wrap;
}

.geoparams-box__item.changeEnabler-filling{
  padding: 6px 20px 6px 6px;
}
.geoparams-box__item:hover ,.geoparams-box__item:focus{
  transform: scale(1.1);
  box-shadow: 0 9px 47px 11px rgba(51, 51, 51, 0.18);

}

.geoparams-box__item.not-tranzit:hover ,.geoparams-box__item.not-tranzit:focus{
  transform: scale(1);
  box-shadow:none;

}

.geoparams-box__item.green{
  background:#97f5c7;
}
.geoparams-box__item.red{
  background: #ffb7a0;
}
.geoparams-box__item.purpure{
  background: #b1b9e1;
}
.geoparams-box__item.selected{
  background: #c9e7f8 !important;
}

.geoparams-info-box{
  min-height: 140px;
  background: #f6f6f6;
  border-bottom: 1px solid #DADADA;
  position: relative;
  height: auto;
}
.btn-renove-info{
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 100000;
}
.geoparams-info-main{
  height: 100%;
}
.ps {
  height: 90%;
}
.geoparams-box__item-column{
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.geoparams-box__item-interval{
  top: 5px;
  right: 20px;
  color: #1C71DA;
  position: absolute;
}
.geoparams-box__img.route-img {
  margin-top: 18px;
}
.geoparams-box__item-title{
  font-weight: 600;
  color: #6b6b6b;
  font-size: 11px;
}
.geoparams-box__item-date{
  color: #1C71DA;
}
.geoparams-box__checkbox{
  margin-left: auto;
  margin-right: 30px;
  height: 100%;
  padding-top: 20px;
}
.geoparams__number{
  padding-right: 10px;
  font-size: 20px;
  color: #1C71DA;
}
.geoparams-box__index{
  color: #676767;
  position: absolute;
  top: 5px;
  left: 16px;
  font-size: 13px;
  opacity: 0.8;
  border: 1px solid #1C71DA;
  border-radius: 50%;
  width: 22px;
  font-weight: bold;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #a5e1ff;
}
.btn-excel{
  padding: 2px 5px;
  line-height: 20px;
  display: flex;
  justify-content: center;
}
.btn-excel svg{
  width: 20px;
  height: 20px;
}
.hide-content-btn{
  position: absolute;
  top: 40vh;
  z-index: 100000;
  transform: rotate(-90deg);
  cursor: pointer;
}
.hide-content-btn svg{
  fill: #777;
}
.hide-content-btn:focus svg,.hide-content-btn:hover svg{
  fill:  #1C71DA;
}
.geoparams-info-main{
  padding-left: 20px;
}
.geoparams-info-main {
  height: 100vh;
  background-color: transparent;
  box-shadow: none;
}
ul.simple-user-list {
  padding-bottom: 20px;
  flex-wrap: wrap;
}
.geoparams-boxes{
  padding: 2px 5px;

  width: 100%;
  font-size: 12px;
}

.geoparams-name{
font-weight: 600;
padding-right: 10px;
  position: relative;
}
.geoparams-name::after{
  content: ':'
}
.geoparams-add-time{
  color: #1C71DA;
  font-weight: 600;
}
.geoparams-item .green{
  color: #00953e;
  font-weight: 600;
}
.geoparams-boxes.green{
  background-color: rgba(	0, 226, 94, 0.4);
}
.geoparams-item .red{
  color: #DD2C00;
  font-weight: 600;
}
.geoparams-boxes.red{
  background-color: rgba(		221, 44, 0, 0.4);
}
.grey.small{
  padding-left: 5px;
  color: #6c6c6c;
  font-weight: 600;
}
.geoparams-counter{
  position: absolute;
  top: 4px;
  right: 10px;
  width: 30px;
  height: 30px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #ffffff;
  border: 1px solid #6c6c6c;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.table-fuel-time{
  font-weight: 600;
  padding: 10px;
}
.geoparams-box-time{
  position: relative;
}
.geoparams-time-icon{
  color: #1C71DA;
  font-size: 16px;
}
.geoparams-fuel{
padding-left: 5px;
}
.geoparams-fuel-icon{
}
.geoparams-fuel-icon i{
width: 16px;
  color: #1C71DA;
}
.geoparams-fuel-icon i svg{
  fill: #1C71DA;
}
.geoparams-fuel-text{
  padding: 10px;
}
.btn-renove-info{
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 100000;
  background: transparent;
  border: none;
}
.reload-table{
  width: 16px;
  height: 16px;
  color: #1C71DA;
  fill: #1C71DA;
}
.reload-table:hover, .reload-table:focus{
  transform: rotate(45deg);
}

@media only screen and (max-width: 1500px) {
  .btn-renove-info {
    top: 0px !important;
    right: 0px;
  }
  .geoparams-box{
    width: 240px ;
    min-width: 240px ;
  }

  .geoparams-box__index{
    color: #676767;
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 12px;
    opacity: 0.8;
    border: 1px solid #1C71DA;
    border-radius: 50%;
    width: 20px;
    font-weight: 500;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #a5e1ff;
  }
  .geoparams-info-box {
    min-height: 180px;
  }
  .geoparams__number{
    font-size: 16px;
    width: 180px;
    display: inline-block;
  }
  .history-table__conrtent {
    width: 240px;
  }

  .geoparams-box.hide-box {
    right: -220px;
  }
  .geoparams-box__item {
    padding: 4px 10px 4px 4px;
    border-radius: 3px;
    margin-bottom: 5px;
  }
  .geoparams-box__checkbox{
    margin-right: 10px;
  }
  .geoparams-box__img {
    width: 20px;
  }
  .geoparams-boxes {
    margin-right: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .panel.geoparams-box{
    position: absolute;
    z-index: 10000000;
    background: #ecedf0;
  }
  .geoparams-info-box{
    width: 220px;
    margin-left: 20px;
  }
  .geoparams__number {
    font-size: 14px;

  }
  .btn-renove-info {
    top: 5px  !important;
    right: 5px;
  }
  ul.simple-todo-list li.completed{
    font-size: 11px;
  }
  .geoparams-box.hide-box {

    background: transparent;
  }
}
ul.simple-todo-list li.completed span:nth-child(2) {
  padding-right: 0px !important;
}
</style>

