<template>
    <section class="panel global-settings-panel" >
        <template v-if="current_resources && current_resources[755] && current_resources[755].enabled ===true">
            <header class="panel-heading vehicle-head  task-head" :class="search_hide ? 'scroll-head-hide': ''">

                <h2 class="panel-title">

                    <span class="vehicle-number" ><span class="svg-box-set"><svg-share-icon/></span><translate-component :text="lang.share_component.title"/></span>
                    <button type="button"  class="mobile-search-scroll" @click="HideSearch()">
          <span class="search-scroll-icon" v-if="!search_hide">
           <search-hide-icon/>
          </span>
                        <span class="search-scroll-icon" v-if="search_hide">
            <search-sho-icon/>
          </span>
                    </button>
                </h2>
                <template v-if="page=== 1">
                    <div class="form-group search-group " >
                        <select class="form-control" v-model="params['records_params'].filter.object_id" @change="FilterStart()">
                            <option :value="null" >{{lang.reports_component.select_any_object}}</option>
                            <option :value="helps.object_id" v-for="helps in help_desc[3]" :key="helps.user_id" >{{helps.name}}</option>
                        </select>

                    </div>
                    <div class="form-group search-group " v-if="custom_role === '-1'|| custom_role === '0'">

                        <select class="form-control" v-model="params['records_params'].filter.user_id" @change="FilterStart()">
                            <option :value="null"  >{{lang.share_component.select_user}}</option>
                            <option :value="help.user_id" v-for="help in help_desc[4]" :key="help.user_id">{{help.name}}</option>

                        </select>

                    </div>
                <div class="form-group search-group ">
                    <div class="checkbox">
                        <label> <input type="checkbox" v-model="params['records_params'].filter.add_time" >
                            {{lang.share_component.add_date}}</label>
                    </div>
                </div>
                <div class="form-group search-group special-search-group">
                    <label><b><translate-component :text="lang.reports_settings_component.DateTimeFrom"/></b></label>
                    <div class="input-group">

                        <v-date-picker v-model="params['records_params'].filter.dt_from">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                        class="bg-white border px-2 py-1 rounded"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </div>
                </div>
                <div class="form-group search-group special-search-group">
                    <label><b><translate-component :text="lang.reports_settings_component.DateTimeTo"/></b></label>
                    <div class="input-group">

                        <v-date-picker v-model="params['records_params'].filter.dt_to">
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                        class="bg-white border px-2 py-1 rounded"
                                        :value="inputValue"
                                        v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </div>
                </div>

                <div class="form-group search-group special-search-group" style="max-width: 100px">
                    <button type="button" class="btn btn-primary btn-sm " @click="FilterStart()">{{lang.task_component.search}}</button>
                </div>
                </template>

            </header>

            <div class="panel-header-top">
                <ul class="route-setting_list">
                    <li >

                            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                                    :class="page===1 ? 'active' : ''" @click="changePage(1)">
                                <svg-info-icon/>
                                <span class="vehicle-btn-title"><translate-component :text="lang.share_component.records"/></span>
                            </button>
                    </li>
                    <li>
                            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                                    :class="page===2 ? 'active' : ''" @click="changePage(2)">
                                <svg-roue-view-icon/>
                                <span class="vehicle-btn-title"><translate-component :text="lang.share_component.bases"/></span>
                            </button>
                    </li>
                    <li >

                            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                                    :class="page===3 ? 'active' : ''" @click="changePage(3)">
                              <svg-cars-icon/>
                                <span class="vehicle-btn-title"><translate-component :text="lang.share_component.objects"/></span>
                            </button>

                    </li>
                    <li  v-if="custom_role === '-1'|| custom_role === '0'">

                            <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                                    :class="page===4 ? 'active' : ''" @click="changePage(4)">
                                <svg-usermanagment-icon/>
                                <span class="vehicle-btn-title"><translate-component :text="lang.share_component.users"/></span>
                            </button>

                    </li>

                </ul>

            </div>



            <div v-if="!redy_loading" class="global-settings-table">

                <perfect-scrollbar  :class="search_hide ? 'scroll-head-hide': ''">
                    <table class="table table-striped table-hover mb-none table-event"  v-if="page === 1 ">
                        <thead class="event-table-header">
                        <tr>
                            <th >ID</th>
                            <th>{{lang.share_component.created  }}</th>
                            <th>{{lang.events_component.type}}</th>
                            <th>{{lang.share_component.car_id}}</th>
                            <th>{{lang.share_component.car}}</th>
                            <th>{{lang.share_component.user_id}}</th>
                            <th>{{lang.share_component.users}}</th>
                            <th>{{lang.share_component.updated}}</th>
                            <th>{{lang.reports_settings_component.DateTimeFrom }}</th>
                            <th>{{lang.reports_settings_component.DateTimeTo }}</th>
                            <th>{{lang.share_component.duration}}</th>
                            <th class="btn-row-small">{{lang.share_component.canceled}}</th>
                            <th>{{lang.main_component.Comment}}</th>
                            <th class="btn-row-small">{{lang.task_component.actions}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr  class="event-table-list" v-for="record in records_list" :key="record.reservation_id">
                            <td>{{record.reservation_id}}</td>
                            <td>{{record.dt_created.replace('T', ' ').replace('Z', ' ') }}</td>
                            <td>{{record.status }}</td>
                            <td>{{record.object_id}}</td>
                            <td>{{record.name }}</td>
                            <td>{{record.user_id }}</td>
                            <td>{{record.user_name }}</td>
                            <td>{{ record.dt_updated}}</td>
                            <td>{{record.dt_from.replace('T', ' ').replace('Z', ' ') }}</td>
                            <td>{{record.dt_to.replace('T', ' ').replace('Z', ' ')  }}</td>
                            <td>{{getDurationRecord(record.dt_from,record.dt_to)}}</td>
                            <td  class="btn-row" style="text-transform: uppercase">
                                <span style="font-weight: bold;color: #d00f27 " v-if="record.canceled == true">
                                    {{lang.share_component.canceled}}
                                </span>
                                <span  style="font-weight: bold;color: #1AAE88"  v-else > {{lang.share_component.active}}</span>

                            </td>
                            <td>{{record.comment}}</td>
                            <td class="btn-row2">


                                <div class="share-btn-box"   v-if="(custom_role === '-1'|| custom_role === '0') || user_id == record.user_id">
                                    <button type="button" class="btn btn-success button-scroll fa fa-key" @click="openDoor(record.name)" >
                                        <span > {{lang.share_component.open_door}}</span>
                                    </button>

                                    <button type="button" class="btn btn-primary button-scroll fa fa-times" @click="deleteRecord(record.reservation_id)" >
                                        <span > {{lang.main_component.cancel}}</span>
                                    </button>
                                </div>
                            </td>

                        </tr>
                        </tbody>
                    </table>
                    <table class="table table-striped table-hover mb-none table-event"  v-if="page === 2 ">
                        <thead class="event-table-header">
                        <tr>
                            <th >ID</th>
                            <th>{{lang.user_component.Username}}</th>
                            <th>{{lang.main_component.Comment}}</th>
                            <th>{{lang.share_component.created}}</th>
                            <th  v-if="custom_role === '-1'|| custom_role === '0'">{{lang.task_component.actions}}</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="record in base_list" :key="record.base_id">
                            <td>{{record.base_id}}</td>
                            <td>{{record.name}}</td>
                            <td>{{record.comment}}</td>
                            <th>{{record.dt_created | moment("YYYY.MM.DD HH:mm") }}</th>
                            <th  v-if="custom_role === '-1'|| custom_role === '0'">
                                <div class="share-btn-box" >
                                <button type="button" class="btn btn-default button-scroll fa fa-edit" @click="editBase(record.base_id)" >
                                    <span >{{lang.events_component.edit}}</span>
                            </button>

                                <button type="button" class="btn btn-primary button-scroll fa fa-trash-o" @click="deleteBase(record.base_id)" >
                                    <span > {{lang.events_component.delete}}</span>
                                </button>
            </div>
                            </th>


                        </tr>
                        </tbody>
                    </table>
                    <table class="table table-striped table-hover mb-none table-event"  v-if="page === 3 ">
                        <thead class="event-table-header">
                        <tr>
                            <th >ID</th>
                            <th>{{lang.share_component.object_name}}</th>
                            <th>{{lang.main_component.Number}}</th>
                            <th>{{lang.share_component.gearbox}}</th>
                            <th>{{lang.share_component.larger_cargo_short}}</th>
                            <th>{{lang.share_component.base_id}}</th>
                            <th>{{lang.main_component.Comment}}</th>
                            <th class="btn-row-small"  v-if="custom_role === '-1'|| custom_role === '0'">{{lang.task_component.actions}}</th>

                        </tr>
                        </thead>
                        <tbody>
                        <tr  class="event-table-list" v-for="record in object_list" :key="record.object_id">
                            <td>{{record.object_id}}</td>
                            <td>{{record.number}}</td>
                            <td>{{record.name}}</td>
                            <td>
                                <template v-if="record.gearbox ==0">
                                    {{lang.share_component.unknown}}
                                </template>
                                <template v-if="record.gearbox ==1">
                                    {{lang.share_component.manual}}
                                </template>
                                <template v-if="record.gearbox ==2">
                                    {{lang.share_component.automatic}}
                                </template>

                            </td>
                            <td>
                                <template v-if="record.largerCargoArea ==0">
                                    {{lang.share_component.unknown}}
                                </template>
                                <template v-if="record.largerCargoArea ==2">
                                    {{lang.share_component.largerCargoArea_yes}}
                                </template>
                                <template v-if="record.largerCargoArea ==1">
                                    {{lang.share_component.largerCargoArea_no}}
                                </template>

                            </td>

                            <td v-if="record.base_id !== null">
                                {{record.base_id}}
                                <span v-if="help_desc[2] != null"> {{help_desc[2].find(x => x.base_id === record.base_id).name}}</span>
                            </td>
                            <td v-if="record.base_id === null">{{lang.share_component.not_attach}}</td>
                            <td>{{record.comment}}</td>
                            <td  class="btn-row"  v-if="custom_role === '-1'|| custom_role === '0'">

                                <div class="share-btn-box" >
                                    <button type="button" class="btn btn-default button-scroll fa fa-edit" @click="getInfoModal(record.object_id)" >
                                        <span >{{lang.share_component.info}}</span>
                                    </button>

                                    <button type="button" class="btn btn-primary button-scroll fa fa-share" @click="attachBase(record.object_id)" >
                                        <span >{{lang.share_component.attach_base}}</span>
                                    </button>
                                </div>

                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="table table-striped table-hover mb-none table-event"  v-if="page === 4  && (custom_role === '-1'|| _this.custom_role === '0')">
                        <thead class="event-table-header">
                        <tr>
                            <th >ID</th>
                            <th>{{lang.user_component.Username}}</th>
                            <th>{{lang.share_component.base_id}}</th>
                            <th>{{lang.main_component.Comment}}</th>
                            <th class="btn-row-small" v-if="custom_role === '-1'|| custom_role === '0'">{{lang.task_component.actions}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr class="event-table-list" v-for="recorda in user_list" :key="recorda.user_id">
                            <td>{{recorda.user_id}}</td>
                            <td>{{recorda.name}}</td>
                            <td v-if="recorda.base_ids.length > 0 ">

                                <span v-for="items in recorda.base_ids">
                                    {{items}}  <span v-if="help_desc[2] != null"> {{help_desc[2].find(x => x.base_id === items).name}}, </span>
                                </span>

                            </td>
                            <td v-if="recorda.base_ids.length === 0 ">{{lang.share_component.not_attach}}</td>
                            <td>{{recorda.comment}}</td>
                            <td  class="btn-row" v-if="custom_role === '-1'|| custom_role === '0' ">
                                <div class="share-btn-box" >

                                    <button type="button" class="btn btn-default button-scroll fa fa-share" @click="attachUserBase(recorda.user_id)" >
                                        <span > {{lang.share_component.attach_base}}</span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>


                </perfect-scrollbar>


                <div class="panel-footer" >



                    <div class="btn-group-scroll big-group-scroll ">
                        <template  v-if="custom_role === '-1'|| custom_role === '0'">
                            <div class="btn  button-scroll button-scroll-total " @click="createBase()" v-if="page=== 2">
                                <span class="fa fa-edit"></span>{{lang.events_component.create+' '+lang.share_component.bases}}

                            </div>


                            <div class="btn  button-scroll button-scroll-total " @click="createRecord()" v-if="page=== 1">
                                <span class="fa fa-edit"></span>{{lang.events_component.create+' '+lang.share_component.records}}

                            </div>
                        </template>
                        <template v-else>
                            <div class="btn  button-scroll button-scroll-total " @click="createRecordUser()" v-if="page=== 1">
                                <span class="fa fa-edit"></span>{{lang.events_component.create+' '+lang.share_component.records}}

                            </div>
                        </template>






                        <button type="button" class="btn  button-scroll " @click="ScrollList(-1)">
                            <span class="button-scroll-icon button-scroll-icon-left fa fa-chevron-left"></span>
                        </button>
                        <div class="btn  button-scroll button-scroll-center hidelog">
                            <translate-component :text="lang.geo_zone_component.page"/>
                            <span class="page-count"> <input type="number" class="form-control"
                                                             @blur="handlerScrollInput($event)"
                                                             v-on:keyup.enter="handlerScrollInput($event)"
                                                             :value="params[type +'_params'].page" > </span></div>
                        <button type="button" class="btn  button-scroll button-scroll-right" @click="ScrollList(1)"><span
                                class="button-scroll-icon button-scroll-icon-right fa fa-chevron-right"></span></button>
                        <div class="btn  button-scroll button-scroll-total" @click="ScrollList('max')"><translate-component :text="lang.events_component.total_page"/>
                            <span style="padding-left: 5px">{{ params[type +'_params'].totalpages }}</span></div>

                        <div class="btn  button-scroll button-scroll-center button-scroll-total" >
                            <translate-component :text="lang.task_component.limit"/>
                            <span class="page-count"> <select class="form-control" v-model="params[type +'_params'].limit" @change="controlRow()" >
               <option value="5">5</option>
                <option value="10">10</option>
              <option value="25">25</option>
                <option value="50">50</option>
            </select></span></div>

                    </div>



                </div>
            </div>

            <div v-else>
                <div class="loading-box" >
                    <scale-loader :height="loading_size"></scale-loader>
                </div>
            </div>


            <div class="modal-share-content" v-if="info_modal">
                <div class="modal-share-box">
                      <button type="button" class="modal-share-close fa fa-times" @click="closeInfoModal()"></button>
                    <h4> {{lang.share_component.object_extra}}</h4>
                    <div class="modal-share-body">
                        <div class="col-12 form-group">
                            <label>{{lang.share_component.gearbox}}</label>
                            <select class="form-control" v-model="extra.gearbox">
                            <option value="0">{{lang.share_component.unknown}}</option>
                                <option value="1">{{lang.share_component.manual}}</option>
                                <option value="2">{{lang.share_component.automatic}}</option>
                            </select>
                        </div>
                        <div class="col-12 form-group">
                            <label>{{lang.share_component.larger_cargo}}</label>
                            <select class="form-control" v-model="extra.largerCargoArea">
                                <option value="0">{{lang.share_component.unknown}}</option>
                                <option value="1">{{lang.share_component.largerCargoArea_no}}</option>
                                <option value="2">{{lang.share_component.largerCargoArea_yes}}</option>
                            </select>
                        </div>
                        <div class="col-12 form-group">
                            <button type="button" class="btn btn-default" @click="saveInfo()">{{lang.main_component.save}}</button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-share-content" v-if="base_modal">
                <div class="modal-share-box">
                    <button type="button" class="modal-share-close fa fa-times" @click="closeBaseModal()"></button>
                    <h4 v-if="base.id === 0">  {{lang.events_component.create+' '+lang.share_component.bases}} </h4>
                    <h4 v-else>  {{lang.events_component.edit+' '+lang.share_component.base_id}} : {{base.id}} </h4>
                    <div class="modal-share-body">
                        <div class="col-12 form-group">
                            <label>{{lang.user_component.Username}}</label>
                           <input type="text" class="form-control" v-model="base.name">
                        </div>
                        <div class="col-12 form-group">
                            <label>{{lang.main_component.Comment}}</label>
                         <textarea  class="form-control" v-model="base.comment"></textarea>
                        </div>
                        <div class="col-12 form-group">
                            <button type="button" class="btn btn-default" @click="saveBase()">{{lang.main_component.save}}</button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-share-content" v-if="object_base">
                <div class="modal-share-box">
                    <button type="button" class="modal-share-close fa fa-times" @click="closeObjectBaseModal()"></button>
                    <h4 >{{lang.share_component.car_id}}: {{attach_object_base.object_id}}, {{lang.share_component.attach_base}} </h4>

                    <div class="modal-share-body">
                        <div class="col-12 form-group">
                            <label>{{lang.user_component.Username}}</label>
                            <select class="form-control" v-model="attach_object_base.base_id">
                                <option value="0">{{lang.share_component.select+" "+lang.share_component.bases}}</option>
                                <template v-if="help_desc[2] != null && help_desc[2].length > 0">
                                <option :value="item.base_id"  v-for="item in help_desc[2]" :key="item.base_id">{{item.name}}</option>
                                </template>

                            </select>
                        </div>

                        <div class="col-12 form-group">
                            <button type="button" class="btn btn-default" @click="saveObjectBase()">{{lang.main_component.save}}</button>
                        </div>
                    </div>

                </div>
            </div>

            <div class="modal-share-content" v-if="user_base">
                <div class="modal-share-box">
                    <button type="button" class="modal-share-close fa fa-times" @click="closeUserBaseModal()"></button>
                    <h4 >{{lang.share_component.user_id}}: {{attach_user_base.user_id}}, {{lang.share_component.attach_base}} </h4>

                    <div class="modal-share-body">
                        <div class="col-12 form-group">
                            <label>{{lang.user_component.Username}}</label>


                            <div  class="modal-share-chexbox-list" v-for="item in help_desc[2]" :key="item.base_id">
                                <div class="checkbox">
                                    <label> <input type="checkbox"
                                       @click="selectBases(item.base_id)"
                                       :value="item.base_id"

                                                   :checked="attach_user_base.base_ids.includes(item.base_id)"
                                >
                                {{item.name}}</label>
                                </div>
                            </div>




                        </div>

                        <div class="col-12 form-group">
                            <button type="button" class="btn btn-default" @click="saveUserBase()">{{lang.main_component.save}}</button>
                        </div>
                    </div>

                </div>
            </div>


            <div class="modal-share-content" v-if="record_modal">
                <div class="modal-share-box">
                    <button type="button" class="modal-share-close fa fa-times" @click="closeRecordModal()"></button>
                    <h4 v-if="record_params.reservation_id === 0"> {{lang.share_component.reservation_create}}</h4>
                    <h4 v-else> {{lang.share_component.reservation_edit}}</h4>

                    <div class="modal-share-body">

                        <div class="col-12 form-group" v-if="custom_role === '-1'|| custom_role === '0'">
                            <label>{{lang.events_component.user}}</label>

                            <select class="form-control" v-model="record_params.user_id">
                                <option disabled>{{lang.share_component.select_user}}</option>
                                <option :value="help.user_id" v-for="help in help_desc[4]" :key="help.user_id">{{help.name}}</option>


                            </select>
                        </div>


                        <div class="col-12 form-group">
                            <label>{{lang.share_component.car}}</label>

                            <select class="form-control" v-model="record_params.object_id">
                                <option disabled>{{lang.reports_component.select_any_object}}</option>
                                <option :value="helps.object_id" v-for="helps in help_desc[3]" :key="helps.user_id" v-if="helps.base_id != null ">{{helps.name}}</option>
                            </select>
                        </div>


                        <div class="col-12 form-group">
                            <label>{{lang.fuel_table_components.date}} {{lang.reports_settings_component.DateTimeFrom}}</label>

                            <div class="input-group col-6">
                                <input type="date" class="form-control" v-model="record_params.dt_from">

                            </div>

                            <div class="input-group col-6">

                                <input type="time" class="form-control" v-model="record_params.time_from">
                            </div>
                        </div>



                        <div class="col-12 form-group">
                            <label>{{lang.fuel_table_components.date}} {{lang.reports_settings_component.DateTimeTo}}</label>

                            <div class="input-group col-6">

                                <input type="date" class="form-control" v-model="record_params.dt_to">
                            </div>

                            <div class="input-group col-6">
                                <input type="time" class="form-control" v-model="record_params.time_to">

                            </div>
                        </div>






                        <div class="col-12 form-group">
                            <label>{{lang.main_component.Comment}}</label>
                            <textarea  class="form-control" v-model="record_params.comment"></textarea>
                        </div>


                        <div class="col-12 form-group">
                            <button type="button" class="btn btn-default" @click="saveRecord()">{{lang.main_component.save}}</button>
                        </div>
                    </div>

                </div>
            </div>




        </template>




    </section>
</template>
<script>

import SearchHideIcon from "@/components/ui/icons/search/SearchHideIcon.vue";
import SearchShoIcon from "@/components/ui/icons/search/SearchShoIcon.vue";
import SvgEngineIcon from "@/components/ui/icons/svgEngineIcon.vue";
import TranslateComponent from "@/components/dashboard/TranslateComponent.vue";
import axios from "axios";
import SvgShareIcon from "@/components/ui/icons/search/svgShareIcon.vue";
import {DatePicker } from 'v-calendar';
import ScaleLoader from "vue-spinner/src/ScaleLoader.vue";
import {mapGetters} from "vuex";
import SvgRoueViewIcon from "@/components/ui/icons/svgRoueViewIcon.vue";
import SvgImageIcon from "@/components/ui/icons/svgImageIcon.vue";
import SvgInfoIcon from "@/components/ui/icons/svgInfoIcon.vue";
import SvgUsermanagmentIcon from "@/components/ui/icons/svgUsermanagmentIcon.vue";
import SvgCarsIcon from "@/components/ui/icons/svgCarsIcon.vue";
import moment from "moment-timezone";


export default {
    name: "ShareComponent",
    components: {
        SvgCarsIcon,
        SvgUsermanagmentIcon,
        SvgInfoIcon,
        SvgImageIcon,
        SvgRoueViewIcon,
        SvgShareIcon, TranslateComponent, SvgEngineIcon, SearchShoIcon, SearchHideIcon,'v-date-picker':DatePicker,ScaleLoader},
    data() {
        return {
            add_date: new Date(),
            range: {
                start: new Date(2020, 0, 1),
                end: new Date(2020, 0, 5)
            },
            search_hide:false,
            loading_size: "30px",
            redy_loading:false,
            show_icon:true,
            redy_icons:false,
            custom_role: 1,
            page:1,
            params:{
                'records_params':{
                    _search:false,
                    limit:10,
                    direction: 'desc',
                    order: 'dt_created',
                    totalpages:1,
                    page:1,
                    filter:{
                        user_id: null,
                        object_id: null,
                        add_time:false,
                        dt_from: new Date(),
                        dt_to: new Date(),
                    }


                },
                'base_params':{
                    _search:false,
                    limit:10,
                    direction: 'desc',
                    order: 'base_id',
                    totalpages:1,
                    page:1
                },
                'user_params':{
                    _search:false,
                    limit:10,
                    direction: 'desc',
                    order: 'user_id',
                    totalpages:1,
                    page:1
                },
                'object_params':{
                    _search:false,
                    limit:10,
                    direction: 'desc',
                    order: 'object_id',
                    totalpages:1,
                    page:1
                },
            },
            type:"records",
            records_list:[],
            base_list:[],
            user_list:[],
            object_list:[],
            base_modal: false,
            base:{
                id:0,
                name:null,
                comment:null,
            },
            info_modal: false,
            current_object: null,
            extra:{
                gearbox:0,
                largerCargoArea:0
            },
            object_base:false,
            attach_object_base:{
                object_id: 0,
                base_id: 0
            },
            task_url:{
                1:'/share/recordlist',
                2:'/share/baselist',
                3:'/share/objectlist',
                4:'/share/userlist'
            },
            help_desc:{
                1:null,
                2:null,
                3:null,
                4:null,
            },
            user_base:false,
            attach_user_base:{
                user_id: 0,
                base_ids: []
            },
            record_modal:false,
            record_params:{
                reservation_id:0,
                user_id:null,
                object_id:null,
                comment:null,
                dt_from: null,
                time_from: '08:00',
                dt_to: null,
                time_to: '23:59',
            },
            user_id: null
        }
    },
    computed: {
        ...mapGetters({
            lang: 'LANG',
            objects: 'OBJECTS',
            current_resources:'CURRENT_RESOURCES',
        }),
    },

    mounted() {
        this.getMyRole()

    },
    methods: {
        openDoor(record_name){
            let text =    record_name+" : "+this.lang.share_component.open_door_text;
            this.$iosAlert(text);
        },
        FilterStart(){
            if(this.page === 1){
                this.getAllTasks()
            }
        },
        getDurationRecord(dt_from,dt_to){
            var startTime = moment(dt_from, 'YYYY-MM-DDThh:mm:ssZ');
            var endTime = moment(dt_to, 'YYYY-MM-DDThh:mm:ssZ');
            var secondsDiff = endTime.diff(startTime, 'seconds');
            return this.$moment.duration(secondsDiff, 'seconds').humanize()

        },
        editRecord(reservation_id){
            if(this.help_desc[3] === null){
                this.getAllTasks(3)
            }
            if(this.help_desc[4] === null){
                this.getAllTasks(4)
            }

            let item = this.records_list.find(item => item.reservation_id ===reservation_id)


            let time_start_arr = item.dt_from.split("T")
            let time_end_arr = item.dt_to.split("T")


            this.record_params ={
                reservation_id:reservation_id,
                    user_id:item.user_id,
                    object_id:item.object_id,
                    comment:item.comment,
                    dt_from: time_start_arr[0],
                    time_from: time_start_arr[1].replace('Z', ''),
                    dt_to: time_end_arr[0],
                    time_to: time_end_arr[1].replace('Z', ''),
            }

            this.record_modal = true
        },
        deleteRecord(reservation_id){


            let _this = this;
            let message = this.lang.share_component.record_delete
            this.$iosConfirm({
                text: message
            }).then(function () {

                let formData = new FormData()
                formData.append("reservation_id", reservation_id );
                axios.post(base_url + '/share/recordDelete',formData).then(response => {

                    if (response.data.success === true) {
                        let itemIndex = _this.records_list.findIndex(item => item.reservation_id === reservation_id)

                        if (itemIndex != -1) {
                            _this.records_list[itemIndex].canceled = true
                        }



                    }else{

                        let text =    response.data.error
                        _this.$iosAlert(text);

                    }
                }).catch(error => {
                    console.log(error)
                })
            })


        },

        saveRecord(){

            let _this = this;
            let formData = new FormData()

            let add_url =  '/share/recordCreate'
            if(this.record_params.reservation_id != 0){
                add_url =  '/share/recordUpdate'
                formData.append("reservation_id",   _this.record_params.reservation_id);
            }
            formData.append("user_id",   _this.record_params.user_id);
            formData.append("object_id",   _this.record_params.object_id);

            let dt_from = '';
            let   dt_to = '';
            console.log(_this.record_params.time_from.length)
            if(_this.record_params.time_from.length>7){
                dt_from = _this.record_params.dt_from+'T'+_this.record_params.time_from+'Z'
            }else{
                dt_from = _this.record_params.dt_from+'T'+_this.record_params.time_from+':00Z'
            }
            if(_this.record_params.time_to.length>7){

               dt_to = _this.record_params.dt_to+'T'+_this.record_params.time_to+'Z'

            }else{
                dt_to = _this.record_params.dt_to+'T'+_this.record_params.time_to+':00Z'
            }

            formData.append("dt_from",  dt_from);
            formData.append("dt_to",  dt_to);
            formData.append("comment",  _this.record_params.comment);



            axios.post(base_url +add_url,formData).then(response => {
                if (response.data.success === true) {

                    _this.closeRecordModal()

                    _this.getAllTasks()

                }else{
                    let text =    response.data.error
                    _this.$iosAlert(text);

                }

            }).catch(error => {
                console.log(error)
            })

        },
        closeRecordModal(){
            this.record_modal = false

            this.record_params = {
                reservation_id:0,
                    user_id:null,
                    object_id:null,
                    comment:null,
                dt_from: null,
                time_from: '08:00',
                dt_to: null,
                time_to: '23:59',
            }

        },
        createRecord(){
            if(this.help_desc[3] === null){
                this.getAllTasks(3)
            }
            if(this.help_desc[4] === null){
                this.getAllTasks(4)
            }


            this.record_modal = true

        },

        createRecordUser(){
            if(this.help_desc[3] === null){
                this.getAllTasks(3)
            }
            this.record_params.user_id = this.user_id
            this.record_modal = true

        },


        selectBases(id){
            //in here you can check what ever condition  before append to array.
            if(this.attach_user_base.base_ids.includes(id)){


                let index =  this.attach_user_base.base_ids.indexOf(id);
                if(index != -1){
                    this.attach_user_base.base_ids.splice(index, 1)
                }

            }else{
                this.attach_user_base.base_ids.push(id)
            }
        },
        attachUserBase(user_id){
            this.attach_user_base.user_id = user_id
            let item = this.user_list.find(item => item.user_id ===  user_id)
            this.attach_user_base.base_ids = [...item.base_ids]

            if(this.help_desc[2] === null){
                this.getAllTasks(2)
            }

            this.user_base = true
        },
        closeUserBaseModal(){
            this.attach_user_base = {
                user_id: 0,
                base_ids: []
            }
            this.user_base = false
        },
        saveUserBase(){
            let _this = this;
            let formData = new FormData()
            formData.append("user_id",   _this.attach_user_base.user_id);
            let arr = this.attach_user_base.base_ids
            for (let i = 0; i < (arr.length); i++) {
                formData.append("base_ids[]", arr[i])
            }
            axios.post(base_url + '/share/setUserBases',formData).then(response => {

                if (response.data.success === true) {
                    let itemIndex = _this.user_list.findIndex(item => item.user_id ===  _this.attach_user_base.user_id)
                    if(itemIndex != -1){

                        _this.user_list[itemIndex].base_ids = _this.attach_user_base.base_ids
                    }
                    _this.closeUserBaseModal()
                }else{
                    let text =    response.data.error
                    _this.$iosAlert(text);

                }

            }).catch(error => {
                console.log(error)
            })

        },
        saveObjectBase(){

                let _this = this;
                let formData = new FormData()
                formData.append("object_id",   _this.attach_object_base.object_id);
                formData.append("base_id",  _this.attach_object_base.base_id);

                axios.post(base_url + '/share/setobjectbase',formData).then(response => {

                    if (response.data.success === true) {
                        let itemIndex = _this.object_list.findIndex(item => item.object_id === _this.attach_object_base.object_id)
                        if(itemIndex != -1){
                            _this.object_list[itemIndex].base_id = _this.attach_object_base.base_id
                        }
                        _this.closeObjectBaseModal()
                    }else{
                        let text =    response.data.error
                        _this.$iosAlert(text);

                    }

                }).catch(error => {
                    console.log(error)
                })

        },

        closeObjectBaseModal(){
            this.attach_object_base = {
                object_id: 0,
                base_id: 0
            }
            this.object_base = false

        },
        attachBase(object_id){
            this.attach_object_base = {
                object_id: object_id,
                base_id: 0
            }

            if(this.help_desc[2] === null){
                this.getAllTasks(2)
            }

            this.object_base = true


        },
        editBase(base_id){
            let _this = this;
            let item = _this.base_list.find(item => item.base_id ===base_id)
            this.base.id = base_id
            this.base.name = item.name
            this.base.comment = item.comment
            this.base_modal = true
        },
        deleteBase(base_id){

            let _this = this;
            let message = this.lang.share_component.confirm_delete
            this.$iosConfirm({
                text: message
            }).then(function () {

            let formData = new FormData()
            formData.append("base_id", base_id );
            axios.post(base_url + '/share/deletebase',formData).then(response => {

                if (response.data.success === true) {
                        let itemIndex = _this.base_list.findIndex(item => item.base_id === base_id)

                    _this.help_desc[2] = null

                        if (itemIndex != -1) {
                            _this.$delete(_this.base_list, itemIndex)
                        }
                }else{

                    let text =    response.data.error
                    _this.$iosAlert(text);

                }
            }).catch(error => {
                console.log(error)
            })
            })
        },
        closeBaseModal(){
            this.base = {
                id:0,
                name:null,
                comment:null,
            }
            this.base_modal = false
        },
        createBase(){
            this.base_modal = true
        },
        saveBase(){
            let _this = this;
            let formData = new FormData()
            let add_url = '/share/createbase';
            if( _this.base.id  !== 0 ){
                formData.append("base_id",  _this.base.id );
                add_url = '/share/updatebase';
            }

            formData.append("name",   _this.base.name);
            formData.append("comment", _this.base.comment);


            axios.post(base_url + add_url,formData).then(response => {

                if (response.data.success === true) {
                    _this.help_desc[2] = null
                    if( _this.base.id  !== 0 ) {
                        let itemIndex = _this.base_list.findIndex(item => item.base_id === _this.base.id)


                        if (itemIndex != -1) {
                            _this.base_list[itemIndex].name = _this.base.name
                            _this.base_list[itemIndex].comment = _this.base.comment

                        }
                    }else{
                        _this.getAllTasks()
                    }
                    _this.closeBaseModal()

                }else{
                    let text =    response.data.error
                    _this.$iosAlert(text);

                }
            }).catch(error => {
                console.log(error)
            })


        },
        closeInfoModal(){
            this.extra = {
                     gearbox:0,
                    largerCargoArea:0
             }

            this.info_modal = false
        },
        getInfoModal(object_id){
            this.current_object = object_id
            let _this = this;
            let item=  _this.object_list.find(item =>item.object_id ===  _this.current_object)

            this.extra.gearbox =item.gearbox
            this.extra.largerCargoArea =item.largerCargoArea
              this.info_modal = true
        },
        saveInfo(){
            let _this = this;
            let formData = new FormData()
            formData.append("object_id",  _this.current_object);
            formData.append("gearbox",  _this.extra.gearbox);
            formData.append("largerCargoArea",  _this.extra.largerCargoArea);

            axios.post(base_url + '/share/setObjectExtra',formData).then(response => {

                if (response.data.success === true) {

                    let itemIndex =  _this.object_list.findIndex(item =>item.object_id ===  _this.current_object)


                  if(itemIndex != -1){
                      _this.object_list[itemIndex].gearbox  = _this.extra.gearbox
                      _this.object_list[itemIndex].largerCargoArea  = _this.extra.largerCargoArea

                  }
                    _this.closeInfoModal()

                }else{
                    let text =    response.data.error
                    _this.$iosAlert(text);

                }
            }).catch(error => {
                console.log(error)
            })

        },
        changePage(num){
            this.page = num
            if(num === 1){
                this.type = "records"
                if(this.records_list.length === 0){

                    this.getAllTasks();
                }
            }
            if(num === 2){
                this.type = "base"
                if(this.base_list.length === 0){

                    this.getAllTasks();
                }
            }
            if(num === 3){
                this.type = "object"

                if(this.object_list.length === 0){

                    this.getAllTasks();
                }
            }
            if(num === 4){
                this.type = "user"
                if(this.user_list.length === 0){

                    this.getAllTasks();
                }
            }
        },
        getMyRole(){
            let _this = this;

            axios.post(base_url + '/problem/clienthistory').then(response => {
                if (response.data.success === true) {

                    _this.custom_role = response.data.role
                   // _this.custom_role = 1
                        _this.getAllTasks();
                        _this.getAllTasks(2)
                        _this.getAllTasks(3)
                    if(_this.custom_role === '-1'|| _this.custom_role === '0') {
                        _this.getAllTasks(4)
                    }


                    _this.user_id = response.data.user_id


                }else{

                }
            }).catch(error => {
                console.log(error)
            })

        },
        getAllTasks( add_page = null){

            let _this =this;
            let formData = new FormData()
            let task_url ='/share/recordlist'

            if(add_page != null){
                task_url = this.task_url[add_page]
                formData.append("limit",  100);
                formData.append("page", 1);


            }else{
                task_url = this.task_url[this.page]


                formData.append("limit",  _this.params[_this.type +'_params'].limit);
                formData.append("page",  _this.params[_this.type +'_params'].page);
                formData.append("direction",  _this.params[_this.type +'_params'].direction);
                formData.append("order",  _this.params[_this.type +'_params'].order);

                if(this.page === 1){

                    if(_this.params[_this.type +'_params'].filter.user_id > 0 ){
                        formData.append("filter[user_id]",  _this.params[_this.type +'_params'].filter.user_id);
                    }
                    if(_this.params[_this.type +'_params'].filter.object_id > 0 ){
                        formData.append("filter[object_id]",  _this.params[_this.type +'_params'].filter.object_id);
                    }
                    if(_this.params[_this.type +'_params'].filter.add_time === true){

                        let start =  _this.params[_this.type +'_params'].filter.dt_from
                        let end =  _this.params[_this.type +'_params'].filter.dt_to

                        let form_start = moment(start).format("YYYY-MM-DD")
                        form_start = form_start+'T00:00:00Z'
                        let  form_end = moment( end).format("YYYY-MM-DD")
                        form_end = form_end+'T23:59:59Z'
                        formData.append("filter[dt_from]",  form_start);
                        formData.append("filter[dt_to]", form_end);

                    }


                }



                _this.redy_loading = true;

            }


            axios.post(base_url + task_url,formData).then(response => {

                if(response.data && response.data.data){

                    if(response.data.data.length > 0 ) {
                        if(add_page !== null){

                            _this.help_desc[add_page] = response.data.data
                            if(add_page === 3 ){
                                _this.object_list =   _this.help_desc[add_page]
                            }

                        }else{
                            if(_this.page ===1){

                                _this.records_list = response.data.data
                            }
                            if(_this.page ===2){
                                _this.base_list = response.data.data
                            }
                            if(_this.page ===3){
                                _this.object_list = response.data.data
                            }
                            if(_this.page ===4){
                                _this.user_list = response.data.data
                            }
                            _this.params[_this.type +'_params'].totalpages = Math.ceil((response.data.total_count *1) /( _this.params[_this.type +'_params'].limit*1))


                        }

                    }
                }
                _this.redy_loading = false;
            }).catch(error => {
                console.log(error)
            })


        },
        ScrollList(num){
            let old_page =  this.params[this.type +'_params'].page;
            if(num=== 'max'){
                this.params[this.type +'_params'].page =this.params[this.type +'_params'].totalpages
            }else{
                if(this.params[this.type +'_params'].page <= this.params[this.type +'_params'].totalpages) {
                    this.params[this.type +'_params'].page = this.params[this.type +'_params'].page + num

                    if(this.params[this.type +'_params'].page === 0 ){
                        this.params[this.type +'_params'].page =1
                    }
                    if(this.params[this.type +'_params'].page > this.params[this.type +'_params'].totalpages ){
                        this.params[this.type +'_params'].page =this.params[this.type +'_params'].totalpages
                    }
                }
            }
            if(old_page != this.params[this.type +'_params'].page ){
                this.getAllTasks()
            }

        },
        handlerScrollInput(event) {
            let old_page =  this.params[this.type +'_params'].page;

            let num = event.target.value
            if (num > 0 && num <= this.params[this.type +'_params'].totalpages) {
                this.params[this.type +'_params'].page = num

            } else {
                if (num > this.params[this.type +'_params'].totalpages) {
                    this.params[this.type +'_params'].page = this.params[this.type +'_params'].totalpages
                }
                if (num <= 0) {
                    this.params[this.type +'_params'].page = 1
                }
            }

            if(old_page != this.params[this.type +'_params'].page ){
                this.getAllTasks()
            }

        },
        getSearchTable() {
            this.getAllTasks();
        },
        controlRow(){
            this.getAllTasks()
        },
        reloadTable(){
            this.getAllTasks()
        },
        HideSearch(){
            this.search_hide =! this.search_hide;
        },
    }


}
</script>
<style scoped>
.modal-share-content{
    position: fixed;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(12,25,156,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-share-box{
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    background: #ffffff;
    max-width: 500px;
    min-width: 300px;
    height: auto;
    padding: 30px;
    position: relative;
}
.modal-share-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    box-shadow: none;
    width: 30px;
    height: 30px;
    background: transparent;
    font-size: 26px;
}
.share-btn-box .btn span{
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    padding-left: 5px;
}
.share-btn-box{
    display: flex;
    flex-wrap: wrap;
}
.share-btn-box .btn{
    margin-left: 10px;
    margin-bottom: 10px;
}
@media only screen and (max-width: 1200px){
    .modal-body {

        max-height: 66vh;

    }
    .ps {
        max-height: calc(100vh - 240px);
    }
    .modal-dialog {
        width: 90%;

    }
    .modal-dialog.tasks-modal-dialog{
        margin-top: 18%;
    }
}
@media only screen and (max-width: 767px){
    .modal-dialog.tasks-modal-dialog {
        margin-top: 35%;
    }
    .panel.global-settings-panel{
        width: 100%;
        height: calc(100vh - 42px );
        top: 60px;
        position: fixed;
    }
    .ps {
        height:  calc(100vh - 260px );
        max-height:  calc(100vh - 260px );
    }
    .global-settings-table {
        margin-right: 20px;
        margin-left: 20px;
    }
}
@media only screen and (max-width: 600px){


    .ps {

        max-height: calc(100vh - 280px);
    }
    .panel-title{
        padding-bottom: 18px;
    }
    .special-search-group{
        flex-wrap: wrap;
    }
    .input-group.special-search-input {
        flex-wrap: wrap;
    }
    .input-group.special-search-input input{
        width: 80px !important;
    }
    .input-groupspecial-search-input .form-control {
        width: 100px !important;
    }
    .special-search-input .input-group-btn {
        width: 100%;
    }
    .special-search-input .input-group-btn button{
        width: 60px;
        margin-right: 20px;
        border-radius: 0;
        margin-top: 10px;

    }
    .seainput-group-btnrch-group{
        margin: 0 5px;
    }
    .search-group.search-status-group label {
        width: 100px;
    }
    .search-group label {
        width: 100px;
        text-align: start;
    }
    .input-group .form-control{
        width: 124px !important;
    }
    .mobile-search-scroll{
        display: block;
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        box-shadow: none;
        border-color: #1c71da;
        border-radius: 50%;
    }
    .search-scroll-icon{
        color: #1c71da;
    }
    .search-scroll-icon svg{
        fill: #1c71da;
    }
    .vehicle-head.task-head {
        flex-wrap: wrap;
        height: 50px;
        overflow-y:  auto;
        transition: height 0.6s linear;
    }
    .vehicle-head.task-head.scroll-head-hide{
        height:auto;
    }

    .ps.scroll-head-hide {
        height:  calc(100vh - 404px );
        max-height:  calc(100vh - 404px );
    }
    .panel-footer {
        padding: 10px 0;
        margin-top: 0;
    }
    .button-scroll.button-scroll-center.hidelog{
        display: none;
    }
    .button-scroll-center.button-scroll-total span:nth-child(1){
        display: none;
    }
    tr.event-table-list{
        position: relative;
    }
    tr.event-table-list td{
        padding-bottom: 30px !important;
    }
    tr.event-table-list td.btn-row{
             position: absolute;
             top: auto;
             left: 0;
             bottom: 0;
             padding-bottom: 4px !important;
             background-color: transparent;
             border: none;
             box-shadow: none;
         }
    tr.event-table-list td.btn-row2{
        position: absolute;
        top: auto;
        left: 150px;
        bottom: 0;
        padding-bottom: 4px !important;
        background-color: transparent;
        border: none;
        box-shadow: none;
    }

    .btn-row-small{
        width: 0 !important;
        display: none;

    }

    .modal-dialog.tasks-modal-dialog {
        margin-top: 55%;
    }
    .modal-header {
        padding: 10px;
    }
    .modal-footer {
        padding: 10px;
    }
    .modal-body {
        position: relative;
        padding: 10px;
        padding-right: 20px;
        max-height: 70vh;
        overflow-y: auto;
    }
    .search-group{
        margin-bottom: 10px !important;
    }
    .modal-share-box{
        margin-top: 35px;
        max-height: 80vh;
        overflow-y: auto;
        padding-right: 20px;
    }


}



</style>