import { render, staticRenderFns } from "./svgSearchIcon.vue?vue&type=template&id=5d1dc07e&scoped=true&"
import script from "./svgSearchIcon.vue?vue&type=script&lang=js&"
export * from "./svgSearchIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d1dc07e",
  null
  
)

export default component.exports