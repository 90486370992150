<template>
  <div>



    <div class="object-wrapper" :class="!show_object_content ? 'hide-object-wrapper' :''">
      <section class="panel main-panel"  :class="scroll_hide ? 'show-full-object' : ''" ref="panelmain">

        <button type="button" class="btn btn-default hide-button" @click="hideContent()">
            <span class="fas">
            <template v-if="show_object_content">
<svg-close-icon/>
            </template>
 <template v-else>
    <svg-open-panel-icon/>
    </template>


          </span>
        </button>
        <header class="panel-heading panel-heading-transparent "  ref="panelheading" v-if="show_object_content">
          <button type="button"  class="mobile-object-scroll" @click="HideObjectScroll()">
          <span class="search-scroll-icon" v-if="!scroll_hide">
            <search-hide-icon/>
          </span>
            <span class="search-scroll-icon" v-if="scroll_hide">
         <search-sho-icon/>
          </span>
          </button>
          <div class="panel-actions object-header">


            <div class="loading-box">
              <scale-loader :height="loading_size" v-if="object_loading  || object_loading_old"></scale-loader>
            </div>
            <h3 class="object-list-title">


              <span class="label label-primary label-sm text-normal va-middle mr-sm ">
                {{ objects.length }}</span>
              <span class="show-all-objects-btn" @click="changeAllObjects()">
                <template v-if="show_all_objects">
                   <svg-eye-show/>
              </template>
          <template v-else>
             <svg-eye-hide/>
          </template>
            </span>

              <translate-component :text="lang.object_component.object_list"></translate-component>


            </h3>

          </div>

        </header>

        <div class="panel-body object-wrapper-panel-body" v-if="show_object_content">
<div class="first-panel" ref="firstpanel">
          <div class="object-search-panel">
            <div class="object-search-box" v-if="!loading_select">
              <span>{{ searchText }}</span>
              <model-list-select

                :list="old_objects"
                @input="AddProductShow()"
                v-model="old_objects_id"
                option-value="ObjectId"
                :custom-text="ProductName"
                :placeholder="lang.object_component.list_select_text"
                @searchchange="printSearchText"

              >
              </model-list-select>
            </div>
          </div>
          <div class="object-button-panel" >

            <popper trigger="hover">
              <div class="popper">
                <translate-component :text="lang.object_component.tooltip_all"></translate-component>
              </div>
              <div class="object-button-panel__box bottom" slot="reference" @click="FilterObject('all')">
                <div class="object-search-star all-search "><span>ALL</span></div>
              </div>
            </popper>
            <popper trigger="hover">
              <div class="popper">
                <translate-component :text="lang.object_component.tooltip_star"></translate-component>
              </div>
              <div class="object-button-panel__box  bottom" slot="reference">
                <div class="object-search-star" @click=" FilterObject('favorite')">
                  <span class="fas fa-star" :class="search_type === 'favorite' ? 'star-gold': '' "></span>
                </div>
              </div>
            </popper>


            <popper trigger="hover" v-if="active_route === 'routing'">
              <div class="popper">
                <translate-component :text="lang.object_component.tooltip_monitor"></translate-component>
              </div>

              <div class="object-button-panel__box bottom" slot="reference" @click="clearSearch()">
                <div class="object-search-star monitoring-icon" :class="monitoring_icons ? 'active' :''">

                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                       data-icon="bullseye" class="svg-inline--fa fa-bullseye fa-w-16" role="img" viewBox="0 0 496 512">
                    <path fill="currentColor"
                          d="M248 8C111.03 8 0 119.03 0 256s111.03 248 248 248 248-111.03 248-248S384.97 8 248 8zm0 432c-101.69 0-184-82.29-184-184 0-101.69 82.29-184 184-184 101.69 0 184 82.29 184 184 0 101.69-82.29 184-184 184zm0-312c-70.69 0-128 57.31-128 128s57.31 128 128 128 128-57.31 128-128-57.31-128-128-128zm0 192c-35.29 0-64-28.71-64-64s28.71-64 64-64 64 28.71 64 64-28.71 64-64 64z"/>
                  </svg>

                </div>
              </div>
            </popper >

            <popper trigger="hover">
              <div class="popper">
                <translate-component :text="lang.object_component.tooltip_alpha"></translate-component>
              </div>
              <div class="object-button-panel__box bottom" slot="reference" @click="filterObject('alpha')">
                <div class="object-search-star alpha-search">

                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                       data-icon="sort-alpha-down" class="svg-inline--fa fa-sort-alpha-down fa-w-14" role="img"
                       viewBox="0 0 448 512">
                    <path :fill="object_filter === 'alpha' ? '#1C71DA' : '#777'"
                          d="M176 352h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.36 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352zm240-64H288a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h56l-61.26 70.45A32 32 0 0 0 272 446.37V464a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-56l61.26-70.45A32 32 0 0 0 432 321.63V304a16 16 0 0 0-16-16zm31.06-85.38l-59.27-160A16 16 0 0 0 372.72 32h-41.44a16 16 0 0 0-15.07 10.62l-59.27 160A16 16 0 0 0 272 224h24.83a16 16 0 0 0 15.23-11.08l4.42-12.92h71l4.41 12.92A16 16 0 0 0 407.16 224H432a16 16 0 0 0 15.06-21.38zM335.61 144L352 96l16.39 48z"/>
                  </svg>
                </div>
              </div>
            </popper>

            <popper trigger="hover">
              <div class="popper">
                <translate-component :text="lang.object_component.tooltip_speed"></translate-component>
              </div>
              <div class="object-button-panel__box bottom" slot="reference" @click="filterObject('speed')">
                <div class="object-search-star alpha-search">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                       data-icon="tachometer-alt" class="svg-inline--fa fa-tachometer-alt fa-w-18" role="img"
                       viewBox="0 0 576 512">
                    <path :fill="object_filter === 'speed' ? '#1C71DA' : '#777'"
                          d="M288 32C128.94 32 0 160.94 0 320c0 52.8 14.25 102.26 39.06 144.8 5.61 9.62 16.3 15.2 27.44 15.2h443c11.14 0 21.83-5.58 27.44-15.2C561.75 422.26 576 372.8 576 320c0-159.06-128.94-288-288-288zm0 64c14.71 0 26.58 10.13 30.32 23.65-1.11 2.26-2.64 4.23-3.45 6.67l-9.22 27.67c-5.13 3.49-10.97 6.01-17.64 6.01-17.67 0-32-14.33-32-32S270.33 96 288 96zM96 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm48-160c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32zm246.77-72.41l-61.33 184C343.13 347.33 352 364.54 352 384c0 11.72-3.38 22.55-8.88 32H232.88c-5.5-9.45-8.88-20.28-8.88-32 0-33.94 26.5-61.43 59.9-63.59l61.34-184.01c4.17-12.56 17.73-19.45 30.36-15.17 12.57 4.19 19.35 17.79 15.17 30.36zm14.66 57.2l15.52-46.55c3.47-1.29 7.13-2.23 11.05-2.23 17.67 0 32 14.33 32 32s-14.33 32-32 32c-11.38-.01-20.89-6.28-26.57-15.22zM480 384c-17.67 0-32-14.33-32-32s14.33-32 32-32 32 14.33 32 32-14.33 32-32 32z"/>
                  </svg>
                </div>
              </div>
            </popper>

            <template v-if="current_resources && current_resources[20] && current_resources[20].enabled === true">
            <popper trigger="hover" v-if="active_route === 'routing'">
              <div class="popper">
                <translate-component :text="lang.object_component.tooltip_history"></translate-component>
              </div>
              <div class="object-button-panel__box bottom" slot="reference" @click="startHistory()">
                <div class="object-search-star">
                  <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                       :fill="select_history ? '#1C71DA' : '#777'"
                       data-icon="history" class="svg-inline--fa fa-history fa-w-16" role="img" viewBox="0 0 512 512">
                    <path
                      d="M504 255.531c.253 136.64-111.18 248.372-247.82 248.468-59.015.042-113.223-20.53-155.822-54.911-11.077-8.94-11.905-25.541-1.839-35.607l11.267-11.267c8.609-8.609 22.353-9.551 31.891-1.984C173.062 425.135 212.781 440 256 440c101.705 0 184-82.311 184-184 0-101.705-82.311-184-184-184-48.814 0-93.149 18.969-126.068 49.932l50.754 50.754c10.08 10.08 2.941 27.314-11.313 27.314H24c-8.837 0-16-7.163-16-16V38.627c0-14.254 17.234-21.393 27.314-11.314l49.372 49.372C129.209 34.136 189.552 8 256 8c136.81 0 247.747 110.78 248 247.531zm-180.912 78.784l9.823-12.63c8.138-10.463 6.253-25.542-4.21-33.679L288 256.349V152c0-13.255-10.745-24-24-24h-16c-13.255 0-24 10.745-24 24v135.651l65.409 50.874c10.463 8.137 25.541 6.253 33.679-4.21z"/>
                  </svg>
                </div>
              </div>
            </popper>
            </template>
            <template v-if="current_resources && current_resources[30] && current_resources[30].enabled ===true">
            <popper trigger="hover" v-if="active_route === 'routing'" >
              <div class="popper">
                <translate-component :text="lang.object_component.tooltip_reports"></translate-component>
              </div>
              <div class="object-button-panel__box bottom" slot="reference"  @click="getShowReports()">
                <div class="object-search-star polygon-icon" :class="show_reports ? 'active' :''">

                 <svg-reports-icon/>
                </div>
              </div>
            </popper>
            </template>
            <popper trigger="hover">
              <div class="popper">
                <translate-component :text="lang.object_component.tooltip_status"></translate-component>
              </div>
              <div class="object-button-panel__box status  bottom" slot="reference" @click="getShowTypes()">
                <div class="object-search-star four-circles">
                  <span class="four-circles__box hoja-red"></span>
                  <span class="four-circles__box hoja-purple"></span>
                  <span class="four-circles__box hoja-yellow"></span>
                  <span class="four-circles__box hoja-green"></span>
                </div>
              </div>
            </popper>
          </div>
          <template v-if="show_reports && active_route === 'routing'">
            <div class="reports-tools-bar" style="margin-bottom: 10px;">
              <div class="reports-tools-select">
                <select class="form-select report-select" @change="changeReportsType($event)">
                  <template v-if="current_resources && current_resources[34] && current_resources[34].enabled ===true">
                  <option value="daily" selected>{{lang.reports_component.routes2}}</option>
                  </template>
                  <template v-if="current_resources && current_resources[42] && current_resources[42].enabled ===true">
                  <option value="full">{{lang.reports_component.telemetry}}</option>
                  </template>
                  <template v-if="current_resources && current_resources[76] && current_resources[76].enabled ===true">
                  <option value="vid">{{lang.reports_component.vid}}</option>
                  </template>

                </select>
              <p class="reports-tools-title" style="padding-right: 20px;">
              <span class="title"><translate-component
                  :text="lang.object_component.select_history_object"></translate-component></span>
              </p>
              </div>
              <popper trigger="hover">

                <div class="popper">
                  <translate-component :text="lang.object_component.tooltip_reports_settings"></translate-component>
                </div>

                <button type="button" class=" btn btn-default calendar-button  reports-setting-btn"
                        :class="show_reports_settings ? 'active' :''"
                        slot="reference"
                        @click="showSettingsReports()" > <span class="far calendar-icon"><svg-setting-mono/> </span>
                </button>

              </popper>

              <popper trigger="hover">

                <div class="popper">
                  <translate-component :text="lang.object_component.tooltip_reports_calendar"></translate-component>
                </div>

                <button type="button" class="history_object_calendar btn btn-default calendar-button  bottom"
                        :class="set_calendar ? 'active' :''"

                        slot="reference"
                        @click.stop="openCalendar()" >
                     <span class="far calendar-icon">
              <svg-calendar-time-icon></svg-calendar-time-icon>
          </span>
                </button>
              </popper>

            </div>
          </template>
          <ul class="simple-bullet-list mb-xlg" v-if="select_history && active_route === 'routing'">
            <li class="green">
              <span class="title"><translate-component
                :text="lang.object_component.select_history_object"></translate-component></span>
            </li>

          </ul>
          <ul class="simple-user-list history_object_list" v-if="select_history && checkObjectLength(history_array)">
            <li class="history_object_item" v-if="history_loading">
              <div class="loading-box">
                <scale-loader :height="loading_size"></scale-loader>
              </div>
            </li>
            <li v-for="(monitor,key,index) in history_array" class="history_object_item"
                :class="settings_active ===monitor.ObjectId ? 'active selected':''"
                @click="addActiveHistory(monitor.ObjectId)">
              <figure class="image rounded">
                <div class="object-label"
                     :class="status_image[monitor.statusImage]? status_image[monitor.statusImage]: status_image.default_status"
                     @click="zoomIcon(monitor,'mark_'+monitor.ObjectId)"
                >
                  {{ index + 1 }}
                </div>
              </figure>
              <span class="title">{{ monitor.Number }}</span>
              <span class="message truncate">{{ monitor.Name }}</span>


              <div class="history-bnt-box">
                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.object_component.tooltip_route_setting"></translate-component>
                  </div>
                  <button type="button" class="history_object_calendar btn btn-default calendar-button bottom"
                          :class="settings_show ? 'active' :''"
                          slot="reference"
                          @click.stop="showSettings(monitor)">

                     <span class="far calendar-icon">
            <template>
              <svg-settings-icon></svg-settings-icon>
            </template>
          </span>
                  </button>
                </popper>

                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.object_component.tooltip_route_calendar"></translate-component>
                  </div>
                  <button type="button" class="history_object_calendar btn btn-default calendar-button bottom"
                          :class="set_calendar ? 'active' :''"
                          slot="reference"
                          @click.stop="showCalendar(monitor)" style="margin-left: 10px;">

                     <span class="far calendar-icon">
              <svg-calendar-time-icon></svg-calendar-time-icon>

          </span>
                  </button>
                </popper>


                <div class="history_object_action">
                  <a class=" fa fa-times" @click.stop="removeHistoryIcon(monitor.ObjectId)"
                    ></a>
                </div>
              </div>
            </li>
          </ul>

          <div class="four-circles__select" v-if="show_types">

            <button class="btn four-circles__btn hoja-green" type="button" @click="FilterObject('movement')"><span
              class="four-circles__counter">{{ in_movement }}</span>
              <translate-component :text="lang.object_component.in_movement"></translate-component>
            </button>
            <button class="btn  four-circles__btn hoja-red" type="button" @click="FilterObject('stopped')"><span
              class="four-circles__counter">{{ stopped }}</span>
              <translate-component :text="lang.object_component.stopped"></translate-component>
            </button>
            <button class="btn  four-circles__btn hoja-purple" type="button"
                    @click="FilterObject('stopped with engine')"><span
              class="four-circles__counter">{{ stopped_with_engine_on }}</span>
              <translate-component :text="lang.object_component.stopped_with_engine_on"></translate-component>
            </button>
            <button class="btn  four-circles__btn hoja-yellow" type="button" @click="FilterObject('offline')"><span
              class="four-circles__counter">{{ offline }}</span>
              <translate-component :text="lang.object_component.offline"></translate-component>
            </button>

          </div>

          <div class="search-icon-panel">

            <template v-if="checkObjectLength(search_icons) && active_route === 'routing'">
              <section class="panel search-icon-panel">
                <header class="panel-heading search-icon-box">
                  <span style="display: none">{{ help_counter }}</span>
                  <h4 class="panel-title">
                  <span
                    class="label label-primary label-sm text-normal va-middle mr-sm">{{
                      Object.keys(search_icons).length
                    }}</span>
                    <span class="va-middle"><translate-component
                      :text="lang.object_component.monitor_text"></translate-component></span>
                  </h4>
                </header>
                <div class="panel-body" style="display: block;padding:0;">
                  <div class="content search-icon-box">
                    <ul class="simple-user-list">
                      <li v-for="(monitor,key,index) in search_icons">
                        <figure class="image rounded">
                          <div class="object-label"
                               :class="status_image[monitor.statusImage]? status_image[monitor.statusImage]: status_image.default_status"
                               @click="zoomIcon(monitor,'monitor_'+monitor.ObjectId)"
                          >
                            {{ index + 1 }}
                          </div>
                        </figure>
                        <span class="title">{{ monitor.Name }}</span>
                        <span class="message truncate">{{ monitor.Number }}</span>
                        <div class="panel-search-actions">
                          <a class=" fa fa-times"  @click.stop="removeSearchIcon(monitor.ObjectId)"></a>
                        </div>

                      </li>
                    </ul>
                  </div>
                </div>
              </section>
            </template>
          </div>
</div>

          <div class="table-responsive" :class="active_route === 'routing' ? '' :'small-scroll'">
            <perfect-scrollbar :options="{'suppressScrollX' :suppressScrollX,'suppressScrollY' : false}" :class="checkObjectLength(search_icons) ? 'selected' :''" :style="{'height': first_panel_width+'px'}">
              <table class="table table-object  mb-none" >

                <tbody>
                <template v-for="(object,index) in objects">
                  <template v-if="current_group == 0 || (current_group_list[object.ObjectId] )">

                  <template v-if="checkObject(object)">

                    <template v-if="active_route !== 'routing'">
                      <template v-if="active_route === 'events'">
                        <tr :class="select_event.indexOf(object.ObjectId)!== -1   ? 'object-selected' : '' "
                            @click="selectEventObject(object.ObjectId)">
                          <td class="tr-label" :class="monitoring_icons ? 'active' :''">
                            <div class="object-label"
                                 :class="status_image[object.statusImage]? status_image[object.statusImage]: status_image.default_status"
                            >
                              {{ index + 1 }}
                            </div>
                          </td>
                          <td>
                            <div class="object-text">
                              <div class="object-text__title">{{ object.Name }}</div>
                              <div class="object-text__subitle">{{ object.Number }}</div>
                              <div class="object-text__addtext" v-if=" object.DriverName"><span>{{
                                  object.DriverName
                                }}</span></div>
                            </div>
                          </td>
                          <td><span class="object-time">{{ formatData(object.Unixdt + '000') }}</span></td>
                          <td class="last-object-list">

                            <div class="object-icon" >
                              <span class="object-icon-gear" @click.stop="showVehicleSettings(object.ObjectId)"><svh-gear-icon/></span>
                              <img class="ObjectIcon"
                                   :src="'/tracking/images/icons/object_types/4type('+ object.icon+').svg' "
                                   alt="icon" v-if="object.billingEnabled"/>
                              <span>{{ object.speed + ' ' }} km/h</span>

                            </div>

                          </td>
                        </tr>
                      </template>
                      <template v-else>

                      <tr :class="select_fuel.ObjectId == object.ObjectId   ? 'object-selected' : '' "
                          @click="selectFuelObject(object)" v-if="object['SensorType'] && (object['SensorType'] > 0 && object['SensorType'] < 6)">
                        <td class="tr-label" :class="monitoring_icons ? 'active' :''">
                          <div class="object-label"
                               :class="status_image[object.statusImage]? status_image[object.statusImage]: status_image.default_status"
                          >
                            {{ index + 1 }}
                          </div>
                        </td>
                        <td>
                          <div class="object-text">
                            <div class="object-text__title">{{ object.Name }}</div>
                            <div class="object-text__subitle">{{ object.Number }}</div>
                            <div class="object-text__addtext" v-if=" object.DriverName"><span>{{
                                object.DriverName
                              }}</span></div>
                          </div>
                        </td>
                        <td><span class="object-time">{{ formatData(object.Unixdt + '000') }}</span></td>
                        <td class="last-object-list">

                          <div class="object-icon" >
                            <template v-if="current_resources && current_resources[520] && current_resources[520].enabled ===true">
                            <span class="object-icon-gear" @click.stop="showVehicleSettings(object.ObjectId)"><svh-gear-icon/></span>
                            </template>
                            <img class="ObjectIcon"
                                 :src="'/tracking/images/icons/object_types/4type('+ object.icon+').svg' "
                                 alt="icon" v-if="object.billingEnabled"/>
                            <span>{{ object.speed + ' ' }} km/h</span>

                          </div>

                        </td>
                      </tr>

                      </template>
                    </template>


                    <template v-else>
                      <tr :class="[selected_objects[object.ObjectId] ?'object-selected' : '',(show_reports && reports_select[object.ObjectId] ) ? 'active-report' :'']" @click="startCenter(object)">
                        <td class="tr-label" :class="monitoring_icons ? 'active' :''">
                          <div class="object-label"
                               :class="status_image[object.statusImage]? status_image[object.statusImage]: status_image.default_status"
                          >
                            {{ index + 1 }}
                          </div>
                        </td>
                        <td>
                          <div class="object-text">
                            <div class="object-text__title">{{ object.Name }}</div>
                            <div class="object-text__subitle">{{ object.Number }}</div>
                            <div class="object-text__addtext" v-if=" object.DriverName"><span>{{
                                object.DriverName
                              }}</span></div>
                          </div>
                        </td>
                        <td>
                          <div class="object-star__box" @click.stop="addFavorit(index)"><span class="fas fa-star"
                                                                                      :class="object.is_favorite? 'star-gold': '' "></span>
                          </div>
                        </td>
                        <td><span class="object-time">{{ formatData(object.Unixdt + '000') }}</span></td>
                        <td class="last-object-list">

                          <div class="object-icon" >
                            <span class="object-icon-gear" @click.stop="showVehicleSettings(object.ObjectId)"><svh-gear-icon/></span>
                            <img class="ObjectIcon"
                                 :src="'/tracking/images/icons/object_types/4type('+ object.icon+').svg' "


                                 alt="icon" v-if="object.billingEnabled"/>
                            <span>{{ object.speed + ' ' }} km/h</span>


                          </div>

                        </td>
                      </tr>
                    </template>

                  </template>

                  </template>

                </template>
                </tbody>

              </table>
            </perfect-scrollbar>
          </div>

        </div>
      </section>
    </div>

  </div>
</template>

<script>

import {mapGetters} from "vuex";
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import axios from "axios";
import {ModelListSelect} from 'vue-search-select';
import moment from 'moment-timezone'
import TranslateComponent from "@/components/dashboard/TranslateComponent";

import DatePicker from "@/components/vue3calendar/calendar/DatePicker";
import GroupListComponent from "@/components/routes/GroupListComponent";
import SvgCalendarTimeIcon from "@/components/ui/icons/svgCalendarTimeIcon";
import SvgSettingsIcon from "@/components/ui/icons/svgSettingsIcon";
import Popper from 'vue-popperjs';
import SvgClockIcon from "@/components/ui/icons/svgClockIcon";
import SvgOpenPanelIcon from "@/components/ui/icons/svgOpenPanelIcon";
import SvgCloseIcon from "@/components/ui/icons/svgCloseIcon";
import zoomIcon from "@/mixins/zoomIcon";
import helpers from "@/mixins/helpers";
import calendarStart from "@/mixins/calendarStart";
import SvgEyeShow from "@/components/ui/icons/svgEyeShow";
import SvgEyeHide from "@/components/ui/icons/svgEyeHide";
import SvgSettingMono from "@/components/ui/icons/svgSettingMono";
import SvgReportsIcon from "@/components/ui/icons/svgReportsIcon";
import SvhGearIcon from "@/components/ui/icons/svhGearIcon";
import SearchHideIcon from "@/components/ui/icons/search/SearchHideIcon";
import SearchShoIcon from "@/components/ui/icons/search/SearchShoIcon";


export default {
  name: "ObjectComponent",
  mixins: [zoomIcon, helpers, calendarStart],
  props: {
    show_custom_panel: {
      type: Boolean,
      default: true,
    }
  },
  components: {
    SearchShoIcon,
    SearchHideIcon,
    SvhGearIcon,
    SvgReportsIcon,
    SvgSettingMono,
    SvgEyeHide,
    SvgEyeShow,
    SvgCloseIcon,
    SvgOpenPanelIcon,
    SvgClockIcon,
    SvgSettingsIcon,
    SvgCalendarTimeIcon,
    ScaleLoader,
    ModelListSelect,
    TranslateComponent,
    DatePicker,
    GroupListComponent,
    'popper': Popper
  },
  data() {
    return {
      scroll_hide:false,
      first_panel_width:400,
      disabledDates:{
        from: new Date(Date.now() + 8640000)
      },
      suppressScrollX:true,
      loading_select:true,
      reload_map:10000,
      select_fuel: {'ObjectId':0, 'Number':0},
      dataChange: {},
      message_tooltips: "Tooltips test",
      select_history_counter: 0,
      help_counter: 0,
      monitoring_show: false,
      monitoring_icons: false,
      searchText: '',
      search_type: 'all',
      in_movement: 0,
      stopped: 0,
      stopped_with_engine_on: 0,
      offline: 0,
      show_types: false,
      show_groups: true,
      inline_style: true,

      show_calendar: false,
      change_select: 1,
      old_objects_id: '',
      base_url: base_url,
      loading: true,
      loading_size: "18px",
      status_image: {
        i0s0: 'hoja-red',
        i1s1: 'hoja-green',
        i1s0: 'hoja-purple',
        '0000': 'hoja-grey',
        default_status: 'hoja-yellow'
      },
      show_list: null,
      old_objects: [],
      object_loading: false,
      work_objects: [],
      selected_objects: {},
      panelmain:400,
      select_event:[],
    }
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      objects: 'OBJECTS',
      show_object_list: 'SHOW_OBJECT_LIST',
      lang_short: 'LANG_SHORT',
      search_icons: 'SEARCH_ICONS',
      group_list: 'GROUP_LIST',
      show_geo_zone: 'SHOW_GEO_ZONE',
      object_filter: 'OBJECT_FILTER',
      select_history: 'SELECT_HISTORY',
      history_array: 'HISTORY_ARRAY',
      settings_show: 'SETTINGS_SHOW',
      settings_options: 'SETTINGS_OPTIONS',
      settings_active: 'SETTINGS_ACTIVE',
      history_time: 'HISTORY_TIME',
      set_calendar: 'ADD_CALENDAR',
      history_loading: 'HISTORY_LOADING',
      history_data: 'HISTORY_DATA',
      show_object_content: 'SHOW_OBJECT_CONTENT',
      show_all_objects: 'SHOW_ALL_OBJECTS',
      object_start:'OBJECT_START',
      default_params:'DEFAULT_PARAMS',
      show_reports:"SHOW_REPORTS",
      reports_select:"REPORTS_SELECT",
      active_route: "ACTIVE_ROUTE",
      table_show:'HISTORY_TABLE_SHOW',
      select_fuel_data:'SELECT_FUEL_DATA',
      object_loading_old:'OBJECTS_LOADING',
      show_reports_settings:'SHOW_REPORTS_SETTINGS',
      reports_cookie:'REPORTS_COOKIE',
      show_reports_list:'SHOW_REPORTS_LIST',
      select_type:'SELECT_TYPE',
      report_type:'REPORTS_TYPE',
      vehicle_settings: 'VEHICLE_SETTINGS',
      show_events_list:'SHOW_EVENTS_LIST',
      current_group: 'CURRENT_GROUP',
      current_group_list: 'CURRENT_GROUP_LIST',
      current_resources:'CURRENT_RESOURCES',
    }),
  },

  watch: {
    reports_cookie(newVal, oldVal){
      if(newVal !== oldVal){
        this.getReports()

      }
    },
    history_time(newVal, oldVal) {
      this.$store.dispatch('getHistoryData')
    },
  },
  mounted() {
    this.panelmain = this.$refs.panelmain.clientHeight
    this.checkPanelSize()
    this.object_loading = false
    this.$store.commit('SET_OBJECTS_LOADING', true)
    this.getObjects()
    if(this.active_route !== 'routing'){
      this.$store.commit('SET_SHOW_OBJECT_LIST', null)
    }

  },

  methods: {
    HideObjectScroll(){
      this.scroll_hide = !this.scroll_hide
       if(this.scroll_hide === true){
         this.first_panel_width =   228
       }else{

         this.first_panel_width =   128
       }

    },
    showVehicleSettings(ObjectId){
      let add = false
      if(this.vehicle_settings === false){
        add = true
        this.$store.commit('SET_CURRENT_OBJECT', ObjectId)
      }
      this.$store.commit('SET_VEHICLE_SETTINGS', add)
    },
    checkPanelSize(){
      let $this = this
      setTimeout(function () {
        let firstpanel = $this.$refs.firstpanel.clientHeight
        let panelheading = $this.$refs.panelheading.clientHeight
        let rezult = $this.panelmain - (firstpanel + panelheading + 30)
        if( $this.scroll_hide == true){
          $this.first_panel_width = rezult+100
        }else{
          $this.first_panel_width = rezult
        }


      },100)
    },
    changeReportsType(event){
      let add = event.target.value
      this.$store.commit('SET_REPORTS_TYPE', add);
    },
    showSettingsReports(){
      let add =true
      if(this.show_reports_settings === true){
        add = false
      }
      this.$store.commit('SET_SHOW_REPORTS_SETTINGS', add);
    },
    changeAllObjects() {
      let change = false
      if (this.show_all_objects === false) {
        change = true
      }
      this.$store.commit('SET_SHOW_ALL_OBJECTS', change)
    },
    selectEventObject(ObjectId){
      let add = {...this.show_events_list}
      if(this.select_event.indexOf(ObjectId)!== -1 ){
        let index = this.select_event.indexOf(ObjectId);
        if (index > -1) {
          this.select_event.splice(index, 1);
          this.$delete(add, ObjectId)
        }
      }else{
        this.select_event.push(ObjectId)
        add[ObjectId] = ObjectId
      }
      this.$store.commit('SET_SHOW_EVENTS_LIST', add)
    },
    selectFuelObject(object) {
      if(this.select_fuel_data.hasOwnProperty(object.ObjectId)) {
        this.$store.commit('SET_SHOW_CALENDAR_PANEL', false)
      }else{
        this.$store.commit('SET_SHOW_CALENDAR_PANEL', true)
      }
      if( this.table_show === true){
        this.$store.commit('SET_HISTORY_TABLE_SHOW',false)
        this.$store.commit('SET_RELOAD_MAP', Math.random());
      }

      this.getVichelSettings(object.ObjectId,object)

      this.select_fuel.ObjectId = object.ObjectId
      this.select_fuel.Number= object.Number

      this.$store.commit('SET_SETTINGS_FUEL_ACTIVE', object.ObjectId);
      this.$store.commit('SET_SELECT_FUEL', this.select_fuel)
      let $this = this
      setTimeout(function () {
        if($this.select_fuel_data[object.ObjectId]){
          $this.$store.commit('SET_HISTORY_TABLE_SHOW', true);
          $this.$store.commit('SET_UPDATE_POLYLINE', {'type':2,'counter':Math.random()});
        }else{

        }

      }, 200);

    },
    closeCalendar() {
      this.$store.commit('SET_ADD_CALENDAR', false);
    },
    openCalendar() {
      if(this.set_calendar === false){
        this.$store.commit('SET_ADD_CALENDAR', true);
      }else{
        this.$store.commit('SET_ADD_CALENDAR', false);
      }

    },
    addActiveHistory(ObjectId) {
      this.$store.commit('SET_ADD_CALENDAR', false);
      this.$store.commit('SET_HISTORY_TABLE_SHOW', false);
      this.$store.commit('SET_RELOAD_MAP', Math.random());
      this.$store.commit('SET_SETTINGS_ACTIVE', ObjectId);
      if (this.history_data[ObjectId]) {
        let $this = this
        setTimeout(function () {
          $this.$store.commit('SET_HISTORY_TABLE_SHOW', true);
          $this.$store.commit('SET_ADD_CALENDAR', true);
        }, 100);
        setTimeout(function () {
          $this.$store.commit('SET_UPDATE_POLYLINE', {'type': 1, 'counter': Math.random()});
        }, 300);
      }

    },
    showSettings(monitor) {
      if(this.settings_show === false){
        this.$store.commit('SET_SETTINGS_SHOW', false);
        if (!this.settings_options[monitor.ObjectId]) {
          let add = {...this.settings_options}
          add[monitor.ObjectId] = {}
          this.$store.commit('SET_SETTINGS_OPTIONS', add);

        }
        this.$store.commit('SET_SETTINGS_ACTIVE', monitor.ObjectId);

        let $this = this
        setTimeout(function () {
          $this.$store.commit('SET_SETTINGS_SHOW', true);
        }, 100);

      }else{
        this.$store.commit('SET_SETTINGS_SHOW', false);
      }


    },
    startHistory() {
      let add = true
      if (this.select_history === true) {
        add = false
        this.reload_map++
        this.$store.commit('SET_RELOAD_MAP', this.reload_map)
      }else{
        this.$store.commit('SET_SELECT_TYPE', 'history')
        this.monitoring_icons = false
        this.$store.commit('SET_SHOW_ONLY_SEARCH', 0);
      }
      this.$store.commit('SET_ADD_CALENDAR', false);
      this.$store.commit('SET_SHOW_REPORTS_SETTINGS', false);
      this.$store.commit('SET_SHOW_REPORTS', false);
      this.$store.commit('SET_SELECT_HISTORY', add);
      this.reload_map++
      this.$store.commit('SET_RELOAD_MAP', this.reload_map);
      this.checkPanelSize()
    },
    filterObject(type) {
      let add = type
      if (this.object_filter === type) {
        add = 'all'
      }
      this.$store.commit('SET_OBJECT_FILTER', add);

      if (add === 'all') {
        this.object_loading = true
        let self = this
        setTimeout(function () {
          self.object_loading = false
        }, 1000);
      }
    },
    removeSearchIcon(ObjectId) {

     let message = this.lang.object_component.confirm_monitoring;
      let $this = this
      this.$iosConfirm({
        text: message
      }).then(function () {
        $this.deleteSearcheObject(ObjectId);
        $this.checkPanelSize();
      })


    },
    deleteSearcheObject(ObjectId) {
      let $this = this

      this.$store.commit('SET_START_SEARCH', false);
      let change_arr = {...$this.search_icons};
      this.$delete(change_arr,ObjectId);
      setTimeout(() => {

       //  change_arr[ObjectId] = null;
       // $this.$store.commit('SET_SEARCH_ICONS', change_arr);

        $this.$store.commit('SET_SEARCH_ICONS', change_arr);
        $this.$store.commit('SET_START_SEARCH', true);
      }, 500)
    },
    clearSearch() {
      if (this.monitoring_icons === false) {
        this.monitoring_icons = true
        this.$store.commit('SET_ADD_CALENDAR', false);
        this.$store.commit('SET_SHOW_REPORTS_SETTINGS', false);
        this.$store.commit('SET_SHOW_REPORTS', false);
        this.$store.commit('SET_SELECT_HISTORY', false);
        this.$store.commit('SET_SELECT_TYPE', "history");
        this.$store.commit('SET_SHOW_ONLY_SEARCH', 1);

      } else {
        this.monitoring_icons = false
        this.$store.commit('SET_SHOW_ONLY_SEARCH', 0);
      }
      this.checkPanelSize()
    },
    checkObjectLength(obj) {
      if (Object.keys(obj).length > 0) {
        return true
      } else {
        return false
      }
    },
    startCenter(obj) {
      if(this.show_reports){
        let add_reports =  {... this.reports_select}
        if( add_reports[obj.ObjectId]){
          this.$delete(add_reports, obj.ObjectId)
        }else{
          add_reports[obj.ObjectId] = obj.ObjectId
        }
        this.$store.commit('SET_REPORTS_SELECT',  add_reports)
        this.checkPanelSize()
      }else {
        if (this.monitoring_icons && !this.select_history) {

          let objectId = obj.ObjectId
          if (!this.search_icons[objectId]) {
            if (Object.keys(this.search_icons).length <= 1) {

              let change_arr = this.search_icons;
              let change_center = [];
              change_center.push(obj.Y);
              change_center.push(obj.X);


              obj.polyline = {}
              obj.polyline.latlngs = []
              obj.polyline.latlngs.unshift(change_center)
              obj.polyline.color = "#2962FF"
              obj.polyline.opacity = 0.8
              obj.start_time = moment().format("DD.MM.YYYY HH:mm:ss")
            //  obj.start_time = moment().startOf('day').format("DD.MM.YYYY HH:mm:ss")
            //  obj.start_time = moment().format("X");
              this.$set(change_arr, objectId, obj)
              this.$store.commit('SET_SEARCH_ICONS', change_arr);

              this.help_counter++;
            } else {
              let message = this.lang.object_component.version_monitoring_objects;
              this.$iosAlert(message);

            }
          }
          this.checkPanelSize()
        }
        if (this.select_history) {

          if (Object.keys(this.history_array).length < 3) {

            let objectId = obj.ObjectId
            if (!this.history_array[objectId]) {
              this.getVichelSettings(objectId, obj);
            }
            this.$store.commit('SET_SETTINGS_ACTIVE', obj.ObjectId)
            this.$store.commit('SET_HISTORY_TABLE_SHOW', false)
            this.$store.commit('SET_ADD_CALENDAR', true)

          } else {
            let message = this.lang.object_component.version_add_history
            this.$iosAlert(message);
          }
          this.checkPanelSize()
        }
        if (!this.monitoring_icons && !this.select_history) {

          this.zoomIcon(obj,'mark_'+obj.ObjectId);

          this.help_counter++;
        }
      }
    },
    removeHistoryIcon(ObjectId) {

          if(this.settings_active ===ObjectId){
            if(Object.keys(this.history_array).length <2) {
              this.$store.commit('SET_SELECT_HISTORY', false);

              setTimeout(() => {
                this.$store.commit('SET_RELOAD_MAP', Math.random());
              }, 200)
            }else{
              this.$store.commit('SET_SETTINGS_ACTIVE', '');
            }

          }
      if (this.history_array[ObjectId]) {
        let add = {...this.history_array}
        this.$delete(add, ObjectId);
        this.select_history_counter++
        this.$store.commit('SET_HISTORY_ARRAY', add);
       // let datas = {...this.history_data}
       // this.$delete(datas, ObjectId);
        //this.$store.commit('SET_HISTORY_DATA', add);
       // let time = {...this.history_time}
      //  this.$delete(time, ObjectId);
     //   this.$store.commit('SET_HISTORY_TIME', time);
      }
      this.checkPanelSize();
    },
    getVichelSettings(objectId,obj){

      let check_option = {...this.settings_options}

      if(check_option[objectId] && this.checkObjectLength(check_option[objectId])){
      }
      else {

        let arr = [
          'reports_minStopTime', 'reports_maxStopDistance', 'reports_skipMinDistance',
          'reports_chkByIgnition', 'routes_chkPlayer', 'routes_autostart',
          'routes_catchMarker', 'routes_showSpeedometer', 'routes_showTimePointsEnabled',
          'routes_chkSpeedLimit', 'routes_showTimePointsMinutes', 'routes_strokeOpacity',
          'routes_strokeSmooth', 'routes_strokeWeight', 'routes_minSpeed',
          'routes_maxSpeed', 'routes_strokeColor',
          'routes_speedLimit', 'routes_strokeBorderOpacity', 'routes_timePointsValue',
          'routes_strokeBorderColor', 'routes_show_direction',
          'routes_color_direction', 'mapShowAdditionalInfo',
          'route_marker_color'
        ]

        let arr_2 = [
          5, 50, 10,
          0, 0, 0,
          0, 0, 1,
          1, 5, 0.5,
          0, 7, 0,
          90, "16a765",
          1, 1, 0,
          "fa573c", 1,
          "3366E6", 1,
          "2979FF"
        ]
        let formData = new FormData();
        formData.append("object_id", objectId)
        for (let i = 0; i < arr.length; i++) {

          formData.append("parameters[" + i + "][0]", arr[i])
          formData.append("parameters[" + i + "][1]", arr_2[i])
        }
        let $this = this
        axios.post(base_url + '/objects/read-parameters', formData).then(res => {
          let add = this.default_params
          if (res.data.success === true) {
            let check = res.data.data
            add = {
              minStopTime: check.reports_minStopTime,
              maxStopDistance: check.reports_maxStopDistance,
              skipMinDistance: check.reports_skipMinDistance,
              chkByIgnition: check.reports_chkByIgnition,
              chkPlayer: check.routes_chkPlayer,
              player_autostart: check.routes_autostart,
              catch_marker: check.routes_catchMarker,
              show_speedometer: check.routes_showSpeedometer,
              showTimePointsEnabled: check.routes_showTimePointsEnabled,
              showTimePointsMinutes: check.routes_showTimePointsMinutes,
              use_speed_limit: check.routes_chkSpeedLimit,
              use_arrow_show: check.routes_show_direction,
              arrow_colorpicker: {
                "hex": "#" + check.routes_color_direction,
                "source": "hex"
              },
              colorpicker: {
                "hex": "#" + check.routes_strokeBorderColor,
                "source": "hex"
              },
              speedLimit: check.routes_speedLimit,
              strokeBorderOpacity: check.routes_strokeBorderOpacity,
              stroke_colorpicker: {
                "hex": "#" + check.routes_strokeColor,
                "source": "hex"
              },
              strokeOpacity: check.routes_strokeOpacity,
              strokeSmooth: check.routes_strokeSmooth,
              strokeWeight: check.routes_strokeWeight,
              mapShowRegionBorders: false,
              mapShowAdditionalInfo: check.mapShowAdditionalInfo,
              mapShowWeather: false,
              mapShowMiniMap: false,
              routes_speed: [check.routes_minSpeed, check.routes_maxSpeed],
              googleMapTypeId: check.googleMapTypeId,
              route_marker_color: {
                "hex": "#" + check.route_marker_color,
                "source": "hex"
              },
            }


          }
          let new_option = {...this.settings_options}
          new_option[objectId] = add
          this.$store.commit('SET_SETTINGS_OPTIONS', new_option);

          if (this.active_route !== 'fuel2') {
            let history_add = {...this.history_array}

            this.$set(history_add, objectId, obj)
            this.select_history_counter++
            this.$store.commit('SET_HISTORY_ARRAY', history_add);
          }

        }).catch(error => {
          console.log(error)
        })

      }
    },
    printSearchText(searchText) {
      this.searchText = searchText
      if (this.searchText!= null &&  this.searchText.length > 1) {
        this.getObjectsList(this.searchText)
      }else{
        this.$store.commit('SET_SHOW_OBJECT_LIST', '');
      }

    },
    async getObjectsList(searchText) {
      let $this = this
      let change_arr = {}
      change_arr['Select'] = searchText
      await $this.$store.commit('SET_SHOW_OBJECT_LIST', change_arr);
    },
    getShowTypes() {
      if (this.show_types === true) {
        this.show_types = false
      } else {
        let new_in_movement = 0;
        let new_stopped = 0;
        let new_stopped_with_engine_on = 0;
        let new_offline = 0;

        this.objects.map((item) => {
          if (item.statusImage === '0000' || item.statusImage === 'problem') {
            new_offline++;
          }
          if (item.statusImage === 'i1s1') {
            new_in_movement++;
          }
          if (item.statusImage === 'i0s0') {
            new_stopped++;
          }
          if (item.statusImage === 'i1s0') {
            new_stopped_with_engine_on++;
          }

        })
        this.offline = new_offline;
        this.in_movement = new_in_movement;
        this.stopped = new_stopped;
        this.stopped_with_engine_on = new_stopped_with_engine_on;
        this.show_types = true;
      }
      this.checkPanelSize()
    },
    checkObject(obj) {

      if( obj.auth && obj.auth === false){
        $this.$store.commit('SET_USER_AUTH', false);
      }
      if (this.show_object_list == null) {
        return true
      } else {
        let add_key = Object.keys(this.show_object_list)[0]

        if (add_key === 'Select') {
          let query = this.show_object_list[add_key].toLowerCase()
          if (obj.Name.toLowerCase().includes(query)
            || obj.Number.toLowerCase().includes(query)
            || (obj.DriverName && obj.DriverName.toLowerCase().includes(query))
          ) {
            return true
          } else {
            return false
          }
        }

        if (this.show_object_list[add_key] === '0000') {
          if (obj[add_key] == '0000' || obj[add_key] === 'problem') {
            return true
          } else {
            return false
          }
        } else {
          if (obj[add_key] === this.show_object_list[add_key]) {
            return true
          } else {
            return false
          }
        }
      }

    },
    FilterObject(type) {

      //this.$store.commit('SET_MAP_ZOOM', 8);

      let $this = this

      let change_arr = {}
      if (type === 'all') {
        this.search_type = 'all';
        change_arr = null
        $this.$store.commit('SET_CURRENT_GROUP', 0);
        $this.$store.commit('SET_CURRENT_GROUP_LIST', {});

      }
      if (type === 'favorite') {

        if (this.search_type === 'favorite') {
          this.search_type = 'all';
          change_arr = null
        } else {
          this.search_type = 'favorite';
          change_arr['is_favorite'] = true
        }
      }
      if (type === 'movement') {
        this.search_type = 'movement';
        change_arr['statusImage'] = 'i1s1'
      }
      if (type === 'stopped') {
        this.search_type = 'stopped';
        change_arr['statusImage'] = 'i0s0'
      }
      if (type === 'stopped with engine') {
        this.search_type = 'stopped with engine';
        change_arr['statusImage'] = 'i1s0'
      }
      if (type === 'offline') {
        this.search_type = 'offline';
        change_arr['statusImage'] = '0000'
      }

      setTimeout(function () {
        $this.$store.commit('SET_SHOW_OBJECT_LIST', change_arr);
        $this.$store.dispatch('getObjectGroups');


      }, 100);

      if(type === 'favorite' || type === 'all') {
        setTimeout(function () {
          $this.$store.commit('SET_BOUND_CLUSTER', Math.random());
        }, 300);
      }

    },
    getShowGroups() {
      this.show_groups = !this.show_groups
    },
    showCalendar(object) {
      let add = true
      if(this.set_calendar === true){
       add = false
      }else{
        this.$store.commit('SET_SETTINGS_ACTIVE', object.ObjectId);
      }
      this.$store.commit('SET_ADD_CALENDAR', add);
    },
    AddProductShow() {
      let $this = this
      let change_arr = {}

      if(this.old_objects_id.includes("-g")){

        let add_current_group =this.old_objects_id.replace("-g", "");

    if(add_current_group != this.current_group){
        let formData = new FormData()
        formData.append("groupId", add_current_group);

        axios.post(base_url + '/objectgroup/objectlist', formData).then(response => {
          if (response.data && response.data.success === true && response.data.data) {
            let cur = response.data.data;
            let result = [];

            cur.forEach(v => {
              result[''+v.objectId+''] = v;
            })
            let add = result

            $this.$store.commit('SET_CURRENT_GROUP_LIST', add);
            $this.$store.commit('SET_CURRENT_GROUP', add_current_group);
          }


        }).catch(error => {
          console.log(error)
        })
      setTimeout(function () {

      }, 1000);
    }
      }else{
        $this.$store.commit('SET_CURRENT_GROUP', 0);
        $this.$store.commit('SET_CURRENT_GROUP_LIST', {});
        change_arr['ObjectId'] = this.old_objects_id
        setTimeout(function () {
          $this.$store.commit('SET_SHOW_OBJECT_LIST', change_arr);
        }, 100);
      }
    },
    ProductName(item) {
      let name = ''
      item.DriverName !== false ? name = ' - '+item.DriverName : name = ''
      return item.Name + ' - ' + item.Number + '' + name
    },

    changeSelectObjects(e) {
      console.log(e)
    },
    hideContent() {
      let open = true;
      if (this.show_object_content === true) {
        open = false;
      }
      this.$store.commit('SET_SHOW_OBJECT_CONTENT', open);
    },
    formatData(new_data) {

      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      let add = new Date(parseInt(new_data))
      let formatter = new Intl.DateTimeFormat("ru")

      let date = moment(add).tz('utc').format('DD.MM.YYYY HH:mm');
      return date

    },
    fetchObject(query) {
      let query2 = query.toUpperCase()
      let new_arr = []
      let num = 0
      this.old_objects.map((item, key) => {
        if (this.old_objects[key].Name.includes(query) || this.old_objects[key].Name.includes(query2) || this.old_objects[key].Number.includes(query)

          || this.old_objects[key].Number.includes(query2)
          || (this.old_objects[key].DriverName && this.old_objects[key].DriverName.includes(query))
          || (this.old_objects[key].DriverName && this.old_objects[key].DriverName.includes(query2))
        ) {

          new_arr[num] = this.old_objects[key]
          num++;
        }
      })
      if (new_arr.length > 0) {
        this.objects = new_arr
      } else {
        this.objects = this.old_objects
      }

    },
    getShowPolygon() {
      let open = true
      if (this.show_geo_zone === true) {
        open = false
      }
      this.$store.commit('SET_SHOW_GEO_ZONE', open);
    },
    getObjects() {

        let $this = this;

        setTimeout(function () {
          if (Object.keys($this.objects).length > 0) {
            $this.old_objects = []
            let group_list2 = [...$this.group_list]
            group_list2.forEach(v => {
              v.ObjectId =  v.groupId+'-g';
              v.Name =  v.groupName;
              v.Number =  $this.lang.group_component.group;
              v.DriverName = false;
            })

            Object.keys(group_list2).forEach(key => {
              $this.old_objects.push(group_list2[key])
            });
            Object.keys($this.objects).forEach(key => {
              $this.old_objects.push($this.objects[key])
            });
            $this.loading_select = false
          } else {
            setTimeout(function () {

            }, 400);
          }
        }, 1000);


    },

    addFavorit(index) {

      let check = this.objects[index]
      if (check.is_favorite) {
        if (check.is_favorite === true) {
          this.objects[index].is_favorite = false
          this.old_objects[index].is_favorite = false
        } else {
          this.objects[index].is_favorite = true
          this.old_objects[index].is_favorite = true
        }
      } else {
        this.objects[index].is_favorite = true
        this.old_objects[index].is_favorite = true
      }
      this.sendFavorit(this.objects[index].is_favorite, this.objects[index].ObjectId)

    },
    sendFavorit(is_favorite, ObjectId) {

      axios.post(base_url + '/objects/set-favorite', {
          is_favorite: is_favorite,
          object_id: parseInt(ObjectId),
        }, {
          headers: {'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json'}
        }
      ).then(response => {

      }).catch(error => {
        console.log(error)
      })
    },
    AddWorkObject(index, objectId) {

      if (!this.selected_objects[objectId]) {
        this.work_objects.push(this.objects[index])
        this.selected_objects[objectId] = true
      } else {
        delete this.selected_objects[objectId]

        let check_index = this.work_objects.findIndex(item => {
          item.objectId === objectId
        })
        this.work_objects.map((item, key) => {

        })
        if (this.work_objects.length > 0) {
          this.show_calendar = true;
        } else {
          this.show_calendar = false;
        }


      }


    },
    getShowReports(){
      let add  = true

      if(this.show_reports === true){
        add  = false
        this.$store.commit('SET_SELECT_TYPE', "history");
        this.$store.commit('SET_SHOW_REPORTS_SETTINGS', add);
      }else{
        this.$store.commit('SET_SELECT_TYPE', "reports");
        this.monitoring_icons = false
        this.$store.commit('SET_SHOW_ONLY_SEARCH', 0);
      }
      this.$store.commit('SET_ADD_CALENDAR', add);


      this.$store.commit('SET_SELECT_HISTORY', false);
      this.$store.commit('SET_SHOW_REPORTS', add);
      this.checkPanelSize()
    },
    getReports(){
      let cookie = this.reports_cookie
      if(cookie  ===1){

        let formData = new FormData();

        axios.post('https://reports.trackpro.lv/reports/routes2/',
        ).then(response => {

          if(response.data.messages && response.data.messages.length > 0) {

            let data_add =[]
            response.data.messages.map(elem =>{
              data_add.push({'cell':elem })
            })
            $this.table_data = data_add
            $this.old_table_data = data_add
            $this.getExcelData();
            if ($this.chart_type_num === 1) {
              $this.getChartShow()
            } else {

              $this.editAvarageChart()
            }
          }
        }).catch(error => {
          console.log(error)
        })

      }

    },
},
  beforeDestroy: function () {
    this.searchText=''
  },
}

</script>

<style scoped>

.mobile-object-scroll{
  position: absolute;
  right: 10px;
  width: 30px;
  height: 30px;
  box-shadow: none;
  border-color: #1c71da;
  border-radius: 50%;
}
.mobile-object-scroll svg {
  fill: #1c71da;
}
.mobile-object-scroll{
  display: none;
}
.object-icon{
  cursor: pointer;
}
.reports-tools-select{
  flex: auto;
  width: 100%;
  margin-right: 10px;
}
.object-wrapper .panel.main-panel.show-full-object {
  height: 70vh;
}
.object-search-star.polygon-icon{
  width: 18px;
}
.object-search-star.polygon-icon.active{
 color: #1C71DA;
}
.object-search-star.polygon-icon.active svg path{
  fill: #1C71DA;
}
.object-search-star.polygon-icon.active svg{
  fill: #1C71DA;
}

.hoja-red {
  background: #FF8A65;
}

.hoja-green {
  background: #69F0AE;
}

.hoja-purple {
  background: #9FA8DA;
}

.hoja-grey {
  background: #EEEEEE;
}

.hoja-yellow {
  background: #FFE082;
}

.object-wrapper.hide-object-wrapper {

  position: relative;
  width: 60px;
  height: 60px;
  background: #ffffff;
  padding: 0;
  margin: 0;
  border-radius: 5px;
  box-shadow: rgba(100 100 111 0.2) 0px 7px 29px 0px;
  margin-left: 20px;
  margin-top: 20px;
  max-height: unset;
  max-width: unset;
  min-height: unset;
  min-width: unset;
}

.object-wrapper.hide-object-wrapper .panel {
  position: unset;
}

.object-search-panel {
  display: flex;
  width: 100%;
  min-height: 60px;
  flex-wrap: wrap;
}

.object-list-title {
  font-size: 20px;
  letter-spacing: 0.3px;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 50px;
  margin-top: 10px;
}

.object-label {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #000000;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 0px solid #0a6aa1;
  transition: border-width 0.2s ease;
  position: relative;
  cursor: pointer;
}

.object-label::before {
  content: '';
  position: absolute;
  top: 4px;
  left: -4px;
  width: 10px;
  border-bottom: 2px solid #0a6aa1;
  height: 10px;
  display: none;
}

.object-label::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 20px;
  width: 10px;
  border-bottom: 2px solid #0a6aa1;
  height: 10px;
  display: none;

}
.object-wrapper-panel-body{
  min-height: 100%;
}

.active .object-label::after {
  display: block;
}

.active .object-label::before {
  display: block;
}


.active .object-label, .active , .object-label .object-label:hover, .object-label:focus {
  border-color: #0a6aa1;
  border-width: 2px;
}



.object-text {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.object-text__title {

}

.object-text__subitle {
  color: #000000;
  font-weight: 700;
}

.object-text__addtext {
  color: #1C71DA;
}

.object-time {
  display: flex;
  width: 80px;
  text-align: right;

  font-weight: 600;

}

.object-icon {
  display: flex;
  width: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 0;
}

.object-icon span {
  font-size: 10px;
  margin-top: 0;
}

.ObjectIcon {
  height: 34px;
  width: auto;

}
.object-icon{
  position: relative;
}
.object-icon:hover .object-icon-gear, .object-icon:focus .object-icon-gear{
  opacity: 1;
}

.object-icon-gear{
  position: absolute;
 width: 10px;
  height: 10px;
  top: 0;
  right: 0;
z-index: 1000;
  opacity: 0;
}
.object-icon-gear svg{
  fill:#777;
}

table.table-object.table-striped {
  width: 300px;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 4px 8px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #dddddd;
}

.table.table-object tbody tr:hover, .table.table-object tbody tr:focus {
  background: rgba(0, 136, 204, 0.4);
}

.object-selected {
  background: #DCE775;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-right: 4px;
  border: 1px solid transparent;
}


.object-search-box {
  width: 100%;
  margin-top: 10px;
}

.calendar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  margin-left: auto;
  box-shadow: rgba(99 99 99 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  border-color: #cccccc;
  padding: 0;
  background: #cccccc;
}

.calendar-button.active {
  border-color: rgba(0, 136, 204, 0.1);
  background: rgba(0, 136, 204, 0.3);
  box-shadow: rgba(0, 136, 204, 0.2) 0px 2px 8px 0px;
}

.calendar-icon {
  width: 24px;
  height: 24px;
  color: #777777;
}

.calendar-button.active .calendar-icon {
  color: #1C71DA;
}
.calendar-button.reports-setting-btn{
  position: relative;
}
.reports-tools-title{
  padding-top: 5px;
  display: block;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #171717;
  position: relative;
  padding-left: 20px;
}
.reports-tools-title::before{
  border: none !important;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  content: '';
  background: #4dd79c;
  display: inline-block;
  left: 0;
  margin: 0;
  position: absolute;
  top: 8px;
  z-index: 2;
}
.object-calendar-panel-header {
  display: flex;
  align-items: center;
  position: relative;
}
.object-calendar-panel-header .button-close{
  position: absolute;
  top: 12px;
  left: 10px;
  z-index: 10000;
}

.object-button-panel {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

.object-button-panel__box {

  margin: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  COLOR: #777;
  border-radius: 10px;
  margin: 5px;
  width: 36px;
  height: 36px;

}

.object-button-panel__box.status {
  min-width: 70px;
}

.object-search-star.all-search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-weight: bold;
  border: 0.5px solid #cccccc;
  border-radius: 50%;
}

.object-search-star.alpha-search {
  width: 22px;
  height: 22px;
}

.object-search-star.four-circles {
  width: 45px;
  position: relative;
}

.four-circles__box {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;

}

.four-circles__box.hoja-red {
  top: 0;
  left: 0;

}

.four-circles__box.hoja-purple {
  top: 0;
  left: 10px;

}

.four-circles__box.hoja-yellow {
  top: 0;
  left: 20px;
}

.four-circles__box.hoja-green {
  top: 0;
  left: 30px;
}

.all-search:hover, .all-search:focus {
  border: 0.5px solid #1C71DA;
  color: #1C71DA;
}

.object-search-star:focus, .object-search-star:hover, .fa-star:hover, .fa-star:focus {
  color: #1C71DA;
}

.four-circles__select {
  width: 100%;
}

.four-circles__btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #34495e;
  padding: 3px 5px;
  margin: 3px;
  width: 46%;
}

.four-circles__counter {
  color: #333333;
  margin-right: 5px;
  font-weight: bold;
}

.monitoring-icon {
  transition: width 2ms ease;
  transition: height 2ms ease;
}

.monitoring-icon.active {
  color: #64dd17;
  width: 30px;
  height: 30px;
}

.search-icon-box ul.simple-user-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 330px;
}

.search-icon-box ul.simple-user-list li {
  margin: 5px 5px;
  min-width: 150px;
  position: relative;
  padding-left: 10px;
}

.search-icon-box.panel-heading {
  padding: 10px;
}

.panel-search-actions {
  position: absolute;
  top: 10px;
  right: 0;
  padding: 5px;
}

.panel-search-actions a {
  cursor: pointer;
}

.panel-heading.panel-heading-transparent {
  background: #e5f3f9;
  border-bottom: 1px solid #e5f3f9;
  padding: 10px 20px;
}

.panel-body {
  background: #fff;
}

.history_object_list {
  width: 100%;
}

.history_object_item {
  position: relative;
  display: flex;
  align-items: center;
}

.history_object_action {
  font-size: 18px;
  margin-right: 10px;
  margin-left: 20px;
  background: #ECEFF1;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-top: auto;
  margin-bottom: auto;
}

ul.simple-user-list li .message {
  padding-left: 5px;
  padding-right: 5px;
}


.history_object_action:hover, .history_object_action:focus {
  background: #CFD8DC;
}

.history_object_action:hover a, .history_object_action:focus a {
  text-decoration: none;
}

.history_object_calendar .calendar-button {
  width: 30px;
  height: 30px;
}

ul.simple-bullet-list li:before {
  border: none !important;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  content: '';
  background: #4dd79c;
  display: inline-block;
  left: 0;
  margin: 0;
  position: absolute;
  top: 5px;
  z-index: 2;

}

html .panel .panel-heading-transparent + .panel-body {
  border-radius: 5px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.history-bnt-box {
  margin-left: auto;
  display: flex;
}

.table.table-object {
  max-width: 380px;
  min-width: 300px;
}

.history_object_item.selected {
  background-color: #E0F2F1;

}

.four-circles__select {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.object-button-panel__box {
  margin: 2px;
}

.object-text__addtext {
  position: relative;
  height: 15px;
  max-width: 100px;
}

.object-text__addtext span {
  position: absolute;
  width: 200px;
}
.object-search-star.alpha-search svg:hover,.object-search-star.alpha-search svg:focus {
  fill: #1C71DA;
  color: #1C71DA;
}
.object-search-star.alpha-search svg:hover path,.object-search-star.alpha-search svg:focus path {
  fill: #1C71DA;
  color: #1C71DA;
}
.object-search-star svg:hover,.object-search-star svg:focus {
  fill: #1C71DA;
  color: #1C71DA;
}
.object-search-star .polygon-icon  svg:hover,.object-search-star .polygon-icon  svg:focus{
  fill: #1C71DA;
  color: #1C71DA;
}
.reports-setting-btn{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  height: 38px;
  box-shadow: rgba(99 99 99 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
  border-color: #cccccc;
  padding: 0;
  padding-bottom: 5px;
  background: #cccccc;
}
.reports-setting-btn svg{
  width: 24px;
  height: 24px;
  fill: #1C71DA;
}
.reports-tools-bar{
  display: flex;
  width: 100%;
  justify-content: end;
}
.reports-tools-bar .btn{
  margin-left: unset !important;
  margin-right: 10px;
}
@media only screen and (max-width: 1500px) {
  .panel-heading.panel-heading-transparent {
    max-width: 370px;
  }
  .reports-tools-bar{
    width: 97%;
  }
  .reports-tools-title {
    font-size: 1.2rem;
  }
  ul.simple-bullet-list li .title {

    font-size: 1.2rem;

  }
  .panel-heading.panel-heading-transparent {
    padding: 5px 5px;
  }

  .object-search-box {
    margin-top: 0;
  }

  .object-button-panel__box {
    width: 32px;
    height: 32px;
    margin: 3px;
  }

  .object-button-panel {;
    padding-top: 0px;
  }

  .four-circles__select {
    width: 100%;
    display: flex;
    display: flex;
    flex-wrap: wrap;
  }

  .four-circles__btn {
    width: 46%;
    margin-bottom: 5px;
  }

  html .panel .panel-heading-transparent + .panel-body {
    border-radius: 5px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    max-width: 370px;
    min-height: 50vh;
  }

  .object-list-title {
    font-size: 18px;
    letter-spacing: 0.3px;
    margin-left: 10px;
    margin-right: 20px;
    padding-left: 30px;
  }


}
.table.table-object td {
  padding: 4px 2px !important;
}
.active-report{
  background: #DCE775;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.history_object_calendar{
  position: relative;
}
.report-select{
  height: 40px;
  padding: 8px;
  min-width: 180px;
  width: 100%;
}
@media screen and (max-width: 991px){}
  .table-responsive {
    border: none;
  }
ul.simple-bullet-list li:before {
  top: 3px;
}
.search-icon-box ul.simple-user-list {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0;
  padding: 0;
}
.search-icon-box ul.simple-user-list li {
  width: 46%;
}
@media screen and (max-width: 600px){
  .mobile-object-scroll{
    display: block;
  }
  .object-wrapper.hide-object-wrapper {
    margin-left: 10px;
  }
  .panel-heading.panel-heading-transparent {
    padding: 2px 2px;
    max-width: 100%;
  }
  .object-search-panel{
    min-height: 42px;
  }
  .four-circles__btn {
    width: 46%;
    margin-bottom: 3px;
    font-size: 12px;
  }
  .object-button-panel {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  .ui.search.selection.dropdown>input.search, .ui.search.selection.dropdown>span.sizer {
    line-height: 1em;
    padding: 0.5em 1.1em 0.5em 1em;
  }
  .ui.selection.dropdown {
    padding: 0.5rem 1.1em 0.5em 1em;
  }
  .ui.selection.dropdown>.delete.icon, .ui.selection.dropdown>.dropdown.icon, .ui.selection.dropdown>.search.icon {

    top: 0.3em;

  }
  html .panel .panel-heading-transparent + .panel-body {

    max-width: 100% !important;

  }
  .table.table-object{
    max-width: 100%;
    min-width: 100%;
  }
  .object-wrapper.hide-object-wrapper{
    position: fixed;
    top: 60px;
  }
  .object-button-panel__box svg {
    width: 32px;
    height: 32px;

  }
  .object-search-star svg {
    width: 25px;
    height: 25px;
  }

  .object-search-star.alpha-search svg {
    width: 22px;
    height: 22px;
  }

  .object-search-star.polygon-icon  svg {
    width: 18px;
    height: 25px;
  }

  .object-wrapper .panel.main-panel{
    height: 43vh;
  }
  .object-button-panel__box.status {
    min-width: 60px;
  }
}
.mb-xlg {
  margin-bottom: 0px !important;
}


</style>

