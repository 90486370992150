<template>


  <div class="full-map" v-if="user_auth">

    <l-map
        ref="map"
        :zoom="map_zoom"
        :center="start_center"
        :maxZoom="maxZoom"
        @update:zoom="zoomUpdate"
    >
      <l-control-fullscreen/>
      <v-geosearch :options="geosearchOptions" class="search-geo"></v-geosearch>


      <div class="geo-custom-control search-control">
        <button type="button" class="btn btn-geo" @click="openGesearch"><span class="search-control-btn"><svg-search-icon/></span>
        </button>
      </div>


      <l-draw-toolbar position="topright" :startdraw='startdraw'/>


      <l-tile-layer :url="main_url" :subdomains="map_subdomains" :name="map_name" :attribution="map_attribution"
                    v-if="renderComponent"></l-tile-layer>
      <l-feature-group ref="features"/>
<template v-if="current_resources && current_resources[11] && current_resources[11].enabled ===true">
      <div class="geo-custom-control">
        <button type="button" class="btn btn-geo" @click="showGeoZone()"><img
            src="/tracking/assets/images/geo-zone/geo-zone-icon1.svg" alt="icon geo zone"/></button>
      </div>
</template>
      <div class="example-custom-control">
        <p><i class="fas fa-layer-group">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
               data-icon="layer-group" class="svg-inline--fa fa-layer-group fa-w-16" role="img" viewBox="0 0 512 512">
            <path fill="currentColor"
                  d="M12.41 148.02l232.94 105.67c6.8 3.09 14.49 3.09 21.29 0l232.94-105.67c16.55-7.51 16.55-32.52 0-40.03L266.65 2.31a25.607 25.607 0 0 0-21.29 0L12.41 107.98c-16.55 7.51-16.55 32.53 0 40.04zm487.18 88.28l-58.09-26.33-161.64 73.27c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.51 209.97l-58.1 26.33c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 276.3c16.55-7.5 16.55-32.5 0-40zm0 127.8l-57.87-26.23-161.86 73.37c-7.56 3.43-15.59 5.17-23.86 5.17s-16.29-1.74-23.86-5.17L70.29 337.87 12.41 364.1c-16.55 7.5-16.55 32.5 0 40l232.94 105.59c6.8 3.08 14.49 3.08 21.29 0L499.59 404.1c16.55-7.5 16.55-32.5 0-40z"/>
          </svg>
        </i></p>
        <div class="show-map-list">
          <ul class="map-layer-list">
            <template v-for="(map_layer) in map_layers">
              <li class="map-layer-name" :class="map_name=== map_layer.name ? 'active' : ''"
                  @click="getCrsMap(map_layer)">{{ map_layer.name }}
              </li>
            </template>
          </ul>
        </div>
      </div>


      <template>
        <template v-if="current_resources && current_resources[10] && current_resources[10].enabled === true">
        <template v-if="show_only_search ===0 && show_all_objects">

          <v-marker-cluster :options="clusterOptions" ref="clusterRef" @ready="setClusterBind()">

            <template v-for="(obj,index) in work_objects">
              <template v-if="current_group == 0 || (current_group_list[obj.ObjectId] )">

              <template v-if="checkIcon(obj,index)">
                <template v-if="obj.Y  !=='0.0' && obj.X !=='0.0'">
                  <v-rotated-marker
                      :lat-lng="[obj.Y, obj.X]"
                      @click="GetObjectData(obj)"
                      :icon="getStaticIcon4(obj)"
                      :rotationAngle="getRotate(obj)"
                      :ref="'mark_'+obj.ObjectId"
                      :key="obj.ObjectId"
                  >
                    <l-tooltip class="path-tooltip" :options="{ permanent: true, interactive: true }">
                      <div v-html="getToltipHtml(obj)">

                      </div>
                    </l-tooltip>

                  </v-rotated-marker>




                </template>
              </template>

              </template>
            </template>


          </v-marker-cluster>
        </template>
        </template>

        <template v-if="checkObjectLength(search_icons) && start_search">

          <template v-for="(obj,index) in search_icons">
            <template v-if="obj.Y">

              <v-rotated-marker
                  :lat-lng="[obj.Y, obj.X]"
                  @click="GetObjectData(obj)"
                  :icon="getStaticIcon2(obj)"
                  :rotationAngle="getRotate(obj)"
                  :ref="'monitor_'+obj.ObjectId"
              >

                <l-tooltip :options="{ permanent: true, interactive: true }">
                  <div v-html="getToltipHtml(obj)">
                  </div>
                </l-tooltip>
              </v-rotated-marker>

              <l-polyline
                  :lat-lngs="obj.polyline.latlngs"
                  :color="obj.polyline.color"
                  :opacity="obj.polyline.opacity"
                  :options="polilyin_opions"
                  v-if="show_polyline"
              />

            </template>

          </template>
        </template>


        <template v-if="checkObjectLength(polygon_zones) && show_all_zones ">
          <template v-for="(zone,key,index) in polygon_zones">
            <template v-if="(zone.type == 'polygon' || zone.type == 'rectangle') && zone.show">
              <l-polygon
                  :lat-lngs="zone.latlngs"
                  :color="zone.color"
                  :key="zone.status"
                  :ref="'poly_'+zone.status"

              />
              <l-marker :lat-lng="zone.center"
                        :icon="icon"
                        :draggable="draggable_marker"
                        @mousedown="makeDrugabbleMarker($event,zone)"
                        @mousemove="infoMarker($event,zone)"
                        @mouseup="removeDrugabbleMarker($event,zone)"
                        :ref="'marker_'+zone.status"
              >
                <l-popup>

                  <button type="button" class="btn btn-default" @click="saveLoationZone($event,zone)">Save</button>
                </l-popup>

                <l-tooltip
                    :options="tooltip_options"


                >
                  <div class="zone-popup-box">
                    <h4 v-if="zone.name"> {{ zone.name }}</h4>
                    <p v-if="zone.info.Address">
                      {{ zone.info.Address }}
                    </p>
                  </div>
                </l-tooltip>
              </l-marker>
            </template>
            <template v-if="zone.type == 'polyline' && zone.show">
              <l-polyline
                  :lat-lngs="zone.latlngs"
                  :color="zone.color"
                  :key="zone.status"
                  :ref="'poly_'+zone.status"
              />
            </template>


            <template v-if="zone.type === 'circle' && zone.show">
              <l-circle
                  :lat-lng="zone.latlng"
                  :radius="zone.mRadius"
                  :color="zone.color"
                  :key="zone.status"
                  :ref="'poly_'+zone.status"
              />
              <l-marker :lat-lng="zone.center"
                        :icon="icon"
                        :draggable="draggable_marker"
                        @mousedown="makeDrugabbleMarker($event,zone)"
                        @mousemove="infoMarker($event,zone)"
                        @mouseup="removeDrugabbleMarker($event,zone)"
                        :ref="'marker_'+zone.status"
              >
                <l-popup>
                  <button type="button" class="btn btn-default" @click="saveLoationZone($event,zone)">Save</button>
                </l-popup>
                <l-tooltip>
                  <div class="zone-popup-box">
                    <h4 v-if="zone.name"> {{ zone.name }}</h4>
                    <p v-if="zone.info.Address">
                      {{ zone.info.Address }}
                    </p>
                  </div>
                </l-tooltip>
              </l-marker>
            </template>
            <template v-if="zone.type === 'marker' && zone.show">


              <l-marker :lat-lng="zone.latlng"
                        :icon="icon"
                        :key="zone.status"
                        :draggable="draggable_marker"
                        @mousedown="makeDrugabbleMarker($event,zone)"
                        @mousemove="infoMarker($event,zone)"
                        @mouseup="removeDrugabbleMarker($event,zone)"
                        :ref="'marker_'+zone.status"
              >
                <l-popup>
                  <button type="button" class="btn btn-default" @click="saveLoationZone($event,zone)">Save</button>
                </l-popup>
                <l-tooltip>
                  <div>
                    <h4 v-if="zone.name"> {{ zone.name }}</h4>
                    <p v-if="zone.info.Address">
                      {{ zone.info.Address }}
                    </p>
                  </div>
                </l-tooltip>
              </l-marker>
            </template>


          </template>
        </template>

        <l-marker :lat-lng="path_markerLatLng" v-if="show_path_one">
          <l-tooltip :options="{ permanent: true, interactive: true }">
            <div class="path_tooltip_box">
              <button type="button" class="btn close_tooltip_box" @click=" show_path_one = false"><span
                  class="fa fa-times"></span></button>
              <div class="path_tooltip_list" v-if="show_path_description">
                <ul>
                  <li>{{ show_path_description.name }}</li>
                  <li>{{ show_path_description.subtext }}</li>
                  <li>{{ show_path_description.subname }}</li>

                  <li>Total Distance:
                    {{ Math.round((show_path_description.summary.totalDistance / 1000) * 100) / 100 + ' km' }}
                  </li>
                  <li>Total Time: {{ Math.round(show_path_description.summary.totalTime / 60) + ' minutes' }}</li>

                </ul>


              </div>


              <button type="button" @click="removePath()" class="mb-xs mt-xs mr-xs btn btn-info"><span
                  class="fa path_tooltip_fa"><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true"
                                                  focusable="false"
                                                  data-prefix="fas" data-icon="trash-alt"
                                                  class="svg-inline--fa fa-trash-alt fa-w-14" role="img"
                                                  viewBox="0 0 448 512"><path fill="currentColor"
                                                                              d="M32 464a48 48 0 0 0 48 48h288a48 48 0 0 0 48-48V128H32zm272-256a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zm-96 0a16 16 0 0 1 32 0v224a16 16 0 0 1-32 0zM432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16z"/></svg></span>
              </button>
            </div>

          </l-tooltip>
        </l-marker>
      </template>
      <template v-if="select_history">

        <template v-if="history_data[settings_active]">


          <template v-if="stop_markers[settings_active] && stop_markers[settings_active].length > 0">
            <template v-for="(item,index) in stop_markers[settings_active]">

              <v-rotated-marker
                  :lat-lng="[item.lat, item.lng]"
                  :icon="getStaticIcon3(item)"
                  :rotationAngle="parseInt(item.a)"
                  @click="getToltipHtml2(item)"

              >

                <l-tooltip :options="{ permanent: true, interactive: true , direction:'right'}"
                           v-if="stop_marker_counter ===item.t ">
                  <div class="move-marker-stop-box">
                    <button type="button" class="btn btn-default move-marker-dat-btn" @click="stop_marker_counter = 0">
                      <span class="fa fa-times"></span>
                    </button>
                    <div style="display: none">{{ stop_marker_counter }}</div>
                    <template v-if="stop_marker_counter ===item.t">
                      <div v-html="stom_marker_html">

                      </div>
                    </template>
                  </div>
                </l-tooltip>


              </v-rotated-marker>

            </template>
          </template>

          <template v-if="history_data[settings_active]['routes']">
            <l-polyline
                :lat-lngs="getBigPolylineCoordinat(history_data[settings_active]['routes'])"
                :opacity="0"
                ref="history_polylinr_simple"
                @ready="starZoomPolyline()"
            />
          </template>


          <template v-if="history_data[settings_active]['stops']">
            <template v-for="(item,index) in history_data[settings_active]['stops']">
              <l-marker
                  :lat-lng="getMarkerCoordinat(item)"
                  :ref="'stop_'+item.id">
                <l-icon
                    :icon-url="'/tracking/images/icons/monitor_arrow/marker_hidden.svg'"
                    :icon-size="add_iconSize2"
                ></l-icon>
              </l-marker>


            </template>


          </template>


          <template v-for="(item,index) in history_data[settings_active]['routes']">


              <l-marker
                  :lat-lng="getMarkerCoordinat(item['r'][0])"
                  :icon="getIconRoute('P' )"
                  v-if="index===0"  >

              <l-popup>
                <div class="path-rout-popup">
                  <div class="path-rout-box">

                    <ul>
                      <li>{{ history_data[settings_active]['stops'][index]['address'] }}</li>

                      <li>
                        <translate-component :text="lang.map_component.start_time"/>: <span class="rout-path-tooltip-title">{{ item.route_start_time }}</span></li>
                      <li>
                        <translate-component :text="lang.map_component.stop_time"/>: <span class="rout-path-tooltip-title">{{
                            history_data[settings_active]['stops'][index]['stop_time']
                          }}</span></li>
                    </ul>
                  </div>
                </div>
              </l-popup>
            </l-marker>

            <l-marker
                :lat-lng="getMarkerCoordinat(item['r'][item['r'].length-1])"
                :icon="getIconRoute(index + 1 )"
            >

              <l-popup>
                <div class="path-rout-popup">
                  <div class="path-rout-box">

                    <ul>
                      <li>{{ history_data[settings_active]['stops'][index + 1]['address'] }}</li>
                      <li>
                        <translate-component :text="lang.map_component.driving_time"/>: <span class="rout-path-tooltip-title">{{ item.route_time + ':00' }}</span></li>
                      <li>
                        <translate-component :text="lang.map_component.total_distance"/>: <span class="rout-path-tooltip-title">{{ item.route_dist }} Km</span></li>
                      <li>
                        <translate-component :text="lang.map_component.start_time"/>: <span class="rout-path-tooltip-title">{{ item.route_start_time }}</span></li>
                      <li>
                        <translate-component :text="lang.map_component.stop_time"/>: <span class="rout-path-tooltip-title">{{
                            history_data[settings_active]['stops'][index + 1]['stop_time']
                          }}</span></li>
                    </ul>
                  </div>
                </div>
              </l-popup>
            </l-marker>


            <leaflet-polylinedecorator
                @mouseup="connectionClick($event,item['r'])"
                :arrow_colorpicker="settings_options[settings_active].arrow_colorpicker.hex"
                :paths="getPolylineCoordinat(item['r'])"
                :pixelSize="parseInt(settings_options[settings_active].strokeWeight)+2"
                v-if="parseInt(settings_options[settings_active].use_arrow_show) === 1">

            </leaflet-polylinedecorator>

            <template v-if="item.checkbox === 1">
              <l-polyline
                  :lat-lngs="getPolylineCoordinat(item['r'])"
                  :color="select_color_path"
                  :smoothFactor="parseFloat(settings_options[settings_active].strokeSmooth)"
                  :opacity="1"
                  :weight="parseInt(settings_options[settings_active].strokeWeight)"

                  :ref="'polyline_'+item['route_start_time_sec']"

              />
            </template>
            <template v-else>
              <template v-if="parseInt(settings_options[settings_active].use_speed_limit) === 1">

                <leaflet-hotline-component
                    @mouseup="connectionClick($event,item['r'])"
                    :palette="pallete"
                    :outline-width="parseInt(settings_options[settings_active].strokeBorderOpacity)"
                    :outline-color="settings_options[settings_active].colorpicker.hex"
                    :latLngs="getPolylineHotCoordinat(item['r'])"
                    :min="parseInt(settings_options[settings_active].routes_speed[0])"
                    :max="parseInt(settings_options[settings_active].routes_speed[1])"
                    :weight="parseInt(settings_options[settings_active].strokeWeight)"
                    :smoothFactor="parseInt(settings_options[settings_active].strokeSmooth)"
                />

              </template>
              <template v-else>
                <l-polyline
                    @mouseup="connectionClick($event,item['r'])"
                    :lat-lngs="getPolylineCoordinat(item['r'])"
                    :color="settings_options[settings_active].stroke_colorpicker.hex"
                    :smoothFactor="parseFloat(settings_options[settings_active].strokeSmooth)"
                    :weight="parseInt(settings_options[settings_active].strokeWeight)"
                    :opacity="parseFloat(settings_options[settings_active].strokeOpacity)"

                />
              </template>


            </template>


            <template v-if="index+1 === history_data[settings_active]['routes'].length">


                <l-marker
                    :lat-lng="getMarkerCoordinat(item['r'][item['r'].length-1])"
                    :icon="getIconRoute(index + 2 )"
                >
                <l-popup>
                  <div class="path-rout-popup">
                    <div class="path-rout-box">
                      <ul>
                        <li>{{ history_data[settings_active]['stops'][index + 1]['address'] }}</li>
                        <li>
                          <translate-component :text="lang.map_component.driving_time"/>
                          :
                          <span class="rout-path-tooltip-title">{{ item.route_time + ':00' }}</span></li>
                        <li>
                          <translate-component :text="lang.map_component.total_distance"/>
                          :
                          <span class="rout-path-tooltip-title">{{ item.route_dist }} Km</span></li>
                        <li>
                          <translate-component :text="lang.map_component.start_time"/>
                          :
                          <span class="rout-path-tooltip-title">{{ item.route_start_time }}</span></li>
                        <li>
                          <translate-component :text="lang.map_component.stop_time"/>
                          :
                          <span class="rout-path-tooltip-title">{{
                              history_data[settings_active]['stops'][index + 1]['stop_time']
                            }}</span></li>
                      </ul>
                    </div>
                  </div>
                </l-popup>
              </l-marker>
            </template>

          </template>


          <template v-if="history_data[settings_active]['routeend']">


            <template v-if="parseInt(settings_options[settings_active].use_speed_limit) === 1">

              <leaflet-hotline-component
                  :palette="pallete"
                  :outline-width="parseInt(settings_options[settings_active].strokeBorderOpacity)"
                  :outline-color="settings_options[settings_active].colorpicker.hex"
                  :latLngs="getPolylineHotCoordinat(history_data[settings_active]['routeend'])"
                  :min="parseInt(settings_options[settings_active].routes_speed[0])"
                  :max="parseInt(settings_options[settings_active].routes_speed[1])"
                  :weight="parseInt(settings_options[settings_active].strokeWeight)"
                  :smoothFactor="parseInt(settings_options[settings_active].strokeSmooth)"
              />

            </template>
            <template v-else>
              <l-polyline
                  :lat-lngs="getPolylineCoordinat(history_data[settings_active]['routeend'])"
                  :color="settings_options[settings_active].stroke_colorpicker.hex"
                  :smoothFactor="parseFloat(settings_options[settings_active].strokeSmooth)"
                  :weight="parseInt(settings_options[settings_active].strokeWeight)"
                  :opacity="parseFloat(settings_options[settings_active].strokeOpacity)"
              />
            </template>
          </template>
        </template>

        <template v-if="move_marker_show && move_marker_data.id">

          <l-marker :key="move_marker_data.id" :lat-lng="move_marker_data.location" @click="move_marker_close = false">
            <l-icon
                icon-url="/tracking/images/icons/monitor_arrow/marker-show.svg"
                :icon-size="add_iconSize"
                :icon-anchor="add_iconAnchor"
            >
            </l-icon>
            <l-tooltip :options="{ permanent: true, interactive: true , direction:'right'}" v-if="!move_marker_close">
              <div class="move-marker-dat-box">
                <button type="button" class="btn btn-default move-marker-dat-btn" @click="move_marker_close = true">
                  <span class="fa fa-times"></span>
                </button>
                <ul>
                  <li v-for="(value, name) in move_marker_data.info">
                    <span class="move-marker-dat-title">{{ name }} : </span> <span
                      class="move-marker-dat-value">{{ value }}</span>
                  </li>
                </ul>
              </div>
            </l-tooltip>
          </l-marker>

        </template>

        <template v-if="show_poly_data">

          <l-marker :key="polyline_data.id" :lat-lng="polyline_data.location" >
            <l-icon
                icon-url="/tracking/images/icons/monitor_arrow/marker-show.svg"
                :icon-size="add_iconSize"
                :icon-anchor="add_iconAnchor"
            >
            </l-icon>
            <l-tooltip :options="{ permanent: true, interactive: true , direction:'right'}" >
              <div class="move-marker-dat-box">
                <button type="button" class="btn btn-default move-marker-dat-btn" @click="show_poly_data = false">
                  <span class="fa fa-times"></span>
                </button>
                <ul>
                  <li >
                    <span class="move-marker-dat-title">{{ lang.map_component.gps_time }} : </span> <br><span
                      class="move-marker-dat-value">{{ polyline_data.time }}</span>
                  </li>
                  <li >
                    <span class="move-marker-dat-title">{{ lang.map_component.speed  }} : </span> <span
                      class="move-marker-dat-value">{{ polyline_data.speed }} (km/h)</span>
                  </li>

                </ul>
              </div>
            </l-tooltip>
          </l-marker>

        </template>


      </template>


      <template
          v-if="active_route ==='fuel2' && fuel_route[settings_fuel_active]  && fuel_route[settings_fuel_active].length > 0  ">
        <template v-if="settings_options[settings_fuel_active] && settings_options[settings_fuel_active].strokeWeight">

          <template v-if="parseInt(settings_options[settings_fuel_active].use_speed_limit) === 1">

            <leaflet-hotline-component
                :palette="pallete"
                :outline-width="parseInt(settings_options[settings_fuel_active].strokeBorderOpacity)"
                :outline-color="settings_options[settings_fuel_active].colorpicker.hex"
                :latLngs="fuel_route[settings_fuel_active]"
                :min="parseInt(settings_options[settings_fuel_active].routes_speed[0])"
                :max="parseInt(settings_options[settings_fuel_active].routes_speed[1])"
                :weight="parseInt(settings_options[settings_fuel_active].strokeWeight)"
                :smoothFactor="parseInt(settings_options[settings_fuel_active].strokeSmooth)"
                ref="history_polylinr_simple2"
            />

          </template>
          <template v-else>
            <l-polyline
                :lat-lngs="fuel_route[settings_fuel_active]"
                :color="settings_options[settings_fuel_active].stroke_colorpicker.hex"
                :smoothFactor="parseFloat(settings_options[settings_fuel_active].strokeSmooth)"
                :weight="parseInt(settings_options[settings_fuel_active].strokeWeight)"
                :opacity="parseFloat(settings_options[settings_fuel_active].strokeOpacity)"
                ref="history_polylinr_simple2"
                @ready="starZoomPolyline2()"
            />
          </template>
          <leaflet-polylinedecorator
              :arrow_colorpicker="settings_options[settings_fuel_active].arrow_colorpicker.hex"
              :paths="fuel_route[settings_fuel_active]"
              :pixelSize="parseInt(settings_options[settings_fuel_active].strokeWeight)+2"
              v-if="parseInt(settings_options[settings_fuel_active].use_arrow_show) === 1">

          </leaflet-polylinedecorator>
          <l-marker :lat-lng="fuel_route[settings_fuel_active][0]">
            <l-icon
                icon-url="/tracking/images/icons/monitor_arrow/location-flag.svg"
                :icon-size="[25, 41]"
                :icon-anchor="[6, 35]"
            >
            </l-icon>
          </l-marker>
          <l-marker :lat-lng="fuel_route[settings_fuel_active][fuel_route[settings_fuel_active].length-1]">
            <l-icon
                icon-url="/tracking/images/icons/monitor_arrow/finish_marker.svg"
                :icon-size="[25, 41]"
                :icon-anchor="[2, 40]"
            >
            </l-icon>
          </l-marker>

          <template v-if="fuel_marker.length>0">
            <l-marker :lat-lng="[fuel_marker[3],fuel_marker[4]]">
              <l-icon
                  icon-url="/tracking/images/icons/monitor_arrow/marker-show.svg"
                  :icon-size="add_iconSize"
                  :icon-anchor="add_iconAnchor"
              >
              </l-icon>
              <l-tooltip :options="{ permanent: true, interactive: true , direction:'right'}">
                <div class="move-marker-dat-box">
                  <ul>
                    <li>
                      <span class="move-marker-dat-value">{{ fuel_marker[0] | moment("utc","DD/MM/YYYY HH:mm") }}</span>
                    </li>
                    <li>
                      <span class="move-marker-dat-title">Fuel level: </span> <span
                        class="move-marker-dat-value">{{ fuel_marker[2] }} L</span>
                    </li>
                  </ul>
                </div>
              </l-tooltip>
            </l-marker>


          </template>
          <template v-if="fuel_fill_markers.length>0">

            <template v-for="(mar, index) in fuel_fill_markers">
              <l-marker
                  :lat-lng="[mar.lat,mar.lng]"
                  :ref="'fuel_'+mar.time"
              >

                <l-icon
                    icon-url="/tracking/images/icons/monitor_arrow/gas-station-point.svg"
                    :icon-size="add_iconSize"
                    :icon-anchor="add_iconAnchor"
                >
                </l-icon>
                <l-popup>
                  <div class="move-marker-dat-box">
                    <ul>
                      <li>
                        <span class="move-marker-dat-value">{{ mar.date + ' ' + mar.time }}</span>
                      </li>
                      <li class="fuel-fill">
                        <span class="move-marker-dat-title">Fuel fill: </span>
                        <span class="move-marker-dat-value green" v-if="mar.fill > 0">{{ '+ ' + mar.fill }}</span>
                        <span class="move-marker-dat-value red" v-if="mar.fill < 0">{{ '- ' + mar.fill }}</span>
                      </li>
                    </ul>
                  </div>
                </l-popup>
              </l-marker>
            </template>

          </template>
        </template>
      </template>

    </l-map>

  </div>

</template>

<script>
import {
  LMap,
  LIcon,
  LTileLayer,
  LMarker,
  LControlLayers,
  LPopup,
  LPolyline,
  LPolygon,
  LRectangle,
  LControl,
  LTooltip,
  LCircle,
  LFeatureGroup,
} from 'vue2-leaflet';
import helpers from '@/mixins/helpers.js'
import 'leaflet/dist/leaflet.css';
import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
import {Icon, Control, icon, divIcon as DivIcon, point as Point} from "leaflet";
import Vue2LeafletRotatedMarker from 'vue2-leaflet-rotatedmarker';
import {mapGetters} from "vuex";
import ClusterDescriptionComponent from "@/components/routes/ClusterDescriptionComponent";
import LDrawToolbar from 'vue2-leaflet-draw-toolbar';
import mapHelper from "@/mixins/mapHelper";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import LeafletHotlineComponent from "@/components/leaflet-hotline/LeafletHotlinwComponent";
import {OpenStreetMapProvider} from 'leaflet-geosearch';
import VGeosearch from 'vue2-leaflet-geosearch';
import LControlFullscreen from 'vue2-leaflet-fullscreen';

var L = require('leaflet');
require('leaflet-routing-machine');

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})
import 'leaflet-simple-map-screenshoter';
import LeafletPolylinedecorator from "@/components/map/LeafletPolylinedecorator";
import axios from "axios";
import SvgSearchIcon from "@/components/ui/icons/svgSearchIcon";
import markerIconComponent from "@/components/icons/markerIconComponent";
import moment from "moment-timezone";


export default {
  name: "MapComponent",
  components: {
    SvgSearchIcon,
    LeafletPolylinedecorator,
    LeafletHotlineComponent,
    TranslateComponent,
    LMap,
    LIcon,
    LTileLayer,
    LMarker,
    LControlLayers,
    LPopup,
    LPolyline,
    LPolygon,
    LRectangle,
    LControl,
    LTooltip,
    LCircle,
    VGeosearch,
    LFeatureGroup,
    LControlFullscreen,
    'v-marker-cluster': Vue2LeafletMarkerCluster,
    'v-rotated-marker': Vue2LeafletRotatedMarker,
    ClusterDescriptionComponent,
    LDrawToolbar,
    markerIconComponent,
  },
  mixins: [helpers, mapHelper],
  props: {
    show_toolbars: {
      type: Boolean,
      default: true,
    },
    props: {
      fuel_type: {
        type: String,
        default: 'fuel2'
      }
    },
  },
  mounted() {
    let _this = this;


    this.$nextTick(() => {
      let map = _this.$refs.map.mapObject
      let width_main = window.innerWidth
      if (width_main <= 600) {
        this.screen_size = 2
      }


      let pluginOptions = {
        cropImageByInnerWH: true, // crop blank opacity from image borders
        hidden: false, // hide screen icon
        domtoimageOptions: {}, // see options for dom-to-image
        position: "topright", // position of take screen icon
        screenName: "screen", // string or function
        hideElementsWithSelectors: [".leaflet-control-container"], // by default hide map controls All els must be child of _map._container
        mimeType: "image/png", // used if format == image,
        caption: null, // streeng or function, added caption to bottom of screen
        captionFontSize: 15,
        captionFont: "Arial",
        captionColor: "black",
        captionBgColor: "white",
        captionOffset: 5
      };


      this.simpleMapScreenshoter = L.simpleMapScreenshoter(pluginOptions).addTo(map);

      map.on('draw:drawstart', function (e) {
        _this.$store.commit('SET_SHOW_GEO_ZONE', true);

      });
      map.on('draw:edited', function (e) {

        let layers = e.layers;
        layers.eachLayer(function (layer) {
          if (layer instanceof L.Rectangle) {
            console.log('im an instance of L rectangle');
          }
          if (layer instanceof L.Polygon) {
            console.log('im an instance of L polygon');

          }
          if (layer instanceof L.Polyline) {
            console.log('im an instance of L polyline');
          }
          if (layer instanceof L.Circle) {
            console.log('im an instance of L circle');
          }

        });

      });

      map.on('draw:created', function (e) {
        //after npm install remove this event in LDrawToolbar.common.js
        let type = e.layerType;
        let layer = e.layer;

        if (_this.show_weather === true) {

          _this.$store.commit('SET_LATLNG', layer._latlng);
          _this.$store.commit('SET_SHOW_WEATHER', false);
          _this.$store.commit('SET_WEATHER_OBJECT', true);


        } else if (_this.create_path === true) {


          let editActions = [L.Toolbar2.EditAction.Popup.Edit, L.Toolbar2.EditAction.Popup.Delete, L.Toolbar2.Action.extendOptions({
            toolbarIcon: {
              className: 'leaflet-color-picker',
              html: '<i class="fas fa-fill-drip"></i>'
            },
            subToolbar: new L.Toolbar2({
              actions: [L.ColorPicker.extendOptions({
                color: 'rgb(219, 29, 15)'
              }), L.ColorPicker.extendOptions({
                color: '#025100'
              }), L.ColorPicker.extendOptions({
                color: '#ffff00'
              }), L.ColorPicker.extendOptions({
                color: '#0000ff'
              })]
            })
          })];
          layer.on('click', function (event) {
            this.editMode = true;
            new L.Toolbar2.EditToolbar.Popup(event.latlng, {
              actions: editActions
            }).addTo(map, layer);
          });

          layer.options.icon.options.iconUrl = '/tracking/images/icons/monitor_arrow/location-flag.svg'

          _this.path_counter++;
          let add = {..._this.path_latlngs}
          if (_this.path_counter === 1) {
            let marker = layer.addTo(map);
            add.start = layer._latlng;
            add.marker1 = marker
            _this.$store.commit('SET_PATH_LATLNGS', add);
          }
          if (_this.path_counter > 1) {
            add.finish = layer._latlng;
            add.marker2 = layer._leaflet_id
            _this.$store.commit('SET_PATH_LATLNGS', add);

            let startIcon = new L.Icon({
              iconUrl: '/tracking/images/icons/monitor_arrow/location-flag.svg',
              iconSize: [25, 41],
              iconAnchor: [6, 35],
              popupAnchor: [1, -34],

            });

            let finishIcon = new L.Icon({
              iconUrl: '/tracking/images/icons/monitor_arrow/finish_marker.svg',
              iconSize: [25, 41],
              iconAnchor: [2, 40],
              popupAnchor: [1, -34],

            });

            let simpleIcon = new L.Icon({
              iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
              iconUrl: require('leaflet/dist/images/marker-icon.png'),
              shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
              iconSize: [24, 37],
              iconAnchor: [12, 37]
            });

            let key_id = add.marker1._leaflet_id;

            let routing_control1 = L.Routing.control({
              waypoints: [
                L.latLng(add.start.lat, add.start.lng),
                L.latLng(add.finish.lat, add.finish.lng)
              ],
              draggableWaypoints: true,
              createMarker: function (i, wp, nWps) {
                if (i === 0) {
                  // here change the starting and ending icons
                  return L.marker(wp.latLng, {
                    icon: startIcon,
                    draggable: true,
                    bounceOnAddOptions: {
                      duration: 1000,
                      height: 800,
                      function() {
                        (bindPopup(myPopup).openOn(mymap))
                      }
                    },// here pass the custom marker icon instance
                  }).on('click', function (e) {
                    _this.showPath(e, key_id);
                  });
                } else if (i === nWps - 1) {
                  return L.marker(wp.latLng, {
                    icon: finishIcon,
                    draggable: true,
                    bounceOnAddOptions: {
                      duration: 1000,
                      height: 800,
                      function() {
                        (bindPopup(myPopup).openOn(mymap))
                      }
                    },
                  }).on('click', function (e) {
                    _this.showPath(e, key_id);
                  });
                } else {
                  return L.marker(wp.latLng, {
                    icon: simpleIcon,
                    draggable: true,
                    bounceOnAddOptions: {
                      duration: 1000,
                      height: 800,
                      function() {
                        (bindPopup(myPopup).openOn(mymap))
                      }
                    },
                  }).on('click', function (e) {
                    _this.showPath(e, key_id, true);
                  });
                }
              }

            }).addTo(map);

            _this.routing_control[key_id] = routing_control1
            map.removeLayer(add.marker1)

            layer.options.icon.options.iconUrl = '/tracking/images/icons/monitor_arrow/marker-icon.png'
            _this.$store.commit('SET_CREATE_PATH', false);
            _this.path_counter = 0;
          }

        } else {

          let item = {}
          let status = (new Date().getTime()).toString(6)
          e.layer.status = status
          let edit_zones = {..._this.polygon_zones}
          let data_now = Date.now()
          item.status = status;
          item.show = true;
          let add_type = 0
          let add_data = [];
          if (type === 'polygon') {
            item.type = 'polygon'
            item.color = '#FF7043'
            item.latlngs = layer._latlngs
            add_type = 2
            let add_rezult = map.addLayer(layer)
            let lat_arr = []
            let lng_arr = []
            item.latlngs[0].map(it => {
              lat_arr.push(it.lat)
              lng_arr.push(it.lng)
            })
            const average = arr => arr.reduce((p, c) => p + c, 0) / arr.length;
            item.center = [average(lat_arr), average(lng_arr)]
          }
          if (type === 'rectangle') {
            item.type = 'rectangle'
            item.color = '#FF7043'
            item.latlngs = layer._latlngs
            let add_rezult = map.addLayer(layer)
            let add1 = item.latlngs[0][0].lat
            let add2 = item.latlngs[0][1].lat
            let add3 = item.latlngs[0][0].lng
            let add4 = item.latlngs[0][3].lng
            item.center = [((add1 + add2) / 2), ((add3 + add4) / 2)]
            add_type = 0
          }
          if (type === 'circle') {

            item.type = 'circle'
            item.color = '#FF7043'
            item.latlng = layer._latlng
            item.mRadius = layer._mRadius
            item.center = [layer._latlng.lat, layer._latlng.lng]
            add_type = 1
          }
          if (type === 'polyline') {
            item.type = 'polyline'
            item.color = '#FF7043'
            item.latlngs = layer._latlngs
            item.center = [layer._latlngs[0][0].lat, layer._latlngs[0][0].lng]
            add_type = 5
          }
          map.removeLayer(e.layer)


          if (type === 'marker') {
            item.type = 'marker'
            item.color = '#FF7043'
            item.latlng = layer._latlng
            item.center = [layer._latlng.lat, layer._latlng.lng]
            item.mRadius = 0

            add_type = 1
          }
          if (type === 'circle' || type === 'marker') {
            add_data = {}
            add_data = {
              "x": item.center[1],
              "y": item.center[0],
              "r": item.mRadius / 1000
            }
          }

          if (type === 'marker') {
            add_data = {}
            add_data = {
              "x": item.latlng.lng,
              "y": item.latlng.lat,
              "r": 0
            }
            item.center = [item.latlng.lat, item.latlng.lng]

          }
          if (type === 'polygon' || type === 'polyline') {
            add_data = []
            item.latlngs[0].forEach(element => {
              let it = {"x": element['lng'], "y": element['lat']};

              add_data.push(it)
            });
          }
          if (type === 'rectangle') {
            add_data = {}
            add_data["X1"] = item.latlngs[0][0]['lng'];
            add_data["Y1"] = item.latlngs[0][0]['lat'];
            add_data["X2"] = item.latlngs[0][2]['lng'];
            add_data["Y2"] = item.latlngs[0][2]['lat'];

          }


          item.monitoring = {}
          item.info = {
            "ZoneId": 0,
            "GroupId": -1,
            "GroupName": null,
            "Name": "",
            "phones": "",
            "Comment": "",
            "Margin": 0.1,
            "Type": add_type,
            "Enabled": 1,
            "dt": data_now,
            "x": item.center[1],
            "y": item.center[0],
            "RootZoneId": -1,
            "MyClientId": 0,
            "MyClientName": null,
            "WeekDays": 0,
            "StopMinutes": 0,
            "StopFromHH": 0,
            "StopFromMM": 0,
            "StopToHH": 0,
            "StopToMM": 0,
            "WeekDays1": 0,
            "WeekDays2": 0,
            "WeekDays3": 0,
            "WeekDays4": 0,
            "bWeekDays": 0,
            "Boxes": 0,
            "CustomType": "",
            "Address": "",
            "RouteLists": [],
            "GeoIsEmpty": 0,
            "data": add_data
          }

          _this.$set(edit_zones, status, item);
          _this.$store.commit('SET_POLYGON_ZONES', edit_zones);

          _this.getAddress(item.center[1], item.center[0], status);
        }
      });

      if (this.user_route_settings) {
        let map_layer = null
        setTimeout(function () {

          Object.keys(_this.map_layers).map(function (key, index) {

            if (_this.map_layers[key]['name'] === _this.user_route_settings.googleMapTypeId) {
              map_layer = _this.map_layers[key]
            }
          });
          if (map_layer !== null) {
            _this.getCrsMap(map_layer, true)

            let add_width = _this.$refs.map.$el.clientWidth

            if (add_width < 1500) {
              _this.bound_padding['padding'] = [50, 50]
            }
            if (add_width < 1100) {
              _this.bound_padding['padding'] = [20, 20]
            }
            if (add_width < 500) {
              _this.bound_padding['padding'] = [10, 10]
            }
          }

        }, 200);


      }
      setTimeout(function () {
        if (_this.fuel_route.length > 0 && _this.active_route === 'fuel2') {
          _this.starZoomPolyline2()
        }
      }, 400);
    });

  },
  data() {
    return {
      cont_route_time: 0,
      show_poly_data:false,
      polyline_data:null,
      marker_color_palette:{
        "2979FF":"#0D47A1",
        "4FC3F7":"#039BE5",
        "42D692":"#24a76b",
        "9575CD":"#5E35B1",
        "323232":"#000000",
        "795548":"#4E342E",
        "AFB42B":"#827717",
        "B2FF59":"#64DD17",
        "FA573C":"#cf2205",
        "F57C00":"#E65100",
        "F4511E":"#BF360C",
        "FFA726":"#F57C00",
        "FFB399":"#ff9a99",
        "FFFF99":"#d4d400",
        "90A4AE":"#37474F",
        "BF360C":"#cf2205",
      },
      screen_size: 1,
      stop_marker_counter: 0,
      stom_marker_html: '',
      show_search_geoc: false,
      polyline_style: {zIndex: 10000},
      bound_padding: {padding: [100, 100]},
      zoomDuration: 2000,
      fadeAnimation: true,
      zoomAnimation: true,
      draggable_marker: true,
      draggable_lat: '',
      draggable_lng: '',
      tooltip_options: {
        direction: 'auto',
        opacity: 0.9
      },
      pallete: {
        0.0: 'green',
        0.5: 'yellow',
        1.0: 'red'
      },
      move_marker_close: false,
      select_color_path: "#F93154",
      path_html: '',
      path_markerLatLng: [0, 0],
      show_path_description: false,
      show_path_one: false,
      path_key_id: 0,
      routing_data: [],
      routing_control: {},
      renderComponent: true,
      maxZoom: null,
      map_attribution: null,
      map_subdomains: 'abc',
      map_name: "OpenStreetMap",
      path_counter: 0,
      base_url: base_url,
      geosearchOptions: { // Important part Here
        provider: new OpenStreetMapProvider(),
        animateZoom: true,
        autoClose: false,
      },
      clusterOptions: {
        disableClusteringAtZoom: 16,
        iconCreateFunction: cluster => {

          //console.log(cluster.getAllChildMarkers())
          let child_arr = cluster.getAllChildMarkers()
          let green = 0;
          let yellow = 0;
          let red = 0;
          let purple = 0;
          let total = 0;
          let details = '';
          let childCount = cluster.getChildCount();

          child_arr.map(item => {
            if (item.options.icon.options.status === 3) {
              green++
            }
            if (item.options.icon.options.status === 2) {
              red++
            }
            if (item.options.icon.options.status === 4) {
              purple++
            }
            if (item.options.icon.options.status === 1 || item.options.icon.options.status === 0) {
              yellow++
            }
            total++
            if (childCount < 9) {
              details += `  <div class="cluster-panel-item">
          <span >${item.options.icon.options.number}</span>
          <span class="text-primary" style="padding-left: 5px" >  ${'  ' + item.options.icon.options.speed} km/h</span>
        </div> `
            }
          })
          let chartDetails = `  <div class="cluster-panel-body"><div class="cluster-panel-list"> ${details} </div> </div> `;
          let procent = 100 / total;
          let c = " my-marker-cluster-";

          if (childCount >= 9) {
            chartDetails = `  <div class="cluster-panel-body">
      <div class="cluster-panel-list">
        <div class="cluster-panel-item">
          <span class="label label-success">${green}</span>
          <span >${this.lang.map_component.in_movement}</span>
        </div>
        <div class="cluster-panel-item">
          <span class="label label-danger">${red}</span>
          <span >${this.lang.map_component.stopped}</span>
        </div>
        <div class="cluster-panel-item">
          <span class="label label-purple" >${purple}</span>
          <span >${this.lang.map_component.stopped_with_engine_on}</span>
        </div>
        <div class="cluster-panel-item">
          <span class="label label-warning">${yellow}</span>
          <span >${this.lang.map_component.offline}</span>
        </div>
      </div>

    </div> `
          }


          if (childCount < 10) {
            c += "small";
          } else if (childCount < 100) {
            c += "medium";
          } else {
            c += "large";
          }
          let add_style = `background-image: conic-gradient(#69F0AE 0%,#69F0AE ${procent * green}%,#FF8A65 ${procent * green + 0.5}%, #FF8A65 ${procent * green + procent * red}%,#9FA8DA ${procent * green + procent * red + 0.5}%, #9FA8DA ${procent * green + procent * red + procent * purple}%, #FFE082 ${procent * green + procent * red + procent * purple + 0.5}%, #FFE082 100%)`
          return new DivIcon({
            html: `<div class="round-cluster-main"><div class="round-cluster-box">
<div class="pie-chart-map" style="${add_style}"></div>
<div class="pie-chart-map-total ">${childCount}
</div>
</div>
${chartDetails}
</div>`,
            className: "markers-cluster" + c,
            iconSize: new Point(40, 40)
          })
        }

      },
      add_iconSize: [40, 40],
      add_iconAnchor: [20, 40],
      add_iconSize2: [25, 25],
      add_iconAnchor2: [12, 25],
      add_iconSize3: [50, 50],
      add_iconAnchor3: [25, 50],
      object_marker_data: '',
      openPopup: false,
      polilyin_opions: {
        polyline_counter: 0
      },
      customText: "Foobar",
      main_url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',

      map_layers: {
        osm: {
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          name: 'OpenStreetMap',
          maxZoom: 18,
        },
        osm_de: {
          url: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
          name: 'OpenStreetMapDE',
          maxZoom: 18,
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        },

        trackpro: {
          url: '//tiles.trackpro.lv/cdn/styles/klokantech-basic/{z}/{x}/{y}.png',
          attribution: '',
          maxZoom: 18,
          name: 'Sizzapp'
        },
        googlee: {
          url: 'https://mt{s}.googleapis.com/vt?x={x}&y={y}&z={z}',
          maxZoom: 18,
          subdomains: ['0', '1', '2', '3'],
          name: 'Google'
        },
        CartoDB_Positron: {
          url: '//{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
          attribution: '&copy; <a href="//www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
          subdomains: ['0', '1', '2', '3'],
          maxZoom: 18,
          name: 'Positron'
        },
        CartoDB_DarkMatter: {
          url: '//{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
          subdomains: ['0', '1', '2', '3'],
          maxZoom: 18,
          name: 'DarkMatter'
        },

        OpenTopoMap: {
          url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
          maxZoom: 17,
          name: 'OpenTopoMap',
          attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
        },
        Esri_WorldStreetMap: {
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
          attribution: 'Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012',
          name: 'WorldStreetMap',
          maxZoom: 18,
        },
        Esri_WorldTopoMap: {
          url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
          attribution: 'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community',
          name: 'WorldTopoMap',
          maxZoom: 18,
        },
        googleHybrid: {
          url: '//{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}&hl=',
          maxZoom: 18,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          name: 'Hybrid'
        },
        googleSat: {
          url: '//{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}&hl=',
          maxZoom: 18,
          subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
          name: 'Satelite'
        },
        /* openMapSurfer: {
           url: '//openmapsurfer.uni-hd.de/tiles/roads/x={x}&y={y}&z={z}',
           maxZoom: 18,
           attribution:'',
           name: 'openMapSurfer'
         },*/
      },

      iconWidth: 25,
      iconHeight: 40,
      icon: icon({
        iconUrl: '/tracking/images/icons/monitor_arrow/location-pointer.svg',
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      currentZoom: 18,
      show_polyline: true,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      work_objects: 'OBJECTS',
      show_object_list: 'SHOW_OBJECT_LIST',
      start_center: 'START_CENTER',
      map_zoom: 'MAP_ZOOM',
      search_icons: 'SEARCH_ICONS',
      show_only_search: 'SHOW_ONLY_SEARCH',
      show_geo_zone: 'SHOW_GEO_ZONE',
      polygon_zones: 'POLYGON_ZONES',
      show_weather: 'SHOW_WEATHER',
      create_path: 'CREATE_PATH',
      path_latlngs: 'PATH_LATLNGS',
      lang_short: 'LANG_SHORT',
      select_history: 'SELECT_HISTORY',
      history_table_show: 'HISTORY_TABLE_SHOW',
      history_data: 'HISTORY_DATA',
      settings_active: 'SETTINGS_ACTIVE',
      settings_options: 'SETTINGS_OPTIONS',
      move_marker_show: 'MOVE_MARKER_SHOW',
      move_marker_data: 'MOVE_MARKER_DATA',
      active_route: 'ACTIVE_ROUTE',
      fuel_route: 'FUEL_ROUTE',
      user_route_settings: 'USER_ROUT_SETTINGS',
      show_all_objects: 'SHOW_ALL_OBJECTS',
      show_all_zones: 'SHOW_ALL_ZONES',
      fuel_marker: 'FUEL_MARKER',
      fuel_fill_markers: 'FUEL_FILL_MARKERS',
      reload_map: 'RELOAD_MAP',
      user_auth: 'USER_AUTH',
      update_polyline: 'UPDATE_POLYLINE',
      stop_markers: 'STOP_MARKERS',
      bound_cluster: 'BOUND_CLUSTER',
      zone_bound: 'ZONE_BOUND',
      settings_fuel_active: 'SETTINGS_FUEL_ACTIVE',
      start_search:'START_SEARCH',
      current_group: 'CURRENT_GROUP',
      current_group_list: 'CURRENT_GROUP_LIST',
      current_resources:'CURRENT_RESOURCES',
    }),
    mutatedZoom() {
      return this.map_zoom;
    },
    iconUrl() {
      return `/tracking/images/icons/object_types/type%20(1).png`;
    },
    iconSize() {
      return [this.iconWidth, this.iconHeight];
    },
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },

  },
  watch: {
    zone_bound(newVal, oldVal) {
      let $this = this
      if ($this.$refs[newVal][0] && $this.$refs[newVal][0] && $this.$refs[newVal][0].mapObject) {
        $this.$refs.map.mapObject.fitBounds($this.$refs[newVal][0].mapObject.getBounds(), $this.bound_padding);
      }
    },
    bound_cluster(newVal, oldVal) {
      if (newVal !== oldVal) {
        let $this = this
        $this.$refs.map.mapObject.fitBounds($this.$refs.clusterRef.mapObject.getBounds(), $this.bound_padding);
      }
    },
    update_polyline(newVal, oldVal) {
      if (newVal.counter !== oldVal.counter) {
        if (newVal.type === 1) {
          this.starZoomPolyline()
        }
        if (newVal.type === 2) {
          this.starZoomPolyline2()
        }
        if (newVal.type === 3) {
          this.starZoomPolyline3()
        }
        if (newVal.type === 4) {
          this.starZoomMarker(newVal.marker_ref)
        }
      }

    },
    select_history(newVal, oldVal) {
      if (newVal === true) {
        if (this.checkObjectLength(this.routing_control)) {

          let map = this.$refs.map.mapObject;
          let $this = this;
          Object.keys(this.routing_control).forEach(key => {
            map.removeControl($this.routing_control[key]);
          });

        }
      } else {
        if (this.checkObjectLength(this.routing_control)) {
          let map = this.$refs.map.mapObject;
          let $this = this;
          Object.keys(this.routing_control).forEach(key => {

            $this.routing_control[key].addTo(map);
          });
        }
      }
    },
    work_objects(newVal, oldVal) {


      if (Object.keys(this.search_icons).length > 0) {

        let changes = {...this.search_icons}
        if(this.cont_route_time % 2) {

          Object.keys(changes).forEach(key => {
            let obj = changes[key];
            let formData = new FormData();

            let end_time =  moment().endOf('day').format("DD.MM.YYYY HH:mm:ss")

            formData.append('objectId', obj.ObjectId);
            formData.append('dt1', obj.start_time);
            formData.append('dt2', end_time);

            axios.post(base_url + '/routes/getroutesimple', formData).then(res => {

              if(res.status === 200 && res.data.success === true){

                if(res.data.data.length > 0){

                  let item = newVal.find(item => item.ObjectId === key);
                  if(item != null){
                    changes[key].Y = item.Y
                    changes[key].X = item.X
                    changes[key].Angle = item.Angle
                  }


                    changes[key].polyline.latlngs =  res.data.data
              //    console.log(res.data.data)
                }
              }
            }).catch(error => {
              console.log(error)
            })


          });
        }

        this.cont_route_time++;
      //  this.show_polyline = false;
        this.$store.commit('SET_SEARCH_ICONS', changes);


      /*  let $this = this
        setTimeout(function () {
          $this.show_polyline = true;

        }, 1000);
*/

      }


    },
    map_zoom(newVal, oldVal) {
      this.$refs.map.setZoom(newVal)
    },
    start_center(newVal, oldVal) {
      this.$refs.map.setCenter(newVal)
    },
    reload_map(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$refs.map.mapObject.invalidateSize()
      }
    },
  },
  beforeMount() {
    window.addEventListener("beforeunload", this.preventNav)
  },

  methods: {
    async getSimpleRoute(obj){
      let formData = new FormData();
      let data_array  = [];

      let end_time =  moment().endOf('day').format("DD.MM.YYYY HH:mm:ss")

      formData.append('objectId', obj.ObjectId);
      formData.append('dt1', obj.start_time);
      formData.append('dt2', end_time);


      await  axios.post(base_url + '/routes/getroutesimple', formData).then(res => {

       if(res.status === 200 && res.data.success === true){

         if(res.data.data.length > 0){
           data_array = res.data.data

         }

       }

      }).catch(error => {

        console.log(error)

      })

    },
    getMarkerRoute() {
      let url_add = '/tracking/images/icons/monitor_arrow/place-marker.svg'
      if (this.settings_options[this.settings_active].route_marker_color && this.settings_options[this.settings_active].route_marker_color.hex) {

        url_add = '/tracking/images/icons/monitor_arrow/marker_' + this.settings_options[this.settings_active].route_marker_color.hex.substring(1) + '.svg'

      }
      return url_add
    },
    getIconRoute(index_number) {

      let add_color ='#2979FF'
      if (this.settings_options[this.settings_active].route_marker_color && this.settings_options[this.settings_active].route_marker_color.hex) {
         add_color = this.settings_options[this.settings_active].route_marker_color.hex
      }

      let add_color_edit = add_color.replace(/#/g, '')


      let add_margin = (index_number > 9 ) ? 15 : 25;

      let svgIcon = L.divIcon({
        className: "leaflet-marker-icon leaflet-zoom-animated leaflet-interactive",
        iconSize:[50, 50],
        iconAnchor:[25, 50],
        html: `
<svg width="42px" height="42px" viewBox="-5 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <path id="Path_19" data-name="Path 19" d="M1002,44a10,10,0,0,0-10,10c0,5.523,10,20,10,20s10-14.477,10-20A10,10,0,0,0,1002,44Zm0,13a3,3,0,1,1,3-3A3,3,0,0,1,1002,57Z" transform="translate(-991 -43)" fill="`+add_color+`" stroke="`+this.marker_color_palette[add_color_edit]+`" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>

     <circle id="sd" class="medium" cx="11" cy="11" r="7" fill="#ffffff" stroke="lightblue" stroke-width="0.5%" >

  </circle>
  <text x="`+ add_margin +`%" y="45%" stroke="#404040" stroke-width="0.2px" dy=".12em">${index_number}</text>
  </svg>`,
      });

      return svgIcon
    },
    openGesearch() {

      this.show_search_geoc = !this.show_search_geoc

      if (this.show_search_geoc === false) {

        document.getElementsByClassName("leaflet-control-geosearch")[0].style.display = "none";
      } else {
        document.getElementsByClassName("leaflet-control-geosearch")[0].style.display = "block";
      }


    },
    setClusterBind() {
      let $this = this
      setTimeout(function () {
        if (Object.keys($this.work_objects).length > 0) {
          $this.$refs.map.mapObject.fitBounds($this.$refs.clusterRef.mapObject.getBounds(), $this.bound_padding);
        } else {
          setTimeout(function () {
            if (Object.keys($this.work_objects).length > 0) {
              $this.$refs.map.mapObject.fitBounds($this.$refs.clusterRef.mapObject.getBounds(), $this.bound_padding);
            }
          }, 400);

        }

      }, 400);
    },
    getFormData(formData, data, previousKey) {
      if (data instanceof Object) {
        Object.keys(data).forEach(key => {
          const value = data[key];
          if (value instanceof Object && !Array.isArray(value)) {
            return this.getFormData(formData, value, key);
          }
          if (previousKey) {
            key = `${previousKey}[${key}]`;
          }
          if (Array.isArray(value)) {
            value.forEach(val => {
              formData.append(`${key}[]`, val);
            });
          } else {
            formData.append(key, value);
          }
        });
      }
    },
    saveLoationZone(event, zone) {


      let formData = new FormData();
      if (zone.info && zone.info.ZoneId) {
        let params = {
          ZoneData: {
            ZoneId: zone.info.ZoneId,
            Name: zone.info.Name,
            Type: zone.info.Type,
          }
        }
        formData.append('ZoneData[ZoneId]', zone.info.ZoneId);
        formData.append('ZoneData[Name]', zone.info.Name);
        formData.append('ZoneData[Type]', zone.info.Type);
        if (zone.type === 'polygon') {
          params.ZoneData.Data = [];
          let i = 0;

          zone.latlngs[0].map(elem => {
            params.ZoneData.Data[i] = {'x': elem.lng, 'y': elem.lat}
            formData.append("ZoneData[Data][" + i + "][x]", elem.lng);
            formData.append("ZoneData[Data][" + i + "][y]", elem.lat);
            i++;
          })
          params.ZoneData.Center = {'x': zone.center[1], 'y': zone.center[0]}

        }
        if (zone.type === 'rectangle') {
          params.ZoneData.Data = {
            'X1': zone.latlngs[0][0].lng,
            'Y1': zone.latlngs[0][0].lat,
            'X2': zone.latlngs[0][2].lng,
            'Y2': zone.latlngs[0][2].lat,
          };
          formData.append("ZoneData[Data][X1]", zone.latlngs[0][0].lng);
          formData.append("ZoneData[Data][Y1]", zone.latlngs[0][0].lat);
          formData.append("ZoneData[Data][X2]", zone.latlngs[0][2].lng);
          formData.append("ZoneData[Data][Y2]", zone.latlngs[0][2].lat);
          params.ZoneData.Center = {'x': zone.center[1], 'y': zone.center[0]}
        }
        if (zone.type === 'circle' || zone.type === 'marker') {
          params.ZoneData.Data = {
            'x': zone.latlng[1],
            'y': zone.latlng[0],
            'r': zone.mRadius / 1000,
          };
          formData.append("ZoneData[Data][x]", zone.latlng[1]);
          formData.append("ZoneData[Data][y]", zone.latlng[0]);
          formData.append("ZoneData[Data][r]", zone.mRadius / 1000);
          params.ZoneData.Center = {'x': zone.center[1], 'y': zone.center[0]}
        }
        formData.append("ZoneData[Center][x]", zone.center[1]);
        formData.append("ZoneData[Center][y]", zone.center[0]);
        params.ZoneData.bUpdateCoords = 1
        formData.append("ZoneData[bUpdateCoords]", 1);
        let $this = this


        /*$.ajax({
          url: base_url + '/geozones/updatezone/',
          type: 'POST',
          data: params,
          crossDomain: true,
          xhrFields: {
            withCredentials: true
          },
          hea
          success: function (res) {
            if (typeof res === 'string') {
              $this.$refs['marker_' + zone.status][0].mapObject.closePopup()
            }
          },
          error: function (error) {
            console.log(error);
          }
        });*/

        /*    console.log(params.ZoneData)
        let add_params = new URLSearchParams();
        add_params.append('ZoneData[]', params.ZoneData);

        let  headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        };*/
        axios.post(base_url + '/geozones/updatezone/', formData).then(res => {
          if (typeof res.data === 'string') {
            $this.$refs['marker_' + zone.status][0].mapObject.closePopup()


            //$this.closeEditZone()
          }
        }).catch(error => {
          console.log(error)

        })

      } else {
        let message = this.lang.map_component.alert_not_save
        this.$iosAlert(message)
      }

    },
    makeDrugabbleMarker(event, zone) {
      this.draggable_lat = event.latlng.lat
      this.draggable_lng = event.latlng.lng
    },
    removeDrugabbleMarker(event, zone) {
      this.$nextTick(() => {
        let lat_add = event.latlng.lat - this.draggable_lat
        let lng_add = event.latlng.lng - this.draggable_lng
        if (zone.type === 'polygon' || zone.type === 'rectangle') {
          zone.latlngs[0].map(elem => {
            elem.lat = elem.lat + lat_add;
            elem.lng = elem.lng + lng_add;
          })
          zone.center = [zone.center[0] + lat_add, zone.center[1] + lng_add]
        }
        if (zone.type === 'circle' || zone.type === 'marker') {

          let lat_add = event.latlng.lat - this.draggable_lat
          zone.latlng = [zone.center[0] + lat_add, zone.center[1] + lng_add]
          zone.center = [zone.center[0] + lat_add, zone.center[1] + lng_add]

        }

      });
      event.target.openPopup();

    },
    infoMarker(event, zone) {
    },

    getMarkerCoordinat(row) {
      let coordinat_arr = [row.Y, row.X];
      return coordinat_arr
    },


    getPolylineHotCoordinat(rows) {
      let coordinat_arr = [];
      let i = 0;
      rows.forEach(element => {
        let speed = 0
        if (element.speed) {
          speed = parseInt(element.speed)
          if (speed > 20) {
            speed = parseInt(element.speed - 10)
          }

        }
        coordinat_arr[i] = [element.Y, element.X, speed];
        i++;
      });

      return coordinat_arr
    },
    getPolylineCoordinat(rows) {
      let coordinat_arr = [];
      let i = 0;
      rows.forEach(element => {
        coordinat_arr[i] = [element.Y, element.X];
        i++;
      });

      return coordinat_arr
    },
    starZoomPolyline() {
      if (this.$refs.history_polylinr_simple !== undefined) {
        if (this.$refs.history_polylinr_simple.mapObject) {
          let $this = this
          let check = this.$refs.history_polylinr_simple.mapObject.getBounds()

          if (Object.keys(check).length > 0) {
            this.$refs.map.mapObject.fitBounds(check, $this.bound_padding);
          }
        }
      }
    },
    starZoomPolyline2() {
      if (this.$refs.history_polylinr_simple2.mapObject) {
        let $this = this
        this.$refs.map.mapObject.fitBounds($this.$refs.history_polylinr_simple2.mapObject.getBounds(), $this.bound_padding);
      }
    },
    starZoomPolyline3() {

      let add = 'polyline_' + this.update_polyline.counter.toString()

      if (this.$refs[add]) {
        let $this = this
        let num = this.$refs[add].length
        this.$refs.map.mapObject.fitBounds($this.$refs[add][num - 1].mapObject.getBounds(), $this.bound_padding);
        $this.$refs[add][num - 1].mapObject.bringToFront()
      }
    },
    starZoomMarker(marker_ref) {

      let add = marker_ref

      if (this.$refs[add] && this.$refs[add][0]) {
        let latlngs = this.$refs[add][0].mapObject.getLatLng()

        let zoomName = (this.screen_size === 2) ? 16 : 18;

        this.$refs.map.mapObject.setView(latlngs, zoomName);

      }
    },
    getBigPolylineCoordinat(routs) {
      let coordinat_arr = [];
      let i = 0;
      if (routs.length > 0) {
        routs.forEach(item => {
          item['r'].forEach(element => {
            coordinat_arr[i] = [element.Y, element.X];
            i++;
          });
        });
      }
      return coordinat_arr
    },
    getAddress(poly_lang, pole_lat, poly_status) {
      let _this = this
      $.ajax({
        url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + pole_lat + ',' + poly_lang + '&key=AIzaSyCoAoGJxRqTz8JJjOwHb4D64FqFC2IT0mE',
        type: 'GET',
        success: function (res) {

          if (res.results) {
            let add = {..._this.polygon_zones}
            add[poly_status].info.Address = res.results[0].formatted_address

          }
        },
        error: function (error) {
          console.log(error);
        }
      });

    },
    showPath(e, key_id, middle = false) {
      this.path_key_id = key_id;
      this.path_markerLatLng = e.latlng
      this.show_path_one = true;
      let add = this.routing_control[key_id];
      this.show_path_description = add._routes[0]

      if (middle === false) {
        this.show_path_description.subname = ''
        this.show_path_description.subtext = ''
      } else {

        let check = add._routes[0].inputWaypoints;
        let k = 0;
        let index = 0;
        check.map(item => {
          if (item.latLng.lat === e.latlng.lat) {
            index = k;
          }
          k++;
        });
        let rezult_check = add._routes[0].waypointIndices[index];
        let coordinat = add._routes[0].instructions;
        let street = ''
        let text = ''
        coordinat.map(elem => {
          if (elem.index === rezult_check) {
            street += elem.road
            text = elem.text
          }
        });
        this.show_path_description.subname = street
        this.show_path_description.subtext = text
      }

    },
    removePath() {
      let num = this.path_key_id;
      let map = this.$refs.map.mapObject;
      if (num) {
        let remove_path = this.routing_control[num];
        if (remove_path) {
          map.removeControl(remove_path);
        }
      }
      this.show_path_one = false;

    },
    deleteRout(route) {

      let map = _this.$refs.map.mapObject;
      map.removeControl(route);

    },
    preventNav(event) {
      let add = {...this.polygon_zones}
      let add2 = JSON.stringify(add)
      // let center = JSON.stringify(this.start_center)
      this.$localStorage.set('polygon_zones', add2)
      //this.$localStorage.set('zoom', this.map_zoom)
      //this.$localStorage.set('start_center', center)
    },

    getRoutPath() {
      this.path_latlngs
    },
    showGeoZone() {
      let add = true
      if (this.show_geo_zone) {
        add = false;
      }
      this.$store.commit('SET_SHOW_GEO_ZONE', add);
    },
    startdraw() {
      // console.log('use geo zoe map answer')
    },
    getGeoZone() {
      // console.log('use geo zoe toolbar')
    },
    click: (e) => console.log("clusterclick"),

    getToltipHtml2(obj) {
      let $this = this
      let address = '';
      if (!obj.address || obj.address.length < 1) {
        let formData3 = new FormData();

        formData3.append('params[0][x]', obj.lng);
        formData3.append('params[0][y]', obj.lat);

        axios.post(base_url + '/index/revgeocoding', formData3
        ).then(response => {

          if (response.data && response.data[0]) {
            obj.address = response.data[0]
          }
          $this.getText(obj, address)
        }).catch(error => {
          console.log(error)
          $this.getText(obj, address)
        })


      } else {
        $this.getText(obj, address)
      }


    },
    getText(obj, address) {
      let $this = this
      let add_item = this.work_objects.find(item => item.ObjectId === $this.settings_active);
      let html = `
          <div class="stop-marker-item">

           <div>
          <span> ${this.lang.map_component.number}</span> : <span>${add_item.Number}</span>
          </div>
           <div>
          <span> ${this.lang.map_component.driver}</span> : <span><${add_item.DriverName}/span>
          </div>
          <div>
          <span> ${this.lang.map_component.company_name}</span> : <span><${add_item.Name}</span>
          </div>
           <div>
          <span> ${this.lang.map_component.address}</span> : <span class="stop-marker-address">${obj.address ? obj.address : address}</span>
          </div>

           <div>
          <span> ${this.lang.map_component.gps_time}</span> : <span>${obj.datetime}</span>
          </div>
           <div>
          <span> ${this.lang.map_component.speed}</span>:<span>${obj.s} km/h</span>
          </div>

          </div>
          `

      this.stom_marker_html = html
      this.stop_marker_counter = obj.t
    },
    getToltipHtml(obj) {

      let html = `<div class="rotation-icon"><div class="driver-names simple" ><span class="driver-disc-box"><span>${obj.Number}</span>
</span><span class="driver-speed"> ${'  ' + obj.speed} km/h</span></div> </div>`;
      return html
    },
    GetObjectData(obj) {

      this.$store.dispatch('getObjectInfo', obj.ObjectId);
      this.$store.commit('SET_SHOW_OBJECT', true);
    },
    getValueLatLangs(val) {

    },
    checkObjectLength(obj) {
      if (Object.keys(obj).length > 0) {
        return true
      } else {
        return false
      }
    },
    changeMapZoom() {
      this.$store.commit('SET_MAP_ZOOM', 8);
      this.$refs.map.setZoom(8)

    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
      if (this.map_zoom != this.currentZoom) {
        this.$store.commit('SET_MAP_ZOOM', this.currentZoom);
      }
    },
    checkIcon(obj, index) {

      if (this.show_object_list == null) {
        return true
      } else {
        let add_key = Object.keys(this.show_object_list)[0]

        if (add_key === 'Select') {

          let query = this.show_object_list[add_key].toLowerCase()
          if (obj.Name.toLowerCase().includes(query)
              || obj.Number.toLowerCase().includes(query)
              || (obj.DriverName && obj.DriverName.toLowerCase().includes(query))
          ) {
            return true
          } else {
            return false
          }
        }

        if (this.show_object_list[add_key] === '0000') {
          if (obj[add_key] == '0000' || obj[add_key] === 'problem') {
            return true
          } else {
            return false
          }
        } else {
          if (obj[add_key] === this.show_object_list[add_key]) {
            return true
          } else {
            return false
          }
        }
      }

    },
    getStaticIcon(obj) {
      // console.log(base_url+"/images/icons/statuses/o-status-0.png");

      let status = 1
      this.search_type = 'movement';
      if (obj['statusImage'] === 'i1s1') {
        status = 3
      }
      if (obj['statusImage'] === 'i0s0') {
        status = 2
      }
      if (obj['statusImage'] === 'i1s0') {
        status = 4
      }
      if (obj['statusImage'] === '0000') {
        status = 0
      }


      return icon({
        iconUrl: "/tracking/images/icons/statuses/o-status-" + status + ".png",
        status: status,
        number: obj.Number,
        speed: obj.speed,
        iconSize: [32, 33],
        iconAnchor: [16, 37]
      })
    },
    getStaticIcon4(obj) {

      let status = 1
      this.search_type = 'movement';
      if (obj['statusImage'] === 'i1s1') {
        status = 3
      }
      if (obj['statusImage'] === 'i0s0') {
        status = 2
      }
      if (obj['statusImage'] === 'i1s0') {
        status = 4
      }
      if (obj['statusImage'] === '0000') {
        status = 0
      }

      return icon({
        iconUrl: "/tracking/images/icons/monitor_arrow/monitor-arrow-"+status+"-t.svg",
        status: status,
        number: obj.Number,
        speed: obj.speed,
        iconSize: [44, 44],
        iconAnchor: [22, 22],
        className: 'rotate-icon'
      })
    },
    getStaticIcon3(obj) {
      return icon({
        iconUrl: "/tracking/images/icons/monitor_arrow/monitor-arrow-green.svg",
        speed: obj.s,
        iconSize: [22, 23],
        iconAnchor: [10, 10],
        className: 'rotate-icon'
      })
    },


    getStaticIcon2(obj) {
      status = this.calculateAngle(obj.Angle)
      return icon({
        iconUrl: "/tracking/images/icons/monitor_arrow/monitor-arrow.svg",
        status: status,
        number: obj.Number,
        speed: obj.speed,
        iconSize: [22, 23],
        iconAnchor: [10, 10],
        className: 'rotate-icon'
      })
    },
    getRotate(obj) {
      return parseInt(obj.Angle) / 2
    },
    getCrsMap(map_layer, check = false) {
      this.renderComponent = false;
      if (map_layer.maxZoom) {
        this.maxZoom = map_layer.maxZoom
      } else {
        this.maxZoom = null
      }
      if (map_layer.subdomains) {
        this.map_subdomains = map_layer.subdomains;
      } else {
        this.map_subdomains = 'abc'
      }
      if (map_layer.name === 'Hybrid' || map_layer.name === 'Satelite') {
        this.main_url = map_layer.url + this.lang_short;
      } else {
        this.main_url = map_layer.url;
      }
      this.map_name = map_layer.name;

      this.$nextTick(() => {
        this.renderComponent = true;
        if (!check) {
          let formData = new FormData();
          formData.append("ParameterName", 'googleMapTypeId')
          formData.append("ParameterValue", map_layer.name)

          this.$store.dispatch('setUserParametrs', formData);
        }
      });
    },
    changeIcon() {
      this.iconWidth += 2;
      if (this.iconWidth > this.iconHeight) {
        this.iconWidth = Math.floor(this.iconHeight / 2);
      }
    },
    connectionClick(event, pol){
      let _this = this;
      let item = event.latlng
      let lat = parseFloat((item.lat).toFixed(6));
      let lng = parseFloat((item.lng).toFixed(6));
      this.circlePolylineCheck(pol, 0.005,lat,lng);
    },
    circlePolylineCheck(check_arr3, add_number,lat,lng){
        let new_num = add_number-0.0002;
        let chek4 = 0;
        let check_arr4 = [];
        for(let m =0; m< check_arr3.length; m++){
          if((parseFloat(check_arr3[m]['Y']) > (lat-new_num)) &&  (parseFloat(check_arr3[m]['Y']) < (lat+new_num)) && (parseFloat(check_arr3[m]['X']) > (lng-new_num)) &&  (parseFloat(check_arr3[m]['X']) < (lng+new_num))){
            chek4 ++;
            check_arr4.push(check_arr3[m]);
          }
        }
        if( chek4 > 2){
          this.polyline_data = check_arr4[0];
          this.polyline_data.location = [ lat,  lng]
          this.circlePolylineCheck(check_arr4,new_num,lat,lng)
        }else{
          if(check_arr4.length>0) {
            this.show_poly_data = false;
            this.polyline_data = check_arr4[0];
            this.polyline_data.location = [ lat,  lng]
            this.showPolyData();
          }else{
            this.show_poly_data = false;
            this.showPolyData();
          }
        }

    },
    showPolyData(){
      this.show_poly_data = true;
    }

  },
}
</script>

<style scoped>
.zoom-custom-control {
  background: #ffffff;
  top: 20px;
  right: 200px;
  border-radius: 5px;
  height: 60px;
  width: 60px;
  position: absolute;
  z-index: 10000;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;

}


.zoom-custom-control .btn {
  outline: none;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 11px;
  background: transparent;
  width: 60px;
}

.example-custom-control {
  background: #ffffff;
  top: 5px;
  right: 100px;
  border-radius: 5px;
  height: 60px;
  width: 60px;
  position: absolute;
  z-index: 10000;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  transition: height 0.26s ease;
  transition: width 0.26s ease;
}

.example-custom-control:hover, .example-custom-control:focus {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.example-custom-control p {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fas.fa-layer-group {
  font-size: 32px;
}

.show-map-list {
  display: none;
  height: 60px;
  width: 60px;
  background: #ffffff;
  position: absolute;
  top: 10px;
  left: 0;
  transition: height 0.26s ease;
  transition: width 0.26s ease;

}

.example-custom-control:hover, .example-custom-control:focus {
  height: 220px;
  width: 120px;
}

.example-custom-control:hover .show-map-list, .example-custom-control:focus .show-map-list {
  display: block;
  height: 220px;
  width: 120px;

}

.object-marker-data {
  background: #ffffff;
  top: 100px;
  right: 500px;
  border-radius: 5px;
  height: 160px;
  width: 160px;
  position: fixed;
  z-index: 10000;
}

.btn-geo {
  outline: none;
  width: 100%;
  height: 100%;
  background-color: transparent;

}

.map-layer-list {
  margin-bottom: 0;
  padding-left: 10px;
  list-style: none;
}

.map-layer-name:hover, .map-layer-name:focus {
  cursor: pointer;
  color: #1C71DA;
}

.map-layer-name.active {
  color: #00C853;
}

.move-marker-dat-box {
  background-color: #ffffff;
  display: block;
  min-width: 180px;
  width: auto;
  padding: 5px;
  opacity: 0.9;
  border-radius: 4px;
  display: block;
  position: relative;
}

.move-marker-dat-box ul {
  list-style: none;
  padding-left: 5px;
}

.move-marker-dat-btn {
  position: absolute;
  top: 2px;
  right: 2px;
  font-size: 12px;
  padding: 2px 5px;
}

.move-marker-dat-value.green {
  color: #00953e;
  font-weight: 600;
}

.move-marker-dat-value.red {
  color: #DD2C00;
  font-weight: 600;
}

.stop-marker-item {
  padding: 0px;
  min-width: 200px;
}

.stop-marker-address {
  display: block;
  word-break: break-all;
  white-space: normal;
}

.move-marker-stop-box {
  background-color: #b8e7ff;
  display: block;
  min-width: 220px;
  width: auto;
  padding: 15px;
  opacity: 0.9;
  border-radius: 4px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .example-custom-control {
    top: 180px;
    right: 10px;
  }

  .show-map-list {
    top: 0;
    left: unset;
    right: 0;
  }

  .example-custom-control:hover .show-map-list, .example-custom-control:focus .show-map-list {
    display: block;
    min-height: 220px;
    min-width: 120px;
    height: auto;
    padding: 10px;
    width: 150px;
  }

}
</style>
