<template>
<div>
  <template v-if="loading_page">
    <section class="body-sign center-body">
    <div class="center-box-sign">
      <ring-loader :height="loading_size"/>
    </div>
    </section>
  </template>
  <template v-if="!loading_page">
  <template v-if="user_auth">
  <geoloc-component></geoloc-component>
</template>
  <template v-else>
    <login-component></login-component>
  </template>
  </template>
</div>
</template>
<script>

import {mapGetters} from "vuex";
import LoginComponent from "@/components/login/LoginComponent";
import GeolocComponent from "@/GeolocComponent";
import axios from "axios";
import MapTestComponent from "@/components/map/MapTestComponent";
import RingLoader from 'vue-spinner/src/RingLoader.vue'
export default {
  name: 'App',
  components: {
    MapTestComponent,
    GeolocComponent,
    LoginComponent,
    RingLoader
  },
  data() {
    return {
      loading_page:true,
      loading_size:"57px",
      base_url:base_url,
    }
  },
  computed: {
    ...mapGetters({
      user_auth:'USER_AUTH'
    }),
  },
  mounted() {
    this.checkAuth()
  },
  methods:{
    checkAuth(){
      let formData = new FormData();

      axios.post(base_url+ '/auth/logged', formData).then(response => {
        if( response.data['success']===true){
          if(response.data['auth']===true){
            this.$store.commit('SET_USER_AUTH', true);
          }else{

          }
        }else{

        }
        this.loading_page = false
      }).catch(error => {
        console.log(error)
      })
    }
  }

}
</script>
