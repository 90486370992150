<template>
  <div class="chart-box__chart " ref="box" v-resize="reloadChart">
    <div class="remove-fuel-chart" @click="removeChart()"><svg-refresh-icon/></div>
    <template v-if="!loading && show_chart_bar">
      <fuel-bar-component
        :chartData="chartData"
        :title="chart_title"
        :event_data="event_data"
        :green_data="bg1"
        :red_data="bg2"
        @update-cart="updateCart"
        :status="status"
        :yMax = "max_y"
        :fuel_type ="fuel_type"
        v-on:chart:render="chartInfo()"
      />
    </template>
    <template v-else>
      <div class="loader-grid">
        <grid-loader :height="loading_size" ></grid-loader>
      </div>
    </template>
  </div>
</template>

<script>

import {mapGetters} from "vuex";
import FuelBarComponent from "@/components/charts/FuelBarComponent";
import moment from 'moment'
import SvgRefreshIcon from "@/components/ui/icons/svgRefreshIcon";
import GridLoader from 'vue-spinner/src/GridLoader.vue'
import axios from "axios";
import resize from 'vue-resize-directive'


export default {
  name: "FuelChartComponent",
  components: {SvgRefreshIcon, FuelBarComponent,GridLoader},
  directives: {
    resize
  },
  props:{
    fuel_type:{
      type:String,
      default:'fuel_2'
    }
  },
  data() {
    return {
      reload_chart_counter:0,
      chartData:{},
      show_chart_bar: false,
      loading: true,
      help_counter: 0,
      chartdata: [],
      labels: [],
      old_chartdata: [],
      old_labels: [],
      chart_title: 'Fuelv2',
      label_name: [ 'Fuel level for'],
      borderColor: ['rgba(4, 96, 136, 0.8)','rgba(4, 96, 136, 0.2)','rgba(4, 96, 136, 0.5)'],
      event_data: [],
      old_event_data: [],
      bg1: [],
      bg2: [],
      bg3: [],
      status :[],
      old_status:[],
      max_y:0,
      gradient: null,
      status_array:[],
      loading_size: "57px",
    }
  },
  computed: {
    ...mapGetters({
      select_fuel: 'SELECT_FUEL',
      select_fuel_data: 'SELECT_FUEL_DATA',
      base_url: 'BASE_ADD',
      chart_data: 'FUEL_CHART_DATA',
      fuel_time_change:'FUEL_TIME_CHANGE',
      settings_fuel_active: 'SETTINGS_FUEL_ACTIVE',
    }),
  },
  watch: {
    fuel_time_change(newVal, oldVal) {

      if(newVal[this.settings_fuel_active]) {
          this.updateCart(newVal)
      }
    },
    select_fuel_data(newVal, oldVal) {

      if(newVal[this.settings_fuel_active]) {
        this.updateCart(newVal)
      }
    }
  },
  mounted() {
    this.reload_chart_counter=0
    if(this.chart_data[this.settings_fuel_active].data) {
      let route =[]
      let i = 0
      let num =10
      let arr_add =[]
      if(this.fuel_type === 'fuel_old') {
        this.event_data = [...this.chart_data.data ]
        if(this.chart_data.days.length > 9) {
          num = 20
        }
      }
      let max_add =0;
      this.chart_data[this.settings_fuel_active].data.map(item => {
        if(this.fuel_type === 'fuel_2') {
          this.chartdata.push(parseInt(item[2]))
          this.labels.push(item[0] * 1000)
          if(max_add < item[2]){
            max_add =item[2]
          }
        }else{
          route.push([item['lat'], item['lng']])
          this.chartdata.push(parseInt(item['fuelLevel']))
          this.labels.push(item['timestamp'] * 1000)
          if(max_add < item['fuelLevel']){
            max_add = item['fuelLevel']
          }
          i++
        }
      })
      if(this.fuel_type === 'fuel_old') {
        this.old_chartdata = [...this.chart_data.data]
        this.old_event_data = [...this.event_data]
        this.$store.commit('SET_FUEL_ROUTE', route)
      }else{
        this.event_data = this.chart_data[this.settings_fuel_active].data
      }
      let  max_new = (max_add / 100 * 20) + max_add
      this.max_y =(Math.round(max_new*100))/100
      this.max_y =Math.ceil(this.max_y / 10) * 10;
      let dataset = [];
      this.getStatus()
      dataset.push(
        {
          categoryPercentage: 1,
          barPercentage: 1,
          fill: false,
          label: this.label_name[0],
          data: this.chartdata,
          backgroundColor: this.borderColor[2],
          borderWidth: 0,
          borderColor: this.borderColor[2],
        }
      )
      if(this.fuel_type === 'fuel_2') {
        dataset.push(
          {
            type: 'line',
            label: this.label_name[0],
            data: this.chartdata,
            borderColor: this.borderColor[0],
            pointRadius: 0,
          }
        )
      }
      this.chartData = {
        labels: this.labels,
        datasets: dataset,
      }

      let $this = this
      setTimeout(() => {
        $this.show_chart_bar = true
        $this.loading = false
        this.$refs.box.addEventListener('resize', this.reloadChart);
      }, 200);
    }

  },

  methods: {
    chartInfo(){
      //console.log('this chart update date')
    },
    reloadChart(){
     //console.log('hi reload start')
      let $this = this
      if(this.reload_chart_counter === 0) {
        this.reload_chart_counter++
        this.show_chart_bar = false

        setTimeout(() => {
          $this.show_chart_bar = true
        }, 400);
      }
      setTimeout(() => {
      $this.reload_chart_counter=0
      }, 600);

    },
    removeChart(){
      if(this.fuel_type === 'fuel_2') {
        this.updateCart(this.select_fuel_data)
      }else{
        this.old_chartdata =   this.chart_data[this.settings_fuel_active].data
        this.changeChart()
      }
    },
    getStatus(num=false){
      this.label_name = [ 'Fuel level for '+this.select_fuel.Number]
      let status =[]
        let Fuel = this.chart_data[this.settings_fuel_active]
        for (let color in Fuel.bg) {
          if (Fuel.bg.hasOwnProperty(color)) {
            if (Fuel.bg[color] instanceof Array) {
              for (let i = 0; i < Fuel.bg[color].length; i++) {
                let add_color = ''
                if (color === '#B4CF58') {
                  add_color = 'rgba(180,207,88,0.9)'
                }
                if (color === '#C76ACB') {
                  add_color = 'rgba(199, 106,203,0.9)'
                }
                if (color === '#fff') {
                  add_color = 'rgba(255,255,255,0.9)'
                }
                if(this.fuel_type === 'fuel_2'){
                  status.push(
                    {
                      type: 'box',
                      id: 'box' + color + '-' + i,
                      xScaleID: 'x-axis-0',
                      yScaleID: 'y-axis-0',
                      xMin: Fuel.bg[color][i][0] * 1000,
                      xMax: Fuel.bg[color][i][2] * 1000,
                      yMin: 0,
                      yMax: this.max_y,
                      backgroundColor: add_color,
                    }
                  )
                }else{
                  status.push(
                    {
                      type: 'box',
                      id: 'box' + color + '-' + i,
                      xScaleID: 'x-axis-0',
                      yScaleID: 'y-axis-0',
                      xMin: Fuel.bg[color][i][0] ,
                      xMax: Fuel.bg[color][i][1] ,
                      yMin: 0,
                      yMax: this.max_y,
                      backgroundColor: add_color,
                    }
                  )
                }



              }
            }
          }
        }


    /*  else{
        let k =0;
        let t =0;
        let $this = this
        this.event_data.map(item => {
          let s1 = 0
          if(item.s >=1) {
            s1 = 1
          }
          let s2 =0
          if( k> 0  && $this.event_data[k-1].s >=1) {
             s2 = 1
          }
          if( k===0 ||(s1 !== s2 && item.i !== $this.event_data[k-1].i) ) {

            let add_color = ''
            if (item.s > 0) {
              add_color = 'rgba(180,207,88,0.8)'
            }
            if (item.i === 0 && item.s === 0) {
              add_color = 'rgba(255,255,255,0.8)'
            }
            if (item.i > 0 && item.s <1) {
              add_color = 'rgba(199, 106,203,0.8)'
            }
            if(status.length > 0) {
              status[status.length - 1]['xMax'] = k
            }
            status.push(
              {
                type: 'box',
                id: 'box' + item.s +'-'+t+'-' + k,
                xScaleID: 'x-axis-0',
                yScaleID: 'y-axis-0',
                xMin: k,
                xMax:k+1,
                yMin: 0,
                yMax: this.max_y,
                backgroundColor: add_color,
              }
            )

            t++
          }else{
            //status[status.length-1]['xMax'] = item.timestamp * 1000
          }

          k++

        });

      }*/
      this.status = status

    },
    changeChart(add_data = false){
      let new_chart = []
      let new_label = []
      let i = 0

      let max_add =0;
      this.show_chart_bar = false
      if(this.fuel_type === 'fuel_2') {
        this.chart_data[this.settings_fuel_active].data.map(item => {
            new_chart.push(parseInt(item[2]))
            new_label.push(item[0] * 1000)
          if(max_add < item[2]){
            max_add = item[2]
          }
          i++
        })
        this.event_data = this.chart_data[this.settings_fuel_active].data
      }else{
        this.event_data=[]
        this.old_chartdata.map(item => {
          new_chart.push(parseInt(item['fuelLevel']))
          new_label.push(item['timestamp'] * 1000)
          this.event_data.push(item)
          if(max_add < item['fuelLevel']){
            max_add = item['fuelLevel']
          }
          i++
        })

      }


      this.chartdata = new_chart
      let max_new = (max_add / 100 * 20) + max_add
      this.max_y =(Math.round(max_new*100))/100
      this.max_y =Math.ceil(this.max_y / 10) * 10;
      this.labels = new_label

      this.getStatus(add_data)
      let dataset = [];
      dataset.push(
        {

          categoryPercentage: 1,
          barPercentage: 1,
          fill: false,
          label: this.label_name[0],
          data: this.chartdata,
          backgroundColor: this.borderColor[2],
          borderWidth: 0,
          borderColor: this.borderColor[2],
        }
      )
      if(this.fuel_type === 'fuel_2') {
        dataset.push(
          {
            type: 'line',
            label: this.label_name[0],
            data: this.chartdata,
            borderColor: this.borderColor[0],
            backgroundColor: this.borderColor[0],
            pointRadius: 0,
          }
        )
      }
      this.chartData =   {
        labels: this.labels,
        datasets: dataset,
      }
      let $this = this
      setTimeout(() => {
        $this.show_chart_bar = true
      }, 100);
    },

    updateCart(e) {
      let data = e
      if(this.fuel_type === 'fuel_2') {
        let formData = new FormData();
        formData.append('id', this.select_fuel.ObjectId)
        if(data.start){
          formData.append('dt1', data.start)
          formData.append('dt2', data.end)
        }else{
          formData.append('dt1', data[this.settings_fuel_active].start)
          formData.append('dt2', data[this.settings_fuel_active].end)
        }



        axios.post(base_url + '/fuel/getchart', formData).then(response => {
          if (response.data['success'] === true) {
             let add = {...this.chart_data}
            add[this.select_fuel.ObjectId] = response.data
            this.$store.commit('SET_FUEL_CHART_DATA', add)
            let $this = this
            setTimeout(() => {
              $this.changeChart()
            }, 100);
          }
        }).catch(error => {
          console.log(error)
        })

      }
      else{
        this.old_chartdata =[]
        let i=0
        if(data[this.settings_fuel_active].start.toString().length >9) {
          this.chart_data[this.settings_fuel_active].data.map(item => {
            if (item['timestamp'] >= data[this.settings_fuel_active].start && item['timestamp'] <= data[this.settings_fuel_active].end) {
              this.old_chartdata.push(item)

            }
            i++
          })
        }else{
          this.chart_data[this.settings_fuel_active].data.map(item => {
            if (i >= data[this.settings_fuel_active].start && i <= data[this.settings_fuel_active].end) {
              this.old_chartdata.push(item)
            }
            i++
          })
        }
        this.event_data = [... this.old_chartdata]
          this.changeChart(data)
      }
    }
  }

}
</script>

<style scoped>
.loader-grid{
  margin-left: auto;
  margin-right: auto;
  width: 60px;
 height: 60px;
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chart-box__chart {
  width: 100%;
  position: relative;
  padding-top: 20px;
}
.remove-fuel-chart{
  position: absolute;
  width: 30px;
  top: 20px;
  right: 60px;
  transition: transform 0.2s ease;
  z-index: 10000;

}
.remove-fuel-chart:hover, .remove-fuel-chart:focus{
  transform: rotate(+65deg);
}
@media only screen and (max-width: 1800px) {
  .remove-fuel-chart {
    top: 0;
  }
  .chart-box__chart{
    padding-top:10px;
  }
}
@media only screen and (max-width: 1400px) {
  .chart-box__chart{
    padding-top: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .remove-fuel-chart {
    top: 5px;
    left: 5px;
    right: unset;
  }
}


</style>
