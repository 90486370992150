<template>
  <ul class="notifications lang">
    <li><a  class="notification-icon" @click="show_lang =!show_lang"><i class="fa lang" ><span class="lang-icon" v-html="lang_image[lang_short]"> </span></i></a>
      <div class="lang-menu" v-if="show_lang">
          <ul class="lang-list">
            <template v-for="(value, name) in lang_image ">
              <li @click="changLang(name)" v-if="lang_short != name"><span>{{name.toUpperCase()}}</span>
                <p class="lang-icon" v-html="value">
                </p>
              </li>
            </template>
          </ul>
        </div>
    </li>
  </ul>
</template>

<script>
import {mapGetters} from 'vuex';
import axios from "axios";

export default {
  name: "LanguageComponent",
  mounted() {
    let $this = this
    setTimeout(function () {
      let add_lang = $this.$localStorage.get('lang')
      if(add_lang !==null && add_lang !=='' && add_lang !==undefined){
        let url  = 'lang_en.json'
        let chek_lang = 'en'
        if(add_lang === 'lv'){
          url  = 'lang_lv.json'
          chek_lang = 'lv'
        }
       if(add_lang === 'ru'){
          url  = 'lang_ru.json'
         chek_lang = 'ru'
        }

        $this.getSystemLanguage(url,chek_lang)

      }
    }, 200);

  },
  data() {
    return {
      show_lang:false,
      lang_image:{
        en:`  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                     xml:space="preserve">
<circle style="fill:#F0F0F0;" cx="256" cy="256" r="256"/>
                  <g>
\t<path style="fill:#0052B4;" d="M52.92,100.142c-20.109,26.163-35.272,56.318-44.101,89.077h133.178L52.92,100.142z"/>
                    <path style="fill:#0052B4;"
                          d="M503.181,189.219c-8.829-32.758-23.993-62.913-44.101-89.076l-89.075,89.076H503.181z"/>
                    <path style="fill:#0052B4;"
                          d="M8.819,322.784c8.83,32.758,23.993,62.913,44.101,89.075l89.074-89.075L8.819,322.784L8.819,322.784   z"/>
                    <path style="fill:#0052B4;"
                          d="M411.858,52.921c-26.163-20.109-56.317-35.272-89.076-44.102v133.177L411.858,52.921z"/>
                    <path style="fill:#0052B4;"
                          d="M100.142,459.079c26.163,20.109,56.318,35.272,89.076,44.102V370.005L100.142,459.079z"/>
                    <path style="fill:#0052B4;"
                          d="M189.217,8.819c-32.758,8.83-62.913,23.993-89.075,44.101l89.075,89.075V8.819z"/>
                    <path style="fill:#0052B4;"
                          d="M322.783,503.181c32.758-8.83,62.913-23.993,89.075-44.101l-89.075-89.075V503.181z"/>
                    <path style="fill:#0052B4;"
                          d="M370.005,322.784l89.075,89.076c20.108-26.162,35.272-56.318,44.101-89.076H370.005z"/>
</g>
                  <g>
\t<path style="fill:#D80027;"
        d="M509.833,222.609h-220.44h-0.001V2.167C278.461,0.744,267.317,0,256,0   c-11.319,0-22.461,0.744-33.391,2.167v220.44v0.001H2.167C0.744,233.539,0,244.683,0,256c0,11.319,0.744,22.461,2.167,33.391   h220.44h0.001v220.442C233.539,511.256,244.681,512,256,512c11.317,0,22.461-0.743,33.391-2.167v-220.44v-0.001h220.442   C511.256,278.461,512,267.319,512,256C512,244.683,511.256,233.539,509.833,222.609z"/>
                    <path style="fill:#D80027;"
                          d="M322.783,322.784L322.783,322.784L437.019,437.02c5.254-5.252,10.266-10.743,15.048-16.435   l-97.802-97.802h-31.482V322.784z"/>
                    <path style="fill:#D80027;"
                          d="M189.217,322.784h-0.002L74.98,437.019c5.252,5.254,10.743,10.266,16.435,15.048l97.802-97.804   V322.784z"/>
                    <path style="fill:#D80027;"
                          d="M189.217,189.219v-0.002L74.981,74.98c-5.254,5.252-10.266,10.743-15.048,16.435l97.803,97.803   H189.217z"/>
                    <path style="fill:#D80027;"
                          d="M322.783,189.219L322.783,189.219L437.02,74.981c-5.252-5.254-10.743-10.266-16.435-15.047   l-97.802,97.803V189.219z"/>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
</svg>`,
        lv:`<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" enable-background="new 0 0 512 512"
                     viewBox="0 0 512 512">
                  <circle cx="256" cy="256" fill="#f0f0f0" r="256"/>
                  <g fill="#a2001d">
                    <path
                      d="m256 0c-118.279 0-217.805 80.221-247.181 189.217h494.363c-29.377-108.996-128.903-189.217-247.182-189.217z"/>
                    <path
                      d="m256 512c118.279 0 217.805-80.221 247.181-189.217h-494.362c29.376 108.995 128.902 189.217 247.181 189.217z"/>
                  </g>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                  <g/>
                </svg>`,
        ru:`  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
                     id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
                     xml:space="preserve">
<circle style="fill:#F0F0F0;" cx="256" cy="256" r="256"/>
                  <path style="fill:#0052B4;"
                        d="M496.077,345.043C506.368,317.31,512,287.314,512,256s-5.632-61.31-15.923-89.043H15.923  C5.633,194.69,0,224.686,0,256s5.633,61.31,15.923,89.043L256,367.304L496.077,345.043z"/>
                  <path style="fill:#D80027;"
                        d="M256,512c110.071,0,203.906-69.472,240.077-166.957H15.923C52.094,442.528,145.929,512,256,512z"/>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
                  <g>
</g>
</svg>`,
      },
    }
    },
  computed: {
    ...mapGetters({
     lang: 'LANG',
      lang_short: 'LANG_SHORT',
    }),
  },
  methods:{
    changLang(type){

      let url  = 'lang_en.json'
      if(type === 'lv'){
        url  = 'lang_lv.json'
      }
      if(type === 'ru'){
        url  = 'lang_ru.json'
      }
      this.getSystemLanguage(url,type)
    },
    getSystemLanguage(url,type){
      let _this =this;
      axios.get('/tracking/docs/'+url).then(response => {

        if(response.status == 200){
          let add_lang = response.data;
          _this.$store.commit('SET_LANG', add_lang);
          _this.$store.commit('SET_LANG_SHORT',type);
          _this.show_lang = false
          _this.$localStorage.set('lang', type)
        }

      }).catch(error => {
        console.log(error)
      })
    }


  }

}
</script>

<style scoped>
.notifications.lang {
  margin-left: 40px;
}
.notifications .notification-menu.lang .content {
  padding: 0;
}
.lang-list li{
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 10px;
  margin: 0;
  font-weight: bold;

}

.lang-list li span{
padding-left: 5px;
  padding-right: 5px;

}
.lang-list li:hover ,.lang-list li:focus {
  background: #1C71DA;
  color: white;
}
.lang-icon{
  display: flex;
  flex-direction: column;
  width: 25px;
  height: 25px;
  margin: 0;
}
.notifications .notification-menu.lang {

  max-width: 80px;
  min-width: 80px;
}
.fa.lang {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  padding: 0;

}
.lang-menu{
  position: absolute;
  top: 0;
  right: 40px;
  padding: 0;
  margin: 0;
}
.lang-list{
  display: flex;
  padding: 0;
  margin: 0;
  justify-content: left;
  align-items: center;
  padding-top: 3px;
}
@media only screen and (max-width: 1500px){
  .notifications {
    margin-top: 0;
  }
  }
@media only screen and (max-width: 600px) {
  .lang-list li span {
    display: none;
  }
  .lang-menu {
    position: absolute;
    top: 10px;
    right: 32px;
    padding: 0;
    margin: 0;
    width: 90px;
  }
  .lang-list li {
    padding: 0px 5px;
  }
  .notifications.lang{
    margin-left: 10px;
  }
}

</style>
