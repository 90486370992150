<template>
  <section class="body-sign">
    <div style="display: none">
    <language-component/>
    </div>
    <div class="background-map">
      <map-test-component></map-test-component>
    </div>
   <div class="background-wrapper"></div>
    <div class="center-sign">

        <div class="container">
          <div class="left">
            <div class="login"><svg-logo-dark/></div>
            <div class="col-sm-8" style="position: relative">

              <div class="box">
                <select id="lang" name="lang" class="form-control"  v-model="login_params.new_lang">
                  <option value="en" >English</option>
                  <option value="lv" >Latvian</option>
                  <option value="ru" >Russian</option>
                </select>
              </div>
            </div>

            <div class="error-info-box" >
              <span class=" error-info-text" v-if="show_error_message">
               {{error_message}}
              </span>

            </div>
          </div>
          <div class="right">
            <svg viewBox="0 0 320 300">
              <defs>
                <linearGradient
                    inkscape:collect="always"
                    id="linearGradient"
                    x1="13"
                    y1="193.49992"
                    x2="307"
                    y2="193.49992"
                    gradientUnits="userSpaceOnUse">
                  <stop
                      style="stop-color:#ff00ff;"
                      offset="0"
                      id="stop876" />
                  <stop
                      style="stop-color:#e33600;"
                      offset="1"
                      id="stop878" />
                </linearGradient>
              </defs>
              <path d="m 40,120.00016 239.99984,-3.2e-4 c 0,0 24.99263,0.79932 25.00016,35.00016 0.008,34.20084 -25.00016,35 -25.00016,35 h -239.99984 c 0,-0.0205 -25,4.01348 -25,38.5 0,34.48652 25,38.5 25,38.5 h 215 c 0,0 20,-0.99604 20,-25 0,-24.00396 -20,-25 -20,-25 h -190 c 0,0 -20,1.71033 -20,25 0,24.00396 20,25 20,25 h 168.57143" />
            </svg>
            <div class="form">
              <label for="username"><translate-component :text="lang.login_component.username"></translate-component></label>
              <label for="username" class="error small" v-if="show_error_username">{{lang.login_component.requre_field}}</label>
              <input id="username" type="text"  v-model="login_params.username">
              <label for="password"><translate-component :text="lang.login_component.password"></translate-component></label>
              <label for="password" class="error small" v-if="show_error_passworde">{{lang.login_component.requre_field}}</label>
              <input type="password" id="password" v-model.trim="login_params.password" >
              <button type="button" class="btn btn-login" v-if="!loading" @click="checkUser()"><translate-component :text="lang.login_component.log_in"></translate-component></button>
              <button class="btn btn-login" type="button" disabled v-else>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
            </div>
          </div>
        </div>

    </div>
  </section>
</template>

<script>
import SvgLandIcon from "@/components/ui/icons/svgLandIcon";
import axios from "axios";
import {mapGetters} from "vuex";
import MapTestComponent from "@/components/map/MapTestComponent";
import SvgLogoWhite from "@/components/ui/icons/svgLogoWhite";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import LanguageComponent from "@/components/dashboard/LanguageComponent";
import moment from "moment";
import SvgLogoDark from "@/components/ui/icons/svgLogoDark";
export default {
  name: "LoginComponent",
  components: {SvgLogoDark, LanguageComponent, TranslateComponent, SvgLogoWhite, MapTestComponent,SvgLandIcon},
  data() {
    return {
      user_auth:false,
      login_params:{
        username:'',
        password:'',
        new_lang:'lv',
      },
      new_lang:'lv',
      loading:false,
      error_message:'',
      show_error_message:false,
      show_error_username:false,
      show_error_passworde:false,
      base_url:base_url,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
    }),

  },
  mounted() {
    this.login_params.lang ='lv'
        let add_lang = this.$localStorage.get('lang')
    if(add_lang && add_lang !=='' && add_lang !==undefined && add_lang !==[]){
      this.login_params.lang = add_lang
      this.login_params.new_lang = add_lang;
      this.$store.commit('SET_LANG_SHORT', add_lang);
    }else{
      this.login_params.lang = 'lv'
      this.$store.commit('SET_LANG_SHORT', 'lv');
    }

  },
  methods:{
    checkUser(){
      if(this.login_params.username.length > 0 && this.login_params.password.length >0 ){
        this.show_error_username = false
        this.show_error_passworde = false
        this.authUser()
      }else {
        if (this.login_params.username.length === 0) {
          this.show_error_username = true
        }
        if (this.login_params.password.length === 0) {
          this.show_error_passworde = true
        }
      }
    },

    authUser(){
      let $this = this
      this.$localStorage.set('lang',this.login_params.new_lang)
      this.show_error_message = false
      this.loading = true
     let  today = new Date();
      let utcStart_old = new moment(today, "YYYY-MM-DD").unix();
      let utcStart = new moment(today, "YYYY-MM-DD").utc('UTC').unix();
      let diffInMinutes = utcStart-utcStart_old;
      let diff = Math.round(diffInMinutes/3600)

      let formData = new FormData();
      formData.append('username', $this.login_params.username);
      formData.append('password', $this.login_params.password);
      formData.append('lang', $this.login_params.new_lang);
      formData.append('tzoffset',diff);

      this.$localStorage.set('lang', this.login_params.new_lang)

      axios.post(base_url+'/auth/login', formData).then(response => {
        if( response.data['success']===true){

          if(response.data['auth']===true){
            this.$store.commit('SET_USER_AUTH', true);
          }else{
            $this.error_message =$this.lang.login_component.unable_login;
            $this.show_error_message = true
          }
        }else{
          $this.error_message = $this.lang.login_component.server_error;
          $this.show_error_message = true
        }
        $this.loading=false
      }).catch(error => {
        console.log(error)

      })

    }
  }

}
</script>

<style scoped>
.logo-box{
  margin-bottom: -20px;
}
.logo-box img{
  width: 160px;
}
.box {
  margin-left: 40px;
  width: 200px;
  max-width: 200px;
  position: relative;

}
.body-sign .panel-sign .panel-title-sign .title {
  background-color: transparent;

}
.box select {
  background: #34495e;

  color: #F1F1F2;
  padding: 5px 15px;
  width: 160px;
  border: none;
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  border-radius: 0;
  width: 100%;
}



.box select option {
  padding: 30px;
}
.icon-earth{
  position: absolute;
  width: 25px;
  height: 25px;
  margin-top: 3px;
}
.background-map{
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.background-wrapper{
  background-color: rgba(58, 83, 155, 0.4);
  width: 100%;
  height: 100%;
  z-index: 20;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;

}
.body-sign .center-sign {

  z-index: 1000;
  position: relative;
}
.box::before {
 display: none;
}
@media only screen and (max-width: 600px) {
  .box {
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }

 .login-btn-box{
   margin-top: 20px;
   width: 100%;
 }
  .box select {
    background: #34495e;
    color: white;
    padding: 5px 15px;
    width: calc(100%);
    border: none;
     box-shadow:none;
    padding-left: 50px;

  }
  .login-btn-box .btn{
    width: 100%;
    box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
  }
  .icon-earth {
    position: absolute;
    width: 25px;
    height: 25px;
    margin-top: 3px;
    z-index: 10000;
    margin-left: 10px;
  }
}
::selection {
  background: #2D2F36;
}
::-webkit-selection {
  background: #2D2F36;
}
::-moz-selection {
  background: #2D2F36;
}

@media (max-width: 767px) {

}
.container {
  display: flex;
  height: 320px;
  margin: 0 auto;
  width: 640px;
}
@media (max-width: 767px) {
  .container {
    flex-direction: column;
    height: 630px;
    width: 320px;
  }
}
.left {
  background: white;
  height: calc(100% - 40px);
  top: 20px;
  position: relative;
  width: 50%;

}
@media (max-width: 767px) {
  .left {
    height: 100%;
    left: 20px;
    width: calc(100% - 40px);
    max-height: 270px;
  }
}
.login {
  font-size: 50px;
  font-weight: 900;
  margin: 50px 40px 40px;
}
.eula {
  color: #999;
  font-size: 14px;
  line-height: 1.5;
  margin: 40px;
}
.right {
  background: #34495e;
  box-shadow: 0px 0px 40px 16px rgba(0,0,0,0.22);
  color: #F1F1F2;
  position: relative;
  width: 50%;

}
@media (max-width: 767px) {
  .right {
    flex-shrink: 0;
    height: 100%;
    width: 100%;
    max-height: 350px;
  }
}
svg {
  position: absolute;
  width: 320px;
}
path {
  fill: none;
  stroke: url(#linearGradient);;
  stroke-width: 4;
  stroke-dasharray: 240 1386;
}
.form {
  margin: 40px;
  position: absolute;
}
label {
  color:  #c2c2c5;
  display: block;
  font-size: 14px;
  height: 16px;
  margin-top: 20px;
  margin-bottom: 5px;
}
input {
  background: transparent !important;
  border: 0;
  color: #f2f2f2 !important;
  font-size: 20px;
  height: 30px;
  line-height: 30px;
  outline: none !important;
  width: 100%;
}
input::-moz-focus-inner {
  border: 0;
}
.btn-login{
  background: transparent !important;
  border: 0;
  color: #f2f2f2 !important;
  font-size: 20px;
  height: 40px;
  line-height: 20px;
  outline: none !important;
  width: 100%;


}
.btn-login{
  color: #707075;
  margin-top: 40px;
  transition: color 300ms;
  border: 2px solid;
  border-image-slice: 1;
  border-radius: 10px;
  border-image-source: linear-gradient(to right, transparent,#ff6332);
  border-radius: 10px;
}
.btn-login:focus {
  color: #f2f2f2 !important;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);

}
.btn-login:active {
  color: #d0d0d2 !important;
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d);

}
.error-info-box{
  position: absolute;
  bottom: 0;
  padding: 20px 30px;
  padding-top: 0px;
  padding-bottom: 30px;
  font-size: 16px;
  color:  #34495e;
}
.error.small{
  margin: 0;
  padding: 0;
  line-height: 18px;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  .error-info-box {
    padding-top: 0px;
    font-size: 12px;
  }

  .left {
    max-height: 250px;
  }

  .login {
    margin: 30px 30px 30px;
  }
  .body-sign .center-sign {
    padding-top: 0 !important;
    top: -10px ;
  }

}
@media only screen and (max-width: 322px) {

  .body-sign .center-sign {
    padding-top: 0;
  }
  .container {
    margin-top: -40px;
    padding-left: 5px;
  }

}
</style>