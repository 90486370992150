import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import axios from 'axios'
import moment from "moment";

axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
};

export default new Vuex.Store({
  state: {
    user_id:null,
    start_search:true,
    select_type:'history',
    client_id:null,
    user_auth:false,
    calendar_dates :[],
    reload_map:0,
    object_start:0,
    base_add: base_url,
    show_all_objects: true,
    show_all_zones: true,
    show_calendar_panel:true,
    active_route: 'routing',
    lang_short: 'en',
    lang: {
      main_calibration:{
        Fuel_Level_Analog_1:"Fuel Level Analog 1",
        Fuel_Level_Analog_2:"Fuel Level Analog 2",
        tankName: "BAK",
        tankL: "L",
        tankB: "B",
        tankH: "H",
        tankVmax: "Vmax",
        tankmV: "mV",
        llsName: "DUT",
        llsSerial: "Serial",
        llsLorign: "L orig",
        llsLobr: "L obr",
        min: "mV min",
        max: "mv max",
        tankInfo: "info",
      },
      main_component:{
        navigation:"Navigation",
        routes:"Routes",
        logout_name:" Logout",
        fuel:"Fuel",
        reports:"Reports",
        vehicle_settings:'Vehicle settings',
        icons:'Icons',
        info:'Information',
        addit:'Additional',
        note:'Notes',
        Number:"Number",
        Mark:"Manufacturer",
        Model:'Model',
        FulNormal:'Fuel norm (l/100km)',
        FulNormal2:'Fuel norm (l/h)',
        TankCapacity:'Tank capacity (l)',
        Comment:'Comment',
        Truck:'Truck',
        Bus:'Bus',
        Ship:'Ship',
        Special:'Special',
        Car:'Car',
        inspection_date:'Inspection date',
        insurance_date:'Insurance date',
        work_date_start:'Work start time',
        work_date_end:'Work end time',
        dis_coefficient:"Distance coefficient",
        dis_source:"Distance source",
        next:'Next',
        cancel:'Cancel',
        previous:'Previous',
        save_at:"Save",
        mon:"MON",
        tue:"TUE",
        wed:"WED",
        thu:"THU",
        fry:"FRY",
        sat:"SAT",
        sun:"SUN",
        events_alert:"Event Alert"
      },
      fuel_table_components: {
        date: 'Date',
        fill: 'Fillings',
        drain: 'Drains',
        consumption: 'Consumption',
        consumption_hour: 'l/h',
        consumption_km: 'l/100km',
        distance: 'Distance(km)',
        tota_consumption: 'Total Consumption',
        consumption_litr :'l'
      },
      login_component:{
        password:"Password",
        username:"Username",
        log_in:"Log In",
        requre_field:"This field is required",
        unable_login:"Unable to login: username or password are incorrect",
        server_error:"Server error"
      },
      object_component: {
        list_select_text: 'Enter name or number',
        in_movement: 'In movement',
        stopped: 'Stopped',
        stopped_with_engine_on: 'Stopped with ignition on',
        offline: 'Offline',
        show_all_objects: 'Show all objects',
        object_list: 'Vehicle list',
        calendar_title: "Select period",
        monitor_text: "Monitoring objects",
        confirm_monitoring: "This monitoring object will be removed from the list. Proceed?",
        select_history_object: "Choose an object from the list",
        select_history_date: "Choose start and end date from calendar",
        tooltip_all: "Show all objects",
        tooltip_star: "Show only favorite objects",
        tooltip_monitor: "Show only objects under observation",
        tooltip_alpha: "Start object list from A-Z",
        tooltip_speed: "Start object list from higher speed",
        tooltip_history: "Show object history",
        tooltip_geozone: "Show geofence tab",
        tooltip_status: "Filter object list by status",
        tooltip_route_setting: "Settings for route display",
        tooltip_route_calendar: "Select start date and end date to search",
        tooltip_reports:"Create daily reports",
        tooltip_reports_settings:"Reports advanced settings",
        tooltip_reports_calendar:"Reports select period",
        version_monitoring_objects: "In the DEMO version, it is possible to monitor only 2 objects",
        version_add_history: "In the DEMO version, the ability is to view the history only of 3 objects at the same time"
      },
      object_info_component: {
        object_status_0: "Unknown status",
        object_status_1: "No GPS",
        object_status_2: "Stop without ignition",
        object_status_3: "Movement without ignition",
        object_status_4: "Stop with ignition on",
        object_status_5: "Movement with ignition on",
        object_status_6: "Movement",
        object_status_7: "Stop",
        object_status_8: "No data for 24 hours",
        object_status_9: "No data at all",
        discription: "Description",
        address: "Address",
        time: "Time",
        coordinates: "Coordinates",
        status: "Status",
        speed_now: "Current speed",
        distance_today: "Distance today",
        routes: "Routes",
        routes_today: "Routes today",
        more_details: "More details",
        information: "Information",
        view_panoramas: 'View panoramas',
      },
      geo_zone_component: {
        title: "Geofences",
        create_btn_title1: "Create polygon",
        create_btn_title2: "Create circle",
        input_name: "Add geofence name",
        empty_title: "You don't have any geofence",
        page: "Page",
        search: "Search by name, address, group",
        add_list: "My geofences list",
        confirm_delete_zone: "Are you sure you want to delete selected zones?",
        title_edit: "Edit zone: ",
        edit_name: "Name",
        edit_address: "Address",
        edit_comments: "Comments",
        edit_group: "Group Name",
        edit_phone: "Phone",
        save_edit: "Save Changes",
        tooltip_polygon: "New polygon zone",
        tooltip_circle: "New circle zone",
        tooltip_marker: "New zone marker",
        tooltip_rectangle: "New rectangle zone",
        tooltip_rout: "Create route",
        tooltip_weather: "Weather at a given point",
        tooltip_monitor_start: "Start monitoring in this zone",
        tooltip_monitor_stop: "Stop monitoring in this zone",
        version_monitoring_zone:"In the DEMO version, it is possible to monitor only 2 zones",
        tooltip_remove_list: "Remove from this list",
        tooltip_save: "Save this zone in the datebase",
        tooltip_update:"Change data in this zone",
        alert_add_name:"First add a name to this zone",
        edit_zone_type:"Edit Polygon",
      },
      zone_info_component: {
        discription: "Description",
        address: "Address",
        phone: "Phone",
        time: "Creation time",
        coordinates: "Coordinates",
        status: "Status",
        speed_now: "Current speed",
        distance_today: "Distance today",
        more_details: "More details",
        information: "Information",
        view_panoramas: 'View panoramas',
        comments: "Comment",
        client: "Customer",
        none_client: "Zone not bind to customer",
        desired_time: "Desired visit time",
        periodicity: "Periodicity",
        route_list: "Used routes lists",
        every_week: "Every week",
        every_month: "Every month",
        monitorin_enter_list: "Enter object list",
        monitorin_exit_list: "Exit object list",
        monitorin_enter_now: "In.",
        zone_history:'History'
      } ,
      group_list_component:{
        group_list:"Your group list",
        group_empty:"You don't have any group",
        group_create:"Do you want to create a group?",
        group_create_input:"Enter group name",
        group_create_objects:"Add object to group from the objects list",
        group_create_move:"Just drag and drop",
        group_create_btn:"Create"
      },
      history_table_component: {
        rows: "Rows",
        pages: "Pages",
        name: "No.",
        gpstime: "Time",
        speed: "Speed",
        Ignition: "Ignition",
        A: "Car battery",
        B: "Internal battery",
        a: "Angle",
        ang: "Angle",
        X: "Longitude",
        Y: "Latitude",
        satellites: "Satellites",
        altitude: "Altitude",
        select_row: "Please, select fields in the table",
        chart_type: "Choose chart type",
        chart_line: "Line chart",
        bar_line: "Bar chart",
        area_line: "Area chart",
        pie_line: "Pie chart",
        radar_line: "Radar chart",
        add_chart: "Display multiple graphs",
        full_chart:"All",
        pdf_download:"Export to .pdf"
      },
      geoparams_siteber_component:{
        number:"Number",
        total_distance:"Total distance",
        total_time:"Driving time",
        stop_length:"Stop length",
        topspeed:"Top speed",
        avgspeed:"Average speed",
        loading:"Loading...",
      },
      route_settings_component: {
        title: "Route settings",
        page_route: "Distance settings",
        page_play: "Player settings",
        page_view: "Route display settings",
        page_map: "Map settings",
        greater_then: "Greater then",
        use_minimap: "Use minimap",
        show_weather: "Show weather",
        show_additional_info: "Show additional info",
        show_region_borders: "Show region borders",
        stroke_weight: "Stroke weight",
        stroke_smooth: "Stroke smooth",
        stroke_opacity: "Stroke opacity",
        stroke_color: "Stroke color",
        strokeBorderOpacity: "Stroke Border Weight",
        speed_Limit: "Speed Limit",
        stroke_Border_Color: "Stroke Border Color",
        use_speed_limit: "View overspeeding",
        show_points_each: "Show points each",
        show_speedometer: "Show speedometer",
        catch_marker: "Catch marker",
        auto_start: "Auto start",
        use_player: "Use player",
        use_ignition: "Use ignition",
        meters: "meters",
        skipMinDistance: "Skip min distance",
        allowable_distance: "Allowable distance within the park",
        minutes: "minutes",
        greater: "Greater then",
        all: "All",
        minimal_stop_time: "Minimal stop time",
        use_arrow_show: "Show driving directions",
        use_arrow_color:"Driving directions color",
        default_params:"Reset Parameters",
        marker_color:"Marker Color"
      },
      map_component: {
        in_movement: 'In movement',
        stopped: 'Stopped',
        stopped_with_engine_on: 'Stopped with ignition on',
        offline: 'Offline',
        weather_marker: "Click on the map to see weather in this area",
        driving_time: "Driving time",
        total_distance: "Total distance",
        start_time: "Start time",
        stop_time: "Parking time",
        alert_not_save: "First, save this zone to the database",
        number:"Number",
        driver :"Driver",
        address :"Address",
        gps_time :"GpsTime",
        speed :"Speed",
        not_attach:"Not assignet",
        company_name: "Name",
        reports: "Reports list",
      },
      reports_settings_component:{
        Advanced_settings:"Reports settings",
        min_stop_time:"Minimal stop time",
        all:"All",
        greater_then:"Greater than",
        minutes:" minutes",
        allowable:"Allowable distance within the parking",
        meters:" meters",
        skip: "Skip minimal distance",
        ignition:"Use ignition",
        daily:"Daily",
        full_days:"Only full days",
        generate_report:"Generate report",
        main_settings:"Main settings",
        full_report_settings:"Full report settings",
        DateTimeFrom:'From',
        DateTimeTo:'To',
        Distance:'Distance (km)',
        FuelCounter:'Fuel used CAN (l)',
        FuelUsedByDistance:'Fuel consumed CAN (l/100km)',
        FuelUsedByTime:'Fuel consumption (l/h)',
        FuelLevelStart:'Fuel level on period beginning FLS (l)',
        FuelLevelEnd:'Fuel level on period end FLS (l)',
        EngineWorkTime:'Engine work time',
        EngineWorkTimeWithMotion:'Engine work time (in motion)',
        SpeedAvg:'Speed (avg)',
        EngineRevolutionsAvg:"Engine revolutions (avg)",
        NormalFuelUsedByDistance:"Consumption by norm (l/100km)",
        NormalFuelUsedByTime:"Consumption by norm (l/h)",
        TimeBegin:"Begin time",
        TimeEnd:"End time",
        FuelUp:"Filled (l)",
        FuelDown:"Drained (l)",
        FuelConsumed:"Fuel consumed LLS (l)",
        EngineWorkTimeWithoutMotion:"Engine worktime (without motion)",
        OverspeedDuration:"Overspeed duration",
        ParkingDuration:"Parking duration",
        FuelCounterIdle:"Fuel consumption at idle (l)",
        SpeedRangeDuration:"Speed range - time",
        SpeedRangeDistance:"Speed range - distance",
        TimeDuration:"Work day duration",
        HeaterTime:"Heater work time",
        FuelConsumedByDistance:"Fuel consumed LLS (l/100km)",
        CanOdometer:"Odometer reading at the beginning of the period (km)",
        date_format:"Date format",
        Show_driver_names:"Show driver names",
        fuel_level:"Fuel level (l)",
        engine_work_time:"Engine work time",
        speed:"Speed (km/h)",
        overspeedLimit:"Overspeed Limit",
        parkingLimit:"Parking Limit",
      },
      reports_component:{
        name:"Name",
        status:"Status",
        actions:"Actions",
        create_at: "Date",
        title:"Reports list",
        routes2:"Report - Routes",
        vid:"Report - WayBill GOV",
        ready:"Ready",
        error:"Error",
        render:"Rendering...",
        open:"Open",
        delete:"Delete",
        back:"Back",
        export_xls:"Export to .xls",
        export_xls_alt:"Export to .xls (Alternative)",
        export_pdf:"Export to .pdf",
        generate_limit:"Reports limit is 3 in one time",
        generate_error:"Server Error",
        telemetry:"Report - Full",
        info_success:"Report successfully created!",
        info_vehicle:"Click here to create a new report",
        info_delete:"Selected report deleted!",
        select_any_object:"Please, select an objects from the list",
      },
      events_component: {
        title:'Events list',
        name:'Name',
        type:'Type',
        utc_off:"UTC offset",
        create_at:'Creation date',
        update_at:'Updated date',
        user:'User',
        e_mail:"Send to email",
        add_alert:"Alert on site",
        enable:"Enabled",
        event_log:'Events log',
        vehicle:"Vehicle",
        data_time:"Date and time",
        status:"Status",
        accept_by:"Accepted by",
        accept_at:"Accepted at",
        message:"Message",
        create:"Create",
        edit:"Edit",
        delete:"Delete",
        accept:"Accept",
        events_name:"Events",
        management_name:"Management",
        management_name2:"Vehicle Management",
        total_page:'of',
        create_event:'Create new event',
        type_31:'Geozone in/out (not in time)',
        type_5:'Data monitoring',
        type_1:'Mobile connection lost',
        type_3:'Geozone in/out',
        type_4:'GPS signal lost',
        type_15:'Parking',
        type_2:'SOS',
        type_8:'Inspection reminder',
        type_9:'Insurance reminder',
        type_10:'Maintenance reminder',
        type_11:'Engine work overtime on parking',
        type_12:'Fuel level monitoring',
        type_13:'Fuel sensor voltage monitoring',
        type_20:'Unplug detection (PnD)',
        type_21:'Crash detection (PnD)',
        type_22:'GPS / GSM jamming detection (PnD)',
        type_30:'Stop outside geozone',
        type_32:'Geozone in/out (not in time)',
        add_zone:"List of zones",
        check_for:"Check for",
        opt_1:"Geozone In/Out",
        opt_2:"Geozone In",
        opt_3:"Geozone Out",
        select_zone:'Select a zone',
        add_vehicle:"List of vehicles",
        select_vehicle:"Select vehicle",
        use_alert:"Show alert on site",
        note_settings:"Notification settings",
        use_email:"Type e-mail(s) for notification",
        placeholder_email:"Enter email",
        use_sms:"Send SMS to these numbers",
        placeholder_sms:"Enter phone number",
        enter_days:"Select work days",
        enter_time:"Select work time",
        enter_31:"Include this text when the object detected in the geozone",
        out_31:"Include this text when the object left the geozone",
        out_offset:"UTC offset",
        adjust:"Adjust summer / winter time",
        react_delay:"Reaction delay (min)",
        event_now:"Enable event now?",
        event_after:"Activate event after save",
        success_text:"A new event has been created!",
        val_1:"Event name is required",
        val_2:"Add any vehicle from list",
        val_3:"Add any geozone from list",
        val_4:"To send a SMS the GSM number is required",
        val_5:"To send an email the Email address is required",
        choose_0:"Speed",
        choose_1:"Power",
        choose_2:"Temperature",
        choose_3:"Battery level (TMT250)",
        choose_4:"Din1",
        choose_5:"Din2",
        choose_6:"Din3",
        choose_7:"Din4",
        choose_8:"Ain1",
        choose_9:"Ain2",
        choose_10:"Ain3",
        choose_11:"Ain4",
        choose_12:"Man down (TMT250)",
        delete_text:"Are you sure you want delete selected event?",
        log_text:"Are you sure you want accept this event log?",
        enter_2:"Include this text when SOS is active",
        out_2:"Include this text when SOS is not active",
        enter_1:"Include this text when Connection lost is active",
        out_1:"Include this text when Connection lost is not active",
        enter_4:"Include this text when GPS signal lost is active",
        out_4:"Include this text when GPS signal lost is not active",
        enter_5:"Include this text when Data monitoring out of range",
        out_5:"Include this text when Data monitoring in range",
        enter_15:"Include this text when object stops out of parking",
        out_15:"Include this text when object stops out of parking",
        enter_8:"Include this text when it is time for inspection",
        out_8:"Include this text when it is time for inspection",
        enter_9:"Include this text when it is time for insurance",
        out_9:"Include this text when it is time for insurance",
        enter_10:"Include this text when it is time for maintenance",
        out_10:"Include this text when it is time for maintenance",
        range_text:"Select data source and ranges",
        inspection_text:"Inspection reminder settings",
        remind:"Remind ",
        days_before:" Days before inspection expired",
        maint_text:"Maintenance reminder settings",
        maint_remain:"Remind Each  ",
        edit_event:"Edit Event ",
        edit_text:"This event has been updated",
      },
      task_component:{
        title: 'Tasks',
        title2: 'Journal of transport technical service',
        Changed:'Changed',
        Remaining:'Remaining',
        Executive:'Executive',
        Phone:'Phone',
        Email:'Email',
        Username:'Username',
        days:'days',
        actions: 'Actions',
        inspection: "Inspection",
        insurance_octa: "Insurance OCTA",
        maintenance_by_km: "Maintenance by km",
        maintenance_by_motohours: "Maintenance by motohours",
        kasko: "Insurance KASKO",
        tahograf_check: "Tahograf check",
        license_map: "License cards",
        eka: "EKA",
        ADR_Check: "ADR Check",
        Other: 'other',
        performance_not_required : "Active",
        performance_is_required : "Performance is required",
        completed : "Completed",
        km : "km",
        hrs : "hrs",
        accept:"accept",
        confirm_message:"are you sure you want to accept task?",
        confirm_delete:"are you sure you want to delete task?",
        accept_message:"task already is accepted",
        delete_message:"task already has been deleted!",
        delete:'delete',
        create_task: 'Create task',
        task_type: 'Task type',
        do_maintenance_after:"Do maintenance after",
        motohours:"motohours",
        data_of: "Date of ",
        send: "Send",
        select_vehicle: "Add Object from list",
        valid_phone:"Please enter valid phone",
        valid_email:"Please enter valid email",
        empty_field:"Please enter executive",
        empty_object:"Please select object",
        empty_sendBefore: "Please enter maintenance value",
        empty_add_date: "Please select calendar date",
        success_update:"Success, created new Task!",
        success_create:"Task has been updated!",
        send_notification_before: 'Send notification before',
        edit_task:'Edit task',
        change:'Change',
        reset:'Reset',
        search: 'Search',
        limit:"Row",
        close_btn:"Close",
      },
      user_component:{
        title: "User manager",
        title2: "User manager",
        title_create:"Add new user",
        title_update:"Edit user",
        id_number: 'ID',
        role: 'Role',
        branch: 'Branch',
        customer:"Customer",
        role_administrator: "Administrator",
        role_power_user:"Power User",
        role_user: "User",
        role_disabled_user:"Disabled User",
        choose_customer: "Choose Customer",
        valid_username:"Username cannot be empty",
        valid_password:"Password cannot be empty",
        valid_confirm_password:"Entered passwords dont match",
        valid_client:"Please select client",
        create_user: "New user created successfully",
        updated_user: "This user updated successfully",
        valid_role:"Please select any user role",
        delete_user: "This user has been deleted!",
        confirm_delete:"Are you sure, you want to delete that user?",
        email:"Email",
        password:"Password",
        confirm_password:"Confirm Password",
        add_object:"Added user objects",
        add_object_customer:"Add objects from customer",
        client_object:"Client objects",
        user_resources:"User resources",
        add_selected:"Add selected",
        add_all:"Add all",
        delete_all:"Delete all",
        delete_selected: "Delete selected",
        sizzapp_limitations : "Sizzapp limitations",
        vehicles:"Vehicles",
        points:"Points",
      },
      group_component:{
        title:'Object Groups',
        title2:'Object group manager',
        groups:'Groups',
        group:'group',
        object_group:'Objects in group',
        available_object:'Available objects',
        private:"private",
        valid_name:"Empty group name",
        create_group: 'Create new group',
        edit_group: 'Edit group',
        group_name:'Group name',
        select_group:"Select group from list",
        confirm_delete: 'Are you sure, you want to remove object group:',
      },
      engine_component:{
        title:"Engine Logs",
        title2:"Engine DTC Log",
        Number:"Number",
        Date_time:"Date time",
        Fault_code:"Fault code",
        Description:"Description",
        Status:"Status",
        code_count:"Active fault code count",
        Clear_DTC:"Clear DTC",
        Clear_DTC_all:"Clear DTC All",
        success_message:"Command was sent successfully.",
        success_message2: "Command was sent successfully. For ",
      },
      "share_component":{
        "title": "Car sharing"
      }
    },
    current_group:0,
    current_group_list:{},
    objects: {},
    objects_loading: true,
    show_object_content: true,
    show_object_list: null,
    start_center: [56.946285,24.105078],
    map_zoom: 8,
    search_icons: {},
    show_only_search: 0,
    group_list: {},
    show_geo_zone: false,
    polygon_zones: {},
    show_weather: false,
    weather_object: false,
    latlng: {},
    create_path: false,
    path_latlngs: {},
    object_info: {},
    show_object: false,
    zone_info: {},
    show_zone_info: false,
    object_filter: 'all',
    select_history: false,
    history_array: {},
    settings_show: false,
    settings_options: {},
    settings_active: '',
    settings_fuel_active: '',
    user_route_settings: {},
    history_time: {},
    add_calendar: false,
    history_data: {},
    history_table_show: false,
    history_loading: false,
    move_marker_show: false,
    move_marker_data: {},
    object_numbers: {},
    route_excel: [],
    select_fuel: {'ObjectId':0, 'Number':0},
    select_fuel_data: {},
    fuel_route: [],
    fuel_chart_data: [],
    fuel_marker:[],
    fuel_fill_markers:[],
    fuel_time_change:{'start':0,'end':0},
    update_polyline:{'type':0,'counter':0},
    stop_marker:{},
    bound_cluster:0,
    default_params:{
      minStopTime: 5,
      maxStopDistance: 50,
      skipMinDistance: 10,
      chkByIgnition: 0,
      chkPlayer: 0,
      player_autostart: 0,
      catch_marker: 0,
      show_speedometer: 0,
      showTimePointsEnabled: 1,
      showTimePointsMinutes: 5,
      use_speed_limit: 1,
      use_arrow_show: 1,
      arrow_colorpicker: {
        "hex": "#3366E6",
        "source": "hex"
      },
      colorpicker: {
        "hex": "#16a765",
        "source": "hex"
      },
      speedLimit: 0,
      strokeBorderOpacity: 1,
      stroke_colorpicker: {
        "hex": "#fa573c",
        "source": "hex"
      },
      strokeOpacity: 0.5,
      strokeSmooth: 0,
      strokeWeight: 7,
      mapShowRegionBorders: false,
      mapShowAdditionalInfo: true,
      mapShowWeather: false,
      mapShowMiniMap: false,
      routes_speed: [0, 90],
      route_marker_color:{
        "hex": "#2979ff",
        "source": "hex"
      },
      googleMapTypeId: "OpenStreetMap",
    },
    zone_bound:0,
    show_reports: false,
    reports_select:{},
    show_reports_settings:false,
    show_graph:false,
    reports_settings_check:0,
    reports_settings_params: {
      reports_bOnlyFullDays:1,
      reports_chkByIgnition:1,
      reports_chkDaily:1,
      reports_chkFuelByDayOnlyWithIgnition:1,
      reports_dateFormat:0,
      reports_distancegps_flipSides:0,
      reports_maxStopDistance:200,
      reports_maxWorkInterval:"20:00",
      reports_minStopTime:5,
      reports_minWorkInterval:"08:00",
      reports_overspeed_overspeedLimit:90,
      reports_parking_parkingLimit:5,
      reports_routecheck_Type:0,
      reports_routecheckm_byParking:1,
      reports_routecheckw_byParking:1,
      reports_selFuelByDayMovementSource:0,
      reports_selFuelByDayMovementSpeed:2,
      reports_showDrivers:0,
      reports_skipMinDistance:5,
      reports_telemetry_chk0:1,
      reports_telemetry_chk1:1,
      reports_telemetry_chk10:0,
      reports_telemetry_chk11:0,
      reports_telemetry_chk12:0,
      reports_telemetry_chk13:0,
      reports_telemetry_chk14:1,
      reports_telemetry_chk15:1,
      reports_telemetry_chk16:1,
      reports_telemetry_chk17:0,
      reports_telemetry_chk18:1,
      reports_telemetry_chk19:0,
      reports_telemetry_chk2:1,
      reports_telemetry_chk20:0,
      reports_telemetry_chk21:0,
      reports_telemetry_chk22:0,
      reports_telemetry_chk23:0,
      reports_telemetry_chk24:0,
      reports_telemetry_chk25:0,
      reports_telemetry_chk26:0,
      reports_telemetry_chk28:1,
      reports_telemetry_chk3:0,
      reports_telemetry_chk4:0,
      reports_telemetry_chk5:0,
      reports_telemetry_chk6:1,
      reports_telemetry_chk7:1,
      reports_telemetry_chk8:0,
      reports_telemetry_chk9:0,
      reports_telemetry_source_Distance:0,
      reports_telemetry_source_EngineTime:0,
      reports_telemetry_source_FuelCounter:0,
      reports_telemetry_source_FuelLevel:0,
      reports_telemetry_source_Speed:0,
    },
    reports_time:{start_time:null, end_time:null,ndt1:null, ndt2:null},
    reports_cookie:null,
    show_reports_list:false,
    show_events_list:{},
    start_render_report:false,
    report_type:'daily',
    vehicle_settings:false,
    current_object:null,
    current_resources:null,
  },
  getters: {
    USER_ID:(state) => {
      return state.user_id
    },
    CURRENT_RESOURCES:(state) => {
      return state.current_resources
    },
    CURRENT_GROUP:(state) => {
      return state.current_group
    },
    CURRENT_GROUP_LIST:(state) => {
      return state.current_group_list
    },
    CURRENT_OBJECT:(state) => {
      return state.current_object
    },
    VEHICLE_SETTINGS:(state) => {
      return state.vehicle_settings
    },
    START_SEARCH:(state) => {
      return state.start_search
    },
    REPORTS_TYPE:(state) => {
      return state.report_type
    },
    SELECT_TYPE:(state) => {
      return state.select_type
    },
    START_RENDER_REPORT:(state) => {
      return state.start_render_report
    },
    CLIENT_ID:(state) => {
      return state.client_id
    },
    SHOW_REPORTS_LIST:(state) => {
      return state.show_reports_list
    },
    SHOW_EVENTS_LIST:(state) => {
      return state.show_events_list
    },
    REPORTS_COOKIE:(state) => {
      return state.reports_cookie
    },
    REPORTS_SETTINGS_CHECK: (state) => {
      return state.reports_settings_check
    },
    REPORTS_TIME: (state) => {
      return state.reports_time
    },
    REPORTS_SETTINGS_PARAMS: (state) => {
      return state.reports_settings_params
    },
    SHOW_REPORTS_SETTINGS: (state) => {
      return state.show_reports_settings
    },
    SHOW_GRAPH: (state) => {
      return state.show_graph
    },
    REPORTS_SELECT: (state) => {
      return state.reports_select
    },
    SHOW_REPORTS: (state) => {
      return state.show_reports
    },
    USER_AUTH: (state) => {
      return state.user_auth
    },
    UPDATE_POLYLINE: (state) => {
      return state.update_polyline
    },
    CALENDAR_DATES: (state) => {
      return state.calendar_dates
    },
    OBJECT_START: (state) => {
      return state.object_start
    },
    RELOAD_MAP: (state) => {
      return state.reload_map
    },
    BASE_ADD: (state) => {
      return state.base_add
    },
    SHOW_ALL_OBJECTS: (state) => {
      return state.show_all_objects
    },
    SHOW_CALENDAR_PANEL: (state) => {
      return state.show_calendar_panel
    },
    SHOW_ALL_ZONES: (state) => {
      return state.show_all_zones
    },
    FUEL_ROUTE: (state) => {
      return state.fuel_route;
    },
    SELECT_FUEL: (state) => {
      return state.select_fuel;
    },
    SELECT_FUEL_DATA: (state) => {
      return state.select_fuel_data;
    },
    FUEL_CHART_DATA: (state) => {
      return state.fuel_chart_data;
    },
    FUEL_MARKER: (state) => {
      return state.fuel_marker;
    },
    FUEL_FILL_MARKERS: (state) => {
      return state.fuel_fill_markers;
    },
    FUEL_TIME_CHANGE: (state) => {
      return state.fuel_time_change;
    },
    ACTIVE_ROUTE: (state) => {
      return state.active_route;
    },
    LANG_SHORT: (state) => {
      return state.lang_short;
    },
    LANG: (state) => {
      return state.lang;
    },
    OBJECTS: (state) => {
      return state.objects;
    },
    OBJECTS_LOADING: (state) => {
      return state.objects_loading;
    },
    SHOW_OBJECT_CONTENT: (state) => {
      return state.show_object_content;
    },
    SHOW_OBJECT_LIST: (state) => {
      return state.show_object_list;
    },
    START_CENTER: (state) => {
      return state.start_center;
    },
    MAP_ZOOM: (state) => {
      return state.map_zoom;
    },
    SEARCH_ICONS: (state) => {
      return state.search_icons;
    },
    SHOW_ONLY_SEARCH: (state) => {
      return state.show_only_search;
    },
    GROUP_LIST: (state) => {
      return state.group_list;
    },
    SHOW_GEO_ZONE: (state) => {
      return state.show_geo_zone;
    },
    POLYGON_ZONES: (state) => {
      return state.polygon_zones;
    },
    SHOW_WEATHER: (state) => {
      return state.show_weather;
    },
    WEATHER_OBJECT: (state) => {
      return state.weather_object;
    },
    LATLNG: (state) => {
      return state.latlng;
    },
    CREATE_PATH: (state) => {
      return state.create_path;
    },
    PATH_LATLNGS: (state) => {
      return state.path_latlngs;
    },
    OBJECT_INFO: (state) => {
      return state.object_info;
    },
    SHOW_OBJECT: (state) => {
      return state.show_object;
    },
    ZONE_INFO: (state) => {
      return state.zone_info;
    },
    SHOW_ZONE_INFO: (state) => {
      return state.show_zone_info;
    },
    OBJECT_FILTER: (state) => {
      return state.object_filter;
    },
    HISTORY_ARRAY: (state) => {
      return state.history_array;
    },
    SELECT_HISTORY: (state) => {
      return state.select_history;
    },
    SETTINGS_SHOW: (state) => {
      return state.settings_show;
    },
    SETTINGS_ACTIVE: (state) => {
      return state.settings_active;
    },
    SETTINGS_FUEL_ACTIVE: (state) => {
      return state.settings_fuel_active;
    },
    SETTINGS_OPTIONS: (state) => {
      return state.settings_options;
    },
    USER_ROUT_SETTINGS: (state) => {
      return state.user_route_settings;
    },
    HISTORY_TIME: (state) => {
      return state.history_time;
    },
    ADD_CALENDAR: (state) => {
      return state.add_calendar;
    },
    HISTORY_DATA: (state) => {
      return state.history_data;
    },
    HISTORY_TABLE_SHOW: (state) => {
      return state.history_table_show;
    },
    HISTORY_LOADING: (state) => {
      return state.history_loading;
    },
    MOVE_MARKER_SHOW: (state) => {
      return state.move_marker_show;
    },
    MOVE_MARKER_DATA: (state) => {
      return state.move_marker_data;
    },
    OBJECTS_NUMBERS: (state) => {
      return state.object_numbers;
    },
    ROUTE_EXCEL: (state) => {
      return state.route_excel;
    },
    STOP_MARKERS: (state) => {
      return state.stop_marker;
    },
    BOUND_CLUSTER: (state) => {
      return state.bound_cluster;
    },
    DEFAULT_PARAMS: (state) => {
      return state.default_params;
    },
    ZONE_BOUND: (state) => {
      return state.zone_bound;
    },
  },
  mutations: {
    SET_USER_ID: (state, payload) => {
      return state.user_id
    },
    SET_CURRENT_RESOURCES: (state, payload) => {
      return state.current_resources
    },
    SET_CURRENT_GROUP_LIST: (state, payload) => {
      state.current_group_list = payload;
    },
    SET_CURRENT_GROUP: (state, payload) => {
      state.current_group = payload;
    },
    SET_CURRENT_OBJECT: (state, payload) => {
      state.current_object = payload;
    },
    SET_VEHICLE_SETTINGS: (state, payload) => {
      state.vehicle_settings = payload;
    },
    SET_START_SEARCH: (state, payload) => {
      state.start_search = payload;
    },
    SET_REPORTS_TYPE: (state, payload) => {
      state.report_type = payload;
    },
    SET_SELECT_TYPE: (state, payload) => {
      state.select_type = payload;
    },
    SET_START_RENDER_REPORT: (state, payload) => {
      state.start_render_report = payload;
    },
    SET_CLIENT_ID: (state, payload) => {
      state.client_id = payload;
    },
    SET_SHOW_REPORTS_LIST: (state, payload) => {
      state.show_reports_list = payload;
    },
    SET_SHOW_EVENTS_LIST: (state, payload) => {
      state.show_events_list = payload;
    },
    SET_REPORTS_COOKIE: (state, payload) => {
      state.reports_cookie = payload;
    },
    SET_REPORTS_SETTINGS_CHECK: (state, payload) => {
      state.reports_settings_check = payload;
    },
    SET_REPORTS_TIME: (state, payload) => {
      state.reports_time = payload;
    },
    SET_REPORTS_SETTINGS_PARAMS: (state, payload) => {
      state.reports_settings_params = payload;
    },
    SET_SHOW_REPORTS_SETTINGS: (state, payload) => {
      state.show_reports_settings = payload;
    },
    SET_REPORTS_SELECT: (state, payload) => {
      state.reports_select = payload;
    },
    SET_SHOW_REPORTS: (state, payload) => {
      state.show_reports = payload;
    },
    SET_UPDATE_POLYLINE: (state, payload) => {
      state.update_polyline = payload;
    },
    SET_USER_AUTH: (state, payload) => {
      state.user_auth = payload;
    },
    SET_CALENDAR_DATES: (state, payload) => {
      state.calendar_dates = payload;
    },
    SET_OBJECT_START: (state, payload) => {
      state.object_start = payload;
    },
    SET_RELOAD_MAP: (state, payload) => {
      state.reload_map = payload;
    },
    SET_SHOW_ALL_ZONES: (state, payload) => {
      state.show_all_zones = payload;
    },
    SET_SHOW_ALL_OBJECTS: (state, payload) => {
      state.show_all_objects = payload;
    },
    SET_SHOW_CALENDAR_PANEL: (state, payload) => {
      state.show_calendar_panel = payload;
    },
    SET_FUEL_ROUTE: (state, payload) => {
      state.fuel_route = payload;
    },
    SET_FUEL_CHART_DATA: (state, payload) => {
      state.fuel_chart_data = payload;
    },
    SET_SELECT_FUEL: (state, payload) => {
      state.select_fuel = payload;
    },
    SET_SELECT_FUEL_DATA: (state, payload) => {
      state.select_fuel_data = payload;
    },
    SET_FUEL_MARKER: (state, payload) => {
      state.fuel_marker = payload;
    },
    SET_FUEL_FILL_MARKERS: (state, payload) => {
      state.fuel_fill_markers = payload;
    },
    SET_FUEL_TIME_CHANGE: (state, payload) => {
      state.fuel_time_change = payload;
    },
    SET_ACTIVE_ROUTE: (state, payload) => {
      state.active_route = payload;
    },
    SET_LANG_SHORT: (state, payload) => {
      state.lang_short = payload;
    },
    SET_LANG: (state, payload) => {
      state.lang = payload;
    },
    SET_OBJECT: (state, payload) => {
      state.objects = payload;
    },
    SET_OBJECTS_LOADING: (state, payload) => {
      state.objects_loading = payload;
    },
    SET_SHOW_OBJECT_LIST: (state, payload) => {
      state.show_object_list = payload;
    },
    SET_SHOW_OBJECT_CONTENT: (state, payload) => {
      state.show_object_content = payload;
    },
    SET_START_CENTER: (state, payload) => {
      state.start_center = payload;
    },
    SET_MAP_ZOOM: (state, payload) => {
      state.map_zoom = payload;
    },
    SET_SEARCH_ICONS: (state, payload) => {
      state.search_icons = payload;
    },
    SET_SHOW_ONLY_SEARCH: (state, payload) => {
      state.show_only_search = payload;
    },
    SET_GROUP_LIST: (state, payload) => {
      state.group_list = payload;
    },
    SET_SHOW_GEO_ZONE: (state, payload) => {
      state.show_geo_zone = payload;
    },
    SET_POLYGON_ZONES: (state, payload) => {
      state.polygon_zones = payload;
    },
    SET_SHOW_WEATHER: (state, payload) => {
      state.show_weather = payload;
    },
    SET_WEATHER_OBJECT: (state, payload) => {
      state.weather_object = payload;
    },
    SET_LATLNG: (state, payload) => {
      state.latlng = payload;
    },
    SET_CREATE_PATH: (state, payload) => {
      state.create_path = payload;
    },
    SET_PATH_LATLNGS: (state, payload) => {
      state.path_latlngs = payload;
    },
    SET_OBJECT_INFO: (state, payload) => {
      state.object_info = payload;
    },
    SET_SHOW_OBJECT: (state, payload) => {
      state.show_object = payload;
    },
    SET_ZONE_INFO: (state, payload) => {
      state.zone_info = payload;
    },
    SET_SHOW_ZONE_INFO: (state, payload) => {
      state.show_zone_info = payload;
    },
    SET_OBJECT_FILTER: (state, payload) => {
      state.object_filter = payload;
    },
    SET_SELECT_HISTORY: (state, payload) => {
      state.select_history = payload;
    },
    SET_HISTORY_ARRAY: (state, payload) => {
      state.history_array = payload;
    },
    SET_SETTINGS_SHOW: (state, payload) => {
      state.settings_show = payload;
    },
    SET_SETTINGS_OPTIONS: (state, payload) => {
      state.settings_options = payload;
    },
    SET_SETTINGS_ACTIVE: (state, payload) => {
      state.settings_active = payload;
    },
    SET_SETTINGS_FUEL_ACTIVE: (state, payload) => {
      state.settings_fuel_active = payload;
    },
    SET_USER_ROUT_SETTINGS: (state, payload) => {
      state.user_route_settings = payload;
    },
    SET_HISTORY_TIME: (state, payload) => {
      state.history_time = payload;
    },
    SET_ADD_CALENDAR: (state, payload) => {
      state.add_calendar = payload;
    },
    SET_HISTORY_DATA: (state, payload) => {
      state.history_data = payload;
    },
    SET_HISTORY_TABLE_SHOW: (state, payload) => {
      state.history_table_show = payload;
    },
    SET_HISTORY_LOADING: (state, payload) => {
      state.history_loading = payload;
    },
    SET_MOVE_MARKER_SHOW: (state, payload) => {
      state.move_marker_show = payload;
    },
    SET_MOVE_MARKER_DATA: (state, payload) => {
      state.move_marker_data = payload;
    },
    SET_OBJECTS_NUMBERS: (state, payload) => {
      state.object_numbers = payload;
    },
    SET_ROUTE_EXCEL: (state, payload) => {
      state.route_excel = payload;
    },
    SET_STOP_MARKERS: (state, payload) => {
      state.stop_marker = payload;
    },
    SET_BOUND_CLUSTER: (state, payload) => {
      state.bound_cluster = payload;
    },
    SET_DEFAULT_PARAMS: (state, payload) => {
      state.default_params = payload;
    },
    SET_ZONE_BOUND: (state, payload) => {
      state.zone_bound = payload;
    },
    SET_SHOW_GRAPH: (state, payload) => {
      state.show_graph = payload;
    },
  },

  actions: {
    getLanguage({commit}, type) {

      axios.get(base_url + '/translation/list', {
        params: {
          language: type
        }
      })
        .then(response => {
          if (response.data['success']) {

            let new_lang = response.data['data'];
            commit('SET_LANG', new_lang);
            commit('SET_LANG_SHORT', type);
          }
        }).catch(error => {
        console.log(error)
      })
    },
    getObjectGroups({commit}) {
      if(this.state.user_auth === true) {
        if(this.state.current_resources === null ){

          let formData = new FormData()
          formData.append("id", 0);
          axios.post(base_url + '/usermanager/getwebclient', formData).then(response => {
            if (response.data.success === true) {
              let arr_list = {};
              let resources = response.data.data.resources;
              resources.map(v => {
                arr_list[v.id] = v
              })

              this.state.current_resources =arr_list;

            }
          }).catch(error => {
            console.log(error)
          })
        }



        axios.get(base_url + '/index/objectsdata', {

        }).then(response => {
          if( response.data['auth']){
            commit('SET_USER_AUTH', false);
            location.reload();
          }else {
            let new_obj = response.data;

            if(this.state.client_id === null) {
              commit('SET_CLIENT_ID', new_obj[0].ClientId);
            }

            if (new_obj[0].ClientId === '152' || new_obj[0].ClientId  === '114') {
              let add = [];
              new_obj.map(item => {
                item['Name'] = 'Demo customer'

                if (item['ObjectId'] === "16481") {
                  item['Number'] ='demo_1'
                }
                if (item['ObjectId'] === "5404") {
                  item['Number']='demo_2'
                }
                if (item['ObjectId'] === "6786") {
                  item['Number']='demo_3'
                }
                add.push(item)
              })
              new_obj = add
            }


            if (this.state.active_route === 'routing') {
              if (this.state.object_filter === 'alpha') {
                new_obj.sort((a, b) => b.Name.toLowerCase().localeCompare(a.Name.toLowerCase()))
              }
              if (this.state.object_filter === 'speed') {
                new_obj.sort(function (a, b) {
                  return b.speed - a.speed;
                });
              }
            }
            commit('SET_OBJECT', new_obj);
            let new_load = false
            commit('SET_OBJECTS_LOADING', new_load);
          }
        }).catch(error => {
          console.log(error)
        })

      }
      else{
        location.reload();
      }
    },
    getGroupsList({commit}) {
      axios.get(base_url + '/objectgroup/list', {
      }).then(response => {
        if (response.data['success'] === true) {
          commit('SET_GROUP_LIST', response.data['data']);
        }

      }).catch(error => {
        console.log(error)
      })
    },
    clearHistory({commit}) {
      commit('SET_HISTORY_TABLE_SHOW', false)
      commit('SET_ADD_CALENDAR', false)
      commit('SET_HISTORY_LOADING', false)
      commit('SET_SELECT_HISTORY', false)
      commit('SET_SETTINGS_SHOW', false)
    },
    getHistoryData({commit}) {

      let add_key = this.state.settings_active
      if (this.state.history_time[add_key]) {

        commit('SET_HISTORY_TABLE_SHOW', false);
        commit('SET_ADD_CALENDAR', false);
        commit('SET_HISTORY_LOADING', true);
        if(!this.state.history_array[this.state.settings_active]){
          let his_arrey ={...this.state.history_array}

          var item = this.state.objects.find(item => item.ObjectId == this.state.settings_active);
          his_arrey[this.state.settings_active] = item
          commit('SET_HISTORY_ARRAY', his_arrey);
        }


        let formData = new FormData();

        let options = {}
        if (this.state.settings_options[add_key]) {
          options = this.state.settings_options[add_key]

          let marker_arr =  {...this.state.stop_markers}
          marker_arr[add_key] = [];
          commit('SET_STOP_MARKERS', marker_arr);

          let utcStart_old = new moment(this.state.history_time[add_key].start, "YYYY-MM-DD").unix();
          let utcStart = new moment(this.state.history_time[add_key].start, "YYYY-MM-DD").utc('UTC').unix();
          let diffInMinutes = utcStart-utcStart_old;
          let diff = diffInMinutes/3600

          let formData4 = new FormData();

          formData4.append('ObjectId', add_key);
          formData4.append('dt1', this.state.history_time[add_key].start);
          formData4.append('dt2', this.state.history_time[add_key].end);
          formData4.append('minStopTime', options.minStopTime);
          formData4.append('maxStopDistance', options.maxStopDistance);
          formData4.append('tzoffset', diff);
          formData4.append('skipMinDistance', options.skipMinDistance);
          formData4.append('byIgnition', options.chkByIgnition);

          axios.all([
            axios.post(base_url + '/index.php/routes/getrouteinfo', formData4),
          ]).then(axios.spread((response1) => {
            // console.log(response1)
            if (response1.data) {
              if (response1.data.routestart[0]) {
                let new_add = {...this.state.history_data}

                let start = [response1.data.routestart[0].Y, response1.data.routestart[0].X]

                response1.data.routes.forEach(v => {
                  v.checkbox = 0
                });
                response1.data.stops.forEach(v => {
                  v.checkbox = 0
                });

                new_add[add_key] = response1.data

                let new_object_numbers = {...this.state.object_numbers}
                new_object_numbers[add_key] = response1.data.Number
                commit('SET_SHOW_OBJECT_CONTENT', false);
                commit('SET_SHOW_GEO_ZONE', false);
                commit('SET_OBJECTS_NUMBERS', new_object_numbers);
                commit('SET_HISTORY_DATA', new_add);
                commit('SET_HISTORY_TABLE_SHOW', true);
                commit('SET_HISTORY_LOADING', false);
                setTimeout(()=> {
                  commit('SET_RELOAD_MAP', Math.random());

                }, 500)
                setTimeout(()=> {
                  commit('SET_RELOAD_MAP', Math.random());
                  commit('SET_UPDATE_POLYLINE', {'type': 1, 'counter': Math.random()});

                }, 1000)


              } else {
                commit('SET_HISTORY_LOADING', false);
              }
            }
          })).catch(error => {
            console.log(error)
          })



        } else {
          let arr = [
            'reports_minStopTime', 'reports_maxStopDistance', 'reports_skipMinDistance',
            'reports_chkByIgnition', 'routes_chkPlayer', 'routes_autostart',
            'routes_catchMarker', 'routes_showSpeedometer', 'routes_showTimePointsEnabled',
            'routes_chkSpeedLimit', 'routes_showTimePointsMinutes', 'routes_strokeOpacity',
            'routes_strokeSmooth', 'routes_strokeWeight', 'routes_minSpeed',
            'routes_maxSpeed', 'routes_strokeColor',
            'routes_speedLimit', 'routes_strokeBorderOpacity', 'routes_timePointsValue',
            'routes_strokeBorderColor', 'routes_show_direction',
            'routes_color_direction', 'mapShowAdditionalInfo',
            'route_marker_color'
          ]

          let arr_2 = [
            5, 50, 10,
            0, 0, 0,
            0, 0, 1,
            1, 5, 0.5,
            0, 7, 0,
            90, "16a765",
            1, 1, 0,
            "fa573c", 1,
            "3366E6", 1,
            "2979FF"
          ]
          let formData = new FormData();
          formData.append("object_id", add_key)
          for (let i = 0; i < arr.length; i++) {

            formData.append("parameters[" + i + "][0]", arr[i])
            formData.append("parameters[" + i + "][1]", arr_2[i])
          }
          let $this = this
          axios.post(base_url + '/objects/read-parameters', formData).then(res => {
            let add = this.default_params
            if (res.data.success === true) {
              let check = res.data.data
              add = {
                minStopTime: check.reports_minStopTime,
                maxStopDistance: check.reports_maxStopDistance,
                skipMinDistance: check.reports_skipMinDistance,
                chkByIgnition: check.reports_chkByIgnition,
                chkPlayer: check.routes_chkPlayer,
                player_autostart: check.routes_autostart,
                catch_marker: check.routes_catchMarker,
                show_speedometer: check.routes_showSpeedometer,
                showTimePointsEnabled: check.routes_showTimePointsEnabled,
                showTimePointsMinutes: check.routes_showTimePointsMinutes,
                use_speed_limit: check.routes_chkSpeedLimit,
                use_arrow_show: check.routes_show_direction,
                arrow_colorpicker: {
                  "hex": "#" + check.routes_color_direction,
                  "source": "hex"
                },
                colorpicker: {
                  "hex": "#" + check.routes_strokeBorderColor,
                  "source": "hex"
                },
                speedLimit: check.routes_speedLimit,
                strokeBorderOpacity: check.routes_strokeBorderOpacity,
                stroke_colorpicker: {
                  "hex": "#" + check.routes_strokeColor,
                  "source": "hex"
                },
                strokeOpacity: check.routes_strokeOpacity,
                strokeSmooth: check.routes_strokeSmooth,
                strokeWeight: check.routes_strokeWeight,
                mapShowRegionBorders: false,
                mapShowAdditionalInfo: check.mapShowAdditionalInfo,
                mapShowWeather: false,
                mapShowMiniMap: false,
                routes_speed: [check.routes_minSpeed, check.routes_maxSpeed],
                googleMapTypeId: check.googleMapTypeId,
                route_marker_color: {
                  "hex": "#" + check.route_marker_color,
                  "source": "hex"
                },
              }


            }
            let add_new = {...this.state.settings_options}
            add_new[add_key] = add
            commit('SET_SETTINGS_OPTIONS', add_new)
            options =add
            let marker_arr =  {...this.state.stop_markers}
            marker_arr[add_key] = [];
            commit('SET_STOP_MARKERS', marker_arr);

            let utcStart_old = new moment(this.state.history_time[add_key].start, "YYYY-MM-DD").unix();
            let utcStart = new moment(this.state.history_time[add_key].start, "YYYY-MM-DD").utc('UTC').unix();
            let diffInMinutes = utcStart-utcStart_old;
            let diff = diffInMinutes/3600


            let formData3 = new FormData();
            formData3.append('ObjectId', add_key);
            formData3.append('dt1', this.state.history_time[add_key].start);
            formData3.append('dt2', this.state.history_time[add_key].end);
            formData3.append('minStopTime', options.minStopTime);
            formData3.append('maxStopDistance', options.maxStopDistance);
            formData3.append('tzoffset', diff);
            formData3.append('skipMinDistance', options.skipMinDistance);
            formData3.append('byIgnition', options.chkByIgnition);

            axios.all([
              axios.post(base_url + '/index.php/routes/getrouteinfo', formData3),
            ]).then(axios.spread((response1) => {
              // console.log(response1)
              if (response1.data) {
                if (response1.data.routestart[0]) {
                  let new_add = {...this.state.history_data}

                  let start = [response1.data.routestart[0].Y, response1.data.routestart[0].X]

                  response1.data.routes.forEach(v => {
                    v.checkbox = 0
                  });
                  response1.data.stops.forEach(v => {
                    v.checkbox = 0
                  });

                  new_add[add_key] = response1.data

                  let new_object_numbers = {...this.state.object_numbers}
                  new_object_numbers[add_key] = response1.data.Number
                  commit('SET_SHOW_OBJECT_CONTENT', false);
                  commit('SET_SHOW_GEO_ZONE', false);
                  commit('SET_OBJECTS_NUMBERS', new_object_numbers);
                  commit('SET_HISTORY_DATA', new_add);
                  commit('SET_HISTORY_TABLE_SHOW', true);
                  commit('SET_HISTORY_LOADING', false);
                  setTimeout(()=> {
                    commit('SET_RELOAD_MAP', Math.random());

                  }, 500)
                  setTimeout(()=> {
                    commit('SET_RELOAD_MAP', Math.random());
                    commit('SET_UPDATE_POLYLINE', {'type': 1, 'counter': Math.random()});

                  }, 1000)


                } else {
                  commit('SET_HISTORY_LOADING', false);
                }
              }
            })).catch(error => {
              console.log(error)
            })



          });
        }

      }
    },
    getObjectInfo({commit}, ObjectId) {
      axios.get(base_url + '/objects/info?object_id=' + ObjectId
      ).then(response => {
        let add = response.data['data']['info']
        add.id = ObjectId
        commit('SET_OBJECT_INFO', add);

      }).catch(error => {
        console.log(error)
      })
    },
    getFuelData({commit}) {
      commit('SET_HISTORY_TABLE_SHOW', false);
      if (this.state.select_fuel.ObjectId !== 0) {
        let formData = new FormData();

        let start = this.state.select_fuel_data.start*1000
        let start_data  =  moment(this.state.calendar_dates[0]).format("DD.MM.YYYY HH:mm:ss")
        let end = this.state.select_fuel_data.end*1000
        let end_data = moment(this.state.calendar_dates[1]).format("DD.MM.YYYY HH:mm:ss")
        formData.append('ObjectIds[]', this.state.select_fuel.ObjectId)
        formData.append('dt1', start_data)
        formData.append('dt2', end_data)

        axios.all([
          axios.post(base_url + '/fuel/getfuel', formData),
        ]).then(axios.spread((response1) => {
          if (response1.data['success'] === true) {
            if( response1.data['data'] && response1.data['data'][this.state.select_fuel.ObjectId].fuel.length  > 0){
              let add = response1.data['data'][this.state.select_fuel.ObjectId].fuel
              let len2 =Math.round(add.length/2)
            }
            let status =  response1.data['data'][this.state.select_fuel.ObjectId].ignition
          let chart_data = {}
            chart_data['data'] =response1.data['data'][this.state.select_fuel.ObjectId].fuel
          chart_data['bg'] ={'#fff':status['i0'],'#C76ACB':status['i1s0'], '#B4CF58':status['i1s1']}
            chart_data['total'] =response1.data['data'][this.state.select_fuel.ObjectId].total
            chart_data['days'] =response1.data['data'][this.state.select_fuel.ObjectId].days
            commit('SET_FUEL_CHART_DATA',chart_data)
            commit('SET_HISTORY_TABLE_SHOW', true)
            commit('SET_SHOW_OBJECT_CONTENT', false)
            commit('SET_SHOW_CALENDAR_PANEL', false)

          }
        })).catch(error => {
          console.log(error)
        })
      }
    },
    getFuel2Data({commit}) {
      commit('SET_SHOW_GRAPH', false);
      commit('SET_HISTORY_TABLE_SHOW', false);
       let active_fuel =   this.state.select_fuel.ObjectId
      if (this.state.select_fuel.ObjectId !== 0) {
        let formData = new FormData();

        formData.append('id', this.state.select_fuel.ObjectId)
        formData.append('dt1', this.state.select_fuel_data[active_fuel].start)
        formData.append('dt2', this.state.select_fuel_data[active_fuel].end)

        axios.all([
          axios.post(base_url + '/fuel/getroute', formData),
          axios.post(base_url + '/fuel/getchart', formData)
        ]).then(axios.spread((response1, response2) => {
          if (response2.data['success'] === true) {
            let add = {...this.state.fuel_chart_data};
            add[this.state.select_fuel.ObjectId] = response2.data
            commit('SET_FUEL_CHART_DATA', add)
          }
          if (response1.data['success'] === true) {
            let add_route = {...this.state.fuel_route};
            add_route[this.state.select_fuel.ObjectId] = response1.data['data']
            commit('SET_FUEL_ROUTE', add_route)
            if( response1.data['data'] && response1.data['data'].length  > 0){
              let len2 =Math.round(response1.data['data'].length/2)
              setTimeout(()=> {
                commit('SET_UPDATE_POLYLINE', {'type':2,'counter':Math.random()});
              }, 1000)
            }

            commit('SET_HISTORY_TABLE_SHOW', true)
            commit('SET_SHOW_OBJECT_CONTENT', false)
            commit('SET_SHOW_CALENDAR_PANEL', false)

          }
        })).catch(error => {
          console.log(error)
        })
      }
    },
    setUserParametrs({commit},params){
      axios.post(base_url + '/index/setuserparameter/', params
      ).then(response => {

      }).catch(error => {
        console.log(error)
      })
    },
    getReportsDailyData({commit}){

      commit('SET_SHOW_REPORTS_LIST',true);
      commit('SET_ADD_CALENDAR', false);
      commit('SET_START_RENDER_REPORT', true);
    }
  },

})
