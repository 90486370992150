<template>
  <div v-if="move_marker_data.id" style="padding: 20px">
    <h2>TrackPro - Report</h2>
    <h3>{{number}}</h3>
    <div style="width: 100%;height: auto;margin-bottom: 20px">
    <img style="margin-left: auto;margin-right: auto" :src="response" alt="map point" >
    </div>
  <div class="table-responsive">
    <table class="table table-bordered mb-none">
      <thead>
      <tr >
        <th v-for="(value, name) in move_marker_data.info">{{name}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <td v-for="(value, name) in move_marker_data.info">{{value}}</td>
      </tr>
      </tbody>
    </table>
  </div>
  </div>
</template>

<script>
export default {
  name: "StaticMapData",
  props:{move_marker_data:{default:{}},
    number:{default:''},
    response:{default:''},
  }

}
</script>

<style scoped>

</style>
