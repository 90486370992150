<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
       id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;"
       xml:space="preserve">
<g>
	<g>
		<path
      d="M437.02,74.981C388.667,26.628,324.381,0,256,0S123.333,26.628,74.98,74.981C26.627,123.334,0,187.62,0,256    s26.628,132.667,74.98,181.019C123.332,485.37,187.619,512,256,512s132.667-26.628,181.02-74.981    C485.373,388.666,512,324.38,512,256S485.372,123.333,437.02,74.981z M413.409,413.408    c-42.046,42.045-97.948,65.201-157.409,65.201s-115.362-23.156-157.409-65.201c-42.044-42.045-65.2-97.947-65.2-157.408    s23.156-115.362,65.2-157.408C140.637,56.547,196.539,33.391,256,33.391s115.363,23.156,157.409,65.201    c42.044,42.045,65.2,97.947,65.2,157.408S455.453,371.362,413.409,413.408z"/>
	</g>
</g>
    <g>
	<g>
		<path
      d="M256,66.783C151.666,66.783,66.783,151.666,66.783,256S151.666,445.217,256,445.217S445.217,360.334,445.217,256    S360.334,66.783,256,66.783z M272.696,410.93v-21.279c0-0.029,0.002-0.057,0.002-0.086c0-9.22-7.477-16.696-16.698-16.696    c-0.001,0-0.001,0-0.002,0s-0.001,0-0.002,0c-9.22,0-16.691,7.475-16.691,16.696v21.364    c-72.348-7.76-130.287-65.885-138.197-138.233h21.65c9.22,0,16.696-7.312,16.696-16.532c0-4.679-1.931-8.822-5.033-11.854    c-3.035-3.133-7.279-5.211-11.985-5.211c-1.126,0-2.226,0.205-3.289,0.205h-18.114c7.631-72.347,65.924-130.449,138.272-138.232    v22.019c0,9.22,7.475,16.696,16.696,16.696c9.22,0,16.696-7.475,16.696-16.696v-0.57c0-0.029,0.004-0.057,0.004-0.086    c0-0.029-0.004-0.057-0.004-0.086V101.07c72.348,7.78,130.641,65.887,138.272,138.234h-18.113c-1.064,0-2.163-0.164-3.289-0.164    c-4.681,0-8.908,2.015-11.94,5.119c-3.133,3.035-5.089,7.157-5.089,11.864c0,9.22,7.475,16.573,16.696,16.573h21.66    C402.983,345.043,345.043,403.173,272.696,410.93z"/>
	</g>
</g>
    <g>
	<g>
		<path
      d="M327.603,185.047c-6.519-6.52-17.091-6.52-23.611,0l-47.669,47.669l-57.909-57.909c-6.519-6.52-17.091-6.52-23.611,0    s-6.52,17.091,0,23.611l69.716,69.715c3.26,3.26,7.533,4.891,11.805,4.891c4.272,0,8.546-1.63,11.805-4.891l59.475-59.475    C334.123,202.138,334.123,191.567,327.603,185.047z"/>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgClockIcon"
}
</script>

<style scoped>

</style>
