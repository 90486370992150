import Vue from 'vue'
import VueRouter from 'vue-router'
import RoutesComponent from "@/components/routes/RoutesComponent";
import FuelComponent from "@/components/fuel/FuelComponent";
import EventsComponent from "@/components/events/EventsComponent";
import GlobalSettings from "@/components/settings/GlobalSettings";
import TasksComponent from "@/components/tasks/TasksComponent";
import ManagmentComponent from "@/components/usermanagment/ManagmentComponent";
import GroupComponent from "@/components/objects/GroupComponent";
import EngineLogsComponent from "@/components/engine/EngineLogsComponent";
import ShareComponent from "@/components/share/ShareComponent.vue";


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    component: RoutesComponent,
    name:"Routes",
  },
  {
    path: '/fuel',
    component: FuelComponent,
    name:"Fuel",
  },
  {
    path: '/events',
    component: EventsComponent,
    name:"Events",
  },
  {
    path: '/management',
    component: GlobalSettings,
    name:"Management",
  },
  {
    path: '/tasks',
    component: TasksComponent,
    name:"Tasks",
  },
  {
    path: '/usermanager',
    component: ManagmentComponent,
    name:"UserManager",
  },
  {
    path: '/groupmanager',
    component: GroupComponent,
    name:"GroupManager",
  },
  {
    path: '/engine',
    component: EngineLogsComponent,
    name:"EngineLogs",
  },
  {
    path: '/share',
    component: ShareComponent,
    name:"ShareManager",
  },


]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  routes
})



export default router
