<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 60 60" style="enable-background:new 0 0 60 60;" xml:space="preserve">
<g>
	<path d="M21.021,28.977C18.75,26.412,15.722,25,12.493,25s-6.257,1.412-8.527,3.977c-4.612,5.211-4.612,13.688,0,18.899   l8.527,9.633l8.527-9.633C25.633,42.665,25.633,34.188,21.021,28.977z M19.523,46.55l-7.029,7.941L5.464,46.55   c-3.966-4.479-3.966-11.768,0-16.247C7.349,28.173,9.846,27,12.493,27s5.145,1.173,7.029,3.303   C23.488,34.782,23.488,42.07,19.523,46.55z"/>
  <path d="M48.747,24.507l6.464-7.286c3.478-3.92,3.478-10.296,0-14.216C53.491,1.067,51.195,0,48.746,0   c-2.448,0-4.744,1.067-6.464,3.005c-3.478,3.92-3.478,10.296,0,14.216L48.747,24.507z M43.778,4.333   C45.113,2.828,46.877,2,48.746,2c1.87,0,3.634,0.828,4.969,2.333c2.828,3.187,2.828,8.373,0,11.56l-4.968,5.601l-4.969-5.601   C40.95,12.706,40.95,7.52,43.778,4.333z"/>
  <path d="M52.604,54.517c-0.539-0.121-1.074,0.217-1.195,0.756c-0.115,0.51-0.338,0.979-0.663,1.392   c-0.341,0.434-0.267,1.063,0.168,1.404c0.184,0.144,0.401,0.214,0.617,0.214c0.297,0,0.59-0.131,0.787-0.382   c0.504-0.64,0.864-1.396,1.042-2.188C53.481,55.173,53.143,54.638,52.604,54.517z"/>
  <path d="M31.995,31c-0.433-0.001-0.854-0.078-1.252-0.23c-0.512-0.195-1.093,0.062-1.291,0.578   c-0.196,0.516,0.063,1.094,0.578,1.291c0.626,0.238,1.285,0.36,1.963,0.361h0.357c0.553,0,1-0.447,1-1s-0.447-1-1-1H31.995z"/>
  <path d="M29.621,24.676c0.181,0,0.363-0.049,0.528-0.151c0.443-0.276,0.933-0.445,1.454-0.503c0.549-0.061,0.945-0.555,0.885-1.104   s-0.551-0.941-1.104-0.885c-0.822,0.091-1.594,0.357-2.294,0.794c-0.469,0.292-0.611,0.909-0.319,1.378   C28.961,24.509,29.287,24.676,29.621,24.676z"/>
  <path d="M18.064,58h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S18.616,58,18.064,58z"/>
  <path d="M35.993,40c-0.086,0-0.171,0.002-0.256,0.006c-0.552,0.022-0.98,0.488-0.958,1.04c0.021,0.538,0.465,0.959,0.998,0.959   c0.014,0,0.028,0,0.042-0.001L37.778,42c0.553,0,1-0.447,1-1s-0.447-1-1-1H35.993z"/>
  <path d="M35.493,24h2c0.553,0,1-0.447,1-1s-0.447-1-1-1h-2c-0.553,0-1,0.447-1,1S34.94,24,35.493,24z"/>
  <path d="M47.778,40c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1s-0.447-1-1-1H47.778z"/>
  <path d="M51.351,32c0-0.553-0.447-1-1-1h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2C50.903,33,51.351,32.553,51.351,32z"/>
  <path d="M41.778,42h2c0.553,0,1-0.447,1-1s-0.447-1-1-1h-2c-0.553,0-1,0.447-1,1S41.226,42,41.778,42z"/>
  <path d="M43.493,24c0.553,0,1-0.447,1-1s-0.447-1-1-1h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1H43.493z"/>
  <path d="M36.351,31c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1s-0.447-1-1-1H36.351z"/>
  <path d="M45.351,32c0-0.553-0.447-1-1-1h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2C44.903,33,45.351,32.553,45.351,32z"/>
  <path d="M59.426,35.632c-0.088-0.546-0.603-0.919-1.146-0.829c-0.546,0.087-0.917,0.601-0.829,1.146   c0.028,0.178,0.044,0.359,0.042,0.552c0,0.336-0.048,0.668-0.142,0.988c-0.155,0.53,0.148,1.086,0.679,1.241   c0.094,0.027,0.188,0.04,0.281,0.04c0.433,0,0.832-0.282,0.96-0.719c0.147-0.503,0.222-1.024,0.222-1.543   C59.495,36.22,59.473,35.925,59.426,35.632z"/>
  <path d="M42.064,58h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S42.616,58,42.064,58z"/>
  <path d="M36.064,58h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S36.616,58,36.064,58z"/>
  <path d="M47.065,50c0-0.553-0.447-1-1-1h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2C46.617,51,47.065,50.553,47.065,50z"/>
  <path d="M41.065,50c0-0.553-0.447-1-1-1h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2C40.617,51,41.065,50.553,41.065,50z"/>
  <path d="M47.993,58h-1.93c-0.553,0-1,0.447-1,1s0.447,1,1,1l1.971-0.001l0.029-1v1c0.553,0,0.965-0.447,0.965-1   C49.028,58.447,48.546,58,47.993,58z"/>
  <path d="M51.531,52.719c0.216,0,0.435-0.07,0.617-0.214c0.435-0.342,0.509-0.971,0.168-1.404c-0.503-0.639-1.152-1.167-1.879-1.528   c-0.497-0.247-1.095-0.044-1.341,0.45c-0.246,0.495-0.044,1.095,0.45,1.341c0.463,0.229,0.877,0.566,1.197,0.974   C50.941,52.588,51.234,52.719,51.531,52.719z"/>
  <path d="M55.345,39.729C54.916,39.909,54.462,40,53.993,40h-0.215c-0.553,0-1,0.447-1,1s0.447,1,1,1h0.215   c0.736,0,1.451-0.144,2.125-0.427c0.51-0.214,0.749-0.8,0.535-1.309C56.44,39.755,55.85,39.515,55.345,39.729z"/>
  <path d="M28.029,29.855c0.524-0.173,0.81-0.738,0.638-1.263c-0.115-0.351-0.174-0.718-0.174-1.088   c0.001-0.151,0.01-0.302,0.028-0.449c0.069-0.548-0.319-1.049-0.867-1.117c-0.565-0.063-1.049,0.321-1.117,0.867   c-0.028,0.226-0.043,0.456-0.044,0.694c0,0.588,0.092,1.166,0.273,1.718c0.139,0.421,0.53,0.688,0.95,0.688   C27.82,29.905,27.926,29.89,28.029,29.855z"/>
  <path d="M24.064,58h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S24.616,58,24.064,58z"/>
  <path d="M34.122,50.594c0.38,0,0.742-0.217,0.91-0.584c0.229-0.503,0.009-1.096-0.493-1.325c-0.469-0.215-0.894-0.538-1.228-0.936   c-0.356-0.423-0.986-0.478-1.409-0.122c-0.423,0.355-0.478,0.986-0.122,1.409c0.524,0.623,1.19,1.131,1.927,1.467   C33.842,50.564,33.983,50.594,34.122,50.594z"/>
  <path d="M56.746,31.738c-0.714-0.414-1.494-0.657-2.317-0.722c-0.549-0.04-1.032,0.367-1.075,0.919   c-0.043,0.551,0.368,1.032,0.919,1.075c0.522,0.041,1.017,0.195,1.47,0.458c0.158,0.092,0.331,0.135,0.501,0.135   c0.345,0,0.681-0.179,0.866-0.498C57.387,32.628,57.224,32.016,56.746,31.738z"/>
  <path d="M30.064,58h-2c-0.553,0-1,0.447-1,1s0.447,1,1,1h2c0.553,0,1-0.447,1-1S30.616,58,30.064,58z"/>
  <path d="M32.555,44.839c0.099-0.514,0.307-0.989,0.619-1.413c0.327-0.444,0.232-1.07-0.213-1.398   c-0.445-0.326-1.07-0.232-1.398,0.213c-0.489,0.666-0.817,1.413-0.973,2.222c-0.104,0.542,0.251,1.066,0.794,1.171   c0.063,0.012,0.127,0.018,0.189,0.018C32.043,45.65,32.462,45.317,32.555,44.839z"/>
  <path d="M48.993,15c3.032,0,5.5-2.468,5.5-5.5S52.025,4,48.993,4s-5.5,2.468-5.5,5.5S45.961,15,48.993,15z M48.993,6   c1.93,0,3.5,1.57,3.5,3.5s-1.57,3.5-3.5,3.5s-3.5-1.57-3.5-3.5S47.064,6,48.993,6z"/>
  <path d="M12.493,31c-3.309,0-6,2.691-6,6s2.691,6,6,6s6-2.691,6-6S15.802,31,12.493,31z M12.493,41c-2.206,0-4-1.794-4-4   s1.794-4,4-4s4,1.794,4,4S14.699,41,12.493,41z"/>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgRouteZoneIcone"
}
</script>

<style scoped>

</style>
