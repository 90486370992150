<template>
  <section class="object-info-box">
    <div class="card object-info">
      <div class="widgets-panel__header">
        <div class="widgets-panel__tracker-title">
          <div v-if="checkObjectLength(object_info) ">
            <span  v-if="object_info.id !== '16481' && object_info.id !== '5404' && object_info.id !== '6786'">

            {{ object_info.number }}
          </span>
            <span  v-if="object_info.id == '16481'">
            demo_1
            </span>
            <span  v-if="object_info.id == '5404'">
            demo_2
            </span>
            <span  v-if="object_info.id == '6786'">
            demo_3
            </span>
          </div>

          <button type="button" class="btn object-info-close" @click="closeInfo()"><span class=" fa fa-times"></span>
          </button>

        </div>
        <div class="street-view widgets-panel__street-view">
          <div class="street-view__streetview" :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">


          </div>
          <div class="street-view__click-stub" @click="getStreetView()">
            <span aria-hidden="true" class="fa touch-icon">
            <svg-click-icon></svg-click-icon>
          </span>
            <translate-component :text="lang.object_info_component.view_panoramas"></translate-component>
          </div>
        </div>
      </div>
      <template v-if="checkObjectLength(object_info)">
        <div class="card-body">
        </div>
        <ul class="list-group list-group-flush bject-info">
          <li class="list-group-item">
            <div class="list-group-icon">
              <svg-map-icon></svg-map-icon>

            </div>
            <div>
              <div class="info-list-title">
                <translate-component :text="lang.object_info_component.address"></translate-component>
              </div>
              <div class="info-list-item">{{ object_info.gps.address }}</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="list-group-icon">
              <svg-clock-icon></svg-clock-icon>
            </div>
            <div>
              <div class="info-list-title">
                <translate-component :text="lang.object_info_component.time"></translate-component>
              </div>
              <div class="info-list-item">{{ object_info.gps.time | moment('DD.MM.YYYY HH:mm:ss') }}</div>

            </div>
          </li>
          <li class="list-group-item">
            <div class="list-group-icon">
              <svg-coordinate-icon></svg-coordinate-icon>
            </div>
            <div>
              <div class="info-list-title">
                <translate-component :text="lang.object_info_component.coordinates"></translate-component>
              </div>
              <div class="info-list-item">{{ object_info.gps.lat + ', ' + object_info.gps.lng }}</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="list-group-icon">
            <svg-status-info-icon/>
            </div>
            <div>

              <div class="info-list-title">
                <translate-component :text="lang.object_info_component.status"></translate-component>
              </div>

              <div class="info-list-item">
                <translate-component
                  :text="lang.object_info_component['object_status_'+ object_info.status.value]"></translate-component>
                <span class="info-list-item_label">{{ getDuration(object_info.status.duration) }} </span></div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="list-group-icon">
            <svg-speed-icon/>
            </div>
            <div>
              <div class="info-list-title">
                <translate-component :text="lang.object_info_component.speed_now"></translate-component>
              </div>
              <div class="info-list-item">{{ object_info.gps.speed }} km/h</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="list-group-icon">
              <svg-distance-icon/>
            </div>
            <div>
              <div class="info-list-title">
                <translate-component :text="lang.object_info_component.distance_today"></translate-component>
              </div>
              <div class="info-list-item">{{ object_info.distance.today }} km</div>
            </div>
          </li>
          <li class="list-group-item">
            <div class="info-list-title">
              <translate-component :text="lang.object_info_component.information"></translate-component>
            </div>
            <div class="info-list-item" style="padding-left: 10px">  {{ object_info.comment }}</div>
          </li>
          <template v-if="current_resources && current_resources[20] && current_resources[20].enabled ===true">
          <li class="list-group-item">
            <div class="info-list-title">
              <form method="post" action="/routes/">
                <input type="hidden" name="ObjectId" :value="object_info.id ">
                <a @click="todayRoute()">
                  <translate-component :text="lang.object_info_component.routes_today"></translate-component>
                </a>
              </form>
            </div>
            <div class="info-list-item"></div>
          </li>
          </template>
        </ul>
        <div class="card-body bject-info" v-if="checkObjectLength(more_info)">
          <ul class="object-info-more-list">
            <template v-for="(item,key,index) in more_info">
              <li class="object-info-more-item" v-if="item !== ''"><span class="object-info-more-key">
                {{ key === 'a' ? 'Car battery' : key }}
                :</span><span>{{ item }}</span></li>
            </template>
          </ul>
        </div>
        <div class="card-body bject-info" v-else>
          <a  class="card-link bject-info" @click="getMoreInfo(object_info.id)">
            <translate-component :text="lang.object_info_component.more_details"></translate-component>
          </a>

        </div>

      </template>
    </div>
  </section>

</template>

<script>
import {mapGetters} from "vuex";
import helpers from "@/mixins/helpers";
import mapHelper from "@/mixins/mapHelper";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import moment from "moment";
import axios from "axios";
import SvgMapIcon from "@/components/ui/icons/svgMapIcon";
import SvgClockIcon from "@/components/ui/icons/svgClockIcon";
import SvgCoordinateIcon from "@/components/ui/icons/svgCoordinateIcon";
import SvgClickIcon from "@/components/ui/icons/svgClickIcon";
import SvgStatusInfoIcon from "@/components/ui/icons/svgStatusInfoIcon";
import SvgSpeedIcon from "@/components/ui/icons/svgSpeedIcon";
import SvgDistanceIcon from "@/components/ui/icons/svgDistanceIcon";


var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);
typeof moment.duration.fn.format === "function";
// true
typeof moment.duration.format === "function";
// true
export default {
  name: "ObjectInfoComponent",
  components: {
    SvgDistanceIcon,
    SvgSpeedIcon,
    SvgStatusInfoIcon, SvgClickIcon, SvgCoordinateIcon, SvgClockIcon, SvgMapIcon, TranslateComponent},
  mixins: [helpers, mapHelper],

  data() {
    return {
      base_url: base_url,
      backgroundImageUrl:  '/tracking/assets/images/geo-zone/image_riga_600.jpg',
      more_info: {},
    }
  },
  computed: {
    ...mapGetters({
      object_info: 'OBJECT_INFO',
      history_time: 'HISTORY_TIME',
      objects: 'OBJECTS',
      history_array: 'HISTORY_ARRAY',
      lang: 'LANG',
      active_route: "ACTIVE_ROUTE",
      current_resources:'CURRENT_RESOURCES',
    }),
  },

  methods: {
    todayRoute(){
      if(this.active_route !== 'routing'){
        this.$router.push("/");
      }
      let settings_active = this.object_info.id
      let object = this.objects.find(item =>item.ObjectId === settings_active)

      this.$store.commit('SET_SELECT_HISTORY', true)
      this.$store.commit('SET_SETTINGS_ACTIVE', settings_active)
      let start_val = this.$moment().startOf('day').toDate();
      let end_val = this.$moment().startOf('day').add(23, 'hours').add(59, 'minutes').toDate();
      let start = moment().startOf('day').format("DD.MM.YYYY HH:mm:ss")
      let end = moment().startOf('day').add(23, 'hours').add(59, 'minutes').format("DD.MM.YYYY HH:mm:ss")


      let data = {'start':start, 'end':end ,'start_val':start_val,'end_val':end_val}
      let old_data = {...this.history_time}
      old_data[settings_active] = data
      this.$store.commit('SET_HISTORY_TIME', old_data)
      this.$store.commit('SET_HISTORY_TABLE_SHOW', false)
      this.closeInfo()
    },
    getStreetView() {
      this.object_info.gps.lat
      this.object_info.gps.lng

      let _this = this
      $.ajax({
        url: 'https://maps.googleapis.com/maps/api/streetview?size=600x300&location=' + this.object_info.gps.lat + ',' + this.object_info.gps.lng + '&heading=151.78&pitch=-0.76&key=AIzaSyCoAoGJxRqTz8JJjOwHb4D64FqFC2IT0mE&signature=YOUR_SIGNATURE',
        type: 'GET',
        success: function (res) {
          //console.log(res);

        },
        error: function (error) {
          console.log(error);
        }
      });

    },
    getMoreInfo(Object_id) {

      let $this = this;
      let formData = new FormData();
      formData.append('ObjectId', (Object_id));

      axios.post(base_url + '/index/monitoring', formData
      ).then(response => {
        if (response.data['data']) {
          this.more_info = response.data['data'][Object_id];
        }
      }).catch(error => {
        console.log(error)
      })
    },

    getDuration(add_duration) {
      let add = moment.duration(add_duration, "seconds").format("d[d] h:mm:ss");

      return add
    },
    closeInfo() {
      this.$store.commit('SET_SHOW_OBJECT', false);
    }
  }


}
</script>


<style scoped>
.object-info-more-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  max-height: 16vh;
  height: auto;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch;
}

.object-info-more-item {
  font-size: 12px;
  line-height: 18px;
}

.object-info-more-key {
  margin-right: 10px;
}

.card.object-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  max-width: 350px;
  height: auto;
  max-height: calc(100% - 80px);
  position: fixed;
  background-color: #fff;
  padding: 0;
  margin: 0;
  top: 60px;
  border-radius: 4px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
}

.widgets-panel__header {
  position: relative;
  height: 110px;
  padding: 0;
  margin: 0;
}
.info-list-title a{
  cursor: pointer;
}
.bject-info a{
  cursor: pointer;
}
.widgets-panel__tracker-title {
  color: #fff !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  width: 100%;
  z-index: 2;
  font-size: 20px;
  font-weight: bolder;
  text-shadow: 1px 1px #000;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);

}

.street-view__streetview, .street-view__streetview img {
  border-radius: 4px 4px 0px 0px;
  background-size: cover;
  -webkit-filter: blur(2px);
  filter: blur(2px);
  width: 100%;
  height: 110px;
  background-repeat: no-repeat;
}
.widgets-panel__tracker-title{
  padding-right: 30px;
}
.street-view__click-stub {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  cursor: pointer;
  font-size: 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.widgets-panel .widgets-panel__street-view {
  height: 110px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.widgets-panel__street-view, .widgets-panel__tracker-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.object-info-close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 20px;
  color: #ccc;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  align-items: center;
}
.list-group-item {
  border-radius: 0;
}
.object-info-close:hover, .object-info-close:focus {
  color: #ffff
}

.list-group-icon {
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.list-group-icon svg {
  fill: #4cabdb;
}

.list-group-item {
  display: flex;
  align-items: center;
}

.info-list-item {
  font-weight: 600;
  line-height: 18px;
  color: #34495e;
}

.info-list-title {
  line-height: 18px;

}

.info-list-item_label {
  border: 1px solid #4cabdb;
  padding: 2px 10px;
  border-radius: 15px;
  margin-left: 5px;
  white-space: nowrap;
}

.card-body.bject-info {
  padding: 20px 20px;
  padding-top: 0;
}

.list-group-item {
  padding: 8px 15px;
}
.street-view__click-stub span{
  opacity:0
}
@media only screen and (max-width: 1500px) {
  .card.object-info {
    min-width: 300px;
    max-width: 320px;
  }
  .widgets-panel__header{
    height: 110px;
  }
  .street-view__streetview, .street-view__streetview img {
    height: 110px;
  }
  .list-group-item{
    padding: 5px 8px;
  }
  .list-group-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-right: 10px;
  }
  .info-list-item_label {
    padding: 2px 6px;
  }

}
@media only screen and (max-width: 1500px) {
  .card.object-info {
    box-shadow: rgb(99 99 99 / 40%) 2px 4px 8px 0px;
  }
}

@media only screen and (max-width: 600px) {
  .object-info-box {
    right: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
    max-width: 100vw;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 100000;
    top: 60px;
    left: 0px;
  }

  .card.object-info {
    min-width: 100vw;
    max-width: 100vw;
    height: 100%;
    max-height: calc(100% - 60px);
  }
}
</style>
