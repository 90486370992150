<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
       id="Layer_1" x="0px" y="0px" viewBox="0 0 502 502" style="enable-background:new 0 0 502 502;"
       xml:space="preserve">
<g>
	<g>
		<g>
			<path
        d="M117.253,371.669c3.112,0,6.047-1.449,7.939-3.919c0.271-0.354,27.396-35.857,54.14-78.162     c36.611-57.913,55.174-100.707,55.174-127.194c0-64.654-52.6-117.253-117.253-117.253S0,97.74,0,162.393     c0,26.487,18.563,69.282,55.174,127.194c26.744,42.305,53.869,77.808,54.14,78.162     C111.207,370.22,114.141,371.669,117.253,371.669z M20,162.393c0-53.625,43.628-97.253,97.253-97.253     s97.253,43.628,97.253,97.253c0,42.092-64.164,137.76-97.254,182.618C84.16,300.158,20,204.504,20,162.393z"/>
      <path
        d="M187.544,162.393c0-38.758-31.532-70.291-70.291-70.291c-38.759,0-70.291,31.532-70.291,70.291     c0,38.759,31.532,70.291,70.291,70.291C156.012,232.684,187.544,201.152,187.544,162.393z M66.963,162.393     c0-27.73,22.56-50.291,50.291-50.291c27.731,0,50.291,22.56,50.291,50.291s-22.56,50.291-50.291,50.291     C89.523,212.684,66.963,190.124,66.963,162.393z"/>
      <path
        d="M127.253,132.103c0-5.523-4.477-10-10-10c-22.216,0-40.291,18.074-40.291,40.291c0,5.523,4.477,10,10,10     c5.523,0,10-4.477,10-10c0-11.188,9.102-20.291,20.291-20.291C122.776,142.103,127.253,137.626,127.253,132.103z"/>
      <path
        d="M384.746,45.14c-64.654,0-117.253,52.6-117.253,117.253c0,26.487,18.563,69.282,55.174,127.194     c26.744,42.305,53.869,77.808,54.14,78.162c1.892,2.471,4.827,3.919,7.939,3.919s6.047-1.449,7.939-3.919     c0.271-0.354,27.396-35.857,54.141-78.162C483.437,231.675,502,188.881,502,162.393C502,97.74,449.4,45.14,384.746,45.14z      M384.746,345.012c-33.093-44.854-97.253-140.508-97.253-182.619c0-53.625,43.628-97.253,97.253-97.253     c53.626,0,97.254,43.628,97.254,97.253C482,204.485,417.836,300.154,384.746,345.012z"/>
      <path
        d="M384.746,92.103c-38.758,0-70.29,31.532-70.29,70.291c0,38.759,31.532,70.291,70.29,70.291s70.291-31.532,70.291-70.291     C455.037,123.635,423.504,92.103,384.746,92.103z M384.746,212.684c-27.73,0-50.29-22.56-50.29-50.291s22.56-50.291,50.29-50.291     c27.73,0,50.291,22.56,50.291,50.291S412.477,212.684,384.746,212.684z"/>
      <path
        d="M384.15,388.86c-18.748,0-34,15.252-34,34s15.252,34,34,34s34-15.252,34-34S402.897,388.86,384.15,388.86z      M384.15,436.86c-7.72,0-14-6.28-14-14c0-7.72,6.28-14,14-14c7.72,0,14,6.28,14,14C398.15,430.58,391.87,436.86,384.15,436.86z"/>
      <path
        d="M323.15,412.86h-27c-5.523,0-10,4.477-10,10s4.477,10,10,10h27c5.523,0,10-4.477,10-10S328.673,412.86,323.15,412.86z"/>
      <path
        d="M261.15,412.86H149.647c-4.281-13.882-17.229-24-32.497-24c-18.748,0-34,15.252-34,34s15.252,34,34,34     c15.268,0,28.217-10.118,32.497-24H261.15c5.523,0,10-4.477,10-10S266.673,412.86,261.15,412.86z M117.15,436.86     c-7.72,0-14-6.28-14-14c0-7.72,6.28-14,14-14c7.72,0,14,6.28,14,14C131.15,430.58,124.87,436.86,117.15,436.86z"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgDistanceIcon"
}
</script>

<style scoped>

</style>
