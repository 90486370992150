<template>
  <section class="panel global-settings-panel" >
    <template v-if="current_resources && current_resources[290] && current_resources[290].enabled ===true">
    <header class="panel-heading vehicle-head  task-head" :class="search_hide ? 'scroll-head-hide': ''">

      <h2 class="panel-title">

        <span class="vehicle-number" ><span class="svg-box-set"><svg-usermanagment-icon/></span><translate-component :text="lang.user_component.title2"/></span>
        <button type="button"  class="mobile-search-scroll" @click="HideSearch()">
          <span class="search-scroll-icon" v-if="!search_hide">
           <search-hide-icon/>
          </span>
          <span class="search-scroll-icon" v-if="search_hide">
            <search-sho-icon/>
          </span>
        </button>
      </h2>

      <div class="form-group search-group search-status-group">
        <label><translate-component :text="lang.task_component.search"/></label>
        <div class="input-group">
          <input type="text" class="form-control" v-model="params.search"/>
          <div class="input-group-btn">
            <button class="btn btn-default" type="button" @click="getSearchTable()">
              <i class="glyphicon glyphicon-search"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="form-group search-group">
        <label><translate-component :text="lang.user_component.role"/></label>
        <select class="form-control" v-model="params.role" @change="reloadTable()" >
          <option value="" >{{lang.reports_settings_component.all}}</option>
          <option :value="type" v-for="(key,type,index) in role_list" >{{key}}</option>
        </select>

      </div>
      <div class="form-group search-group search-status-group special-search-group" v-if="custom_role == '-1'">
        <label><translate-component :text="lang.user_component.customer"/></label>
        <div class="input-group special-search-input" >
          <input  class="form-control" type="text" v-on:keyup="getClientsList()" style="width: 200px" v-model="client_search">
        <model-list-select
            class="form-control"
            :list="customer_list"
            option-value="id"
            option-text="clientName"
            v-model="params.client"
            @select="onSelect"

            :placeholder="lang.user_component.choose_customer"
        ></model-list-select>
          <div class="input-group-btn" >
            <button class="btn btn-default" type="button" @click="getSearchTable()">
              <i class="glyphicon glyphicon-user"></i>
            </button>
            <button class="btn btn-default" type="button" @click="getSearchClear()">
              <i class="glyphicon glyphicon-remove-sign"></i>
            </button>
          </div>
        </div>
      </div>

    </header>

    <div v-if="!redy_loading" class="global-settings-table">
      <perfect-scrollbar  :class="search_hide ? 'scroll-head-hide': ''">
        <table class="table table-striped table-hover mb-none table-event" v-if="user_list.length > 0">
          <thead class="event-table-header">
          <tr>
            <th >Nr</th>
            <th>{{lang.user_component.id_number}}</th>
            <th>{{lang.task_component.Username}}</th>
            <th>{{lang.main_component.Comment}}</th>
            <th>{{lang.user_component.role}}</th>
            <th>{{lang.user_component.customer}}</th>
            <th v-if="custom_role == '-1'">{{lang.user_component.branch}}</th>
            <th>{{lang.task_component.actions}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(user,index) in user_list">
            <tr class="event-table-list" :key="user.id" >
              <td class="event-table-name" style="min-width: 40px">{{index+1+page_number_row}}</td>
              <td class="event-table-name"><b>{{user.id}}</b></td>
              <td >{{user.name}}</td>
              <td ><div class="comment-row">{{user.comment}}</div></td>
              <td >{{role_list[user.roleId]}}</td>
              <td >{{user.clientName == 'TomWare-Latvia' ? 'Demo customer' : user.clientName }}</td>
              <td v-if="custom_role == '-1'">{{user.branchName }}</td>
              <td class="btn-row">  <button  v-if="custom_role == '-1' || custom_role == '0'" type="button" class="btn-row-box btn btn-xs btn-sm btn-warning" @click="getUpdateUser(user.id)">{{lang.events_component.edit}}</button>
                <button  v-if="custom_role == '-1' || custom_role == '0'" type="button" class="btn-row-box btn btn-xs btn-sm btn-danger" @click="getDeleteUser(user.id)">{{lang.task_component.delete}}</button></td>
            </tr>
          </template>
          </tbody>
        </table>
      </perfect-scrollbar>
      <div class="panel-footer" >

        <div class="btn-group-scroll big-group-scroll ">
          <div class="btn  button-scroll button-scroll-total" @click="createEvent()" v-if="custom_role == '-1' || custom_role == '0'"><span class="fa fa-edit"></span><translate-component :text="lang.events_component.create"/>

          </div>
          <button type="button" class="btn  button-scroll " @click="ScrollList(-1)">
            <span class="button-scroll-icon button-scroll-icon-left fa fa-chevron-left"></span>
          </button>
          <div class="btn  button-scroll button-scroll-center hidelog">
            <translate-component :text="lang.geo_zone_component.page"/>
            <span class="page-count"> <input type="number" class="form-control"
                                             @blur="handlerScrollInput($event)"
                                             v-on:keyup.enter="handlerScrollInput($event)"
                                             :value="params.page" > </span></div>
          <button type="button" class="btn  button-scroll button-scroll-right" @click="ScrollList(1)"><span
              class="button-scroll-icon button-scroll-icon-right fa fa-chevron-right"></span></button>
          <div class="btn  button-scroll button-scroll-total" @click="ScrollList('max')"><translate-component :text="lang.events_component.total_page"/>
            <span style="padding-left: 5px">{{ this.params.totalpages }}</span></div>

          <div class="btn  button-scroll button-scroll-center button-scroll-total" >
            <translate-component :text="lang.task_component.limit"/>
            <span class="page-count"> <select class="form-control" v-model="params.limit" @change="controlRow()" >
                <option>10</option>
              <option>25</option>
                <option>50</option>
                <option>100</option>
                <option>200</option>
            </select></span></div>

        </div>



      </div>
    </div>
    <div v-else>
      <div class="loading-box" >
        <scale-loader :height="loading_size"></scale-loader>
      </div>
    </div>

    <div  class="modal fade tasks-modal" role="dialog" style="display: block;opacity: 1" v-if="create_modal">
      <div class="modal-dialog tasks-modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close"  @click="closeCreateModal()">&times;</button>
            <h4 class="modal-title"> <template v-if="current_type === 1">{{lang.user_component.title_create}}</template><template v-if="current_type === 2">{{lang.user_component.title_update}}</template></h4>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group" >
                <label><translate-component :text="lang.task_component.Username"/> <span class="require-field"> *</span></label>
               <input type="text" class="form-control"  autocomplete="off" v-model="user_create.username">
              </div>
              <div class="form-group" >
                <label><translate-component :text="lang.user_component.password"/> <span class="require-field"> *</span></label>
                <input type="password" class="form-control" autocomplete="off" v-model="user_create.password">
              </div>
              <div class="form-group">
                <label><translate-component :text="lang.user_component.confirm_password"/> <span class="require-field"> *</span></label>
                <input type="password"  class="form-control"  autocomplete="off" v-model="user_create.confirm_password">
              </div>
              <div class="form-group">
                <label><translate-component :text="lang.user_component.email"/> <span class="require-field"> *</span></label>
                <input type="email"  class="form-control" autocomplete="off" v-model="user_create.email">
              </div>
              <div class="form-group">
                <label><translate-component :text="lang.user_component.role"/> <span class="require-field"> *</span></label>
                <select  class="form-control" v-model="user_create.roleId">
                  <option value="-2">{{lang.user_component.valid_role}}</option>
                  <option :value="type" v-for="(key,type,index) in role_arr" >{{key}}</option>
                </select>
              </div>
                <div>
                <div class="col-lg-6 col-md-6 col-sm-12">
              <div class="form-group">
                <label style="margin-bottom: 18px;"><translate-component :text="lang.user_component.add_object"/> </label>
                <select  class="form-control" multiple size="11" v-model="actions_objects">
                  <option :value="ob.id" v-for="(ob,index) in add_objects" >{{ob.client == 'TomWare-Latvia' ? 'Demo customer' : ob.client}}: {{ob.name}}</option>
                </select>
                <div class="btn-row-box2">
                  <button type="button" class="btn btn-success btn-xs"  @click="DeleteSelectedObjects()" >{{lang.user_component.delete_selected}}</button>
                  <button type="button" class="btn btn-success btn-xs" @click="DeleteAllObjects()" >{{lang.user_component.delete_all}}</button>

                </div>
              </div>
                </div>
                  <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label ><translate-component :text="lang.user_component.add_object_customer"/> </label>
                  <select  class="form-control" v-model="user_create.clientId" @change="ClientChangeHandler()">
                    <option value="0">{{lang.user_component.valid_client}}</option>
                    <option :value="cl.id" v-for="(cl,index) in client_list" >{{cl.name == 'TomWare-Latvia' ? 'Demo customer' : cl.name}}</option>
                  </select>

                  <select  class="form-control" multiple size="10" v-model="selecteted_objects">

                    <option :value="ob.id" v-for="(ob,index) in client_objects" >{{ob.client  == 'TomWare-Latvia' ? 'Demo customer' : ob.client}}: {{ob.name}}</option>
                  </select>

                  <div class="btn-row-box2">
                    <button type="button" class="btn btn-success btn-xs"  @click="AddSelectedObjects()" >{{lang.user_component.add_selected}}</button>
                    <button type="button" class="btn btn-success btn-xs" @click="AddAllObjects()">{{lang.user_component.add_all}}</button>

                  </div>
                </div>
                </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="form-group">
                  <label ><translate-component :text="lang.user_component.user_resources"/></label>
                  <div class="checkbox-list-resources">
                  <template v-for="(ob,index) in resources_list">
                    <div class="checkbox" v-if="ob.id != 800">
                      <label><input type="checkbox" :value="ob.id" v-model="ob.selected" @change="handleResourcesChek($event)"> <span v-html="ob.name"></span></label>
                    </div>

                  </template>
                  </div>
                  <div class="btn-row-box2">
                    <button type="button" class="btn btn-success btn-xs"  @click="SelectedAllResources()" >{{lang.user_component.add_all}}</button>
                    <button type="button" class="btn btn-success btn-xs" @click="DeleteAllResources()" >{{lang.user_component.delete_all}}</button>

                  </div>
                </div>
                <div class="form-group special-resources-box" v-if="special_resources">
                  <label>{{lang.user_component.sizzapp_limitations}}</label>
                  <div >
                    <div class="specia-row-box"><span style="padding-right: 20px">{{lang.user_component.vehicles}}: </span><input style="max-width: 200px" type="number"  class="form-control" v-model="user_create.optimisticVehicleLimit" ></div>
                    <div class="specia-row-box"><span style="padding-right: 20px" >{{lang.user_component.points}}: </span><input style="max-width: 200px" type="number" class="form-control"  v-model="user_create.optimisticPointLimit" ></div>
                  </div>

                </div>

                <div class="form-group">
                  <label><translate-component :text="lang.main_component.Comment"/> </label>
                  <textarea  rows="8" class="form-control" v-model="user_create.comment"></textarea>
                </div>
                </div>
              </div>
            </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-info" @click="saveUser()">{{lang.main_component.save}}</button>
            <button type="button" class="btn btn-default" @click="closeCreateModal()">{{lang.main_component.cancel}}</button>
          </div>
          </div>
          </div>

      </div>
    </template>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import ScaleLoader from "vue-spinner/src/ScaleLoader";
import SvgUsermanagmentIcon from "@/components/ui/icons/svgUsermanagmentIcon";
import SearchHideIcon from "@/components/ui/icons/search/SearchHideIcon";
import SearchShoIcon from "@/components/ui/icons/search/SearchShoIcon";
import axios from "axios";
import managerHelper from "@/mixins/managerHelper";
import { ModelListSelect } from 'vue-search-select'


export default {
  name: "ManagmentComponent",
  components: {SearchShoIcon, SearchHideIcon, SvgUsermanagmentIcon, TranslateComponent, ScaleLoader,ModelListSelect},
  mixins:[managerHelper],
  computed: {
    ...mapGetters({
      lang: 'LANG',
      objects: 'OBJECTS',
      current_resources:'CURRENT_RESOURCES',
    }),
  },
  data() {
    return {
      page_number_row:0,
      special_resources:false,
      add_objects:[],
      actions_objects:[],
      selected_objects_count:1,
      selecteted_objects:[],
      role_arr:{},
      current_type:1,
      create_modal:false,
      customer_list:[],
      search_hide:false,
      loading_size: "30px",
      redy_loading:false,
      show_icon:true,
      redy_icons:false,
      user_list:[],
      role_list:{},
      client_objects:[],
      client_objects_count:1,
      resources_list:[],
      client_list:[],
      client_search: '',
      custom_role: 1,
      params:{
        _search:false,
        limit:25,
        order:'name',
        direction:'asc',
        nd: Date.now(),
        totalpages:1,
        page:1,
        filterStatus: -1,
        filterType:-1,
        search:'',
        role:'',
        client:'',
      },
      user_create:{
        username: '',
        password:'',
        confirm_password: '',
        email:'',
        branchId: null,
        roleId: -2,
        comment:'',
        clientId:0,
        objects:[],
        resources:[],
        loginId: null,
        optimisticVehicleLimit:10,
        optimisticPointLimit:150,
      }
    }
  },
  mounted() {
    this.role_list ={
      '-1': this.lang.user_component.role_administrator,
      '0': this.lang.user_component.role_power_user,
      '1': this.lang.user_component.role_user,
      '2': this.lang.user_component.role_disabled_user,
    };
    this.getMyRole()
    this.getAllTasks()
  },
  methods: {
    validationsRules(){
      let chek_valid = 0;
      let check_message ='';
      if (this.user_create.username.length < 1) {
        check_message = check_message+'\n '+this.lang.user_component.valid_username+','
        chek_valid++
      }

      if(this.current_type === 2 ){
        if(this.user_create.password.length >1 && this.user_create.password != this.user_create.confirm_password){
          check_message = check_message+'\n '+this.lang.user_component.valid_confirm_password+','
          chek_valid++
        }
      }else{
        if (this.user_create.password.length < 1) {
          check_message = check_message+'\n '+this.lang.user_component.valid_password+','
          chek_valid++
        }

        if(this.user_create.password != this.user_create.confirm_password){
          check_message = check_message+'\n '+this.lang.user_component.valid_confirm_password+','
          chek_valid++
        }
      }



      let filter = /(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[*[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+]*/;
      if (!filter.test(this.user_create.email) ) {
        check_message = check_message+'\n '+this.lang.user_component.valid_email+','
        chek_valid++
      }
      if(this.user_create.roleId == null || this.user_create.roleId == -2 ){
        check_message = check_message+'\n '+this.lang.user_component.valid_role+','
        chek_valid++
      }
      if(this.custom_role > -1 && (this.user_create.clientId == null || this.user_create.clientId == 0) ){
        check_message = check_message+'\n '+this.lang.user_component.valid_client+','
        chek_valid++
      }

      if(chek_valid > 0){
        this.$iosAlert(check_message)
      }
      return  chek_valid
    },
    saveUser(){
      if(this.validationsRules() === 0){
        this.saveData();
      }
    },
    handleResourcesChek(event){

      if(event.target.value == 750  ) {
        if (event.target.checked === true) {
            this.special_resources = true
          }else{
          this.special_resources = false
        }

      }
    },
    saveData(){
      let _this =this;
      let formData = new FormData()
      formData.append('username',_this.user_create.username);
      if(this.current_type === 1) {

        formData.append('password', _this.user_create.password);


      }
      if(this.current_type === 2){
        if(_this.user_create.password.length > 1){
          formData.append('password',_this.user_create.password);
        }else{
          formData.append('password','');
        }


      }
      formData.append('email',_this.user_create.email);
      formData.append('roleId',_this.user_create.roleId);
      formData.append('clientId',_this.user_create.clientId);
      formData.append('comment',_this.user_create.comment);
      if(this.current_type === 2){

        formData.append('loginId',_this.user_create.loginId);

      }

       // formData.append('optimisticVehicleLimit',_this.user_create.optimisticVehicleLimit);
      //  formData.append('optimisticPointLimit',_this.user_create.optimisticPointLimit);

      let objects = []
      let resources = []
      let check_resource = false;
      for (let i = 0; i < this.resources_list.length; i++) {
        if(this.resources_list[i].selected === true){
         let id =  this.resources_list[i].id
          if(id == 800 ){
            check_resource = true;
          }
          resources.push(id)
          formData.append("resources[]",id);
        }
      }
      if( check_resource === false){
        formData.append("resources[]",800);
      }
      for (let k = 0; k < this.add_objects.length; k++) {
        let ob_id =  this.add_objects[k].id
        objects.push(ob_id)
          formData.append("objects[]",ob_id);

      }

      axios.post(base_url + '/usermanager/saveuser',formData).then(response => {

        if (response.data.success === true) {
          if(_this.current_type === 1){
            _this.$iosAlert(_this.lang.user_component.create_user);
          }else{
            _this.$iosAlert(_this.lang.user_component.updated_user);
          }
          _this.closeCreateModal()
          _this.getAllTasks()

          }else{
          _this.$iosAlert(response.data.error);
        }


      }).catch(error => {
        console.log(error)
      })
    },
    SelectedAllResources(){

      let resources = [...this.resources_list]

      resources.forEach(v => {v.selected =  true})
      this.resources_list = resources

    },
    DeleteAllResources(){
      let resources = [...this.resources_list]

      resources.forEach(v => {
        if(v.id != 800){
          v.selected =  false
        }

      })
      this.resources_list = resources
    },
    DeleteAllObjects(){
      this.add_objects =[]
    },
    DeleteSelectedObjects(){

      let add_obj = [...this.add_objects]
      for (let i = 0; i < this.actions_objects.length; i++) {
        let id = this.actions_objects[i]
        let itemIndex =this.add_objects.findIndex(x => x.id === id);
        if(itemIndex != null || itemIndex != -1  ){
          add_obj.splice(itemIndex, 1)
        }
      }

      this.add_objects = add_obj

    },
    AddSelectedObjects(){

      let add_obj = [...this.add_objects]
      for (let i = 0; i < this.selecteted_objects.length; i++) {
        let id = this.selecteted_objects[i]
        let check =this.add_objects.findIndex(x => x.id === id);
        if(check == null || check == -1 ){
          let item =this.client_objects.find(x => x.id === id);
          add_obj.push(item)
        }
      }
      this.add_objects = add_obj

    },
    AddAllObjects(){

      let add_obj = [...this.add_objects]

      for (let i = 0; i < this.client_objects.length; i++) {
        let id = this.client_objects[i].id
        let check =this.add_objects.findIndex(x => x.id === id);
        if(check == null || check == -1 ){
          add_obj.push(this.client_objects[i])
        }
      }
      this.add_objects = add_obj

    },
    ClientChangeHandler(){

      if(this.user_create.clientId != 0 && this.user_create.clientId != null) {
        this.getClientObjects()
      }
    },
    getClientObjects(){
        let _this = this;
      _this.client_objects =[]
        let formData = new FormData()
        formData.append("id", _this.user_create.clientId);
        axios.post(base_url + '/usermanager/getclientobjects', formData).then(response => {

          if (response.data.success === true) {
            _this.client_objects = response.data.data
            _this.client_objects_count = response.data.count
          } else {
          }
        }).catch(error => {
          console.log(error)
        })

    },
    createEvent(){
      this.current_type = 1;
      this.create_modal = true;
    },
    closeCreateModal(){
      this.current_type = 1
      this.create_modal = false;
      this.user_create = {
            username: '',
            password:'',
            confirm_password: '',
            email:'',
            branchId: null,
            roleId: -2,
            comment:'',
            clientId:0,
            objects:[],
            resources:[],
            loginId: null,
            optimisticVehicleLimit:10,
            optimisticPointLimit:150,
      }
      this.special_resources = false
          this.actions_objects=[]
          this.selecteted_objects=[]

      this.DeleteAllResources()
      this.DeleteAllObjects()
    },
    onSelect(items){

    },
    getUpdateUser(user_id){
      let _this =this;
      let formData = new FormData()
      formData.append("id", user_id);
      axios.post(base_url + '/usermanager/get', formData).then(response => {

        if(response.data.success === true){
          let user  = response.data.data
          _this.current_type = 2
          _this.user_create = {
            username: user.name,
            password:'',
            confirm_password: '',
            email:user.email,
            branchId: user.branchId,
            roleId: user.roleId,
            comment:user.comment,
            clientId:user.clientId ,
            objects:[],
            resources:[],
            loginId: user_id,
            optimisticVehicleLimit:user.optimisticVehicleLimit ? user.optimisticVehicleLimit : 10,
            optimisticPointLimit:user.optimisticPointLimit ? user.optimisticPointLimit : 150,
          }

          _this.add_objects = user.objects
         let resources = user.resources

          for (let i = 0; i < _this.resources_list.length; i++) {
            let id = _this.resources_list[i].id
            let item =resources.find(x => x.id === id);
            if( item != null){
              if(item.enabled === true){
                _this.resources_list[i].selected = true

              }
              if(item.id == 800){
                _this.resources_list[i].selected = true
              }
            }

          }



          _this.create_modal = true;

        }else{
          _this.$iosAlert(response.data.error);
        }

      }).catch(error => {
        console.log(error)
      })
    },
    getDeleteUser(user_id){
      let _this =this;
      let message = this.lang.user_component.confirm_delete
      this.$iosConfirm({
        text: message
      }).then(function () {

      let formData = new FormData()
      formData.append("loginId",  user_id);

      axios.post(base_url + '/usermanager/delete',formData).then(response => {

        let res = response.data
        if (res.success === true) {

          _this.$iosAlert(_this.lang.user_component.delete_user)
          _this.getAllTasks()

        }else{
          _this.$iosAlert(res.error);
        }
      }).catch(error => {
        console.log(error)
      })
      })
    },
    getSearchClear(){
      this.params.client = ''
      this.getAllTasks();
    },
    getClientsCreateList(){
      let _this =this;
      let formData = new FormData()
      axios.post(base_url + '/usermanager/getcreatestuff', formData).then(response => {

        if (response.data.success === true) {
          _this.client_list = response.data.clients
         let resources = response.data.resources

          resources.forEach(v => {
            v.selected =  false
          if(v.id == 800){
            v.selected =  true
          }
          })
          _this.resources_list = resources

        }else{

        }
      }).catch(error => {
        console.log(error)
      })
    },
    getMyRole(){
      let _this =this;
      axios.post(base_url + '/problem/clienthistory').then(response => {

        if (response.data.success === true) {
            _this.custom_role = response.data.role


            if(_this.custom_role == '-1'){
              _this.role_arr = _this.role_list;
            }

          if(_this.custom_role == '0'){
            _this.role_arr= {
              '0': _this.lang.user_component.role_power_user,
              '1': _this.lang.user_component.role_user,
              '2': _this.lang.user_component.role_disabled_user,
            }
          }
          if(_this.custom_role == '0' || _this.custom_role == '-1'){
            _this.getClientsCreateList()
          }

        }else{

        }
      }).catch(error => {
        console.log(error)
      })

    },
    getClientsList(){

      let _this =this;
      if(_this.client_search.length > 1) {
        let formData = new FormData()
        formData.append("limit", 15);
        formData.append("order", 'clientName');
        formData.append("direction", 'asc');
        formData.append("search", _this.client_search);
        axios.post(base_url + '/usermanager/clientlist', formData).then(response => {

          _this.customer_list = [];

          if(response.data && response.data.data){
            _this.customer_list =response.data.data;
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    getAllTasks(){
      let _this =this;
      _this.redy_loading = true;

      let formData = new FormData()
      formData.append("limit",  _this.params.limit);
      formData.append("page",  _this.params.page);
      formData.append("_search",  _this.params._search);
      formData.append("order",  _this.params.order);
      formData.append("direction",  _this.params.direction);
      formData.append("nd",  _this.params.nd);
      formData.append("role",  _this.params.role);
      formData.append("client",  _this.params.client);
      if(this.params.search.length > 1) {
        formData.append("search", _this.params.search);
      }
      axios.post(base_url + '/usermanager/list', formData).then(response => {
        _this.user_list = [];
        if(response.data && response.data.data){
          _this.user_list =  response.data.data;

          _this.params.totalpages  =  Math.ceil(response.data.total/_this.params.limit);

          _this.page_number_row = (_this.params.page-1)*_this.params.limit;
        }
        if(_this.params.page == 1){
          _this.page_number_row =0;
        }
        _this.redy_loading = false;
      }).catch(error => {
        console.log(error)
      })


    },
  },
}
</script>

<style scoped>
.input-group.special-search-input {
  display: flex;
  flex-wrap: nowrap;
}

.btn-row-box2{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

}
.btn-row-box2 .btn{
  margin-right: 20px;
}
.chexbox-row{
  display: flex;
  margin-top: 20px;
}
.checkbox-list-resources{
  max-height: 250px;
  overflow-y: auto;
}
.comment-row{
  max-width: 300px;
}
.ps {
  max-height: calc(100vh - 160px);
}






.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 4px;

}


.event-btn {
  margin-right: 5px;
}
.panel-footer {
  border-radius: 0 0 5px 5px;

}
.modal.tasks-modal{
  background: rgba(26,35,126,0.2);
}


.modal-dialog.tasks-modal-dialog .modal-content {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.require-field{
  font-weight: bold;
  color: #ff3d00;
}



/*searcg_group*/




.modal-dialog {
  width: 80%;

}
.modal-dialog.tasks-modal-dialog{
  margin-top: 14%;
}
.modal-body {
  position: relative;
  padding: 15px;
  max-height: 78vh;
  overflow: auto;
}
@media only screen and (max-width: 1600px){
  .panel.global-settings-panel{
    width: calc(100vw - 50px );
    height: calc(100vh - 42px );

  }
  .vehicle-head.task-head{
    flex-wrap: wrap;
  }
  .panel-title{
    width: 100%;
    padding-bottom: 10px;
  }
  .ps {
    max-height: calc(100vh - 200px);
  }
  .modal-dialog.tasks-modal-dialog{
    margin-top: 14%;
  }
}
@media only screen and (max-width: 1200px){
  .modal-body {

    max-height: 66vh;

  }
  .ps {
    max-height: calc(100vh - 240px);
  }
  .modal-dialog {
    width: 90%;

  }
  .modal-dialog.tasks-modal-dialog{
    margin-top: 18%;
  }
}
@media only screen and (max-width: 767px){
  .modal-dialog.tasks-modal-dialog {
    margin-top: 35%;
  }
  .panel.global-settings-panel{
    width: 100%;
    height: calc(100vh - 42px );
    top: 60px;
    position: fixed;
  }
  .ps {
    height:  calc(100vh - 260px );
    max-height:  calc(100vh - 260px );
  }
  .global-settings-table {
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 600px){
  .panel-title{
    padding-bottom: 18px;
  }
  .special-search-group{
  flex-wrap: wrap;
  }
  .input-group.special-search-input {
   flex-wrap: wrap;
  }
  .input-group.special-search-input input{
    width: 80px !important;
  }
  .input-groupspecial-search-input .form-control {
    width: 100px !important;
  }
  .special-search-input .input-group-btn {
    width: 100%;
  }
  .special-search-input .input-group-btn button{
    width: 60px;
    margin-right: 20px;
    border-radius: 0;
    margin-top: 10px;

  }
  .seainput-group-btnrch-group{
    margin: 0 5px;
  }
  .search-group.search-status-group label {
    width: 100px;
  }
  .search-group label {
    width: 100px;
    text-align: start;
  }
  .input-group .form-control{
    width: 124px !important;
  }
  .mobile-search-scroll{
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    box-shadow: none;
    border-color: #1c71da;
    border-radius: 50%;
  }
  .search-scroll-icon{
    color: #1c71da;
  }
  .search-scroll-icon svg{
    fill: #1c71da;
  }
  .vehicle-head.task-head {
    flex-wrap: wrap;
    height: 50px;
    overflow-y:  auto;
    transition: height 0.6s linear;
  }
  .vehicle-head.task-head.scroll-head-hide{
    height:auto;
  }

  .ps {
    height:  calc(100vh - 220px );
    max-height:  calc(100vh - 220px );
  }
  .ps.scroll-head-hide {
    height:  calc(100vh - 404px );
    max-height:  calc(100vh - 404px );
  }
  .panel-footer {
    padding: 10px 0;
    margin-top: 0;
  }
  .button-scroll.button-scroll-center.hidelog{
    display: none;
  }
  .button-scroll-center.button-scroll-total span:nth-child(1){
    display: none;
  }
  tr.event-table-list{
    position: relative;
  }
  tr.event-table-list td{
    padding-bottom: 30px !important;
  }
  tr.event-table-list td.btn-row{
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
    padding-bottom: 4px !important;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  .modal-dialog.tasks-modal-dialog {
    margin-top: 55%;
  }
  .modal-header {
    padding: 10px;
  }
  .modal-footer {
    padding: 10px;
  }
  .modal-body {
    position: relative;
    padding: 10px;
    padding-right: 20px;
    max-height: 70vh;
    overflow-y: auto;
  }
  .search-group{
    margin-bottom: 10px !important;
  }

}

.special-resources-box{
  border: 1px solid #ff4444;
  border-radius: 5px;
  padding: 10px;
  padding-bottom: 0;
}
.special-resources-box label{
  font-weight: bold;
  color: #ff4444;
}
.specia-row-box{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}
</style>