
<script>
import { Line } from "vue-chartjs";
export default {
  extends: Line,
  name: "AreaChartComponent",
  props: {
    headers_units: {
      type: Array
    },
    chart_max: {
      type: Array
    },
    chart_min: {
      type: Array
    },
    chartdata: {
      type: Array
    },
    add_labels: {
      type: Array
    },
    label_name: {
      type: Array
    }
    ,
    backgroundColor: {
      type: String,
    },
    pointBackgroundColor: {
      type: Array
    },
    pointBorderColor:{
      type: String
    },
    title: {
      type: String
    },
    chart_type_num:{
      type:Number
    }
  },
  data() {
    return {
      gradient: null,
      gradient2: null,
      alpha: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      dataset: [],
      yAxes: [],
    };
  },
  mounted() {

    let $this = this;
    $this.dataset =[];
    $this.yAxes =[]
    let k =0;
    if(this.chart_type_num ===1) {
      $this.dataset.push(
        {

          label: $this.label_name[1],
          borderColor: this.pointBorderColor[1].hex,
          borderWidth: 1,
          pointBackgroundColor: this.pointBorderColor[1].hex,
          pointBorderColor: this.pointBorderColor,
          backgroundColor: this.gradient2,
          data: this.chartdata[1],
          pointRadius: 0,
        }
      )

    }else {

      this.add_labels.map(item => {

        if (k > 1) {
          if(Array.isArray($this.chartdata[k]) && $this.chartdata[k].length>0) {
            $this.dataset.push(
              {
                label: $this.label_name[k],
                borderColor: this.pointBorderColor[1].hex,
                borderWidth: 1,
                pointBackgroundColor: this.pointBorderColor[1].hex,
                intBorderColor: this.pointBorderColor,
                backgroundColor: this.gradient1,
                pointRadius: 0,
                data: $this.chartdata[k],
              }
            )
          }
        }
        k++;
      })

    }


    this.gradient = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);
    this.gradient2 = this.$refs.canvas
      .getContext("2d")
      .createLinearGradient(0, 0, 0, 450);

    this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
    this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
    this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

    this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
    this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
    this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");

    this.renderChart(
      {
        labels: this.add_labels,
        datasets:  this.dataset
      },
      { responsive: true,
        maintainAspectRatio: false ,
        title: {
          display: true,
          text: this.title
        },
        scales: {
          xAxes: [{
            ticks: {
              maxTicksLimit: 10,
              maxRotation: 0,
              minRotation: 0
            },
            maxBarThickness: 100,
            borderWidth: 0,
          }]
        },

      }
    );
  }
};
</script>
