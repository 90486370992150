<script>
import {Bar, mixins } from "vue-chartjs";
import "chartjs-plugin-annotation";
import zoomIcon from "@/mixins/zoomIcon";
const { reactiveProp } = mixins;
import moment from 'moment'

export default {
  extends:  Bar,
  props: {
    chartData: {
      type:Object
    },
    title: {
      type: String
    },
    event_data: {
      type: Array,
    },
    green_data:{
      type: Array,
    },
    red_data:{
      type: Array,
    },
    status:{
      type: Array,
    },
    yMax:{
      type: Number
    },
    fuel_type:{
      type:String,
      default:'fuel_2'
    }
  },
  mixins: [zoomIcon,reactiveProp],
  data() {
    return {
      ticksLimit:10,
      screen_size:1,
      plotBands:[],
      dataset: [],
      check_attempt :0,
      change_data:{
        start:'',
        end:''
      },
    }
  },
  mounted() {
    let width_main = window.innerWidth
    if(width_main <=600){
      this.screen_size = 2
      this.ticksLimit =2
    }
    let $this = this;
    let add_plotBands = this.status

    this.renderChart(
      this.chartData, {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        scales: {
          yAxes: [{
            position: 'right',
            ticks: {
              max:this.yMax,
              beginAtZero: true,
              callback: function(value, index, values) {
                return value +' L';
              }
            }
          }

          ],
          xAxes: [{
            ticks: {
              maxTicksLimit: $this.ticksLimit,
              maxRotation: 0,
              minRotation: 0,
              callback: function(value, index, values) {
                let dateForm  = ($this.screen_size === 2) ? '' : moment(value).utc().format("HH:mm");
                return [moment(value).format("DD/MM/YYYY"), dateForm];
              }
            },

          }],
        },
        tooltips: {
          callbacks: {
            enabled: true,
            title: ((tooltipItems, data) => {
                return moment(data['labels'][tooltipItems[0]['index']]).utc().format('DD/MM/YYYY HH:mm')
            }),
            label: ((tooltipItems, data) => {
              return 'Fuel level: '+tooltipItems.yLabel+ ' L'
            })
          }
        },
        annotation: {
         drawTime: "beforeDatasetsDraw",
          annotations: add_plotBands
        },
        onClick: function (c, i) {
          let e = i[0];
          if (e) {
            $this.SetMarkerIndex(e._index)
            if (window.event.ctrlKey) {
              e._chart.config.data.datasets[0].backgroundColor = "rgb(255,0,0)"
              if ($this.check_attempt > 0) {
                $this.change_data.end = $this.event_data[e._index][0]
                if ($this.fuel_type === 'fuel_old') {
                  $this.change_data.end = $this.event_data[e._index]['timestamp']
                }
                $this.SetChartIndex()

              } else {
                $this.change_data.start = $this.event_data[e._index][0]
                if ($this.fuel_type === 'fuel_old') {
                  $this.change_data.start = $this.event_data[e._index]['timestamp']
                }
                $this.check_attempt++
              }
              this.render(0, true)
            }
          }


        },

      })

    this.$emit('chart:rendered')
  },
  methods: {
    SetMarkerIndex(add_index) {

      let marker = this.event_data[add_index]
      if(this.fuel_type === 'fuel_old') {
        let change = marker
           marker =[change['timestamp'],change['a'],change['fuelLevel'],change['lat'],change['lng']]
      }
      let $this =this
      this.$store.commit('SET_FUEL_MARKER', marker);
      $this.$store.commit('SET_MAP_ZOOM', 15);
      setTimeout(()=>{

        $this.$store.commit('SET_START_CENTER', [marker[3],marker[4]]);
      }, 100)
    },
    SetChartIndex(){
      this.$emit('update-cart', this.change_data)
      this.check_attempt = 0;
    }
  },

};
</script>
