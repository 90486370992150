<script>
import {Radar} from "vue-chartjs";

export default {
  extends: Radar,
  props: {
    headers_units: {
      type: Array
    },
    chart_max: {
      type: Array
    },
    chart_min: {
      type: Array
    },
    chartdata: {
      type: Array
    },
    add_labels: {
      type: Array
    },
    label_name: {
      type: Array
    }
    ,
    pointBackgroundColor: {
      type: String,
    },
    borderColor: {
      type: Array
    },
    pointBorderColor: {
      type: String
    },
    pointHoverBackgroundColor: {
      type: String
    },
    pointHoverBorderColor: {
      type: String
    },

    title: {
      type: String
    },
    chart_type_num: {
      type: Number
    }
  },
  data() {
    return {
      alpha: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      dataset: [],
      yAxes: [],
    };
  },
  mounted() {

    let $this = this;
    this.dataset = [];
    $this.yAxes =[]
    let k = 0;
    this.$nextTick(() => {
      if (this.chart_type_num === 1) {
        this.dataset.push(
          {

            label: $this.label_name[1],
            backgroundColor: "rgba(255,99,132,0.2)",
            borderColor: "rgba(255,99,132,1)",
            pointBackgroundColor: "rgba(255,99,132,1)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgba(255,99,132,1)",
            data: $this.chartdata[1]
          }
        )

      } else {

        $this.add_labels.map(item => {

          if (k > 1) {
            if (Array.isArray($this.chartdata[k]) && $this.chartdata[k].length > 0) {
              $this.dataset.push(
                {
                  label: $this.label_name[k],
                  backgroundColor: $this.hexToRGB($this.borderColor[k].hex,0.2),
                  borderColor: $this.hexToRGB($this.borderColor[k].hex,1),
                  pointBackgroundColor: $this.hexToRGB($this.borderColor[k].hex,1),
                  pointBorderColor: "#fff",
                  pointHoverBackgroundColor: "#fff",
                  pointHoverBorderColor: $this.hexToRGB($this.borderColor[k].hex,1),
                  data: $this.chartdata[k]
                }
              )
              if($this.chart_max[k] !== "undefined" && $this.chart_max[k] != null) {
                let add = $this.headers_units[k]
                $this.yAxes.push({
                  display: true,
                  id: $this.alpha[k - 2],
                  type: 'linear',
                  position: 'left',
                  ticks: {
                    min: $this.chart_max[k],
                    max: $this.chart_min[k],
                    maxTicksLimit: 10,
                    stepSize: $this.chart_max[k]/10,
                    check_val:$this.headers_units[k],
                    fontColor: $this.borderColor[k].hex,
                    callback: function (value, index, values) {
                      return value +''+ add;
                    }
                  }
                })

              }
            }

          }
          k++;
        })

      }
      if(this.chart_type_num === 1) {
        $this.renderChart(
          {
            labels: $this.add_labels,
            datasets: $this.dataset

          },
          {
            responsive: true, maintainAspectRatio: false,
            title: {
              display: true,
              text: $this.title
            },
            scales: {
              zAxes: [{
                ticks: {
                  maxTicksLimit: 10,
                  maxRotation: 0,
                  minRotation: 0
                },
              }]
            },
          }
        );
      }else{
        $this.renderChart(
          {
            labels: $this.add_labels,
            datasets: $this.dataset

          },
          {
            responsive: true, maintainAspectRatio: false,
            title: {
              display: true,
              text: $this.title
            },
            scales: {
              zAxes: this.yAxes,
            },
          }
        );
      }

    });

  },
  methods: {
    hexToRGB(hex, alpha) {
      var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);
      if (alpha) {
        return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
      } else {
        return "rgb(" + r + ", " + g + ", " + b + ")";
      }
    }
  }
};
</script>
