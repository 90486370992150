<template>
  <div class="routes-flex-box">
  <div class="fuel-calendar-wrapper" v-if="(select_history  &&  settings_active != '' && settings_active != null )|| show_reports">

    <button type="button" class="btn btn-default btn-close-calendar"
            slot="reference"
            @click="closeShowCalendar()"  v-if="add_calendar">

      <i class="fa fa-times"></i>
    </button>

    <button type="button" class="history_object_calendar btn btn-default calendar-button  bottom" :class="add_calendar ?'active':'disactive'"
            slot="reference"
            @click="closeShowCalendar()" v-if="!add_calendar">

                     <span class="far calendar-icon">
              <svg-calendar-time-icon></svg-calendar-time-icon>

          </span>
    </button>


    <template v-if="add_calendar">
      <date-picker
          :select_type="select_type"
          :language="lang_short"
          apply-button-label="use"
          :show-helper-buttons="true"
          :switch-button-initial="true"
          :is-monday-first="true"
          :initial-dates="getInitinalDate() "
          :inline="inline_style"
          :disabledDates="disabledDates"
          :show_short="true"
      />
    </template>

  </div>

  <button type="button" class="btn fuel-chart-show-btn" @click="showSettingsMap()" v-if="select_history && settings_active != '' && !setting_show" ><svg-setting-mono/></button>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import helpers from "@/mixins/helpers";
import SvgSettingMono from "@/components/ui/icons/svgSettingMono";
import DatePicker from "@/components/vue3calendar/calendar/DatePicker";
import calendarStart from "@/mixins/calendarStart";
import SvgCalendarTimeIcon from "@/components/ui/icons/svgCalendarTimeIcon";


export default {
  name: "RoutesComponent",
  mixins: [helpers,calendarStart],
  components: {
    SvgCalendarTimeIcon,
    DatePicker,
    SvgSettingMono,
  },
  data() {
    return {
      width: 0,
      height: 0,
      top: 0,
      left: 0,
      inline_style: true,
      show_short: true,
      disabledDates:{
        from: new Date(Date.now() + 8640000)
      },
    }
  },

  computed: {
    ...mapGetters({
      select_history: 'SELECT_HISTORY',
      settings_active: 'SETTINGS_ACTIVE',
      user_auth:'USER_AUTH',
      start_center: 'START_CENTER',
      map_zoom: 'MAP_ZOOM',
      setting_show:'SETTINGS_SHOW',
      showCalendar: 'SHOW_CALENDAR_PANEL',
      lang_short: 'LANG_SHORT',
      select_type:'SELECT_TYPE',
      show_reports:"SHOW_REPORTS",
      add_calendar:'ADD_CALENDAR',
      history_time: 'HISTORY_TIME',
      active_route: "ACTIVE_ROUTE",
    }),

  },
  mounted() {
    this.$store.commit('SET_SHOW_REPORTS_SETTINGS', false);
      this.$store.commit('SET_ACTIVE_ROUTE', 'routing');
    this.$nextTick(function () {
      this.$store.commit('SET_RELOAD_MAP',   Math.random())
    })
  },
  methods: {
    closeShowCalendar(){
      let add = true
      if(this.add_calendar === true){
        add = false
      }
      this.$store.commit('SET_ADD_CALENDAR', add);

    },
    showSettingsMap(){
      this.$store.commit('SET_SETTINGS_SHOW', true);
    },
    resize(newRect) {
      this.width = newRect.width;
      this.height = newRect.height;
      this.top = newRect.top;
      this.left = newRect.left;
    },
  },
  beforeDestroy: function () {
    this.$store.dispatch('clearHistory');

     /* let arr =['mapCenterZoom','mapCenterLatitudeX','mapCenterLongtitudeY']
      let arr_2 =[this.map_zoom,this.start_center[0],this.start_center[1]]
      let formData = new FormData();
      for (let i = 0; i < arr.length; i++) {
        formData.append("parameters["+i+"][0]", arr[i])
        formData.append("parameters["+i+"][1]", arr_2[i])
      }

      this.$store.dispatch('setUserParametrs', formData);*/

  }
}
</script>

<style scoped>

</style>
