<template>
  <div>
    <canvas ref="canvas" width="100%" height="400"></canvas>
  </div>
</template>
<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    chartdata: {
      type: Array
    },
    add_labels: {
      type: Array
    },
    label_name: {
      type: Array
    },
    title: {
      type: String
    },
    chart_type_num:{
      type:Number
    }
  },
  data(){
    return{
      dataset:[],
    }
  },
  mounted() {
      this.gradient = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);
      this.gradient2 = this.$refs.canvas
        .getContext("2d")
        .createLinearGradient(0, 0, 0, 450);

      this.gradient.addColorStop(0, "rgba(255, 0,0, 0.5)");
      this.gradient.addColorStop(0.5, "rgba(255, 0, 0, 0.25)");
      this.gradient.addColorStop(1, "rgba(255, 0, 0, 0)");

      this.gradient2.addColorStop(0, "rgba(0, 231, 255, 0.9)");
      this.gradient2.addColorStop(0.5, "rgba(0, 231, 255, 0.25)");
      this.gradient2.addColorStop(1, "rgba(0, 231, 255, 0)");

    let $this = this;
    $this.dataset =[]
    let k =0;
    if(this.chart_type_num ===1) {
      $this.dataset.push(
        {
          label: $this.label_name[1],
          data: $this.chartdata[1],
          backgroundColor: [this.gradient, this.gradient2, "#00D8FF"],
        }

      )

    }else {

      this.add_labels.map(item => {

        if (k > 1) {
          $this.dataset.push(
            {
              label: $this.label_name[k],
              data: $this.chartdata[k],
              backgroundColor: [this.gradient, this.gradient2, "#00D8FF"],
            }
          )

        }
        k++;
      })

    }

      this.renderChart(
        {
          labels: this.add_labels,
          datasets:   this.dataset
        },
        { responsive: true, maintainAspectRatio: false,
          title: {
            display: true,
            text: this.title
          },
        }
      );


  }
};
</script>
