<template>
  <svg :fill="monitoring_start ?  ' #1C71DA' : '#777'" style="width: 20px;height: 20px;"
       xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
       viewBox="0 0 315.737 315.737" enable-background="new 0 0 315.737 315.737">
    <g>
      <path
        d="M157.869,0C70.82,0,0,70.819,0,157.868s70.82,157.869,157.869,157.869s157.868-70.82,157.868-157.869S244.918,0,157.869,0z    M157.869,295.737C81.848,295.737,20,233.89,20,157.868C20,81.848,81.848,20,157.869,20c76.02,0,137.868,61.848,137.868,137.868   C295.737,233.89,233.89,295.737,157.869,295.737z"/>
      <path
        d="m266.542,147.868h-14.42c-4.666-44.291-39.962-79.587-84.253-84.253v-14.42c0-5.522-4.478-10-10-10-5.523,0-10,4.478-10,10v14.42c-44.292,4.666-79.588,39.961-84.254,84.253h-14.42c-5.522,0-10,4.478-10,10 0,5.523 4.478,10 10,10h14.42c4.666,44.292 39.962,79.588 84.254,84.254v14.42c0,5.523 4.477,10 10,10 5.522,0 10-4.477 10-10v-14.42c44.291-4.666 79.587-39.962 84.253-84.254h14.42c5.522,0 10-4.477 10-10 0-5.522-4.478-10-10-10zm-34.581,0h-36.368c-3.573-13.514-14.211-24.151-27.724-27.724v-36.368c33.246,4.466 59.627,30.846 64.092,64.092zm-84.092-64.092v36.367c-13.514,3.573-24.152,14.211-27.725,27.725h-36.368c4.466-33.246 30.847-59.626 64.093-64.092zm-64.093,84.092h36.368c3.573,13.514 14.211,24.152 27.725,27.725v36.368c-33.246-4.465-59.628-30.846-64.093-64.093zm84.093,64.093v-36.368c13.514-3.573 24.151-14.211 27.724-27.725h36.368c-4.465,33.247-30.845,59.628-64.092,64.093z"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: "svgTargetIcon",
  props:{monitoring_start:{default:false}}
}
</script>

<style scoped>

</style>
