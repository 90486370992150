<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
       id="Layer_1" x="0px" y="0px" viewBox="0 0 101 128" style="enable-background:new 0 0 101 128;"
       xml:space="preserve">
<g>
	<path
    d="M88.4,74.4l-7.6-19.6c-1.4-3.7-4.5-6.9-10.4-6.9H59.7H41.4H30.6c-5.8,0-8.9,3.2-10.4,6.9l-7.6,19.6   c-3,0.4-8.3,3.9-8.3,10.6v24.9h7.4v8c0,9.8,13.9,9.7,13.9,0v-8h25h0h0h0h25v8c0,9.7,13.9,9.8,13.9,0v-8h7.4V85   C96.7,78.3,91.4,74.7,88.4,74.4z M18.8,94.9c-3.5,0-6.4-3-6.4-6.6c0-3.6,2.9-6.6,6.4-6.6c3.5,0,6.4,3,6.4,6.6   C25.2,91.9,22.4,94.9,18.8,94.9z M50.5,74.2L50.5,74.2L50.5,74.2h-30l5.7-15.4c0.7-2.2,1.8-3.7,4.3-3.8h20h0h0h20   c2.5,0,3.6,1.6,4.3,3.8l5.7,15.4H50.5z M82.2,94.9c-3.5,0-6.4-3-6.4-6.6c0-3.6,2.9-6.6,6.4-6.6c3.5,0,6.4,3,6.4,6.6   C88.6,91.9,85.7,94.9,82.2,94.9z"/>
  <path
    d="M26.5,40.4c7,0,13.2-3.9,16.4-9.6h9.7l6.1-6.1l4.7,4.7l4.5-4.5l4.6,4.7l4.7-4.7l4.7,4.7l7.9-8c1.2-1.2,1.2-2,0-3.2   l-6.5-6.6H43c-3.3-5.8-9.4-9.6-16.5-9.6c-10.5,0-19,8.6-19,19.2C7.6,31.8,16.1,40.4,26.5,40.4z M18.2,16c2.8,0,5,2.3,5,5.1   c0,2.8-2.3,5.1-5,5.1s-5-2.3-5-5.1C13.2,18.3,15.4,16,18.2,16z"/>
</g>
</svg>
</template>

<script>
export default {
  name: "svgStatusInfoIcon"
}
</script>

<style scoped>

</style>
