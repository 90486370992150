<template>
  <section class="panel global-settings-panel" >
    <template v-if="current_resources && current_resources[310] && current_resources[310].enabled ===true">
    <header class="panel-heading vehicle-head  task-head" :class="search_hide ? 'scroll-head-hide': ''">

      <h2 class="panel-title">

        <span class="vehicle-number" ><span class="svg-box-set"><svg-engine-icon/></span><translate-component :text="lang.engine_component.title2"/></span>
        <button type="button"  class="mobile-search-scroll" @click="HideSearch()">
          <span class="search-scroll-icon" v-if="!search_hide">
           <search-hide-icon/>
          </span>
          <span class="search-scroll-icon" v-if="search_hide">
            <search-sho-icon/>
          </span>
        </button>
      </h2>
      <div class="form-group search-group ">

      </div>
      <div class="form-group search-group special-search-group">
        <label><b><translate-component :text="lang.reports_settings_component.DateTimeFrom"/></b></label>
        <div class="input-group">

          <v-date-picker v-model="add_date">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
      </div>

      <div class="form-group search-group special-search-group">
        <label><b><translate-component :text="lang.reports_settings_component.DateTimeTo"/></b></label>
        <div class="input-group">

          <v-date-picker v-model="add_date">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                  class="bg-white border px-2 py-1 rounded"
                  :value="inputValue"
                  v-on="inputEvents"
              />
            </template>
          </v-date-picker>
        </div>
      </div>
      <div class="form-group search-group special-search-group" style="max-width: 100px">
        <button type="button" class="btn btn-primary btn-sm ">{{lang.task_component.search}}</button>
      </div>
    </header>


    <div v-if="!redy_loading" class="global-settings-table">
      <perfect-scrollbar  :class="search_hide ? 'scroll-head-hide': ''">
        <table class="table table-striped table-hover mb-none table-event" v-if="engine_logs &&  custom_role== -1">
          <thead class="event-table-header">
          <tr>
            <th >Nr</th>
            <th>{{lang.engine_component.Number}}</th>
            <th>{{lang.engine_component.Date_time}}</th>
            <th>{{lang.engine_component.Fault_code}}</th>
            <th>{{lang.engine_component.Description}}</th>
            <th>{{lang.engine_component.Status}}</th>
            <th>{{lang.engine_component.code_count}}</th>
            <th>{{lang.task_component.actions}}</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <template v-for="(engine,key,index) in engine_logs">
            <tr class="event-table-list"  :key ="key">
              <td class="event-table-name" style="min-width: 40px">{{index+1+page_number_row}}</td>
              <td class="event-table-name">{{engine.number}}</td>
              <td class="event-table-name">{{engine.date}}</td>
              <td class="event-table-name"><b>{{engine.code}}</b></td>
              <td class="event-table-name">{{engine.description}}</td>
              <td class="event-table-name">{{engine.status}}</td>
              <td class="event-table-name">{{engine.code_count}}</td>
              <td class="btn-row">
                <button  type="button" class="btn-row-box btn btn-xs btn-sm btn-warning" @click="getClearDtc(engine.number)">{{lang.engine_component.Clear_DTC}}</button>

              </td>
              <td></td>
            </tr>
          </template>
          </tbody>
        </table>
      </perfect-scrollbar>
      <div class="panel-footer" v-if="custom_role == -1">

        <div class="btn-group-scroll big-group-scroll ">
          <div class="btn  button-scroll button-scroll-total" @click="getClearDtcAll()" v-if="custom_role == '-1'">
            <span class="fa fa-bell-o" style="padding-right: 5px"></span>
            <translate-component :text="lang.engine_component.Clear_DTC_all"/>

          </div>
          <button type="button" class="btn  button-scroll " @click="ScrollList(-1)">
            <span class="button-scroll-icon button-scroll-icon-left fa fa-chevron-left"></span>
          </button>
          <div class="btn  button-scroll button-scroll-center hidelog">
            <translate-component :text="lang.geo_zone_component.page"/>
            <span class="page-count"> <input type="number" class="form-control"
                                             @blur="handlerScrollInput($event)"
                                             v-on:keyup.enter="handlerScrollInput($event)"
                                             :value="params.page" > </span></div>
          <button type="button" class="btn  button-scroll button-scroll-right" @click="ScrollList(1)"><span
              class="button-scroll-icon button-scroll-icon-right fa fa-chevron-right"></span></button>
          <div class="btn  button-scroll button-scroll-total" @click="ScrollList('max')"><translate-component :text="lang.events_component.total_page"/>
            <span style="padding-left: 5px">{{ this.params.totalpages }}</span></div>

          <div class="btn  button-scroll button-scroll-center button-scroll-total" >
            <translate-component :text="lang.task_component.limit"/>
            <span class="page-count"> <select class="form-control" v-model="params.limit" @change="controlRow()" >
               <option value="5">5</option>
                <option value="10">10</option>
              <option value="25">25</option>
                <option value="50">50</option>
            </select></span></div>

        </div>



      </div>



      </div>

    <div v-else>
      <div class="loading-box" >
        <scale-loader :height="loading_size"></scale-loader>
      </div>
    </div>
    </template>
  </section>
</template>

<script>
import {mapGetters} from "vuex";
import SvgEngineIcon from "@/components/ui/icons/svgEngineIcon";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import SearchHideIcon from "@/components/ui/icons/search/SearchHideIcon";
import SearchShoIcon from "@/components/ui/icons/search/SearchShoIcon";
import managerHelper from "@/mixins/managerHelper";
import axios from "axios";
import ScaleLoader from "vue-spinner/src/ScaleLoader";
import {DatePicker } from 'v-calendar';

export default {
  name: "EngineLogsComponent",
  components: {SearchShoIcon, SearchHideIcon, TranslateComponent, SvgEngineIcon,ScaleLoader,'v-date-picker':DatePicker},
  mixins:[managerHelper],
  computed: {
    ...mapGetters({
      lang: 'LANG',
      objects: 'OBJECTS',
      current_resources:'CURRENT_RESOURCES',
    }),
  },
  data() {
    return {
      add_date: new Date(),
      range: {
        start: new Date(2020, 0, 1),
        end: new Date(2020, 0, 5)
      },
      selectedDate:null,
      page_number_row:0,
      search_hide:false,
      loading_size: "30px",
      redy_loading:false,
      show_icon:true,
      redy_icons:false,
      custom_role: 1,
      params:{
        _search:false,
        limit:5,
        order:'number',
        direction:'asc',
        nd: Date.now(),
        totalpages:1,
        page:1,
        filterStatus: -1,
        filterType:-1,
        search:'',
        role:'',
        client:'',
      },
      engine_logs:[],
    }
    },
  mounted() {
    this.getMyRole();
    this.getAllTasks();
  },
  methods: {
    getMyRole(){
      let _this =this;
      axios.post(base_url + '/problem/clienthistory').then(response => {
        if (response.data.success === true) {
          _this.custom_role = response.data.role

        }else{

        }
      }).catch(error => {
        console.log(error)
      })

    },
    getAllTasks(){
      let _this =this;
      _this.redy_loading = true;
      axios.get('/tracking/docs/engine_logs.json').then(response => {

        if(response.status == 200){
         _this.engine_logs = response.data;
        }
        _this.redy_loading = false;
      }).catch(error => {
        console.log(error)
      })

    },
    getClearDtcAll(){

      let _this = this
      let message =  this.lang.engine_component.success_message
      setTimeout(function(){
        _this.$iosAlert(message);
      },500)
    },
    getClearDtc(object_name){
      let _this = this
      let message =  this.lang.engine_component.success_message2+' '+object_name
      setTimeout(function(){
        _this.$iosAlert(message);
      },500)
    },

  }
}
</script>
<style scoped>
.input-group.special-search-input {
  display: flex;
  flex-wrap: nowrap;
}

.btn-row-box2{
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;

}
.btn-row-box2 .btn{
  margin-right: 20px;
}

.ps {
  max-height: calc(100vh - 160px);
}



.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 4px;

}

.event-btn {
  margin-right: 5px;
}
.panel-footer {
  border-radius: 0 0 5px 5px;

}
.big-group-scroll, .btn-group-scroll {
  width: 100%;
  justify-content: start;
}


.search-group.special-search-group{
  max-width: 160px;
}



@media only screen and (max-width: 1600px){
  .panel.global-settings-panel{
    width: calc(100vw - 50px );
    height: calc(100vh - 42px );

  }
  .vehicle-head.task-head{
    flex-wrap: wrap;
  }
  .panel-title{
    width: 100%;
    padding-bottom: 10px;
  }
  .ps {
    max-height: calc(100vh - 200px);
  }

}
@media only screen and (max-width: 1200px){

  .ps {
    max-height: calc(100vh - 240px);
  }

}
@media only screen and (max-width: 767px){

  .panel.global-settings-panel{
    width: 100%;
    height: calc(100vh - 42px );
    top: 60px;
    position: fixed;
  }
  .ps {
    height:  calc(100vh - 260px );
    max-height:  calc(100vh - 260px );
  }
  .global-settings-table {
    margin-right: 20px;
    margin-left: 20px;
  }
}
@media only screen and (max-width: 600px){

  .vehicle-head.task-head {
    flex-wrap: wrap;
    height: 50px;
    overflow-y:  auto;
    transition: height 0.6s linear;
  }
  .vehicle-head.task-head.scroll-head-hide{
    height:auto;
  }

  .ps {
    height:  calc(100vh - 220px );
    max-height:  calc(100vh - 220px );
  }
  .ps.scroll-head-hide {
    height:  calc(100vh - 404px );
    max-height:  calc(100vh - 404px );
  }
  .panel-footer {
    padding: 10px 0;
    margin-top: 0;
  }
  .button-scroll.button-scroll-center.hidelog{
    display: none;
  }
  .button-scroll-center.button-scroll-total span:nth-child(1){
    display: none;
  }
  tr.event-table-list{
    position: relative;
  }
  tr.event-table-list td{
    padding-bottom: 30px !important;
  }
  tr.event-table-list td.btn-row{
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
    padding-bottom: 4px !important;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .search-group{

    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }

}

</style>


