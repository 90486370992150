<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 511.999 511.999" style="enable-background:new 0 0 511.999 511.999;" xml:space="preserve">
<path style="fill:#2D527C;" d="M255.999,426.059c-58.135,0-120.528-28.211-180.434-81.584  c-44.524-39.668-71.926-78.859-73.07-80.509c-3.326-4.79-3.326-11.141,0-15.931c1.144-1.65,28.546-40.84,73.072-80.509  C135.473,114.154,197.865,85.941,256,85.941c38.633,0,78.631,12.107,118.882,35.983c79.124,46.936,132.393,122.898,134.622,126.11  c4.399,6.338,2.827,15.042-3.509,19.44c-6.338,4.397-15.043,2.83-19.442-3.509c-0.508-0.732-52.166-74.261-125.924-118.013  c-35.88-21.283-71.081-32.074-104.63-32.074c-107.992,0-200.798,111.051-224.637,142.114  C55.229,287.035,148.191,398.121,256,398.121c64.189,0,122.834-39.976,160.728-73.51c5.777-5.111,14.607-4.572,19.717,1.203  c5.113,5.777,4.573,14.606-1.203,19.718C375.714,398.213,313.732,426.059,255.999,426.059z"/>
    <path style="fill:#CEE8FA;" d="M330.079,181.919c-40.913-40.913-107.248-40.913-148.161,0s-40.913,107.248,0,148.161  s107.248,40.913,148.161,0l-74.08-74.08L330.079,181.919z"/>
    <path style="fill:#2D527C;" d="M255.999,374.679c-30.406,0-60.81-11.575-83.958-34.721c-46.294-46.295-46.294-121.622,0-167.917  c46.297-46.295,121.62-46.294,167.917,0c5.455,5.455,5.455,14.3,0,19.756L275.755,256l64.203,64.203  c5.455,5.455,5.455,14.3,0,19.756C316.809,363.104,286.405,374.679,255.999,374.679z M255.999,165.246  c-23.254,0-46.501,8.848-64.203,26.552c-35.401,35.401-35.401,93.005,0,128.406c32.044,32.043,82.277,35.084,117.77,9.119  l-63.445-63.445c-5.455-5.455-5.455-14.3,0-19.756l63.445-63.445C293.681,171.057,274.836,165.246,255.999,165.246z"/>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgEyeShow"
}
</script>

<style scoped>

</style>
