<template>

  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<circle cx="186.182" cy="116.364" r="11.636"/>
	</g>
</g>
    <g>
	<g>
		<circle cx="139.636" cy="116.364" r="11.636"/>
	</g>
</g>
    <g>
	<g>
		<circle cx="232.727" cy="116.364" r="11.636"/>
	</g>
</g>
    <g>
	<g>
		<path d="M195.491,260.655c-3.491-5.818-15.127-5.818-18.618,0s-37.236,57.018-37.236,86.109c0,26.764,20.945,48.873,46.545,48.873    c25.6,0,46.545-22.109,46.545-48.873C232.727,317.673,200.145,266.473,195.491,260.655z M186.182,372.364    c-12.8,0-23.273-11.636-23.273-25.6c0-12.8,11.636-37.236,23.273-57.018c11.636,19.782,23.273,44.218,23.273,57.018    C209.455,360.727,198.982,372.364,186.182,372.364z"/>
	</g>
</g>
    <g>
	<g>
		<path d="M508.509,143.127l-58.182-58.182c-4.655-4.655-11.636-4.655-16.291,0c-4.655,4.655-4.655,11.636,0,16.291l29.091,29.091    c-11.636,5.818-20.945,18.618-20.945,32.582c0,19.782,15.127,34.909,34.909,34.909c4.655,0,8.145-1.164,11.636-2.327v211.782    c0,19.782-15.127,34.909-34.909,34.909c-19.782,0-34.909-15.127-34.909-34.909V221.091c0-32.582-25.6-58.182-58.182-58.182    h-23.273V58.182C337.455,25.6,311.855,0,279.273,0H93.091C60.509,0,34.909,25.6,34.909,58.182v384    C15.127,442.182,0,457.309,0,477.091C0,496.873,15.127,512,34.909,512h302.545c19.782,0,34.909-15.127,34.909-34.909    c0-19.782-15.127-34.909-34.909-34.909H93.091c-6.982,0-11.636,4.655-11.636,11.636s4.655,11.636,11.636,11.636h244.364    c6.982,0,11.636,4.655,11.636,11.636s-4.655,11.636-11.636,11.636H34.909c-6.982,0-11.636-4.655-11.636-11.636    s4.655-11.636,11.636-11.636h11.636c6.982,0,11.636-4.655,11.636-11.636V58.182c0-19.782,15.127-34.909,34.909-34.909h186.182    c19.782,0,34.909,15.127,34.909,34.909v349.091c0,6.982,4.655,11.636,11.636,11.636s11.636-4.655,11.636-11.636V186.182h23.273    c19.782,0,34.909,15.127,34.909,34.909v186.182c0,32.582,25.6,58.182,58.182,58.182c32.582,0,58.182-25.6,58.182-58.182v-256    C512,147.782,510.836,145.455,508.509,143.127z M477.091,174.545c-6.982,0-11.636-4.655-11.636-11.636    c0-6.982,4.655-11.636,11.636-11.636s11.636,4.655,11.636,11.636C488.727,169.891,484.073,174.545,477.091,174.545z"/>
	</g>
</g>
    <g>
	<g>
		<path d="M279.273,46.545H93.091c-6.982,0-11.636,4.655-11.636,11.636v116.364c0,6.982,4.655,11.636,11.636,11.636h186.182    c6.982,0,11.636-4.655,11.636-11.636V58.182C290.909,51.2,286.255,46.545,279.273,46.545z M267.636,162.909H104.727V69.818    h162.909V162.909z"/>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgFuelIcon"
}
</script>
