<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<circle style="fill:#61A2EF;" cx="255.999" cy="255.998" r="255.998"/>
    <path style="fill:#528ACF;" d="M511.999,256.002C511.999,114.618,397.384,0.001,256.001,0v512  C397.385,511.999,511.999,397.385,511.999,256.002z"/>
    <path style="fill:#9ED04D;" d="M54.383,185.772c12.449,13.108,26.146,25.03,40.845,35.554c4.035,2.888,8.391,5.989,9.995,10.685  c4.661,13.654-3.341,28.394,4.815,43.956c7.711,14.714,24.743,23.894,29.145,39.912c3.434,12.494-1.844,26.056,1.139,38.666  c3.617,15.294,18.386,25.618,23.72,40.401c3.394,9.405,2.668,19.805,5.148,29.492c2.48,9.687,10.287,19.563,20.244,18.653  c12.03-1.099,16.972-15.93,17.912-27.974c0.941-12.044,2.174-26.568,12.958-32.013c5.735-2.896,13.228-2.446,17.717-7.043  c7.46-7.64-0.043-20.152,0.213-30.826c0.273-11.377,9.632-20.179,18.286-27.571c10.904-9.314,21.808-18.628,32.712-27.942  c7.572-6.403,9.509-17.871,6.401-27.288c-3.106-9.419-10.441-16.935-18.693-22.435c-8.875-5.916-20.304-9.893-30.149-5.791  c-7.495,3.122-13.292,10.601-21.397,11.068c-7.475,0.431-13.924-5.503-17.509-12.076c-3.584-6.574-5.275-14.052-8.909-20.599  c-10.43-18.796-27.314-17.651-45.519-20.467c-18.38-2.843-37.072-11.424-50.769-23.981c-2.195-2.012-4.371-4.25-5.241-7.097  c-4.868-15.931,23.347-22.98,33.106-22.658c8.001,0.263,16.164,2.597,23.871,0.436c7.276-2.039,12.84-7.719,19.024-12.061  c17.499-12.287,43.073,4.468,46.457-28.77c1.326-13.018-3.713-25.748-8.656-37.864c-7.167-17.568-13.614-34.231-18.318-52.299  C120.117,26.288,59.753,75.939,26.879,141.73c8.752,9.421,6.188,5.019,9.858,17.356C39.808,169.406,46.968,177.966,54.383,185.772z"/>
    <g>
	<path style="fill:#86B143;" d="M330.002,69.557c-1.773,2.295-3.604,4.721-4.057,7.586c-0.738,4.676,2.336,9.011,5.243,12.746   c4.413,5.671,9.348,11.692,16.322,13.431c12.996,3.24,24.625-9.77,37.976-10.841c10.721-0.86,20.671,6.104,31.42,6.495   c12.194,0.442,18.494-11.176,20.958-23.127c-19.585-19.771-42.372-36.357-67.518-48.934   C360.547,39.6,339.801,56.873,330.002,69.557z"/>
      <path style="fill:#86B143;" d="M500.255,179.152c-4.732,14.431-18.03,10.613-35.278,11.94   c-22.144,1.704-35.602-9.186-56.057-13.252c-18.983-3.773-35.664,9.302-44.534,25.041c-6.585,11.685-16.904,37.545-8.238,49.482   c4.142,5.704,10.357,9.481,16.403,13.105c8.705,5.218,17.454,10.457,26.865,14.255c10.821,4.365,23.237,7.352,30.12,16.773   c5.669,7.758,6.127,18.135,5.142,27.692c-2.675,25.947-25.27,48.978-4.639,72.551c7.752,8.859,18.303,13.757,30.073,13.769   c32.556-42.941,51.884-96.464,51.884-154.506C511.999,229.225,507.881,203.412,500.255,179.152z"/>
      <path style="fill:#86B143;" d="M295.63,262.434c-3.106-9.419-10.441-16.935-18.693-22.435c-6.202-4.135-13.651-7.31-20.936-7.455   v85.565c0.173-0.148,0.345-0.298,0.516-0.445c10.904-9.314,21.808-18.628,32.712-27.942   C296.802,283.319,298.738,271.852,295.63,262.434z"/>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgLandIcon"
}
</script>

<style scoped>

</style>