import { render, staticRenderFns } from "./sortDownFilterIcon.vue?vue&type=template&id=765f4acd&scoped=true&"
import script from "./sortDownFilterIcon.vue?vue&type=script&lang=js&"
export * from "./sortDownFilterIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "765f4acd",
  null
  
)

export default component.exports