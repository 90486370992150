<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M490.129,139.288c-13.534-24.562-32.674-46.441-56.889-65.029c-47.767-36.668-110.712-56.861-177.239-56.861    S126.528,37.591,78.76,74.259c-24.214,18.587-43.355,40.466-56.888,65.029C7.358,165.626,0,193.741,0,222.853    c0,29.112,7.358,57.226,21.871,83.565c11.776,21.372,27.857,40.769,47.84,57.713L58.671,469.07    c-0.875,8.323,2.651,16.218,9.433,21.12c4.041,2.92,8.727,4.411,13.47,4.411c3.217,0,6.462-0.687,9.546-2.078l143.845-64.904    c7.046,0.456,14.113,0.687,21.036,0.687c58.57,0,113.568-15.269,159.052-44.159c3.844-2.442,4.981-7.537,2.54-11.383    c-2.441-3.845-7.537-4.981-11.384-2.54c-42.835,27.207-94.777,41.588-150.209,41.588c-7.215,0-14.601-0.265-21.957-0.787    c-1.365-0.099-2.73,0.146-3.976,0.709l-145.73,65.754c-3.138,1.416-5.641,0.007-6.571-0.666c-0.929-0.672-3.052-2.605-2.692-6.028    l11.501-109.322c0.291-2.766-0.833-5.494-2.989-7.253c-19.945-16.271-35.849-35.032-47.271-55.763    c-13.154-23.872-19.823-49.309-19.823-75.605c0-26.296,6.669-51.733,19.823-75.605c12.429-22.557,30.087-42.712,52.485-59.905    C133.706,52.874,193.084,33.891,256,33.891s122.294,18.983,167.198,53.451c22.398,17.193,40.057,37.347,52.484,59.905    c13.155,23.872,19.824,49.309,19.824,75.605c0,26.296-6.669,51.732-19.823,75.605c-10.671,19.364-25.278,37.043-43.415,52.544    c-3.461,2.959-3.87,8.164-0.91,11.627c2.957,3.461,8.163,3.87,11.627,0.911c19.65-16.794,35.512-36.014,47.143-57.123    C504.641,280.079,512,251.964,512,222.853S504.642,165.627,490.129,139.288z"/>
	</g>
</g>
    <g>
	<g>
		<path d="M396.415,122.231C359.152,93.626,309.285,77.874,256,77.874c-46.816,0-90.38,11.865-125.982,34.312    c-3.853,2.429-5.007,7.521-2.578,11.374c2.43,3.852,7.521,5.007,11.375,2.577c32.965-20.784,73.487-31.77,117.186-31.77    c49.674,0,95.974,14.542,130.373,40.947c31.377,24.087,48.657,55.176,48.657,87.539s-17.281,63.453-48.657,87.539    c-34.398,26.406-80.699,40.947-130.373,40.947c-49.673,0-95.976-14.542-130.374-40.947    c-31.377-24.086-48.657-55.174-48.657-87.538c0-28.017,12.674-54.759,36.656-77.336c3.316-3.122,3.472-8.341,0.351-11.657    c-3.124-3.316-8.341-3.473-11.657-0.351c-27.373,25.772-41.842,56.667-41.842,89.344c0,37.608,19.571,73.342,55.108,100.622    c37.263,28.605,87.13,44.357,140.416,44.357c53.285,0,103.152-15.753,140.415-44.357c35.538-27.28,55.108-63.014,55.108-100.622    C451.523,185.245,431.953,149.511,396.415,122.231z"/>
	</g>
</g>
    <g>
	<g>
		<path d="M295.647,259.359c-3.21-3.23-8.431-3.248-11.662-0.037c-5.966,5.928-16.69,9.611-27.986,9.611    c-11.235,0-21.93-3.655-27.912-9.538c-3.244-3.192-8.467-3.152-11.661,0.097c-3.194,3.247-3.15,8.469,0.097,11.662    c9.087,8.936,23.844,14.272,39.476,14.272c15.725,0,30.532-5.385,39.61-14.405C298.84,267.811,298.857,262.59,295.647,259.359z"/>
	</g>
</g>
    <g>
	<g>
		<circle cx="214.061" cy="197.721" r="15.76"/>
	</g>
</g>
    <g>
	<g>
		<circle cx="298.045" cy="197.721" r="15.76"/>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgCommentIcon"
}
</script>

<style scoped>

</style>
