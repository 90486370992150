import { render, staticRenderFns } from "./svgInfoIcon.vue?vue&type=template&id=77653eb1&scoped=true&"
import script from "./svgInfoIcon.vue?vue&type=script&lang=js&"
export * from "./svgInfoIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77653eb1",
  null
  
)

export default component.exports