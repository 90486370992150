<template>
  <section class="panel weather-box">
    <header class="panel-heading weather-header">
      <div class="panel-actions">
        <a href="#" class="fa fa-times" @click="closeWeather()"></a>
      </div>
      <span class="fa geo-zone-icon">
         <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="nightfog" x="0px" y="0px" viewBox="0 0 213.5976 212.9661" enable-background="new 0 0 213.5976 212.9661" xml:space="preserve" >
<g>
	<path fill="#5CB0FF" d="M35.0696,98.9661c0,14.3359,10.7656,26,24,26h87.3086c0.1445-0.0234,0.2852-0.043,0.4336-0.0508   c15.8438-0.9688,28.2578-15,28.2578-31.9492c0-17.3438-13.2227-31.6953-29.4727-31.9941c-1.7578-0.0332-3.2891-1.209-3.7734-2.9004   c-4.2266-14.7812-18.5195-25.1055-34.7539-25.1055c-19.8516,0-36,15.252-36,34c0,0.9883,0.0469,1.9648,0.1328,2.9297   c0.1211,1.3398-0.4414,2.6484-1.4922,3.4863c-1.043,0.8398-2.4414,1.0938-3.7305,0.6777   c-2.2305-0.7266-4.5547-1.0938-6.9102-1.0938C45.8352,72.9661,35.0696,84.6302,35.0696,98.9661z"/>
	<path fill="#D0E8FF" d="M30.2532,55.0638c6.5781,0.2559,12.875-2.1191,17.6953-6.6191c4.8281-4.498,7.6094-10.6055,7.8398-17.1973   s-2.1172-12.877-6.6172-17.7012c-0.3906-0.4219-0.7969-0.8262-1.2109-1.2148c0.3008,9.2852-3.3672,18.4512-10.3828,24.9902   c-7.0117,6.5391-16.4141,9.5684-25.6484,8.6113c0.3555,0.4414,0.7344,0.873,1.125,1.293   C17.554,52.0501,23.6594,54.8333,30.2532,55.0638z"/>
	<path fill="#1C71DA" d="M29.9719,63.0599c0.3906,0.0137,0.7812,0.0195,1.1719,0.0195c8.2969,0,16.1641-3.0938,22.2617-8.7832   c6.3867-5.9551,10.0742-14.041,10.3789-22.7676c0.3047-8.7285-2.8047-17.0527-8.7617-23.4395   c-3.1406-3.3652-6.8594-5.9785-11.0586-7.7715c-1.4531-0.6133-3.1367-0.3262-4.2969,0.7539s-1.5703,2.7402-1.0547,4.2363   c3.2031,9.2324,0.6562,19.502-6.4883,26.1621c-7.1406,6.6641-17.5625,8.4902-26.5547,4.6504   c-1.457-0.6152-3.1367-0.3281-4.3008,0.7559c-1.1562,1.0801-1.5664,2.7402-1.0469,4.2363c1.5,4.3184,3.8477,8.2109,6.9805,11.5703   C13.1594,59.0696,21.2454,62.7552,29.9719,63.0599z M37.5774,37.3216c7.0156-6.5391,10.6836-15.7051,10.3828-24.9902   c0.4141,0.3887,0.8203,0.793,1.2109,1.2148c4.5,4.8242,6.8477,11.1094,6.6172,17.7012s-3.0117,12.6992-7.8398,17.1973   c-4.8203,4.5-11.1172,6.875-17.6953,6.6191c-6.5938-0.2305-12.6992-3.0137-17.1992-7.8379   c-0.3906-0.4199-0.7695-0.8516-1.125-1.293C21.1633,46.8899,30.5657,43.8606,37.5774,37.3216z"/>
	<path fill="#1C71DA" d="M59.0696,64.9661c-17.6445,0-32,15.252-32,34c0,18.7461,14.3555,34,32,34h88   c0.3281,0,0.6523-0.0391,0.9688-0.1172c19.7031-1.6055,35.0312-18.9766,35.0312-39.8828c0-20.6523-15.2031-37.9141-34.4414-39.8242   c-6.0977-16.7266-22.7773-28.1758-41.5586-28.1758c-23.6562,0-43.0078,17.9082-43.9648,40.2676   C61.7727,65.056,60.425,64.9661,59.0696,64.9661z M69.7102,73.3821c1.0508-0.8379,1.6133-2.1465,1.4922-3.4863   c-0.0859-0.9648-0.1328-1.9414-0.1328-2.9297c0-18.7481,16.1484-34,36-34c16.2344,0,30.5273,10.3242,34.7539,25.1055   c0.4844,1.6914,2.0156,2.8672,3.7734,2.9004c16.25,0.2988,29.4727,14.6504,29.4727,31.9941   c0,16.9492-12.4141,30.9805-28.2578,31.9492c-0.1484,0.0078-0.2891,0.0273-0.4336,0.0508H59.0696c-13.2344,0-24-11.6641-24-26   s10.7656-26,24-26c2.3555,0,4.6797,0.3672,6.9102,1.0938C67.2688,74.4759,68.6672,74.222,69.7102,73.3821z"/>
	<path fill="#1C71DA" d="M95.1633,204.9661H11.0696c-2.2109,0-4,1.7891-4,4s1.7891,4,4,4h84.0938c2.2109,0,4-1.7891,4-4   S97.3743,204.9661,95.1633,204.9661z"/>
	<path fill="#1C71DA" d="M199.1633,204.9661h-84.0938c-2.2109,0-4,1.7891-4,4s1.7891,4,4,4h84.0938c2.2109,0,4-1.7891,4-4   S201.3743,204.9661,199.1633,204.9661z"/>
	<path fill="#1C71DA" d="M11.0696,172.9661h116.0703c2.2109,0,4-1.7891,4-4s-1.7891-4-4-4H11.0696c-2.2109,0-4,1.7891-4,4   S8.8586,172.9661,11.0696,172.9661z"/>
	<path fill="#1C71DA" d="M147.0696,164.9661c-2.2109,0-4,1.7891-4,4s1.7891,4,4,4h52.0703c2.2109,0,4-1.7891,4-4s-1.7891-4-4-4   H147.0696z"/>
	<path fill="#1C71DA" d="M11.0696,192.9661h186.043c2.2109,0,4-1.7891,4-4s-1.7891-4-4-4H11.0696c-2.2109,0-4,1.7891-4,4   S8.8586,192.9661,11.0696,192.9661z"/>
	<path fill="#1C71DA" d="M7.0696,148.9661c0,2.2109,1.7891,4,4,4h186.043c2.2109,0,4-1.7891,4-4s-1.7891-4-4-4H11.0696   C8.8586,144.9661,7.0696,146.7552,7.0696,148.9661z"/>
</g>
<path fill="#FF5D5D" d="M195.455,107.7983c-1.0234,0-2.0469-0.3906-2.8281-1.1714c-1.5625-1.5625-1.5625-4.0952,0-5.6572  l14.1426-14.1421c1.5605-1.5615,4.0938-1.5615,5.6562,0c1.5625,1.5625,1.5625,4.0952,0,5.6572l-14.1426,14.1421  C197.5028,107.4076,196.4784,107.7983,195.455,107.7983z"/>
<path fill="#FF5D5D" d="M209.5976,107.7983c-1.0234,0-2.0469-0.3906-2.8281-1.1714l-14.1426-14.1421  c-1.5625-1.562-1.5625-4.0947,0-5.6572c1.5605-1.5615,4.0938-1.5615,5.6562,0l14.1426,14.1421c1.5625,1.562,1.5625,4.0947,0,5.6572  C211.6454,107.4076,210.621,107.7983,209.5976,107.7983z"/>
<path fill="#00D40B" d="M165.455,31.7983c-7.7197,0-14-6.2803-14-14s6.2803-14,14-14s14,6.2803,14,14  S173.1747,31.7983,165.455,31.7983z M165.455,11.7983c-3.3086,0-6,2.6914-6,6s2.6914,6,6,6s6-2.6914,6-6  S168.7636,11.7983,165.455,11.7983z"/>
<path fill="#FFC504" d="M74.7685,106.4257c-1.0605,0-2.0781-0.4214-2.8281-1.1719l-11.3135-11.314  c-1.5625-1.562-1.5625-4.0942,0-5.6567l11.3135-11.3135c1.5605-1.5615,4.0957-1.5615,5.6562,0l11.3135,11.3135  c1.5625,1.5625,1.5625,4.0947,0,5.6567l-11.3135,11.314C76.8466,106.0043,75.829,106.4257,74.7685,106.4257z M69.1122,91.1117  l5.6562,5.6572l5.6562-5.6572l-5.6562-5.6567L69.1122,91.1117z"/>
</svg>
        </span>
      <h2 class="panel-title geo-zone">

        <span class="va-middle" v-if="summer_object.location" >{{summer_object.location.country+',  '+summer_object.location.name}}</span>
        <span class="loading-box" v-else> <scale-loader :height="loading_size" ></scale-loader></span>
      </h2>


    </header>
    <div class="panel-body">
      <perfect-scrollbar>
      <ul class="simple-post-list weather-list">
        <template v-if="summer_object.forecast">
        <li class="weather-hour"  v-for="(item,index) in summer_object.forecast"  :style="{'backgroundColor' : getColor(item.tt) }">

              <div class="weather-flex-box" >
                <div class="weather-time">
                <div class="date">{{ item.datetime| moment('DD.MM.YYYY') }}</div>
                <div class="time">{{ item.datetime| moment('HH:mm') }}</div>
              </div>
                <div class="weather-temp">
                <div class="t">{{ item.tt > 0 ? '+' + item.tt : ' ' + item.tt }}&deg;C </div>
                <div class="ww">
                  {{ item.wwtext }}
                </div>
                </div>
                <div class="weather-wind">
                <div class="wind">
                  Wind: {{ (item.ff * 0.514444).toFixed(1) }} m/s <img
                  :src="base_url+'/images/icons/angle_gray/'+calculateAngle(item.dd)+'.png'"/>
                </div>
                <div class="mm">
                  Precipitation: {{ item.rrr }} mm
                </div>
                <div class="m">
                  Visibility: {{ item.vv }} m
                </div>
                </div>
              </div>
        </li>
        </template>

      </ul>
      </perfect-scrollbar>
    </div>
  </section>
</template>


<script>

import {mapGetters} from "vuex";
import axios from "axios";
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import helpers from "@/mixins/helpers";
import mapHelper from "@/mixins/mapHelper";

export default {
  name: "WeatherComponent",
  components: {
    ScaleLoader,
  },
  mixins: [helpers, mapHelper],
  mounted() {
    this.getWeather(this.latlng);
  },
  data() {
    return {
      summer_object: {},
      base_url: base_url,
      loading_size:'18px'
    }
  },
  computed: {
    ...mapGetters({
      weather_object: 'WEATHER_OBJECT',
      latlng: 'LATLNG'
    }),

  },

  methods: {
    closeWeather(){
      this.$store.commit('SET_WEATHER_OBJECT', false);
    },
    getColor(tem){

       let color = '#E3F2FD'

        if(tem >= 25) {
          color ='#FFCDD2'
        }

      if(tem >= 20 && tem <25) {
        color ='#FFE0B2'
      }
      if(tem >= 15 && tem <20) {
        color ='#FFF8E1'
      }
      if(tem >= 5 && tem  <15) {
        color ='#F3E5F5'
      }
      if(tem >= 0 && tem <5) {
        color ='#E8EAF6'
      }
      if(tem >= -5 && tem <0) {
        color ='#E3F2FD'
      }
      if(tem >= -15 && tem <-5) {
        color ='#B2DFDB'
      }
      if(tem  < -15) {
        color ='#90CAF9'
      }
      return  color
    },
    getWeather(latlng) {
      axios.get(base_url + '/weather/get?lat=' + latlng.lat + '&lon=' + latlng.lng).then(response => {
        this.summer_object = response.data;

      }).catch(error => {
        console.log(error)
      })

    },
  },

}
</script>

<style scoped>

.weather-header{
  display: flex;
  align-items: center;
  background: #e5f3f9;
  border-bottom: 1px solid #e5f3f9;
}
.weather-list{
  max-height: 300px;
}
.weather-list li{

  padding: 15px 0;
  margin: 5px 25px 5px 5px;
  padding: 5px 10px;
  box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px, rgb(0 0 0 / 30%) 0px 1px 3px -1px;
}
.weather-flex-box{
  display: flex;
}
.weather-time{
  height: 100%;
  font-size: 12px;
  font-weight: 600;
  color: #34495e;
  line-height: 14px;
}
.weather-temp{
  margin-left: 10px;
  margin-right: 10px;
}
.weather-temp .t{
  font-size: 30px;
  padding-top: 10px;
  font-weight: 600;
  color: #2196F3;
}
.weather-temp .ww{
  padding-left: 10px;
  font-size: 11px;
  padding-top: 5px;

}
.fa.geo-zone-icon {
  width: 30px;
  height: 30px;
  color: #1C71DA;
  margin-right: 15px;
}
.fa.geo-zone-icon svg{
  width: 30px;
}


</style>
