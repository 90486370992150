<template>
  <section class="object-info-box" :class="show_content? '' : 'hide-object-wrapper'">
    <div class="card object-info">
      <div class="widgets-panel__header">
        <div class="widgets-panel__tracker-title settin-title-box">
           <span>
          <translate-component :text="lang.route_settings_component.title"></translate-component><br/>


          <span v-if="active_route === 'fuel2'">{{getObjectName()}}</span>
                 <span v-else >{{ history_array[settings_active] ? history_array[settings_active].Number : ' ' }}</span>
          </span>

          <button type="button" class="btn object-info-close" @click="closeModal()"><span class=" fa fa-times"></span>
          </button>


        </div>
        <div class="street-view widgets-panel__street-view">
          <div class="street-view__streetview" :style="{ backgroundImage: 'url(' + backgroundImageUrl + ')' }">

          </div>
          <button type="button" class="btn remove-default-btn" @click="addDefault()">
            <translate-component :text="lang.route_settings_component.default_params"></translate-component>
          </button>

          <div class="street-view__click-stub">
            <span aria-hidden="true" class="fa touch-icon">

          </span>

          </div>
        </div>
      </div>
      <template v-if="checkObjectLength(settings_options) && show_content ">
        <div class="card-body">
          <div class="route-setting_box">
            <ul class="route-setting_list">
              <li>
                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.route_settings_component.page_route"/>
                  </div>
                  <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                          :class="page===1 ? 'active' : ''" @click="changePage(1)">
                    <svg-path-icon/>
                  </button>
                </popper>
              </li>
              <li>
                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.route_settings_component.page_play"/>
                  </div>
                  <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                          :class="page===2 ? 'active' : ''" @click="changePage(2)">
                    <svg-player-icon/>

                  </button>
                </popper>
              </li>
              <li>
                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.route_settings_component.page_view"/>
                  </div>
                  <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                          :class="page===3 ? 'active' : ''" @click="changePage(3)">
                    <svg-roue-view-icon/>
                  </button>
                </popper>
              </li>
              <li>
                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.route_settings_component.page_map"/>
                  </div>
                  <button type="button" class="btn route-setting_page-btn bottom" slot="reference"
                          :class="page===4 ? 'active' : ''" @click="changePage(4)">
                    <svg-map-icon/>

                  </button>
                </popper>
              </li>
            </ul>
            <div style="display: none;">{{ help_counter }}</div>
            <perfect-scrollbar :options="{'suppressScrollX' :suppressScrollX,'suppressScrollY' : false}" >
            <transition-group name="testanim" tag="div" >
              <div  key="1" class="route-setting_item"  v-if="page===1">
                <div class="left-pane-padding">
                  <div class="route-setting_input-box">
                    <translate-component :text="lang.route_settings_component.minimal_stop_time"/>
                    :<br/>
                    <select id="minStopTime" name="minStopTime"
                            @change="getSelect($event,'minStopTime','reports_minStopTime')"
                            :value="settings_options.minStopTime">
                      <option value="0">
                        <translate-component :text="lang.route_settings_component.all"/>
                      </option>
                      <template v-for="(step) in stop_time">
                        <option :value="step">
                          <translate-component :text="lang.route_settings_component.greater"/>
                          {{ step }}
                          <translate-component :text="lang.route_settings_component.minutes"/>
                        </option>
                      </template>
                    </select>
                  </div>

                  <div class="route-setting_input-box">
                    <translate-component :text="lang.route_settings_component.allowable_distance"/>
                    :<br/>
                    <select id="maxStopDistance" name="maxStopDistance"
                            @change="getSelect($event,'maxStopDistance','reports_maxStopDistance')"
                            :value="settings_options.maxStopDistance">
                      <template v-for="(step) in allowable_distance">
                        <option :value="step">{{ step }}
                          <translate-component :text="lang.route_settings_component.meters"/>
                        </option>
                      </template>
                    </select>
                  </div>
                  <div class="route-setting_input-box">
                    <translate-component :text="lang.route_settings_component.skipMinDistance"/>
                    :<br/>
                    <select id="skipMinDistance" name="skipMinDistance"
                            @change="getSelect($event,'skipMinDistance','reports_skipMinDistance')"
                            :value="settings_options.skipMinDistance">
                      <template v-for="(step) in min_distance">
                        <option :value="step">{{ step }}
                          <translate-component :text="lang.route_settings_component.meters"/>
                        </option>
                      </template>
                    </select>
                  </div>
                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" class="switch_1" id="chkByIgnition"
                             @change="getSelect($event,'chkByIgnition','reports_chkByIgnition')"
                             :checked="settings_options.chkByIgnition == 1 ? 1 :0">
                    </div>
                    <span> <translate-component :text="lang.route_settings_component.use_ignition"/></span>

                  </div>
                </div>
              </div>
              <div  key="2" class="route-setting_item" v-if="page===2">
                <div class="left-pane-padding">
                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" id="chkPlayer" class="switch_1"
                             @change="getSelect($event,'chkPlayer','routes_chkPlayer')"
                             :checked="settings_options.chkPlayer == 1 ? 1 :0">
                    </div>
                    <span> <translate-component :text="lang.route_settings_component.use_player"/></span>

                  </div>
                  <div class="route-setting_input-box checkbox-group">

                    <div class="switch_box box_1">
                      <input type="checkbox" class="switch_1" id="player-autostart"
                             @change="getSelect($event,'player_autostart','routes_autostart')"
                             :checked="settings_options.player_autostart == 1 ? 1 :0"

                      />
                    </div>
                    <span> <translate-component :text="lang.route_settings_component.auto_start"/></span>
                  </div>
                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" class="switch_1" id="catch-marker"
                             @change="getSelect($event,'catch_marker','routes_catchMarker')"
                             :checked="settings_options.catch_marker == 1 ? 1 :0"
                      />
                    </div>
                    <span>
                      <translate-component :text="lang.route_settings_component.catch_marker"/>
                      </span>
                  </div>
                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" class="switch_1" id="show-speedometer"

                             @change="getSelect($event,'show_speedometer','routes_showSpeedometer')"
                             :checked="settings_options.show_speedometer == 1 ? 1 :0"
                      />
                    </div>
                    <span><translate-component :text="lang.route_settings_component.show_speedometer"/></span>
                  </div>
                </div>
              </div>
              <div key="3" class="route-setting_item"  v-if="page===3">

                <div class="left-pane-padding">
                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" id="chkMarkerShow" class="switch_1"
                             @change="getSelect($event,'use_arrow_show','routes_show_direction')"
                             :checked="settings_options.use_arrow_show == 1 ? 1 :0"
                      />
                    </div>
                    <span><translate-component :text="lang.route_settings_component.use_arrow_show"/></span>
                  </div>

                  <div class="route-setting_input-box">
                    <div style="display: flex"><span><translate-component
                        :text="lang.route_settings_component.use_arrow_color"/></span>
                      <span class="color-chacker" @click="isArrowOpen = !isArrowOpen"
                            :style="{'backgroundColor': settings_options.arrow_colorpicker.hex}"></span></div>

                    <compact-picker
                        v-show="isArrowOpen"
                        :palette="color_pallete"
                        @input="getColor($event,'arrow_colorpicker','routes_color_direction')"
                        :value="settings_options.arrow_colorpicker"
                    ></compact-picker>
                  </div>

                  <div class="route-setting_input-box" v-if="show_showTimePointsEnabled">
                    <div class="route-setting_input-helper">
                      <span class="min">1</span>
                      <span class="max">200</span>
                    </div>
                    <vue-range-slider
                        :min="1" :max="200"
                        @input="getSelect($event,'showTimePointsMinutes','routes_showTimePointsMinutes')"
                        :value="settings_options.showTimePointsMinutes"
                    ></vue-range-slider>
                  </div>


                  <div class="route-setting_input-box checkbox-group" v-if="show_showTimePointsEnabled">
                    <div class="switch_box box_1">
                      <input type="checkbox" id="showTimePointsEnabled" class="switch_1"
                             @change="getSelect($event,'showTimePointsEnabled','routes_showTimePointsEnabled')"
                             :checked="settings_options.showTimePointsEnabled == 1 ? 1 :0"
                      />
                    </div>
                    <span><translate-component :text="lang.route_settings_component.show_points_each"/>
                    (<span class="spanTimePointsMinutes">{{ settings_options.showTimePointsMinutes }}</span> min)
                     </span>
                  </div>

                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" id="chkSpeedLimit" class="switch_1"
                             @change="getSelect($event,'use_speed_limit','routes_chkSpeedLimit')"
                             :checked="settings_options.use_speed_limit == 1 ? 1 :0"
                      />
                    </div>
                    <span><translate-component :text="lang.route_settings_component.use_speed_limit"/></span>
                  </div>

                  <div class="route-setting_input-box">
                    <span><translate-component :text="lang.route_settings_component.speed_Limit"/></span>
                    <div class="route-setting_input-helper">
                      <span class="min">0</span>
                      <span class="max">150</span>
                    </div>
                    <vue-range-slider :min="range_min" :max="range_max"
                                      :enable-cross="enableCross"
                                      :tooltip-merge="tooltipMerge"
                                      @input="getSelect($event,'routes_speed','routes_speed')"
                                      :value="settings_options.routes_speed"


                    ></vue-range-slider>
                  </div>


                  <div class="route-setting_input-box">
                    <div style="display: flex">
                      <span><translate-component
                          :text="lang.route_settings_component.stroke_color"/>
                    </span>
                      <span class="color-chacker" @click="toggleStrokeColor"
                            :style="{'backgroundColor': settings_options.stroke_colorpicker.hex}">
                      </span>
                    </div>
                    <compact-picker
                        v-show="isStrokeOpen"
                        :palette="color_pallete"
                        @input="getColor($event,'stroke_colorpicker','routes_strokeColor')"
                        :value="settings_options.stroke_colorpicker"

                    ></compact-picker>
                  </div>


                  <div class="route-setting_input-box">
                    <span> <translate-component :text="lang.route_settings_component.stroke_opacity"/></span>
                    <div class="route-setting_input-helper">
                      <span class="min">0</span>
                      <span class="max">1</span>
                    </div>
                    <vue-range-slider :step="step" :min="range_min" :max="border_opacity_max"
                                      @input="getSelect($event,'strokeOpacity','routes_strokeOpacity')"
                                      :value="settings_options.strokeOpacity"
                    ></vue-range-slider>

                  </div>


                  <div class="route-setting_input-box">
                    <span> <translate-component :text="lang.route_settings_component.stroke_smooth"/></span>
                    <div class="route-setting_input-helper">
                      <span class="min">0</span>
                      <span class="max">9</span>
                    </div>
                    <vue-range-slider :min="0" :max="9"
                                      @input="getSelect($event,'strokeSmooth','routes_strokeSmooth')"
                                      :value="settings_options.strokeSmooth"

                    ></vue-range-slider>

                  </div>

                  <div class="route-setting_input-box">
                    <span> <translate-component :text="lang.route_settings_component.stroke_weight"/></span>
                    <div class="route-setting_input-helper">
                      <span class="min">0</span>
                      <span class="max">20</span>
                    </div>
                    <vue-range-slider :min="range_min" :max="20"
                                      @input="getSelect($event,'strokeWeight','routes_strokeWeight')"
                                      :value="settings_options.strokeWeight"

                    ></vue-range-slider>

                  </div>

                  <div class="route-setting_input-box">
                    <div style="display: flex"><span><translate-component
                        :text="lang.route_settings_component.stroke_Border_Color"/></span>
                      <span class="color-chacker" @click="toggleBorderColor"
                            :style="{'backgroundColor': settings_options.colorpicker.hex}"></span></div>


                    <compact-picker
                        v-show="isOpen"
                        :palette="color_pallete"

                        @input="getColor($event,'colorpicker','routes_strokeBorderColor')"

                        :value="settings_options.colorpicker"


                    ></compact-picker>
                  </div>


                  <div class="route-setting_input-box">
                    <span> <translate-component :text="lang.route_settings_component.strokeBorderOpacity"/></span>
                    <div class="route-setting_input-helper">
                      <span class="min">0</span>
                      <span class="max">10</span>
                    </div>
                    <vue-range-slider :step="step" :min="0" :max="10"
                                      @input="getSelect($event,'strokeBorderOpacity','routes_strokeBorderOpacity')"
                                      :value="settings_options.strokeBorderOpacity"

                    ></vue-range-slider>

                  </div>
                </div>

              </div>
              <div  key="4" class="route-setting_item"  v-if="page===4">
                <div class="left-pane-padding">
                  <div class="route-setting_input-box">
                    <div style="display: flex">
                      <span><translate-component
                          :text="lang.route_settings_component.marker_color"/>
                    </span>
                      <span class="color-chacker" @click="toggleMarkerColor"
                            :style="{'backgroundColor': settings_options.route_marker_color.hex}">
                      </span>
                    </div>
                    <compact-picker
                        v-show="isMarkerOpen"
                        :palette="color_pallete_marker"
                        @input="getColor($event,'route_marker_color','route_marker_color')"
                        :value="settings_options.route_marker_color"

                    ></compact-picker>
                  </div>
                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" id="mapShowAdditionalInfo" class="switch_1"
                             @change="getSelect($event,'mapShowAdditionalInfo','mapShowAdditionalInfo')"
                             :checked="settings_options.mapShowAdditionalInfo == 1 ? 1 :0"
                      />
                    </div>
                    <span>
                    <translate-component :text="lang.route_settings_component.show_additional_info"/>
                    </span>
                  </div>
                  <div class="route-setting_input-box checkbox-group">
                    <div class="switch_box box_1">
                      <input type="checkbox" id="mapShowMiniMap" class="switch_1"
                             @change="getSelect($event,'mapShowMiniMap','mapShowMiniMap')"
                             :checked="settings_options.mapShowMiniMap == 1 ? 1 :0"
                      />
                    </div>
                    <span>
                    <translate-component :text="lang.route_settings_component.use_minimap"/>
                      </span>
                  </div>
                </div>
              </div>
            </transition-group>
            </perfect-scrollbar>
          </div>
        </div>

      </template>
    </div>
  </section>
</template>
<script>

import TranslateComponent from "@/components/dashboard/TranslateComponent";
import SvgPathIcon from "@/components/ui/icons/svgPathIcon";
import SvgPlayerIcon from "@/components/ui/icons/svgPlayerIcon";
import SvgRoueViewIcon from "@/components/ui/icons/svgRoueViewIcon";
import SvgMapIcon from "@/components/ui/icons/svgMapIcon";
import {mapGetters} from "vuex";
import helpers from "@/mixins/helpers";
import Popper from 'vue-popperjs';
import VueRangeSlider from 'vue-range-component'
import {Compact} from 'vue-color'
import SvgSettingsIcon from "@/components/ui/icons/svgSettingsIcon";
import SvgSettingMono from "@/components/ui/icons/svgSettingMono";
import axios from "axios";

export default {
  name: "RouteSettingsComponent",
  components: {
    SvgSettingMono,
    SvgSettingsIcon,
    SvgMapIcon,
    SvgRoueViewIcon,
    SvgPlayerIcon,
    SvgPathIcon,
    TranslateComponent,
    'popper': Popper,
    VueRangeSlider,
    'compact-picker': Compact
  },
  mixins: [helpers],
  data() {
    return {
      suppressScrollX:true,
      show_showTimePointsEnabled: false,
      isMarkerOpen: false,
      help_counter: 0,
      tooltipMerge: false,
      show_content: true,
      step: 0.1,
      isStrokeOpen: false,
      border_opacity_max: 1,
      isOpen: false,
      isArrowOpen: false,
      add_color: {},
      one_colors: "#16a765",
      color_pallete_marker: [
        '#2979FF', '#42D692', '#FFFF99',
        '#FFB399', '#323232', '#FA573C',
        '#795548', '#9575CD', '#AFB42B',
        '#4FC3F7', '#F57C00', '#B2FF59',
        '#BF360C', '#90A4AE'

      ],
      color_pallete: [
        '#16a765', '#ac725e', '#d06b64', '#FFFF99', '#f83a22',
        '#fa573c', '#ff7537', '#ffad46', '#42d692', '#7bd148',
        '#b3dc6c', '#fbe983', '#fad165', '#92e1c0', '#9fe1e7',
        '#9fc6e7', '#4986e7', '#9a9cff', '#b99aff', '#c2c2c2',
        '#cabdbf', '#cca6ac', '#f691b2', '#cd74e6', '#a47ae2',
        '#000000', '#FFFFFF', '#39888E', '#78B13A', '#1E77B1',
        '#FF6633', '#FFB399', '#FF33FF', '#FF4D4D', '#00B3E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#E6FF80', '#1AFF33', '#999933'],
      range_min: 0,
      range_max: 150,
      enableCross: false,
      base_url: base_url,
      backgroundImageUrl: '/tracking/assets/images/geo-zone/image_riga_600.jpg',
      page: 1,
      stop_time: [1, 2, 5, 10, 15, 20, 25, 30],
      allowable_distance: [0, 50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000, 2000, 3000],
      min_distance: [0, 5, 10, 15, 20, 30, 50, 100],
      settings_options: {},
      resize_count: 1000000,
      mouse_up: 0,
    }
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      settings_option: 'SETTINGS_OPTIONS',
      settings_active: 'SETTINGS_ACTIVE',
      user_route_settings: 'USER_ROUT_SETTINGS',
      stop_markers: 'STOP_MARKERS',
      history_time: 'HISTORY_TIME',
      history_array: 'HISTORY_ARRAY',
      default_params: 'DEFAULT_PARAMS',
      active_route: "ACTIVE_ROUTE",
      settings_fuel_active: 'SETTINGS_FUEL_ACTIVE',
      select_fuel: 'SELECT_FUEL',
    }),

  },
  mounted() {

    let $this = this

      if (this.active_route === 'fuel2') {
        if ($this.settings_option[$this.settings_fuel_active]) {
          if ($this.checkObjectLength($this.settings_option[$this.settings_fuel_active])) {
            $this.settings_options = {...$this.settings_option[$this.settings_fuel_active]}
          } else {

          }
        }
      } else {
        if ($this.settings_option[$this.settings_active]) {
          if ($this.checkObjectLength($this.settings_option[$this.settings_active])) {
            $this.settings_options = {...$this.settings_option[$this.settings_active]}
          } else {

          }
        }
      }
  },
  methods: {
    getObjectName(){
      let add = ''
      if(this.select_fuel['Number']){
        add = this.select_fuel['Number']
      }

     return add
    },
    moseUpCounter() {
      this.mouse_up = 1
    },

    toggleMarkerColor() {
      this.isMarkerOpen = !this.isMarkerOpen
    },
    changeMap() {
      this.resize_count++
      this.$store.commit('SET_RELOAD_MAP', this.resize_count)
    },
    getColor(event, option_type, save_type = null) {
      let val_add = event
      this.settings_options[option_type] = val_add
      let add = {...this.settings_option}

      if (this.active_route === 'fuel2') {
        add[this.settings_fuel_active][option_type] = val_add
      } else {
        add[this.settings_active][option_type] = val_add
      }

      this.$store.commit('SET_SETTINGS_OPTIONS', add);
      let new_value = val_add.hex.substring(1)
      this.sendSettings(new_value, save_type)
    },
    getSelect(event, option_type, save_type = null) {
      let val_add = ''
      if (!isNaN(event) || Array.isArray(event)) {
        val_add = event
      } else {
        val_add = event.target.value
      }
      let old_value = this.settings_options[option_type]
      if (event.target) {
        if (event.target.className === 'switch_1') {
          if (event.target.checked === true) {
            val_add = 1

          } else {
            val_add = 0

          }
        }
      }

      let new_value = val_add

      if (option_type === 'routes_speed'
      ) {

        if (new_value[0] != old_value[0]) {
          this.sendSettings(new_value[0], 'routes_minSpeed')

        }
        if (new_value[1] != old_value[1]) {
          this.sendSettings(new_value[1], 'routes_maxSpeed')
        }
        if (new_value[1] != old_value[1] || new_value[0] != old_value[0]) {
          this.settings_options[option_type] = val_add
          let add = {...this.settings_option}
          if (this.active_route === 'fuel2') {
            add[this.settings_fuel_active][option_type] = val_add
          } else {
            add[this.settings_active][option_type] = val_add
          }

          this.$store.commit('SET_SETTINGS_OPTIONS', add);

        }
      } else {
        if (new_value != old_value) {
          this.settings_options[option_type] = val_add
          let add = {...this.settings_option}
          if (this.active_route === 'fuel2') {
            add[this.settings_fuel_active][option_type] = val_add
          } else {
            add[this.settings_active][option_type] = val_add
          }


          this.$store.commit('SET_SETTINGS_OPTIONS', add);
          this.sendSettings(new_value, save_type)

          if (option_type === 'mapShowAdditionalInfo') {
            let $this = this
            setTimeout(function () {
              $this.changeMap()
            }, 200);
          }
        }


      }
    },
    sendSettings(value, params) {
      let $this = this
      let formData = new FormData();
      let active_obj = this.settings_active
      if (this.active_route === 'fuel2') {
        active_obj = this.settings_fuel_active
      }

      formData.append("object_id", active_obj)
      formData.append("parameters[0][0]", params)
      formData.append("parameters[0][1]", value)

      axios.post(base_url + '/objects/save-parameters', formData).then(res => {

      })
    },
    hideContent() {
      this.show_content = !this.show_content
    },
    toggleStrokeColor() {
      this.isStrokeOpen = !this.isStrokeOpen
    },
    toggleBorderColor() {
      this.isOpen = !this.isOpen

    },
    getColorBorder() {
      this.settings_options.colorpicker = this.add_color.hex8
    },
    closeModal() {
      this.$store.commit('SET_SETTINGS_SHOW', false);
    },
    changePage(num) {
      this.page = num
    },
    addDefault() {
      let $this = this
      let active_obj = this.settings_active
      if (this.active_route === 'fuel2') {
        active_obj = this.settings_fuel_active
      }

      let add = {...this.settings_option}
      add[active_obj] = {...this.default_params}
      this.settings_options = {...this.default_params}
      this.$store.commit('SET_SETTINGS_OPTIONS', add);
      this.help_counter++;
      let options = this.settings_options
      let formData2 = new FormData();
      formData2.append("object_id", active_obj)
      let arr = ['routes_strokeOpacity', 'routes_strokeWeight', 'routes_strokeColor',
        'routes_showTimePointsEnabled', 'routes_showTimePointsMinutes', 'routes_showStopMarkers',
        'routes_combineStopMessages', 'reports_chkByIgnition', 'reports_minStopTime',
        'reports_maxStopDistance', 'reports_skipMinDistance', 'routes_speedLimit',
        'routes_chkSpeedLimit', 'routes_chkPlayer', 'routes_showSpeedometer',
        'routes_catchMarker', 'routes_autostart', 'routes_minSpeed',
        'routes_maxSpeed', 'routes_strokeBorderOpacity', 'routes_strokeSmooth', 'routes_timePointsValue',
        'routes_strokeBorderColor', 'routes_show_direction', 'routes_color_direction', 'mapShowAdditionalInfo', 'route_marker_color']

      let arr_2 = [
        options.strokeOpacity, options.strokeWeight, options.stroke_colorpicker.hex.substring(1),
        options.showTimePointsEnabled, options.showTimePointsMinutes, 1,
        1, options.chkByIgnition, options.minStopTime,
        options.maxStopDistance, options.skipMinDistance, options.speedLimit,
        options.use_speed_limit, options.chkPlayer, options.show_speedometer,
        options.catch_marker, 0, options.routes_speed[0],
        options.routes_speed[1], options.strokeBorderOpacity, options.strokeSmooth, 6,
        options.colorpicker.hex.substring(1), options.use_arrow_show, options.arrow_colorpicker.hex.substring(1),
        options.mapShowAdditionalInfo, options.route_marker_color.hex.substring(1)

      ]
      for (let i = 0; i < arr.length; i++) {

        formData2.append("parameters[" + i + "][0]", arr[i])
        formData2.append("parameters[" + i + "][1]", arr_2[i])
      }
      axios.post(base_url + '/objects/save-parameters', formData2).then(response3 => {

      })

    }


  }
}
</script>

<style scoped>
.object-info-more-list {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  max-height: 200px;
  overflow-y: auto;
}

.object-info-more-item {
  font-size: 12px;
  line-height: 18px;
}

.object-info-more-key {
  margin-right: 10px;
}

.card.object-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;
  max-width: 350px;
  height: auto;
  max-height: calc(100% - 80px);
  position: fixed;
  background-color: #fff;
  padding: 0;
  margin: 0;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.widgets-panel__header {
  position: relative;
  height: 120px;
  padding: 0;
  margin: 0;
}

.widgets-panel__tracker-title {
  color: #fff !important;
  display: flex;
  justify-content: start;
  align-items: center;
  padding-left: 16px;
  width: 100%;
  z-index: 2;
  font-size: 20px;
  font-weight: bolder;
  text-shadow: 1px 1px #000;
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(transparent));
  background: linear-gradient(rgba(0, 0, 0, 0.5), transparent);

}

.settin-title-box span {
  padding-left: 10px;
}

.street-view__streetview, .street-view__streetview img {
  border-radius: 4px 4px 0px 0px;
  background-size: cover;
  -webkit-filter: blur(2px);
  filter: blur(2px);
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
}

.street-view__click-stub {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  cursor: pointer;
  font-size: 16px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.widgets-panel .widgets-panel__street-view {
  height: 100px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.widgets-panel__street-view, .widgets-panel__tracker-title {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.object-info-close {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  padding: 0;
  font-size: 20px;
  color: #ccc;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  border-radius: 50%;
  align-items: center;
}

.object-info-close:hover, .object-info-close:focus {
  color: #ffff
}

.list-group-icon {
  width: 25px;
  min-width: 25px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
}

.list-group-icon svg {
  fill: #4cabdb;
}

.list-group-item {
  display: flex;
  align-items: center;
}

.info-list-item {
  font-weight: 600;
  line-height: 18px;
  color: #34495e;
}

.info-list-title {
  line-height: 18px;

}

.info-list-item_label {
  border: 1px solid #4cabdb;
  padding: 2px 10px;
  border-radius: 15px;
  margin-left: 5px;
}

.card-body.bject-info {
  padding: 20px 20px;
  padding-top: 0;
}

.list-group-item {
  padding: 8px 15px;
}

.history-setting-section {
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  padding: 0;
  background: #fff;
  width: 100%;
  max-width: 800px;
  height: auto;
  max-height: 90vh;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}

.testanim-enter-active, .testanim-leave-active {
  transition: all .5s;
}

.testanim-enter, .testanim-leave-to {
  transform: translateX(1rem);
  opacity: 0;
}

.testanim-leave-active {
  position: absolute;
}

.testanim-move {
  transition: all .5s;
}

.route-setting_box {
  width: 100%;
  height: 100%;
}

.route-setting_list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.route-setting_list li {
  width: 25%;
  padding: 0;
  margin: 0;
  padding: 5px;
}

.route-setting_page-btn {
  white-space: normal;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0 , 0.20), 0px 6px 10px 0px rgba(0, 0, 0 , 0.14), 0px 1px 18px 0px rgba(0, 0, 0 , 0.12);
  border-radius: 4px;
  min-width: 0;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  border: none;
  padding: 4px 5px;
  font-weight: 600;
}

.route-setting_page-btn.active {
  color: #1C71DA;
  background: rgba(0, 136, 204, 0.3);
}

.route-setting_page-btn svg {
  width: 25px;
  margin-right: 5px;
}

.route-setting_page-btn svg {
  fill: #1C71DA;
}


.route-setting_page-btn:hover, .route-setting_page-btn:focus {
  background-color: #dcdcdc;
}

.route-setting_page-btn img {
  width: 25px;
}

.object-list-title {
  margin-top: 0;
  margin-bottom: 0;
}

.route-setting_item {
  padding: 10px 10px;
  max-height: 50vh;
  min-height: 50vh;
  padding-top: 40px;
  padding-right: 20px;
}


select {
  width: 100%;
}

.route-setting_input-box {
  margin-bottom: 15px;
}

.route-setting_input-box span {
  font-weight: 600;
  font-size: 14px;
}

.switch_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 100px;
  min-width: 100px;
  height: auto;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

/* Switch 1 Specific Styles Start */

.box_1 {
  background: transparent;
}

input[type="checkbox"].switch_1 {
  font-size: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 2.5em;
  height: 1.0em;
  background: #ddd;
  border-radius: 3em;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

input[type="checkbox"].switch_1:checked {
  background: #0ebeff;
}

input[type="checkbox"].switch_1:after {
  position: absolute;
  content: "";
  width: 1.0em;
  height: 1.0em;
  border-radius: 50%;
  background: #fff;
  -webkit-box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
  box-shadow: 0 0 .25em rgba(0, 0, 0, .3);
  -webkit-transform: scale(.7);
  transform: scale(.7);
  left: 0;
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
}

input[type="checkbox"].switch_1:checked:after {
  left: calc(100% - 1em);
}

.route-setting_input-box.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group span {
  padding-top: 15px;
}

.route-setting_input-helper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.route-setting_input-helper .min, .route-setting_input-helper .max {
  border-radius: 3px;
  background-color: #ddd;
  padding: 2px 5px;
}

.color-chacker {
  width: 25px;
  height: 25px;
  border-radius: 3px;
  margin-left: 10px;
  display: block;
  cursor: pointer;
  border: 1px solid #cecece;
}

.street-view__click-stub .hide-button {
  top: 40px;
}

.object-info-box.hide-object-wrapper {
  max-width: 100px;
  height: 100px;
  width: 100px;

}

.hide-object-wrapper .card.object-info {
  min-width: 100px;
  max-width: 100px;
}

.hide-object-wrapper .object-info-close {
  display: none;
}

.hide-object-wrapper .widgets-panel__header {
  height: 100px;
}

.hide-object-wrapper .widgets-panel__tracker-title {
  font-size: 14px;
  display: none;
}

.hide-object-wrapper .street-view__click-stub .hide-button {
  top: 10px;
}

.hide-object-wrapper .street-view__click-stub {
  width: 1px;
}

.hide-object-wrapper .street-view__streetview, .street-view__streetview img {
  display: none;
}

.hide-object-wrapper .card.object-info {
  min-width: 1px;
  max-width: 1px;
  max-height: 1px;
}

@media only screen and (max-width: 1500px) {
  .route-setting_input-box span {
    font-weight: 600;
    font-size: 12px;
  }

  .switch_box {
    display: flex;
    max-width: 80px;
    min-width: 80px;
    height: auto;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .card.object-info {
    min-width: 280px;
    max-width: 300px;
  }

  .widgets-panel__header {
    height: 110px;
  }

  .street-view__streetview, .street-view__streetview img {
    height: 110px;
  }

  .list-group-item {
    padding: 5px 8px;
  }

  .list-group-icon {
    width: 20px;
    min-width: 20px;
    height: 20px;
  }

  .route-setting_list {
    padding-top: 10px;
  }

  .route-setting_item {
    max-height: 50vh;
    min-height: 50vh;
  }

  .route-setting_input-box select {
    border: 1px solid #E5E7E9;
    border-radius: 6px;
    height: 40px;
    padding: 8px;
    outline: none;
  }

  .route-setting_input-box {
    margin-bottom: 10px;
  }

  input[type="checkbox"].switch_1 {
    width: 2.2em;
    height: .8em;
  }

  input[type="checkbox"].switch_1:after {
    width: 0.8em;
    height: 0.8em;
  }

  input[type="checkbox"].switch_1:checked:after {
    left: calc(100% - 0.8em);
  }

}

.settin-title-box {
  font-size: 16px;
}

.remove-default-btn {
  position: absolute;
  right: 5px;
  bottom: 10px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  padding: 3px;
  font-size: 12px;
  font-weight: 600;
  background-color: #f5f5f5;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.remove-default-btn:hover, .remove-default-btn:focus {

  background-color: #ffffff;
}
.ps {
  height: 50vh;
}
@media only screen and (max-width: 1500px) {
  .card.object-info {
    box-shadow: rgba(99, 99, 99, 0.4) 2px 4px 8px 0px;
  }
  .ps {
    height: 50vh;
  }
}

@media only screen and (max-width: 600px) {

  .object-info-box {
    right: 0;
    padding: 0;
    margin: 0;
    bottom: 0;
    max-width: 100vw;
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 100000;
    top: 60px;
    left: 0px;
  }

  .card.object-info{
    min-width: 100vw;
    max-width: 100vw;
    height: 100%;
    max-height: calc(100% - 60px);
  }

  .ps {
    height: 60vh;

  }
  .route-setting_item{
    height: 60vh;
    max-height: 60vh;
    padding-top: 20px;
    padding-right: 60px;
  }
}
</style>
