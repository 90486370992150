<template>
      <section class="body">
        <template >
          <section class="center-body loading-main-wrapper"  v-if="loading_page">
            <div class="center-box-sign">
              <ring-loader :height="loading_size"/>
            </div>
          </section>
        </template>

        <header class="header">
          <div class="logo-container">


            <a href="" class="logo">
<svg-logo-dark></svg-logo-dark>
            </a>
            <button  @click="changeSiteBar()"   class="btn btn-defaultvisible-xs toggle-sidebar-left ">
              <i class="fa fa-bars" aria-label="Toggle sidebar"></i>
            </button>
          </div>

          <div class="header-right">
            <language-component ></language-component>

            <span class="separator"></span>
            <ul class="notifications event-notifications">
              <li :class="showNotifications ? 'open' : ''">
                <span style="display: none">{{alert_counter}}</span>
                <a  class=" notification-icon" @click="showNotifications =!showNotifications">
                  <i class="fa fa-bell" :class="aletr_change ?'note-alert' : ''"></i>
                  <span class="badge">{{Object.keys(alertIds).length}}</span>
                </a>
                <div class="dropdown-menu notification-menu">
                  <div class="notification-title">
                    <span class="pull-right label label-default">{{Object.keys(alertIds).length}}</span>
                    <translate-component :text="lang.main_component.events_alert"/>
                  </div>
                  <perfect-scrollbar>
                  <div class="content">
                    <ul>
                      <template v-for="alert in alertIds">
                      <li :key="alert.id">
                        <div class="clearfix  alert-box">
                          <button class="btn alert-btn" @click="logAccept(alert.id)">
                            <span class="label label-success">Accept</span>
                          </button>
                          <div class="alert-body">
                          <span class="label label-default" v-if="alert.name" style="margin-left: 5px">{{alert.name}}</span>
                          <span class="title">{{alert.message}}   </span>
                          <span class="message"><translate-component :text="lang.events_component['type_'+alert.type]" />
                          <span class="alert-number" v-if="alert.number" style="margin-left: 5px">{{checkName(alert.number)}}</span>
                          </span>
                          </div>
                        </div>
                      </li>
                      </template>
                    </ul>
                    <hr>
                    <div class="text-right">
                      <router-link :to="{ name: 'Events'}" class-active="view-more"  >View All</router-link>
                    </div>
                  </div>
                  </perfect-scrollbar>
                </div>

              </li>
            </ul>
            <div id="userbox" class="userbox">
              <a href="#" data-toggle="dropdown">
                <div class="profile-info" >
                  <clock-component></clock-component>
                  <span class="logout-box" @click="logOut()"><i class="fa fa-power-off"></i>
                    <translate-component :text="lang.main_component.logout_name"/>
                  </span>
                </div>
              </a>

            </div>
          </div>

        </header>


        <div class="inner-wrapper">
          <aside id="sidebar-left" class="sidebar-left">

            <div class="sidebar-header">
              <div class="sidebar-title">
                <translate-component :text="lang.main_component.navigation"/>
                 <span style="display: none">{{help_counter}}</span>
              </div>
              <div class="sidebar-toggle hidden-xs" data-toggle-class="sidebar-left-collapsed" data-target="html"
               @click="changeSiteBar()"   data-fire-event="sidebar-left-toggle">
                <i class="fa fa-bars" aria-label="Toggle sidebar"></i>
              </div>
            </div>
            <div class="nano">
              <div class="nano-content">
                <nav id="menu" class="nav-main" role="navigation">
                  <ul class="nav nav-main">
                    <li class="nav-active">
                      <router-link :to="{ name: 'Routes'}" class-active="active" v-on:click.native="hideSiteBar()">
                        <i class="fas fa-route" aria-hidden="true" >
                          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
                               data-icon="route" class="svg-inline--fa fa-route fa-w-16" role="img" viewBox="0 0 512 512">
                            <path fill="currentColor"
                                  d="M416 320h-96c-17.6 0-32-14.4-32-32s14.4-32 32-32h96s96-107 96-160-43-96-96-96-96 43-96 96c0 25.5 22.2 63.4 45.3 96H320c-52.9 0-96 43.1-96 96s43.1 96 96 96h96c17.6 0 32 14.4 32 32s-14.4 32-32 32H185.5c-16 24.8-33.8 47.7-47.3 64H416c52.9 0 96-43.1 96-96s-43.1-96-96-96zm0-256c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zM96 256c-53 0-96 43-96 96s96 160 96 160 96-107 96-160-43-96-96-96zm0 128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z"/>
                          </svg>
                        </i>
                        <span>
                          <translate-component :text="lang.main_component.routes"/>

                        </span>
                      </router-link>
                    </li>
                    <template v-if="current_resources && current_resources[170] && current_resources[170].enabled ===true">
                    <li class="nav-active">
                      <router-link :to="{ name: 'Fuel'}" class-active="active"  v-on:click.native="hideSiteBar()" >
                        <i class="fas fa-route" aria-hidden="true">
                          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gas-pump" class="svg-inline--fa fa-gas-pump fa-w-16" role="img" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M336 448H16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h320c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm157.2-340.7l-81-81c-6.2-6.2-16.4-6.2-22.6 0l-11.3 11.3c-6.2 6.2-6.2 16.4 0 22.6L416 97.9V160c0 28.1 20.9 51.3 48 55.2V376c0 13.2-10.8 24-24 24s-24-10.8-24-24v-32c0-48.6-39.4-88-88-88h-8V64c0-35.3-28.7-64-64-64H96C60.7 0 32 28.7 32 64v352h288V304h8c22.1 0 40 17.9 40 40v27.8c0 37.7 27 72 64.5 75.9 43 4.3 79.5-29.5 79.5-71.7V152.6c0-17-6.8-33.3-18.8-45.3zM256 192H96V64h160v128z"/></svg>

                        </i>
                        <span>
                          <translate-component :text="lang.main_component.fuel"/>
                        </span>
                      </router-link>
                    </li>
                    </template>
                    <template v-if="current_resources && current_resources[30] && current_resources[30].enabled ===true">
                    <li class="nav-active" >
                      <a  class="router-link-active" :class="show_reports_list ? 'router-link-exact-active' : ''"  @click="getReportList()">
                        <i class="fas fa-route" aria-hidden="true">

                          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="far" data-icon="flag" class="svg-inline--fa fa-flag fa-w-16" role="img" viewBox="0 0 512 512"><path fill="currentColor" d="M336.174 80c-49.132 0-93.305-32-161.913-32-31.301 0-58.303 6.482-80.721 15.168a48.04 48.04 0 0 0 2.142-20.727C93.067 19.575 74.167 1.594 51.201.104 23.242-1.71 0 20.431 0 48c0 17.764 9.657 33.262 24 41.562V496c0 8.837 7.163 16 16 16h16c8.837 0 16-7.163 16-16v-83.443C109.869 395.28 143.259 384 199.826 384c49.132 0 93.305 32 161.913 32 58.479 0 101.972-22.617 128.548-39.981C503.846 367.161 512 352.051 512 335.855V95.937c0-34.459-35.264-57.768-66.904-44.117C409.193 67.309 371.641 80 336.174 80zM464 336c-21.783 15.412-60.824 32-102.261 32-59.945 0-102.002-32-161.913-32-43.361 0-96.379 9.403-127.826 24V128c21.784-15.412 60.824-32 102.261-32 59.945 0 102.002 32 161.913 32 43.271 0 96.32-17.366 127.826-32v240z"/></svg>

                        </i>
                        <span>
                          <translate-component :text="lang.main_component.reports"/>
                        </span>
                      </a>
                    </li>
                    </template>
                    <template v-if="current_resources && current_resources[120] && current_resources[120].enabled ===true">
                    <li class="nav-active">
                      <router-link :to="{ name: 'Events'}" class-active="active"  v-on:click.native="hideSiteBar()" >
                        <i class="fas fa-route" aria-hidden="true">
                          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="gas-pump" class="svg-inline--fa fa-gas-pump fa-w-16" role="img"  viewBox="0 0 448 512">
                            <path fill="currentColor" d="M160 32V64H288V32C288 14.33 302.3 0 320 0C337.7 0 352 14.33 352 32V64H400C426.5 64 448 85.49 448 112V160H0V112C0 85.49 21.49 64 48 64H96V32C96 14.33 110.3 0 128 0C145.7 0 160 14.33 160 32zM0 192H448V464C448 490.5 426.5 512 400 512H48C21.49 512 0 490.5 0 464V192zM328.1 304.1C338.3 295.6 338.3 280.4 328.1 271C319.6 261.7 304.4 261.7 295 271L200 366.1L152.1 319C143.6 309.7 128.4 309.7 119 319C109.7 328.4 109.7 343.6 119 352.1L183 416.1C192.4 426.3 207.6 426.3 216.1 416.1L328.1 304.1z"/></svg>
                        </i>
                        <span>
                           <translate-component :text="lang.events_component.events_name"/>
                        </span>
                      </router-link>
                    </li>
                    </template>
                    <template v-if="current_resources && current_resources[520] && current_resources[520].enabled ===true">
                    <li class="nav-active">
                      <router-link :to="{ name: 'Management'}" class-active="active"  v-on:click.native="hideSiteBar()" >
                        <i class="fas fa-route" aria-hidden="true">
                        <svg-managment-icon/>

                        </i>
                        <span>
                           <translate-component :text="lang.events_component.management_name"/>
                        </span>
                      </router-link>
                    </li>
                    </template>
                    <template v-if="current_resources && current_resources[320] && current_resources[320].enabled ===true">
                    <li class="nav-active">
                      <router-link :to="{ name: 'Tasks'}" class-active="active"  v-on:click.native="hideSiteBar()" >
                        <i class="fas fa-route" aria-hidden="true">
                         <svg-tasks-icon/>
                        </i>
                        <span>
                           <translate-component :text="lang.task_component.title"/>
                        </span>
                      </router-link>
                    </li>
                    </template>
                    <template v-if="current_resources && current_resources[290] && current_resources[290].enabled ===true">
                    <li class="nav-active">
                      <router-link :to="{ name: 'UserManager'}" class-active="active"  v-on:click.native="hideSiteBar()" >
                        <i class="fas fa-route" aria-hidden="true">
                         <svg-usermanagment-icon/>
                        </i>
                        <span>
                           <translate-component :text="lang.user_component.title"/>
                        </span>
                      </router-link>
                    </li>
                    </template>
                    <template v-if="current_resources && current_resources[190] && current_resources[190].enabled ===true">
                    <li class="nav-active">
                      <router-link :to="{ name: 'GroupManager'}" class-active="active"  v-on:click.native="hideSiteBar()" >
                        <i class="fas fa-route" aria-hidden="true">
                       <svg-group-icon/>
                        </i>
                        <span>
                           <translate-component :text="lang.group_component.title"/>
                        </span>
                      </router-link>
                    </li>
                    </template>


                      <template v-if="current_resources && current_resources[190] && current_resources[190].enabled ===true">
                          <li class="nav-active">
                              <router-link :to="{ name: 'ShareManager'}" class-active="active"  v-on:click.native="hideSiteBar()" >
                                  <i class="fas fa-route" aria-hidden="true">
                                   <svg-share-icon/>
                                  </i>
                                  <span>
                           <translate-component :text="lang.share_component.title"/>
                        </span>
                              </router-link>
                          </li>
                      </template>

                    <template v-if="current_resources && current_resources[310] && current_resources[310].enabled ===true">
                    <li class="nav-active">
                      <router-link :to="{ name: 'EngineLogs'}" class-active="active"  v-on:click.native="hideSiteBar()" >
                        <i class="fas fa-route" aria-hidden="true">
                          <svg-engine-icon/>
                        </i>
                        <span>
                           <translate-component :text="lang.engine_component.title"/>
                        </span>
                      </router-link>
                    </li>
                    </template>

                    <li class="nav-active mobile-logout" >
                      <a  class="router-link-active" :class="show_reports_list ? 'router-link-exact-active' : ''" class-active="active" @click="logOut()">

                        <i class="fa fa-power-off"></i>
                        <span> <translate-component :text="lang.main_component.logout_name"/>
                        </span>
                      </a>
                    </li>




                  </ul>
                </nav>

              </div>

            </div>

          </aside>
          <section role="main" class="content-body">
            <div id ="main_wrapper" class="main-wrapper">
              <div class="main-blog" :class="showNotifications ? 'open' : ''">
                <div class="main-component-wrapper ">
                  <ObjectComponent :show_custom_panel="show_custom_panel"></ObjectComponent>
                  <router-view></router-view>

                  <report-daily-component v-if="show_reports_list" ></report-daily-component>
                  <transition name="fade">
                    <route-settings-component v-if="settings_show && (table_show || active_route==='routing')"></route-settings-component>
                  </transition>
                  <transition name="fade">
                    <object-info-component v-if="show_object"></object-info-component>
                  </transition>
                  <transition name="fade">
                    <template v-if="current_resources && current_resources[11] && current_resources[11].enabled ===true">
                    <geo-zone-info-component v-if="show_zone_info"></geo-zone-info-component>
                    </template>
                  </transition>
                  <transition name="fade">
                    <reports-settings-component v-if="show_reports_settings"></reports-settings-component>
                  </transition>

                </div>

                <weather-component v-if="weather_object"></weather-component>
                <map-component  :fuel_type ="active_route" :show_toolbars="show_toolbars" ></map-component>
                <transition name="fade">
                  <template v-if="current_resources && current_resources[11] && current_resources[11].enabled ===true">
                  <geo-zone-component v-if="show_geo_zone"></geo-zone-component>
                  </template>
                </transition>
                <div class="fuel2-sub-big-box" v-if="show_graph && active_route==='fuel2'">
                  <fuel-chart-component :fuel_type ="fuel_type" v-if="select_fuel_data[settings_fuel_active]"></fuel-chart-component>
                </div>

              </div>
               <template v-if="active_route==='routing' && history_data[settings_active]">
              <geoparams-sitebar-component v-if="select_history && history_table_show && history_time[settings_active] && (settings_options[settings_active].mapShowAdditionalInfo !=0)"></geoparams-sitebar-component>
               </template>

              <template v-if="table_show  &&   select_fuel_data[settings_fuel_active] && active_route==='fuel2'">
                <fuel-table-component :fuel_type ="fuel_type" :ps_style="ps_style"  />
              </template>
              <history-table-component v-if="select_history && history_table_show && history_time[settings_active]"></history-table-component>
            </div>

          </section>

        </div>


<template v-if="vehicle_settings">
  <vehicle-component/>
</template>
      </section>
</template>
<script>

import LanguageComponent from './components/dashboard/LanguageComponent.vue'
import {mapGetters} from "vuex";
import ClockComponent from "@/components/dashboard/ClockComponent";
import helpers from "@/mixins/helpers";
import axios from "axios";
import SvgLogoGeoloc from "@/components/ui/icons/svgLogoGeoloc";
import SvgLogoDark from "@/components/ui/icons/svgLogoDark";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import MapComponent from "@/components/map/MapComponent";
import GeoparamsSitebarComponent from "@/components/routes/GeoparamsSitebarComponent";
import FuelTableComponent from "@/components/fuel/FuelTableComponent";
import FuelChartComponent from "@/components/fuel/FuelChartComponent";
import WeatherComponent from "@/components/dashboard/WeatherComponent";
import GeoZoneComponent from "@/components/geozone/GeoZoneComponent";
import HistoryTableComponent from "@/components/routes/historyTableComponent";
import ObjectInfoComponent from "@/components/objects/ObjectInfoComponent";
import GeoZoneInfoComponent from "@/components/geozone/GeoZoneInfoComponent";
import ObjectComponent from "@/components/objects/ObjectComponent";
import RouteSettingsComponent from "@/components/routes/RouteSettingsComponent";
import ReportsSettingsComponent from "@/components/reports/ReportsSettingsComponent";
import ReportDailyComponent from "@/components/reports/ReportDailyComponent";
import RingLoader from 'vue-spinner/src/RingLoader.vue';
import VehicleComponent from "@/components/objects/VehicleComponent";
import EventsComponent from "@/components/events/EventsComponent";
import GlobalSettingIcon from "@/components/ui/icons/GlobalSettingIcon";
import TasksComponent from "@/components/tasks/TasksComponent";
import SvgTasksIcon from "@/components/ui/icons/svgTasksIcon";
import SvgManagmentIcon from "@/components/ui/icons/svgManagmentIcon";
import SvgUsermanagmentIcon from "@/components/ui/icons/svgUsermanagmentIcon";
import SvgGroupIcon from "@/components/ui/icons/svgGroupIcon";
import SvgEngineIcon from "@/components/ui/icons/svgEngineIcon";
import SvgShareIcon from "@/components/ui/icons/search/svgShareIcon.vue";

export default {
  name: 'GeolocComponent',
  components: {
      SvgShareIcon,
    SvgEngineIcon,
    SvgGroupIcon,
    SvgUsermanagmentIcon,
    SvgManagmentIcon,
    SvgTasksIcon,
    TasksComponent,
    GlobalSettingIcon,
    EventsComponent,
    VehicleComponent,
    ReportDailyComponent,
    ReportsSettingsComponent,
    RouteSettingsComponent,
    ObjectComponent,
    HistoryTableComponent,
    GeoZoneComponent,
    WeatherComponent,
    FuelChartComponent,
    FuelTableComponent,
    GeoparamsSitebarComponent,
    MapComponent,
    TranslateComponent,
    SvgLogoDark,
    SvgLogoGeoloc,
    ClockComponent,
    LanguageComponent,
    ObjectInfoComponent,
    GeoZoneInfoComponent,
    RingLoader
  },
  mixins:[helpers],
  data() {
    return {
      alert_counter:0,
      aletr_change:false,
      alertIds:{},
      showNotifications:false,
      loading_size:"57px",
      loading_page:true,
      help_counter:0,
      show_custom_panel:false,
      ps_style:'height: 300px;',
      fuel_type:'fuel_2',
      show_toolbars:true,
      date_now: '',
      counter: 0,
      base_url: base_url,
      clientsdata: {},
      currentTime: null,
      currentDay: '',
      timer: null,
      event_timer: null,
      set_time: 5000,
      set_event_time: 10000,
      sitebar_open:false,
      default_params:{

        minStopTime: 5,
        maxStopDistance: 50,
        skipMinDistance: 10,
        chkByIgnition: 0,
        chkPlayer: 0,
        player_autostart: 0,
        catch_marker: 0,
        show_speedometer: 0,
        showTimePointsEnabled: 1,
        showTimePointsMinutes: 5,
        use_speed_limit: 1,
        use_arrow_show: 1,
        arrow_colorpicker: {
          "hex": "#3366E6",
          "source": "hex"
        },
        colorpicker: {
          "hex": "#16a765",
          "source": "hex"
        },
        speedLimit: 0,
        strokeBorderOpacity: 1,
        stroke_colorpicker: {
          "hex": "#fa573c",
          "source": "hex"
        },
        strokeOpacity: 0.5,
        strokeSmooth: 0,
        strokeWeight: 7,
        mapShowRegionBorders: false,
        mapShowAdditionalInfo: true,
        mapShowWeather: false,
        mapShowMiniMap: false,
        routes_speed: [0, 90],
        googleMapTypeId: "OpenStreetMap",
      },

    }
  },
  mounted() {

    let $this = this

    this.$store.dispatch('getObjectGroups');
    this.$store.dispatch('getGroupsList');

    this.getNewObject()

    if (this.checkObjectLength(this.user_route_settings)) {
    } else {

      let add = {...this.default_params}
      $this.$store.commit('SET_USER_ROUT_SETTINGS', add);

    }
    $this.getAlert()
    setTimeout(function () {
      $this.loading_page = false
      $this.readParams()
    }, 1000)
    $this.getEventsAlerts()

  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      objects: 'OBJECTS',
      polygon_zones: 'POLYGON_ZONES',
      select_history: 'SELECT_HISTORY',
      user_route_settings: 'USER_ROUT_SETTINGS',
      user_auth:'USER_AUTH',
      active_route:"ACTIVE_ROUTE",
      history_table_show:'HISTORY_TABLE_SHOW',
      history_time: 'HISTORY_TIME',
      settings_options: 'SETTINGS_OPTIONS',
      settings_active: 'SETTINGS_ACTIVE',
      table_show:'HISTORY_TABLE_SHOW',
      select_fuel_data:'SELECT_FUEL_DATA',
      settings_fuel_active: 'SETTINGS_FUEL_ACTIVE',
      show_graph:'SHOW_GRAPH',
      weather_object: 'WEATHER_OBJECT',
      show_geo_zone: 'SHOW_GEO_ZONE',
      history_data:'HISTORY_DATA',
      show_object: 'SHOW_OBJECT',
      show_zone_info: 'SHOW_ZONE_INFO',
      settings_show: 'SETTINGS_SHOW',
      showCalendar:'SHOW_CALENDAR_PANEL',
      calendar_date:'CALENDAR_DATES',
      show_reports_settings:'SHOW_REPORTS_SETTINGS',
      show_reports_list:'SHOW_REPORTS_LIST',
      reports_settings_params:'REPORTS_SETTINGS_PARAMS',
      vehicle_settings: 'VEHICLE_SETTINGS',
      current_resources:'CURRENT_RESOURCES',
    }),
  },
  watch: {
    objects(newVal, oldVal) {
      let _this = this;
      if (Object.keys(this.polygon_zones).length > 0) {

        let changes_polys = {...this.polygon_zones}

        Object.keys(changes_polys).forEach(function (key) {
          //console.log(key)
          if (changes_polys[key].monitoring_start && changes_polys[key].type !== 'marker') {

            let k = 0
            let check = []
            let poly = changes_polys[key]

            if (poly.type === 'polygon') {
              poly.latlngs[0].map(item => {
                check[k] = []
                check[k].push(item.lat)
                check[k].push(item.lng)
                k++;
              });
            }
            if (poly.type === 'rectangle') {
              poly.latlngs[0].map(item => {
                check[k] = []
                check[k].push(item.lat)
                check[k].push(item.lng)
                k++;
              });
            }
            let add_time = Date.now();
            let add_poly = {}
            newVal.map(item => {
              if (poly.type === 'polygon' || poly.type === 'rectangle') {

                let rezult = _this.isMarkerInsidePolygon([item.Y, item.X], check)

                add_poly = _this.helperAddEnter(rezult, poly, item, add_time)
              }
              if (poly.type === 'circle') {

                let rezult = _this.isMarkerInsideCircle(item.X, item.Y, poly.info.data.x, poly.info.data.y, poly.info.data.r)


                add_poly = _this.helperAddEnter(rezult, poly, item, add_time)

              }

            });

            changes_polys[key] = add_poly;
          }


        });

        this.$store.commit('SET_POLYGON_ZONES', changes_polys);

      }

    },

  },
  methods: {
    checkName(ObjectId){
      if (ObjectId === "MA-9779") {
        return 'demo_1'
      }
      if (ObjectId=== "RE-28") {
        return'demo_2'
      }
      if (ObjectId === "KF-150") {
        return 'demo_3'
      }
      return  ObjectId
    },
    getClientsCreateList(){

      let  $this =this;
      let formData = new FormData()
      formData.append("id", 0);
      axios.post(base_url + '/usermanager/getwebclient', formData).then(response => {

        if (response.data.success === true) {
          let arr_list = {};
          let resources = response.data.data.resources;
          resources.map(v => {
            arr_list[v.id] = v
          })


          $this.$store.commit('SET_CURRENT_RESOURCES',  arr_list);
        }
      }).catch(error => {
        console.log(error)
      })
    },
    readParams(){
      let formData = new FormData();
      let $this = this
      let i =0
      Object.keys($this.reports_settings_params).map(function(key, index) {
        formData.append("parameters["+i+"][0]", key)
        formData.append("parameters["+i+"][1]",  $this.reports_settings_params[key])
        i++
      })

      axios.post( base_url + '/index/read-user-parameters',formData).then(res => {
        if(res.data.success === true){
          let rezult = res.data.data
          $this.$store.commit('SET_REPORTS_SETTINGS_PARAMS',  rezult);
        }else{
        }

      }).catch(error => {
        console.log(error)
      })


      $this.$store.commit('SET_REPORTS_SETTINGS_CHECK',  1);

    },
    getReportList(){
      let add  = true
      if(this.show_reports_list === true) {
        add = false
      }
      this.$store.commit('SET_SHOW_REPORTS_LIST', add);
      this.sitebar_open =false;
      let root = document.getElementsByTagName( 'html' )[0];
      root.classList.add('sidebar-left-collapsed')
      this.help_counter++;

    },
    logOut(){
      axios.get( base_url + '/auth/logout').then(res => {
        location.reload();
      }).catch(error => {
        location.reload();

      })
    },
    hideSiteBar(){
      this.sitebar_open =false;
      let root = document.getElementsByTagName( 'html' )[0];
      root.classList.add('sidebar-left-collapsed')
      this.help_counter++;
    },
    changeSiteBar(){

      let root = document.getElementsByTagName( 'html' )[0];
      if(this.sitebar_open ===false){
        root.classList.remove('sidebar-left-collapsed')

        this.sitebar_open = true
      }else{
        root.classList.add('sidebar-left-collapsed')

        this.sitebar_open = false
      }
    },
    isMarkerInsidePolygon(point, vs) {

      let x = point[0], y = point[1];

      let inside = false;
      for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        let xi = vs[i][0], yi = vs[i][1];
        let xj = vs[j][0], yj = vs[j][1];

        let intersect = ((yi > y) != (yj > y))
            && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        if (intersect) inside = !inside;
      }

      return inside;
    },
    calculateDistance(lat1, lon1, lat2, lon2, unit) {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var radlon1 = Math.PI * lon1 / 180;
      var radlon2 = Math.PI * lon2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") {
        dist = dist * 1.609344;
      }
      if (unit == "N") {
        dist = dist * 0.8684;
      }
      return dist;
    },
    isMarkerInsideCircle(X, Y, cX, cY, R) {

      let distance = this.calculateDistance(X, Y, cX, cY, "N");
      if (distance * 1000 < R * 1000) {  // radius is in meter; distance in km
        return true
      } else {
        return false
      }

    },
    helperAddEnter(rezult, poly, item, add_time) {
      if (rezult === true) {
        if (!poly.monitoring[item.ObjectId]) {
          poly.monitoring[item.ObjectId] = []
          let add_obj = {
            'id': item.ObjectId, 'Number': item.Number, 'Driver_name': item.DriverName,'enter_time': add_time, 'out_time': false
          }
          poly.monitoring[item.ObjectId].push(add_obj)
        } else {
          if(poly.monitoring[item.ObjectId][0].out_time !== false){
            poly.monitoring[item.ObjectId][0].hidtory=[]
            poly.monitoring[item.ObjectId][0].hidtory.push( {'enter_time':  poly.monitoring[item.ObjectId][0].enter_time, 'out_time': poly.monitoring[item.ObjectId][0].out_time} )
            poly.monitoring[item.ObjectId][0].enter_time = add_time
            poly.monitoring[item.ObjectId][0].out_time = false
          }
        }

      } else {
        if (poly.monitoring[item.ObjectId]) {
          if (poly.monitoring[item.ObjectId][0].out_time === false) {
            poly.monitoring[item.ObjectId][0].out_time = add_time
          }
        }
      }
      return poly
    },
    getNewObject() {
      this.timer = setInterval(() => {
            this.$store.dispatch('getObjectGroups');
          },
          this.set_time);
    },
    getEventsAlerts() {
      this.event_timer = setInterval(() => {
            this.getAlert();
          },
          this.set_event_time);
    },
    getAlert(){
      let $this = this
      let now = new Date();
      let formData = new FormData();
      formData.append(' tz', now.getTimezoneOffset())
      if( this.checkObjectLength($this.alertIds)){
        for (const [key, value] of Object.entries( $this.alertIds)) {
       formData.append('ids[]',key)
       }
       }

      axios.post(base_url + '/eventmanager/getalerts/', formData).then(response => {
        let datas = response.data
        if (datas.success) {
        if (datas.changes === true) {
          $this.aletr_change = true
           if(datas.data && datas.data.length> 0){
             for(let i=0; i<datas.data.length;i++){
               let id = datas.data[i]['id']
               $this.alertIds[id] =datas.data[i]
             }
             $this.alert_counter++
           }
        }else{
          $this.aletr_change = false
        }
        }
      });
    },
    logAccept(log_id) {
      let $this = this
      let message = this.lang.events_component.log_text
      this.$iosConfirm({
        text: message
      }).then(function () {
        let formData = new FormData();
        formData.append('Alerts[]', log_id)
        axios.post(base_url + '/eventmanager/accept/', formData).then(response => {
          if (response.data['error'] == 0) {
            delete $this.alertIds[log_id]
            $this.alert_counter++
          }

        }).catch(error => {
          console.log(error)
        })

      })
    },
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  }
}
</script>
<style>






.panel.global-settings-panel{
  width: calc(100vw - 60px );
  height: calc(100vh - 60px );
  background-color: #f5f5f5;
  position: absolute;
  z-index: 10000;
}
.vehicle-number{
  padding-left: 5px;
  color: #1c71da;
  font-weight: bold;
}
.panel-heading.vehicle-head {
  background: #e5f3f9;
  border-bottom: 1px solid #e5f3f9;
  padding: 10px 20px;
}
.global-settings-table{
  margin-right: 40px;
}
.btn-row-box{
  margin-right: 10px;
}

.vehicle-head.task-head {
  display: flex;
  width: 100%;
  align-items: center;
}


.big-group-scroll,.btn-group-scroll {
  width: 100%;
  justify-content: start;
}
.big-group-scroll .btn, .btn-group-scroll .btn{
  margin-right: 10px;
}



/*searcg_group*/
.mobile-search-scroll{
  display: none;
}
.search-group{
  max-width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}
.search-group label{
  width: 130px;
  text-align: end;
  padding-right: 10px;
}
.search-group select{
  width: 200px;
}
.search-group.search-status-group{
  max-width: 300px;
}
.search-group.search-status-group label{
  width: 80px;
}

/*searcg_group mobile */


/*searcg_group end*/






.fas svg {
  height: 20px;
}
.svg-box-set svg{
  width: 20px;
  height: 20px;

}
.svg-box-set {
  padding-right: 20px;
  line-height: 20px;

}
.router-link-active.router-link-exact-active {
  background-color: #21262d;

}

.header {
  display: flex;
}

.header-right {
  margin-left: auto;
}

.header-left {
  margin-left: 50px;
}

.data-minute {
  font-weight: bold;
}

.role.menu {
  color: #346FA1;
}
.mt-xl {
  margin-top: 25px !important;
}

.main-blog{
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  min-width: calc(100% - 300px);
  width:100%;
  height: 100vh;
  max-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 1;
}
.main-component-wrapper {
  position: absolute;
  z-index: 100000;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
}

.report-btn {
  background: transparent;
  border: none;
  color: #cccccc;
  width: 100%;
  display: flex;
  justify-content:start;
  align-items: center;
  padding: 12px 25px;
  box-shadow: 2px 0 0 #1C71DA inset;
  border-radius: 0;
}
.report-btn.selected,.report-btn:hover ,.report-btn:focus {
  background-color: #171717;
  color: #cccccc;
}
html.sidebar-left-collapsed .sidebar-left .nav-main .report-btn span {
  visibility: hidden;
}
.notifications{
  padding-right: 30px;
}
.open>.dropdown-menu {
  display: block;
}
.notifications > li .notification-icon .note-alert {
  color: #1C71DA;
  font-size: 18px;
}
.alert-number{
font-weight: bold;
  color: #1C71DA;
}
.alert-box{
  display: flex;
}
.notifications .notification-menu {
  width: 255px;
}
.alert-btn{
  padding: 6px 6px;
  margin-right: 10px;
  background: transparent;
}
.ps {
  max-height: 60vh;
}
@media only screen and (max-width: 1500px) {
  .notifications {
    margin-top: 0;
  }
  .main-blog{
    max-height: calc(100vh - 42px);

  }
  .panel-heading.panel-heading-transparent {
    max-width: 370px;
  }
  .report-btn {
    padding: 12px 15px;

  }
}
.loading-main-wrapper{
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  background: #ecedf0;;
  height: 100vh;
  width: 100vw;
  position: fixed;

}
.routes-flex-box{
  display: flex;
}
@media only screen and (max-width: 600px) {
  .main-component-wrapper {
    top: unset;
    left: 0;
    right:0;
    bottom:0;
  }
  .routes-flex-box {
    display: flex;
    position: fixed;
    top: 60px;
    left:50px;
    z-index: 1000;
  }
  .main-blog{
    max-height: calc(100vh - 60px);
  }
  .notifications.event-notifications{
    position: absolute;
    left: 60px;
    top: 12px;
  }
  .notifications > li .notification-menu {
    left: 0;
    right: 0;
    top: auto;
    min-width: 80vw;
  }
}

.object-wrapper {
  max-height: calc(100vh - 60px);
  max-width: 400px;
  min-width: 400px;
  min-height: calc(100vh - 60px);
  overflow: hidden;
}
.panel.main-panel {
  position: relative;
  height: 100%;
  background: #fff;
  min-height: calc(100vh - 60px);
  overflow: hidden;
  overflow-y: hidden !important;
}
.hide-object-wrapper .panel.main-panel{
  height: 100% !important;
}
@media only screen and (max-width: 1500px){
  .object-wrapper {
    max-height: calc(100vh - 42px);
    min-height: calc(100vh - 42px);

  }
  .panel.main-panel {

    min-height: calc(100vh - 42px);
    width: 370px;
  }

}

@media only screen and (max-width: 600px) {
  .object-wrapper {
     max-height: unset;
    min-height: unset;
    max-width: unset;
    min-width: unset;
  }
  .panel.main-panel {
    min-height: unset;
    margin-bottom: 0px;
    width: unset;
  }
  ul.nav-main > li.nav-active > a {
    box-shadow: unset;
    padding-left: 8px;
  }
}
.leaflet-container {
  font-family: Helvetica Neue,Arial,Helvetica,sans-serif;
  font-size: 12px !important;
  line-height: 1.5;
}



.route-setting_list {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
}

.route-setting_list li {
    width: 25%;
    padding: 0;
    margin: 0;
    padding: 5px;
}

.route-setting_page-btn {
    white-space: normal;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    min-width: 0;
    height: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    color: rgba(0, 0, 0, 0.54);
    border: none;
    padding: 4px 5px;
    font-weight: 600;
}

.route-setting_page-btn.active {
    color: #1C71DA;
    background: rgba(0, 136, 204, 0.3);
}

.route-setting_page-btn svg {
    width: 25px;
    margin-right: 5px;
}
.route-setting_page-btn svg {
    fill: #1C71DA;
}

.route-setting_page-btn:hover, .route-setting_page-btn:focus {
    background-color: #dcdcdc;
}

.route-setting_page-btn img {
    width: 25px;
}



</style>
