<template>
  <div class="history-table__conrtent" :style="{'height':bodySize2+'px'}"
       :class="show_all ? '' : 'hide-object-wrapper hide-history-table'">
    <v-resizable
      :active="handlers"
      :fit-parent="fit"
      :max-height="maxH"
      :min-height="minH"
      :height="height"
      @mount="eHandler"
      @resize:move="eHandler"
    >
      <section class="history-table__panel panel">


        <header class="panel-heading" v-if="show_all">

          <div class="panel-heading-resize-top">

            <span @click="changeSize()"><svg-move-down-table-icon v-if="!roll_down"/><svg-move-table-icon
              v-if="roll_down"/></span>
          </div>

        </header>
        <div class="panel-body history-table__body" v-if="show_all">

          <div class="table-responsive history-table__table" ref="tablebody">
            <template v-if="!loading">
              <template v-if="show_line_chart">

                <div class="wrapper-chart-box">
                  <div class="chart-box__panel">
                    <div class="chart-box__btn-box btn-box">
                      <button type="button" class="btn btn-full-chart" @click="getFullChart()"><translate-component :text="lang.history_table_component.full_chart"></translate-component></button>
                    </div>
                    <div class="chart-box__btn-box box" v-if="headers_data.length>0">
                      <select @change="changeChartValue()" v-model="active_column">
                        <template v-for="(item,index) in headers_data">
                          <template v-if="index>1">
                            <option :value="index">{{ item.translation }}</option>
                          </template>
                        </template>
                      </select>
                    </div>

                    <div class="chart-box__btn-box box">
                      <select v-model="chart_type">
                        <option disabled>
                          <translate-component :text="lang.history_table_component.chart_type"/>
                        </option>
                        <option value="line">
                          <translate-component :text="lang.history_table_component.chart_line"/>
                        </option>
                        <option value="area" v-if="chart_type_num ===1">
                          <translate-component :text="lang.history_table_component.area_line"/>
                        </option>
                        <option value="pie" v-if="chart_type_num ===1">
                          <translate-component :text="lang.history_table_component.pie_line"/>
                        </option>
                        <option value="bar">
                          <translate-component :text="lang.history_table_component.bar_line"/>
                        </option>
                        <option value="radar">
                          <translate-component :text="lang.history_table_component.radar_line"/>
                        </option>
                      </select>
                    </div>
                    <div class="chart-box__btn-box box-list">
                      <div class="chart-box__select">
                        <translate-component :text="lang.history_table_component.add_chart"/>
                      </div>
                      <ul class="chart-box__list">
                        <li style="display: none">{{ chart_helper2 }}</li>
                        <template v-for="(item,index) in headers_data">
                          <template v-if="index > 1">
                            <li class="chart-box__item">
                        <span style="padding-left: 5px"><input type="checkbox"
                                                               @click="()=>{selectChart(index)}"
                                                               :value="chart_list[index]"
                        ></span><span @click="chartAddList(index)">{{ item.translation }}</span>
                              <span class="chart-box__color">
                              <span class="color-chacker" @click="toggleBorderColor(index)"
                                    :style="{'backgroundColor': borderColor[index].hex}"></span>


                    <compact-picker
                      v-show="showBorderColor[index]"
                      :palette="color_pallete"

                      @input="getColor($event,index)"

                      :value="borderColor[index]"


                    ></compact-picker>

                            </span>
                            </li>
                          </template>
                        </template>
                      </ul>
                    </div>
                  </div>
                  <div class="chart-box__chart">
                    <template v-if="count_chart">
                      <line-chart
                        :headers_units="headers_units"
                        :chart_max="chart_max"
                        :chart_min="chart_min"
                        :add_labels="labels"
                        :chart_type_num="chart_type_num"
                        :chartdata="chartdata"
                        :label_name="label_name"
                        pointBackgroundColor="rgba(1, 116, 188, 0.50)"
                        :borderColor="borderColor"
                        :title="chart_title"
                        v-if="chart_type==='line'"
                      ></line-chart>

                      <AreaChartComponent
                        :headers_units="headers_units"
                        :chart_max="chart_max"
                        :chart_min="chart_min"
                        :add_labels="labels"
                        :chart_type_num="chart_type_num"
                        :chartdata="chartdata"
                        :label_name="label_name"
                        :pointBackgroundColor="borderColor"
                        pointBorderColor="rgba(171, 71, 188, 1)"
                        :title="chart_title"
                        v-if="chart_type==='area'"
                      />
                      <bar-chart-component
                        :headers_units="headers_units"
                        :chart_max="chart_max"
                        :chart_min="chart_min"
                        :add_labels="labels"
                        :chart_type_num="chart_type_num"
                        :chartdata="chartdata"
                        :label_name="label_name"
                        :title="chart_title"
                        :backgroundColor="borderColor"
                        v-if="chart_type==='bar'"

                      />
                      <radar-chart-component
                        :headers_units="headers_units"
                        :chart_max="chart_max"
                        :chart_min="chart_min"
                        :add_labels="labels"
                        :chart_type_num="chart_type_num"
                        :chartdata="chartdata"
                        :label_name="label_name"
                        :title="chart_title"
                        backgroundColor="rgba(255,99,132,0.2)"
                        :borderColor="borderColor"
                        pointBackgroundColor="rgba(255,99,132,1)"
                        pointBorderColor="#fff"
                        pointHoverBackgroundColor="#fff"
                        pointHoverBorderColor="rgba(255,99,132,1)"

                        v-if="chart_type==='radar'"
                      />

                      <pie-chart-component
                        :add_labels="labels"
                        :chart_type_num="chart_type_num"
                        :chartdata="chartdata"
                        :label_name="label_name"
                        :title="chart_title"
                        v-if="chart_type==='pie'"


                      />


                    </template>
                  </div>
                </div>
              </template>
              <template v-else>
                <v-table class="table table-bordered table-hover mb-none table-history" :data="table_data">
                  <thead slot="head">
                  <th v-for="(val,index) in headers_data">
                    <template v-if="!val['filter'] || val['filter'] === 0">
                      <span class="sort-table" @click="sortUpTable(val.name, index)"><sort-up-filter-icon/></span>
                    </template>
                    <template v-if="val['filter'] && val['filter'] === 1">
                      <span class="sort-table" @click="sortDownTable(val.name, index)"><sort-down-filter-icon/></span>
                    </template>
                    <template v-if="val['filter'] && val['filter'] === 2">
                      <span class="sort-table" @click="removeFilterTable(index)"><table-filter-icon/></span>
                    </template>
                    <span class="history-table__th-title">
                  <template v-if="lang.history_table_component[val.name]">
                  <translate-component :text="lang.history_table_component[val.name]"/>
                  </template>
                  <template v-else>
                    {{ val.translation }}
                  </template>
                </span>
                    <span class="history-table__th-subtitle">{{ val.units }}</span>


                  </th>
                  </thead>
                  <tbody slot="body" slot-scope="{displayData}">
                  <tr @click=showLocation(row) v-for="row in displayData" :key="row.id">
                    <td v-for="item in  row.cell">{{ item }}</td>
                  </tr>
                  </tbody>
                </v-table>
              </template>


            </template>
            <template v-else>
              <div class="loading-box">
                <grid-loader :height="loading_size"></grid-loader>
              </div>
            </template>
          </div>

          <div class="history-table__footer">

            <div class="excel-download-btn" v-if="!loading">
              <button type="button" class="btn  button-scroll button-scroll-center btn-chart"
                      @click="getChartShowOpen">
                <svg-chart-line-icon/>
              </button>
            </div>
            <div class="excel-download-btn" v-if="!loading && part_excel">
              <span style="display: none">{{ count_helper }}</span>
              <export-excel
                ref="exportExcel1"
                class="btn btn-default btn-excel-start"
                :data="excel_arr"
                :fields="json_fields"
                :worksheet="object_numbers[settings_active]"
                :name="object_numbers[settings_active]+'_route_list.xls'">
                Download Excel
              </export-excel>
            </div>
            <div class="btn-group-scroll ">
              <div class="btn  button-scroll button-scroll-center">
                <translate-component :text="lang.history_table_component.rows"/>


                <span class="page-count">
                <select class="form-control" @change="handlerRowsInput($event)">
                  <option :value="item" v-for="item in options.rows_arr">{{ item }}</option>
                </select>
              </span></div>


              <button type="button" class="btn  button-scroll " @click="ScrollList(-1)">
                <span class="button-scroll-icon button-scroll-icon-left fa fa-chevron-left"></span>
              </button>
              <div class="btn  button-scroll button-scroll-center">
                <translate-component :text="lang.history_table_component.pages"/>
                <span class="page-count"><input type="number" class="form-control"
                                                @blur="handlerScrollInput($event)"
                                                v-on:keyup.enter="handlerScrollInput($event)"
                                                :value="options.current_page"> </span></div>
              <button type="button" class="btn  button-scroll button-scroll-right" @click="ScrollList(1)"><span
                class="button-scroll-icon button-scroll-icon-right fa fa-chevron-right"></span></button>
              <div class="btn  button-scroll button-scroll-total">{{ options.total }}</div>
            </div>
          </div>
        </div>

      </section>
    </v-resizable>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import axios from "axios";
import GridLoader from 'vue-spinner/src/GridLoader.vue'
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import SvgTableIcon from "@/components/ui/icons/svgTableIcon";
import SvgCloseIcon from "@/components/ui/icons/svgCloseIcon";
import TableFilterIcon from "@/components/ui/icons/tableFilterIcon";
import SortDownFilterIcon from "@/components/ui/icons/sortDownFilterIcon";
import SortUpFilterIcon from "@/components/ui/icons/sortUpFilterIcon";
import SvgMoveTableIcon from "@/components/ui/icons/svgMoveTableIcon";
import SvgMoveDownTableIcon from "@/components/ui/icons/svgMoveDownTableIcon";
import zoomIcon from "@/mixins/zoomIcon";
import StaticMapData from "@/components/map/StaticMapData";
import SvgChartLineIcon from "@/components/ui/icons/svgChartLineIcon";
import lineChart from "@/components/charts/lineChartComponent";
import AreaChartComponent from "@/components/charts/AreaChartComponent";
import PieChartComponent from "@/components/charts/pieChartComponent";
import BarChartComponent from "@/components/charts/barChartComponent";
import RadarChartComponent from "@/components/charts/radarChartComponent";
import VResizable from "@/components/resizable/VueResizable";
import {Compact} from 'vue-color'
import moment from "moment";

export default {
  name: "historyTableComponent",
  mixins: [zoomIcon],
  components: {
    RadarChartComponent,
    BarChartComponent,
    PieChartComponent,
    AreaChartComponent,
    SvgChartLineIcon,
    StaticMapData,
    SvgMoveDownTableIcon,
    SvgMoveTableIcon,
    SortUpFilterIcon,
    SortDownFilterIcon,
    TableFilterIcon,
    SvgCloseIcon,
    SvgTableIcon,
    TranslateComponent,
    GridLoader,
    VResizable,
    'line-chart': lineChart,
    'pie-chart-component': PieChartComponent,
    'compact-picker': Compact
  },
  mounted() {

    this.getTableHeader()
    this.getTableData()
    this.bodySize = 0;
    this.height = 500;
    this.bodySize2 = this.height
    this.roll_down = true
  },

  data() {
    return {
      excel_start:false,
      headers_units:[],
      chart_max: [],
      chart_min: [],
      roll_down: false,
      chart_type_num: 1,
      chart_helper2: 0,
      color_pallete: [
        '#4D80CC', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6',
        '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
        '#E666B3', '#809900', '#E6B3B3', '#6680B3', '#66991A',
        '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
        '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
        '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
        '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
        '#80B300', '#33991A', '#CC9999', '#B3B31A', '#00E680',
        '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
        '#FF3380', '#CCCC00', '#66E64D',
      ],
      showBorderColor: [],
      borderColor: [],
      chart_list: [],
      bodySize2: 500,
      height: 500,
      maxH: 700,
      minH: 150,
      fit: true,
      handlers: ['lt', 't', 'rt'],
      chart_type: 'line',
      count_chart: false,
      active_column: 2,
      label_name: [],
      chart_title: '',
      labels: [],
      chartdata: [],
      show_line_chart: false,
      excel_arr: [],
      new_data: {},
      part_excel: true,
      res_map: '',
      res_number: '',
      pdf_data: '',
      lat_header: 0,
      lng_header: 0,
      json_fields: {},
      count: 0,
      interval: null,
      interval2: null,
      bodyY: 0,
      body_total: 250,
      bodySize: 250,
      isDraggable: false,
      show_all: true,
      count_helper: 0,
      loading_size: "18px",
      loading: true,
      base_url: base_url,
      headers_data: {},
      table_data: {},
      old_table_data: {},
      options: {
        rows: 100,
        rows_arr: [100, 200, 500, 1000],
        page: 1,
        total: 1,
        sord: 'asc',
        search: false,
        combineStopMessages: 0,
        records: 0,
        current_page: 1,
      }

    }
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      select_history: 'SELECT_HISTORY',
      history_table_show: 'HISTORY_TABLE_SHOW',
      settings_active: 'SETTINGS_ACTIVE',
      history_time: 'HISTORY_TIME',
      move_marker_show: 'MOVE_MARKER_SHOW',
      move_marker_data: 'MOVE_MARKER_DATA',
      object_numbers: 'OBJECTS_NUMBERS',
      route_excel: 'ROUTE_EXCEL',
    }),

  },
  watch: {
    route_excel(newVal, oldVal) {

        this.part_excel = false;
        this.getExcelData2();

    }

  },
  filters: {
    checkEmpty(value) {
      return typeof value !== "number" ? 0 : value;
    }
  },
  methods: {
    getFullChart(){
      let formData = new FormData();

      let $this = this
      let time_add = this.history_time[this.settings_active]
      formData.append('objectId', $this.settings_active);
      formData.append('dt1', time_add.start);
      formData.append('dt2', time_add.end);
      formData.append('minutes', 6);

      axios.post(base_url + '/routes/getmsgchart/ObjectId/'+$this.settings_active+'/dt1/'+time_add.start+'/dt2/'+time_add.end, {}
      ).then(response => {

        if(response.data.messages && response.data.messages.length > 0) {

          let data_add =[]
          response.data.messages.map(elem =>{
            data_add.push({'cell':elem })
          })
          $this.table_data = data_add
          $this.old_table_data = data_add
          $this.getExcelData();
          if ($this.chart_type_num === 1) {
            $this.getChartShow()
          } else {

            $this.editAvarageChart()
          }
        }
      }).catch(error => {
        console.log(error)
      })


    },
    getColor(event, num) {
      let val_add = event
      this.borderColor[num] = val_add
    },
    toggleBorderColor(index) {

      this.showBorderColor[index] = !this.showBorderColor[index]

      this.chart_helper2++
    },
    selectChart(num) {
      if (this.chart_list[num] && this.chart_list[num] === 1) {
        this.chart_list[num] = 0
        this.removeChart(num)

      } else {
        this.chart_list[num] = 1
        this.getAvarageChart(num)
      }
      this.chart_helper2++
    },
    removeChart(num) {
      this.count_chart = false
      this.chartdata[num] = []
      let $this = this
      setTimeout(function () {
        $this.count_chart = true

      }, 100);
    },
    eHandler(data) {
      let check = data.height - this.height
      this.bodySize = this.body_total + check
      this.bodySize2 = data.height
    },
    changeSize() {
      if (this.roll_down === true) {
        this.bodySize = 460;
        this.height = 560;
        this.bodySize2 = this.height
        this.roll_down = false
      } else {
        this.bodySize = 0;
        this.height = 40;
        this.bodySize2 = this.height
        this.roll_down = true
      }
    },
    changeChartValue() {

      this.getChartShow()

    },
    getChartShowOpen() {
      if (this.show_line_chart === false) {
        this.getChartShow()
        this.show_line_chart = true;
      } else {
        this.show_line_chart = false
      }
    },
    getChartShow(result = null) {
      this.chart_type_num = 1
      this.chartdata = []
      this.label_name = []
      this.count_chart = false
      let num = this.active_column
      let tew_arr = []
      let k = 0;
      let chartdata = []
      if(result === null){
        this.old_table_data.map(element => {

          tew_arr.push([moment(element.cell[1]).format("DD/MM/YYYY"), moment(element.cell[1]).format("h:mm")])
          chartdata.push(element.cell[num])
          k++
        })
      }else{
        this.result.map(element => {

          tew_arr.push([moment(element.cell[1]).format("DD/MM/YYYY"), moment(element.cell[1]).format("h:mm")])
          chartdata.push(element[num])
          k++
        })
      }



      this.labels = tew_arr;
      // this.chartdata[num] = chartdata
      // this.label_name[num] = this.headers_data[num].translation
      this.chartdata[1] = chartdata
      this.chart_max[1]= Math.max(...chartdata);
      this.chart_min[1]= Math.min(...chartdata);

        this.label_name[1] = ''
      if (this.headers_data[num]) {
        this.label_name[1] = this.headers_data[num].translation
      }


      this.borderColor[1] = {'hex': '#74bcbb'}

      this.chart_title = this.headers_data[num].translation + ' ' + this.headers_data[num].units
      this.headers_units[1]=this.headers_data[num].units
      let $this = this
      this.$nextTick(() => {
        $this.count_chart = true
      })

    },
    getAvarageChart(num) {
      this.chart_type_num = 2

      this.count_chart = false

      let tew_arr = []
      let k = 0;
      let chartdata = []


      this.old_table_data.map(element => {

        tew_arr.push([moment(element.cell[1]).format("DD/MM/YYYY"), moment(element.cell[1]).format("h:mm")])

        chartdata.push(element.cell[num])
        k++
      })

      this.labels = tew_arr;
      this.chartdata[num] = chartdata

      this.chart_max[num]= Math.max(...chartdata)
      this.chart_min[num]= Math.min(...chartdata)
      this.headers_units[num]=this.headers_data[num].units
      let mes = ''
      if(this.headers_data[num]){
        if(this.headers_data[num].translation) {
          mes = this.headers_data[num].translation + ' ' + this.headers_data[num].units
        }
      }
      this.label_name[num] =  mes

      this.chart_title = ''

      let $this = this
      this.$nextTick(() => {
        $this.count_chart = true
      })

    },
    editAvarageChart(result = null) {
      this.count_chart = false
      let k = 0;

      let $this = this
      this.chartdata.map(item => {
        let chartdata_add = []
        if (k > 1) {
          if (Array.isArray($this.chartdata[k]) && $this.chartdata[k].length > 0) {
            $this.old_table_data.map(element => {
              chartdata_add.push(element.cell[k])
            })
            $this.chartdata[k] = chartdata_add
            $this.labels[k] = tew_arr;
            $this.chart_max[k]= Math.max(...chartdata_add)
            $this.chart_min[k]= Math.min(...chartdata_add)
          }
        }
        k++
      })
      let tew_arr = []

      this.old_table_data.map(element => {
        tew_arr.push([moment(element.cell[1]).format("DD/MM/YYYY"), moment(element.cell[1]).format("h:mm")])
      })

      this.labels = tew_arr;
      this.$nextTick(() => {
        $this.count_chart = true
      })
    },

    DateTable() {
      let formData = new FormData();
      let $this = this
      formData.append('rows', 1000);
      formData.append('page', $this.options.page);
      formData.append('sord', $this.options.sord);
      formData.append('_search', $this.options.search);
      formData.append('combineStopMessages', $this.options.combineStopMessages);
      let id = this.settings_active;
      let dt1 = this.route_excel[0]
      let dt2 = this.route_excel[1]

      axios.post(base_url + '/index.php/routes/getmsg2/ObjectId/' + id + '/dt1/' + dt1 + '/dt2/' + dt2, formData
      ).then(response => {
        console.log(response.data.rows)

        this.new_data = response.data.rows
        if(this.excel_start === true) {
          let new_add = this.new_data
          let arr = []
          let i = 0

          if (Array.isArray(new_add)) {
            new_add.map(element => {
              arr[i] = element.cell
              i++
            })
          } else {
            if (typeof new_add === 'object') {
              new_add.forEach(element => {
                arr[i] = element.cell
                i++
              })
            }
          }

          this.excel_arr = arr
          this.excel_start =false
          this.count_helper++;
          this.part_excel = true;
          let $this = this

          this.$nextTick(() => {
            if ($this.excel_arr.length > 0) {
              $this.$refs.exportExcel1.generate();
            }
          });
        }

      }).catch(error => {
        console.log(error)
      })


    },

    getExcelData() {
      let new_add = {}
      new_add = this.table_data
      let arr = []
      let i = 0
      new_add.forEach(element => {

        arr[i] = element.cell
        i++
      })
      this.excel_arr = arr

    },
    getExcelData2() {
      this.excel_start =true
      this.DateTable();

    },
    mousedown() {
      this.increment()
      if (this.interval === null) {
        this.interval = setInterval(this.increment, 10);
      }
    },
    mousedownDown() {
      this.increment2()
      if (this.interval2 === null) {
        this.interval2 = setInterval(this.increment2, 10);
      }
    },
    mouseup() {
      clearInterval(this.interval);
      this.interval = null
    },
    mouseup2() {
      clearInterval(this.interval2);
      this.interval2 = null
    },
    increment() {
      this.bodySize = this.bodySize + 1;
    },
    increment2() {
      this.bodySize = this.bodySize - 1;
    },
    removeFilterTable(index) {
      let num_check = index
      this.headers_data[num_check]['filter'] = 0
      this.table_data = [...this.old_table_data]
    },
    sortDownTable(val_name, index) {
      let num_check = index
      let check = this.table_data[0].cell[index]
      this.headers_data[num_check]['filter']++
      if (!isNaN(check)) {
        let add = this.table_data.sort(function (a, b) {
          return a.cell[num_check] - b.cell[num_check];
        });
      } else {
        if (val_name === 'gpstime' || 'time') {
          let add = this.table_data.sort(function (a, b) {
            return new Date(a.cell[num_check]).getTime() - new Date(b.cell[num_check]).getTime();
          });
        } else {
          let add = this.table_data.sort((a, b) => a.cell[num_check].localeCompare(b.cell[num_check]))
        }
      }
    },
    sortUpTable(val_name, index) {
      let num_check = index
      if (!this.headers_data[num_check]['filter']) {
        this.headers_data[num_check]['filter'] = 1

      } else {
        this.headers_data[num_check]['filter']++
      }
      let check = this.table_data[0].cell[index]
      if (!isNaN(check)) {

        let add = this.table_data.sort(function (a, b) {
          return b.cell[num_check] - a.cell[num_check];
        });

      } else {
        if (val_name === 'gpstime' || 'time') {
          let add = this.table_data.sort(function (a, b) {
            return new Date(b.cell[num_check]).getTime() - new Date(a.cell[num_check]).getTime();
          });
        } else {
          let add = this.table_data.sort((a, b) => b.cell[num_check].localeCompare(a.cell[num_check]))
        }
      }
    },
    hideContent() {
      this.show_all = !this.show_all
    },
    handlerRowsInput(event) {
      let num = event.target.value
      this.options.rows = num
      this.getTableData()
    },
    handlerScrollInput(event) {

      let num = event.target.value
      if (num > 0 && num <= this.options.total) {
        this.options.page = num
        this.options.current_page = num

      } else {
        if (num > this.options.total) {
          this.options.page = this.options.total
          this.options.current_page = this.options.total
        }
        if (num <= 0) {
          this.options.page = 1
          this.options.current_page = 1
        }
      }
      this.getTableData()

    },
    ScrollList(num) {

      let check = 0;
      let add = parseInt(this.options.page)
      if (num === -1) {


        if (add > 1) {
          this.options.page = add + num
          this.options.current_page = this.options.page
          check++
        } else {

        }
      } else {
        if (add === this.options.total) {

        } else {
          this.options.page = add + num
          this.options.current_page = this.options.page
          check++
        }

      }
      if (check > 0) {
        this.getTableData()
      }
    },
    showLocation(row) {

      if (this.move_marker_data.id !== row.id) {

        let info_add = {}
        let k = 0
        this.headers_data.forEach(element => {
          info_add[element.translation] = row.cell[k]
          k++
        });

        let object_data = {
          id: row.id,
          location: [row.cell[this.lng_header], row.cell[this.lat_header]],
          info: info_add
        }

        this.$store.commit('SET_MOVE_MARKER_DATA', object_data);
        this.$store.commit('SET_MOVE_MARKER_SHOW', true);

        let object = {'X': row.cell[this.lat_header], 'Y': row.cell[this.lng_header]}
        this.zoomIcon(object)



      } else {
        this.$store.commit('SET_MOVE_MARKER_SHOW', false);
        this.$store.commit('SET_MOVE_MARKER_DATA', {});
      }
    },
    downloadPDF() {
      if (this.move_marker_data.id) {

        let $this = this

        $.getJSON(base_url + '/static_map.php?lat=' + $this.move_marker_data.location[0] + '&lng=' + $this.move_marker_data.location[1] + '&h=500&w=800&callback=?', function (response) {

          $this.res_map = response
          $this.res_number = $this.object_numbers[$this.settings_active]

          $this.$refs.DownloadComp.generatePdf()
        });

      } else {
        this.$iosAlert(this.lang.history_table_component.select_row)
      }

    },
    getTableHeader() {

      let formData = new FormData();
      formData.append('ObjectId', this.settings_active);
      axios.post(base_url + '/routes/getmsgheaders2/', formData
      ).then(response => {
        this.headers_data = response.data
        this.json_fields = {}
        let i = 0;
        let add_chart_list = []
        this.headers_data.forEach(element => {
          add_chart_list[i] = 0;
          if (element.name === 'X') {
            this.lat_header = i
          }
          if (element.name === 'Y') {
            this.lng_header = i
          }
          let add_name =''
          if(element.translation){
             add_name = element.translation + ' ' + element.units
          }


          if (i === 0) {
            this.json_fields[add_name] = '0'
          } else {
            this.json_fields[add_name] = i
          }
          if (i > 1) {
            this.borderColor[i] = {'hex': this.color_pallete[i]}
            this.showBorderColor[i] = false
          }

          i++
        })
        if (this.chart_list.length === 0) {
          this.chart_list = add_chart_list
        }


      }).catch(error => {
        console.log(error)
      })
    },
    getTableData() {
      let formData = new FormData();
      let $this = this
      formData.append('rows', $this.options.rows);
      formData.append('page', $this.options.page);
      formData.append('sord', $this.options.sord);
      formData.append('_search', $this.options.search);
      formData.append('combineStopMessages', $this.options.combineStopMessages);
      let id = this.settings_active;
      let dt1 = this.history_time[this.settings_active].start
      let dt2 = this.history_time[this.settings_active].end

      axios.post(base_url + '/index.php/routes/getmsg2/ObjectId/' + id + '/dt1/' + dt1 + '/dt2/' + dt2 + '/', formData
      ).then(response => {
        $this.table_data = response.data.rows
        $this.options.records = response.data.records
        $this.options.total = response.data.total
        $this.loading = false
        $this.old_table_data = response.data.rows
        $this.getExcelData();
        if ($this.chart_type_num === 1) {
          $this.getChartShow()
        } else {

          $this.editAvarageChart()
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.sort-btn-box {
  display: none;
  position: absolute;
  top: 0px;
  left: 2px;
  width: 50px;
  height: 30px;
  background: #fff;
  padding-top: 5px;
  padding-left: 5px;

}

.th:hover .sort-btn-box .th:focus .sort-btn-box {
  display: block;
}

.sort-table svg {
  width: 16px;
  height: 16px;
}

.sort-table {
  padding-right: 5px;
}

.history-table__conrtent {
  width: 100%;
  bottom: 40px;
  left: 0;
  z-index: 20000;

  display: none !important;
}


.history-table__panel.panel {
  padding: 0;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px !important;
  width: 100%;
  height: 100%;
  margin: 0;

}

.table-responsive.history-table__table {
  height: 100%;
  overflow-y: auto;
}

.history-table__panel .panel-heading {
  padding: 0 15px;
}

table {
  text-align: left;
  position: relative;
  border-collapse: collapse;
}

th, td {
  padding: 0.25rem;
}

th {
  background: white;
  position: sticky;
  top: 0; /* Don't forget this, required for the stickiness */
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  padding: 5px;
  line-height: 1.42857143;
  vertical-align: top;
  border-top: 1px solid #dddddd;
  font-weight: 600;
  font-size: 11px;
}


.history-table__footer {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-table__footer .btn-group-scroll {
  width: 460px;
}

.history-table__footer .btn-group-scroll .button-scroll-center {
  width: 120px;
}

.history-table__panel .hide-button {
  bottom: 10px;
  top: unset;
  width: 30px;
  height: 30px;
}

.history-table__conrtent.hide-history-table {
  width: 100px;
  bottom: 50px;
  left: 10px;
}

.history-table__conrtent.hide-history-table .history-table__panel {
  background: #ffffff;
  width: 60px;
  height: 60px;
  padding-left: 0px;
  padding-right: 30px;
}

.hide-button:hover svg, .hide-button:focus svg {
  fill: #ffffff
}

.history-table__conrtent.hide-history-table .hide-button {
  position: absolute;
  left: 10px;
}

.panel-heading-resize-top {
  display: flex;
  justify-content: center;
}

.panel-heading-resize-top svg {
  width: 25px;
  fill: #777;
  cursor: pointer;
}

.panel-heading-resize-top svg:hover, .panel-heading-resize-top svg:focus {
  fill: #1C71DA;

}


.history-table__body {
  padding: 0 15px !important;
  height: 100%;
}

.excel-download-btn {
  margin-right: 30px;
}

.excel-download-btn .btn {
  background-color: rgb(153, 207, 234);
  font-weight: 600;
  padding: 3px 10px;
  box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #777777;
}

.excel-download-btn .btn:hover, .excel-download-btn .btn:focus {
  color: #333333;
}

.table table-hover tr:active {
  background-color: rgb(153, 207, 234);
}

.excel-download-btn.btn-chart {
  width: 60px;
  padding: 1px 5px;
}

.btn-chart svg {
  width: 30px;
  height: 25px;
  fill: #777;
}

.btn-chart:hover svg, .btn-chart:focus svg {
  fill: #333333;
}

.box select {
  background-color: #0563af;
  color: white;
  padding: 10px;
  width: 220px;
  border: none;
  font-size: 14px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  -webkit-appearance: button;
  appearance: button;
  outline: none;
  height: 40px !important;
}


.box select option {
  padding: 30px;
}

.wrapper-chart-box {
  position: relative;
  width: 100%;
  height: 100%;
}

.chart-box__panel {
  display: flex;
  position: absolute;
  top: 0;
  left: -5px;
  width: 100%;
}

.chart-box__btn-box {
  position: relative;
  padding-left: 5px;
}

.chart-box__select {
  background-color: #0563af;
  color: white;
  padding: 10px;
  width: 240px;
  border: none;
  font-size: 14px;
  box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
  -webkit-appearance: button;
  -moz-appearance: button;
  appearance: button;
  outline: none;
  height: 40px !important;
  border-radius: 5px;
}

.chart-box__btn-box.box-list {
  margin-left: auto;
  margin-right: 40px;
}

.chart-box__list {
  background-color: #0563af;
  color: white;
  padding: 10px;
  width: 250px;
  max-height: 200px;
  overflow-y: auto;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 10px;
  margin-top: -5px;
  display: none;
}

.chart-box__btn-box.box-list:hover .chart-box__list, .chart-box__btn-box.box-list:focus .chart-box__list {
  display: block;
}

.chart-box__item {
  display: flex;
  flex-wrap: wrap;
}

.chart-box__color {
  margin-left: auto;
  margin-right: 20px;
}

.chart-box__color span {
  width: 20px;
  height: 20px;
  display: block;
}

.chart-box__item span {
  padding-right: 5px;
}

.table-responsive.history-table__table {
  height: 80%;
}
.table-history th{
 min-width: 60px;
}
.table-history th:first-child{
  min-width: 30px;
}
.chart-box__chart canvas{
  max-width: 100% !important;
}
.btn.btn-full-chart{
  background-color: #0563af;
  color: white;
  box-shadow: 0 5px 25px rgb(0 0 0 / 20%);
  font-weight: bold;
  height: 40px;
}
@media only screen and (max-width: 1500px) {
  .box select {
    padding: 5px;
    width: 180px;
    font-size: 13px;
    height: 34px !important;
  }
  .btn.btn-full-chart{

    height:  34px
  }


  .chart-box__select {
    background-color: #0563af;
    color: white;
    padding: 5px;
    width: 220px;
    border: none;
    font-size: 13px;
    height: 34px !important;
    border-radius: 5px;
  }

  .chart-box__list {
    width: 200px;
    max-height: 200px;
    padding: 5px;
  }

  .chart-box__btn-box {
    padding-left: 5px;
  }

}


</style>
