<template>
 <img  src="/tracking/images/geoloc-logo.svg" alt="">
</template>

<script>

export default {
  name: "svgLogoGeoloc",
}
</script>

<style scoped>

</style>