export default {

  methods: {
    calculateAngle(angle){
      let a = 0;
      let an = parseInt(angle);
      if (an >= 338 || an < 23) {
        a = 0;
        //0
      } else if (an >= 23 && an < 68) {
        a = 1;
        //1
      } else if (an >= 68 && an < 113) {
        a = 2;
        //2
      } else if (an >= 113 && an < 158) {
        a = 3;
        //3
      } else if (an >= 158 && an < 203) {
        a = 4;
        //4
      } else if (an >= 203 && an < 248) {
        a = 5;
        //5
      } else if (an >= 248 && an < 293) {
        a = 6;
        //6
      } else if (an >= 293 && an < 338) {
        a = 7;
        //7
      } else {
        a = 8;
      }
      return a

    }
  }
}
