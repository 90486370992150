<template>
  <section class="panel global-settings-panel" >
    <template v-if="current_resources && current_resources[520] && current_resources[520].enabled ===true">
    <header class="panel-heading vehicle-head ">

      <h2 class="panel-title">

        <span class="vehicle-number" ><span class="svg-box-set"><svg-managment-icon/></span><translate-component :text="lang.events_component.management_name2"/></span>
      </h2>
    </header>

    <div v-if="redy_loading" class="global-settings-table">
      <perfect-scrollbar  >

      <b-editable-table
          bordered
          class="editable-table"
          v-model="items"
          :fields="fields"
          @input-change="handleInput"
      >
        <template #cell(isActive)="data">
          <span v-if="data.value">Yes</span>
          <span v-else>No</span>
        </template>
        <template #cell(icon)="data">
          <figure class="image rounded">
            <img

                @click="handleIcon(data,data.id, data.value)"
                class="vehicle-img"
                :src="'/tracking/images/icons/object_types/4type('+ data.value+').svg' "
                alt="icon" />
          </figure>
        </template>
      </b-editable-table>

      </perfect-scrollbar>
      <div class="panel-footer" style="margin-top: 40px">
        <button  class="btn route-setting_page-btn bottom" @click="saveOptions()"><translate-component :text="lang.geo_zone_component.save_edit"/></button>
      </div>

    </div>


<div v-else>
  <div class="loading-box" >
    <scale-loader :height="loading_size"></scale-loader>
  </div>
</div>
    <div class="wrapper-vehicle" v-if="redy_icons">
  <section class="panel vehicle-settings"  >
    <header class="panel-heading vehicle-head ">
      <div class="panel-actions">
        <a href="#" class="fa fa-times" @click="redy_icons = false"></a>
      </div>
      <h2 class="panel-title">

        <span class="vehicle-number" ><translate-component :text="lang.main_component.icons"/></span>
      </h2>
    </header>
    <div class="panel-body vehicle-body">
    <ul class="simple-user-list vehicle-list">
      <template v-for="n in 88">
        <li >
          <figure class="image rounded">
            <img
                @click="sendSettingsIcon(n)"
                class="vehicle-img"
                :src="'/tracking/images/icons/object_types/4type('+ n+').svg' "
                alt="icon" />
          </figure>

        </li>
      </template>
    </ul>
    </div>
  </section>
    </div>
    </template>
  </section>
</template>

<script>
import BEditableTable from "bootstrap-vue-editable-table";
import {mapGetters} from "vuex";
import axios from "axios";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue'
import SvgManagmentIcon from "@/components/ui/icons/svgManagmentIcon";


export default {
  name: "GlobalSettings",
  components: {
    SvgManagmentIcon,
    TranslateComponent,
    BEditableTable,
    ScaleLoader,
  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      objects: 'OBJECTS',
      current_resources:'CURRENT_RESOURCES',
    }),
  },
  data() {
    return {
      error_objects:'',
      request_counter:0,
      request_check:0,
      request_summ:0,
      loading_size: "30px",
      show_icon:true,
      redy_icons:false,
      redy_loading:false,
      current_object: null,
      current_icon : null,
      fields: [],
      items: [],

      info_params: {
        Number: '',
        Comment: '',
        Marka: '',
        Modelj: '',
        Vid: 1,
        TankCapacity: 0,
        FuelNormal: 0,
        FuelNormal2: 0,
        inspectionDate:'',
        insuranceDate:'',
        workStart:'',
        workEnd:'',
      },
      reports_params: {
        distanceCoef:"1.0",
        distanceSource:"0",
      }
    };
  },
  mounted() {
    let _this =this;
    setTimeout(function () {

        _this.getAllParamstotal();


      //_this.objects.map(item => {
     //   _this.getAllParams(item['ObjectId'], item['icon'])



      _this.fields = [
      {
        key: "ObjectId",
        label: 'Nr',
        type: "text",
        editable: false,
        class: "name-col",
      },

      {
        key: "Number",
        label:  _this.lang.main_component.Number,
        type: "text",
        editable: true,
        placeholder: "Enter Number",
        class: "name-col",
      },
      {
        key: "Mark",
        label:  _this.lang.main_component.Mark,
        type: "text",
        editable: true,
        placeholder: "Enter Mark",
        class: "name-col",
      },

      {
        key: "Modelj",
        label:  _this.lang.main_component.Model,
        type: "text",
        editable: true,
        placeholder: "Enter Model",
        class: "name-col",
      },

      {
        key: "Vid",
        label:  _this.lang.events_component.type,
        type: "select",
        editable: true,
        class: "department-col",
        options: [
          { value: '1', text:  _this.lang.main_component.Car},
          { value: '2', text:  _this.lang.main_component.Truck },
          { value: '3', text:  _this.lang.main_component.Bus },
          { value: '4', text:  _this.lang.main_component.Ship },
          { value: '5', text:  _this.lang.main_component.Special },
        ],
      },
      {
        key: 'TankCapacity',
        label:  _this.lang.main_component.TankCapacity,
        type: "number",
        min: "0",
        editable: true,
        placeholder: "Enter Tank Capacity",
        class: "age-col",
      },
      {
        key: 'FuelNormal',
        label:  _this.lang.main_component.FuelNormal,
        type: "number",
        editable: true,
        placeholder: "Enter Ful Normal l/km",
        class: "age-col",
      },

      {
        key: 'FuelNormal2',
        label:  _this.lang.main_component.FuelNormal2,
        type: "number",
        editable: true,
        placeholder: "Enter Ful Normal l/h",
        class: "age-col",
      },

      {
        key: "Comment",
        label:  _this.lang.main_component.Comment,
        type: "text",
        editable: true,
        placeholder: "Enter Comments",
        class: "name-col",
      },

        {
          key: "inspectionDate",
          label:  _this.lang.main_component.inspection_date,
          type: "date",
          editable: true,
          placeholder: "Enter Inspection Date",
          class: "date-col",
          locale: "en",
          "date-format-options": {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
        },
        {
          key: "insuranceDate",
          label:  _this.lang.main_component.insurance_date,
          type: "date",
          editable: true,
          placeholder: "Enter Insurance Date",
          class: "date-col",
          locale: "en",
          "date-format-options": {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
        },

        {
          key: "workStart",
          label:  _this.lang.main_component.work_date_start,
          type: "time",
          editable: true,
          placeholder: "Enter Work Start",
          class: "date-col",
          locale: "en",
        },
        {
          key: "workEnd",
          label:  _this.lang.main_component.work_date_end,
          type: "time",
          editable: true,
          placeholder: "Enter Work End",
          class: "date-col",
          locale: "en",
        },

        {
          key: 'distanceCoef',
          label:  _this.lang.main_component.dis_coefficient,
          type: "number",
          min: "0",
          editable: true,
          placeholder: "Enter distance Coefficient",
          class: "age-col",
        },

        {
          key: "distanceSource",
          label:  _this.lang.main_component.dis_source,
          type: "select",
          editable: true,
          class: "department-col",
          options: [
            { value: '0', text: 'GPS'},
            { value: '1', text:  'CAN' },
            { value: '2', text: 'Odometer' },

          ],
        },
        {
          key: "icon",
          label:  _this.lang.main_component.icons,
          type: "text",
          editable: true,
          class: "age-col icon-select-field",
        },

    ]

      _this.redy_loading = true;

    }, 1000);
  },
  methods: {
    saveOptions(){
      let _this = this
      this.request_counter=0
      this.request_check=0
      this.error_objects=''
      this.request_summ =  this.items.length

      // _this.items.map(item => {
      //
      //   _this.sendOption(item);
      //
      // })

        _this.sendOptiontotal();

    },
      sendOptiontotal(){

          let $this = this
          let formData = new FormData()
          let m = 0
          $this.request_counter = 0
          this.items.map(item => {

              formData.append("ObjectIds["+m+"]",  item.id)

              Object.keys( $this.info_params).forEach(function(key) {
                  if(item[key] != undefined && item[key] != null) {
                      if(key == 'TankCapacity'){
                          let kk  = parseFloat(item[key]*1).toFixed( 0 )
                          formData.append("params["+item.id+"]["+key+"]",  kk )
                      }else{
                          let raw = item[key];
                          if(raw === '_'){
                              raw = ''
                          }
                          formData.append("params["+item.id+"]["+key+"]", raw)
                      }

                  }
              });

              Object.keys( $this.reports_params).forEach(function(key) {
                  if(item[key] != undefined && item[key] != null && item[key] != '_') {
                      formData.append("reportOptions["+item.id+"][" + key + "]", item[key])
                  }
              });

              m++
          })


          axios.post(base_url + '/index/setObjectParamsall/', formData).then(response => {

                  Object.keys(response.data).forEach(function(key) {

              if(response.data[key]['error'] !== 0 && key != 'error'){
                  $this.error_objects=$this.error_objects+', ID '+ key
                  $this.request_check++;
              }

                  });


              let message = $this.lang.main_component.save_message
                  if($this.request_check > 0 ){
                      message = $this.lang.main_component.save_error_message+' '+$this.error_objects
                  }

                  $this.$iosAlert(message);


          })
      },
    sendOption(item){

      let formData = new FormData()
      formData.append("ObjectId",  item.id)
      let $this = this
      Object.keys( $this.info_params).forEach(function(key) {
        if(item[key] != undefined && item[key] != null) {
          if(key == 'TankCapacity'){
            let kk  = parseFloat(item[key]*1).toFixed( 0 )
            formData.append("params["+key+"]",  kk )
          }else{
            let raw = item[key];
            if(raw === '_'){
              raw = ''
            }
            formData.append("params["+key+"]", raw)
          }

        }
      });
      Object.keys( $this.reports_params).forEach(function(key) {
        if(item[key] != undefined && item[key] != null && item[key] != '_') {
          formData.append("reportOptions[" + key + "]", item[key])
        }
      });
      axios.post(base_url + '/index/setObjectParams/', formData).then(response => {

          if(response['data']['error'] === 0 ){
              $this.request_check++;
          }else{
              $this.error_objects=$this.error_objects+', ID '+item.id
          }
          $this.request_counter++;

          if($this.request_counter === $this.request_summ){
              let message = $this.lang.main_component.save_error_message+' '+$this.error_objects

              if($this.request_check === $this.request_summ){
                  message = $this.lang.main_component.save_message
              }
              $this.$iosAlert(message);
          }
      })
    },
    sendSettingsIcon(icon_number){

      let formData = new FormData()

      formData.append("objectId",  this.current_object)
      formData.append("icon", icon_number)

      axios.post(base_url + '/objects/seticon', formData).then(response => {
        if(response.data['success'] === true) {
          let newObj =[...this.objects]
          let item =newObj.find(item => item.ObjectId === this.current_object);
          item.icon =icon_number
          this.$store.commit('SET_OBJECT',  newObj);
          let ob_val = this.current_object

          var item2 = this.items.findIndex(item => item.id === ob_val);


          let icon_number2 =  icon_number.toString()

          let val2 =  this.items[item2];
          val2['icon'] = icon_number2;
          this.$set(this.items, item2, val2 )
        }
        this.redy_icons = false


      })


    },

    setIconsList(){
      let arrr =[];
      for(let i = 1;i <= 88; i++){
        arrr.push(
            {
              value: ""+i+"",
              text: ` <figure class="image rounded">
                  <img
                      @click="sendSettings(n)"
                      class="vehicle-img"
                      :src="'/tracking/images/icons/object_types/4type('${i}').svg' "
                      alt="icon" />
                </figure>`
            }
        )
      }
      return arrr;
    },
    handleIcon(data,object_id, icon_number) {

      this.current_object = object_id
      this.current_icon =icon_number
      this.redy_icons = true
    },
    handleInput(value, data) {

    },
      getAllParamstotal() {
          let formData = new FormData()
          let _this = this
          let m = 0
          this.objects.map(item => {
              formData.append("ObjectId[" + m + "]", item['ObjectId'])
              m++
          })

          axios.post(base_url + '/index/getObjectParamsall/', formData).then(response => {

              let adddata = response.data

              Object.keys(adddata).forEach(key => {
                  let item = adddata[key]
                  if (item['error'] == 0) {
                      let info_params = item['params']
                      let obj = _this.objects.find(x => x.ObjectId == key)
                      let icon = obj['icon']
                      this.items.push(
                          {
                              id: key,
                              ObjectId: key,
                              Number: info_params.Number,
                              Mark: info_params.Mark != null && info_params.Mark != '' ? info_params.Mark : '_',
                              Modelj: info_params.Modelj != null && info_params.Modelj != '' ? info_params.Modelj : '_',
                              Vid: info_params.Vid,
                              TankCapacity: info_params.TankCapacity > 0 ? parseFloat(info_params.TankCapacity * 1).toFixed(0) : info_params.TankCapacity,
                              FuelNormal: info_params.FuelNormal > 0 ? parseFloat(info_params.FuelNormal * 1).toFixed(2) : info_params.FuelNormal,
                              FuelNormal2: info_params.FuelNormal2 > 0 ? parseFloat(info_params.FuelNormal2 * 1).toFixed(2) : info_params.FuelNormal2,
                              Comment: info_params.Comment != null && info_params.Comment != '' ? info_params.Comment : '_',
                              inspectionDate: info_params.inspectionDate,
                              insuranceDate: info_params.insuranceDate,
                              workStart: info_params.workStart,
                              workEnd: info_params.workEnd,
                              distanceCoef: item['reportOptions'].distanceCoef > 0 ? parseFloat(item['reportOptions'].distanceCoef * 1).toFixed(2) : item['reportOptions'].distanceCoef,
                              distanceSource: item['reportOptions'].distanceSource,
                              icon: icon,
                          },
                      )

                  }
              })
          })
      },

    getAllParams(ObjectId,icon){
      let formData = new FormData()
      formData.append("ObjectId",  ObjectId)
      axios.post(base_url + '/index/getObjectParams/', formData).then(response => {
        if(response.data['error'] == 0) {
          let info_params = response.data['params']

         this.items.push(
              {
                id: ObjectId,
                ObjectId: ObjectId,
                Number:  info_params.Number,
                Mark: info_params.Mark != null && info_params.Mark != '' ? info_params.Mark : '_',
                Modelj: info_params.Modelj != null &&  info_params.Modelj != '' ? info_params.Modelj : '_',
                Vid: info_params.Vid,
                TankCapacity: info_params.TankCapacity >0 ? parseFloat(info_params.TankCapacity*1).toFixed( 0 ): info_params.TankCapacity ,
                FuelNormal: info_params.FuelNormal  >0  ? parseFloat(info_params.FuelNormal*1).toFixed( 2 ) : info_params.FuelNormal,
                FuelNormal2: info_params.FuelNormal2  >0  ? parseFloat(info_params.FuelNormal2*1).toFixed( 2 ) : info_params.FuelNormal2 ,
                Comment: info_params.Comment != null && info_params.Comment != '' ? info_params.Comment : '_',
                inspectionDate: info_params.inspectionDate,
                insuranceDate: info_params.insuranceDate,
                workStart: info_params.workStart,
                workEnd: info_params.workEnd,
                distanceCoef: response.data['reportOptions'].distanceCoef >0  ? parseFloat(response.data['reportOptions'].distanceCoef*1).toFixed( 2 ) : response.data['reportOptions'].distanceCoef,
                distanceSource: response.data['reportOptions'].distanceSource,
                icon: icon,
              },
          )

        }
      })
    }
  },


}
</script>

<style scoped>
.panel.global-settings-panel{
  width: calc(100vw - 60px );
  height: calc(100vh - 60px );
  background-color: #f5f5f5;
position: absolute;
  z-index: 10000;
}

table.b-table {
  width: 100%;
}
.vehicle-img{
  width: 30px;
}
.vehicle-content{
  margin-top: 30px;
  max-height:  50vh;;
  overflow-y: auto;
  overflow-x: hidden;
}
.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.wrapper-vehicle{
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000000;
  background-color: rgba(10,106,161,0.2) ;
}
.panel.vehicle-settings{
  margin: auto;
  margin-top: 20vh;
  margin-left: 500px;
  width: 800px;
}
.vehicle-img{
  width: 38px;
  height: 38px;
  cursor: pointer;

}
.vehicle-img:hover, .vehicle-img:focus{
  transform: scale(1.4);
}
.vehicle-list{
  display: flex;
  flex-wrap: wrap;
}
.route-setting_list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.route-setting_list li {
  width: 25%;
  margin: 0;
  padding: 5px;
}

.route-setting_page-btn {
  white-space: normal;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  min-width: 0;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  border: none;
  padding: 4px 5px;
  font-weight: 600;
}

.route-setting_page-btn.active {
  color: #1C71DA;
  background: rgba(0, 136, 204, 0.3);
}

.route-setting_page-btn svg {
  width: 25px;
  margin-right: 5px;
}
.route-setting_page-btn svg {
  fill: #1C71DA;
}

.route-setting_page-btn:hover, .route-setting_page-btn:focus {
  background-color: #dcdcdc;
}

.route-setting_page-btn img {
  width: 25px;
}
.vehicle-btn-title{
  padding-left: 5px;
}
.vehicle-body{
  min-height: 440px;
}
.panel-heading.vehicle-head{
  background: #e5f3f9;
  border-bottom: 1px solid #e5f3f9;
  padding: 10px 20px;
}
.vehicle-head .panel-actions {
  top: 10px;
}
.vehicle-number{
  padding-left: 5px;
  color: #1c71da;
  font-weight: bold;
}
@media only screen and (max-width: 1500px) {
  .panel.vehicle-settings{
    margin-left: 440px;
    width: calc(100vw - 500px);
    margin-top: 40px;
  }
  .vehicle-body {
    min-height: 440px;
    height: 68vh;
    overflow-y: auto;
  }
  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
@media only screen and (max-width: 600px) {
  .panel.vehicle-settings{
    margin-left: 0;
    margin-top: 60px;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }

  .vehicle-head .panel-actions {
    position: absolute;
    top: 10px;
  }
  .vehicle-head.panel-heading {
    padding: 10px;
  }
  .vehicle-btn-title{
    display: none;
  }
  .vehicle-body {
    min-height: unset;
    height: calc(100vh - 180px);
    overflow-y: auto;
  }

}
.loading-box{
  margin-top: 20px;
  margin-left: 20px;
}
.global-settings-table{
 margin-right: 40px;
}
.ps {
  height:  calc(100vh - 260px );
  max-height:  calc(100vh - 260px );
}

@media only screen and (max-width: 1500px){
  .panel.global-settings-panel{
    width: calc(100vw - 50px );
    height: calc(100vh - 42px );

  }
}
@media only screen and (max-width: 767px){

  .panel.global-settings-panel{
    width: 100%;
    height: calc(100vh - 42px );
    top: 60px;
    position: fixed;
  }
  .ps {
    height:  calc(100vh - 260px );
    max-height:  calc(100vh - 260px );
  }
  .global-settings-table {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .panel.global-settings-panel {
    left: 0;
  }
}
</style>