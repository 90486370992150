<template>

  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"

       version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 511 511" style="enable-background:new 0 0 511 511;" xml:space="preserve">
<g>
	<path d="M39.5,231h24c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.358-7.5,7.5S35.358,231,39.5,231z"/>
  <path d="M39.5,167h168c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-168c-4.142,0-7.5,3.358-7.5,7.5S35.358,167,39.5,167z"/>
  <path d="M239.5,167h32c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-32c-4.142,0-7.5,3.358-7.5,7.5S235.358,167,239.5,167z"/>
  <path d="M375.5,167h96c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-96c-4.142,0-7.5,3.358-7.5,7.5S371.358,167,375.5,167z"/>
  <path d="M375.5,216c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3c1.39,1.4,3.33,2.2,5.3,2.2   c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3C379.41,216.8,377.47,216,375.5,216z"/>
  <path d="M380.8,282.2c-1.39-1.4-3.33-2.2-5.3-2.2c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3   c1.39,1.4,3.33,2.2,5.3,2.2c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3S382.2,283.59,380.8,282.2z"/>
  <path d="M375.5,344c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3c1.39,1.4,3.33,2.2,5.3,2.2   c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3C379.41,344.8,377.47,344,375.5,344z"/>
  <path d="M407.5,344c-1.98,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3c1.39,1.4,3.32,2.2,5.3,2.2   c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3C411.41,344.8,409.48,344,407.5,344z"/>
  <path d="M375.5,408c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3c1.39,1.4,3.33,2.2,5.3,2.2   c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3C379.41,408.8,377.47,408,375.5,408z"/>
  <path d="M407.5,408c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3c1.39,1.4,3.33,2.2,5.3,2.2   c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3s-0.8-3.91-2.2-5.3C411.41,408.8,409.47,408,407.5,408z"/>
  <path d="M444.8,346.2c-1.39-1.4-3.33-2.2-5.3-2.2c-1.97,0-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.32-2.2,5.3s0.8,3.91,2.2,5.3   c1.39,1.4,3.33,2.2,5.3,2.2c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3S446.2,347.59,444.8,346.2z"/>
  <path d="M412.8,218.2c-1.39-1.4-3.32-2.2-5.3-2.2s-3.91,0.8-5.3,2.2c-1.4,1.39-2.2,3.33-2.2,5.3s0.8,3.91,2.2,5.3   c1.39,1.4,3.33,2.2,5.3,2.2c1.97,0,3.91-0.8,5.3-2.2c1.4-1.39,2.2-3.33,2.2-5.3S414.2,219.59,412.8,218.2z"/>
  <path d="M63.5,408h-24c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h24c4.142,0,7.5-3.358,7.5-7.5S67.642,408,63.5,408z"/>
  <path d="M143.5,103h224c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-224c-4.142,0-7.5,3.358-7.5,7.5S139.358,103,143.5,103z"/>
  <path d="M39.5,295h24c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-24c-4.142,0-7.5,3.358-7.5,7.5S35.358,295,39.5,295z"/>
  <path d="M63.5,344h-24c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h24c4.142,0,7.5-3.358,7.5-7.5S67.642,344,63.5,344z"/>
  <path d="M215.5,344h-16c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h16c4.142,0,7.5-3.358,7.5-7.5S219.642,344,215.5,344z"/>
  <path d="M199.5,423h16c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-16c-4.142,0-7.5,3.358-7.5,7.5S195.358,423,199.5,423z"/>
  <path d="M215.5,280h-16c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h16c4.142,0,7.5-3.358,7.5-7.5S219.642,280,215.5,280z"/>
  <path d="M199.5,231h16c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-16c-4.142,0-7.5,3.358-7.5,7.5S195.358,231,199.5,231z"/>
  <path d="M247.5,231h32c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-32c-4.142,0-7.5,3.358-7.5,7.5S243.358,231,247.5,231z"/>
  <path d="M303.5,280h-56c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h56c4.142,0,7.5-3.358,7.5-7.5S307.642,280,303.5,280z"/>
  <path d="M263.5,344h-16c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h16c4.142,0,7.5-3.358,7.5-7.5S267.642,344,263.5,344z"/>
  <path d="M247.5,423h32c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5h-32c-4.142,0-7.5,3.358-7.5,7.5S243.358,423,247.5,423z"/>
  <path d="M471.5,56h-432C17.72,56,0,73.72,0,95.5v320C0,437.28,17.72,455,39.5,455h432c21.78,0,39.5-17.72,39.5-39.5v-320   C511,73.72,493.28,56,471.5,56z M175,376v-49h161v49H175z M336,391v49H175v-49H336z M496,135v49H351v-49H496z M336,199v49H175v-49   H336z M160,248H15v-49h145V248z M160,263v49H15v-49H160z M175,263h161v49H175V263z M351,263h145v49H351V263z M351,248v-49h145v49   H351z M336,184H15v-49h321V184z M15,327h145v49H15V327z M351,327h145v49H351V327z M39.5,71h432c13.509,0,24.5,10.991,24.5,24.5V120   H15V95.5C15,81.991,25.991,71,39.5,71z M15,415.5V391h145v49H39.5C25.991,440,15,429.009,15,415.5z M471.5,440H351v-49h145v24.5   C496,429.009,485.009,440,471.5,440z"/>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>

</template>

<script>
export default {
  name: "svgTableIcon"
}
</script>

<style scoped>

</style>
