<template>

  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 486.493 486.493" style="enable-background:new 0 0 486.493 486.493;" xml:space="preserve">
<g>
	<g id="XMLID_1812_">
		<path id="XMLID_1906_" d="M20.086,142.379l22.4,10.5c-5.4,21-6.6,43.2-3,65.5l-23.2,8.4c-8.7,3.2-13.2,12.8-10.1,21.6l8.9,24.4    c3.2,8.7,12.8,13.2,21.6,10.1l23.2-8.4c11.5,19.4,26.7,35.7,44.3,48.3l-10.5,22.4c-3.9,8.4-0.3,18.4,8.1,22.4l23.6,11    c8.4,3.9,18.4,0.3,22.4-8.1l10.5-22.5c15.5,3.9,31.7,5.6,48.1,4.6l0.3-6.5c0.4-9.2,4.4-17.7,11.2-23.9c6.1-5.6,13.9-8.8,22.2-9    c1.4-3.5,3-6.9,4.8-10.3c-2.2-2.8-3.9-5.9-5.1-9.2c-9.1,3.7-18.8,6.3-29,7.5c-59.9,7-114.1-35.8-121.1-95.7s35.8-114.1,95.7-121.1    s114.1,35.8,121.1,95.7c2.4,20.8-1.1,40.9-9.4,58.6c3.9,1.6,7.4,3.9,10.5,6.8c3.5-1.4,7-2.7,10.7-3.9    c2.1-17.7,17.6-31.1,35.7-30.3l2.4,0.1c1.7-14.5,1.4-29.3-1-44.1l24.2-8.8c8.7-3.2,13.2-12.8,10.1-21.6l-8.9-24.4    c-3.2-8.7-12.8-13.2-21.6-10.1l-24.2,8.8c-11.3-19.1-26.3-35.3-43.7-47.8l10.9-23.2c3.9-8.4,0.3-18.4-8.1-22.4l-23.6-11    c-8.4-3.9-18.4-0.3-22.4,8.1l-10.8,23.1c-20.8-5.4-42.9-6.6-64.9-3.1l-8.6-23.8c-3.2-8.7-12.8-13.2-21.6-10.1l-24.4,8.9    c-8.7,3.2-13.2,12.8-10.1,21.6l8.6,23.6c-19.3,11.4-35.7,26.4-48.3,43.9l-22.5-10.5c-8.4-3.9-18.4-0.3-22.4,8.1l-11,23.6    C7.986,128.379,11.586,138.379,20.086,142.379z"/>
    <path id="XMLID_1868_" d="M379.286,246.779c0-0.2,0-0.3,0-0.5c0-5.6-4.4-10.3-10.1-10.6l-16.4-0.7c-5.9-0.3-10.8,4.3-11.1,10.1    l-0.7,15.6c-13.5,1.9-26.8,6.6-38.8,14l-10.5-11.5c-4-4.3-10.7-4.6-15-0.7l-12.1,11.1c-2.3,2.1-3.4,5-3.4,7.8    c0,2.6,0.9,5.1,2.8,7.2l10.5,11.5c-8.5,11.3-14.3,24.1-17.4,37.4l-15.6-0.7c-5.9-0.3-10.8,4.3-11.1,10.1l-0.7,16.4    c0,0.2,0,0.3,0,0.5c0,5.6,4.4,10.3,10.1,10.6l15.7,0.7c2,13.5,6.7,26.7,14.2,38.6l-11.7,10.7c-2.3,2.1-3.4,5-3.4,7.8    c0,2.6,0.9,5.1,2.8,7.2l11.1,12.1c4,4.3,10.7,4.6,15,0.7l11.8-10.8c11.3,8.4,24,14.1,37.2,17.2l-0.7,16.1c0,0.2,0,0.3,0,0.5    c0,5.6,4.4,10.3,10.1,10.6l16.4,0.7c5.9,0.3,10.8-4.3,11.1-10.1l0.7-16.1c13.4-1.9,26.4-6.6,38.3-14l10.9,12    c4,4.3,10.7,4.6,15,0.7l12.1-11.1c2.3-2.1,3.4-5,3.4-7.8c0-2.6-0.9-5.1-2.8-7.2l-10.9-12c8.4-11.2,14.2-23.8,17.3-37l16.1,0.7    c5.9,0.3,10.8-4.3,11.1-10.1l0.7-16.4c0-0.2,0-0.3,0-0.5c0-5.6-4.4-10.3-10.1-10.6l-16.1-0.7c-1.9-13.4-6.5-26.6-13.9-38.5    l11.8-10.8c2.3-2.1,3.4-5,3.4-7.8c0-2.6-0.9-5.1-2.8-7.2l-11.1-12.1c-4-4.3-10.7-4.6-15-0.7l-11.7,10.7    c-11.3-8.5-24-14.4-37.2-17.5L379.286,246.779z M395.786,323.879c9.6,10.5,14.3,23.7,14.3,36.9c0,14.8-6,29.6-17.8,40.3    c-22.3,20.4-56.8,18.8-77.2-3.4c-9.6-10.5-14.3-23.7-14.3-36.9c0-14.8,6-29.6,17.8-40.3    C340.786,300.079,375.386,301.579,395.786,323.879z"/>
    <path id="XMLID_1827_" d="M197.886,111.679c-44.7,0-81,36.3-81,81s36.3,81,81,81s81-36.3,81-81S242.586,111.679,197.886,111.679z     M197.886,166.279c-14.6,0-26.4,11.8-26.4,26.4c0,6.7-5.4,12.1-12.1,12.1c-6.7,0-12.1-5.4-12.1-12.1c0-27.9,22.7-50.6,50.6-50.6    c6.7,0,12.1,5.4,12.1,12.1S204.486,166.279,197.886,166.279z"/>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>

</template>

<script>
export default {
  name: "svgSettingMono"
}
</script>

<style scoped>

</style>
