import { render, staticRenderFns } from "./svgMoveTableIcon.vue?vue&type=template&id=01c25644&scoped=true&"
import script from "./svgMoveTableIcon.vue?vue&type=script&lang=js&"
export * from "./svgMoveTableIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01c25644",
  null
  
)

export default component.exports