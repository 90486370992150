<template>


  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px"
       y="0px" fill="#1C71DA" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<path style="fill:#99cfea;"
      d="M256,7.918c137.026,0,248.082,111.056,248.082,248.082S393.026,504.082,256,504.082  S7.918,393.026,7.918,256S118.974,7.918,256,7.918z"/>
    <path
      d="M437.024,74.976C388.676,26.627,324.386,0,256,0S123.324,26.627,74.976,74.976S0,187.614,0,256  s26.627,132.676,74.976,181.024C123.324,485.373,187.614,512,256,512s132.676-26.627,181.024-74.976  C485.373,388.676,512,324.386,512,256S485.373,123.324,437.024,74.976z M425.828,425.828  C380.47,471.185,320.157,496.165,256,496.165s-124.47-24.98-169.828-70.337S15.835,320.157,15.835,256s24.98-124.47,70.337-169.828  S191.843,15.835,256,15.835s124.47,24.98,169.828,70.337S496.165,191.843,496.165,256S471.185,380.47,425.828,425.828z"/>
    <polygon
      points="263.918,213.773 248.082,213.773 248.082,248.082 213.773,248.082 213.773,263.918 248.082,263.918   248.082,298.227 263.918,298.227 263.918,263.918 298.227,263.918 298.227,248.082 263.918,248.082 "/>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgCircleIcon"
}
</script>

<style scoped>

</style>
