<template>
  <img  src="/tracking/images/sizzapp_black.svg" alt="">
</template>

<script>
export default {
  name: "svgLogoDark"
}
</script>

<style scoped>

</style>