<template>
  <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" >
    <path d="M20.25 4.5C20.6642 4.5 21 4.16421 21 3.75C21 3.33579 20.6642 3 20.25 3H3.75C3.33579 3 3 3.33578 3 3.75C3 4.16421 3.33579 4.5 3.75 4.5L20.25 4.5Z" />
    <path d="M20.7803 9.71967C20.921 9.86032 21 10.0511 21 10.25V14.5H16V9.5H20.25C20.4489 9.5 20.6397 9.57902 20.7803 9.71967Z" />
    <path d="M14.5 14.5V10.4821C14.2506 10.518 13.9943 10.5001 13.75 10.4275V11.25C13.75 12.2165 12.9665 13 12 13C11.0335 13 10.25 12.2165 10.25 11.25V10.4275C10.0057 10.5001 9.74942 10.518 9.5 10.4821V14.5L14.5 14.5Z" />
    <path d="M9.5 16L14.5 16V21L9.5 21V16Z" />
    <path d="M8 14.5V9.5H3.75C3.33579 9.5 3 9.83579 3 10.25V14.5H8Z"/>
    <path d="M3 16H8V21H6.25C4.45507 21 3 19.5449 3 17.75V16Z" />
    <path d="M16 16H21V17.75C21 19.5449 19.5449 21 17.75 21H16V16Z" />
    <path d="M14.8105 9.24827C14.5354 9.55786 14.0613 9.58574 13.7517 9.31056L12.75 8.42014V11.25C12.75 11.6642 12.4142 12 12 12C11.5858 12 11.25 11.6642 11.25 11.25V8.42013L10.2483 9.31056C9.93868 9.58574 9.46462 9.55786 9.18944 9.24827C8.91425 8.93869 8.94213 8.46463 9.25172 8.18944L11.5017 6.18944C11.7859 5.93685 12.2141 5.93685 12.4983 6.18944L14.7483 8.18944C15.0579 8.46463 15.0857 8.93869 14.8105 9.24827Z" />
  </svg>
</template>

<script>
export default {
  name: "svgMoveTableIcon"
}
</script>

<style scoped>

</style>
