<template>
  <div class="geo-zone-box">
    <section class="panel zone_edit" v-if="edit_zone_show">

      <header class="panel-heading geo-zone">
        <div class="panel-actions">
          <a href="#" class="fa fa-times" @click="closeEditZone()"></a>
        </div>
        <span class="fa geo-zone-icon">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
               data-icon="draw-polygon" class="svg-inline--fa fa-draw-polygon fa-w-14" role="img" viewBox="0 0 448 512"><path
            fill="currentColor"
            d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z"/></svg>
        </span>
        <h2 class="panel-title geo-zone">
          <span class="va-middle"><translate-component :text="lang.geo_zone_component.title_edit"/>{{ edit_zone.info.Name }}</span>
        </h2>
      </header>
      <div class="panel-body">
        <div class="timeline timeline-simple">
          <div class="tm-body">
            <div class="tm-title">
              <div class="form-group">
                <label class="control-label">
                  <translate-component :text="lang.geo_zone_component.edit_name"/>
                </label>
                <textarea class="form-control" name="firstname"  rows="2" v-model.trim.lazy="edit_zone.info.Name"></textarea>
              </div>

            </div>
            <ol class="tm-items">
              <li>
                <div class="tm-box">
                  <div class="form-group">
                    <label class="control-label">
                      <translate-component :text="lang.geo_zone_component.edit_phone"/>
                    </label>
                    <input type="text" name="firstname" class="form-control" v-model.trim.lazy="edit_zone.info.phones">
                  </div>

                </div>
              </li>
              <li>
                <div class="tm-box">
                  <p class="text-muted mb-none">
                    <translate-component :text="lang.geo_zone_component.edit_address"/>
                  </p>
                  <p>
                    <textarea class="form-control" rows="3" v-model.trim.lazy="edit_zone.info.Address"></textarea>
                  </p>
                </div>
              </li>
              <li>
                <div class="tm-box">
                  <p class="text-muted mb-none">
                    <translate-component :text="lang.geo_zone_component.edit_comments"/>
                  </p>
                  <p>
                    <textarea class="form-control" rows="3" v-model.trim.lazy="edit_zone.info.Comment"></textarea>
                  </p>
                </div>
              </li>
              <li>
                <div class="tm-box">
                  <div class="form-group">
                    <label class="control-label">
                      <translate-component :text="lang.geo_zone_component.edit_group"/>
                    </label>
                    <input type="text" name="firstname" class="form-control"
                           v-model.trim.lazy="edit_zone.info.CustomType">
                  </div>

                </div>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <footer class="panel-footer">
        <button class="btn btn-primary" @click="saveEditZone()">
          <translate-component :text="lang.geo_zone_component.save_edit"/>
        </button>
      </footer>
    </section>


    <section class="panel" v-if="!edit_zone_show">
      <header class="panel-heading geo-zone">
        <div class="panel-actions">
          <a href="#" class="fa fa-times" @click=" closeModal()"></a>
        </div>
        <span class="fa geo-zone-icon">
          <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas"
               data-icon="draw-polygon" class="svg-inline--fa fa-draw-polygon fa-w-14" role="img" viewBox="0 0 448 512"><path
            fill="currentColor"
            d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z"/></svg>
        </span>
        <h2 class="panel-title geo-zone">

          <span class="va-middle"><translate-component :text="lang.geo_zone_component.title"/></span>
          <span class="label label-primary label-sm text-normal va-middle mr-sm ">
                {{ objects.length }}</span>
          <span class="show-all-objects-btn" @click="changeAllObjects()">
                <template v-if="show_all_zones">
                   <svg-eye-show/>
              </template>
          <template v-else>
             <svg-eye-hide/>
          </template>
            </span>
        </h2>
      </header>
      <div class="panel-body">
        <div class="content">

          <ul class="simple-user-list">
            <li>
              <div class="btn-box">
                <template v-if="current_resources && current_resources[13] && current_resources[13].enabled ===true">

                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.geo_zone_component.tooltip_polygon"></translate-component>
                  </div>
                  <button type="button" class="btn btn-default btn-geo-create bottom" slot="reference"
                          @click="eventClickPolygonal"><span
                    class="poly-icon">
            <svg-polygon-icon/>
              </span></button>
                </popper>
                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.geo_zone_component.tooltip_circle"></translate-component>
                  </div>
                  <button type="button" class="btn btn-default btn-geo-create bottom" slot="reference"
                          @click="eventClickCircle()">
<span class="poly-icon">
 <svg-circle-icon/>
</span>
                  </button>
                </popper>

                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.geo_zone_component.tooltip_rectangle"></translate-component>
                  </div>
                <button type="button" class="btn btn-default btn-geo-create bottom" slot="reference" @click="eventClickRectangle()">
 <span class="poly-icon">
           <svg-rectangle-icon/>
 </span>
                </button>
                </popper>

                <popper trigger="hover">




                  <div class="popper">
                    <translate-component :text="lang.geo_zone_component.tooltip_marker"></translate-component>
                  </div>
                <button type="button" class="btn btn-default btn-geo-create bottom" slot="reference" @click="eventClickMarker()">
                <span class="poly-icon">
<svg-marcer-icon/>
                  </span>
                </button>
                </popper>
                </template>



                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.geo_zone_component.tooltip_rout"></translate-component>
                  </div>
                <button type="button" class="btn btn-default btn-geo-create bottom" slot="reference" @click="eventClickPath()">

 <span class="poly-icon">
               <svg-path-icon/>
 </span>
                </button>
                </popper>


                <popper trigger="hover">
                  <div class="popper">
                    <translate-component :text="lang.geo_zone_component.tooltip_weather"></translate-component>
                  </div>
                <button type="button" class="btn btn-default btn-geo-create bottom" slot="reference" @click="eventClickWeather()">

                       <span class="poly-icon">
                      <svg-weather-icon/>
                       </span>

                </button>
                </popper>

              </div>
            </li>
          </ul>

<template v-if="show_list">
  <div class="panel geo-zone-scroll-panel" >
  <geozone-list-component  @handlebox="handlePolyCheckbox2($event)" @closelog ='show_list=false' />
  </div>
</template>

          <template v-if="checkObjectLength(polygon_zones)">
            <ul class="geo-zone-scroll-list poly-zone-list">
              <li class="geozone-list" v-for="(poly,key,index) in polygon_zones">

                <span class="index-label">
                  <div class="checkbox-custom checkbox-default">
<input type="checkbox" @click="handlePolyCheckbox(poly)" :checked="poly.show" :id="'checkbox'+poly.status">
<label :for="'checkbox'+poly.status"></label>
</div>
                </span>
                <template v-if="poly.name">
                  <span class="title">{{ poly.name }}</span>
                </template>
                <template v-else>
                  <input type="text" class="form-control" :id="'inputDefault'+poly.status" v-model="current_name"
                         @blur="createZoneName( poly.status)"
                         v-on:keyup.enter="createZoneName( poly.status)"
                         :placeholder="lang.geo_zone_component.input_name">

                </template>

                <template v-if="current_resources && current_resources[12] && current_resources[12].enabled ===true">
                <button type="button" class="mb-xs mt-xs mr-xs btn btn-default geozone-bnt-left geozone-bnt monitoring"
                        @click="startMonitoring(poly)">
                  <popper trigger="hover">
                    <div class="popper">
                      <template v-if="!poly.monitoring_start">
                        <translate-component :text="lang.geo_zone_component.tooltip_monitor_start"></translate-component>
                      </template>
                      <template v-else>
                        <translate-component :text="lang.geo_zone_component.tooltip_monitor_stop"></translate-component>
                      </template>
                    </div>
                  <span class="fa monitoring bottom" slot="reference" style="margin-top: 4px;">
                    <svg-target-icon :monitoring_start="poly.monitoring_start"/>
                  </span>
                  </popper>

                </button>
                </template>


                <button type="button" class="mb-xs mt-xs mr-xs btn btn-default  geozone-bnt"
                        @click="handlerPolyLocation(poly)">
                  <i class="fa fa-info"></i></button>

                <template v-if="current_resources && current_resources[14] && current_resources[14].enabled ===true">
                <button type="button" class="mb-xs mt-xs mr-xs btn btn-default  geozone-bnt" @click="EditZone(poly)">
                  <popper trigger="hover">
                    <div class="popper">
                      <translate-component :text="lang.geo_zone_component.tooltip_update"></translate-component>
                    </div>
                  <span slot="reference"> <i class="fa fa-edit"></i></span>
                  </popper>

                </button>
                </template>


                <template v-if="current_resources && current_resources[14] && current_resources[14].enabled ===true">
                  <button type="button" class="mb-xs mt-xs mr-xs btn btn-default  geozone-bnt"
                          @click="handleSaveItem(poly)" v-if="!poly.info.ZoneId || poly.info.ZoneId === 0">
                    <popper trigger="hover" v-if="!poly.info.ZoneId || poly.info.ZoneId === 0">
                      <div class="popper">
                        <translate-component :text="lang.geo_zone_component.tooltip_save"></translate-component>
                      </div>
                    <span slot="reference"><i class="fa fa-save"></i></span>
                    </popper>

                  </button>
                </template>



                  <button type="button" class="mb-xs mt-xs mr-xs btn btn-default geozone-bnt"
                          @click="deletGeoZone(poly.status)">
                    <popper trigger="hover">
                      <div class="popper">
                        <translate-component :text="lang.geo_zone_component.tooltip_remove_list"></translate-component>
                      </div>
                    <span slot="reference"><i class="fa fa-times"></i></span>
                    </popper>

                  </button>


              </li>

            </ul>
          </template>
          <template v-if="current_resources && current_resources[16] && current_resources[16].enabled ===true">
          <ul class="geo-zone-scroll-list poly-zone-list">
            <li class="geozone-list" style="position:relative;">
              <a @click="GeoZoneList()">
                <translate-component :text="lang.geo_zone_component.add_list"/>
                <span class="label label-primary label-sm text-normal va-middle mr-sm">{{
                    zone_list_options.total > 0 ? zone_list_options.total : ''
                  }}</span></a>
              <div class="panel-actions"></div>
            </li>
          </ul>
          </template>

          <hr class="dotted short">
        </div>
      </div>
      <div class="panel-footer geo-zone">

      </div>
    </section>
  </div>
</template>

<script>


import {mapGetters} from "vuex";
import TranslateComponent from "@/components/dashboard/TranslateComponent";
import helpers from '@/mixins/helpers.js'
import axios from "axios";
import SvgPolygonIcon from "@/components/ui/icons/svgPolygonIcon";
import SvgCircleIcon from "@/components/ui/icons/svgCircleIcon";
import SvgMarcerIcon from "@/components/ui/icons/svgMarcerIcon";
import SvgPathIcon from "@/components/ui/icons/svgPathIcon";
import SvgWeatherIcon from "@/components/ui/icons/svgWeatherIcon";
import SvgRectangleIcon from "@/components/ui/icons/svgRectangleIcon";
import SvgTargetIcon from "@/components/ui/icons/svgTargetIcon";
import Popper from 'vue-popperjs';
import SvgEyeShow from "@/components/ui/icons/svgEyeShow";
import SvgEyeHide from "@/components/ui/icons/svgEyeHide";
import GeozoneListComponent from "@/components/geozone/GeozoneListComponent";

export default {
  name: "GeoZoneComponent",
  components: {
    GeozoneListComponent,
    SvgEyeHide,
    SvgEyeShow,
    SvgTargetIcon,
    SvgRectangleIcon,
    SvgWeatherIcon, SvgPathIcon, SvgMarcerIcon, SvgCircleIcon, SvgPolygonIcon, TranslateComponent,
    'popper': Popper,
  },
  mixins: [helpers],
  data() {
    return {
      current_name:'',
      edit_zone_show: false,
      edit_zone: {},
      zone_count: 0,
      zone_name: '',
      base_url: base_url,
      zone_list_options: {
        rows: 100,
        page: 1,
        sidx: 'ZoneId',
        sord: 'desc',
        totalpages: 0,
        current_page: 1,
        simple_search: '',
        total: 0,
        is_favorite: false,
      },
      zone_list: [],
      show_list: false,

    }

  },
  computed: {
    ...mapGetters({
      lang: 'LANG',
      show_geo_zone: 'SHOW_GEO_ZONE',
      polygon_zones: 'POLYGON_ZONES',
      show_weather: 'SHOW_WEATHER',
      create_path: 'CREATE_PATH',
      objects: 'OBJECTS',
      start_center: 'START_CENTER',
      map_zoom: 'MAP_ZOOM',
      show_all_zones: 'SHOW_ALL_ZONES',
      current_resources:'CURRENT_RESOURCES',
    }),

  },

  beforeDestroy: function () {
    let add = {...this.polygon_zones}
    let add2 = JSON.stringify(add)
    this.$localStorage.set('polygon_zones', add2)
  },
  methods: {
    changeAllObjects() {
      let change = false
      if (this.show_all_zones === false) {
        change = true
      }
      this.$store.commit('SET_SHOW_ALL_ZONES', change);
    },
    saveEditZone() {
      if (this.edit_zone.info.ZoneId && this.edit_zone.info.ZoneId > 0) {
        let formData = this.getFormData(this.edit_zone.info, true)

        let $this = this

        axios.post( base_url + '/geozones/updatezone/',formData).then(res => {

              if(res.data.success === true) {

                let edit_zones = {...$this.polygon_zones}
                let check = $this.edit_zone.status
                $this.edit_zone.name = $this.edit_zone.info.Name

                edit_zones[check] = $this.edit_zone;
                $this.$store.commit('SET_POLYGON_ZONES', edit_zones);


                $this.closeEditZone()
              }
              }).catch(error => {
          console.log(error)

        })


      } else {
        this.saveItem(this.edit_zone)
      }

    },
    closeEditZone() {
      this.edit_zone_show = false;
    },
    EditZone(poly) {
      this.edit_zone = poly;
      this.edit_zone_show = true;

      this.$store.commit('SET_ZONE_INFO', poly);
      this.$store.commit('SET_MAP_ZOOM', 12);

      this.$store.commit('SET_START_CENTER', poly.center);
      this.$store.commit('SET_MAP_ZOOM', 14);

    },
    calculateZoneMonitoring(){
      let check = 0
      if (Object.keys(this.polygon_zones).length > 0) {
         let $this = this;
        Object.keys(this.polygon_zones).forEach(function (key) {
          if ( $this.polygon_zones[key].monitoring_start) {
            check++
          }
        })

        }
      return check
    },

    startMonitoring(poly) {
      let mon = false
      if (poly.monitoring_start && poly.monitoring_start === true) {
        mon = false

      }else{

        if(this.calculateZoneMonitoring() >9){
          mon = false
          let message = this.lang.geo_zone_component.version_monitoring_zone;
          this.$iosAlert(message);

        }else{
          mon = true
        }

      }

      let add = {...this.polygon_zones}

      let change = this.polygon_zones[poly.status]
      change.monitoring_start = mon
      let date = Date.now()
      if (mon === true) {
        change.monitoring_start_time = date
        change.monitoring_stop_time = false
      } else {
        change.monitoring_stop_time = date

      }

      add[poly.status] = change;
      this.$store.commit('SET_POLYGON_ZONES', add);

    },

    GeoZoneList() {
      this.show_list = true;

    },
    eventClickPolygonal() {
      let button = document.getElementsByClassName("leaflet-draw-draw-polygon");
      button[0].click();
    },
    eventClickPolyline() {
      let button = document.getElementsByClassName("leaflet-draw-draw-polyline");
      button[0].click();
    },
    eventClickRectangle() {
      let button = document.getElementsByClassName("leaflet-draw-draw-rectangle");
      button[0].click();
    },
    eventClickMarker() {
      let button = document.getElementsByClassName("leaflet-draw-draw-marker");
      button[0].click();
    },
    eventClickCircle() {
      let button = document.getElementsByClassName("leaflet-draw-draw-circle");
      button[0].click();
    },
    eventClickWeather() {
      this.$store.commit('SET_SHOW_WEATHER', true);
      let button = document.getElementsByClassName("leaflet-draw-draw-marker");
      button[0].click();
    },
    eventClickPath() {
      this.$store.commit('SET_CREATE_PATH', true);
      let button = document.getElementsByClassName("leaflet-draw-draw-marker");
      button[0].click();
    },

    handlerPolyLocation(poly) {

      this.$store.commit('SET_SHOW_ALL_ZONES', true);
      this.$store.commit('SET_ZONE_INFO', poly);
      this.$store.commit('SET_ZONE_INFO', poly);
      let add_ref = 'poly_'+poly.status
      if(poly.type === 'marker'){
        this.$store.commit('SET_START_CENTER', poly.center);
        this.$store.commit('SET_MAP_ZOOM', 17);
      }else{
        this.$store.commit('SET_ZONE_BOUND', add_ref);
      }


      let $this = this;
      this.$nextTick(() => {
        $this.$store.commit('SET_SHOW_ZONE_INFO', true);
      })
    },
    getZoneInfo(zone_id) {
      let $this = this;
      let formData = new FormData();
      formData.append('ZoneData[Ids][]', zone_id);
      axios.post(base_url + '/geozones/getzoneinfo/', formData
      ).then(response => {
        if (response.data) {
          let zone = response.data[zone_id]

          let item = {}
          item.monitoring = {}
          item.info = zone
          let status = zone_id
          item.status = status;
          item.show = true;
          item.name = zone['Name'];
          item.color = '#FF7043'
          //console.log(zone)
          //this.$store.commit('SET_MAP_ZOOM', 12);

          if (zone.data['R'] >= 0) {
            if (zone.data['R'] == 0) {
              item.type = 'marker'
            } else {
              item.type = 'circle'
            }
            item.latlng = [];
            let y = zone.data['Y']
            let x = zone.data['X']
            let r = zone.data['R']
            item.latlng.push(y);
            item.latlng.push(x);
            item.mRadius = r * 1000
            item.center = [];
            item.center.push(y);
            item.center.push(x);
            zone.data = []
            zone.data['y'] = y
            zone.data['x'] = x
            zone.data['r'] = r

          } else if (zone.data['X1']) {
            item.type = 'rectangle'
            item.latlngs = []
            item.latlngs[0] = []
            let it1 = {'lat': zone.data['Y1'], 'lng': zone.data['X1']};

            let it2 = {'lat': zone.data['Y1'], 'lng': zone.data['X2']};
            let it3 = {'lat': zone.data['Y2'], 'lng': zone.data['X2']};
            let it4 = {'lat': zone.data['Y2'], 'lng': zone.data['X1']};

            item.latlngs[0].push(it1)
            item.latlngs[0].push(it2)
            item.latlngs[0].push(it3)
            item.latlngs[0].push(it4)
            item.center = [];
            item.center.push(zone['y']);
            item.center.push(zone['x']);

          } else {
            item.type = 'polygon'
            item.latlngs = [];
            item.latlngs[0] = []
            zone.data.forEach(element => {
              let it = {'lat': element['Y'], 'lng': element['X']};
              item.latlngs[0].push(it)
            });
            item.center = [];
            item.center.push(zone['y']);
            item.center.push(zone['x']);

          }
          //console.log(item)


          let edit_zones = {...$this.polygon_zones}


          $this.$set(edit_zones, status, item);
          $this.$store.commit('SET_POLYGON_ZONES', edit_zones);
          $this.$store.commit('SET_ZONE_INFO', item);

         /* $this.$nextTick(() => {
            this.$store.commit('SET_MAP_ZOOM', 16);
            this.$store.commit('SET_START_CENTER', item.center);
          });*/


        }
      }).catch(error => {
        console.log(error)
      })
    },
    handleSaveItem(poly) {

      if(poly.name && poly.name.length >0){
        this.saveItem(poly);
      }else{
        let message =this.lang.geo_zone_component.alert_add_name
        this.$iosAlert(message)
      }

    },
    getFormData(info, edit = false) {
      let formData = new FormData();


      let params = {
        ZoneData: {
          ZoneId: (info.ZoneId || 0),
          Name: info.Name,
          Code: 0,
          Address: info.Address,
          Type: info.Type,
          phones: info.phones,
          WeekDays: info.WeekDays,
          WeekDays1: info.WeekDays1,
          WeekDays2: info.WeekDays2,
          WeekDays3: info.WeekDays3,
          WeekDays4: info.WeekDays4,
          StopMinutes: 0,
          Boxes: info.Boxes,
          StopFromHH: info.StopFromHH,
          StopFromMM: info.StopFromMM,
          StopToHH: info.StopToHH,
          StopToMM: info.StopToMM,
          CustomType: info.CustomType,
          MyClientId: info.MyClientId,
          RootZoneId: info.RootZoneId,
          bWeekDays: info.bWeekDays,
          bUpdateCoords: 1,
          Comment: info.Comment,
        }
      }
      Object.keys(params.ZoneData).map(function(key, index) {
        formData.append("ZoneData["+key+"]",  params.ZoneData[key])
      });

      if (edit === false) {
        params.ZoneData.Data = info.data
        if (info.Type === 2) {

          let i = 0;
          info.data.map(elem => {
            formData.append("ZoneData[Data]["+i+"][x]",  elem.x);
            formData.append("ZoneData[Data]["+i+"][y]",  elem.y);
            i++;
          })

        }
        if (info.Type === 0) {

          formData.append("ZoneData[Data][X1]", info.data.X1);
          formData.append("ZoneData[Data][Y1]",  info.data.Y1);
          formData.append("ZoneData[Data][X2]", info.data.X2);
          formData.append("ZoneData[Data][Y2]",  info.data.Y2);

        }
        if (info.Type === 1 || info.Type === 3) {

          formData.append("ZoneData[Data][x]", info.data.x);
          formData.append("ZoneData[Data][y]",  info.data.y);
          formData.append("ZoneData[Data][r]", info.data.r);
        }

        params.ZoneData.Center = {'x': info.x, 'y': info.y}

        formData.append("ZoneData[Center][x]", info.x);
        formData.append("ZoneData[Center][y]",info.y);
      }

      return formData
    },
    saveItem(poly) {
      //console.log(poly)
      let formData = this.getFormData(poly.info)
      let $this = this
      //console.log(formData)
      /*$.ajax({
        url: base_url + '/index.php/geozones/addzone/',
        dataType: "jsonp",
        data: formData,
        xhrFields:{withCredentials:true},
        header:{
          'Content-Type' : 'application/json',
        },
        success: function (res) {
          console.log(res)
          if (typeof res === 'string') {


            let edit_zones = {...$this.polygon_zones}

            let check = poly.status
            poly.info.ZoneId = res;
            $this.$set(edit_zones, check, poly);

            $this.$store.commit('SET_POLYGON_ZONES', edit_zones);
            $this.closeEditZone()
          }
        },
        error: function (error) {
          console.log(error);
        }
      });*/


      //Object.keys(zone_data).forEach(key => formData.append(key, zone_data[key]));

      axios.post(base_url + '/geozones/addzone/' ,formData
       ).then(response => {
        if (typeof response.data === 'string') {

          let edit_zones = {...$this.polygon_zones}
          let check = poly.status
          poly.info.ZoneId = parseInt(response.data)
          $this.$set(edit_zones, check, poly)

          $this.$store.commit('SET_POLYGON_ZONES', edit_zones)
          $this.closeEditZone()
        }
       }).catch(error => {
         console.log(error)
       })
    },
    handlePolyCheckbox(poly) {
      let add = {...this.polygon_zones}
      if (poly.show === true) {
        add[poly.status].show = false
      } else {
        add[poly.status].show = true
      }
      this.$store.commit('SET_POLYGON_ZONES', add);
    },
    handlePolyCheckbox2(zone) {


      if (!this.polygon_zones[zone.id]) {
        this.getZoneInfo(zone.id)
      }


    },
    closeModal() {
      this.$store.commit('SET_SHOW_GEO_ZONE', false);
    },
    createZoneName(poly_status) {

      let add_name = this.current_name

      if(add_name.length>1) {
        let add = {...this.polygon_zones}
        add[poly_status].name = add_name

        add[poly_status].info.Name = add_name
        this.$store.commit('SET_POLYGON_ZONES', add);
        this.current_name=''
      }

    },
    getAddress(poly_lang, pole_lat, poly_status) {
      let _this = this
      $.ajax({
        url: 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + pole_lat + ',' + poly_lang + '&key=AIzaSyCoAoGJxRqTz8JJjOwHb4D64FqFC2IT0mE ',
        type: 'GET',
        success: function (res) {
          //console.log(res);
          if (res.results) {
            let add = {..._this.polygon_zones}
            add[poly_status].info.Address = res.results[0].formatted_address

          }
        },
        error: function (error) {
          console.log(error);
        }
      });
    },
    deletGeoZone(poly_status) {
      let add = {...this.polygon_zones}
      add[poly_status].name = event.target.value
      delete add[poly_status]
      this.$store.commit('SET_POLYGON_ZONES', add);
    },

  }
}
</script>

<style scoped>
.panel-body {
  padding-left: 0;
  padding-right: 0;
}

.geozone-list {
  display: flex;
  align-items: center;
  margin: 0;
  border-bottom: 0.5px solid #e5e5e5;
  border-radius: 3px;
  padding: 4px 10px;
  min-height: 40px;
  padding-right: 20px;
}


.geozone-list:focus, .geozone-list:hover {
  background-color: #e5f3f9;
}

.geozone-bnt {
  color: #777;
  border: none;
  box-shadow: none;
  opacity: 0;
  transition: opacity 100ms linear;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 16px;
  min-height: 25px;
  min-width: 25px;
  max-height: 25px;
}

.geozone-list:focus .geozone-bnt, .geozone-list:hover .geozone-bnt {
  opacity: 1;
  cursor: pointer;
  padding: 4px 10px;
  height: 40px;

}


.geozone-list .geozone-bnt.monitoring {
  opacity: 1 !important;
}

.geozone-bnt:hover, .geozone-bnt:focus {
  background-color: rgba(0, 0, 0, 0.24);

}

.geozone-bnt-left {
  margin-left: auto;
}

.img-circle {
  width: 24px;
  height: auto;
}

ul.simple-user-list li .title {
  color: #777;
  display: block;
  line-height: 1.334;
  font-size: 16px;
}

.index-label {
  margin-right: 10px;
  color: #1C71DA;
  font-size: 16px;
  font-weight: bold;

}

.btn-geo-create {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  min-width: 0;
  height: 40px;
  width: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  color: rgba(0, 0, 0, 0.54);
  border: none;

}

.btn-geo-create:hover, .btn-geo-create:focus {
  background-color: #dcdcdc;
}

.btn-box {
  display: flex;
  width: 300px;
  justify-content: space-between;
  margin-left: 20px;
}

.poly-icon svg {
  width: 26px;
}

.geo-zone-scroll-list {
  display: block;
  padding-left: 0;
}

.geo-zone-scroll-list.big {
  max-height: 350px;

}



.geo-zone .input-search:hover, .geo-zone .input-search:focus {
  border-color: #1C71DA;
}

.panel {
  min-width: 340px;
}

.geozone-list .panel-actions {
  top: 5px;
}

.input-group-arrow {
  margin: 0 10px;
}

.geozone-list a {
  text-decoration: none;
  cursor: pointer;
  padding-left: 10px;
}

.zone-star {
  margin-right: 10px

}

.label.label-primary {
  font-size: 10px;
  margin-left: 20px;
}

.fa.monitoring {
  width: 20px;
  height: 20px;
}

.panel.zone_edit {
  position: absolute;
  left: -400px;
  max-width: 300px;
  z-index: 10000;
}

.timeline .tm-body {
  position: relative;
  padding: 5px 0;
}

.timeline.timeline-simple .tm-body .tm-title {
  background: transparent;
}

.timeline .tm-items > li {
  margin: 10px 0;
}

.timeline .tm-items > li .tm-box {
  padding: 3px 3px;
  margin-right: 5px;
}

.zone-group__box {
  margin-left: auto;
  border-radius: 3px;
  background: #26A69A;
  color: #fff;
  padding: 2px 6px;
  font-size: 11px;
  line-height: 15px;
  font-weight: 600;
  margin-right: 10px;
}
.panel-title {
  font-size: 20px;
  max-width: 75%;
  color: #777;
  letter-spacing: 0.3px;
}
.panel.zone_edit  .panel-body{
  max-height: 65vh;
  overflow-y: auto;
}
.panel-body .content{
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
}


@media only screen and (max-width: 1500px) {
  .btn-geo-create[data-v-356a52c5] {
    border-radius: 6px;
    height: 32px;
    width: 32px;

  }
  .panel-heading {
    padding: 10px 5px;
  }
  .panel {
    min-width: 280px;
    max-width: 280px;
  }
  .btn-box {
    width: 250px;
  }
  .panel-title {
    font-size: 18px;
  }


}
@media only screen and (max-width: 600px) {
  .panel {
    min-width: 100vw;
    max-width: 100vw;
    width: 100vw;
  }
  ul.simple-user-list li {
    margin: 5px 0 10px;
  }

  .geo-zone-box {
    top: 60px;
    left: 0;
  }
  .geozone-bnt {
    opacity: 0.8;
  }
  .panel.geo-zone-scroll-panel {
     position: unset;
   left: 0;
    width: 100vw;
    top: 10px;
  }
  .panel-footer.geo-zone {

   }
  hr.dotted {
    display: none;
  }

  .btn-group-scroll {
    width: 100%;
  }
  .btn-box {
    width: 100%;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .mr-xs {
    margin-right: 8px !important;
  }
  .panel.zone_edit{
    position: absolute;
    left: 0;
    max-width: 100%;
    z-index: 10000;
    max-height: calc(100vh - 60px) ;
    height: calc(100vh - 60px);
  }
  .fa.geo-zone-icon {
    margin-right: 10px;
    margin-left: auto;
  }
  .panel-actions {
    position: absolute;
    top: 13px;
    right: 10px;
  }
  .panel-title.geo-zone .va-middle{
    display: none;
  }
  .panel-title.geo-zone{
    margin-right: 40px;
  }


}
</style>
