<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1"
       x="0px" y="0px" viewBox="0 0 490.7 490.7" style="enable-background:new 0 0 490.7 490.7;"
       xml:space="preserve">
<g>
	<g>
		<g>
			<path
        d="M137.869,242l0.2-31.1v-78.2c0-1.1,0-2.1,0.1-3.2c2.3-39.8,34.8-70.7,74.9-70.7c40.5,0,73.7,32.3,75,72.5l12,99.7     c27.1-24.3,44.3-59.4,44.3-98.6c0-73-59.4-132.4-132.4-132.4s-132.3,59.4-132.3,132.4C79.669,178,102.769,218.2,137.869,242z"/>
		</g>
    <g>
			<path
        d="M465.369,353c-80.6-55.3-196.2-74.5-196.2-74.5l-17.4-144.6c0-21.4-17.3-38.7-38.7-38.7c-21,0-38.1,16.7-38.7,37.6l0,0     v78.5l-1.5,222.5l-45.8-35.3c0,0-58.7-51.8-107.3,1.6c0,0,31.1,43.8,80.4,90.6h362.6C479.469,424.4,465.369,353,465.369,353z"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgClickIcon"
}
</script>

<style scoped>

</style>
