<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 490.667 490.667" style="enable-background:new 0 0 490.667 490.667;" xml:space="preserve">
<g>
	<g>
		<g>
			<path d="M245.333,0C110.059,0,0,110.059,0,245.333s110.059,245.333,245.333,245.333s245.333-110.059,245.333-245.333     S380.608,0,245.333,0z M245.333,469.333c-123.52,0-224-100.48-224-224s100.48-224,224-224s224,100.48,224,224     S368.853,469.333,245.333,469.333z"/>
      <path d="M245.333,192c29.397,0,53.333-23.936,53.333-53.333s-23.936-53.333-53.333-53.333S192,109.269,192,138.667     C192,168.064,215.936,192,245.333,192z M245.333,106.667c17.643,0,32,14.357,32,32c0,17.643-14.357,32-32,32s-32-14.357-32-32     C213.333,121.024,227.691,106.667,245.333,106.667z"/>
      <path d="M309.333,341.333h-10.667V224c0-5.888-4.779-10.667-10.667-10.667h-85.333c-17.643,0-32,14.357-32,32s14.357,32,32,32     h10.667v64h-10.667c-17.643,0-32,14.357-32,32c0,17.643,14.357,32,32,32h106.667c17.643,0,32-14.357,32-32     C341.333,355.691,326.976,341.333,309.333,341.333z M309.333,384H202.667c-5.888,0-10.667-4.779-10.667-10.667     c0-5.888,4.779-10.667,10.667-10.667H224c5.888,0,10.667-4.779,10.667-10.667v-85.333c0-5.888-4.779-10.667-10.667-10.667     h-21.333c-5.888,0-10.667-4.779-10.667-10.667s4.779-10.667,10.667-10.667h74.667V352c0,5.888,4.779,10.667,10.667,10.667h21.333     c5.888,0,10.667,4.779,10.667,10.667C320,379.221,315.221,384,309.333,384z"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgInfoIcon"
}
</script>

<style scoped>

</style>