<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 409.413 409.413" style="enable-background:new 0 0 409.413 409.413;" xml:space="preserve">
<g>
	<path d="M143.195,148.25h-74v183h74V148.25z M128.195,316.25h-44v-153h44V316.25z"/>
  <path d="M248.195,207.25h-74v124h74V207.25z M233.195,316.25h-44v-94h44V316.25z"/>
  <path d="M354.195,262.25h-74v69h74V262.25z M339.195,316.25h-44v-39h44V316.25z"/>
  <polygon points="379.53,338.064 368.735,348.479 382.02,362.25 38.195,362.25 38.195,37.913 51.354,51.071 61.96,40.464    30.98,9.484 0,40.464 10.606,51.071 23.195,38.482 23.195,377.25 379.993,377.25 367.921,389.321 378.528,399.929 409.413,369.043     "/>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>
</template>

<script>
export default {
  name: "svgChartBar"
}
</script>

<style scoped>

</style>